import React, { useEffect, useState } from "react";
import { BackgrondProps } from "../../interfaces";
import { backgroundDay } from "./day";
import { generatePlantColors } from "./helpers";
import { backgroundNight } from "./night";

let hue = 0;
export const Office = ({ idle, svgProps }: BackgrondProps) => {
  const hour = new Date().getHours();
  const day = hour > 6 && hour < 18;

  useEffect(() => {
    let timeout;
    if (idle) {
      timeout = setInterval(() => {
        hue += 0.1;
        setPlantColor(generatePlantColors(hue));
        if (hue >= 1) clearTimeout(timeout);
      }, 1000 * 30);
    } else {
      clearInterval(timeout);
    }

    return () => clearTimeout(timeout);
  }, [idle]);

  const [plantColor, setPlantColor] = useState(generatePlantColors(0));

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1674 1200"
      {...svgProps}
    >
      {day ? backgroundDay(plantColor) : backgroundNight()}
    </svg>
  );
};

import { Message } from "../../message-hub/interfaces";

export const ONBOARDING_NEXT_SCREEN = "onboarding.next.screen";
export const ONBOARDING_DONE = "onboarding.done";
export const ONBOARDING_PASS_SCORE = "onboarding.pass.score";

export type OnboardingNextScreen = Message<typeof ONBOARDING_NEXT_SCREEN>;
export type OnboardingDone = Message<typeof ONBOARDING_DONE>;
export type OnboardingPassScore = Message<
  typeof ONBOARDING_PASS_SCORE,
  { score: number }
>;

export const messagesTypes = {
  ONBOARDING_NEXT_SCREEN,
  ONBOARDING_DONE,
  ONBOARDING_PASS_SCORE,
} as const;

export type OnboardingAppMessages =
  | OnboardingNextScreen
  | OnboardingDone
  | OnboardingPassScore;

import LockIcon from "@mui/icons-material/Lock";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Autocomplete,
  Box,
  TextField,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppLayout, { AppLayoutPropsType } from "../app-layout/app-layout";
import { BrowserContext } from "../browser/browser";
import { useDeviceDetect, useRootState } from "../hooks";
import { Application } from "../interfaces";
import theme from "../theme";
import styles from "./styles";

type PropsType = AppLayoutPropsType & {
  app: Application;
};

const BrowserLayout: React.FC<PropsType> = (props: PropsType) => {
  const browserContext = useContext(BrowserContext);
  const classes = styles({ color: props.headerColor });
  const device = useDeviceDetect();
  const { appsBrowser } = useRootState();
  const history = useHistory();
  const [domainName, setDomainName] = useState(browserContext.domain);
  const [hideSearchBar, setHideSearchBar] = useState<boolean>(false);
  const [isWarningIcon, setIsWarningIcon] = useState<boolean>(false);
  const urlsToSuggest = appsBrowser
    .map((a) => a.browser && a.browser.domain)
    .filter(Boolean);

  const onKeyUp = (e) => {
    if (e.key === "Enter") browserContext.navigate(domainName);
  };

  useEffect(() => {
    if (props.state && props.state === "spamfighter") {
      setDomainName("finder.com");
    }
  }, [props.app]);

  useEffect(() => {
    setDomainName(browserContext.domain);
  }, [browserContext.domain]);

  useEffect(() => {
    const pathname: string = history.location.pathname;
    if (
      pathname.includes("main") ||
      pathname.includes("message") ||
      pathname.includes("/p/") ||
      pathname.includes("chat")
    ) {
      setHideSearchBar(true);
    } else if (pathname.includes("worldnews")) {
      setIsWarningIcon(true);
    } else {
      setHideSearchBar(false);
    }
  }, [history.location.pathname, props.app]);

  const searchBar = () => {
    return (
      <ThemeProvider theme={theme}>
        <Toolbar
          className={`${classes.searchBar} ${
            device.mobile ? classes.mobile : ""
          }`}
          style={{
            backgroundColor: props.headerColor || "transparent",
            borderTopLeftRadius: device.browser ? 15 : 0,
            borderTopRightRadius: device.browser ? 15 : 0,
          }}
        >
          <Box className={classes.urlBox}>
            {isWarningIcon ? (
              <WarningRoundedIcon fontSize="small" />
            ) : (
              <LockIcon fontSize="small" />
            )}
            <Autocomplete
              options={urlsToSuggest}
              freeSolo
              fullWidth
              size="small"
              disableClearable
              value={domainName}
              onKeyUp={onKeyUp}
              onChange={(e, newValue) => browserContext.navigate(newValue)}
              onInputChange={(e, newValue) => setDomainName(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    "data-e2e-url-input": true,
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Box>
        </Toolbar>
      </ThemeProvider>
    );
  };

  if (device.mobile) {
    return (
      <AppLayout
        {...props}
        searchBar={searchBar()}
        content={
          <div style={{ height: "100%", overflow: "auto" }}>
            {hideSearchBar || searchBar()}
            {props.content}
          </div>
        }
      />
    );
  } else {
    return (
      <AppLayout
        {...props}
        searchBar={searchBar()}
        header={
          <div>
            {searchBar()}
            {props.header}
          </div>
        }
      />
    );
  }
};

export default BrowserLayout;

import { Card, CardContent, CardHeader } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { FormattedMessage } from "react-intl";

type PropsType = {
  title: string;
  subheader?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  style?: Record<string, any>;
};

const useStyles = makeStyles(() => ({
  box: {
    minHeight: "100%",
  },
  content: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    maxWidth: "100%",
    overflowY: "auto",
    placeItems: "center",
  },
}));

export const DashboardBox = (props: PropsType) => {
  const classes = useStyles();

  const { style, className, title, subheader, children, ...rest } = props;

  return (
    <Card className={`${classes.box} ${className}`} style={style}>
      <CardHeader
        title={<FormattedMessage id={title} />}
        subheader={subheader}
        {...rest}
      />
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

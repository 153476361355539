import { Message } from "../message-hub/interfaces";

export const STEP_DONE = "script.step.done";
export const STEP_MARKER = "script.step.marker";
export const SCRIPT_COMPLETE = "script.complete";
export const SCRIPT_REPLAY_DONE = "script.replaydone";
export const SCRIPT_HASH = "script.hash";
export const SCRIPT_STEPS = "script.steps";
export const SCRIPT_QUESTS = "script.quests";

export type stepDoneMessage = Message<typeof STEP_DONE, string>;
export type scriptCompleteMessage = Message<typeof SCRIPT_COMPLETE, string>;
export type scriptHashMessage = Message<typeof SCRIPT_HASH, string>;
export type scriptStepsMessage = Message<typeof SCRIPT_STEPS, string[]>;

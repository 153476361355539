import { marked } from "marked";
import {
  getApplicationConfigSingleValue,
  getSeasonsDataFromApi,
  updateApplicationConfigSingleValue,
} from "../core/client";
import { MicrolearningContent, TakeAwayContent } from "../core/microlearning";
import { ForcedEpisodeConfig, SAVE_KEY, setItem } from "../core/storage";
import { createEpisodeHash } from "../core/utils";
import { createToLang, getSeasons } from "../episodes";
import { MarkdownEpisodeBuilder } from "../episodes/builder/builder";
import { getLanguage } from "../i18n";
import { Episode, EpisodePublish, Season } from "./interfaces";

export type EpisodeMetaInfo = Omit<Episode, "load"> & {
  index: number;
};

type EpisodeAccess = {
  [k: Season["id"]]: { lastEpisode: Episode["index"] };
};

const getEpisodeAccessConfig = async (
  ...seasonId: Season["id"][]
): Promise<EpisodeAccess> => {
  const episodeConfig = await getApplicationConfigSingleValue("seasons");
  if (episodeConfig) {
    try {
      return JSON.parse(episodeConfig);
    } catch (e) {
      console.error(e);
    }
  }
  // Fallback to old configuration
  const res = await Promise.all(
    seasonId.map((id) =>
      getApplicationConfigSingleValue(`S${id}_LATEST_EPISODE`)
    )
  );
  const out = {} as EpisodeAccess;
  for (let i = 0; i < res.length; i++) {
    const index = parseInt(res[i], 10);
    if (!isNaN(index)) {
      out[seasonId[i]] = { lastEpisode: index };
    }
  }
  return out;
};

type EndedEpisodes = Record<Episode["id"], number>;
export type EndedEpisodesExternal = Record<
  Season["id"],
  Record<Episode["id"], number>
>;
export class Episodes {
  private episodeList: Episode[] = [];
  private seasonList: Season[] = [];
  private episodeById: Record<Episode["id"], Episode> = {};
  private seasonById: Record<Season["id"], Season> = {};
  private forcedEpisode?: ForcedEpisodeConfig;
  private accessState: EpisodeAccess = {};
  private endedEpisodesExternal: EndedEpisodesExternal = {};
  private endedEpisodes: EndedEpisodes = {};
  private episodesPayload: Record<Episode["id"], any> = {};

  async init(
    endedEpisodes: EndedEpisodes,
    endedEpisodesExternal: EndedEpisodesExternal,
    episodesPayload: Record<Episode["id"], any>,
    forcedEpisode?: ForcedEpisodeConfig
  ): Promise<void> {
    this.endedEpisodes = endedEpisodes;
    this.endedEpisodesExternal = endedEpisodesExternal;
    this.episodesPayload = episodesPayload;
    this.forcedEpisode = forcedEpisode;

    const localSeasons = await getSeasons(getLanguage());
    const remoteSeasons = await this.getSeasonsInfo();
    const remoteSeasonsNoDuplicates = remoteSeasons.filter((season) => {
      return !localSeasons.map(({ id }) => id).includes(season.id);
    });

    this.accessState = await getEpisodeAccessConfig(
      ...localSeasons.map((s) => s.id)
    );

    this.transformSeasonDefinitionsToSeason(
      localSeasons,
      remoteSeasonsNoDuplicates,
      this.accessState
    );
  }

  private async getSeasonsInfo(): Promise<Season[]> {
    const seasons = await getSeasonsDataFromApi();
    const toLang = createToLang(getLanguage());

    return seasons.map((s: Season) => ({
      ...s,
      episodes: Object.entries(s.episodes).map(([key, value]) => ({
        ...value,
        id: key,
        description: toLang(value.description),
        title: toLang(value.title),
        seasonId: s.id,
        maturity: value.maturity,
        episodePublishingCompatibility: false,
        state: "LOCKED",
        playcount: 0,
        assetsUrl: s.assetsURL,
      })),
      topics: [],
      info: toLang(s.info),
      seasonEndMessage: toLang(s.seasonEndMessage),
      title: toLang(s.title),
    }));
  }

  private transformSeasonDefinitionsToSeason = (
    local: Season[],
    remote: Season[],
    episodeAccess: EpisodeAccess
  ) => {
    const processSeason = (season: Season) => {
      season.index = this.seasonList.length;
      this.seasonById[season.id] = season;
      let episodeIndex = 1;
      season.episodes.forEach((e) => {
        this.episodeById[e.id] = this.addEpisodeState(e);
        e.index = episodeIndex++;
        if (this.episodesPayload && this.episodesPayload[e.id]) {
          e.payload = this.episodesPayload[e.id];
        }
        this.episodeList.push(e);
      });
      this.addSeasonState(season);
      this.seasonList.push(season);
    };

    for (const s of local) {
      const { lastEpisode } = episodeAccess[s.id] || {};
      if (typeof lastEpisode !== "number") continue;
      processSeason(s);
    }

    remote.forEach(processSeason);
  };

  private addSeasonState(s: Season) {
    const getSeasonState = (e) => {
      return e.episodes.every((e) => !!this.endedEpisodes[e.id]);
    };

    Object.defineProperty(s, "completed", {
      enumerable: true,
      get() {
        return getSeasonState(this);
      },
    });

    return s;
  }

  private addEpisodeState(episode: Episode) {
    const isEpisodeComplete = (e: Episode) => getPlaycount(e) > 0;

    const isEpisodeLocked = (episode: Episode) => {
      if (
        episode.seasonId === this.forcedEpisode?.sid &&
        episode.id === this.forcedEpisode?.eid
      ) {
        return false;
      }

      if (
        !episode.episodePublishingCompatibility &&
        episode.maturity === "PUBLIC"
      ) {
        return false;
      }

      return episode.publish !== "PUBLISHED";
    };

    const getEpisodePublish = (e: Episode): EpisodePublish => {
      if (e.maturity === "PUBLIC") {
        const lastEpisode = this.accessState[e.seasonId].lastEpisode;
        if (e.index <= lastEpisode) return "PUBLISHED";
        if (e.index - 1 === lastEpisode) return "READY";
      }

      return "LOCKED";
    };

    const getPlaycount = (e: Episode) =>
      this.endedEpisodes[e.id] ||
      this.endedEpisodesExternal[e.seasonId]?.[e.id] ||
      0;

    if (episode.episodePublishingCompatibility) {
      Object.defineProperty(episode, "publish", {
        enumerable: true,
        get() {
          return getEpisodePublish(this);
        },
      });
    }

    Object.defineProperty(episode, "locked", {
      enumerable: true,
      get() {
        return isEpisodeLocked(this);
      },
    });

    Object.defineProperty(episode, "completed", {
      enumerable: true,
      get() {
        return isEpisodeComplete(this);
      },
    });

    Object.defineProperty(episode, "playcount", {
      enumerable: true,
      get() {
        return getPlaycount(this);
      },
    });

    return episode;
  }

  async firstEpisode() {
    for (const e of this.episodeList) {
      if (e.locked) return e;
    }
  }

  async episode(episodeId: Episode["id"]) {
    return this.getEpisodeById(episodeId);
  }

  async season(episodeId: Episode["id"]) {
    const e = await this.getEpisodeById(episodeId);
    return this.seasonById[e.seasonId];
  }

  async complete(episodeId: Episode["id"]) {
    const numberOfReplays = this.endedEpisodes[episodeId] || 0;
    this.endedEpisodes[episodeId] = numberOfReplays + 1;
  }

  async pause(episodeId) {
    const index = this.episodeList.findIndex((e) => e.id === episodeId);
    this.episodeList[index].directRun = false;
  }

  async getEpisodeById(id: Episode["id"]): Promise<Episode> {
    return this.episodeById[id];
  }

  getEpisodeByHash(hash: string): Episode | null {
    return (
      this.episodeList.find(({ id }) => createEpisodeHash(id) === hash) || null
    );
  }

  async getSeasonById(id: Season["id"]): Promise<Season> {
    return this.seasonById[id];
  }

  async getEpisodes(
    seasonId: Season["id"] = this.seasonList[0].id
  ): Promise<Episode[]> {
    return this.episodeList.filter((e) => e.seasonId === seasonId);
  }

  async getSeasons() {
    return this.seasonList;
  }

  async publishEpisode(episode: Episode): Promise<void> {
    const state = {
      ...this.accessState,
      [episode.seasonId]: { lastEpisode: episode.index },
    };
    await updateApplicationConfigSingleValue("seasons", JSON.stringify(state));
    this.accessState = state;
  }

  getForcedEpisode() {
    return this.forcedEpisode;
  }

  getNextPlayable() {
    const episodes = this.seasonList
      .reduce((episodes: Episode[], season) => {
        return [...episodes, ...season.episodes];
      }, [])
      .filter((e) => !e.hidden);
    const episode = episodes.find((e) => !e.locked && !e.completed);
    return episode;
  }

  saveMetaInfo(tourDone: boolean, tourTipDone: boolean) {
    setItem(SAVE_KEY, {
      endedEpisodes: this.endedEpisodes,
      episodesPayload: this.episodesPayload,
      tourDone,
      tourTipDone,
    });
  }
}

export const loadTakeAways = async (e: Episode): Promise<TakeAwayContent> => {
  const takeAwayContent: TakeAwayContent = {};
  const url = e.assetsUrl
    ? `${e.assetsUrl}/microlearning/${e.id}/microlearning.${getLanguage()}.json`
    : `${e.url}microlearning.${getLanguage()}.json`;
  try {
    const microlearningResponse = await fetch(url);
    if (microlearningResponse.ok) {
      takeAwayContent.links =
        (await microlearningResponse.json()) as MicrolearningContent[];
    }
  } catch (err) {
    console.error("Error while downloading microlearning content", err);
  }

  try {
    const markdownResponse = await fetch(
      e.url + (getLanguage() === "de" ? "takeaways.md" : "takeaways_en.md")
    );
    if (markdownResponse.ok) {
      takeAwayContent.markdown = marked(await markdownResponse.text());
    }
  } catch (err) {
    console.log("Error while downloading take-away content", err);
  }
  return takeAwayContent;
};

export const loadQuizExplanations = async (e: Episode) => {
  const file = getLanguage() === "de" ? "script.md" : "script_en.md";
  const url = e.url + file;

  const md = new MarkdownEpisodeBuilder(url);
  if (md.tokens.length === 0) {
    await md.init();
  }
  const labels = md.tokens.filter(
    (t) =>
      t.type === "blockquote" && new RegExp("quizTakeaway", "gi").test(t.text)
  );
  const quizExplanations = <any>[];
  labels.forEach((l) => {
    const [label] = md.findLabel(l.text);
    const [heading] = label.filter((l) => l.type === "heading");
    const content = label.filter((l) => l.type !== "heading");

    quizExplanations.push({
      heading: heading.text,
      content: marked.parser(content),
    });
  });

  return quizExplanations;
};

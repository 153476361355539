import { Message } from "../../message-hub/interfaces";
import { Content } from "./interfaces/content";
import { Question } from "./interfaces/question";

export const QUIZ_TIME_ADD_MESSAGE = "quiz.time.add.question";
export const QUIZ_TIME_APP_EXIT = "quiz.time.app.exit";
export const QUIZ_TIME_SKIP_QUESTION = "quiz.time.skip.question";
export const QUIZ_TIME_ANSWER_QUESTION = "quiz.time.answer.question";
export const QUIZ_TIME_CONTINUE = "quiz.time.continue";
export const QUIZ_TIME_SHOW_CONTENT = "quiz.time.show.content";
export const QUIZ_TIME_CHECK_IF_ANY_QUESTION_AVAILABLE =
  "quiz.time.check.if.any.question.available";
export const QUIZ_TIME_NO_QUESTIONS_LEFT = "quiz.time.no.questions.left";
export const QUIZ_TIME_SET_ACTOR_IMG = "quiz.time.set.actor.img";

export type QuizTimeAddQuestion = Message<
  typeof QUIZ_TIME_ADD_MESSAGE,
  {
    question: Question;
    content: Content;
  }
>;

export type QuizTimeAppExit = Message<typeof QUIZ_TIME_APP_EXIT>;

export type QuizTimeSkipQuestion = Message<
  typeof QUIZ_TIME_SKIP_QUESTION,
  { id: string }
>;

export type QuizTimeAnswerQuestion = Message<
  typeof QUIZ_TIME_ANSWER_QUESTION,
  {
    id: string;
    answers: Record<number, boolean>;
  }
>;

export type QuizTimeContinue = Message<
  typeof QUIZ_TIME_CONTINUE,
  { id: string }
>;

export type QuizTimeShowContent = Message<
  typeof QUIZ_TIME_SHOW_CONTENT,
  { show: boolean }
>;

export type QuizTimeCheckIfAnyQuestionAvailable = Message<
  typeof QUIZ_TIME_CHECK_IF_ANY_QUESTION_AVAILABLE
>;

export type QuizTimeNoQuestionsLeft = Message<
  typeof QUIZ_TIME_NO_QUESTIONS_LEFT
>;

export type QuizTimeSetActorImage = Message<
  typeof QUIZ_TIME_SET_ACTOR_IMG,
  { actorImg: string }
>;

export type QuizTimeMessage =
  | QuizTimeAddQuestion
  | QuizTimeAppExit
  | QuizTimeSkipQuestion
  | QuizTimeAnswerQuestion
  | QuizTimeContinue
  | QuizTimeShowContent
  | QuizTimeCheckIfAnyQuestionAvailable
  | QuizTimeNoQuestionsLeft
  | QuizTimeSetActorImage;

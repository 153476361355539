import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import icon from "./assets/feedback.svg";
import logo from "./assets/logo.png";
import classes from "./feedback-button.module.scss";

type PropsType = {
  styles?: React.CSSProperties;
  iconButton?: boolean;
  className?: string;
  fallbackContactStyles?: React.CSSProperties;
};

export const FeedbackButton: React.FC<PropsType> = (props: PropsType) => {
  const { styles, iconButton, className, fallbackContactStyles } = props;
  const [fallbackContactOpen, setFallbackContactOpen] = useState(false);
  const intl = useIntl();

  const feedback = () => {
    const w: any = window;
    if (w?.fcWidget && w?.fcWidget.isLoaded()) {
      w.fcWidget.open();
    } else {
      setFallbackContactOpen(true);
    }
  };

  if (iconButton) {
    return (
      <div
        className={`${classes.rootIconButton} ${className || ""}`}
        style={styles || {}}
        onClick={feedback}
      >
        <img className={classes.bugIcon} src={icon} />
      </div>
    );
  }

  const fallbackContact = () => (
    <div
      className={classes.fallbackContact}
      style={fallbackContactStyles || {}}
    >
      <header className={classes.header}>
        <img src={logo} alt="logo" />
        <div
          className={classes.closeBtn}
          onClick={() => setFallbackContactOpen(false)}
        >
          <Close />
        </div>
      </header>
      <div style={{ position: "relative" }}>
        <div className={classes.articleWrapper}>
          <article className={classes.article}>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.messages["global.contact.blocked"] as string,
              }}
            />
          </article>
          <article className={classes.article}>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.messages["global.contact.email"] as string,
              }}
            />
          </article>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        data-dashboard-tour="feedback-button"
        className={`${classes.root} ${className || ""}`}
        style={styles || {}}
        onClick={feedback}
      >
        <p title={intl.formatMessage({ id: "global.contact.tip" })}>
          <FormattedMessage id="global.contact" defaultMessage="Kontakt" />
        </p>
      </div>
      {fallbackContactOpen && fallbackContact()}
    </>
  );
};

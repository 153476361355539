import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from "@mui/material";
import { getMissionsIndividualProgress } from "core/client";
import { DiamondIcon } from "core/diamond-icon/diamond-icon";
import {
  MissionIndividualProgress,
  MissionProgressOnly,
} from "core/interfaces";
import { isNumber } from "lodash";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getName } from "../pages/admin/missions/missions.service";
import { TARGET_THRESHOLD } from "../shared";
import styles from "./mission-individual-progress-card.module.scss";

const ProgressBox = ({
  value,
  who,
  color,
}: {
  value: number;
  who: ReactElement;
  color: LinearProgressProps["color"];
}) => {
  const [internalValue, setInternalValue] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isNumber(value)) setInternalValue(value);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Box>
      <Typography variant="caption">{who}</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1, position: "relative" }}>
          <LinearProgress
            variant="determinate"
            color={value >= TARGET_THRESHOLD ? "success" : color}
            value={internalValue}
          />
          <Box
            sx={{
              position: "absolute",
              left: `${TARGET_THRESHOLD}%`,
              top: "-4px",
              height: "12px",
              backgroundColor: "#fff",
              width: "2px",
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="caption">{value}%</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const calculateOverallProgress = (
  missions: MissionIndividualProgress[]
): MissionProgressOnly => {
  const totals = missions.reduce(
    (acc, cur) => ({
      myProgress: acc.myProgress + cur.myProgress,
      organizationProgress: acc.organizationProgress + cur.organizationProgress,
    }),
    {
      myProgress: 0,
      organizationProgress: 0,
    }
  );

  return {
    myProgress: Math.round(totals.myProgress / missions.length),
    organizationProgress: Math.round(
      totals.organizationProgress / missions.length
    ),
  };
};

const MissionIndividualProgressCard = () => {
  const [progress, setProgress] = useState<MissionIndividualProgress[]>([]);
  const calculatedProgress = useMemo<MissionProgressOnly | undefined>(() => {
    if (progress?.length) {
      return calculateOverallProgress(progress);
    }
  }, [progress]);
  const [expanded, setExpanded] = useState(false);
  const [showSubHeader, setShowSubHeader] = useState(false);

  useEffect(() => {
    getMissionsIndividualProgress().then(setProgress);
  }, []);

  if (progress && progress.length < 1) return null;

  return (
    <Card sx={{ mb: 2 }} className="animate__animated animate__slideInDown">
      <CardHeader
        title={<FormattedMessage id="dashboard.page.home.missions.title" />}
        subheader={
          showSubHeader ? (
            <FormattedMessage
              id="dashboard.page.home.missions.subheader"
              values={{ threshold: TARGET_THRESHOLD }}
            />
          ) : null
        }
        action={
          showSubHeader ? (
            <IconButton onClick={() => setShowSubHeader(false)}>
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton onClick={() => setShowSubHeader(true)}>
              <ExpandMore />
            </IconButton>
          )
        }
      />

      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {calculatedProgress && (
              <ProgressBox
                value={calculatedProgress.myProgress}
                who={<FormattedMessage id="dashboard.page.home.missions.you" />}
                color="primary"
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {calculatedProgress && (
              <ProgressBox
                value={calculatedProgress.organizationProgress}
                who={
                  <FormattedMessage id="dashboard.page.home.missions.everyone" />
                }
                color="secondary"
              />
            )}
          </Grid>
        </Grid>
        {expanded && (
          <Grid container spacing={2} mt={{ xs: 1, md: 2 }}>
            {progress?.map((mission) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={mission.targetId}
                className="animate__animated animate__fadeIn"
              >
                <Card variant="outlined">
                  <CardHeader
                    title={
                      <FormattedMessage
                        id={getName(mission.what)}
                        defaultMessage={getName(mission.what)}
                      />
                    }
                    titleTypographyProps={{
                      noWrap: true,
                    }}
                    subheader={
                      <FormattedMessage
                        id={getName(mission.who)}
                        defaultMessage={getName(mission.who)}
                      />
                    }
                    subheaderTypographyProps={{ noWrap: true }}
                    avatar={
                      <DiamondIcon
                        level={mission.level}
                        tooltip={true}
                        style={{ height: 42 }}
                      />
                    }
                    classes={{ content: styles.card_header_content }}
                  />
                  <CardContent>
                    <Stack>
                      <ProgressBox
                        value={mission.myProgress}
                        who={
                          <FormattedMessage id="dashboard.page.home.missions.you" />
                        }
                        color={"primary"}
                      />
                      <ProgressBox
                        value={mission.organizationProgress}
                        who={
                          <FormattedMessage id="dashboard.page.home.missions.everyone" />
                        }
                        color="secondary"
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
      <CardActions>
        {expanded ? (
          <Button size="small" onClick={() => setExpanded(false)}>
            <FormattedMessage id="dashboard.page.home.missions.show-less" />
          </Button>
        ) : (
          <Button size="small" onClick={() => setExpanded(true)}>
            <FormattedMessage id="dashboard.page.home.missions.show-more" />
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default MissionIndividualProgressCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useCompetence } from "../../competences";
import { DiamondIcon } from "../../diamond-icon/diamond-icon";
import { CompetenceArea } from "../../interfaces";
import styles from "./episode-report-tests.module.scss";

type Props = {
  competenceArea: CompetenceArea;
  language: "en" | "de";
};

export const CompetenceAreaTests = ({ competenceArea, language }: Props) => {
  const competence = useCompetence(competenceArea.competenceAreaId);
  const [tooltipVisable, setTooltipVisable] = useState(false);

  const getDescriptionForTest = (test) => {
    let description;

    try {
      description = JSON.parse(test.description)[language];
    } catch {
      console.log(`Error Parsing ${test}`);
      description = test.description;
    }

    return description;
  };

  return (
    <div className={styles.competenceArea}>
      <div className="animate__animated animate__fadeIn">
        <div className={styles.title}>
          {competence?.icon && (
            <FontAwesomeIcon
              icon={competence?.icon}
              className={styles.competenceIcon}
              style={{ background: competence.color }}
              onMouseEnter={() => setTooltipVisable(true)}
              onMouseLeave={() => setTooltipVisable(false)}
              onClick={() => setTooltipVisable(!tooltipVisable)}
            />
          )}
          <h1>{competence?.name}</h1>
        </div>
        <div
          style={{
            background: competence?.color,
            width: "100%",
            height: "4px",
            margin: "1rem 0",
          }}
        />
        <div>
          {competenceArea.tests.map((test) => (
            <div
              key={test.id}
              className={styles.testRow}
              style={test.result !== 1 ? { opacity: "0.5" } : {}}
            >
              <DiamondIcon
                level={test.level}
                className="animate__animated animate__bounceInDown"
              />
              <p
                className="animate__animated animate__bounceInUp"
                data-e2e-report-test-id={test.id}
                data-e2e-report-test-result={test.result}
              >
                {getDescriptionForTest(test)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

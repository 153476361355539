import { Button, Grid, Typography } from "@mui/material";
import { getItem } from "core/storage";
import { scriptHash } from "game-engine/persistence";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import GameEngine from "../../game-engine";
import { Episode } from "../../game-engine/interfaces";
import LevelSurvey from "../level-end-survey/level-survey";
import styles from "./styles";

type Props = {
  onClose: () => void;
};

const LevelEnd: React.FC<Props> = (props: Props) => {
  const gameEngine = GameEngine();
  const classes = styles();
  const [episode] = useState<Episode>(gameEngine.episode as Episode);
  const [
    testEngineHasFailedResultUploads,
    setTestEngineHasFailedResultUploads,
  ] = useState<boolean>(false);

  const [hash, setHash] = useState("");

  useEffect(() => {
    if (episode) {
      const levelSurvey = getItem("levelSurvey");
      const hash = getItem(scriptHash(episode.id));
      const feedbackGiven = levelSurvey?.includes(hash);

      setHash(hash);

      if (feedbackGiven || episode.skipEndgameFeedback) props.onClose();
      setTestEngineHasFailedResultUploads(
        gameEngine.testEngineHasFailedResultUploads()
      );
    }
  }, [episode]);

  return (
    <div className={classes.root}>
      <Grid
        style={{ minHeight: "100%" }}
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction="column"
        wrap="nowrap"
      >
        <Grid item className={classes.textContainer}>
          <Typography className={classes.level}>
            <FormattedMessage
              id="level.end.completed"
              values={{ level: episode?.index }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <LevelSurvey episode={episode} hash={hash} />
        </Grid>
        <Grid item>
          {testEngineHasFailedResultUploads ? (
            <Typography className={classes.levelText}>
              <FormattedMessage id="gameControl.levelend.uploadFailed" />
            </Typography>
          ) : (
            <Button
              data-e2e-to-results-button
              variant="contained"
              disableElevation
              className={classes.button}
              onClick={props.onClose}
            >
              <FormattedMessage id="level.end.to.results" />
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LevelEnd;

const messages = {
  "meetings-tool-browser.type": "Type",
  "meetings-tool-browser.duration": "Duration",
  "meetings-tool-browser.form.title": "Fill details",
  "meetings-tool-browser.form.name": "Name *",
  "meetings-tool-browser.form.mail": "Your email *",
  "meetings-tool-browser.form.additional_information": "Additional information",
  "meetings-tool-browser.form.schedule_event": "Schedule event",
  "meetings-tool-browser.message.title": "You are scheduled with {name}",
  "meetings-tool-browser.message.text":
    "We have sent you the details of the meeting to your email.",
  "meetings-tool-browser.mail-error-message": "Incorrect mail address format",
};

export default messages;

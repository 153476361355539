import React from "react";
import { FormattedMessage } from "react-intl";
import { useActors } from "../applications";
import { msgKey, scale } from "./data";
import styles from "./styles.module.scss";

export default function Satisfaction(props: { onAnswer: (a) => void }) {
  const actors = useActors();
  const { sprites } = actors.player;
  const icons = [
    sprites?.faceWorried as string,
    sprites?.faceShocked as string,
    sprites?.faceNeutral as string,
    sprites?.faceSmile as string,
    sprites?.faceHappy as string,
  ];
  const icon = (ix) => <img src={icons[ix]} />;

  return (
    <div className={styles.buttonContainer}>
      {scale.map((s, ix) => (
        <button
          key={ix}
          className={`${styles.button} ${styles.square} ${styles.face}`}
          onClick={() => props.onAnswer(ix)}
        >
          {icon(ix)}
          <span>
            <FormattedMessage id={msgKey(s.title)} />
          </span>
        </button>
      ))}
    </div>
  );
}

export const RewinderIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_613_6)">
        <path
          d="M39.3736 18.7871C29.6388 18.7871 21.7472 26.6698 21.7472 36.3936C21.7472 46.1174 29.6388 54.0001 39.3736 54.0001C49.1084 54.0001 57 46.1174 57 36.3936C57 26.6698 49.1084 18.7871 39.3736 18.7871ZM50.6335 32.3889L38.2561 45.0864C37.7734 45.5817 37.1106 45.8611 36.4186 45.8611H36.4139C35.7202 45.8598 35.0568 45.578 34.5745 45.0798L28.1071 38.3969C27.1226 37.3796 27.1502 35.7579 28.1686 34.7745C29.187 33.7912 30.8106 33.8188 31.7951 34.8359L36.4251 39.6203L46.9584 28.8147C47.9463 27.8011 49.5701 27.7792 50.585 28.7664C51.5999 29.7534 51.6216 31.3752 50.6333 32.3889H50.6335Z"
          fill="white"
        />
        <path
          d="M7.22255 12.7142C7.42565 12.924 7.70963 13.0447 8.00379 13.0452C8.29533 13.0452 8.57907 12.9256 8.78225 12.717L12.4795 8.9242C12.682 8.71647 12.7915 8.44245 12.7875 8.15255C12.7837 7.86265 12.667 7.59163 12.459 7.38945C12.2511 7.18719 11.9759 7.07944 11.6865 7.08183C11.3963 7.08576 11.1249 7.20223 10.9225 7.40996L8.00649 10.4013L6.85299 9.20932C6.4366 8.77913 5.74741 8.76749 5.31667 9.18334C5.10793 9.38483 4.99032 9.65546 4.98539 9.94528C4.98052 10.2352 5.08887 10.5095 5.29058 10.718L7.22247 12.7143L7.22255 12.7142Z"
          fill="white"
        />
        <path
          d="M14.5337 8.69019H29.8265C30.129 8.69019 30.3745 8.93546 30.3745 9.23753V10.889C30.3745 11.1911 30.1289 11.4364 29.8265 11.4364H14.5336C14.2311 11.4364 13.9856 11.1911 13.9856 10.889V9.23761C13.9856 8.93546 14.2311 8.69019 14.5336 8.69019H14.5337Z"
          fill="white"
        />
        <path
          d="M7.22247 21.4668C7.42557 21.6766 7.70955 21.7972 8.00371 21.7978C8.29517 21.7978 8.57892 21.6781 8.78218 21.4696L12.4794 17.6767C12.6819 17.469 12.7913 17.1949 12.7874 16.905C12.7835 16.6151 12.6669 16.3441 12.4589 16.1419C12.2545 15.9431 11.986 15.8342 11.7013 15.8342C11.6963 15.8342 11.6913 15.8342 11.6864 15.8343C11.3961 15.8382 11.1249 15.9547 10.9224 16.1624L8.00649 19.1538L6.85299 17.9618C6.43652 17.5316 5.74741 17.52 5.31667 17.9358C5.10793 18.1373 4.99032 18.4079 4.98539 18.6978C4.98052 18.9877 5.08887 19.2621 5.29058 19.4706L7.22247 21.4669V21.4668Z"
          fill="white"
        />
        <path
          d="M11.6865 24.5866C11.3963 24.5904 11.125 24.707 10.9225 24.9147L8.00654 27.9061L6.85304 26.7141C6.43657 26.284 5.74747 26.2722 5.31672 26.6881C4.88597 27.104 4.87424 27.7925 5.29063 28.2228L7.22252 30.2191C7.42562 30.4289 7.7096 30.5495 8.00369 30.55C8.29515 30.55 8.57889 30.4303 8.78215 30.2218L12.4794 26.429C12.6819 26.2212 12.7913 25.9472 12.7874 25.6573C12.7836 25.3674 12.6669 25.0964 12.4589 24.8942C12.251 24.692 11.9758 24.5815 11.6864 24.5866H11.6865Z"
          fill="white"
        />
        <path
          d="M11.6865 33.3391C11.3963 33.343 11.125 33.4595 10.9225 33.6673L8.00654 36.6586L6.85304 35.4666C6.43657 35.0365 5.74747 35.0247 5.31672 35.4407C4.88597 35.8566 4.87424 36.5451 5.29063 36.9753L7.22252 38.9716C7.42563 39.1814 7.7096 39.302 8.00376 39.3025C8.2953 39.3025 8.57905 39.1829 8.78223 38.9743L12.4795 35.1815C12.682 34.9738 12.7914 34.6998 12.7875 34.4099C12.7836 34.12 12.667 33.849 12.459 33.6468C12.2511 33.4445 11.9759 33.3368 11.6865 33.3391Z"
          fill="white"
        />
        <path
          d="M19.3048 36.3209C19.3048 35.8593 19.3219 35.4017 19.3526 34.9478H14.5337C14.2315 34.9478 13.9857 35.1933 13.9857 35.4952V37.1466C13.9857 37.4485 14.2315 37.694 14.5337 37.694H19.3526C19.3219 37.2402 19.3048 36.7826 19.3048 36.3209Z"
          fill="white"
        />
        <path
          d="M22.0474 26.1953H14.5337C14.2315 26.1953 13.9857 26.4409 13.9857 26.7427V28.3942C13.9857 28.696 14.2315 28.9416 14.5337 28.9416H20.7069C21.0846 27.988 21.5342 27.0709 22.0474 26.1954V26.1953Z"
          fill="white"
        />
        <path
          d="M30.3745 18.3859V17.9903C30.3745 17.6884 30.1287 17.4429 29.8265 17.4429H14.5337C14.2315 17.4429 13.9857 17.6884 13.9857 17.9903V19.6417C13.9857 19.9435 14.2315 20.1891 14.5337 20.1891H27.4441C28.3638 19.5082 29.3438 18.9042 30.3745 18.3859Z"
          fill="white"
        />
        <path
          d="M20.8674 44.0927H2.38702C2.33578 44.0927 2.29419 44.051 2.29419 43.9999V2.38432C2.29419 2.33314 2.33586 2.29159 2.38702 2.29159H32.9727C33.0239 2.29159 33.0655 2.33322 33.0655 2.38432V17.27C33.812 17.0226 34.5773 16.817 35.3597 16.6576V2.38432C35.3597 1.06957 34.2889 0 32.9727 0H2.38702C1.07085 0 0 1.06964 0 2.38432V43.9999C0 45.3147 1.07085 46.3842 2.38702 46.3842H22.0105C21.5829 45.6487 21.2003 44.8837 20.8674 44.0927Z"
          fill="white"
        />
        <path
          d="M40 48C47.1797 48 53 42.4036 53 35.5C53 28.5964 47.1797 23 40 23C32.8203 23 27 28.5964 27 35.5C27 42.4036 32.8203 48 40 48Z"
          fill="white"
        />
        <path
          className="arrow"
          d="M30.5 37.866C29.8333 37.4811 29.8333 36.5189 30.5 36.134L38 31.8038C38.6667 31.4189 39.5 31.9001 39.5 32.6699V41.3301C39.5 42.0999 38.6667 42.5811 38 42.1962L30.5 37.866Z"
          fill="#474747"
        />
        <path
          className="arrow"
          d="M30.5 37.866C29.8333 37.4811 29.8333 36.5189 30.5 36.134L38 31.8038C38.6667 31.4189 39.5 31.9001 39.5 32.6699V41.3301C39.5 42.0999 38.6667 42.5811 38 42.1962L30.5 37.866Z"
          fill="#474747"
        />
        <path
          className="arrow"
          d="M30.5 37.866C29.8333 37.4811 29.8333 36.5189 30.5 36.134L38 31.8038C38.6667 31.4189 39.5 31.9001 39.5 32.6699V41.3301C39.5 42.0999 38.6667 42.5811 38 42.1962L30.5 37.866Z"
          fill="#474747"
        />
        <path
          className="arrow"
          d="M36.5 37.866C35.8333 37.4811 35.8333 36.5189 36.5 36.134L44 31.8038C44.6667 31.4189 45.5 31.9001 45.5 32.6699V41.3301C45.5 42.0999 44.6667 42.5811 44 42.1962L36.5 37.866Z"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_613_6">
          <rect width="57" height="54" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

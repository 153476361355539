import { Message } from "../../message-hub/interfaces";
import { Action } from "./interfaces/action";
import { Trigger } from "./interfaces/trigger";

export const ITTT_PROCESS_ADD = "ittt.process.add";
export const ITTT_TRIGGER_SELECT = "ittt.process.selectTrigger";
export const ITTT_TRIGGER_ADD = "ittt.process.addTrigger";
export const ITTT_ACTION_SELECT = "ittt.process.selectAction";
export const ITTT_ACTION_ADD = "ittt.process.addAction";

export type ITTTProcessAdd = Message<
  typeof ITTT_PROCESS_ADD,
  {
    trigger: Trigger;
    action: Action;
  }
>;

export type ITTTTriggerSelect = Message<
  typeof ITTT_TRIGGER_SELECT,
  { name: string }
>;

export type ITTTTriggerAdd = Message<
  typeof ITTT_TRIGGER_ADD,
  { trigger: Trigger }
>;

export type ITTTTActionSelect = Message<
  typeof ITTT_ACTION_SELECT,
  { name: string }
>;

export type ITTTTActionAdd = Message<
  typeof ITTT_PROCESS_ADD,
  { action: Action }
>;

export type ITTTAppMessages =
  | ITTTProcessAdd
  | ITTTTriggerSelect
  | ITTTTriggerAdd
  | ITTTTActionSelect
  | ITTTTActionAdd;

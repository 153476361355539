import { Badge } from "../badges-engine";

export const updateBadges = (state) => {
  if (Array.isArray(state.BADGES_ENGINE_STORAGE)) return state;

  const badges: Record<string, Badge> = state.BADGES_ENGINE_STORAGE || {};
  const obtainedBadges: string[] = [];
  for (const episode of Object.entries(badges)) {
    for (const badge of Object.values(episode[1])) {
      badge.obtained && obtainedBadges.push(badge.id);
    }
  }
  state.BADGES_ENGINE_STORAGE = obtainedBadges;
  return state;
};

import { asapScheduler, EMPTY, NEVER, Observable, of, timer } from "rxjs";
import {
  concatMap,
  filter,
  observeOn,
  startWith,
  takeUntil,
} from "rxjs/operators";
import {
  CHAT_MESSAGE_SEND,
  CHAT_MESSAGE_TAKE_NEXT,
  CHAT_ROOM_READ,
} from "../apps/chat/messages";
import {
  QUIZ_TIME_CHECK_IF_ANY_QUESTION_AVAILABLE,
  QUIZ_TIME_NO_QUESTIONS_LEFT,
} from "../apps/quiz-time/messages";
import {
  VIDEO_CALL_ACTOR_SAY,
  VIDEO_CALL_END,
  VIDEO_CALL_FINISH,
  VIDEO_CALL_MESSAGE_ACK,
} from "../apps/video-call/messages";
import CONFIG from "../config";
import {
  DECISION_ADD,
  DECISION_CHOOSE,
  DECISION_END,
} from "../core/decision/messages";
import {
  DIRECT_COMMUNICATION_END,
  DIRECT_COMMUNICATION_FINISHED,
  DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT,
  DIRECT_COMMUNICATION_OPEN,
  DIRECT_COMMUNICATION_SEND,
  DIRECT_COMMUNICATION_START,
  DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG,
} from "../core/direct-communication/messages";
import {
  ANIMATION_RUN,
  ANIMATION_STOP,
  MAIN_WAIT,
  NAVIGATION_URL,
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
  PASS_TIME,
  PASS_TIME_FINISH,
  VIDEO_FINISH,
  VIDEO_PLAY,
} from "../core/messages";
import { Message } from "../message-hub/interfaces";

// const messageNoDelay = new Set([GAME_LEVEL_PROGRESS, GAME_CURRENT_QUEST]);
const replayIgnoreTypes = new Set([
  ANIMATION_RUN,
  NOTIFICATION_ADD,
  PASS_TIME,
  NAVIGATION_URL,
  VIDEO_PLAY,
  MAIN_WAIT,
]);
export function handleMessageReplay(
  messages: Observable<Message>
): Observable<Message> {
  return messages.pipe(
    concatMap((m) => {
      if (replayIgnoreTypes.has(m.type)) return EMPTY;
      switch (m.type) {
        case CHAT_MESSAGE_SEND:
          return of(m, {
            type: CHAT_ROOM_READ,
            payload: { id: m.payload.roomId },
          });
        case DIRECT_COMMUNICATION_END:
          return of(m, {
            type: DIRECT_COMMUNICATION_FINISHED,
            payload: true,
          });
        default:
          return of(m);
      }
    })
  );
}

export function handleSpecialMessages(messages: Observable<Message>) {
  return (message: Message) => {
    const waitFor = (condition: (message: Message) => boolean) =>
      NEVER.pipe(
        takeUntil(messages.pipe(observeOn(asapScheduler), filter(condition))),
        startWith(message)
      );
    const p = message.payload;
    switch (message.type) {
      case ANIMATION_RUN:
        return waitFor((m) => m.type === ANIMATION_STOP);
      case DECISION_ADD:
        const decisionId = message.payload.id;
        return waitFor((m) => {
          return (
            (m.type === DECISION_END && m.payload === decisionId) ||
            (m.type === DECISION_CHOOSE && m.payload.decision === decisionId)
          );
        });
      case NOTIFICATION_ADD:
        return waitFor((m) => m.type === NOTIFICATION_REMOVE);
      case DIRECT_COMMUNICATION_END:
        if (message.payload.requireConfirm)
          return waitFor((m) => m.type === DIRECT_COMMUNICATION_FINISHED);
        return of(message);
      case VIDEO_CALL_END:
        return waitFor((m) => m.type === VIDEO_CALL_FINISH);
      case PASS_TIME:
        return waitFor((m) => m.type === PASS_TIME_FINISH);
      case VIDEO_CALL_ACTOR_SAY:
        return waitFor((m) => m.type === VIDEO_CALL_MESSAGE_ACK);
      case VIDEO_PLAY:
        return waitFor((m) => m.type === VIDEO_FINISH);
      case DIRECT_COMMUNICATION_START:
        if (p.autostart) return of(message);
        return waitFor(
          (m) =>
            m.type === DIRECT_COMMUNICATION_OPEN ||
            m.type === DIRECT_COMMUNICATION_FINISHED
        );
      case DIRECT_COMMUNICATION_SEND:
        if (!p.next) return of(message);
        const ackDirectCommunication = waitFor(
          (m: any) =>
            m.type === DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT ||
            m.type === DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG ||
            m.type === DIRECT_COMMUNICATION_FINISHED
        );
        return p.autoNext
          ? ackDirectCommunication.pipe(takeUntil(timer(3000)))
          : ackDirectCommunication;
      case CHAT_MESSAGE_SEND:
        const chatRoomId = p.room;
        const ackChatMessageSend = p.next
          ? waitFor(
              (m: any) =>
                m.type === CHAT_MESSAGE_TAKE_NEXT &&
                m.payload.roomId === chatRoomId
            )
          : of(message);
        return p.autoNext
          ? ackChatMessageSend.pipe(takeUntil(timer(3000)))
          : ackChatMessageSend;
      case MAIN_WAIT:
        return NEVER.pipe(
          takeUntil(
            timer(
              typeof CONFIG.SCRIPT_WAIT_FIXED === "number"
                ? Number(CONFIG.SCRIPT_WAIT_FIXED)
                : p.seconds * 1000,
              1
            )
          )
        );
      case QUIZ_TIME_CHECK_IF_ANY_QUESTION_AVAILABLE:
        return waitFor((m) => m.type === QUIZ_TIME_NO_QUESTIONS_LEFT);
      default:
        return of(message);
    }
  };
}

import { CompetenceAreaTree } from "core/interfaces";
import { createContext } from "react";

export type DashboardContextProps = {
  filledTags: string[];
  setFilledTags: (data: string[]) => void;
  availableTags: number;
  departmentsFilled: boolean;
  setDepartmentsFilled: (data: boolean) => void;
  playerSettingsFilled: boolean;
  setPlayerSettingsFilled: (data: boolean) => void;
  colleagueInviteLinkActivated: boolean;
  setColleagueInviteLinkActivated: (data: boolean) => void;
  dashboardTourFinished: boolean;
  setDashboardTourFinished: (data: boolean) => void;
  competenceAreaTree: CompetenceAreaTree[];
  setCompetenceAreaTree: (data: CompetenceAreaTree[]) => void;
};

export const DashboardContext = createContext<DashboardContextProps>({
  filledTags: [],
  setFilledTags: (_: string[]) => [],
  availableTags: 0,
  departmentsFilled: false,
  setDepartmentsFilled: (_: boolean) => false,
  playerSettingsFilled: false,
  setPlayerSettingsFilled: (_: boolean) => false,
  colleagueInviteLinkActivated: false,
  setColleagueInviteLinkActivated: (_: boolean) => false,
  dashboardTourFinished: false,
  setDashboardTourFinished: (_: boolean) => false,
  competenceAreaTree: [],
  setCompetenceAreaTree: (_: CompetenceAreaTree[]) => [],
});

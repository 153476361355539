import { ChevronLeft, ChevronRight, Euro, Place } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SchoolIcon from "@mui/icons-material/School";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { postEvents } from "core/client";
import { TrainingRecommendation } from "core/interfaces";
import { getItem, setItem } from "core/storage";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import { useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const STORAGE_KEY = "trainingRecommendationsLinksOpened";

export const TrainingRecommendations = ({
  trainingList,
}: {
  trainingList: TrainingRecommendation[];
}) => {
  return (
    <div className="animate__animated animate__fadeIn">
      {trainingList.length === 0 && (
        <Card
          sx={{
            background: "linear-gradient(#00B2E3, #000)",
            height: 250,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <StyledSchoolIcon />
            <Typography variant="h6" sx={{ mb: 2 }} align="center">
              <FormattedMessage id="training.recommendations.noTraining" />
            </Typography>
          </CardContent>
        </Card>
      )}
      {trainingList.length === 1 && <TraningCard training={trainingList[0]} />}
      {trainingList.length > 1 && (
        <Slide
          slidesToShow={1}
          infinite={false}
          autoplay={false}
          prevArrow={
            <IconButton
              sx={{
                mr: "-40px",
                "&.Mui-disabled": { pointerEvents: "auto" },
              }}
            >
              <ChevronLeft />
            </IconButton>
          }
          nextArrow={
            <IconButton
              sx={{
                ml: "-40px",
                "&.Mui-disabled": { pointerEvents: "auto" },
              }}
            >
              <ChevronRight />
            </IconButton>
          }
        >
          {trainingList.map((training, index) => (
            <TraningCard training={training} key={index} />
          ))}
        </Slide>
      )}
    </div>
  );
};

const TraningCard = ({ training }: { training: TrainingRecommendation }) => {
  const intl = useIntl();

  const [linksOpened, setLinksOpened] = useState<string[]>(
    getItem(STORAGE_KEY) || []
  );

  const handleLinkOpen = (url: string) => {
    if (!linksOpened.includes(url)) {
      const newLinksOpened = [...linksOpened, url];
      setLinksOpened(newLinksOpened);
      setItem(STORAGE_KEY, newLinksOpened);
    }

    postEvents([
      {
        eventTypeId: "training.recommendations.opened",
        url,
      },
    ]);
    window.open(url, "_blank");
  };

  return (
    <Card
      data-testid="training-recommendation-card"
      sx={{
        background: "linear-gradient(#00B2E3, #000)",
        height: 250,
      }}
    >
      <CardActionArea
        onClick={() => handleLinkOpen(training.url)}
        sx={{ height: "100%" }}
      >
        <CardHeader
          title={training.courseName}
          subheader={training.providerName}
          subheaderTypographyProps={{ noWrap: true }}
          titleTypographyProps={{
            noWrap: true,
          }}
          sx={{
            "& .MuiCardHeader-content": {
              display: "grid",
            },
          }}
        />
        <CardContent
          sx={{
            mr: 3,
            ml: 3,
            height: 110,
          }}
        >
          <Typography sx={textElipsis}>{training.description}</Typography>
        </CardContent>
        <CardActions>
          <Chip
            icon={<AccessTimeIcon />}
            label={getTraningHours(training.durationHours)}
          />
          <Chip icon={<Place />} label={getTrainingType(training.type, intl)} />
          <Chip icon={<Euro />} label={getTrainingCost(training.cost, intl)} />
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

const textElipsis = {
  display: "-webkit-box",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

/*
  Based on https://github.com/date-fns/date-fns/issues/2134
  This works only for languages that have h and min for hours
  and minutes respectively.
*/
const getTraningHours = (inputHours: number): string | undefined => {
  const formatDistanceLocale = {
    xMinutes: "{{count}} min",
    xHours: "{{count}} h",
  };
  const shortLocale = {
    formatDistance: (token: string, count: number) =>
      formatDistanceLocale[token].replace("{{count}}", count),
  };
  const hoursInMilliseconds = inputHours * 60 * 60 * 1000;
  const duration = intervalToDuration({
    start: 0,
    end: hoursInMilliseconds,
  });

  return formatDuration(duration, {
    format: ["hours", "minutes"],
    locale: shortLocale,
  });
};

const getTrainingCost = (cost: number, intl: IntlShape): string => {
  if (cost === 0) {
    return intl.formatMessage({ id: "dashboard.recommendations.free" });
  }

  return intl
    .formatNumber(cost, { style: "currency", currency: "EUR" })
    .replace("€", "");
};

const getTrainingType = (
  type: TrainingRecommendation["type"],
  intl: IntlShape
): string => {
  return type === "FACE_TO_FACE"
    ? intl.formatMessage({
        id: "dashboard.recommendations.trainingType.faceToFace",
      })
    : intl.formatMessage({
        id: "dashboard.recommendations.trainingType.online",
      });
};

const StyledSchoolIcon = styled(SchoolIcon)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: 110,
  opacity: "0.7",
}));

import { Box, styled, Typography } from "@mui/material";
import { Badge } from "game-engine/badges-engine";
import { FormattedMessage } from "react-intl";
import DashboardTooltip from "./tooltip";

export const BadgeItem = ({ badge }: { badge: Badge }) => {
  return (
    <DashboardTooltip
      enterDelay={500}
      enterNextDelay={500}
      title={
        <Typography variant="body1">
          <FormattedMessage id={`badges.description.${badge.id}`} />
        </Typography>
      }
    >
      <Box
        data-e2e-badge={badge.name}
        data-e2e-badge-obtained={badge.obtained ? "true" : "false"}
        sx={{
          width: 120,
          textAlign: "center",
          opacity: badge.obtained ? 1 : 0.2,
        }}
      >
        <BadgeImage src={badge.img} alt={badge.name} />
        <Typography
          variant="body1"
          sx={{
            wordBreak: "break-word",
          }}
        >
          <FormattedMessage id={`badges.${badge.id}`} />
        </Typography>
      </Box>
    </DashboardTooltip>
  );
};

const BadgeImage = styled("img")(() => ({
  width: 100,
  height: 100,
}));

import { Observable, race } from "rxjs";
import { filter, find, map, take } from "rxjs/operators";
import allMessages from "../core/all-messages";
import { getReportData, postEvents } from "../core/client";
import {
  COMPETENCE_SCORE_UPDATE,
  TEST_COMPLETE,
  TEST_STOP,
} from "../core/messages";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { TestOutcome } from "./interfaces";
import { SCRIPT_COMPLETE, SCRIPT_REPLAY_DONE } from "./messages";

export class TestsEngine {
  private failedUploadResults: Message[] = [];
  testResults: Record<string, TestOutcome> = {};
  private uploadActive = false;

  constructor(
    private messages: Observable<Message>,
    private seasonId: string,
    private episodeId: string
  ) {
    this.updateCompetenceScore();
    this.startResultsUploader(messages);
  }

  startResultsUploader(messages: Observable<Message>) {
    messages
      .pipe(find((msg) => msg.type === SCRIPT_REPLAY_DONE))
      .subscribe(() => (this.uploadActive = true));
  }

  private uploadResult(msg: Message) {
    const message = {
      ...msg,
      result: {
        id: msg.result?.id || "",
        value: msg.result?.value !== undefined ? msg.result?.value : 0,
      },
    };
    const results = [...this.failedUploadResults, message];

    console.log("uploadResult", results, this.seasonId, this.episodeId);

    postEvents(results, this.seasonId, this.episodeId)
      .then(() => {
        this.failedUploadResults = [];
        this.updateCompetenceScore();
      })
      .catch((err) => {
        console.error(err);
        this.failedUploadResults = results;
      });
  }

  hasFailedResultUploads(): boolean {
    return this.failedUploadResults.length > 0;
  }

  private async updateCompetenceScore(): Promise<void> {
    const reportData = await getReportData();
    if (Array.isArray(reportData)) {
      const competenceScore = reportData.reduce(
        (acc, cur) => {
          acc.totalPassed = acc.totalPassed + cur.totalPassed;
          acc.totalTried = acc.totalTried + cur.totalTried;
          return acc;
        },
        { totalPassed: 0, totalTried: 0 }
      );

      MessageHub.send({
        type: COMPETENCE_SCORE_UPDATE,
        payload: {
          totalPassed: competenceScore.totalPassed,
          totalTried: competenceScore.totalTried,
        },
      });
    } else {
      console.error("reportData is not an Array", reportData);
    }
  }

  startTest = (testId, testFn) => {
    const testOperator = testFn(allMessages);
    const operator = (msg$: Observable<Message>) =>
      race(
        msg$.pipe(testOperator),
        msg$.pipe(
          filter((msg) => {
            return (
              msg.type === SCRIPT_COMPLETE ||
              (msg.type === TEST_STOP && msg.payload.test === testId)
            );
          }),
          take(1),
          map((msg) => ({
            failed: true,
            timestamp: msg?.timestamp || Date.now(),
          }))
        )
      ) as Observable<TestOutcome>;

    return this.messages.pipe(operator).subscribe((outcome) => {
      if (outcome) this.onTestResult(outcome, testId);
    });
  };

  private onTestResult(outcome, testId: string) {
    const msg: Message = {
      type: TEST_COMPLETE,
      payload: {
        test: testId,
        data: outcome.data || {},
        failed: !!outcome.failed,
        timestamp: outcome.timestamp,
      },
      result: {
        id: testId,
        value: outcome.failed ? 0 : 1,
      },
    };

    const { test, failed, data } = msg.payload;
    const timestamp = msg.timestamp || Date.now();
    this.testResults[test] = {
      failed,
      data,
      timestamp,
    };

    MessageHub.sendEpisode(msg);

    if (this.uploadActive) {
      this.uploadResult(msg);
    }
  }
}

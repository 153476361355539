import { Cancel } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Theme,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CONFIG from "../../config";
import MessageHub from "../../message-hub";
import { updateAppLayout, useDeviceDetect } from "../hooks";
import { Application } from "../interfaces";
import { APP_CLOSED } from "../messages";
import { getItem, setItem } from "../storage";
import styles from "./styles";
import appBarTheme from "./theme";

export type AppLayoutPropsType = {
  app?: Application;
  content: JSX.Element;
  contentColor?: string;
  header?: JSX.Element;
  headerColor?:
    | string
    | "default"
    | "inherit"
    | "primary"
    | "secondary"
    | "transparent";
  headerWithoutShadow?: boolean;
  theme?: Theme;
  disableTopHeaderPadding?: boolean;
  desktopMode?: "none" | "modal" | "panel";
  hideExitButton?: boolean;
  showHeaderWithExit?: boolean;
  exitButtonColor?: string;
  onClose?: () => void;
  searchBar?: JSX.Element;
  border?: string;
  state?: string;
  closeCallback?: () => void;
  customRootStyles?: React.CSSProperties;
};

export const LAYOUT_STORAGE_KEY = "APP_LAYOUT_CONFIG";

const AppLayout: React.FC<AppLayoutPropsType> = (props: AppLayoutPropsType) => {
  const {
    app,
    content,
    contentColor,
    header,
    headerColor,
    headerWithoutShadow,
    theme,
    disableTopHeaderPadding,
    desktopMode,
    showHeaderWithExit,
    exitButtonColor,
    hideExitButton,
    onClose,
    searchBar,
    border,
    state,
    closeCallback,
    customRootStyles,
  } = props;
  const classes = styles({
    color: contentColor,
    border: border,
    height: state === "spamfighter" ? "60vh" : "",
  });
  const history = useHistory();
  const device = useDeviceDetect();
  const [appAlreadyUsed, setAppAlreadyUsed] = useState<boolean | null>(null);
  const appRef = useRef<any>(null);

  useEffect(() => {
    let config = getItem(LAYOUT_STORAGE_KEY);

    if (!config) {
      config = {};
    }

    if (app && app.splash) {
      if (!config[app.id]) {
        setAppAlreadyUsed(false);

        const timeout = setTimeout(() => {
          config[app.id] = true;
          setAppAlreadyUsed(true);
          setItem(LAYOUT_STORAGE_KEY, config);
        }, 3000);

        return () => clearTimeout(timeout);
      } else {
        setAppAlreadyUsed(true);
      }
    } else {
      if (app && !config[app.id]) {
        config[app.id] = true;
        setItem(LAYOUT_STORAGE_KEY, config);
      }
      setAppAlreadyUsed(true);
    }
  }, [app]);

  useEffect(() => {
    if (appRef.current !== null) {
      const el = appRef.current;
      const left = el.offsetLeft;
      const right = window.innerWidth - (el.offsetLeft + el.offsetWidth);
      updateAppLayout({
        appLeft: left,
        appRight: right,
      });
      return () => updateAppLayout({ appLeft: 0, appRight: 0 });
    }
  }, [appRef.current, window.innerWidth, desktopMode]);

  const mode: "none" | "modal" | "panel" = !!desktopMode
    ? desktopMode
    : "modal";

  const getRootClasses = () => {
    const root = classes.root;
    const modalView =
      device.browser && mode === "modal" ? classes.modalView : "";
    const panelView =
      device.browser && mode === "panel" ? classes.panelView : "";
    const dialogView =
      device.mobile && state && state === "spamfighter"
        ? classes.dialogView
        : "";
    const rounded = device.browser ? classes.rounded : "";

    return `${rounded} ${root} ${modalView} ${panelView} ${dialogView}`;
  };

  const close = () => {
    if (onClose) onClose();

    if (!state || state !== "spamfighter") {
      history.push("/");
    }

    if (closeCallback) {
      closeCallback();
    }

    if (app) {
      MessageHub.send({
        type: APP_CLOSED,
        payload: {
          appId: app.id,
        },
      });
    }
  };

  const getContent = () => {
    return (
      <div ref={appRef} className={getRootClasses()} style={customRootStyles}>
        <ThemeProvider theme={appBarTheme}>
          {device.mobile && !state && (
            <AppBar
              position="static"
              style={{
                paddingTop: disableTopHeaderPadding ? 0 : "40px",
                background: headerColor || "transparent",
                backdropFilter: "blur(3px)",
                top: 0,
                left: 0,
                right: 0,
              }}
              elevation={headerWithoutShadow ? 0 : 4}
            >
              {header}
            </AppBar>
          )}

          {(device.browser && mode !== "none") ||
          (device.mobile && state && state === "spamfighter") ? (
            <AppBar
              position="relative"
              className={
                !header
                  ? state === "spamfighter"
                    ? classes.sfBrowserAppBar
                    : classes.browserAppBar
                  : ""
              }
              style={{
                background: headerColor || "transparent",
              }}
            >
              {!header && showHeaderWithExit ? (
                <Toolbar className={classes.browserToolbar}>
                  {hideExitButton ? (
                    <></>
                  ) : (
                    <IconButton
                      data-e2e-home-button
                      id="homeButton"
                      className={classes.browserToolbarExitButton}
                      style={{ color: exitButtonColor || "black" }}
                      onClick={close}
                      size="large"
                    >
                      <Cancel />
                    </IconButton>
                  )}
                </Toolbar>
              ) : hideExitButton ? (
                <></>
              ) : (
                <div
                  className={classes.browserToolbarExitButtonAbsoluteWrapper}
                >
                  <IconButton
                    data-e2e-home-button
                    id="homeButton"
                    className={classes.browserToolbarExitButtonAbsolute}
                    style={{ color: exitButtonColor || "white" }}
                    onClick={close}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                </div>
              )}

              {header}
            </AppBar>
          ) : null}
        </ThemeProvider>
        <div
          data-app-name={props.app?.id}
          className={`content ${classes.content}`}
        >
          {content}
        </div>
        <div data-app-name={props.app?.id} className="decision-spacer"></div>

        {(device.mobile || (device.browser && mode === "panel")) && (
          <div className={classes.footer}></div>
        )}
      </div>
    );
  };

  const splashScreenClasses = () => {
    const splashMobile = device.mobile ? classes.splashMobile : "";
    const modalView =
      device.browser && mode === "modal" ? classes.splashModal : "";
    const panelView =
      device.browser && mode === "panel" ? classes.splashPanel : "";

    return `${splashMobile} ${modalView} ${panelView}`;
  };

  return (
    <>
      {app &&
      app.splash &&
      appAlreadyUsed === false &&
      CONFIG.ANIMATIONS_ENABLED ? (
        <div className={classes.splashScreenContainer}>
          <div className={splashScreenClasses()}>
            {searchBar && (
              <div className={classes.splashScreenSearchBar}>
                {searchBar}
                {device.browser && (
                  <IconButton
                    data-e2e-home-button
                    id="homeButton"
                    className={classes.browserToolbarExitButton}
                    style={{
                      color: exitButtonColor || "black",
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                    onClick={close}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                )}
              </div>
            )}
            <app.splash />
          </div>
        </div>
      ) : (
        <>
          {theme ? (
            <ThemeProvider theme={theme}>{getContent()}</ThemeProvider>
          ) : (
            getContent()
          )}
        </>
      )}
    </>
  );
};

export default AppLayout;

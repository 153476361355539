import { Check, FastRewind, PlayArrow } from "@mui/icons-material";
import { StepIconProps } from "@mui/material/StepIcon";
import { makeStyles } from "@mui/styles";
import { useDeviceDetect } from "../hooks";

const styles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.5,
  },
  active: {
    background: "#00B2E2",
    opacity: 1,
  },
  completed: {
    background: "#00B2E2",
    opacity: 1,
  },
  rewind: {
    background: "#FFB400",
    opacity: 1,
  },
  skipped: {
    background: "red",
    opacity: 1,
  },
});

const QuestStepIcon = (props: StepIconProps) => {
  const classes = styles();
  const { active, completed, error, style } = props;
  const device = useDeviceDetect();
  const rewindReady = completed || active;

  const getIcon = () => {
    if (error) {
      return <Check />;
    }
    if (device.mobile && rewindReady) {
      return <FastRewind className={classes.rewind} />;
    }
    if (style?.background && rewindReady) {
      return <FastRewind className={classes.rewind} />;
    }
    if (active) {
      return <PlayArrow />;
    }
    return <Check />;
  };

  const getClasses = () => {
    if (error) {
      return [classes.root];
    }
    if (device.mobile && completed) {
      return [classes.root, classes.rewind];
    }
    if (active) {
      return [classes.root, classes.active];
    }
    if (completed) {
      return [classes.root, classes.completed];
    }
    return [classes.root];
  };

  return (
    <div
      className={getClasses().join(" ")}
      style={rewindReady && !error ? style : {}}
    >
      {getIcon()}
    </div>
  );
};

export default QuestStepIcon;

import { Observable, Subscription } from "rxjs";
import { GAME_CURRENT_QUEST, GAME_QUESTS_UPDATE } from "../core/messages";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { SCRIPT_QUESTS, SCRIPT_REPLAY_DONE, SCRIPT_STEPS } from "./messages";

export interface RewindRef {
  id: string;
  stepId: string;
}

export interface Quest {
  id: string;
  description: string;
}

export class GameRewinder {
  private subscription?: Subscription;
  private stepIds: string[] = [];
  private rewindRefs: Record<string, RewindRef> = {};
  private quests: Quest[] = [];

  private onMessage = (m: Message) => {
    switch (m.type) {
      case SCRIPT_STEPS:
        this.stepIds = m.payload;
        break;
      case SCRIPT_QUESTS:
        this.quests = m.payload;
        this.notifyChanges();
        break;
      case GAME_CURRENT_QUEST:
        this.rewindRefs[m.payload.title] = {
          id: m.payload.title,
          stepId: m.stepId ?? "",
        };
        this.notifyChanges();
        break;
      case SCRIPT_REPLAY_DONE:
        setTimeout(() => this.notifyChanges());
        break;
      default:
        break;
    }
  };

  init(o: Observable<Message>) {
    this.subscription?.unsubscribe();
    this.stepIds = [];
    this.rewindRefs = {};
    this.subscription = o.subscribe(this.onMessage);
  }

  private notifyChanges = () => {
    const filteredQuests = this.quests;
    MessageHub.send({
      type: GAME_QUESTS_UPDATE,
      payload: {
        quests: filteredQuests,
        refs: this.rewindRefs,
      },
    });
  };

  getRef(rewindId: string): [string, Set<string>] {
    const ref = this.rewindRefs[rewindId];
    if (ref === undefined) {
      throw new Error(`No rewind reference with id ${rewindId}`);
    }
    return this.getRewindInfo(ref.stepId);
  }

  getRewindInfo(stepId: string): [string, Set<string>] {
    const index = this.stepIds.indexOf(stepId);
    if (index < 1) throw Error("step id not found");
    const discardedStepIds = new Set(
      this.stepIds.slice(index, this.stepIds.length - 1)
    );
    const savedStep = this.stepIds[index - 1];
    return [savedStep, discardedStepIds];
  }
}

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 40,
    backgroundColor: "rgba(10, 10, 10, 0.2)",
    backdropFilter: "blur(3px)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    zIndex: theme.custom.zIndex.toolbar,
  },
}));

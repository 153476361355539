import React from "react";
export const backgroundDay = (plantColor) => (
  <>
    <style>
      {`.prefix__st0{fill:#f6f6f6}.prefix__st2{fill:#a9b7c0}.prefix__st3{fill:#a2b2b8}.prefix__st5{fill:#e3fffb}.prefix__st6{fill:#caf0f7}.prefix__st7{fill:#b4c3ca}.prefix__st12{fill:#9d9d9c}.prefix__st13{fill:#727674}.prefix__st14{fill:#828a89}.prefix__st15{fill:#909e9a}.prefix__st17{fill:#313432}.prefix__st18{fill:#423e3b}.prefix__st19{fill:#363230}.prefix__st20{fill:#6b6460}.prefix__st25{fill:#dadada}.prefix__st28{fill:#535a54}.prefix__st31{fill:#918c89}.prefix__st32{fill:#ededed;stroke:#c6c6c6;stroke-miterlimit:10}.prefix__st33{fill:#b2b2b2}.prefix__st34{fill:${plantColor.mid}}.prefix__st35{fill:${plantColor.dark}}.prefix__st36{fill:${plantColor.light}}.prefix__st37{fill:#e3e3e3}`}
    </style>
    <g id="prefix___x31_">
      <path className="prefix__st0" d="M280.94 84h1117.98v917H280.94z" />
      <path
        fill="#b4c4cc"
        d="M284.1 989.47L0 1138.47V1200h1674v-73.06l-264.09-137.47z"
      />
      <path
        className="prefix__st2"
        d="M286.05 63.99L76.23 0H0v1140.18l290-153.51z"
      />
      <path
        className="prefix__st3"
        d="M289.33 970.67L0 1122.07v18.19l292.5-154.59z"
      />
      <path
        className="prefix__st2"
        d="M1411.43 989.47L1674 1130.96V0h-58.81l-203.76 63.99z"
      />
      <path
        className="prefix__st3"
        d="M1405.75 986.75L1674 1131.06v-18.04l-262.57-138.27z"
      />
      <path fill="#b4c5cc" d="M1411.43 63.99L1664.39 0H29.62l256.43 63.99z" />
      <path
        className="prefix__st5"
        d="M295.32 554.55h58.54v225.17h-58.54zm625.42-9.72h64.22v268.42h-64.22z"
      />
      <path
        className="prefix__st5"
        d="M973.12 631.32h79.54v240h-79.54zm130.56-141.95h53.41v270.77h-53.41zm59.79 44.62h58.77v270.77h-58.77zm66.43-66.15h24.28v272.3h-24.28z"
      />
      <path className="prefix__st5" d="M1246.51 478.61h24.27v272.3h-24.27z" />
      <path
        className="prefix__st5"
        d="M1254.18 564.77h60.04v150.76h-60.04zm-117.53 10.76h60.04v150.76h-60.04zm-68.99 16.92h60.04v150.76h-60.04z"
      />
      <path
        className="prefix__st5"
        d="M1195.41 597.07h60.04v150.76h-60.04zm177.58 110.77h-47.27V500.15h42.83l.04 6.99 4.4-.04z"
      />
      <path
        className="prefix__st5"
        d="M1355.1 566.3h41.01v400.68h-41.01zm-67.71 32.31h63.08V806.3h-63.08zm-957.36 40.72h43.33v194.12h-43.33z"
      />
      <path
        className="prefix__st5"
        d="M368.97 612.05h43.33v194.12h-43.33zm62.24-15.69h43.33v194.12h-43.33z"
      />
      <path
        className="prefix__st5"
        d="M393.1 553.35h40.58v202.43H393.1zm53.44-46.26h55.99v354.54h-55.99zm293.38 65.75h42.56V772.8h-42.56z"
      />
      <path className="prefix__st5" d="M688.27 596.13h85.12v199.96h-85.12z" />
      <path
        className="prefix__st5"
        d="M647.46 612.76h85.12v169.26h-85.12zm92.57 15.44h120.42v209.01H740.03z"
      />
      <path
        className="prefix__st5"
        d="M645.42 478.91h66.95v265.1h-66.95zm114.9 30.92h44.31v205.85h-44.31zm-175.4 301.44h48.62V360.42l-41.47-.16-.04 6.26-7.11-.02zm-74.1-23.83h61.37V454.31l-41.04-18.84-20.33 6.26zm328.42-183.42h60.81v198.57h-60.81z"
      />
      <path
        className="prefix__st5"
        d="M890.42 619.38h52.82v172.47h-52.82zm-85.79 13.12h85.12v199.96h-85.12z"
      />
      <path className="prefix__st5" d="M295.32 741.23h1095.42v225.75H295.32z" />
      <path
        className="prefix__st5"
        d="M546.8 523.8h62.49v284.29H546.8zm163.71 126.44h33.07v50.21h-33.07z"
      />
      <path
        className="prefix__st5"
        d="M603.53 506.26h70.35v309.43h-70.35zm435.92 181.35h354.46v138.94h-354.46zM490.11 576.7h43.99v182.98h-43.99zm160.7-103.22h59.2v13.81h-59.2zm-203.13 30.04h53.46v11.86h-53.46z"
      />
      <path
        className="prefix__st5"
        d="M450.71 498.98h26.33v10.48h-26.33zm-55.21 49.13h26.33v10.48H395.5zm-24.73 59.66h26.33v12.86h-26.33zm-52.61-81.07h20.37v12.86h-20.37zm340.37-57.79h20.37v12.86h-20.37zm104.68 34.8h20.37v12.86h-20.37zm160.35 37.13h39.36v12.86h-39.36zm-82.79 59.97h39.35v12.86h-39.35z"
      />
      <path
        className="prefix__st5"
        d="M842.59 596.13h35.33v12.86h-35.33zM917.16 564h35.33v8.19h-35.33zm0 14.44h35.33v8.19h-35.33zm0 14.43h35.33v8.2h-35.33zm0 14.44h35.33v8.2h-35.33zm82.8 21.19h35.33v8.2h-35.33z"
      />
      <path className="prefix__st5" d="M1001.57 619.88h43.56v11.69h-43.56z" />
      <path
        className="prefix__st5"
        d="M1003.17 624.77h43.57v11.69h-43.57zm162.86-94.06h43.56v11.69h-43.56zm-58.85-44.45h40.13v11.69h-40.13zm121.01-14.21h23.9v134.62h-23.9zm98.69 25.39h23.9v13.99h-23.9zm-61.76 60.78h33.73v13.53h-33.73z"
      />
      <path className="prefix__st5" d="M1275.02 538.5h17.4v34.34h-17.4z" />
      <path
        className="prefix__st5"
        d="M1276.62 535.7h13.73v34.34h-13.73zm90.8 24.45h13.73v34.33h-13.73zm-922.3 26.86h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.81h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zM609.23 513.88h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.14h-26.33zm0-5.8h26.33V460h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zM609.23 438.61h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zM1097.68 642.05h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.81h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zM1097.68 566.78h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.14h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.8h26.33v4.13h-26.33zm0-5.81h26.33v4.13h-26.33zM297.52 534.8h45.96v21.54h-45.96zm212.18-88.94h4.95v142.02h-4.95z"
      />
      <path
        className="prefix__st6"
        d="M1196.25 696.78h84.58v232.47h-84.58zm-445.9-5.56h92.79v276.44h-92.79z"
      />
      <path
        className="prefix__st6"
        d="M652.54 780.52h114.92v187.15H652.54zM501.68 633.97h77.16v279.54h-77.16zm-94.13 46.06h84.9v279.54h-84.9zm-46.15-68.29h35.07v281.12H361.4z"
      />
      <path className="prefix__st6" d="M337.41 622.86h35.07v281.12h-35.07z" />
      <path
        className="prefix__st6"
        d="M295.32 711.81h66.08v155.64h-66.08zm149.14 11.11h86.75v155.65h-86.75zm99.67 17.47h86.75v155.65h-86.75z"
      />
      <path
        className="prefix__st6"
        d="M359.55 745.16h86.75v155.65h-86.75zm-64.23 1.58h18.09v214.42h-18.09zm747.57-26.6h61.49v206.45h-61.49z"
      />
      <path className="prefix__st6" d="M1056.02 744.19H1179v206.44h-122.98z" />
      <path
        className="prefix__st6"
        d="M1114.97 761.35h122.98v174.74h-122.98zm-184.71 15.94h173.97v190.37H930.26z"
      />
      <path
        className="prefix__st6"
        d="M1144.18 700.75h96.73v266.92h-96.73zm-133.29-45.66h64.01v212.52h-64.01zm317.43 312.58V715.99l-10.28.02-.05-6.46-59.92.17v257.95zm67.79 0V659.15l-49.4 16.21v292.31zM873.03 752.33h87.86v205.01h-87.86z"
      />
      <path
        className="prefix__st6"
        d="M810.64 768.19h76.31v178.06h-76.31zm77.26 13.55h122.99v185.93H887.9z"
      />
      <path className="prefix__st6" d="M295.32 893.98h1100.79v73.68H295.32z" />
      <path
        className="prefix__st6"
        d="M1293.11 747.1h90.28v220.57h-90.28zm-194.01 52.94h47.78v51.84h-47.78z"
      />
      <path
        className="prefix__st6"
        d="M1199.78 728.99h101.65v238.67h-101.65zM295.32 838.63h376.3v129.04h-376.3zm852.28-143.49h85.53v14.25h-85.53zm70.81-27.11h29.42v13.27h-29.42z"
      />
      <path
        className="prefix__st6"
        d="M1192.54 690.42h29.42v13.28h-29.42zm-151.23-41.64h29.42v13.28h-29.42zm-259.1 38.32h56.86v13.27h-56.86zm119.62 61.92h56.85v13.27h-56.85z"
      />
      <path
        className="prefix__st6"
        d="M904.99 744.19h51.05v13.28h-51.05zm-107.73-33.17h51.05v8.46h-51.05zm0 14.9h51.05v8.46h-51.05zm0 14.9h51.05v8.46h-51.05zm0 14.91h51.05v8.46h-51.05zM677.63 777.6h51.04v8.46h-51.04z"
      />
      <path className="prefix__st6" d="M663.42 768.71h62.94v12.06h-62.94z" />
      <path
        className="prefix__st6"
        d="M661.11 773.75h62.94v12.06h-62.94zm-235.3-97.11h62.94v12.06h-62.94zm89.98-45.89h57.98v12.07h-57.98zm-151.36-14.66h34.52v138.99h-34.52zm-67.58 88.95h48.74v13.97h-48.74z"
      />
      <path className="prefix__st6" d="M306.16 684.69h25.13v35.45h-25.13z" />
      <path
        className="prefix__st6"
        d="M309.14 681.8h19.84v35.45h-19.84zm387.1 285.87l-.37-247.82-80.89.12.37 247.7z"
      />
      <path
        transform="translate(-1.1 1)"
        className="prefix__st6"
        d="M617 716.23h77.23v12.24H617z"
      />
      <path className="prefix__st6" d="M652.08 711.47h38.04v10.81h-38.04z" />
      <path
        transform="translate(-1.25 1.06)"
        className="prefix__st6"
        d="M660 802.38h38.04v4.27H660z"
      />
      <path
        transform="translate(-1.24 1.06)"
        className="prefix__st6"
        d="M659.99 796.4h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.23 1.06)"
        className="prefix__st6"
        d="M659.98 790.4h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.22 1.06)"
        className="prefix__st6"
        d="M659.97 784.42h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.21 1.06)"
        className="prefix__st6"
        d="M659.97 778.42h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.2 1.06)"
        className="prefix__st6"
        d="M659.96 772.44H698v4.27h-38.04z"
      />
      <path
        transform="translate(-1.2 1.05)"
        className="prefix__st6"
        d="M659.95 766.44h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.18 1.06)"
        className="prefix__st6"
        d="M659.94 760.46h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.17 1.05)"
        className="prefix__st6"
        d="M659.93 754.47h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.17 1.06)"
        className="prefix__st6"
        d="M659.92 748.48h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.16 1.06)"
        className="prefix__st6"
        d="M659.91 742.49h38.04v4.27h-38.04z"
      />
      <path
        transform="translate(-1.15 1.06)"
        className="prefix__st6"
        d="M659.9 736.5h38.04v4.27H659.9z"
      />
      <path
        transform="translate(-1.14 1.06)"
        className="prefix__st6"
        d="M659.89 730.51h38.04v4.27h-38.04z"
      />
      <path
        className="prefix__st6"
        d="M1255.15 736.85h38.05v4.27h-38.05zm0-5.99h38.05v4.27h-38.05zm0-5.98h38.05v4.27h-38.05zm0-5.99h38.05v4.27h-38.05zm0-5.99h38.05v4.27h-38.05z"
      />
      <path
        className="prefix__st6"
        d="M1255.15 724.68h38.05v4.27h-38.05zm0-5.99h38.05v4.27h-38.05zM549.46 791.59h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.98h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-6h38.04v4.27h-38.04zm0-5.98h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zM549.46 713.89h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zm0-5.99h38.04v4.27h-38.04zM1211.25 676.39h66.4v22.24h-66.4z"
      />
      <g>
        <path
          className="prefix__st7"
          d="M286.05 63.99v925.48h1125.38V63.99H286.05zm281.34 900.74H309.66v-876H567.4v876zm533.12 0H596.97v-876h503.54v876zm287.31 0h-257.74v-876h257.74v876z"
        />
        <path className="prefix__st7" d="M295.82 191.47h1115.61v20.5H295.82z" />
      </g>
      <path
        opacity={0.44}
        fill="#ededed"
        d="M1466.31 1200H228.08l57.97-210.53h1112.87l11.25 35.16z"
      />
    </g>
    <path
      opacity={0.32}
      fill="#fff"
      d="M1674 759.79l-262 214.96-.57-910.76z"
      id="prefix___x32_"
    />
    <g id="prefix__Shadow">
      <path
        opacity={0.23}
        fill="#706f6f"
        d="M1674 1131.06L1523.75 1047l-141.58-4 232.08 125.37H1674z"
      />
      <g opacity={0.23}>
        <path
          className="prefix__st12"
          d="M888.22 1080.12v4.78h.95l72.39 1.35-.02.24v8.67h2.67v9.7h-2.31c-2.83 0-5.11.73-5.29 1.65h-.02l-.02.11c0 .06.02.13.04.19.03.08.06.15.12.22h32.76l.1-.18a.7.7 0 00.06-.24l-.02-.11h-.02c-.17-.92-2.46-1.65-5.29-1.65h-2.31v-9.7h2.67v-8.67l-.02-.24 72.39-1.35h.95v-4.78c4.66-.55 7.9-1.74 7.9-3.12 0-1.9-6.15-3.45-13.74-3.45s-13.74 1.54-13.74 3.45c0 1.38 3.24 2.56 7.9 3.12v1.7l-64.72 1.2c-.78-.33-1.68-.58-2.66-.73v-2.18c4.66-.55 7.9-1.74 7.9-3.12 0-1.9-6.15-3.45-13.74-3.45s-13.74 1.54-13.74 3.45c0 1.38 3.24 2.56 7.9 3.12v2.18c-.98.15-1.87.4-2.66.73l-64.72-1.2v-1.7c4.66-.55 7.9-1.74 7.9-3.12 0-1.9-6.15-3.45-13.74-3.45s-13.74 1.54-13.74 3.45c.02 1.4 3.26 2.58 7.92 3.13z"
        />
        <path
          className="prefix__st12"
          d="M228.06 1200.06l74.65-216.69h260.32l-14.55 80.91-.7-2.89-9.8-.8 3.51 42.58-7.12 39.59-3.93.69-15.59-39.33-9.8-.62 5.62 43.42-43.6 7.64V1175h61.49l-4.51 25.06h33.97l4.03-25.06h323.08c-.04.14-.06.29-.07.43-.56 1.36.77 2.64 3.46 3.68.29.13.6.27.92.39.68.27 1.44.52 2.25.75.54.15 1.12.3 1.72.44l.92.2a62.8 62.8 0 0012.28 1.11h132.97a62.8 62.8 0 0013.2-1.31 33.07 33.07 0 003.97-1.19l.92-.39c2.69-1.04 4.02-2.32 3.46-3.68a2.26 2.26 0 00-.07-.43h71.23l4.03 25.06h33.97l-4.51-25.06h94.05v-20.45l-43.36-10.5 5.38-40.56-9.8.62-14.61 35.33-14.71-3.56 6.21-74.26-9.8-.24-16.92 69.53-4.56-1.1-26.33-146.45h260.32l74.65 216.69h27.12l-83.56-232.39H284.5l-83.56 232.39h27.12zM592.9 983.37h508.59l23.17 143.99-84.47.58-1.18-5.93h24.66c1.78 0 3.4-.37 4.65-.97.39-.12.76-.24 1.06-.38a4.1 4.1 0 00-.35-.02c1.1-.71 1.78-1.63 1.78-2.64v-.91c0-2.21-3.2-4.01-7.14-4.01h-3.93c0-3.33-4.81-6.03-10.74-6.03H897.22c-5.93 0-10.74 2.7-10.74 6.03h-3.93c-3.94 0-7.14 1.79-7.14 4.01v.91c0 1.01.67 1.93 1.78 2.64l-.35.02c.31.13.67.26 1.06.38 1.25.6 2.87.97 4.65.97h24.66l-1.36 6.85-270.91 1.87-10.17-49.94-47.8 1.54 15.93-98.96z"
        />
      </g>
    </g>
    <g id="prefix___x33_">
      <path
        className="prefix__st13"
        d="M1043.69 648.32H910.72c-13.5 0-23.31 13.86-21.31 29.72l18.35 145.55c.41 3.22.69 6.43.85 9.63l3.51 70.65c.53 10.74 9.48 19.32 19.99 19.32h90.19c10.51 0 19.46-8.58 19.99-19.32l3.71-74.56c.03-.6.08-1.2.16-1.79L1065 678.04c2-15.87-7.81-29.72-21.31-29.72z"
      />
      <path
        className="prefix__st14"
        d="M977.21 800.39c33.97 0 64.41-26.7 79.92-66.85l8.03-58.18c1.87-13.53-8.06-25.31-21.54-25.31H910.79c-13.48 0-23.41 11.78-21.54 25.31l8.03 58.18c15.52 40.16 45.95 66.85 79.93 66.85z"
      />
      <path
        className="prefix__st15"
        d="M897.59 742.92l3.04 24.14-4.41-73.88c-1.78-14.48 7.32-27.1 19.75-27.1h122.47c12.43 0 21.53 12.63 19.75 27.1l-4.41 73.88 3.04-24.14 8.18-64.88c2-15.86-7.82-29.72-21.31-29.72H910.72c-13.5 0-23.31 13.86-21.31 29.72l8.18 64.88z"
      />
      <path
        d="M1043.61 947.23H910.8A20.23 20.23 0 01890.57 927v-3.82h173.27V927a20.23 20.23 0 01-20.23 20.23z"
        fill="#484b49"
      />
      <path
        className="prefix__st13"
        d="M1064.22 923.19H890.19c-5.9 0-10.69-4.78-10.69-10.69v-14.25c0-5.9 4.78-10.69 10.69-10.69h174.03c5.9 0 10.69 4.78 10.69 10.69v14.25a10.7 10.7 0 01-10.69 10.69z"
      />
      <path
        className="prefix__st15"
        d="M969.24 1042.97l-75.95 5.63-.03-13.07 75.95-5.63z"
      />
      <path className="prefix__st15" d="M892.31 1035.53h11.68v26.11h-11.68z" />
      <circle cx={898.15} cy={1067.02} r={13.74} />
      <circle className="prefix__st17" cx={898.15} cy={1067.02} r={8.27} />
      <path
        className="prefix__st15"
        d="M985.17 1042.97l75.95 5.63.03-13.07-75.95-5.63z"
      />
      <path
        transform="rotate(-180 1056.26 1048.59)"
        className="prefix__st15"
        d="M1050.42 1035.53h11.68v26.11h-11.68z"
      />
      <circle cx={1056.26} cy={1067.02} r={13.74} />
      <circle className="prefix__st17" cx={1056.26} cy={1067.02} r={8.27} />
      <path className="prefix__st15" d="M971.37 1035.53h11.68v26.11h-11.68z" />
      <circle cx={977.2} cy={1067.02} r={13.74} />
      <circle className="prefix__st17" cx={977.2} cy={1067.02} r={8.27} />
      <path
        className="prefix__st15"
        d="M981.33 947.8h-8.25a8.9 8.9 0 00-4.78 7.89v77.86a8.91 8.91 0 0017.82 0v-77.86a8.93 8.93 0 00-4.79-7.89z"
      />
      <path
        className="prefix__st13"
        d="M977.2 1046.52a11.58 11.58 0 01-11.58-11.58v-40.33h23.15v40.33a11.56 11.56 0 01-11.57 11.58zm-16.38-99.23c-.1.53-.16 1.08-.16 1.65 0 3.86 2.38 7 5.32 7h22.44c2.94 0 5.32-3.13 5.32-7 0-.57-.06-1.12-.16-1.65h-32.76z"
      />
      <path
        className="prefix__st17"
        d="M993.73 949.38l.02-.43c0-.57-.06-1.12-.16-1.65h-32.76c-.1.53-.16 1.08-.16 1.65l.02.43h33.04z"
      />
      <path
        className="prefix__st14"
        d="M1064.22 887.57H890.19c-3.96 0-7.42 2.16-9.26 5.37 21.71 5.81 56.75 9.58 96.28 9.58s74.56-3.77 96.28-9.58a10.68 10.68 0 00-9.27-5.37z"
      />
    </g>
    <g id="prefix___x34_">
      <path
        className="prefix__st18"
        d="M514.85 1104.12l-9.8-.62 7.97-247.2 24.95 1.57z"
      />
      <path
        className="prefix__st19"
        d="M535.68 882.33l2.29-24.46-24.95-1.57-.84 26.03z"
      />
      <path
        className="prefix__st18"
        d="M547.77 1061.21l-9.8-.62 7.97-247.2 24.95 1.57zm664.32 42.91l9.8-.62-7.97-247.2-24.95 1.57z"
      />
      <path
        className="prefix__st19"
        d="M1214.76 882.33l-.84-26.03-24.95 1.57 2.3 24.46z"
      />
      <path
        className="prefix__st18"
        d="M1179.17 1061.21l9.8-.62-7.97-247.2-24.95 1.57z"
      />
      <path className="prefix__st20" d="M467.08 823h792.79v53H467.08z" />
      <path fill="#908a88" d="M1259.87 823H467.08l127.45-19.59h537.89z" />
      <path fill="#6b6765" d="M625.49 1083.93h-69.01l-13.9-136.38h96.81z" />
      <path fill="#676362" d="M638.48 956.5l.91-8.95h-96.81l.91 8.95z" />
      <path
        d="M641.16 950.73H540.81a6.71 6.71 0 010-13.42h100.35a6.71 6.71 0 010 13.42z"
        fill="#5f5958"
      />
    </g>
    <g id="prefix___x35_">
      <path
        className="prefix__st25"
        d="M909.47 779.46H688.14a5.33 5.33 0 01-5.33-5.33V629.46a5.33 5.33 0 015.33-5.33h221.33a5.33 5.33 0 015.33 5.33v144.67a5.32 5.32 0 01-5.33 5.33z"
      />
      <path
        d="M748.81 810.13h100v9.33h-100zm77.33 0h-54.67l3.8-95.56a5.32 5.32 0 015.32-5.11h36.44c2.86 0 5.2 2.26 5.32 5.11l3.79 95.56z"
        fill="#fff"
      />
      <path fill="#d6e1db" d="M771.66 805.46l-.19 4.67h54.67l-.19-4.67z" />
      <path
        className="prefix__st28"
        d="M575.48 811.54a2 2 0 01-1.7-.95l-48-77.33a2 2 0 01.64-2.75 2 2 0 012.75.64l48 77.33a2 2 0 01-1.69 3.06z"
      />
      <path
        className="prefix__st28"
        d="M527.48 734.2a2 2 0 01-1.02-3.72l76.67-45.33a2 2 0 012.04 3.44l-76.67 45.33c-.32.19-.67.28-1.02.28z"
      />
      <path
        className="prefix__st28"
        d="M627.48 688.87h-23.33c-1.1 0-2-.9-2-2s.9-2 2-2h23.33a2 2 0 110 4z"
      />
      <path
        className="prefix__st28"
        d="M681.7 708.85l.02-1.05a32.96 32.96 0 00-65.9.68c0 .35.02.7.04 1.05l65.84-.68zm-72.97-21.98a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-76.26 45.28a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm19.21 76.04h45.92a8.14 8.14 0 018.14 8.14v1.05h-62.2v-1.05a8.15 8.15 0 018.14-8.14z"
      />
    </g>
    <g id="prefix___x36_">
      <path
        className="prefix__st18"
        d="M1476.75 1096.5l7.66-70.35-19.91-1.52 3.58 71.87zm137.5 71.87l7.66-70.35-19.91-1.52 3.58 71.87zm-233-125.87l7.66-70.35-19.91-1.52 3.58 71.87z"
      />
      <path className="prefix__st20" d="M1594.55 759.79H1674v344.32h-79.45z" />
      <path fill="#87827f" d="M1674 759.79h-79.45l-235.49-15.71 63.27-2.21z" />
      <path
        fill="#9e9895"
        d="M1594.55 759.79v344.33l-93.46-53.6-5-2.87-75.5-43.29-5-2.87-56.53-32.42V744.08l56.53 3.77 5 .33 75.5 5.04 5 .34z"
      />
      <path
        className="prefix__st31"
        d="M1501.09 1050.52V753.56l-5-.34v294.43zm-80.5-302.34v256.18l-5-2.87V747.85z"
      />
      <path
        className="prefix__st31"
        d="M1359.06 809.78l56.53 7.97 5 .71 75.5 10.65 5 .71 93.46 13.19v-8.55l-93.46-10.84-5-.58-75.5-8.75-5-.58-56.53-6.55zm0 54.64l56.53 15.08 5 1.34 75.5 20.14 5 1.33 93.46 24.93v-8.34l-93.46-22.66-5-1.21-75.5-18.31-5-1.21-56.53-13.71zm235.49 142.54v7.53l-93.46-40.18-5-2.15-75.5-32.46-5-2.15-56.53-24.3v-2.57l56.53 23.11 5 2.05 75.5 30.86 5 2.05z"
      />
      <path
        className="prefix__st18"
        d="M1562.08 783.52c-.19.01-.37 0-.57-.01-13.17-1.1-26.1-2.06-38.1-2.94a4.5 4.5 0 11.66-8.98c12.03.88 24.98 1.84 38.2 2.95a4.5 4.5 0 01-.19 8.98zm-87.6-6.6c-.18.01-.36.01-.54-.01-12.76-1-24.01-1.97-34.39-2.95a4.5 4.5 0 11.86-8.96c10.33.98 21.53 1.95 34.24 2.94a4.49 4.49 0 01-.17 8.98zm-69.54-5.7h-.1c-8 .15-16.14-.76-24.01-1.63-4.88-.54-9.48-1.06-13.98-1.32a4.51 4.51 0 01.53-8.99c4.73.28 9.67.83 14.44 1.36 7.59.84 15.44 1.72 22.85 1.58a4.5 4.5 0 01.27 9zm153.03 87.65c-.33.02-.67 0-1.01-.06l-10.41-1.85-23.26-4.12a4.5 4.5 0 011.56-8.86l23.27 4.12 10.41 1.85a4.5 4.5 0 01-.56 8.92zm-83.48-14.63c-.32.02-.65 0-.98-.06a3738.02 3738.02 0 00-34.46-5.7 4.5 4.5 0 011.43-8.89c10.9 1.75 22.19 3.62 34.54 5.72a4.5 4.5 0 01-.53 8.93zm-69.74-11.78a4.5 4.5 0 01-1.15-.09l-2.47-.52c-10.79-2.27-20.98-4.42-31.62-5.37a4.5 4.5 0 01.8-8.96c11.17 1 21.61 3.2 32.67 5.53l2.46.52a4.5 4.5 0 01-.69 8.89zm154.68 104.35a4.3 4.3 0 01-1.42-.15c-9.98-2.66-20.52-5.54-34.18-9.33a4.5 4.5 0 112.41-8.67c13.63 3.78 24.15 6.65 34.1 9.31a4.5 4.5 0 01-.91 8.84zm-84.04-23.17c-.48.03-.97-.02-1.45-.15a2502.32 2502.32 0 00-34.94-9.33 4.5 4.5 0 112.25-8.71c10.56 2.72 21.7 5.7 35.07 9.36a4.5 4.5 0 01-.93 8.83zm-70.36-19c-.47.03-.96-.02-1.44-.15-3.81-1.03-7.73-1.98-11.52-2.91-7.96-1.94-16.19-3.94-23.8-6.76a4.5 4.5 0 013.12-8.44c7.12 2.63 14.74 4.49 22.81 6.45 3.84.94 7.82 1.9 11.74 2.96a4.5 4.5 0 01-.91 8.85zm153.39 123.51a4.41 4.41 0 01-1.94-.37 2413 2413 0 00-33.83-14.3 4.5 4.5 0 013.45-8.31 2557.2 2557.2 0 0133.95 14.35 4.51 4.51 0 01-1.63 8.63zm-84.06-34.83a4.48 4.48 0 01-1.85-.33l-1.27-.52c-11.15-4.52-22.3-9.03-33.44-13.59a4.5 4.5 0 013.41-8.33c11.13 4.55 22.27 9.06 33.41 13.58l1.27.52a4.5 4.5 0 01-1.53 8.67zm-70.04-28.65a4.55 4.55 0 01-1.73-.28 350.81 350.81 0 01-17.19-7.1 339.62 339.62 0 00-17.55-7.22 4.5 4.5 0 113.11-8.45c6.16 2.26 12.19 4.88 18.02 7.41a356.9 356.9 0 0016.75 6.92 4.5 4.5 0 01-1.41 8.72z"
      />
      <path
        className="prefix__st25"
        d="M1508.66 749.28l-50.46.75V624.99l50.46-.02z"
      />
      <path
        className="prefix__st32"
        d="M1458.53 750.04l-13.5-.76V625.73l13.5-.76z"
      />
      <path
        className="prefix__st33"
        d="M1453.87 735.5c0 2.4-.96 4.3-2.13 4.24-1.17-.06-2.13-2.04-2.13-4.43s.95-4.29 2.13-4.24c1.18.06 2.13 2.04 2.13 4.43z"
      />
      <path
        className="prefix__st32"
        d="M1445.03 749.28l-13.16-.74V626.47l13.16-.74z"
      />
      <path
        className="prefix__st33"
        d="M1440.49 734.92c0 2.37-.93 4.25-2.08 4.19-1.15-.05-2.07-2.01-2.07-4.37s.93-4.24 2.07-4.19c1.15.05 2.08 2.01 2.08 4.37z"
      />
      <path
        className="prefix__st32"
        d="M1431.87 748.54l-12.86-.72V627.2l12.86-.73z"
      />
      <path
        className="prefix__st33"
        d="M1427.43 734.36c0 2.34-.91 4.2-2.03 4.14-1.12-.05-2.03-1.99-2.03-4.32s.91-4.19 2.03-4.14c1.12.04 2.03 1.98 2.03 4.32z"
      />
      <path
        className="prefix__st32"
        d="M1419.01 747.82l-12.56-.71V627.9l12.56-.7z"
      />
      <path
        className="prefix__st33"
        d="M1414.68 733.8c0 2.31-.89 4.15-1.98 4.09-1.09-.05-1.98-1.96-1.98-4.27s.88-4.14 1.98-4.09c1.09.05 1.98 1.96 1.98 4.27z"
      />
      <path
        className="prefix__st34"
        d="M1117.51 734.26c0 5.39-6.96.58-6.96.58s-7.56 4.76-6.96-.58c.43-3.82 4.45-17.71 6.96-17.71 1.54 0 6.96 13.86 6.96 17.71zm-26.82 3.12c5.18 1.5-1.38 6.84-1.38 6.84s2.46 8.58-2.5 6.52c-3.55-1.48-15.77-9.21-15.06-11.62.43-1.48 15.25-2.81 18.94-1.74z"
      />
      <path
        className="prefix__st35"
        d="M1108.16 729.83c1.7 5.12-6.42 2.74-6.42 2.74s-5.67 6.89-6.79 1.64c-.8-3.76-1.36-18.21 1.03-19 1.46-.48 10.97 10.97 12.18 14.62z"
      />
      <path
        className="prefix__st36"
        d="M1130.51 740.43c-2.39 4.84-6.5-2.56-6.5-2.56s-8.88.92-5.98-3.6c2.07-3.24 11.82-13.91 14.07-12.8 1.39.68.11 15.51-1.59 18.96zm-27.91 13.09c-.3 0-.57-.2-.65-.5-2.31-8.6-11.04-15.75-11.13-15.82a.68.68 0 01-.1-.95.68.68 0 01.95-.1c.37.3 9.16 7.49 11.58 16.52a.68.68 0 01-.65.85z"
      />
      <path
        className="prefix__st35"
        d="M1104.54 751.86h-.07a.67.67 0 01-.6-.74c.89-8.85-4.75-18.63-4.81-18.73a.67.67 0 111.16-.68c.24.41 5.92 10.25 4.99 19.55a.67.67 0 01-.67.6z"
      />
      <path
        className="prefix__st36"
        d="M1105.74 753.02a.67.67 0 01-.34-1.25c7.79-4.59 21.85-18.63 21.99-18.78a.68.68 0 01.95 0c.26.26.26.69 0 .95-.58.58-14.33 14.31-22.26 18.98a.61.61 0 01-.34.1z"
      />
      <path
        className="prefix__st35"
        d="M1103.79 753.02a.67.67 0 01-.43-1.19c.48-.4 11.72-9.67 18.47-8.16.36.08.59.44.51.8a.67.67 0 01-.8.51c-6.11-1.36-17.2 7.79-17.32 7.89a.7.7 0 01-.43.15z"
      />
      <path
        className="prefix__st35"
        d="M1087.98 755.22a.67.67 0 01-.49-.21.68.68 0 01.03-.95c.07-.07 7.25-6.65 16.56-2.32.34.16.48.56.33.9a.68.68 0 01-.9.33c-8.47-3.94-14.8 1.84-15.07 2.08a.73.73 0 01-.46.17z"
      />
      <path
        className="prefix__st36"
        d="M1102.14 733.86c4.37 7.75-5.65 4.01-5.44 4.43.25.4-3.99 8.37-6.27 3.09-.81-1.75-2.88-6.2-6.19-9.62-3.07-3.56-7.36-6.11-7.11-7.3-.05-.64 5.5-1.79 11.39.18 5.9 1.6 12.14 6.33 13.62 9.22z"
      />
      <path
        className="prefix__st36"
        d="M1116.99 739.49c3.59 6.36-4.64 3.29-4.46 3.64.2.33-3.28 6.87-5.15 2.54-.66-1.44-2.37-5.09-5.08-7.9-2.52-2.93-6.04-5.02-5.83-5.99-.04-.52 4.51-1.47 9.35.14 4.84 1.31 9.96 5.2 11.17 7.57z"
      />
      <path
        className="prefix__st37"
        d="M1109.11 814.19h-20.81a12.6 12.6 0 01-12.56-11.67l-3.52-47.72h52.98l-3.52 47.72a12.6 12.6 0 01-12.57 11.67z"
      />
      <path fill="#a6a6a5" d="M1124.47 764.6l.73-9.79h-52.98l.72 9.79z" />
      <path className="prefix__st0" d="M1071.12 753.8h55.17v9.11h-55.17z" />
      <path
        className="prefix__st35"
        d="M1088.95 743.55c6.41-2.87 4.4 7.97 4.4 7.97s9.69 6.46 3.02 8.59c-4.77 1.53-23.43 4.15-24.77 1.16-.83-1.84 12.78-15.67 17.35-17.72z"
      />
      <path
        className="prefix__st34"
        d="M1118.11 752.74c-6.87-1.49 1.18-9.02 1.18-9.02s-3.97-10.94 2.66-8.7c4.75 1.6 21.33 10.55 20.64 13.75-.43 1.96-19.58 5.03-24.48 3.97z"
      />
      <path
        className="prefix__st35"
        d="M1118.92 736.53c-8.12-3.64-3.47 6-3.91 5.82-.42-.21-7.97 4.75-2.5 6.53 1.82.64 6.44 2.3 10.15 5.27 3.83 2.72 6.76 6.76 7.93 6.41.64-.01 1.28-5.64-1.22-11.33-2.15-5.72-7.44-11.5-10.45-12.7z"
      />
      <path
        className="prefix__st34"
        d="M1105.96 738.34c0 5.41-6.98.58-6.98.58s-7.58 4.77-6.98-.58c.43-3.83 4.46-17.75 6.98-17.75 1.56 0 6.98 13.89 6.98 17.75z"
      />
      <path
        className="prefix__st34"
        d="M1108.06 749.96c-4.1 1.82-2.79-5.09-2.79-5.09s-6.17-4.14-1.91-5.49c3.05-.96 14.96-2.6 15.81-.69.53 1.17-8.19 9.97-11.11 11.27z"
      />
      <path
        className="prefix__st35"
        d="M1103.05 736.5c6.4-.12.85 8.24.85 8.24s5.81 8.86-.53 8.27c-4.54-.42-21.11-4.87-21.17-7.85-.04-1.84 16.29-8.58 20.85-8.66zm22.51 3.21c-2.39 4.84-6.5-2.56-6.5-2.56s-8.88.92-5.98-3.6c2.07-3.24 11.82-13.91 14.07-12.8 1.39.69.11 15.51-1.59 18.96z"
      />
      <path
        className="prefix__st34"
        d="M1076.34 740.53c5.18 1.5-1.38 6.84-1.38 6.84s2.46 8.58-2.5 6.52c-3.55-1.48-15.77-9.21-15.06-11.62.43-1.47 15.25-2.81 18.94-1.74z"
      />
      <path
        className="prefix__st35"
        d="M1093.81 732.98c1.7 5.12-6.42 2.74-6.42 2.74s-5.67 6.89-6.79 1.64c-.8-3.76-1.36-18.21 1.03-19 1.47-.48 10.97 10.97 12.18 14.62z"
      />
      <path
        className="prefix__st36"
        d="M1088.25 756.67c-.3 0-.57-.2-.65-.5-2.31-8.6-11.04-15.75-11.13-15.82a.68.68 0 01-.1-.95.68.68 0 01.95-.1c.37.3 9.16 7.49 11.58 16.52.1.36-.12.73-.48.83-.05.02-.11.02-.17.02z"
      />
      <path
        className="prefix__st35"
        d="M1090.2 755.01h-.07a.67.67 0 01-.6-.74c.89-8.85-4.75-18.63-4.81-18.73a.67.67 0 111.16-.68c.24.41 5.92 10.25 4.99 19.55a.68.68 0 01-.67.6zm-16.57 3.36a.67.67 0 01-.49-.21.68.68 0 01.03-.95c.07-.07 7.25-6.65 16.56-2.32.34.16.48.56.33.9a.68.68 0 01-.9.33c-8.47-3.94-14.8 1.84-15.07 2.08a.73.73 0 01-.46.17z"
      />
      <path
        className="prefix__st36"
        d="M1087.79 737.01c4.37 7.75-5.65 4.01-5.44 4.43.25.4-3.99 8.37-6.27 3.09-.81-1.75-2.88-6.2-6.19-9.62-3.07-3.56-7.36-6.11-7.11-7.3-.05-.64 5.5-1.79 11.39.18 5.9 1.6 12.14 6.33 13.62 9.22z"
      />
      <path
        className="prefix__st36"
        d="M1102.65 742.64c3.59 6.36-4.64 3.29-4.46 3.64.2.33-3.28 6.87-5.15 2.54-.66-1.44-2.37-5.09-5.08-7.9-2.52-2.93-6.04-5.02-5.83-5.99-.04-.52 4.51-1.47 9.35.14 4.83 1.31 9.96 5.2 11.17 7.57z"
      />
      <path
        className="prefix__st35"
        d="M1074.6 746.7c6.41-2.87 4.4 7.97 4.4 7.97s9.69 6.46 3.02 8.59c-4.77 1.53-23.43 4.15-24.77 1.16-.82-1.84 12.78-15.67 17.35-17.72z"
      />
      <path
        className="prefix__st34"
        d="M1091.62 741.49c0 5.41-6.98.58-6.98.58s-7.58 4.77-6.98-.58c.43-3.83 4.46-17.75 6.98-17.75 1.55 0 6.98 13.9 6.98 17.75z"
      />
      <path
        className="prefix__st35"
        d="M1088.7 739.65c6.4-.12.85 8.24.85 8.24s5.81 8.86-.53 8.27c-4.54-.42-21.11-4.87-21.17-7.85-.03-1.84 16.29-8.58 20.85-8.66z"
      />
      <path
        className="prefix__st34"
        d="M1099.3 743.8c0 5.39-6.96.58-6.96.58s-7.56 4.76-6.96-.58c.43-3.82 4.45-17.71 6.96-17.71 1.54.01 6.96 13.87 6.96 17.71z"
      />
      <path
        className="prefix__st36"
        d="M1112.3 751.59c-2.39 4.84-6.5-2.56-6.5-2.56s-8.88.92-5.98-3.6c2.07-3.24 11.82-13.91 14.07-12.8 1.39.68.11 15.51-1.59 18.96zm-13.52-.95c3.59 6.36-4.64 3.29-4.46 3.64.2.33-3.28 6.87-5.15 2.54-.66-1.44-2.37-5.09-5.08-7.9-2.52-2.93-6.04-5.02-5.83-5.99-.04-.52 4.51-1.47 9.35.14 4.84 1.32 9.96 5.21 11.17 7.57z"
      />
      <path
        className="prefix__st34"
        d="M1103.62 762.13c-6.87-1.49 1.18-9.02 1.18-9.02s-3.97-10.94 2.66-8.7c4.75 1.6 21.33 10.55 20.64 13.75-.43 1.97-19.59 5.03-24.48 3.97z"
      />
      <path
        className="prefix__st35"
        d="M1100.71 747.68c-8.12-3.64-3.47 6-3.91 5.82-.42-.21-7.97 4.75-2.5 6.53 1.82.64 6.44 2.3 10.15 5.27 3.83 2.72 6.76 6.76 7.93 6.41.64-.01 1.28-5.64-1.22-11.33-2.15-5.71-7.44-11.49-10.45-12.7z"
      />
      <path
        className="prefix__st34"
        d="M1089.85 761.11c-4.1 1.82-2.79-5.09-2.79-5.09s-6.17-4.14-1.91-5.49c3.05-.96 14.96-2.6 15.81-.69.53 1.18-8.19 9.98-11.11 11.27z"
      />
      <path
        className="prefix__st35"
        d="M1107.35 750.87c-2.39 4.84-6.5-2.56-6.5-2.56s-8.88.92-5.98-3.6c2.07-3.24 11.82-13.91 14.07-12.8 1.39.68.11 15.51-1.59 18.96z"
      />
      <path
        d="M119.95 653.7l.01-.06.05.05-.06.01zm-82.51-51.47l-.06-.02.06-.03v.05zm81.62 117.52l.06-.07.02.08-.08-.01z"
        opacity={0.09}
        fill="#1d1d1b"
      />
      <g>
        <path fill="#734d28" d="M368.98 630.58h8.56v255.86h-8.56z" />
        <circle
          transform="rotate(-45 370.49 574.23)"
          className="prefix__st35"
          cx={370.49}
          cy={574.23}
          r={81.68}
        />
        <ellipse
          transform="rotate(-80.01 384.24 580.32)"
          className="prefix__st35"
          cx={384.21}
          cy={580.3}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M378.31 587.77c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55s-6.5 1.1-11.66-2.12-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M378.06 561.27c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M386.47 584.19c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zM358 568.18c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2s-10.06-4.9-9.39-7.23c.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M368.6 571.26c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 383.7 573.13)"
          className="prefix__st36"
          cx={383.67}
          cy={573.08}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-67.65 376.75 563.6)"
          className="prefix__st36"
          cx={376.75}
          cy={563.62}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-8.52 360.54 583.2)"
          className="prefix__st36"
          cx={360.5}
          cy={583.14}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M373.85 562.48c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 311.89 599.36)"
          className="prefix__st35"
          cx={311.85}
          cy={599.33}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M305.96 606.8c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M305.71 580.3c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M314.12 603.22c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-61.99 294.2 594.17)"
          className="prefix__st36"
          cx={294.19}
          cy={594.16}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-8.52 311.35 592.16)"
          className="prefix__st36"
          cx={311.31}
          cy={592.11}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M300.35 580.98c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.91-2.18-6.22.14-11.85zm-12.85 16.85c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.36-2.38 4.22-5.05 10.24-5.96z"
        />
        <path
          className="prefix__st34"
          d="M301.5 581.51c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32s-3.84-10.51-1.75-11.72c2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 330.5 615.8)"
          className="prefix__st35"
          cx={330.46}
          cy={615.77}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M324.56 623.25c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.27-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M324.31 596.74c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.58 3.53 7.27 9.37z"
        />
        <ellipse
          transform="rotate(-30.17 328.88 621.85)"
          className="prefix__st34"
          cx={328.93}
          cy={621.87}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M316.71 627.52c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-24.65 7.98c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.86 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M314.85 606.74c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.15 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 329.95 608.6)"
          className="prefix__st36"
          cx={329.92}
          cy={608.56}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M318.95 597.43c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.22.14-11.85zm-12.85 16.85c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.36-2.4 4.23-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st34"
          d="M320.1 597.96c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32s-3.84-10.51-1.75-11.72c2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 338.23 583.54)"
          className="prefix__st35"
          cx={338.2}
          cy={583.51}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M332.3 590.99c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.87 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M332.05 564.48c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M340.46 587.4c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.43.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 310.82 575.62)"
          className="prefix__st34"
          cx={310.8}
          cy={575.6}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M322.59 574.47c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 337.69 576.33)"
          className="prefix__st36"
          cx={337.66}
          cy={576.29}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M326.69 565.17c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 314.52 586.4)"
          className="prefix__st36"
          cx={314.49}
          cy={586.35}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M327.84 565.69c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32s-3.84-10.51-1.75-11.72c2.09-1.21 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M365.53 602.38c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23s5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M358.87 614.17c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M358.62 587.66c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.59 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M367.03 610.59c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.34 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-61.99 347.11 601.54)"
          className="prefix__st36"
          cx={347.11}
          cy={601.53}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M363.58 595.14c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.36-2.38 4.23-5.06 10.24-5.96z"
        />
        <path
          className="prefix__st36"
          d="M353.26 588.35c2.31-5.63 6-9.44 8.24-8.52s2.18 6.23-.14 11.85-6 9.44-8.24 8.52c-2.23-.91-2.17-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 341.1 609.58)"
          className="prefix__st36"
          cx={341.06}
          cy={609.54}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M354.41 588.88c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M316.99 569.55c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M310.33 581.34c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96z"
        />
        <ellipse
          transform="rotate(-57.99 290.5 559.82)"
          className="prefix__st35"
          cx={290.48}
          cy={559.81}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M310.08 554.83c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M325.93 584.74c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-23.45.87c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.56-2.9 11.5zm12.2-31.86c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2s-10.06-4.9-9.39-7.23c.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M300.62 564.82c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3zm13.37-5.88c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.37-2.39 4.22-5.06 10.24-5.96z"
        />
        <path
          className="prefix__st36"
          d="M304.72 555.52c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 292.55 576.74)"
          className="prefix__st36"
          cx={292.52}
          cy={576.7}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M305.87 556.04c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.07 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M336.89 558.2c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.43-2.38 5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M330.24 569.99c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M329.99 543.49c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M338.4 566.41c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm4.02 24.48c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm-7.84-48.48c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 308.75 554.63)"
          className="prefix__st34"
          cx={308.73}
          cy={554.61}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M320.53 553.48c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 335.62 555.34)"
          className="prefix__st36"
          cx={335.59}
          cy={555.3}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M324.63 544.17c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.91-2.18-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 322.25 574.2)"
          className="prefix__st36"
          cx={322.21}
          cy={574.16}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-30.17 321.94 546.89)"
          className="prefix__st34"
          cx={321.99}
          cy={546.9}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M368.56 561.8c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.45 11.61-2.4z"
        />
        <ellipse
          transform="rotate(-.69 358.26 574.82)"
          className="prefix__st35"
          cx={357.52}
          cy={573.65}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M351.66 548.08c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M361.65 547.09c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.59 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M370.06 570.01c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.43.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zM341.6 554c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M352.2 557.08c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.14 6.41-1.56 11.78 1.3z"
        />
        <path
          className="prefix__st36"
          d="M366.61 554.57c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.36-2.4 4.23-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st36"
          d="M356.29 547.78c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85zm-12.85 16.85c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.36-2.4 4.23-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st34"
          d="M357.44 548.3c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M353.25 611.3c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.43-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M346.59 623.09c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M346.35 596.58c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.32-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M354.76 619.51c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 325.11 607.73)"
          className="prefix__st34"
          cx={325.09}
          cy={607.71}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M336.89 606.58c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.15 6.41-1.56 11.78 1.3z"
        />
        <path
          className="prefix__st36"
          d="M351.3 604.06c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.36-2.4 4.23-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st36"
          d="M340.99 597.27c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.91-2.18-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 328.82 618.5)"
          className="prefix__st36"
          cx={328.79}
          cy={618.46}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-80.01 341.61 642.45)"
          className="prefix__st35"
          cx={341.58}
          cy={642.42}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M335.68 649.9c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07s4.43 4.87 4.51 10.96z"
        />
        <ellipse
          transform="rotate(-57.99 323.14 628.1)"
          className="prefix__st35"
          cx={323.11}
          cy={628.09}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M335.43 623.39c1.69 5.84 1.17 11.13-1.15 11.8-2.32.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M343.84 646.31c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.43.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 314.2 634.54)"
          className="prefix__st34"
          cx={314.17}
          cy={634.51}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M325.97 633.38c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 341.07 635.26)"
          className="prefix__st36"
          cx={341.04}
          cy={635.2}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M330.07 624.08c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.92-2.17-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 317.9 645.32)"
          className="prefix__st36"
          cx={317.87}
          cy={645.26}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M331.22 624.6c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 373.73 634.65)"
          className="prefix__st35"
          cx={373.69}
          cy={634.62}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M367.79 642.09c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M367.55 615.58c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.32-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M375.96 638.51c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-16.02 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.67 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-24.65 7.98c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.86 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M337.78 591.25c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.14 6.4-1.55 11.78 1.3zm34.72 31.81c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.36-2.38 4.23-5.06 10.24-5.96z"
        />
        <ellipse
          transform="rotate(-67.65 366.24 617.93)"
          className="prefix__st36"
          cx={366.24}
          cy={617.94}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-8.52 350.02 637.51)"
          className="prefix__st36"
          cx={349.98}
          cy={637.46}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M359.28 623.86c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M398.98 623.58c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M392.32 635.37c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.44 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M392.07 608.86c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M400.48 631.78c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.56-2.9 11.5zm12.19-31.86c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32zm-33.48-8.03c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M382.61 618.85c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3z"
        />
        <path
          className="prefix__st36"
          d="M397.03 616.34c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.36-2.39 4.22-5.06 10.24-5.96z"
        />
        <path
          className="prefix__st36"
          d="M386.71 609.55c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85zm-12.85 16.85c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.36-2.4 4.22-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st34"
          d="M387.86 610.08c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M391.54 596.86c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.55.76 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M379 601.98c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.58-3.26 11.4zm7.79-26.37c2.05 5.73 1.86 11.03-.42 11.85-2.28.81-5.79-3.17-7.83-8.9-2.05-5.73-1.86-11.03.42-11.85 2.27-.82 5.78 3.17 7.83 8.9z"
        />
        <path
          className="prefix__st35"
          d="M395.24 581.03c-2.3 5.63-5.98 9.46-8.22 8.54-2.24-.92-2.19-6.22.11-11.85s5.98-9.46 8.22-8.54c2.24.91 2.19 6.22-.11 11.85z"
        />
        <path
          className="prefix__st34"
          d="M387.62 604.23c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.4.34 3.64 5.5 2.77 11.52z"
        />
        <ellipse
          transform="rotate(-15.85 368.92 596.2)"
          className="prefix__st34"
          cx={369}
          cy={596.24}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M399.52 583.03c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.39.34 3.63 5.5 2.77 11.52zm-24.29-9.02c3.56 4.94 4.85 10.08 2.88 11.5-1.96 1.41-6.44-1.44-9.99-6.38-3.56-4.94-4.85-10.08-2.88-11.5 1.95-1.41 6.43 1.45 9.99 6.38z"
        />
        <path
          className="prefix__st36"
          d="M380.45 609.46c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33s-2.62-10.88-.41-11.85c2.21-.98 5.98 2.75 8.43 8.33zm14.05-19.49c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.2-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M385.34 599.1c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 363.67 621.64)"
          className="prefix__st35"
          cx={363.64}
          cy={621.61}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-.69 354.1 630.42)"
          className="prefix__st35"
          cx={353.36}
          cy={629.14}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M365.9 625.5c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5z"
        />
        <path
          className="prefix__st35"
          d="M352.12 626.21c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st34"
          d="M353.62 634.42c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 372.6 649.56)"
          className="prefix__st35"
          cx={372.56}
          cy={649.53}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M366.66 657c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55s-6.5 1.1-11.66-2.12-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M366.41 630.49c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.59 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M374.82 653.42c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2s-10.06-4.9-9.39-7.23c.66-2.34 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-8.52 368.07 643.6)"
          className="prefix__st36"
          cx={368.03}
          cy={643.54}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-67.65 365.11 632.84)"
          className="prefix__st36"
          cx={365.11}
          cy={632.85}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-8.52 348.9 652.43)"
          className="prefix__st36"
          cx={348.85}
          cy={652.37}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M358.14 638.77c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 397.12 642.83)"
          className="prefix__st35"
          cx={397.08}
          cy={642.8}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M391.19 650.28c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M390.94 623.77c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M399.35 646.69c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.56-2.9 11.5zm12.19-31.86c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32zm-33.48-8.03c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M392.54 642.92c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 396.59 635.64)"
          className="prefix__st36"
          cx={396.55}
          cy={635.58}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M385.58 624.46c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.92-2.18-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 367.51 602.68)"
          className="prefix__st36"
          cx={367.47}
          cy={602.62}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M386.73 624.99c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M390.4 611.77c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.56.76 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M377.87 616.89c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.58-3.26 11.4z"
        />
        <ellipse
          transform="rotate(-81.84 382.17 618.51)"
          className="prefix__st34"
          cx={382.15}
          cy={618.52}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-23.66 375.29 626.1)"
          className="prefix__st36"
          cx={375.3}
          cy={626.13}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M393.36 604.88c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M427.59 580.62c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M406.65 598.81c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.45 11.61-2.4z"
        />
        <ellipse
          transform="rotate(-.69 396.43 611.9)"
          className="prefix__st35"
          cx={395.61}
          cy={610.66}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M408.16 607.02c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-16.02 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.67 6.57-2.9 11.5z"
        />
        <path
          className="prefix__st35"
          d="M394.37 607.73c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.43-2.38 5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st34"
          d="M395.88 615.94c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 414.85 631.08)"
          className="prefix__st35"
          cx={414.81}
          cy={631.05}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M408.92 638.52c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zM398.67 613c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M408.67 612.02c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M417.08 634.94c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2s-10.06-4.9-9.39-7.23c.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-8.52 414.32 623.88)"
          className="prefix__st36"
          cx={414.27}
          cy={623.83}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M403.31 612.7c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.91-2.18-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 397.82 580.81)"
          className="prefix__st36"
          cx={397.79}
          cy={580.77}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M400.4 620.29c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 449.71 611.38)"
          className="prefix__st35"
          cx={449.67}
          cy={611.36}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M433.44 631.8c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07s4.44 4.88 4.51 10.96z"
        />
        <ellipse
          transform="rotate(-57.99 420.9 610.01)"
          className="prefix__st35"
          cx={420.87}
          cy={610}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M433.19 605.29c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M441.6 628.22c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-33.49-8.04c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M423.73 615.28c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.55 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-50.01 439.44 618.2)"
          className="prefix__st36"
          cx={439.43}
          cy={618.19}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M427.83 605.98c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 415.68 627.23)"
          className="prefix__st36"
          cx={415.63}
          cy={627.16}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M428.98 606.51c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32s-3.84-10.51-1.75-11.72c2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M432.66 593.29c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.55.77 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M420.12 598.41c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.46 6.58-3.26 11.4z"
        />
        <ellipse
          transform="rotate(-81.84 424.42 600.03)"
          className="prefix__st34"
          cx={424.41}
          cy={600.04}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-23.66 417.54 607.62)"
          className="prefix__st36"
          cx={417.55}
          cy={607.65}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M435.62 586.4c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.2-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M426.88 586.52c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M405.94 604.71c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M399.28 616.5c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M407.44 612.91c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.07 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5z"
        />
        <path
          className="prefix__st35"
          d="M393.66 613.63c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st34"
          d="M395.17 621.83c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 414.14 636.97)"
          className="prefix__st35"
          cx={414.1}
          cy={636.94}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M408.2 644.42c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <ellipse
          transform="rotate(-16.12 403.64 619.1)"
          className="prefix__st35"
          cx={403.75}
          cy={619.13}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M416.37 640.83c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32zm-16.02 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.67 6.56-2.9 11.5zm12.2-31.86c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-8.52 366.21 590.5)"
          className="prefix__st36"
          cx={366.17}
          cy={590.44}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M402.6 618.6c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85s-6 9.44-8.24 8.52c-2.24-.92-2.18-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 406.9 637.63)"
          className="prefix__st36"
          cx={406.87}
          cy={637.58}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M399.69 626.18c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M439.39 625.9c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M432.73 637.69c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.44 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M432.48 611.18c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M440.89 634.11c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36s.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32s-3.84-10.51-1.75-11.72c2.09-1.22 6.27 2.06 9.32 7.32zm-33.48-8.04c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.86 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M423.02 621.18c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3s-8.8-6.91-7.67-9.04c1.14-2.14 6.41-1.56 11.78 1.3zm33.09-9.52c5.52 2.55 9.18 6.39 8.17 8.59-1.01 2.2-6.31 1.91-11.84-.63-5.52-2.55-9.18-6.39-8.17-8.59 1.01-2.2 6.31-1.92 11.84.63z"
        />
        <path
          className="prefix__st36"
          d="M427.12 611.87c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85s-6 9.44-8.24 8.52c-2.23-.91-2.17-6.22.14-11.85zm-62.24-82.51c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.37-2.39 4.22-5.06 10.24-5.96z"
        />
        <path
          className="prefix__st34"
          d="M428.27 612.4c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M431.95 599.18c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.55.77 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M419.41 604.3c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.59-3.26 11.4z"
        />
        <path
          className="prefix__st34"
          d="M428.03 606.55c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.4.34 3.64 5.5 2.77 11.52z"
        />
        <path
          className="prefix__st36"
          d="M420.86 611.78c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33-2.44-5.57-2.62-10.88-.41-11.85 2.21-.97 5.98 2.76 8.43 8.33z"
        />
        <ellipse
          transform="rotate(-60.19 432.71 596.09)"
          className="prefix__st36"
          cx={432.73}
          cy={596.1}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M449.72 550.58c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M428.78 568.77c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.37 5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M422.12 580.57c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07s4.44 4.87 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M430.28 576.98c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32-3.84-10.51-1.75-11.72c2.09-1.21 6.27 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.56-2.9 11.5z"
        />
        <ellipse
          transform="rotate(-80.01 415.78 582.03)"
          className="prefix__st35"
          cx={415.74}
          cy={582.01}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M418.01 585.9c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 436.98 601.04)"
          className="prefix__st35"
          cx={436.94}
          cy={601.01}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M431.04 608.49c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.87 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M430.8 581.98c1.69 5.84 1.17 11.13-1.15 11.8s-5.58-3.52-7.27-9.37c-1.69-5.84-1.17-11.13 1.15-11.8 2.32-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M439.2 604.9c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.43.67 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-8.52 436.45 593.84)"
          className="prefix__st36"
          cx={436.4}
          cy={593.79}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M425.43 582.67c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85zm-12.84 16.85c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.97-6.02.9-11.18-.31-11.54-2.7-.37-2.4 4.22-5.07 10.24-5.97z"
        />
        <path
          className="prefix__st34"
          d="M422.53 590.25c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 461.5 594.31)"
          className="prefix__st35"
          cx={461.47}
          cy={594.28}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M455.57 601.76c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M455.32 575.25c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M463.73 598.18c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5zm15.63-34.95c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zm-36.92-4.94c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2s-10.06-4.9-9.39-7.23c.66-2.33 5.94-2.86 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M445.86 585.25c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.31-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.55 11.78 1.31z"
        />
        <ellipse
          transform="rotate(-8.52 460.98 587.12)"
          className="prefix__st36"
          cx={460.93}
          cy={587.07}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M449.96 575.94c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 437.8 597.17)"
          className="prefix__st36"
          cx={437.76}
          cy={597.13}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M451.11 576.47c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M454.79 563.25c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.55.77 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M442.25 568.37c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.59-3.26 11.4z"
        />
        <path
          className="prefix__st34"
          d="M450.87 570.62c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.39.34 3.64 5.5 2.77 11.52z"
        />
        <path
          className="prefix__st36"
          d="M443.69 575.85c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33-2.44-5.57-2.62-10.88-.41-11.85 2.22-.97 5.99 2.76 8.43 8.33z"
        />
        <ellipse
          transform="rotate(-60.19 455.55 560.16)"
          className="prefix__st36"
          cx={455.57}
          cy={560.17}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M441.67 507.53c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 420.01 530.06)"
          className="prefix__st35"
          cx={419.98}
          cy={530.03}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M414.08 537.51c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M422.24 533.93c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5z"
        />
        <ellipse
          transform="rotate(-80.01 407.74 538.98)"
          className="prefix__st35"
          cx={407.7}
          cy={538.95}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M409.96 542.84c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.07 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M429.66 553.64c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23s5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M423 565.43c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.27-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M422.75 538.92c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M431.16 561.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.57-2.9 11.5zm12.2-31.86c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.07 9.32 7.32zm-24.65 7.99c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.87 11.79-1.2z"
        />
        <path
          className="prefix__st36"
          d="M427.71 546.4c6.02-.9 11.18.31 11.54 2.7.36 2.39-4.23 5.06-10.24 5.96-6.02.9-11.18-.31-11.54-2.7-.36-2.39 4.22-5.06 10.24-5.96z"
        />
        <path
          className="prefix__st36"
          d="M417.39 539.61c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 405.23 560.84)"
          className="prefix__st36"
          cx={405.19}
          cy={560.79}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M414.48 547.2c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 453.46 551.25)"
          className="prefix__st35"
          cx={453.42}
          cy={551.23}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M447.52 558.71c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.04 4.44 4.87 4.51 10.96zm-10.24-25.53c5.16 3.22 8.3 7.5 7.02 9.55s-6.5 1.1-11.66-2.12-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M447.28 532.2c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M455.69 555.12c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.95 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-33.48-8.03c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.34 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-61.99 435.76 546.08)"
          className="prefix__st36"
          cx={435.76}
          cy={546.06}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-8.52 452.92 544.05)"
          className="prefix__st36"
          cx={452.88}
          cy={544.01}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-67.65 445.97 534.54)"
          className="prefix__st36"
          cx={445.97}
          cy={534.55}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-8.52 429.76 554.12)"
          className="prefix__st36"
          cx={429.72}
          cy={554.07}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M443.07 533.41c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M446.74 520.19c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.56.77 10.6 5.31z"
        />
        <ellipse
          transform="rotate(-52.36 430.74 522.63)"
          className="prefix__st35"
          cx={430.74}
          cy={522.64}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M442.83 527.56c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.39.34 3.63 5.5 2.77 11.52z"
        />
        <path
          className="prefix__st36"
          d="M435.65 532.79c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33s-2.62-10.88-.41-11.85c2.21-.97 5.99 2.76 8.43 8.33z"
        />
        <path
          className="prefix__st34"
          d="M413.38 503.17c6.01.93 10.58 3.63 10.21 6.02-.37 2.39-5.55 3.57-11.56 2.64-6.01-.93-10.58-3.63-10.21-6.02.38-2.39 5.55-3.57 11.56-2.64z"
        />
        <path
          className="prefix__st35"
          d="M436.08 514.35c5.6 2.37 9.38 6.09 8.44 8.32-.94 2.23-6.25 2.12-11.85-.25s-9.38-6.09-8.44-8.32c.94-2.23 6.24-2.12 11.85.25z"
        />
        <ellipse
          transform="rotate(-81.17 437.47 514.45)"
          className="prefix__st34"
          cx={437.46}
          cy={514.43}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-81.17 433.7 522.74)"
          className="prefix__st34"
          cx={433.7}
          cy={522.71}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M426.41 507.21c6.08-.28 11.09 1.44 11.21 3.86.11 2.42-4.72 4.61-10.8 4.89-6.08.28-11.09-1.44-11.21-3.86-.11-2.42 4.73-4.61 10.8-4.89z"
        />
        <path
          className="prefix__st35"
          d="M422.51 520.17c1.41 5.92.64 11.17-1.72 11.73-2.35.56-5.4-3.79-6.81-9.7-1.41-5.92-.64-11.17 1.72-11.73 2.35-.56 5.4 3.78 6.81 9.7z"
        />
        <path
          className="prefix__st34"
          d="M429.68 514.89c4.14 4.46 6.05 9.41 4.28 11.06-1.77 1.65-6.57-.64-10.7-5.1-4.14-4.46-6.05-9.41-4.28-11.06 1.77-1.65 6.57.64 10.7 5.1z"
        />
        <path
          className="prefix__st36"
          d="M422.93 500.57c5.67-2.2 10.98-2.15 11.85.1.87 2.26-3.01 5.87-8.69 8.07-5.67 2.2-10.98 2.15-11.85-.1-.87-2.26 3.01-5.87 8.69-8.07z"
        />
        <path
          className="prefix__st35"
          d="M403.13 520.68c4.59-3.99 9.6-5.75 11.19-3.92 1.59 1.83-.85 6.54-5.44 10.53-4.59 3.99-9.6 5.75-11.19 3.92-1.58-1.82.85-6.54 5.44-10.53z"
        />
        <path
          className="prefix__st36"
          d="M396.28 517.64c3.09-5.24 7.28-8.5 9.36-7.27 2.09 1.23 1.27 6.47-1.81 11.71-3.09 5.24-7.28 8.5-9.36 7.27-2.09-1.22-1.28-6.46 1.81-11.71z"
        />
        <path
          className="prefix__st35"
          d="M413.34 497.39c4.59-3.99 9.6-5.75 11.19-3.92 1.59 1.83-.85 6.54-5.44 10.53-4.59 3.99-9.6 5.75-11.19 3.92-1.59-1.82.85-6.54 5.44-10.53z"
        />
        <path
          className="prefix__st35"
          d="M418.31 509.99c4.77 3.77 7.43 8.37 5.93 10.27-1.5 1.9-6.59.38-11.36-3.39s-7.43-8.37-5.93-10.27c1.5-1.9 6.59-.38 11.36 3.39z"
        />
        <ellipse
          transform="rotate(-19 393.42 506)"
          className="prefix__st35"
          cx={393.46}
          cy={506.03}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M397.56 493.5c5.6 2.37 9.38 6.09 8.44 8.32-.94 2.23-6.25 2.12-11.85-.25s-9.38-6.09-8.44-8.32c.94-2.23 6.24-2.12 11.85.25z"
        />
        <path
          className="prefix__st34"
          d="M420.66 501.39c6.01.93 10.58 3.63 10.21 6.02-.37 2.39-5.55 3.57-11.56 2.64-6.01-.93-10.58-3.63-10.21-6.02.38-2.39 5.55-3.57 11.56-2.64z"
        />
        <ellipse
          transform="rotate(-15.16 412.54 519.91)"
          className="prefix__st34"
          cx={412.46}
          cy={519.92}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M399.61 489.25c6.01.93 10.58 3.63 10.21 6.02-.37 2.39-5.55 3.57-11.56 2.64-6.01-.93-10.58-3.63-10.21-6.02.38-2.39 5.55-3.57 11.56-2.64z"
        />
        <ellipse
          transform="rotate(-22.99 401.01 511.12)"
          className="prefix__st36"
          cx={401.08}
          cy={511.17}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M406.49 494.35c3.09-5.24 7.28-8.5 9.36-7.27 2.09 1.23 1.27 6.47-1.81 11.71-3.09 5.24-7.28 8.5-9.36 7.27-2.09-1.22-1.28-6.47 1.81-11.71z"
        />
        <path
          className="prefix__st36"
          d="M394.72 498.1c-2.92-5.34-3.56-10.61-1.44-11.77 2.12-1.16 6.21 2.23 9.13 7.57 2.92 5.34 3.56 10.61 1.44 11.77-2.13 1.15-6.22-2.23-9.13-7.57zm5.01 20.59c3.09-5.24 7.28-8.5 9.36-7.27 2.09 1.23 1.27 6.47-1.81 11.71-3.09 5.24-7.28 8.5-9.36 7.27-2.09-1.22-1.28-6.47 1.81-11.71z"
        />
        <ellipse
          transform="rotate(-81.17 395.18 501.89)"
          className="prefix__st34"
          cx={395.18}
          cy={501.87}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M387.89 486.36c6.08-.28 11.09 1.44 11.21 3.86.11 2.42-4.72 4.61-10.8 4.89-6.08.28-11.09-1.44-11.21-3.86-.11-2.42 4.73-4.61 10.8-4.89z"
        />
        <path
          className="prefix__st34"
          d="M391.16 494.04c4.14 4.46 6.05 9.41 4.28 11.06-1.77 1.65-6.57-.64-10.7-5.1-4.14-4.46-6.05-9.41-4.28-11.06 1.77-1.64 6.56.64 10.7 5.1z"
        />
        <path
          className="prefix__st36"
          d="M390.7 502.91c5.87 1.61 10.1 4.81 9.46 7.14-.64 2.33-5.92 2.92-11.78 1.31-5.87-1.61-10.1-4.81-9.46-7.14.64-2.33 5.92-2.92 11.78-1.31z"
        />
        <path
          className="prefix__st34"
          d="M375.64 490.33c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.61-2.35 3.68-5.47 9.58-6.98z"
        />
        <path
          className="prefix__st35"
          d="M400.9 491.67c6.08-.03 11.02 1.91 11.04 4.33.01 2.42-4.91 4.41-10.99 4.44-6.08.03-11.02-1.91-11.04-4.33s4.91-4.41 10.99-4.44z"
        />
        <path
          className="prefix__st34"
          d="M401.12 486.95c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.61-2.35 3.68-5.47 9.58-6.98z"
        />
        <path
          className="prefix__st34"
          d="M400.92 496.05c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.6-2.35 3.69-5.47 9.58-6.98z"
        />
        <ellipse
          transform="rotate(-25.87 391.13 492.86)"
          className="prefix__st35"
          cx={391.12}
          cy={492.85}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M390.72 502.37c3.62 4.89 4.98 10.02 3.04 11.46-1.94 1.44-6.46-1.35-10.08-6.24-3.62-4.89-4.98-10.02-3.04-11.46 1.94-1.44 6.46 1.35 10.08 6.24z"
        />
        <path
          className="prefix__st34"
          d="M395.23 494.68c5.56 2.47 9.27 6.27 8.28 8.48-.98 2.21-6.29 2-11.84-.47-5.56-2.47-9.27-6.27-8.28-8.48.98-2.21 6.28-2 11.84.47z"
        />
        <ellipse
          transform="rotate(-44.38 386.42 487.29)"
          className="prefix__st36"
          cx={386.45}
          cy={487.32}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-64.2 377.05 512.36)"
          className="prefix__st35"
          cx={377.05}
          cy={512.37}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-82.7 369.95 510.92)"
          className="prefix__st36"
          cx={369.96}
          cy={510.92}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M373.32 485.04c2.65-5.48 6.56-9.06 8.74-8.01 2.18 1.05 1.8 6.35-.85 11.82-2.65 5.48-6.56 9.06-8.74 8.01-2.18-1.05-1.8-6.35.85-11.82z"
        />
        <path
          className="prefix__st35"
          d="M382.85 494.66c5.87 1.59 10.12 4.77 9.49 7.1-.63 2.34-5.9 2.94-11.78 1.36-5.87-1.59-10.12-4.77-9.49-7.1.64-2.34 5.91-2.95 11.78-1.36zm-27.34 2.9c4.51-4.08 9.48-5.94 11.1-4.15 1.63 1.79-.71 6.56-5.22 10.64-4.51 4.08-9.48 5.94-11.1 4.15-1.63-1.79.71-6.56 5.22-10.64z"
        />
        <path
          className="prefix__st35"
          d="M357.28 487.67c6.08-.03 11.02 1.91 11.04 4.33.01 2.42-4.91 4.41-10.99 4.44-6.08.03-11.02-1.91-11.04-4.33-.01-2.42 4.91-4.41 10.99-4.44z"
        />
        <path
          className="prefix__st34"
          d="M381.63 485.83c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.6-2.35 3.69-5.48 9.58-6.98zm3.19 17.54c3.77 4.77 5.3 9.85 3.4 11.36-1.9 1.5-6.5-1.15-10.27-5.92-3.77-4.77-5.3-9.86-3.4-11.36s6.5 1.15 10.27 5.92zm-27.32-20.41c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.61-2.35 3.68-5.48 9.58-6.98z"
        />
        <path
          className="prefix__st36"
          d="M364.32 499.49c4.21-4.39 9.04-6.59 10.79-4.91 1.75 1.68-.25 6.59-4.47 10.98-4.21 4.39-9.04 6.59-10.79 4.91-1.75-1.67.25-6.59 4.47-10.98z"
        />
        <path
          className="prefix__st36"
          d="M365.83 484.94c.77-6.03 3.35-10.68 5.75-10.37 2.4.31 3.72 5.45 2.95 11.48s-3.35 10.68-5.75 10.37c-2.4-.31-3.72-5.45-2.95-11.48z"
        />
        <path
          className="prefix__st36"
          d="M356.48 493.02c-4.78-3.76-7.45-8.35-5.95-10.25 1.5-1.9 6.58-.4 11.37 3.36s7.45 8.35 5.95 10.25c-1.49 1.9-6.58.39-11.37-3.36z"
        />
        <ellipse
          transform="rotate(-82.7 373.53 510.53)"
          className="prefix__st36"
          cx={373.54}
          cy={510.53}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M357.3 492.05c5.89-1.51 11.16-.83 11.76 1.51.6 2.34-3.69 5.47-9.58 6.98-5.89 1.51-11.16.83-11.76-1.51-.6-2.35 3.69-5.47 9.58-6.98z"
        />
        <path
          className="prefix__st35"
          d="M345.59 484.91c5.47-2.65 10.77-3.04 11.82-.86 1.06 2.18-2.53 6.09-8 8.75-5.47 2.65-10.77 3.04-11.82.86-1.06-2.18 2.52-6.09 8-8.75z"
        />
        <path
          className="prefix__st34"
          d="M351.61 490.69c5.56 2.47 9.27 6.27 8.28 8.48-.98 2.21-6.29 2-11.84-.47s-9.27-6.27-8.28-8.48c.98-2.22 6.28-2.01 11.84.47z"
        />
        <path
          className="prefix__st36"
          d="M354.69 499.02c6.03-.83 11.18.44 11.51 2.84.33 2.4-4.29 5.01-10.31 5.84-6.03.83-11.18-.44-11.51-2.84-.34-2.4 4.28-5.01 10.31-5.84zm95.01 14.29c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.11 6.46-1.36 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M383.22 568.12c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M375.75 585.91c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.46 6.59-3.26 11.4z"
        />
        <path
          className="prefix__st36"
          d="M391.25 573.9c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M382.51 574.02c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm22.84-35.93c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 383.69 560.62)"
          className="prefix__st35"
          cx={383.65}
          cy={560.59}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M377.75 568.07c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M385.91 564.48c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72s6.27 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.56-2.9 11.5z"
        />
        <path
          className="prefix__st35"
          d="M372.13 565.19c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.43-2.37 5.62-3.45 11.61-2.4z"
        />
        <path
          className="prefix__st34"
          d="M373.64 573.4c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M376.43 570.47c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M386.43 569.48c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.32-.67 5.58 3.52 7.27 9.37z"
        />
        <ellipse
          transform="rotate(-30.17 387.18 570.59)"
          className="prefix__st34"
          cx={387.23}
          cy={570.61}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M381.07 570.17c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.92-2.18-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-30.17 374.32 579.94)"
          className="prefix__st34"
          cx={374.37}
          cy={579.96}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M400.95 563.74c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.51-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M410.95 562.75c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M415.55 561.68c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32zm-33.49-8.04c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.33 5.94-2.86 11.79-1.2zm24.68 10.33c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M410.42 550.75c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.55.77 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M397.88 555.87c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.59-3.26 11.4z"
        />
        <path
          className="prefix__st34"
          d="M406.5 558.12c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.4.34 3.64 5.5 2.77 11.52z"
        />
        <path
          className="prefix__st36"
          d="M399.33 563.35c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33s-2.62-10.88-.41-11.85c2.21-.97 5.98 2.76 8.43 8.33z"
        />
        <ellipse
          transform="rotate(-60.19 411.19 547.66)"
          className="prefix__st36"
          cx={411.2}
          cy={547.67}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-80.01 375.64 517.56)"
          className="prefix__st35"
          cx={375.61}
          cy={517.53}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M369.71 525.01c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.44 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M377.87 521.43c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.42.66 6.57-2.9 11.5z"
        />
        <ellipse
          transform="rotate(-80.01 363.36 526.48)"
          className="prefix__st35"
          cx={363.33}
          cy={526.45}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M365.59 530.35c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 384.56 545.48)"
          className="prefix__st35"
          cx={384.53}
          cy={545.45}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M378.63 552.93c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M378.38 526.42c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M386.79 549.35c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32c-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 357.15 537.57)"
          className="prefix__st34"
          cx={357.13}
          cy={537.55}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-8.52 384.03 538.28)"
          className="prefix__st36"
          cx={383.99}
          cy={538.24}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M373.02 527.11c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 360.86 548.34)"
          className="prefix__st36"
          cx={360.82}
          cy={548.3}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M370.11 534.7c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32-3.84-10.51-1.75-11.72c2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 409.1 538.75)"
          className="prefix__st35"
          cx={409.05}
          cy={538.73}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M403.16 546.21c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.87 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55s-6.5 1.1-11.66-2.12-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M402.91 519.7c1.69 5.84 1.17 11.13-1.15 11.8-2.32.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M411.32 542.62c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.56-2.9 11.5zm12.19-31.86c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32-3.84-10.51-1.75-11.72c2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st36"
          d="M393.45 529.69c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.13-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 408.55 531.55)"
          className="prefix__st36"
          cx={408.51}
          cy={531.51}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M397.55 520.39c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.92-2.18-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 385.39 541.62)"
          className="prefix__st36"
          cx={385.35}
          cy={541.57}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M398.7 520.91c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M402.37 507.7c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.62 6.56.76 10.6 5.31z"
        />
        <ellipse
          transform="rotate(-81.84 394.14 514.44)"
          className="prefix__st34"
          cx={394.12}
          cy={514.44}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M391.28 520.29c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33-2.44-5.57-2.62-10.88-.41-11.85 2.22-.97 5.99 2.76 8.43 8.33zm14.05-19.48c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.1 6.46-1.35 11.74 1.67z"
        />
        <ellipse
          transform="rotate(-30.17 314.56 573.65)"
          className="prefix__st34"
          cx={314.61}
          cy={573.67}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M310.93 589.25c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.91 1.48.45 6.58-3.26 11.4z"
        />
        <path
          className="prefix__st36"
          d="M326.42 577.24c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M317.68 577.36c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zm22.84-35.93c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M319.59 559.62c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.42-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M312.93 571.41c.07 6.08-1.83 11.04-4.25 11.07s-4.44-4.88-4.51-10.96c-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M321.09 567.82c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32zm-16.01 7.86c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5s8.04-7.78 10-6.36c1.96 1.42.66 6.56-2.9 11.5z"
        />
        <ellipse
          transform="rotate(-80.01 306.58 572.88)"
          className="prefix__st35"
          cx={306.55}
          cy={572.85}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M308.81 576.74c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M311.6 573.81c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.06 6.51-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M321.6 572.82c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M326.2 571.74c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st36"
          d="M316.24 573.51c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85z"
        />
        <path
          className="prefix__st34"
          d="M313.33 581.09c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M336.13 567.08c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M346.13 566.09c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.32-.66 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M350.72 565.02c3.06 5.26 3.84 10.51 1.75 11.72s-6.27-2.06-9.32-7.32-3.84-10.51-1.75-11.72 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-74.1 316.06 561.22)"
          className="prefix__st34"
          cx={316.04}
          cy={561.2}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M341.92 567.31c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M345.59 554.09c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14 1.81-1.61 6.56.77 10.6 5.31z"
        />
        <path
          className="prefix__st35"
          d="M333.05 559.21c-3.71 4.82-8.28 7.52-10.2 6.05-1.92-1.48-.46-6.58 3.26-11.4 3.71-4.82 8.28-7.52 10.2-6.05 1.92 1.48.46 6.59-3.26 11.4z"
        />
        <path
          className="prefix__st34"
          d="M341.68 561.46c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.78-11.52.86-6.02 3.5-10.62 5.9-10.28 2.4.34 3.64 5.5 2.78 11.52z"
        />
        <path
          className="prefix__st36"
          d="M334.5 566.69c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33s-2.62-10.88-.41-11.85c2.21-.97 5.99 2.76 8.43 8.33z"
        />
        <ellipse
          transform="rotate(-60.19 346.36 551)"
          className="prefix__st36"
          cx={346.37}
          cy={551.01}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-80.01 310.81 520.9)"
          className="prefix__st35"
          cx={310.78}
          cy={520.87}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M304.46 525.11c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.88 4.51 10.96z"
        />
        <path
          className="prefix__st34"
          d="M313.05 524.77c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.08-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 301.48 524.95)"
          className="prefix__st35"
          cx={301.45}
          cy={524.92}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M297.56 534.5c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M320.46 544.48c5.99 1.06 10.51 3.84 10.09 6.23-.42 2.38-5.62 3.46-11.61 2.4-5.99-1.06-10.51-3.84-10.09-6.23.43-2.38 5.62-3.46 11.61-2.4z"
        />
        <path
          className="prefix__st35"
          d="M313.81 556.27c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.41-.03 4.43 4.88 4.51 10.96zm-10.25-25.52c5.16 3.22 8.3 7.5 7.02 9.55-1.28 2.05-6.5 1.1-11.66-2.12s-8.3-7.5-7.02-9.55c1.28-2.05 6.5-1.1 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M313.56 529.76c1.69 5.84 1.17 11.13-1.15 11.8-2.33.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.53 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M321.97 552.69c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.95 1.42.66 6.57-2.9 11.5zm12.19-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.27 2.06 9.32 7.32zM293 538.57c5.85 1.67 10.06 4.9 9.39 7.23-.66 2.33-5.94 2.86-11.79 1.2-5.85-1.67-10.06-4.9-9.39-7.23.66-2.34 5.94-2.87 11.79-1.2z"
        />
        <ellipse
          transform="rotate(-8.52 319.2 541.61)"
          className="prefix__st36"
          cx={319.17}
          cy={541.58}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M308.2 530.45c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.24-.92-2.18-6.22.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 291.2 549.41)"
          className="prefix__st36"
          cx={291.18}
          cy={549.37}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M305.29 538.04c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.22 6.26 2.06 9.32 7.32z"
        />
        <ellipse
          transform="rotate(-80.01 344.26 542.1)"
          className="prefix__st35"
          cx={344.23}
          cy={542.07}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st35"
          d="M338.33 549.55c.07 6.08-1.83 11.04-4.25 11.07-2.42.03-4.44-4.88-4.51-10.96-.07-6.08 1.83-11.04 4.25-11.07 2.42-.03 4.44 4.87 4.51 10.96zm-10.24-25.52c5.16 3.22 8.3 7.5 7.02 9.55s-6.5 1.1-11.66-2.12-8.3-7.5-7.02-9.55c1.28-2.06 6.5-1.11 11.66 2.12z"
        />
        <path
          className="prefix__st35"
          d="M338.08 523.04c1.69 5.84 1.17 11.13-1.15 11.8-2.32.67-5.58-3.52-7.27-9.37-1.69-5.84-1.17-11.13 1.15-11.8 2.33-.67 5.58 3.52 7.27 9.37z"
        />
        <path
          className="prefix__st34"
          d="M346.49 545.96c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32zm-16.01 7.85c-3.56 4.93-8.04 7.78-10 6.36-1.96-1.42-.67-6.56 2.9-11.5 3.56-4.93 8.04-7.78 10-6.36 1.96 1.43.66 6.57-2.9 11.5zm12.2-31.85c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.26 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st36"
          d="M328.62 533.03c5.37 2.86 8.8 6.91 7.67 9.04-1.14 2.14-6.41 1.55-11.78-1.3-5.37-2.86-8.8-6.91-7.67-9.04 1.14-2.14 6.41-1.56 11.78 1.3z"
        />
        <ellipse
          transform="rotate(-8.52 343.72 534.9)"
          className="prefix__st36"
          cx={343.69}
          cy={534.85}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M332.72 523.73c2.31-5.63 6-9.44 8.24-8.52 2.24.92 2.18 6.23-.14 11.85-2.31 5.63-6 9.44-8.24 8.52-2.23-.92-2.17-6.23.14-11.85z"
        />
        <ellipse
          transform="rotate(-8.52 320.56 544.96)"
          className="prefix__st36"
          cx={320.52}
          cy={544.91}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M333.87 524.25c3.06 5.26 3.84 10.51 1.75 11.72-2.09 1.22-6.27-2.06-9.32-7.32-3.06-5.26-3.84-10.51-1.75-11.72 2.09-1.21 6.27 2.06 9.32 7.32z"
        />
        <path
          className="prefix__st35"
          d="M337.55 511.04c4.05 4.54 5.86 9.53 4.05 11.14-1.81 1.61-6.55-.77-10.6-5.31-4.05-4.54-5.86-9.53-4.05-11.14s6.55.76 10.6 5.31z"
        />
        <ellipse
          transform="rotate(-81.84 329.31 517.78)"
          className="prefix__st34"
          cx={329.3}
          cy={517.78}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M326.46 523.63c2.44 5.57 2.62 10.88.41 11.85-2.22.97-5.99-2.76-8.43-8.33-2.44-5.57-2.62-10.88-.41-11.85 2.21-.97 5.99 2.76 8.43 8.33zm14.05-19.48c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.2-2.1 6.46-1.35 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M324.58 522.25c-5.22 3.13-10.45 3.98-11.7 1.91s1.97-6.29 7.19-9.42c5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.07-1.97 6.29-7.19 9.42z"
        />
        <path
          className="prefix__st35"
          d="M306.69 515.02c-4.87-3.65-7.64-8.17-6.19-10.11 1.45-1.94 6.57-.55 11.44 3.1 4.87 3.65 7.64 8.17 6.19 10.11-1.45 1.94-6.57.55-11.44-3.1z"
        />
        <path
          className="prefix__st36"
          d="M318.91 530.35c-2.95 5.32-7.06 8.68-9.17 7.51s-1.44-6.44 1.51-11.76c2.95-5.32 7.06-8.68 9.17-7.51 2.12 1.18 1.44 6.44-1.51 11.76z"
        />
        <path
          className="prefix__st34"
          d="M318.68 521.62c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.24 2.07-1.98 6.29-7.19 9.42z"
        />
        <ellipse
          transform="rotate(-30.96 352.67 540.19)"
          className="prefix__st34"
          cx={352.67}
          cy={540.2}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M336.44 523.27c-.97 6.01-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57s3.7-10.56 6.09-10.17c2.4.39 3.55 5.57 2.57 11.57z"
        />
        <path
          className="prefix__st35"
          d="M324.56 516.78c-6.08.16-11.06-1.68-11.12-4.1-.06-2.42 4.82-4.51 10.9-4.67 6.08-.16 11.06 1.68 11.12 4.1.06 2.42-4.82 4.51-10.9 4.67z"
        />
        <path
          className="prefix__st34"
          d="M328.26 524.89c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.24 2.07-1.98 6.29-7.19 9.42zm-8.08-15.9c-4.98-3.49-7.89-7.93-6.5-9.91 1.39-1.98 6.55-.76 11.53 2.74 4.98 3.49 7.89 7.93 6.5 9.91-1.38 1.97-6.55.75-11.53-2.74z"
        />
        <path
          className="prefix__st35"
          d="M327.36 511.12c-.97 6-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57.97-6.01 3.7-10.56 6.09-10.17 2.39.38 3.54 5.56 2.57 11.57z"
        />
        <path
          className="prefix__st34"
          d="M319.17 512.73c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.08-1.97 6.3-7.19 9.42z"
        />
        <path
          className="prefix__st35"
          d="M322.14 515.49c-3.15 5.2-7.39 8.4-9.46 7.15-2.07-1.25-1.19-6.49 1.96-11.69 3.15-5.2 7.39-8.4 9.46-7.15s1.2 6.48-1.96 11.69z"
        />
        <ellipse
          transform="rotate(-16.9 322.03 521.36)"
          className="prefix__st35"
          cx={322}
          cy={521.28}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M324.41 530.05c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.07-1.97 6.29-7.19 9.42z"
        />
        <ellipse
          transform="rotate(-68.45 320.86 524.17)"
          className="prefix__st36"
          cx={320.9}
          cy={524.19}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st34"
          d="M314.88 517.32c-5.22 3.13-10.45 3.98-11.7 1.91s1.97-6.29 7.19-9.42c5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.07-1.97 6.29-7.19 9.42z"
        />
        <path
          className="prefix__st35"
          d="M329.21 539.92c-3.15 5.2-7.39 8.4-9.46 7.15-2.07-1.25-1.19-6.49 1.96-11.69 3.15-5.2 7.39-8.4 9.46-7.15 2.07 1.25 1.19 6.48-1.96 11.69z"
        />
        <path
          className="prefix__st35"
          d="M330.33 549.9c-5.82 1.77-11.11 1.33-11.81-.99-.7-2.32 3.44-5.63 9.26-7.39 5.82-1.77 11.11-1.33 11.81.99.71 2.31-3.44 5.62-9.26 7.39z"
        />
        <ellipse
          transform="rotate(-30.96 329.22 550.7)"
          className="prefix__st34"
          cx={329.22}
          cy={550.72}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M339.04 520.89c-1.59 5.87-4.77 10.12-7.1 9.49s-2.94-5.9-1.36-11.78c1.59-5.87 4.77-10.12 7.1-9.49s2.95 5.9 1.36 11.78z"
        />
        <ellipse
          transform="rotate(-30.96 326.8 541.94)"
          className="prefix__st34"
          cx={326.8}
          cy={541.95}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M342.33 549.2c-4.49 4.11-9.45 5.99-11.08 4.21-1.63-1.78.68-6.56 5.16-10.67 4.49-4.11 9.45-5.99 11.08-4.21 1.64 1.78-.68 6.56-5.16 10.67z"
        />
        <path
          className="prefix__st35"
          d="M337.03 536.73c-4.87-3.65-7.64-8.17-6.19-10.11s6.57-.55 11.44 3.1c4.87 3.65 7.64 8.17 6.19 10.11s-6.57.55-11.44-3.1z"
        />
        <ellipse
          transform="rotate(-82.63 335.46 541.02)"
          className="prefix__st34"
          cx={335.46}
          cy={541.04}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M329.58 538.28c-5.54 2.52-10.84 2.77-11.84.57s2.68-6.03 8.21-8.55c5.54-2.52 10.84-2.77 11.84-.57s-2.68 6.03-8.21 8.55zm19.67 13.78c-2.95 5.32-7.06 8.68-9.17 7.51s-1.44-6.44 1.51-11.76c2.95-5.32 7.06-8.68 9.17-7.51 2.12 1.18 1.44 6.44-1.51 11.76z"
        />
        <path
          className="prefix__st35"
          d="M379.39 514.63c-.97 6.01-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57.97-6 3.7-10.56 6.09-10.17 2.39.39 3.54 5.57 2.57 11.57z"
        />
        <path
          className="prefix__st35"
          d="M367.5 508.14c-6.08.16-11.06-1.68-11.12-4.1-.06-2.42 4.82-4.51 10.9-4.67 6.08-.16 11.06 1.68 11.12 4.1.06 2.42-4.82 4.51-10.9 4.67z"
        />
        <ellipse
          transform="rotate(-30.96 368.95 512.48)"
          className="prefix__st34"
          cx={368.94}
          cy={512.49}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M363.13 500.35c-4.98-3.49-7.89-7.93-6.5-9.91 1.39-1.98 6.55-.76 11.53 2.74 4.98 3.49 7.89 7.93 6.5 9.91-1.39 1.98-6.55.75-11.53-2.74z"
        />
        <path
          className="prefix__st35"
          d="M370.3 502.48c-.97 6.01-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57s3.7-10.56 6.09-10.17c2.39.38 3.54 5.57 2.57 11.57z"
        />
        <path
          className="prefix__st34"
          d="M362.11 504.1c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.07-1.97 6.29-7.19 9.42z"
        />
        <path
          className="prefix__st35"
          d="M351.59 523.94c-.97 6.01-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57s3.7-10.56 6.09-10.17c2.4.38 3.54 5.57 2.57 11.57z"
        />
        <ellipse
          transform="rotate(-1.48 339.04 512.25)"
          className="prefix__st35"
          cx={339.59}
          cy={513.07}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M365.08 506.85c-3.15 5.2-7.39 8.4-9.46 7.15-2.07-1.25-1.19-6.49 1.96-11.69 3.15-5.2 7.39-8.4 9.46-7.15 2.07 1.25 1.2 6.49-1.96 11.69z"
        />
        <ellipse
          transform="rotate(-16.9 364.98 512.72)"
          className="prefix__st35"
          cx={364.94}
          cy={512.64}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M343.41 525.56c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.24 2.07-1.98 6.29-7.19 9.42zm-8.08-15.9c-4.98-3.49-7.89-7.93-6.5-9.91 1.39-1.98 6.55-.76 11.53 2.74 4.98 3.49 7.89 7.93 6.5 9.91-1.38 1.97-6.55.75-11.53-2.74z"
        />
        <ellipse
          transform="rotate(-30.96 365.1 517.64)"
          className="prefix__st34"
          cx={365.1}
          cy={517.65}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M359.02 496.87c-1.59 5.87-4.77 10.12-7.1 9.49s-2.95-5.9-1.36-11.78c1.59-5.87 4.77-10.12 7.1-9.49s2.95 5.91 1.36 11.78z"
        />
        <path
          className="prefix__st36"
          d="M358.8 521.89c.98 6-.15 11.19-2.54 11.58-2.39.39-5.12-4.16-6.11-10.16-.98-6 .15-11.19 2.54-11.58 2.39-.39 5.13 4.16 6.11 10.16z"
        />
        <path
          className="prefix__st36"
          d="M365.45 511.48c5.66 2.24 9.52 5.87 8.63 8.12-.89 2.25-6.2 2.26-11.85.03-5.66-2.24-9.52-5.87-8.63-8.12.88-2.25 6.19-2.26 11.85-.03zm-17.03-12.61c.98 6-.15 11.19-2.54 11.58-2.39.39-5.12-4.16-6.11-10.16-.98-6 .15-11.19 2.54-11.58 2.39-.4 5.13 4.15 6.11 10.16zm-17.2-1.48c.98 6-.15 11.19-2.54 11.58-2.39.39-5.12-4.16-6.11-10.16-.98-6 .15-11.19 2.54-11.58 2.4-.39 5.13 4.16 6.11 10.16z"
        />
        <ellipse
          transform="rotate(-30.96 355.57 504.91)"
          className="prefix__st34"
          cx={355.57}
          cy={504.92}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M331.95 499.33c-1.47-5.9-.76-11.16 1.59-11.75 2.35-.58 5.44 3.73 6.91 9.63 1.47 5.9.76 11.16-1.59 11.75-2.35.58-5.44-3.73-6.91-9.63z"
        />
        <path
          className="prefix__st35"
          d="M358.66 548.37c-.97 6.01-3.7 10.56-6.09 10.17-2.39-.39-3.54-5.57-2.57-11.57.97-6.01 3.7-10.56 6.09-10.17 2.39.38 3.54 5.56 2.57 11.57z"
        />
        <ellipse
          transform="rotate(-1.48 346.09 536.64)"
          className="prefix__st35"
          cx={346.66}
          cy={537.49}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st35"
          d="M372.15 531.28c-3.15 5.2-7.39 8.4-9.46 7.15-2.07-1.25-1.19-6.49 1.96-11.69 3.15-5.2 7.39-8.4 9.46-7.15 2.07 1.25 1.19 6.48-1.96 11.69z"
        />
        <path
          className="prefix__st35"
          d="M373.27 541.26c-5.82 1.77-11.11 1.33-11.81-.99-.7-2.32 3.44-5.63 9.26-7.4 5.82-1.77 11.11-1.33 11.81.99.71 2.32-3.44 5.63-9.26 7.4z"
        />
        <ellipse
          transform="rotate(-30.96 348.22 546.22)"
          className="prefix__st34"
          cx={348.21}
          cy={546.23}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st34"
          d="M342.4 534.09c-4.98-3.49-7.89-7.93-6.5-9.91 1.39-1.98 6.55-.76 11.53 2.74 4.98 3.49 7.89 7.93 6.5 9.91-1.39 1.97-6.55.75-11.53-2.74z"
        />
        <ellipse
          transform="rotate(-30.96 372.16 542.07)"
          className="prefix__st34"
          cx={372.16}
          cy={542.08}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-62.78 359.27 529.96)"
          className="prefix__st36"
          cx={359.26}
          cy={529.94}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M365.86 546.32c.98 6-.15 11.19-2.54 11.58-2.39.39-5.12-4.16-6.11-10.16-.98-6 .15-11.19 2.54-11.58 2.4-.39 5.13 4.16 6.11 10.16z"
        />
        <ellipse
          transform="rotate(-68.45 370.86 539.96)"
          className="prefix__st36"
          cx={370.9}
          cy={539.98}
          rx={4.38}
          ry={11.01}
        />
        <path
          className="prefix__st36"
          d="M355.48 523.29c.98 6-.15 11.19-2.54 11.58-2.39.39-5.12-4.16-6.11-10.16-.98-6 .15-11.19 2.54-11.58 2.4-.39 5.13 4.16 6.11 10.16z"
        />
        <path
          className="prefix__st34"
          d="M372 537.07c-5.22 3.13-10.45 3.98-11.7 1.91-1.25-2.08 1.97-6.29 7.19-9.42 5.22-3.13 10.45-3.98 11.7-1.91 1.25 2.07-1.97 6.29-7.19 9.42z"
        />
        <path
          className="prefix__st35"
          d="M385.27 540.56c-4.49 4.11-9.45 5.99-11.08 4.21-1.63-1.78.68-6.56 5.16-10.67s9.45-5.99 11.08-4.21c1.64 1.78-.67 6.56-5.16 10.67z"
        />
        <ellipse
          transform="rotate(-82.63 378.4 532.38)"
          className="prefix__st34"
          cx={378.41}
          cy={532.4}
          rx={4.38}
          ry={11.01}
        />
        <ellipse
          transform="rotate(-24.45 370.7 525.7)"
          className="prefix__st36"
          cx={370.7}
          cy={525.66}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-60.98 388.36 541.28)"
          className="prefix__st36"
          cx={388.36}
          cy={541.3}
          rx={11.01}
          ry={4.38}
        />
        <ellipse
          transform="rotate(-29.32 392.81 582.1)"
          className="prefix__st36"
          cx={392.76}
          cy={582.07}
          rx={11.01}
          ry={4.38}
        />
        <path
          className="prefix__st36"
          d="M370.08 583.5c5.28 3.02 8.58 7.18 7.38 9.28-1.2 2.1-6.46 1.35-11.74-1.67s-8.58-7.18-7.38-9.28c1.21-2.11 6.46-1.36 11.74 1.67z"
        />
        <path
          className="prefix__st34"
          d="M391.19 579.37c-.86 6.02-3.5 10.62-5.9 10.28-2.4-.34-3.64-5.5-2.77-11.52.86-6.02 3.5-10.62 5.9-10.28 2.39.34 3.63 5.5 2.77 11.52z"
        />
        <path
          className="prefix__st37"
          d="M416.95 1040.53h-87.58L310.6 802.09h125.12z"
        />
        <path
          opacity={0.1}
          fill="#1d1d1b"
          d="M369.33 1086.98l47.62-46.45h-87.58l-93.99 46.39z"
        />
        <path
          d="M416.95 1040.53h-87.58L310.6 802.09l26.67 157.79a95.83 95.83 0 0061.69 74.08l17.99 6.57z"
          opacity={0.23}
          fill="#9d9d9c"
        />
        <path fill="#c6c6c6" d="M434.61 816.19l1.11-14.1H310.6l1.11 14.1z" />
        <path
          className="prefix__st20"
          d="M435.27 813.03H311.05a7.23 7.23 0 01-7.23-7.23v-1.55a7.23 7.23 0 017.23-7.23h124.21a7.23 7.23 0 017.23 7.23v1.55a7.23 7.23 0 01-7.22 7.23z"
        />
      </g>
    </g>
  </>
);

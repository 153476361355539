import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Actor } from "../core/interfaces";
import {
  actorAddMessage,
  actorChangeMessage,
  ACTOR_ADD,
  ACTOR_CHANGE,
} from "../core/messages";
import { Message } from "../message-hub/interfaces";

const initialState: Record<Actor["id"], Actor> = {};

export class ActorStore {
  state = initialState;
  constructor(messages: Observable<Message>) {
    messages
      .pipe(
        filter(
          (msg: any) => msg.type === ACTOR_CHANGE || msg.type === ACTOR_ADD
        )
      )
      .subscribe(this.onMessage);
  }

  private onMessage = (msg: actorAddMessage | actorChangeMessage) => {
    const actor = msg.payload;
    const id = actor.id as string;
    const current = this.state[id] || {
      avatarUrl: `/actors/${actor.id}.svg`,
      sprites: {
        face: `/actors/${id}.svg`,
        angry: `/actors/${id}_angry.svg`,
        excited: `/actors/${id}_excited.svg`,
        happy: `/actors/${id}_happy.svg`,
        neutral: `/actors/${id}_neutral.svg`,
        reflective: `/actors/${id}_reflective.svg`,
        shocked: `/actors/${id}_shocked.svg`,
        tired: `/actors/${id}_tired.svg`,
        shy: `/actors/${id}_shy.svg`,
        sad: `/actors/${id}_sad.svg`,
      },
      backgroundUrl: `/actors/backgrounds/${actor.background}.svg`,
    };
    this.state[id] = { ...current, ...msg.payload };
  };

  replaceActor = (id, property = "name") => {
    id = id.toLowerCase();

    try {
      const actor = this.state[id] || {
        id,
        name: `${id} 🚧`,
        shortname: id,
        email: `${id}@gmail.com`,
        avatarUrl: "/avatars/avatar1.svg",
      };
      return actor[property];
    } catch (e) {
      throw new Error(
        `"Error in placeholder user: ${id}, property: ${property}`
      );
    }
  };
}

import { Observable } from "rxjs";
import { scan, shareReplay, startWith } from "rxjs/operators";
import MessageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { ofTypes } from "../applications";
import { episodeEndMessage, EPISODE_END, NAVIGATION_URL } from "../messages";
import { DirectCommunicationEnd } from "./interfaces/direct-communication-end";
import { DirectCommunicationMessage } from "./interfaces/direct-communication-message";
import { State } from "./interfaces/state";
import {
  DirectMessageHubMessages,
  DIRECT_COMMUNICATION_CLEAR,
  DIRECT_COMMUNICATION_CLOSE,
  DIRECT_COMMUNICATION_END,
  DIRECT_COMMUNICATION_FINISHED,
  DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT,
  DIRECT_COMMUNICATION_OPEN,
  DIRECT_COMMUNICATION_SEND,
  DIRECT_COMMUNICATION_START,
  DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG,
} from "./messages";

export class DirectMessages {
  static instance: DirectMessages;

  initialState: State = {
    open: false,
    mode: "idle",
    messages: [],
    autostart: false,
    final: false,
  };

  state: Observable<State>;

  constructor(messages: Observable<Message>) {
    DirectMessages.instance = this;

    this.state = messages.pipe(
      ofTypes<DirectMessageHubMessages | episodeEndMessage>(
        DIRECT_COMMUNICATION_START,
        DIRECT_COMMUNICATION_OPEN,
        DIRECT_COMMUNICATION_CLOSE,
        DIRECT_COMMUNICATION_SEND,
        DIRECT_COMMUNICATION_END,
        DIRECT_COMMUNICATION_FINISHED,
        DIRECT_COMMUNICATION_CLEAR,
        EPISODE_END
      ),
      scan(this.reducer, this.initialState),
      startWith(this.initialState),
      shareReplay(1)
    );

    this.state.subscribe();
  }

  private reducer = (
    state: State,
    msg: DirectMessageHubMessages | episodeEndMessage
  ): State => {
    switch (msg.type) {
      case EPISODE_END:
      case DIRECT_COMMUNICATION_CLEAR:
        return this.initialState;
      case DIRECT_COMMUNICATION_START:
        return this.onStart(state, msg.payload);
      case DIRECT_COMMUNICATION_OPEN:
        return this.onToggle(state, true);
      case DIRECT_COMMUNICATION_CLOSE:
        return this.onToggle(state, false);
      case DIRECT_COMMUNICATION_SEND:
        return this.onSendMessage(state, msg.payload);
      case DIRECT_COMMUNICATION_END:
        return this.endDirectMessages(state, msg.payload);
      case DIRECT_COMMUNICATION_FINISHED:
        return this.onFinished(state);
      default:
        return state;
    }
  };

  private onStart = (
    state: State,
    payload: {
      actor?: string;
      autostart?: boolean;
      final?: boolean;
    }
  ): State => {
    if (state.actor === payload.actor) {
      if (state.open) {
        // If the pane is already open we must send the message to release let the script continue
        // check `case DIRECT_COMMUNICATION_START` in `src/game-engine/messages-to-observable.ts`
        this.open();
      }

      return {
        ...state,
        mode: "active",
        autostart: payload.autostart || false,
        final: payload.final || false,
        messages: [],
      };
    }
    return {
      ...this.initialState,
      mode: "active",
      actor: payload.actor,
      autostart: payload.autostart || false,
      final: payload.final || false,
    };
  };

  private onToggle = (state: State, open: boolean): State => ({
    ...state,
    open,
    autostart: open ? false : state.autostart,
  });

  private onSendMessage = (
    state: State,
    payload: DirectCommunicationMessage
  ): State => ({
    ...state,
    lastMessage: payload,
    messages: [...state.messages, payload],
    mode: "active",
  });

  private endDirectMessages = (
    state: State,
    payload: DirectCommunicationEnd
  ): State => ({
    ...state,
    mode: payload?.text ? "end" : state.mode,
    endText: payload?.text,
  });

  private onFinished = (state: State): State => {
    if (state.final) {
      MessageHub.send({
        type: NAVIGATION_URL,
        payload: {
          url: "/quiz-time",
        },
      });
    }
    return {
      ...state,
      open: false,
      autostart: false,
      mode: "finish",
    };
  };

  finish = () => {
    MessageHub.send({
      type: DIRECT_COMMUNICATION_FINISHED,
      payload: true,
    });
  };

  open = () => {
    MessageHub.send({
      type: DIRECT_COMMUNICATION_OPEN,
      payload: true,
    });
  };

  close = () => {
    MessageHub.send({
      type: DIRECT_COMMUNICATION_CLOSE,
      payload: true,
    });
  };

  takeNext = () => {
    MessageHub.send({
      type: DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT,
      payload: true,
    });
  };

  takeNextDialog = () => {
    MessageHub.send({
      type: DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG,
      payload: true,
    });
  };
}

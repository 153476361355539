import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import OpenAI from "openai";
import { openAiConnection } from "../services/open-ai-connection";

export const useGetOpenAiRun = <T = OpenAI.Beta.Threads.Runs.Run>(
  threadId = "",
  runId = "",
  options?: UseQueryOptions<OpenAI.Beta.Threads.Runs.Run, unknown, T>
) => {
  return useQuery({
    queryKey: [useGetOpenAiRun.queryKey, threadId, runId],
    queryFn: async () => {
      return await openAiConnection
        .getConnection()
        .beta.threads.runs.retrieve(threadId, runId);
    },
    meta: {
      devErrorMsg: useGetOpenAiRun.queryKey,
    },
    ...options,
  });
};

useGetOpenAiRun.queryKey = "useGetOpenAiRun";

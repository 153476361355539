import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MissionIndividualProgressCard from "core/dashboard/components/mission-individual-progress-card";
import Seasons from "core/dashboard/components/seasons";
import { RefObject, useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import GameEngine from "../../../../game-engine";
import { Episode, Season } from "../../../../game-engine/interfaces";
import { useDeviceDetect } from "../../../hooks";
import { userHasRole } from "../../../keycloak";
import { MicrolearningContext, useMicrolearning } from "../../../microlearning";
import { getItem } from "../../../storage";
import DashboardBanner from "../../components/banner";
import CompetenceIcon from "../../components/competence-icon";
import { DashboardTour } from "../../components/dashboard-tour";
import PopUp from "../../components/pop-up";
import { DashboardContext } from "../../dashboard.context";
import { getName } from "../admin/missions/missions.service";
import tourData, {
  DASHBOARD_TOUR_COMPLETE_STORAGE_KEY,
} from "./episodes-tour.data";
import {
  StyledActionButton,
  StyledContainer,
  StyledDialogPopUpHeader,
  StyledDialogPopUpHeaderTitle,
  StyledEpisodesList,
  StyledFurtherInfo,
  StyledFurtherInfoText,
  StyledHeader,
  StyledHeaderDivider,
  StyledInfoButton,
  StyledInfoButtonsRow,
  StyledInfoSection,
  StyledInfoSectionTextContainer,
  StyledInfoSectionTitle,
} from "./home.styles";
import DashboardHomeItem from "./item";
import PlayerProfileModal from "./player-profile-modal";
import { Recommendations } from "./recommendations";

type Props = {
  onPlay: (e: Episode) => void;
  lastEpisode?: Episode;
  contentRef?: RefObject<HTMLDivElement>;
};

type Tab = { episodeId: Episode["id"]; tab: string };
let lastTab: Tab | undefined;
const DashboardHome = ({ onPlay, lastEpisode, contentRef }: Props) => {
  const history = useHistory();
  const { seasonId } = useParams<any>();
  const microlearningHook = useMicrolearning();
  const gameEngine = GameEngine();
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const device = useDeviceDetect();
  const [furtherInfoShowed, setFurtherInfoShowed] = useState<boolean>(false);
  const [thematicFocusShowed, setThematicFocusShowed] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<Tab | undefined>(() => {
    if (history.action === "POP" && lastTab) return lastTab;
    if (lastEpisode?.id && lastEpisode.completed) {
      return { episodeId: lastEpisode.id, tab: "competence" };
    }
    return undefined;
  });
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);
  const [isDemo] = useState<boolean>(() => getItem("isDemoTenant"));

  const currentSeason = seasons.find((s) => s.id === seasonId);
  const bannerType = currentSeason && isDemo ? "home-demo" : undefined;
  const isAdmin = userHasRole("talent_admin");
  const [dashboardTourActive, setDashboardTourActive] = useState(false);
  const { setDashboardTourFinished, playerSettingsFilled } =
    useContext(DashboardContext);

  const [episodeToPlay, setEpisodeToPlay] = useState<Episode>();
  const [showAvatarSelectionModal, setShowAvatarSelectionModal] =
    useState(false);

  useEffect(() => {
    gameEngine.episodes.getSeasons().then(setSeasons);
  }, []);

  useEffect(() => {
    const dashboardTourComplete = getItem(DASHBOARD_TOUR_COMPLETE_STORAGE_KEY);
    if (dashboardTourComplete) {
      setDashboardTourFinished(true);
      return;
    }

    if (episodes.find(({ completed }) => completed))
      setDashboardTourActive(true);
  }, [seasonId, episodes]);

  useEffect(() => {
    lastTab = currentTab;
  }, [currentTab]);

  useEffect(() => {
    if (currentSeason) {
      const episodes = seasons.find((s) => s.id === currentSeason.id)?.episodes;
      setEpisodes(episodes?.length ? episodes : []);
    } else {
      setEpisodes([]);
    }
  }, [currentSeason, seasons]);

  const onPublishEpisode = () => {
    if (currentSeason && selectedEpisode) {
      gameEngine.episodes.publishEpisode(selectedEpisode).then(() => {
        setSelectedEpisode(null);
      });
    }
  };

  const onSelectSeason = (seasonId: Season["id"]) => {
    history.push(`/seasons/${seasonId}`);
  };

  const onFinishTour = () => {
    setDashboardTourActive(false);

    setTimeout(() => {
      setDashboardTourFinished(true);
    }, 500);
  };

  const onPlayEpisode = (episode: Episode) => {
    if (episode.format?.length) {
      onPlay(episode);
    } else {
      if (playerSettingsFilled) {
        onPlay(episode);
      } else {
        setEpisodeToPlay(episode);
        setShowAvatarSelectionModal(true);
      }
    }
  };

  const hasTopics = !!currentSeason?.topics.length;

  const isVisible = useCallback(
    (episode: Episode) => {
      if (episode.hidden) {
        if (episode.payload || episode.completed) return true;
        return false;
      }
      return true;
    },
    [episodes]
  );

  const getSeason = () => (
    <>
      {dashboardTourActive && (
        <DashboardTour
          contentRef={contentRef}
          onFinish={onFinishTour}
          tourData={tourData}
          storageKey={DASHBOARD_TOUR_COMPLETE_STORAGE_KEY}
          containerDataAttribute="episodes-tour-container"
        />
      )}
      <StyledHeader>
        <IconButton
          data-dashboard-tour="episodes-list-back-button"
          size="medium"
          color="inherit"
          onClick={() => history.push("/seasons/")}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={device.browser ? "h3" : "h5"} component="h1">
          {currentSeason?.title}
        </Typography>
      </StyledHeader>
      {device.browser && (
        <StyledFurtherInfo>
          <StyledFurtherInfoText
            dangerouslySetInnerHTML={{
              __html: currentSeason?.info || "",
            }}
          />
        </StyledFurtherInfo>
      )}
      {hasTopics && <StyledHeaderDivider />}
      {device.mobile && (
        <>
          <StyledInfoButtonsRow>
            <StyledInfoButton
              isSelected={furtherInfoShowed}
              variant="outlined"
              onClick={() => setFurtherInfoShowed((showed) => !showed)}
            >
              <FormattedMessage id="dashboard.page.home.button.further-information" />
            </StyledInfoButton>
            {hasTopics && (
              <StyledInfoButton
                variant="outlined"
                isSelected={thematicFocusShowed}
                onClick={() => setThematicFocusShowed((showed) => !showed)}
              >
                <FormattedMessage id="dashboard.page.home.button.thematic-focus" />
              </StyledInfoButton>
            )}
          </StyledInfoButtonsRow>
          {furtherInfoShowed && (
            <StyledFurtherInfo>
              <StyledFurtherInfoText
                dangerouslySetInnerHTML={{
                  __html: currentSeason?.info || "",
                }}
              />
            </StyledFurtherInfo>
          )}
          {thematicFocusShowed && hasTopics && (
            <StyledInfoSection>
              <StyledInfoSectionTextContainer>
                <StyledInfoSectionTitle>
                  <FormattedMessage id="dashboard.page.home.title" />
                </StyledInfoSectionTitle>
                {currentSeason?.topics.map(
                  ({ competenceAreaId, description }, i) => (
                    <div key={i}>
                      <CompetenceIcon
                        competenceAreaId={competenceAreaId}
                        index={i}
                      />
                      <Typography variant="body1" component="p" my={1}>
                        {getName(description)}
                      </Typography>
                    </div>
                  )
                )}
              </StyledInfoSectionTextContainer>
            </StyledInfoSection>
          )}
        </>
      )}
      <StyledEpisodesList>
        {device.browser && hasTopics && (
          <StyledInfoSection>
            <StyledInfoSectionTextContainer>
              <StyledInfoSectionTitle>
                <FormattedMessage id="dashboard.page.home.title" />
              </StyledInfoSectionTitle>
              <List>
                {currentSeason?.topics.map(
                  ({ competenceAreaId, description }, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <CompetenceIcon
                          competenceAreaId={competenceAreaId}
                          index={i}
                        />
                      </ListItemIcon>
                      <ListItemText primary={getName(description)} />
                    </ListItem>
                  )
                )}
              </List>
            </StyledInfoSectionTextContainer>
          </StyledInfoSection>
        )}
        <MicrolearningContext.Provider value={microlearningHook}>
          {episodes.map(
            (episode) =>
              isVisible(episode) && (
                <DashboardHomeItem
                  seasonId={currentSeason?.id || ""}
                  onPlay={() => onPlayEpisode(episode)}
                  key={episode.id}
                  allEpisodes={episodes}
                  episode={episode}
                  onSelectTab={(tab) =>
                    setCurrentTab({ episodeId: episode.id, tab })
                  }
                  currentTab={currentTab}
                  isAdmin={isAdmin}
                  onSelectEpisode={() => setSelectedEpisode(episode)}
                  assetsUrl={currentSeason?.assetsURL}
                />
              )
          )}
        </MicrolearningContext.Provider>
      </StyledEpisodesList>

      {selectedEpisode && (
        <PopUp
          withConfetti={false}
          handleClose={() => setSelectedEpisode(null)}
          actionsBody={
            <>
              <StyledActionButton
                buttonType="cancel"
                onClick={() => setSelectedEpisode(null)}
              >
                <FormattedMessage id="dashboard.page.home.publish.btn.cancel" />
              </StyledActionButton>
              <StyledActionButton
                buttonType="confirm"
                onClick={() => onPublishEpisode()}
              >
                <FormattedMessage id="dashboard.page.home.publish.btn.confirm" />
              </StyledActionButton>
            </>
          }
        >
          <StyledDialogPopUpHeader>
            <StyledDialogPopUpHeaderTitle>
              <FormattedMessage id="dashboard.page.home.publish.message" />
            </StyledDialogPopUpHeaderTitle>
          </StyledDialogPopUpHeader>
        </PopUp>
      )}
    </>
  );

  const getSeasonsPage = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MissionIndividualProgressCard />
      </Grid>

      <Recommendations onPlayEpisode={onPlayEpisode} seasons={seasons} />

      <Grid item xs={12}>
        <Seasons seasons={seasons} onSelectSeason={onSelectSeason} />
      </Grid>
    </Grid>
  );

  let content: JSX.Element;
  if (currentSeason) {
    content = getSeason();
  } else {
    content = getSeasonsPage();
  }

  return (
    <>
      <DashboardBanner bannerType={bannerType} />
      <StyledContainer
        maxWidth="lg"
        data-e2e-dashboard-overview-page
        data-episodes-tour-container
      >
        {content}
        <PlayerProfileModal
          open={showAvatarSelectionModal}
          close={() => setShowAvatarSelectionModal(false)}
          onSave={() => episodeToPlay && onPlay(episodeToPlay)}
        />
      </StyledContainer>
    </>
  );
};

export default DashboardHome;

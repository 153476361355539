let config = {};

switch (process.env.REACT_APP_STAGE) {
  case "dev":
    config = {
      ANIMATIONS_ENABLED: true,
      AUDIO_ENABLED: true,
      SCRIPT_WAIT_FIXED: 100,
      DEBUG: true,
    };
    break;
  case "test":
    config = {
      ANIMATIONS_ENABLED: false,
      AUDIO_ENABLED: false,
      SCRIPT_WAIT_FIXED: 100,
      DEBUG: true,
    };
    break;
}

export default {
  DEBUG: false,
  ANIMATIONS_ENABLED: true,
  AUDIO_ENABLED: true,
  SCRIPT_WAIT_FIXED: false,
  ...config,
};

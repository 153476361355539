import digitalZen from "../apps/digital-zen/i18n/message-de";
import meetingsToolBrowser from "../apps/meetings-tool-browser/i18n/message-de";
import meetingsTool from "../apps/meetings-tool/i18n/message-de";
import { competences } from "./competences-de";

/* We will start by adding the translation strings manually in this files.
later in the process we can automate the process of extracting string and managing translations in dedicated app
*/

const supportedLanguages = {
  "language.en": "Englisch",
  "language.de": "Deutsch",
};

const globalMessages = {
  all: "Alle",
  "global.continue": "Weiter",
  "global.ok": "Ok",
  "global.congratulation": "Glückwunsch!",
  "global.hour": "Uhr",
  "global.contact": "Kontakt",
  "global.contact.tip":
    "Brauchst du Hilfe? Wir geben dir gerne einen Tipp. Möchtest du uns sagen, was dir gut und was dir nicht so gut gefällt? Wir sind dankbar für jedes Feedback -- denn dieses Produkt ist für dich gemacht",
  "global.warning.message":
    "talent::digital funktioniert am besten mit einem mindestens 375 x 660 Pixel großem Browserfenster. Bitte vergrößere dein Browserfenster oder benutze ein Gerät, das ein größeres Browserfenster ermöglicht.",
  "global.warning.confirm": "Weiter",
  "global.contact.blocked":
    "Anscheinend blockiert deine IT-Organisation den Support-Chat. Mehr dazu findest du in diesem <a href='https://talentdigital.freshdesk.com/support/solutions/articles/47001208238-wie-kann-ich-chat-support-aktivieren-' target='_blank'>Artikel</a>.",
  "global.contact.email": `Du kannst uns aber erreichen:
    <ul>
      <li><a href='https://talentdigital.freshdesk.com/support/home' target='_blank'>über unsere Support-Webseite</a>,</li>
      <li><a href='mailto:support@talentdigital.eu' target='_blank'>mit E-Mail</a> oder</li>
      <li>telefonisch unter <a href='tel:+49 211 94252837'>+49 211 94252837</a>.</li>
    </ul>`,
  "global.exit.title": "Episode neu starten?",
  "global.exit.description": "Möchtest du die Episode wirklich neu starten?",
  "global.exit.restart": "Neustart",
  "global.exit.continue": "Episode fortsetzen",
  "global.video.playbackSpeed": "Playback speed",
};

const gameControl = {
  "gameControl.tabs.stats": "Fortschritt",
  "gameControl.tabs.teams": "Teams",
  "gameControl.tabs.profil": "Profil",
  "gameControl.tabs.settings": "Einstellungen",
  "gameControl.stats.engagementPoints": "Score",
  "gameControl.stats.competenceScore": "Kompetenzpunkte",
  "gameControl.stats.progress": "Fortschritt",
  "gameControl.stats.progress.toNextLevel":
    "Noch {percents}% für Level {level}",
  "gameControl.stats.tasks": "Deine Aufgabe(n)",
  "gameControl.profile.badges":
    "Verdiene Abzeichen, in dem du dich auf Entdeckungsreise begibst und während des Spiels Neues ausprobierst! Gelingt es dir, alle Abzeichen freizuschalten?",
  "gameControl.stats.exitGame": "Spiel beenden",
  "gameControl.stats.pauseGame": "Spiel pausieren",
  "gameControl.stats.playGame": "Weiterspielen",
  "gameControl.settings.my-data": "Meine Daten",
  "gameControl.settings.my-player-profile": "Mein Spielerprofil",
  "gameControl.settings.terms-and-conditions": "Rechtliches",
  "gameControl.levelend.uploadFailed":
    "Die Spielergebnisse konnten nicht gespeichert werden. Das kann passieren, wenn du die App lange ohne Aktivität offen lässt, oder wenn deine Internet-Verbindung gestört ist. Dein Spielstand wird gespeichert, wenn du die Seite neu lädst und dich einloggst.",
  "gameControl.modal.player-profile.account.title":
    "Setze deinen Spielernamen und Avatar",
  "gameControl.modal.player-profile.data.title": "Konfiguriere dein Profil",
  "gameControl.modal.player-profile.data.subtitle":
    "Diese Daten werden für anonymisierte Berichte verwendet. ",
  "gameControl.modal.player-profile.data.subtitle.link": "Mehr informationen.",
};

const header = {
  "header.score.heading": "Erspielte Aufgaben in dieser Episode",
  "header.score.no-tests": "Noch keine Aufgaben erledigt.",
  "header.test-level.1": "Anfänger",
  "header.test-level.FOUNDATION": "Anfänger",
  "header.test-level.2": "Fortgeschrittene",
  "header.test-level.INTERMEDIATE": "Fortgeschrittene",
  "header.test-level.3": "Profi",
  "header.test-level.ADVANCED": "Profi",
};

const settings = {
  saveButton: "Speichern und weiter",
  companyName: "Wie soll deine Firma heißen?",
  companyLogo: "Wähle dein Firmenlogo aus",
  playerName: "Dein Spielername",
  playerAvatar: "Wie soll dein Avatar aussehen?",
  playerPassword: "Wähle dein Passwort",
  playerEmailAddress: "Hier steht deine E-Mail-Adresse",
  settingsDone: "Mitarbeiterprofil angelegt. Du bist startklar.",
  profileTitle: "Angestelltenprofil",
};

const boarding = {
  "boarding.start": "Episode starten",
  "boarding.end": "Beende die Episode",
};

const rewinder = {
  "rewinder.title": "Wohin möchtest du zurückspulen?",
  "rewinder.quest.active": "Aktuelle Aufgabe",
  "rewinder.quest.active.restart": "Aktuelle Aufgabe neu starten",
  "rewinder.quest.completed": "Zu dieser Aufgabe zurückspulen",
  "rewinder.quest.skipped": "Aufgabe übersprungen",
};

const certificates = {
  "certificates.certificate": "Zertifikate",
  "certificates.no-certiticates-in-season":
    "Für diese Staffel sind noch keine Zertifikate verfügbar. Bitte wähle eine andere Staffel aus.",
  "certificates.attention":
    "Um das Zertifikat zu erhalten, benötigst du noch Punkte aus den Episoden ",
  "certificates.sorting":
    "Die Episoden-Reihenfolge hier ist nach relativer Leistung sortiert. Aus der hier erstgenannten Episode benötigst du anteilig noch die meisten Punkte um das Zertifikat zu erhalten, aus der letztgenannten Episode anteilig die wenigsten.",

  // TI-2846 - Commented out the certificates that are not used in the season
  // // Season 1
  // "certificates.name.Security Basics": "Grundlagen von Schutz und Sicherheit",
  // "certificates.description.Security Basics":
  //   "<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen von Schutz und Sicherheit nach DigComp 2.1 Kompetenzbereich 4 teilgenommen. Dabei wurden die folgenden Themen behandelt:</p><ul><li>Schutz von Geräten und digitalen Inhalten.</li><li>Verwendung von Passwörtern und Mehrfaktorauthentifizierung.</li><li>Grundlagen von Verschlüsselung und Datenschutz.</li><li>Identifizierung von Grundmustern bei gefährlichen Webseiten, Spam und Phishing.</li><li>Vermeidung von Gesundheitsgefährdungen und Stressfaktoren an digitalen Arbeitsplätzen.</li><li>Energieverbrauch durch Informationstechnologie.</li></ul>",
  // // prettier-ignore
  // "certificates.accredible.description.Security Basics": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen von Schutz und Sicherheit nach DigComp 2.1 Kompetenzbereich 4 teilgenommen. Dabei wurden die folgenden Themen behandelt:

  // * Schutz von Geräten und digitalen Inhalten.
  // * Verwendung von Passwörtern und Mehrfaktorauthentifizierung.
  // * Grundlagen von Verschlüsselung und Datenschutz.
  // * Identifizierung von Grundmustern bei gefährlichen Webseiten, Spam und Phishing.
  // * Vermeidung von Gesundheitsgefährdungen und Stressfaktoren an digitalen Arbeitsplätzen.
  // * Energieverbrauch durch Informationstechnologie.`,
  "certificates.certificate.conjuction": " von ",
  // "certificates.name.Automation Basics":
  //   "Grundlagen der Arbeitsautomatisierung",
  // // prettier-ignore
  // "certificates.description.Automation Basics":
  //   "<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen digitaler Problemlösung nach DigComp 2.1 teilgenommen. Dabei wurden die folgenden Themen im Bereich 5.2, Bedarfsanalyse und technologische Antworten, behandelt:</p>  <ul><li>Auswahl und Anpassung von Standardanwendungen bei gegebenen Anforderungen.</li><li>Relevanz von Nutzungsbedingungen bei IT-Dienstleistungen.</li><li>Verständnis von automatisierbaren Prozessen, sowie Beschreibung und Automatisierung elementarer Prozesse.</li></ul>",
  // "certificates.accredible.description.Automation Basics": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen digitaler Problemlösung nach DigComp 2.1 teilgenommen. Dabei wurden die folgenden Themen im Bereich 5.2, Bedarfsanalyse und technologische Antworten, behandelt:
  // * Auswahl und Anpassung von Standardanwendungen bei gegebenen Anforderungen.
  // * Relevanz von Nutzungsbedingungen bei IT-Dienstleistungen.
  // * Verständnis von automatisierbaren Prozessen, sowie Beschreibung und Automatisierung elementarer Prozesse.`,

  // Season Roadmap
  "certificates.name.Wenn Ideen fliegen lernen": "Wenn Ideen fliegen lernen",

  "certificates.description.Wenn Ideen fliegen lernen":
    "<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen von Schutz und Sicherheit nach DigComp 2.1 Kompetenzbereich 4 teilgenommen. Dabei wurden die folgenden Themen behandelt:</p><ul><li>Schutz von Geräten und digitalen Inhalten.</li><li>Verwendung von Passwörtern und Mehrfaktorauthentifizierung.</li><li>Grundlagen von Verschlüsselung und Datenschutz.</li><li>Identifizierung von Grundmustern bei gefährlichen Webseiten, Spam und Phishing.</li><li>Vermeidung von Gesundheitsgefährdungen und Stressfaktoren an digitalen Arbeitsplätzen.</li><li>Energieverbrauch durch Informationstechnologie.</li></ul>",

  // prettier-ignore
  "certificates.accredible.description.Wenn Ideen fliegen lernen": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen von Schutz und Sicherheit nach DigComp 2.1 Kompetenzbereich 4 teilgenommen. Dabei wurden die folgenden Themen behandelt:

  * Schutz von Geräten und digitalen Inhalten.
  * Verwendung von Passwörtern und Mehrfaktorauthentifizierung.
  * Grundlagen von Verschlüsselung und Datenschutz.
  * Identifizierung von Grundmustern bei gefährlichen Webseiten, Spam und Phishing.
  * Vermeidung von Gesundheitsgefährdungen und Stressfaktoren an digitalen Arbeitsplätzen.
  * Energieverbrauch durch Informationstechnologie.`,

  // Season 2
  "certificates.name.Digital Collaboration":
    "Grundlagen der digitalen Zusammenarbeit",
  "certificates.description.Digital Collaboration": `<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen digitaler Kollaboration nach DigComp 2.1 teilgenommen.
     Dabei wurden die folgenden Themen im Bereich 2.1, Interagieren durch digitale Technologien, und 2.4, Zusammenarbeit durch digitale Dienste, behandelt:</p>
     <ul>
      <li>Nutzung digitaler Werkzeuge und Technologien für kollaborative Prozesse und kollaborative Zusammenarbeit im Team.</li>
      <li>Grundlagen von Chatbots.</li>
      <li>Modernes Projektmanagement.</li>
      <li>Digitale Echtzeit-Kollaboration.</li>
     </ul>`,
  "certificates.accredible.description.Digital Collaboration": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Grundlagen digitaler Kollaboration nach DigComp 2.1 teilgenommen. Dabei wurden die folgenden Themen im Bereich 2.1, Interagieren durch digitale Technologien, und 2.4, Zusammenarbeit durch digitale Dienste, behandelt:
  * Nutzung digitaler Werkzeuge und Technologien für kollaborative Prozesse und kollaborative Zusammenarbeit im Team.
  * Grundlagen von Chatbots.
  * Modernes Projektmanagement.
  * Digitale Echtzeit-Kollaboration.`,

  // Season 93
  "certificates.name.OpenBlue Solutioneer": "OpenBlue Solutioneer",
  "certificates.description.OpenBlue Solutioneer": `<p>Der Teilnehmer bzw. die Teilnehmerin hat an einem Kurs zum Vertrieb von OpenBlue-basierten Lösungen teilgenommen.
    Dabei wurden die folgenden behandelt:</p>
    <ul>
      <li>Lösungsorientierter Vertrieb.</li>
      <li>Kundenlösungen mit OpenBlue.</li>
      <li>Grundlagen digitales Gebäudemanagement.</li>
    </ul>`,
  "certificates.accredible.description.OpenBlue Solutioneer": `Der Teilnehmer bzw. die Teilnehmerin hat an einem Kurs zum Vertrieb von OpenBlue-basierten Lösungen teilgenommen. Es wurden die folgenden Aspekte abgedeckt:
  * Lösungsorientierter Vertrieb.
  * Kundenlösungen mit OpenBlue.
  * Grundlagen digitales Gebäudemanagement.
  `,

  // Season 94
  "certificates.name.Das Bürgergeld in der Praxis":
    "Das Bürgergeld in der Praxis",

  "certificates.description.Das Bürgergeld in der Praxis": `<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Bürgergelds teilgenommen. Dabei wurden die folgenden Themen behandelt:</p>
  <ul>
     <li>Bürgergeld-Detailregelungen (gesetzliche Neuerungen - insbesondere zu Regelsatzerhöhung, Karenzzeit, Vermögenswerten, Einkommensfreibeträgen, usw.)</li>
     <li>Beratungsansätze (Beratung auf Augenhöhe, Potenzialanalyse, Kooperationsplan, usw.)</li>
     <li>Qualifizierungsmechanismen (Qualifizierungsgebot, zielgerichtete Unterstützung, Weiterbildungsgeld, usw.)</li>
  </ul>`,

  "certificates.accredible.description.Das Bürgergeld in der Praxis": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Bürgergelds teilgenommen. Dabei wurden die folgenden Themen behandelt:
   * Bürgergeld-Detailregelungen (gesetzliche Neuerungen – insbesondere zu Regelsatzerhöhung, Karenzzeit, Vermögenswerten, Einkommensfreibeträgen, usw.)
   * Beratungsansätze (Beratung auf Augenhöhe, Potenzialanalyse, Kooperationsplan, usw.)
   * Qualifizierungsmechanismen (Qualifizierungsgebot, zielgerichtete Unterstützung, Weiterbildungsgeld, usw.)`,

  // Season 95
  "certificates.name.Der Kooperationsplan im Bürgergeld":
    "Der Kooperationsplan im Bürgergeld",
  "certificates.description.Der Kooperationsplan im Bürgergeld": `<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Kooperationsplans teilgenommen. Dabei wurden die folgenden Themen behandelt:</p>
  <ul>
     <li>Grundlagen des Kooperationsplans (Rahmen, Rechtsgrundlage, usw.)</li>
     <li>Inhalte des Kooperationsplans (Abschluss, Form, Inhalte, usw.)</li>
     <li>Potenzialanalyse (Rechtsgrundlagen, Rahmen, Inhalte, usw.)</li>
     <li>Kooperationsplan in der Praxis (Einladungsprozedere, Erstellung, Überprüfung, usw.)</li>
     <li>Schlichtungsverfahren (Rechtsgrundlagen, Rahmen, Inhalte, usw.)</li>
     <li>Leistungsminderungen (Neuerungen, Rechtsgrundlagen, Ablauf, usw.)</li>
  </ul>`,

  "certificates.accredible.description.Der Kooperationsplan im Bürgergeld": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Kooperationsplans teilgenommen. Dabei wurden die folgenden Themen behandelt:
  *Grundlagen des Kooperationsplans (Rahmen, Rechtsgrundlage, usw.)
  *Inhalte des Kooperationsplans (Abschluss, Form, Inhalte, usw.)
  *Potenzialanalyse (Rechtsgrundlagen, Rahmen, Inhalte, usw.)
  *Kooperationsplan in der Praxis (Einladungsprozedere, Erstellung, Überprüfung, usw.)
  *Schlichtungsverfahren (Rechtsgrundlagen, Rahmen, Inhalte, usw.)
  *Leistungsminderungen (Neuerungen, Rechtsgrundlagen, Ablauf, usw.)`,

  // Regular translations
  "certificates.download": "Zertifikat herunterladen",
  "certificates.threshold":
    "Du brauchst mindestens {threshold}%, um ein Zertifikat zu erhalten.",
};

const mailbox = {
  "mailbox.datetime.today": "Heute",
  "mailbox.attachments.storage.openButton": "In der Cloud speichern",
  "mailbox.attachments.storage.saveToFile": "In Dateien sichern",
  "mailbox.attachments.storage.project": "Projekt",
  "mailbox.attachments.storage.saveConfirmationMessage":
    "Dokument erfolgreich in der Cloud gespeichert.",
};

const search = {
  "search.searchLabel": "Durchsuche das Web",
  "search.searchTitle": "Finder",
  "search.noResults": "Keine Einträge gefunden.",
};

const appStore = {
  "appStore.title": "App-Store",
  "appStore.search.title": "Suchen",
  "appStore.list.downloadButton": "Download",
  "appStore.list.runButton": "Öffnen",
  "appStore.list.sponsored": "Gesponsert",
  "appStore.list.noAppsFoundMessage":
    "Leider haben wir dazu (noch) kein Angebot. Bitte starte eine neue Suche.",
  "appStore.info.dialog.text":
    "Es können keine weiteren Anwendungen der gleichen Kategorie heruntergeladen werden.",
  "appStore.info.dialog.error": "Fehler beim Installieren der App",
};

const calendar = {
  "calendar.meetingAppointment.title": "Name des Termins",
  "calendar.meetingAppointment.allDay": "Den ganzen Tag",
  "calendar.meetingAppointment.cancelButton": "Abbrechen",
  "calendar.meetingAppointment.saveButton": "Termin speichern",
  "calendar.meetingAppointment.videoCallText":
    "Videokonferenz- und Einwahldetails wurden hinzugefügt.",
  "calendar.meetingAppointment.start": "Start",
  "calendar.meetingAppointment.end": "Ende",
  "calendar.selectActorsDialog.title": "Gäste",
  "calendar.selectActorsDialog.save": "Gäste hinzufügen",
  "calendar.meetingAppointment.error.startTime":
    "Du kannst keinen Termin erstellen, der vor 09:00 Uhr beginnt.",
  "calendar.meetingAppointment.error.endTime":
    "Du kannst keinen Termin erstellen, der nach 17:00 Uhr endet.",
  "calendar.meetingAppointment.error.endBeforeStart":
    "Das Ende der Besprechung kann nicht vor dem Beginn liegen.",
};

const chatBot = {
  "chatbot.btn.save.trigger": "Auslöser speichern",
  "chatbot.btn.save.action": "Regel speichern",
  "chatbot.btn.cancel": "Abbrechen",
  "chatbot.label.keyword": "Stichwort",
  "chatbot.label.keywords": "Stichwortliste",
  "chatbot.label.select.subject": "Titel",
  "chatbot.label.when": "Wenn",
  "chatbot.label.then": "Dann",

  "chatbot.dropdown.select.label.selectTrigger": "Auslöser auswählen",
  "chatbot.dropdown.select.label.selectDepartment": "Abteilung auswählen",
  "chatbot.dropdown.select.label.selectEmployee": "Mitarbeiter auswählen",
  "chatbot.dropdown.select.label.addAttributes":
    "Füge deiner Nachricht Attribute hinzu",
  "chatbot.dropdown.select.label.saveAndAssign":
    "Besuchernachricht und Bewertung senden an:",

  "chatbot.select.item.start": "Kunde besucht Website",
  "chatbot.select.item.with_keywords":
    "Kundennachricht enthält eines der Schlüsselwörter",
  "chatbot.select.item.without_keywords":
    "Kundennachricht enthält keine Schlüsselwörter",
  "chatbot.select.item.correct_1": "Häufig gestellte Fragen und Antworten",
  "chatbot.select.item.incorrect_1":
    "Bubblr - so einfach können es sogar deine Kinder",
  "chatbot.select.item.correct_2":
    "Ob Badewanne oder Brause – Bubblr funktioniert sogar zu Hause.",
  "chatbot.select.item.incorrect_2":
    "Alles, was Sie über Bubblr wissen wollten und sich trauten zu fragen",
  "chatbot.select.item.correct_3":
    "Nachhaltig, umweltbewusst und gesund, mit Bubblr läuft das Leben rund!",
  "chatbot.select.item.incorrect_3":
    "Der Hauptgrund, warum du Bubblrn solltest",
  "chatbot.select.item.sales": "Vertriebsteam",
  "chatbot.select.item.hr": "Personalabteilung",
  "chatbot.select.item.development": "Produktentwicklungsabteilung",
  "chatbot.select.item.it": "IT Abteilung",
  "chatbot.select.item.warranty": "Abteilung für Garantieservice",
  "chatbot.select.item.costumer": "Kundendienstabteilung",

  "chatbot.creator.message.label": "Schreibe deine Nachricht",
  "chatbot.creator.member.defaultMessage":
    "Besuchernachricht direkt weiterleiten",
  "chatbot.creator.ticket.defaultMessage": "Neues Ticket erstellt von Chatbot",
  "chatbot.creator.connect.defaultMessage":
    "Verbinde den Besucher per LiveChat mit",

  "chatbot.header.sidebar.rule": "Regel erstellen",
  "chatbot.header.sidebar.action": "Aktion auswählen",

  "chatbot.action.menu.message": "Antwort senden",
  "chatbot.action.menu.ticket": "Ticket erstellen",
  "chatbot.action.menu.member": "Besuchernachricht an Teammitglied senden",
  "chatbot.action.menu.connect": "Weiterleitung an",

  "chatbot.defaultMessage.chatbotRule2SubquestRule1":
    "Dabei gibt es auf unserer Seite einen echt guten Fragen und Antworten-Bereich",

  "chatbot.node.title.start": "Start",
  "chatbot.node.subtitle.start": "Erstellen eine neue Regel für deinen Chatbot",
  "chatbot.node.title.welcome": "Willkommensnachricht",
  "chatbot.node.subtitle.welcome": "Starte den Chat mit einer Nachricht.",
  "chatbot.node.greeting.message":
    "Hallo! Ich bin dein persönlicher Asisstent und helfe dir bei allen Fragen rund um Bubblr. Was kann ich heute für dich tun?",

  "chatbot.flow.item.trigger.with.keywords":
    "Wenn die Nachricht Schlüsselwörter enthält.",
  "chatbot.flow.item.trigger.without.keywords":
    "Wenn die Nachricht keine Schlüsselwörter enthält.",
  "chatbot.flow.item.action.message":
    "Dann automatische Antwort mit Artikel senden",
  "chatbot.flow.item.action.ticket": "Dann erstelle ein Ticket",
  "chatbot.flow.item.action.member":
    "Dann Nachricht an Teammitglied weiterleiten",
  "chatbot.flow.item.action.connect": "Dann verbinde dich im Live-Chat",
  "chatbot.end.message": "Vielen Dank, dass Sie ChatBot verwenden!",
  "chatbot.close.button": "Beenden",
};

const socialNetwork = {
  "socialNetwork.btn.codeActivation": "Code aktivieren",
  "socialNetwork.btn.login": "Login",
  "socialnetwork.chat.title": "News",
  "socialNetwork.codeActivation.btn": "Code aktivieren",
  "socialNetwork.codeActivation.text": "Bitte gib deinen Code ein:",
  "socialNetwork.codeActivation.wrongCode": "Code ist ungültig.",
  "socialNetwork.commentInput.placeholder": "Beitrag verfassen",
  "socialNetwork.login.welcomeText":
    "Willkommen bei science:LAB. Logge dich ein oder verwende deinen Einladungscode.",
  "socialNetwork.profile.btn": "Profil erstellen",
  "socialNetwork.profile.input.company": "Unternehmensname",
  "socialNetwork.profile.input.fullName": "Name",
  "socialNetwork.profile.radio.label": "Was führt sie her?",
  "socialNetwork.profile.radio.value1": "Ich suche einen neuen Job.",
  "socialNetwork.profile.radio.value2": "Ich suche Verstärkung für mein Team.",
  "socialNetwork.profile.radio.value3": "Ich schaue mich nur um.",
  "socialNetwork.profile.subText": "Hinterlege dein Profil",
  "socialNetwork.profile.welcomeText": "Willkommen",
  "socialNetwork.subtitle":
    "Geheimes Netzwerk: Zutritt nur für WissenschaftlerInnen und ErfinderInnen",
  "socialNetwork.title": "science:LAB",
  "socialNetwork.userIdentification.btn": "Einloggen",
  "socialNetwork.userIdentification.errorText":
    "Zugangsdaten konnten nicht gefunden werden. Bitte überprüfe deine Daten.",
  "socialNetwork.userIdentification.password": "Passwort",
  "socialNetwork.userIdentification.text": "Bitte gib deine Zugangsdaten ein.",
  "socialNetwork.userIdentification.userName": "Benutzername",
  "socialNetwork.userIdentification.wrongCredentials":
    "Benutzername oder Passwort ist ungültig.",
};

const chat = {
  "chat.title": "Messenger",
  "chat.message.player": "Spieler",
  "chat.groupAdded": 'Sie wurden zur Gruppe "{roomName}" hinzugefügt.',
};

const animation = {
  "animation.hacker.message":
    "Ich weiß, was ihr vorhabt! Und ich werde nicht zulassen, dass euer Projekt erfolgreich wird! – Macht euch auf euren Untergang gefasst! Hahahahah!",
  "animation.dataRecovered.message":
    "Die Transaktion war erfolgreich.<br/>Die Daten werden nun freigegeben.",
  "animation.dataRecovered.message1": "Daten wieder herstellen",
  "animation.dataRecovered.message2": "Wiederherstellung...",
  "animation.dataRecovered.message3": "Daten freigegeben!",
  "animation.dataDeleted.message": "Daten werden gelöscht",
  "animation.dataDeleted.message1": "Daten löschen",
  "animation.dataDeleted.message2": "Daten gelöscht! FÜR IMMER!",
  "animation.dataDeletedHacker.message":
    "Damit sind deine Daten für immer vernichtet!",
};

const decisions = {
  "decisions.title": "Ich entscheide mich für:",
  "decisions.confirmHeader": "Bist du sicher?",
  "decisions.confirmOption": "Ja",
  "decisions.rejectOption": "Nein",
};

const root = {
  "root.timePassing": "Zeit vergeht",
};

const report = {
  "report.title": "Dein Spielreport",
  "report.points": "Aktivitäts-Münzen",
  "report.competenceScore": "Kompetenzpunkte",
  "report.badges": "Abzeichen",
  "report.competences": "Gesammelte Kompetenz-Diamanten",
  "report.departments": "Abteilungen",
  "report.missions": "Missionen",
  "report.adminCockpit": "Management-Cockpit",
  "report.competence1": "Umgang mit Informationen und Daten",
  "report.competence1.tooltip": `
  <h3>Informations- und Datenkompetenz</h3>
  <p><i>Ist die Fähigkeit souverän und produktiv mit digitalen Informationen und Daten umzugehen.</i></p>
  <p>Verwendet man Suchoperatoren bei einer Internetrecherche, um die Ergebnisqualität zu verbessern?<br/>
  Werden die angezeigten Informationsquellen anschließend weiter überprüft und miteinander verglichen, um Relevanz, Glaubwürdigkeit und Richtigkeit zu überprüfen?<br/>
  Wie werden die gefundenen Inhalte gespeichert, verwaltet, analysiert und organisiert?</p>
  `,
  "report.competence2": "Kommunikation und Kollaboration",
  "report.competence2.tooltip": `
  <h3>Kommunikation und Kollaboration</h3>
  <p><i>Ist die Fähigkeit digitale Technologien zu verwenden, um den gemeinsamen Austausch von Daten und Informationen zu erleichtern.</i></p>
  <p>Werden zum Beispiel E-Mail, (mobile) Messenger und soziale Netzwerke sinnvoll für die Zusammenarbeit genutzt?<br />
  Wird auch im digitalen Umfeld auf eine respektvolle und wertschätzende Kommunikation geachtet?<br />
  Gestaltet man seine persönliche Identität und Reputation sorgfältig?<br />
  Und berücksichtigt man, dass kulturelle und generationsübergreifende Vielfalt auch online gilt?</p>
  `,
  "report.competence3": "Erzeugen digitaler Inhalte",
  "report.competence3.tooltip": `
  <h3>Erstellung digitaler Inhalte</h3>
  <p><i>Ist die Kompetenz digitale Inhalte selbstständig zu erzeugen und zu verbreiten.</i></p>
  <p>Fällt es z.B. einfach, Video-, Audio- und Bilddateien zu erzeugen und zu bearbeiten?<br />
  Werden fortgeschrittene Funktionen von Text- und Tabellenkalkulationsprogrammen genutzt (z.B. Serienbriefe, Pivot-Tabellen, usw.)?<br />
  Lassen sich eigenständig digitale Anwendungen (z.B. Websiten oder Apps) erzeugen, weil die Kenntnisse (mindestens) einer Programmiersprache vorhanden sind?<br />
  In diesem Zusammenhang spielt auch das Wissen um Copyright, Lizenzen und Urheberrecht eine wesentliche Rolle.</p>
  `,
  "report.competence4": "Sicherheit",
  "report.competence4.tooltip": `
  <h3>Sicherheit</h3>
  <p><i>Ist die Kompetenz (persönliche) Daten und Informationen sowie Hardware zu schützen.</i></p>
  <p>Werden z.B. eine Firewall und Anti-Virus Programme eingesetzt und durch Updates auf dem aktuellen Stand gehalten?<br />
  Werden wichtige Dateien in verschlüsselter Form weitergegeben und durch Backups gesichert?<br />
  Werden Anwendungen durch wechselnde und anspruchsvolle Passwörter geschützt?<br />
  Lässt sich eine Phishing-Mail von einer vertrauensvollen Information unterscheiden und existiert ein Plan, was zu tun ist, wenn ein Hackingangriff stattgefunden hat?</p>
  `,
  "report.competence5": "Lösung von Problemen",
  "report.competence5.tooltip": `
  <h3>Digitale Problemlösung</h3>
  <p><i>Ist die Kompetenz digitale Werkzeuge bewusst dafür einsetzen, bestimmte Problemsituationen zu lösen.</i></p>
  <p>Ist man in der Lage, digitale Anwendungen auszuwählen, die den Bedürfnissen der Organisation entsprechen?<br />
  Kann die Effektivität von digitalen Programmen oder Services beurteilt werden, um existierende Probleme zu lösen?<br />
  Hierzu zählt auch die fortlaufende Beobachtung aktueller technologischer Entwicklungen.</p>
  `,
  "report.takeAways": "Weiterführende Informationen für Episode ",
  "report.textToAvoidHacker":
    "Hacker-Angriffe stellen auch in der echten Welt eine Gefahr dar.<br/>Hier findest du ein paar Hinweise, wie du diese vermeiden kannst:",
  "report.helpfulInfo":
    "Gewünscht Informationen online schnell aufzufinden, ist hilfreich und spart Zeit. Hier erfährst du wie du deine Suche verbessern kannst:",
  "report.qualifyButton": "Passende Weiterbildungsangebote für Dich",
  "report.goDashboardButton": "Zur Episodenübersicht",
  "report.continue": "Weiter",
  "report.noBadges": "Keine Abzeichen.",
  "report.season": "Staffel ",
  "report.solved": "gelöst",
};

const seasonEnd = {
  "seasonend.congratulations": `Super, du hast die Staffel "{season_title}" talent::digital abgeschlossen. {season_end_message}`,
  "seasonend.next": "Weiter",
};

const videoCall = {
  "videoCall.endCall.text": "Die Konferenz ist beendet.",
  "videocall.home.insertCode": "Geben Sie einen Konferenzcode ein",
  "videocall.home.select": "Wählen Sie eine Videokonferenz aus der Liste aus",
  "videocall.code.title": "Geben Sie den Konferenzcode ein",
  "videocall.code.button": "Konferenz betreten",
  "videocall.select.title": "Geplante Videokonferenzen",
  "videocall.notification.join": "Beitreten",
  "videocall.notification.incoming": "Eingehender Anruf",
  "videocall.conferenceRoom.disabled":
    " - Die Konferenz hat noch nicht begonnen",
  "videocall.activeCall.sendTranslationRequest": "Übersetzungsanfrage senden",
  "videocall.activeCall.disableTranslation": "Übersetzung deaktivieren",
  "videocall.activeCall.startRecording": "Aufnahme starten",
  "videocall.activeCall.stopRecording": "Aufnahme beenden",
  "videoCall.endCall.recording":
    "Ein Video Ihres Anrufs wurde unter VideoCall - salesCallWithJoao.mp4 gespeichert.",
  "videoCall.endCall.transcript":
    "Eine Abschrift Ihres Gesprächs wurde unter VideoCall - salesCallWithJoao.txt gespeichert.",
  "videoCall.title": "{conversationPartnerName}, {companyName}: {topic}",
  "videoCall.quest.title": "Videokonferenz",
  "videoCall.quest.description":
    "Videogespräch mit {conversationPartnerName} über {topic}",
};

const news = {
  "news.search.placeholder": "Nach Artikeln suchen",
  "news.search.subtitle": 'Ihre Suchergebnisse zu "{term}"',
  "news.search.noResults": 'Keine Suchergebnisse für "{term}"',
  "news.search.moreArticles": "Weitere Artikel die passen könnten",

  "news.header": "News",
  "news.title": "Top News",
  "news.toolbar.input.placeholder": "Nachrichten durchsuchen...",
  "news.search.noFavorites":
    "Sobald Du einen Artikel gespeichert hast, findest Du ihn hier",
  "news.favorite.subtitle": "Meine gemerkten Artikel",
  "news.article.nps.title":
    "Würdest du diesen Artikel einem Kollegen zum Lesen empfehlen?",
  "news.article.nps.recommend": "Auf keinen Fall",
  "news.article.nps.noRecommend": "Ja, sicher!",
  "news.assetManager.title": "Dateien verwalten",
  "news.assetManager.delete": "Löschen",
  "news.assetManager.askDelete":
    'Sind Sie sicher, dass Sie "{fileName}" löschen wollen?',
  "news.assetManager.confirmDelete": "Ja, Löschen",
  "news.assetManager.upload": "Datei hochladen",
  "news.assetManager.close": "Schließen",
  "news.assetManager.select": "Auswählen",
  "news.assetManager.select-image": "Bild auswählen",
  "news.assetManager.select-video": "Video auswählen (optional)",
  "news.assetManager.fileAlreadyExists":
    "Es existiert bereits eine Datei mit demselben Namen!",
  "news.assetManager.cancel": "Abbrechen",
  "news.assetManager.select-file": "Datei auswählen",
  "news.assetManager.save": "Speichern",
  "news.assetManager.keep_original_image": "Originalgröße beibehalten",

  "news.menu.tooltip.my-favourites": "Meine Favoriten",
  "news.menu.tooltip.manage-files": "Dateien verwalten",
  "news.menu.tooltip.add-new": "Neue hinzufügen",

  "news.editor.abstract.title": "Vorschautext (fettgedruckt)",
  "news.article.remove.title": "Artikel dauerhaft löschen?",
  "news.article.remove.content":
    "Wenn Sie diesen Artikel löschen, können Sie ihn nicht wiederherstellen. Möchten Sie es löschen?",
  "news.article.remove.cancel-button": "Abbrechen",
  "news.article.remove.article": "Artikel",
  "news.editor.unsaved-changes": "Du hast nicht gespeicherte Änderungen",
  "news.editor.saved-changes": "Alle Änderungen wurden gespeichert",
  "news.editor.author": "Autor",
  "news.editor.enter-title": "Titel hinterlegen",
  "news.editor.add-tag": "Tag hinzufügen",

  "news.list.item.tooltip.edit": "Der Artikel kann bearbeitet werden",
  "news.list.item.tooltip.remove": "Der Artikel kann entfernt werden",
  "news.list.noArticle": "Keine Artikel gespeichert",

  "news.article.untitled": "Unbenannt",
  "news.article.no-abstract": "keine Zusammenfassung",
};

const tour = {
  "settings.welcome": "Spielsteuerung",
  "settings.overview": "Möchtest du die Spielsteuerung erklärt bekommen?",
  "settings.start": "Ja",
  "settings.skip": "Überspringen",

  "tour.screen1.title": "Episoden-Fortschritt",
  "tour.screen1.description":
    "Hier siehst du, wie weit du bereits gekommen bist.",
  "tour.screen2.title": "Kompetenz-Diamanten",
  "tour.screen2.description":
    "Löse Aufgaben, um mehr Diamanten zu erhalten. Klicke hier, um die Details der erledigten Aufgaben zu sehen.",
  "tour.screen3.title": "Aktivitäts-Münzen",
  "tour.screen3.description":
    "Je häufiger und intensiver du talent::digital nutzt, umso mehr Aktivitäts-Punkte sammelst du.",
  "tour.screen4.title": "Persönliche Mitteilungen",
  "tour.screen4.description":
    "Hier bekommst du persönliche Mitteilungen von den Spielcharakteren.",
  "tour.screen5.title": "Zeitreise",
  "tour.screen5.description":
    "Hier siehst du, was gerade zu tun ist, und kannst an vorige Punkte der Episode zurückspringen.",
  "tour.screen6.title": "Home-Taste",
  "tour.screen6.description": "Hier kannst du zur Startseite zurückkehren.",
  "tour.screen7.title": "Spiel pausieren",
  "tour.screen7.description": "Hier kannst du das Spiel unterbrechen.",
  "tour.screen8.title": "Kontaktdaten deines Charakters",
  "tour.screen8.description":
    "Hier kannst du schnell und einfach auf die Kontaktdaten deines Charakters zugreifen. Klicke einfach auf deinen Avatar, um die Kontaktinformationen zu öffnen, und klicke dann auf die Informationen, um sie in die Zwischenablage zu kopieren.",
  "tour.btn": "Weiter",
  "tour.finalBtn": "Fertig",
};

const onboardingBuyer = {
  "onboardingBuyer.dashboard.seasons.season1": "01. Wenn Ideen fliegen lernen.",

  "onboardingBuyer.scenarios.button.start": "Starten",
  "onboardingBuyer.scenarios.tooltip.locked": "Nicht im Trial verfügbar",

  "onboardingBuyer.scenarios.scenario1.title": "Datensicherheit",
  "onboardingBuyer.scenarios.scenario1.description":
    "Teste und steigere die Sensibilität für Datenschutz und Sicherheit in deiner Organisation.",

  "onboardingBuyer.scenarios.scenario2.title": "Digitale Kommunikation",
  "onboardingBuyer.scenarios.scenario2.description":
    "Chat-Bots und andere digitale Hilfsmittel verbessern deine Arbeitsabläufe und Prozesse. Entwickle deine Organisation weiter.",

  "onboardingBuyer.scenarios.scenario3.title": "Digitale Fitness",
  "onboardingBuyer.scenarios.scenario3.description":
    "Teste und steigere das digitale Selbstbewusstsein deiner Organisation.",

  "onboardingBuyer.scenarios.scenario4.title": "Datengetriebene Organisation",
  "onboardingBuyer.scenarios.scenario4.description":
    "Daten sind das Gold der Gegenwart. Finde heraus, wie deine Organisation besser mit ihnen arbeiten kann.",

  "onboardingBuyer.scenarios.scenario5.title": "Digitaler Vertrieb",
  "onboardingBuyer.scenarios.scenario5.description":
    "Wie gut ist dein Vertriebsteam für die Anforderungen eines digitalen Vertriebs gerüstet? Finde es heraus und steigere ihre Fähigkeiten.",

  "onboardingBuyer.scenarios.scenario6.title": "Eigene Themen",
  "onboardingBuyer.scenarios.scenario6.description":
    "Dein Anliegen taucht hier nicht auf? Sprich uns an, gemeinsam realisieren wir Dein Vorhaben",

  "onboardingBuyer.scenarios.scenario1.signals.signal1.what":
    "Verbessere die Abwehrkräfte gegen Spam und Phishing.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.why":
    "Senke das Risiko von finanziellen Verlusten und Datenverlusten.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.how":
    "talent::digital bietet passende Inhalte an (Staffel 1 Episode 5). Motiviere ggf. das Team, die Grundzertifizierung IT-Sicherheit abzuschließen.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.scope.name":
    "Buchhaltung",

  "onboardingBuyer.scenarios.scenario1.signals.signal2.what":
    "Erhöhe das Verständnis für starke Passwörter und Mehrfaktorauthentifizierung.",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.why":
    "Senke das Risiko von Störungen und Datenverlusten durch Einbrüche",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.how":
    "talent::digital bietet passende Inhalte an (Tutorial). Motiviere ggf. das Team, die Grundzertifizierung IT-Sicherheit abzuschließen.",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.scope.name":
    "Buchhaltung",

  "onboardingBuyer.scenarios.scenario1.signals.signal3.what":
    "Beteilige dein Management-Team.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.why":
    "Das Management-Team ist eine notwendige Unterstützung für die Mitarbeiter im digitalen Wandel.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.how":
    "Informiere das Management-Team und lade es explizit zum Ausprobieren von talent::digital ein.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.scope.name":
    "Linienmanagement",

  "onboardingBuyer.scenarios.scenario2.signals.signal1.what":
    "Prüfe den Einsatz von digitalen Kontaktangeboten und Kontaktautomatisierung.",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.why":
    "Ermögliche höhere Verfügbarkeit beispielsweise in Kundendienst und Vertrieb.",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.how":
    "Basis-Zertifizierung digitale Kollaboration, Staffel 2 Episode 1 & 2 wiederholen",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.scope.name":
    "Kundendienst",

  "onboardingBuyer.scenarios.scenario2.signals.signal2.what":
    "Erhöhe das Verständnis für agile Arbeitsmethoden.",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.why":
    "Senke deine Projekt- und Entwicklungsrisiken durch schrittweises, angepasstes Arbeiten.",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.how":
    "Basis-Zertifizierung digitale Kollaboration, Staffel 2 Episode 3 wiederholen",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.scope.name": "Beratung",

  "onboardingBuyer.scenarios.scenario2.signals.signal3.what":
    "Beteilige dein Magement-Team.",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.why":
    "Das Management-Team unterstützt die Mitarbeiter beim digitalen Wandel.",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.how":
    "Informiere das Management-Team und lade es explizit zum Ausprobieren von talent::digital ein.",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.scope.name":
    "Linienmanagement",

  "onboardingBuyer.departments.centralFunction": "Zentrale Funktionen",
  "onboardingBuyer.departments.accounting": "Buchhaltung",
  "onboardingBuyer.departments.humanResources": "Personalabteilung",
  "onboardingBuyer.departments.fieldService": "Aussendienst",
  "onboardingBuyer.departments.customerService": "Kundendienst",
  "onboardingBuyer.departments.consulting": "Beratung",

  "onboardingBuyer.competenceAreas.informationAndDataLiteracy":
    "Information and Data Literacy",
  "onboardingBuyer.competenceAreas.communicationAndCollaboration":
    "Communication and Collaboration",
  "onboardingBuyer.competenceAreas.digitalContentCreation":
    "Digital Content Creation",
  "onboardingBuyer.competenceAreas.protectionAndSecurity":
    "Protection and Security",
  "onboardingBuyer.competenceAreas.problemSolving": "Problem Solving",
};

const travelBot = {
  "travelBot.title1": "Es ist zeit zum<br />traveln",
  "travelBot.title2": "Nenne deine<br />Handynummer",
  "travelBot.title3": "Super!",
  "travelBot.description":
    "TOBi meldet sich gleich bei dir. Er unterstützt dich bei deiner Reisebuchung.",
  "travelBot.startBtn": "Jetzt starten",
  "travelBot.continueBtn": "Weiter",

  "travelBot.tickets.btnText": "Hinfahrt",
  "travelBot.tickets.yourJourney": "Deine Reise",
  "travelBot.tickets.direction": "Düsseldorf - Leirvik",

  "travelBot.form.salutation": "Anrede",
  "travelBot.form.title": "Titel",
  "travelBot.form.name": "Name",
  "travelBot.form.email": "E-Mail",
  "travelBot.form.Weiblich": "Frau",
  "travelBot.form.Männlich": "Herr",
  "travelBot.form.Divers": "Mx",

  "travelBot.booking.yourData": "Ihre Daten",
  "travelBot.booking.wallet": "Wallet",
  "travelBot.booking.bookBtn": "Kostenpflichtig Buchen",

  "travelBot.creditCard.use": "Kreditkarte aus der Walletverwenden?",
  "travelBot.creditCard.name": "SunCopter Kreditkarte",

  "travelBot.booked.copy": "In die Wallet kopieren",
  "travelBot.booked.yourBooking": "Ihre Buchung",
  "travelBot.booked.eTicket": "E-Ticket",
  "travelBot.booked.type": "Einfache Zugfahrt",
  "travelBot.booked.passengers": "Passagiere",
  "travelBot.booked.adult": "Erwachsener",
  "travelBot.booked.date": "Datum",
  "travelBot.booked.trainNo": "Zug Nr.",
  "travelBot.booked.time": "Uhrzeit",
  "travelBot.booked.class": "Klasse",
  "travelBot.booked.seat": "Sitz",

  "travelBot.tomorrow": "Morgen",
};

const cloudapparticles = {
  "cloudapparticles.title": "Die besten Cloud-Provider im Test",
  "cloudapparticles.description":
    "Von einfacher Dateiverwaltung bis zum digitalen Firmen-Betriebssystem, überall und immer verfügbar. Wir stellen dir hier die besten Anbieter vor.",
  "cloudapparticles.pros": "Pro",
  "cloudapparticles.cons": "Kontra",
  "cloudapparticles.button": "Zum Anbieter",
};

const cloudapp = {
  "cloudapp.title": "Lösungen für smarte Unternehmen der Zukunft",
  "cloudapp.advantages": "Vorteile",
  "cloudapp.formTitle": "Bitte hinterlegen Sie folgende Angaben",
  "cloudapp.form.organization": "Organisation",
  "cloudapp.form.name": "Name",
  "cloudapp.form.email": "E-Mail-Adresse",
  "cloudapp.form.terms.conditions": "AGB",
  "cloudapp.form.terms.conditions.separator": " und ",
  "cloudapp.form.data.protection": "Datenschutz",
  "cloudapp.form.terms.conditions.end": " gelesen",
  "cloudapp.form.2factorauth": "2-Faktor-Authentifizierung einrichten",
  "cloudapp.form.marketingEmail": "Marketing-E-Mails erhalten",
  "cloudapp.signedpage.title": "Vielen Dank",
  "cloudapp.signedpage.text1":
    "Sie erhalten in Kürze eine Bestätigungs-E-Mail.",
  "cloudapp.signedpage.text2":
    "Um Ihr Konto zu aktivieren, klicken Sie einfach auf den Aktivierungslink in der E-Mail.",
  "cloudapp.verification.title": "Konto wurde verifiziert!",
  "cloudapp.verification.description":
    "Bitte wählen SIe nun die Personenanzahl.",
  "cloudapp.verification.button": "Weiter",
  "cloudapp.verification.package.small.title": "1 bis 4",
  "cloudapp.verification.package.small.description": "For Free",
  "cloudapp.verification.package.medium.title": "5 - 10",
  "cloudapp.verification.package.medium.description": "219 &euro; / Monat",
  "cloudapp.verification.package.large.title": "11 - 20",
  "cloudapp.verification.package.large.description": "439 &euro; / Monat",
  "cloudapp.2factor.title": "2-Faktor-Authentifizierung",
  "cloudapp.2factor.description1": "Bitte nennen Sie uns Ihre Mobilnummer.",
  "cloudapp.2factor.description2":
    "Wir senden Ihnen einen persönlichen Authentifizierungscode. Drücken Sie den Knopf und tragen Sie den Code hier ein, um die Registrierung abzuschließen.",
  "cloudapp.2factor.mobilenumber": "Handynummer",
  "cloudapp.2factor.code": "Code",
  "cloudapp.2factor.button1": "Weiter",
  "cloudapp.2factor.button2": "Code versenden",
  "cloudapp.appsinstall.title": "Vielen Dank",
  "cloudapp.appsinstall.description":
    "Jetzt Cloud-App herunterladen und direkt loslegen!",
  "cloudapp.appsinstall.button": "App herunterladen",
  "cloudapp.appsWasInstalled.button":
    "Der Download war erfolgreich.<br />Sie können den Browser nun schließen.",
  "cloudapp.appsinstall.app.contacts": "Kontakte",
  "cloudapp.appsinstall.app.wallet": "Wallet",
  "cloudapp.appsinstall.app.storage": "Dateien",
  "cloudapp.back": "Zurück",
  "cloudapp.signup.email.error": "Falsches E-Mail Format",
};

const alerts = {
  "alerts.finder": "Finder",
  "alerts.alerts": "Alerts",
  "alerts.follow": "Interessanten neuen Inhalten im Web folgen",
  "alerts.placeholder": "Alert erstellen für...",
  "alerts.info.message": "Dadurch wird ein E-Mail-Alert für",
  "alerts.btn": "Alert erstellen",
  "alerts.list.title": "Meine Alerts ",
};

const storageApp = {
  "storageApp.name": "Dateien",
  "storageApp.main.folder.name": "Dateien",
  "storageApp.main.folder.noDocuments": "Keine Dateien vorhanden.",
  "storageApp.folders.list.project": "Projekt",
  "storageApp.modal.share": "Freigeben",
  "storageApp.modal.view": "Datei anzeigen",
  "storageApp.add.people": "Personen hinzufügen",
  "storageApp.contacts.modal.title": "Projekt senden an:",
  "storageApp.add": "Hinzufügen",
  "storageApp.message.field.placeholder": "Nachricht hinzufügen",
  "storageApp.confirmation.message": "Ihre Nachricht wurde gesendet",
};

const gezwitscherApp = {
  "gezwitscherApp.profile.desc":
    "Wir schreiben Innovation und Denker-Geist groß! Wir kennen und kreieren die Trends von morgen!",
  "gezwitscherApp.profile.followers": "134 Folge ich",
  "gezwitscherApp.profile.observe": "3632 Follower",
  "gezwitscherApp.tweet.content":
    "„Unter Wasser wird geblubbert! Nur noch 24 Stunden, bis zum Vor-Verkaufsstart unseres BabblR-Unterwasserlautsprecher! Natürlich mit patentierter Walgesangs-Technologie!“",
};

const programmingGame = {
  "programmingGame.dialogs.success.title": "Du hast die Mission erfüllt!",
  "programmingGame.dialogs.warning.title":
    "Du hast deine Mission nicht erfüllt.",
  "programmingGame.dialogs.warning.text": "Versuche es nochmal.",
  "programmingGame.dialogs.error.title":
    "Deine Anweisungen sind nicht korrekt.",
  "programmingGame.dialogs.information.title":
    "Möchtest du diese Mission überspringen?",
  "programmingGame.dialogs.hint.title": "Möchtest du einen Tipp erhalten?",
  "programmingGame.dialogs.button.text": "Ja",
  "programmingGame.dialog.deniedButton.text": "Nein",
  "programmingGame.menu.title": `Wie konnte das passieren? Der Zauberer Randalf hat sich in den verwunschenen Landen hoffnungslos verloren. Kennst du die passenden Sprüche aus dem Script von Java, um ihn an sein Ziel zu führen?`,
  "programmingGame.menu.description": "Du hast alle Level geschafft!",
  "programmingGame.descriptionPage.tasks": "Anleitung",
  "programmingGame.descriptionPage.instructions": "Deine Mission",
  "programmingGame.descriptionPage.hints": "Tipps",
  "programmingGame.descriptionPage.button.next": "Weiter",
  "programmingGame.descriptionPage.button.conclude": "Schliessen",
  "programmingGame.endScreen.title": "Herzlichen Glückwunsch!",
  "programmingGame.endScreen.description1":
    "Du hast alle Missionen erfüllt und Randalf sicher durch die verwunschenen Lande geführt.",
  "programmingGame.endScreen.description2":
    "Du kannst die App jetzt schliessen.",
  "programmingGame.endScreen.skipped.title": "Ähm, ok...",
  "programmingGame.endScreen.skipped.description1":
    "Du hast alle Missionen übersprungen und den armen Randalf sich selbst überlassen.",
  "programmingGame.tab.0": "Mission",
  "programmingGame.tab.1": "Anleitung",
  "programmingGame.tab.2": "Spielbrett",
  "programmingGame.tab.3": "Tipps",
};

const reboot = {
  reboot: "Systeme werden neu gestartet.",
};

const digidoc = {
  "digidoc.description": "Einfach senden und signieren",
  "digidoc.login.button": "Mit Cloud-Account anmelden",
  "digidoc.login.afterregister.description1":
    "Vielen Dank für die Registrierung.",
  "digidoc.login.afterregister.description2":
    "Starten Sie jetzt mit Ihrem ersten Dokument.",
  "digidoc.start.button": "Jetzt starten",
  "digidoc.step": "Schritt",
  "digidoc.upload.document": "Dokument hochladen",
  "digidoc.upload.document.invalid.image": "Die Signaturdatei ist ungültig",
  "digidoc.upload.document.select.again":
    "Bitte laden Sie die Unterschrift erneut hoch",
  "digidoc.button.next": "Weiter",
  "digidoc.button.share": "Dokument freigeben",
  "digidoc.selectFiles.title": "Dateien",
  "digidoc.sign.document": "Dokument signieren",
  "digidoc.sign.document.input.title": "Vollständiger Name",
  "digidoc.sign.document.name.preview": "Vorschau",
  "digidoc.check.document": "Dokument prüfen",
  "digidoc.add.people": "Personen hinzufügen",
  "digidoc.add.people.skip": "Überspringen",
  "digidoc.add.people.new-signatory-email":
    "E-Mail-Adresse des neuen Unterzeichners",
  "digidoc.add.people.new-signatory-name": "Name des neuen Unterzeichners",
  "digidoc.add.people.add.new": "+ Weitere Personen hinzufügen?",
  "digidoc.share.title": "Freigeben",
  "digidoc.share.document": "Dokument",
  "digidoc.share.signatory": "Unterzeichner",
  "digidoc.document.sent.title": "Dokument versendet",
  "digidoc.document.sent.text1": "Ihr Dokument wurde gesendet.",
  "digidoc.document.sent.text2":
    "Wir werden Sie per E-Mail informieren, sofern eine Person das Dokument signiert hat.",
};

const goblinNews = {
  "goblin.news.news": "Goblin Inc. entwickelt<br />neuen Helikopter",
  "goblin.news.video": "video",
  "goblin.news.register.text":
    "SunCopter: Eine Erfindung von Goblin Inc. – Demnächst erhältlich!<br />Jetzt anmelden und mehr Informationen erhalten.",
  "goblin.news.register.name": "Vorname",
  "goblin.news.register.surname": "Nachname",
  "goblin.news.register.income": "Einkommen",
  "goblin.news.register.email": "E-Mail-Adresse",
  "goblin.news.register.start": "Jetzt starten",
};

const levelEnd = {
  "level.end.completed": "Level {level} abgeschlossen",
  "level.end.to.results": "Zur Auswertung",
};

const notification = {
  "notification.newMessage": "Neue Nachricht",
  "notification.newGroup": "Neue Gruppe",
  "notification.read": "Lesen",
  "notification.ok": "OK",
  "notification.continue": "Weiter",
  "notification.Recherche-King": "Recherche-King",
  "notification.Crypto-Meister": "Crypto-Meister",
  "notification.Organisationstalent": "Organisationstalent",
  "notification.Sharing is caring": "Sharing is caring",
  "notification.Web Watcher": "Web Watcher",
  "notification.Good Sign(s)": "Good Sign(s)",
  "notification.Wizard of Code": "Wizard of Code",
  "notification.congratulation": "Glückwunsch!",
  "notification.badge.description.1":
    "Deine Online-Recherche-Kenntnisse sind überdurchschnittlich gut ausgeprägt!",
  "notification.badge.description.2":
    "Du hast ein überdurchschnittlich ausgeprägtes Auge für gute Apps und Sicherheit.",
  "notification.badge.description.3":
    "Du kannst Termine problemlos koordinieren!",
  "notification.badge.description.4":
    "Du weißt genau wie man wichtige Links und Informationen mit anderen teilt!",
  "notification.projectChat": "SunCopter Gruppe",
  "notification.teamChat": "Gruppen Chat",
  "notification.bubblr": "BubblR Gruppe",
  "notification.newGroupMessage": "Neue Nachricht in der Gruppe",
  "notification.from": " von ",
  "notification.event.title": "Termin beginnt bald",
  "notification.event.description":
    'Dein Termin, Videocall mit {conversationPartnerName} ({companyName}) zum Thema "{topic}" beginnt bald',
};

const itttapp = {
  "ittt.title1": "WENN",
  "ittt.title2": "DIES",
  "ittt.title3": "DANN",
  "ittt.title4": "DAS",
  "ittt.subtitle": "Prozessautomatisierung leicht gemacht.",
  "ittt.start": "STARTEN",
  "ittt.choose.trigger": "Wählen Sie einen Auslöser",
  "ittt.choose.action": "Wählen Sie eine Aktion",
  "ittt.continue": "WEITER",
  "ittt.trigger.sender.label": "Wählen Sie den Absender",
  "ittt.trigger.option.label": "Wählen Sie eine Option",
  "ittt.trigger.checkbox.label.signal": "Signalstärke (RSSI)",
  "ittt.trigger.checkbox.label.voltage": "Spannung (V)",
  "ittt.trigger.checkbox.label.energy": "Energie (kWh)",
  "ittt.action.action.label": "Was soll gemacht werden?",
  "ittt.action.data.label": "Welche Daten?",
  "ittt.trigger.subject.label": "Was soll im Betreff vorkommen?",
  "ittt.trigger.subject.label.energysense": "Wählen Sie die Datenpunkte:",
  "ittt.action.reason.label": "Wählen Sie den Grund",
  "ittt.action.done.label": "Was soll gemacht werden?",
  "ittt.action.placeholder.surfpatrol":
    "<p>Die folgenden Platzhalter stehen zur Verfügung:</p><ul><li><i>Der Absender der E-Mail</i>: {{emailSender}}</li><li><i>Der Titel der E-Mail</i>: {{emailSubject}}</li><li><i>Der Inhalt der E-Mail</i>: {{emailText}}</li></ul>",
  "ittt.action.placeholder.dashdb":
    "<p>Die folgenden Platzhalter stehen zur Verfügung:</p><ul><li><i>Messzeitpunkt</i>: {{Zeit}}</li><li><i>Messgerät</i>: {{Gerät}}</li><li><i>Messwert</i>: {{value}}</li></ul>",
  "ittt.action.placeholder.none":
    "<p>Die folgenden Platzhalter stehen zur Verfügung:</p><ul><li>Keine</li></ul>",
  "ittt.title.sender": "Absender:",
  "ittt.title.subject": "Betreff:",
  "ittt.title.reason": "Grund:",
  "ittt.title.reason.dashdb": "Machen:",
  "ittt.title.whatToDo": "Was:",
  "ittt.title.whatToDo.dashdb": "Daten:",
  "ittt.title.data-points": "Datenpunkte:",
  "ittt.button.finished": "FERTIG?",
  "ittt.ready.page.title": "READY?",
  "ittt.button.further": "App schliessen",
  "ittt.ready.page.subtitle": "Der automatische Prozess wurde hinterlegt.",
  "ittt.form.options.alert": "Alert",
  "ittt.form.options.digidoc": "DIGIdoc",
  "ittt.form.options.tina": "Dr. Tina Digel",
  "ittt.form.options.dsuite": "DSuite",
  "ittt.form.options.restart": "Neustart",
  "ittt.form.options.battery": "Batteriewarnung",
  "ittt.form.options.signal": "Signalstärkewarnung",
  "ittt.form.options.newdata": "Neue Daten",
  "ittt.form.options.new": "Neue Daten einfügen",
  "ittt.form.options.update": "Daten aktualisieren",
  "ittt.form.options.delete": "Daten löschen",
  "ittt.form.options.vulnerability": "Sicherheitslücke melden",
  "ittt.form.options.virus": "Virus melden",
  "ittt.form.options.website": "Website melden",
  "ittt.form.dashdb.label.source": "Quelle",
  "ittt.form.dashdb.label.timestamp": "Zeitstempel",
  "ittt.form.dashdb.label.value": "Wert",
  "ittt.wrongApp.title": "{{name}} verbinden",
  "ittt.wrongApp.subtitle": "Bitte verbinden Sie Ihr Konto",
  "ittt.wrongApp.input.login": "Anmeldename",
  "ittt.wrongApp.input.password": "Passwort",
  "ittt.wrongApp.alert.message": "Benutzer kann nicht gefunden werden",
};

const surfpatrol = {
  "surfpatrol.description":
    "Vielen Dank, dass Sie uns beim Schutz vor Phishing-Websites unterstützen. Sind Sie Ihrer Meinung nach auf eine Seite gestoßen, die versucht, an persönliche Daten von Personen zu gelangen? Melden Sie diese Seite bitte mithilfe des unten stehenden Formulars.",
  "surfpatrol.input.label": "Wie lautet die URL?",
  "surfpatrol.button": "Senden",
  "surfpatrol.text.thanks": "DANKE!",
  "surfpatrol.text.thanks.description":
    "Die von Ihnen gemeldete Seite wird überprüft. Vielen Dank für Ihre Mithilfe, Phishing zu bekämpfen! Ihr Surf-Patrol Team.",
  "surfpatrol.report.another": "+ Eine weitere Seite melden?",
  "surfpatrol.contact":
    "Sie wollen sich direkt an uns wenden? \nphishing@surf-patrol.com",
};

const dashboard = {
  "dashboard.main.profile-link": "Mein Profil",
  "dashboard.main.menu.home": "Spiel",
  "dashboard.main.menu.news": "News",
  "dashboard.main.menu.competition": "Wettbewerb",
  "dashboard.main.menu.report": "Report",
  "dashboard.main.menu.explore": "Erkunden",
  "dashboard.main.menu.support-button": "Support",
  "dashboard.main.menu.company-report": "Management-Cockpit",
  "dashboard.main.menu.admin": "Unsere Organisation",
  "dashboard.main.menu.marketplace": "Qualifizierungsmarktplatz",
  "dashboard.main.menu.invitation": "Teammitglieder einladen",
  "dashboard.main.menu.logout-button": "Ausloggen",
  "dashboard.main.menu.invite-colleague-button": "Teammitglieder einladen",

  "dashboard.page.home.title": "Themenschwerpunkte",
  "dashboard.page.home.competences": "Kompetenzbereiche:",
  "dashboard.page.home.button.further-information": "Weitere Infos",
  "dashboard.page.home.button.thematic-focus": "Themenschwerpunkte",
  "dashboard.page.home.seasons-dropdown": "Weitere Staffeln",
  "dashboard.page.home.seasons-dropdown.topics": "Schwerpunkte:",
  "dashboard.page.home.replay-episode":
    'Noch etwas unklar? Schau dir "Weiterwissen" an und probiere es gleich nochmal! Du kannst keine Punkte verlieren.',
  "dashboard.page.home.competencies":
    "Kompetenzen {passedTests}/{totalTests} {caret}",
  "dashboard.page.home.badges":
    "Abzeichen {earnedBadges}/{totalBadges} {caret}",
  "dashboard.page.home.take-aways": "Weiterwissen {caret}",
  "dashboard.page.home.take-aways.intro-failed":
    "Die folgenden Kompetenzdiamanten hast du noch nicht gesammelt. Hier sind einige Links, die dir helfen könnten, diese Diamanten zu sammeln, wenn du die Episode erneut spielst.",
  "dashboard.page.home.take-aways.intro-passed":
    "Hier sind einige Links zu den Kompetenzdiamanten, die du bereits gesammelt hast.",
  "dashboard.page.home.take-aways.show-passed":
    "Gesammelte Kompetenzdiamanten anzeigen",
  "dashboard.page.home.take-aways.hide-passed":
    "Gesammelte Kompetenzdiamanten ausblenden",
  "dashboard.page.home.publish.tooltip.locked":
    "Die Episode kann noch nicht für Benutzer veröffentlicht werden.",
  "dashboard.page.home.publish.tooltip.ready":
    "Die Episode wartet auf die Veröffentlichung für Benutzer in deiner Organisation.",
  "dashboard.page.home.publish.tooltip.published":
    "Die Episode ist veröffentlicht und jeder Benutzer in deiner Organisation kann sie spielen.",
  "dashboard.page.home.publish.btn.cancel": "Nicht jetzt",
  "dashboard.page.home.publish.btn.confirm": "Veröffentlichen",
  "dashboard.page.home.publish.message":
    "Möchtest du diese Episode wirklich veröffentlichen?",
  "dashboard.page.home.checkpoint-episode-not-met-tooltip":
    "Sie müssen in der letzten spielbaren Episode eine Punktzahl von mindestens {percentage}% erreicht haben, um fortzufahren",
  "dashboard.page.home.episode-report.checkpoint-not-met":
    "Mindestens {percentage}% der Aufgaben in dieser Episode müssen richtig gelöst werden, um mit der nächsten Episode fortzufahren. Bitte schauen Sie sich die Episode erneut an.",

  "dashboard.page.home.missions.title": "Missionen",
  "dashboard.page.home.missions.subheader":
    "Die Missionsziele werden von einem Systemadministrator für dich festgelegt. Die Missionen sind abgeschlossen, wenn der Balken {threshold}% erreicht.",
  "dashboard.page.home.missions.you": "Dein Teil",
  "dashboard.page.home.missions.everyone": "Alle",
  "dashboard.page.home.missions.show-more": "Mehr zeigen",
  "dashboard.page.home.missions.show-less": "Weniger zeigen",

  "dashboard.page.play.continue.btn": "Loslegen",
  "dashboard.page.completed.episodes": "Abgeschlossen {completed}/{total}",
  "dashboard.page.episode.play": "Folge {episode} spielen ",
  "dashboard.page.coming.soon": "Demnächst",

  "dashboard.page.profile.title": "MEIN PROFIL",
  "dashboard.page.profile.data.title": "Meine Daten",
  "dashboard.page.profile.profile.title": "Mein Spielerprofil",
  "dashboard.page.profile.termsandconditions.title": "Rechtliches",
  "dashboard.page.profile.account.title": "Kontoeinstellungen",
  "dashboard.page.profile.button.save": "Speichern",
  "dashboard.page.profile.termsAndConditions": "Nutzungsbedingungen",
  "dashboard.page.profile.privacyPolicy": "Datenschutzerklärung",

  "dashboard.page.report.title": "Report",
  "dashboard.page.report.tab.competence": "Kompetenz",
  "dashboard.page.report.tab.progress": "Fortschritt",
  "dashboard.page.report.tab.engagement": "Engagement",
  "dashboard.page.report.title.competencyPoints": "Kompetenz-Diamanten",
  "dashboard.page.report.title.certificates": "Zertifikate",
  "dashboard.page.report.fallback": "Es liegen noch zu wenige Daten vor.",
  "dashboard.page.report.dialog.confirm": "Weiter",
  "dashboard.page.report.dialog.cancel": "Abbrechen",
  "dashboard.page.report.dialog.description.title": "Glückwunsch!",
  "dashboard.page.report.dialog.description.text":
    "Du hast dein erstes, persönliches Zertifikat geschafft. Bitte nenne uns deinen Vornamen und Nachnamen, damit wir das Zertifikat ausstellen können.",
  "dashboard.page.report.dialog.attention.title": "Achtung:",
  "dashboard.page.report.dialog.attention.text":
    "Der Name kann später nicht mehr verändert werden. Achte bitte deshalb vor dem Absenden auf die Korrektheit.",
  "dashboard.page.report.dialog.placeholder.firstName": "Vorname",
  "dashboard.page.report.dialog.placeholder.lastName": "Nachname",
  "dashboard.page.report.dialog.validationError.firstName":
    "Bitte Vornamen eingeben",
  "dashboard.page.report.dialog.validationError.lastName":
    "Bitte geben Sie den Nachnamen ein",
  "dashboard.page.report.title.results": "Mein Ergebnis",
  "dashboard.page.report.results.team": "Bester im Team",
  "dashboard.page.report.results.organisation": "Bester in der Organisation",
  "dashboard.page.report.results.passed": "{score} Geschaffte Aufgaben",
  "dashboard.page.report.results.tried": "{score} Gemachte Aufgaben",
  "dashboard.page.report.results.tooltip.title":
    "Dein Team ist nicht konfiguriert.",
  "dashboard.page.report.results.tooltip.noData":
    "Es sind nicht genügend Daten verfügbar.",
  "dashboard.page.report.results.tooltip":
    "Bitte setze deine Abteilung und dein Team {link}",
  "dashboard.page.report.results.tooltip.link": "hier",

  "dashboard.page.company-report.competence": "Kompetenz-Diamanten",
  "dashboard.page.company-report.Organisationseinheiten":
    "Organisationseinheiten",
  "dashboard.page.company-report.Organisationszugehörigkeit":
    "Organisationszugehörigkeit",
  "dashboard.page.company-report.Management-Verantwortung":
    "Management-Verantwortung",
  "dashboard.page.company-report.Beschäftigungsverhältnis":
    "Beschäftigungsverhältnis",
  "dashboard.page.company-report.Bildungsabschluss": "Bildungsabschluss",
  "dashboard.page.company-report.Geschlecht": "Geschlecht",
  "dashboard.page.company-report.Funktion": "Funktion",
  "dashboard.page.company-report.Altersgruppe": "Altersgruppe",
  "dashboard.page.company-report.Standort": "Standort",
  "dashboard.page.company-report.departments.btn.points": "Punkte",
  "dashboard.page.company-report.departments.btn.placement": "Platzierung",
  "dashboard.page.company-report.departments.tooltip.total": "Alle Aufgaben",
  "dashboard.page.company-report.departments.tooltip.passed":
    "Bestandene Aufgaben",
  "dashboard.page.company-report.departments.tooltip.tried":
    "Gespielte Aufgaben",
  "dashboard.page.company-report.departments.tooltip.progress.top":
    "% Wert aus gespielten Aufgaben",
  "dashboard.page.company-report.departments.tooltip.progress.bottom":
    "und bestandenen Aufgaben",

  "dashboard.page.company-report.missions": "Missionen",
  "dashboard.page.company-report.missions.department-team-function":
    "Abteilung, Team oder Funktion",
  "dashboard.page.company-report.missions.competence": "Kompetenz",
  "dashboard.page.company-report.missions.difficultyLevel":
    "Schwierigkeitsgrad",

  "dashboard.page.company-report.missions.competenceLevels.1": "Anfänger",
  "dashboard.page.company-report.missions.competenceLevels.2":
    "Fortgeschritten",
  "dashboard.page.company-report.missions.competenceLevels.3": "Profi",

  "dashboard.page.company-report.missions.description": `Definiere hier Deine Missionen. Dadurch legst Du fest, auf welchem Schwierigkeitsgrad eine Abteilung, ein Team oder eine spezifische Funktion das Spiel meistern soll (Missionsziel). Die verfügbaren Level sind "Anfänger", "Fortgeschritten" und "Profi". talent::digital liefert dann aktuelle Statusinformationen, zeigt die Fortschritte im Vergleich zur Ausgangsmessung und präsentiert den Trend im 14-Tage-Vergleich.`,
  "dashboard.page.company-report.missions.createButton": "Speichern",
  "dashboard.page.company-report.missions.emptyStateMessage":
    "Erstellen Sie Ihre erste neue Mission",
  "dashboard.page.company-report.missions.noData":
    "Es wurden noch nicht genug Daten gesammelt.",
  "dashboard.page.company-report.missions.noMissionAdded":
    "Es wurde keine Mission hinzugefügt",
  "dashboard.page.company-report.missions.progress":
    "Ausgangsmessung {initial}%, Verbesserung {progress}%",

  "dashboard.page.company-report.missions.trends.done": "Erledigt",
  "dashboard.page.company-report.missions.trends.onTrack": "Auf dem Weg",
  "dashboard.page.company-report.missions.trends.stalled": "Gestoppt",
  "dashboard.page.company-report.missions.trends.declining": "Abnehmend",
  "dashboard.page.company-report.missions.trends.none":
    "Nicht ausreichend Daten",

  "dashboard.page.company-report.missions.whatScope.ALL": "Alle",
  "dashboard.page.company-report.missions.whatScope.COMPETENCE_AREA":
    "Kompetenzbereich",
  "dashboard.page.company-report.missions.whatScope.COMPETENCE": "Kompetenz",
  "dashboard.page.company-report.missions.whatScope.SUB_COMPETENCE":
    "Unterkompetenz",

  "dashboard.page.company-report.signals.VERYHIGH": "Sehr hoch",
  "dashboard.page.company-report.signals.HIGH": "Hoch",
  "dashboard.page.company-report.signals.MEDIUM": "Mittel",
  "dashboard.page.company-report.signals.LOW": "Niedrig",
  "dashboard.page.company-report.signals.NO_DATA": "Nicht ausreichend Daten",
  "dashboard.page.company-report.signals.NOT_ACTIVE": "Sehr niedrig",
  "dashboard.page.company-report.feedback.PERFECT": "Kein Handlungsbedarf",
  "dashboard.page.company-report.feedback.MIXED": "Möglicher Handlungsbedarf",
  "dashboard.page.company-report.feedback.BAD": "Dringender Handlungsbedarf",
  "dashboard.page.company-report.feedback.NO_DATA": "Nicht ausreichend Daten",

  "dashboard.page.company-report.missions.link-to-crate": "Neue Mission ",
  "dashboard.page.company-report.missions.link": "hier hinzufügen",
  "dashboard.page.company-report.chart.select-season": "Staffel auswählen",

  "dashboard.page.company-report.statistics.active-users": "Davon aktiv",
  "dashboard.page.company-report.statistics.participating-users":
    "Spieler:innen",
  "dashboard.page.company-report.statistics.active-user": "Episoden",
  "dashboard.page.company-report.statistics.no-data": "Keine Daten",

  "dashboard.page.company-report.tooltip.competence":
    "Median der gespielten und bestandenen Aufgaben. Die Daten ändern sich ständig und passen sich dem Fortschritt des Spiels an.",
  "dashboard.page.company-report.tooltip.organization-header":
    "Kategorie-Umschalter",
  "dashboard.page.company-report.tooltip.organization-paragraph1":
    "Dieser Bereich zeigt die Testergebnisse weiter nach Merkmalen der Spieler:innen, wie beispielsweise Organisationseinheit oder Funktion, kategorisiert.",
  "dashboard.page.company-report.tooltip.organization-paragraph2":
    "Klicke auf die Kategorie in der Titelzeile, um zu anderen Kategorien zu wechseln.",
  "dashboard.page.company-report.tooltip.organization-paragraph3":
    "Wir empfehlen, mit der Interpretation der Zahlen abzuwarten, bis genügend Aufgaben abgeschlossen wurden. Je mehr Aufgaben abgeschlossen werden, desto verlässlicher wird das Bild.",
  "dashboard.page.company-report.tooltip.points-header": "Kompetenz-Tabelle",
  "dashboard.page.company-report.tooltip.points-paragraph1":
    "Die linke Zahl zeigt den Median der bestandenen Aufgaben an. Die rechte Zahl zeigt den Median der durchgeführten Aufgaben an.",
  "dashboard.page.company-report.tooltip.points-paragraph2":
    "Wenn keine Zahlen angezeigt werden, liegt noch nicht die erforderliche Mindestmenge an Spieler:innen im Merkmal vor.",
  "dashboard.page.company-report.tooltip.participants-header":
    "Registrierte Spieler:innen",
  "dashboard.page.company-report.tooltip.participants-paragraph1":
    "Hier steht die Anzahl der insgesamt registrierten Spieler:innen.",
  "dashboard.page.company-report.tooltip.participants-paragraph2":
    "Die Zahl in Klammern zeigt die Neuregistrierungen der letzten zwei Wochen an.",
  "dashboard.page.company-report.tooltip.active-header": "Aktive Spieler:innen",
  "dashboard.page.company-report.tooltip.active-paragraph1":
    "Hier steht die Anzahl der Spieler:innen, die in den letzten zwei Wochen aktiv das Spiel gespielt haben.",
  "dashboard.page.engagement.title": "Aktivitäts-Münzen",
  "dashboard.page.engagement.tooltip":
    "Die Zahl in Klammern zeigt die Änderung in den letzten zwei Wochen.",
  "dashboard.page.company-report.departments.subheader":
    "Ausgegraute Bereiche zeigen an, dass noch nicht genügend Daten vorhanden sind. Das kann passieren, wenn die Teilnehmer im Spiel noch nicht weit genug fortgeschritten sind, oder wenn das Merkmal zu wenige Teilnehmer enthält.",

  "dashboard.page.company-report.no-data.tooltip":
    "Es wurden noch nicht ausreichend Aufgaben gespielt, um ein Testergebnis zu erhalten.",

  "dashboard.page.company-report.signals": "Signale",
  "dashboard.page.company-report.signals.collecting":
    "Daten werden noch erfasst...",
  "dashboard.page.company-report.signals.why": "Warum?",
  "dashboard.page.company-report.signals.how": "Wie?",
  "dashboard.page.company-report.activity": "Aktivitäten",
  "dashboard.page.company-report.activity.failToLoad":
    "Daten konnten nicht geladen werden",
  "dashboard.page.company-report.signals.departments": "Abteilungen",
  "dashboard.page.company-report.signals.functions": "Funktionen",

  "dashboard.page.company-report.feedback": "Mitarbeiter-Feedback",
  "dashboard.page.company-report.feedback.noFeedbackAdded":
    "Keine Feedback-Daten verfügbar",
  "dashboard.page.admin.title": "Unsere Organisation",
  "dashboard.page.admin.tools": "Unsere Werkzeuge",
  "dashboard.page.admin.tools.description":
    "Bitte wähle die Software-Werkzeuge aus, die in deiner Organisation (hauptsächlich) eingesetzt werden. Lerninhalte für eingesetzte Werkzeuge, falls vorhanden, werden bei Weiterwissen bevorzugt berücksichtigt. Es kann eine beliebige Anzahl von Werkzeugen pro Kategorie gewählt werden. Vermisst du hier eine Software/ Werkzeug? Dann kontaktiere uns über den Kontakt-Knopf.",

  "dashboard.page.admin.save": "Speichern",
  "dashboard.page.admin.reset": "Zurücksetzen",

  "dashboard.page.admin.tools.chat": "Chat",
  "dashboard.page.admin.tools.calendar-services": "Terminvereinbarung",
  "dashboard.page.admin.tools.project-collaboration": "Projektverwaltung",
  "dashboard.page.admin.tools.crm": "Kundenbeziehungsmanagement",
  "dashboard.page.admin.tools.video-conference": "Videokonferenz",
  "dashboard.page.admin.tools.chatbot": "Chatbot",
  "dashboard.page.admin.tools.document-creation": "Dokumentenerstellung",

  "dashboard.page.invitation.title":
    "Teammitglieder zu talent::digital einladen",
  "dashboard.page.invitation.mail-subject":
    "Werde digitaler mit talent::digital!",
  "dashboard.page.invitation.mail-body":
    "Mit diesem Link kannst du dich bei talent::digital anmelden und spielerisch deine Fähigkeiten ausbauen:%0D{link}%0D",
  "dashboard.page.invitation.description":
    "Lade ganz einfach deine Kollegen und Kolleginnen zu talent::digital ein.",

  "dashboard.page.invitation.email.title": "Einladen über E-Mail",
  "dashboard.page.invitation.email.description":
    "Versende deine Einladung über dein E-Mail Programm. Schreibe am besten noch etwas persönliches hinzu, damit die Kolleg:innen die Einladung nicht mit Spam oder Phishing verwechseln.",
  "dashboard.page.invitation.email.action": "E-Mail öffnen",

  "dashboard.page.invitation.link.title": "Einladen über Link",
  "dashboard.page.invitation.link.description":
    "Kopiere den Link in deine Zwischenablage. Aus der Zwischenablage kannst du den Link z.B. in Chats, E-Mails oder Intranet-Nachrichten einfügen.",
  "dashboard.page.invitation.link.action": "Link kopieren",

  "dashboard.page.invitation.copied": "In die Zwischenablage kopiert.",

  "dashboard.company.chart.tooltip":
    "{players} Spieler:innen haben noch keine Episode beendet.",
  "dashboard.company.chart.tooltip1":
    "{players} Spieler:innen haben Episode {episode} gespielt.",

  "dashboard.hubspot.title": "Eigene Themen? - Kein Problem!",
  "dashboard.hubspot.text":
    "Eigene Themen schulen? Sehr gerne! talent::digital lässt sich individuell auf Bedürfnisse anpassen. Sprich uns an!",

  "dashboard.news.edit": "Bearbeiten",
  "dashboard.news.done": "Speichern",
  "dashboard.news.publish": "Veröffentlichen",
  "dashboard.news.unpublish": "Unpublish",
  "dashboard.news.select-image": "Bild oder Video wählen",
  "dashboard.news.draft": "Entwurf",
  "dashboard.news.published": "Veröffentlicht",
  "dashboard.news.remove": "Artikel entfernen",

  "dashboard.banner.demo.title": "Hallo {player}!",
  "dashboard.banner.demo.description":
    "talent::digital hat noch mehr zu bieten.<br />Willst du die digitalen Kompetenzen in deiner Organisation analysieren und weiterentwickeln? Dann sprich uns jetzt an!",
  "dashboard.banner.demo.description.mobile":
    "Interesse an der Vollversion von talent::digital? Sprich uns an.",
  "dashboard.banner.demo.btn": "Kontakt aufnehmen",
  "dashboard.banner.demo.company":
    "<p>Mehr erfahren?</p><p>Wir stehen gern zur Verfügung, um Einsatzmöglichkeiten und Funktionen von talent::digital zu besprechen.</p>",
  "dashboard.banner.demo.company.btn": "Kontakt aufnehmen",

  "dashboard.demo.popup.s01e05":
    '<p>Glückwunsch!</p> <p>Du hast schon 5 Episoden gemeistert.</p><p>Ab sofort erhältst du Zugriff auf den "Qualifizierungsmarktplatz". Hier erhältst du passgenaue Fortbildungsangebote.</p> <p>Schau es dir direkt an.</p>',
  "dashboard.demo.popup.link": "Mehr Infos über talent::digital",
  "dashboard.report.popup.headerContent": "Herzlichen Glückwunsch!",
  "dashboard.report.popup.content":
    "Du hast den folgenden Schwellenwert zum Erhalt des Zertifikats erreicht:",
  "dashboard.report.popup.contentEnd":
    "Jetzt kannst du dein persönliches Zertifikat herunterladen!",
  "dashboard.report.popup.btn.content": "Zertifikat erhalten",
  "dashboard.report.popup.btn.contentReject": "Jetzt nicht",

  "dashboard.menu.progress.bar.value": "Profil {value}% abgeschlossen",

  "dashboard.player-progress.list-title": "Richte dein Konto ein",
  "dashboard.player-progress.demographic-data": "Fülle deine Daten aus",
  "dashboard.player-progress.player-settings": "Lege deinen Namen fest",
  "dashboard.player-progress.invite-teammate": "Lade Teammitglieder ein",
  "dashboard.tour.1": "Am Ende jeder Episode erhältst du eine Übersicht.",
  "dashboard.tour.1.header": "ÜBERBLICK:",
  "dashboard.tour.2": "Hier siehst du, welche Aufgaben du gelöst hast.",
  "dashboard.tour.2.header": "KOMPETENZ:",
  "dashboard.tour.3":
    "Hier siehst du, welche Abzeichen du erhalten hast. Abzeichen gibt es für besondere Leistungen.",
  "dashboard.tour.3.header": "ABZEICHEN:",
  "dashboard.tour.4":
    "Hier siehst du deine persönlichen Materialien zum “Weiterwissen”.",
  "dashboard.tour.4.header": "WEITERWISSEN:",
  "dashboard.tour.5": "Hier kommst du zur Übersichtseite aller Staffeln.",
  "dashboard.tour.5.header": "ZURÜCK:",
  "dashboard.tour.6":
    "Und jetzt viel Spass! Das talent::digital-Team steht dir über den Kontakt-Button jederzeit zur Seite.",
  "dashboard.tour.6.header": "KONTAKT:",
  "dashboard.tour.support-text": "Weiter",
  "dashboard.tour.support-text-finish": "Fertig",
  "dashboard.tour.episode.hint": "Klicke hier, um die erste Episode zu starten",

  "dashboard.company.tour.1":
    "Wie weit ist deine Organisation in Bezug auf deine Ziele?",
  "dashboard.company.tour.1.header": "Missionen",

  "dashboard.company.tour.2":
    "Welche Erkenntnisse aus den Spielerdaten helfen dir bei Verbesserungen?",
  "dashboard.company.tour.2.header": "Signale",

  "dashboard.company.tour.3":
    "Wie ist die Haltung der Mitarbeiter zu deinen Zielen und wie arbeiten sie?",
  "dashboard.company.tour.3.header": "Feedback",

  "dashboard.company.tour.4": "Wie aktiv sind die Mitarbeiter im Spiel?",
  "dashboard.company.tour.4.header": "Aktivitäten",

  "dashboard.recommendations.description": "Beschreibung",
  "dashboard.recommendations.provider": "Anbieter",
  "dashboard.recommendations.trainingType": "Art der Ausbildung",
  "dashboard.recommendations.cost": "Kosten",
  "dashboard.recommendations.trainingType.faceToFace":
    "Von Angesicht zu Angesicht",
  "dashboard.recommendations.trainingType.online": "Online",
  "dashboard.recommendations.free": "Kostenlos",
  "dashboard.recommendations.link": "Besuchen",
};

const buttons = {
  "sf.button.play": "Play!",
  "sf.button.ok": "Ok",
  "sf.button.continue": "Weiter",
  "sf.button.safe": "Sicher",
  "sf.button.unsafe": "Unsicher",
  "sf.button.ask": "Nachfragen",
  "sf.button.search": "Recherchieren",
  "sf.button.level": "Level ",
  "sf.button.finished": "Fertig",
  "sf.button.skip": "Round überspringen?",
  "sf.button.next.round": "Nächste Runde starten",
};

const sfOutro = {
  "sf.outro.level": "Level ",
  "sf.outro.round": "Round ",
  "sf.outro.finished": " FERTIG",
  "sf.outro.levelup": "Du hast ",
  "sf.outro.levelup.round1": " Mails richtig erkannt.",
  "sf.outro.levelup.round2": " Chats richtig erkannt.",
  "sf.outro.levelup.round3": " Websites richtig erkannt.",
  "sf.outro.levelup.round4": " Mails richtig erkannt.",
  "sf.outro.finished.title.round1": "Spam E-Mails",
  "sf.outro.finished.title.round2": "Unsichere Chats",
  "sf.outro.finished.title.round3": "Unsichere Websites",
  "sf.outro.finished.title.round4": "Spear Phishing",
  "sf.outro.finished.text.round1": `Indizien für Spam-E-Mails sind:
    <ul>
      <li>Obskure Absenderadressen.</li>
      <li>Links zu dubiosen Webseiten oder Anhänge.</li>
      <li>Unstimmigkeiten zwischen Absender:in, Titel und Inhalt.</li>
      <li>Drängende Handlungsaufforderungen und sensible Themen.</li>
    </ul>
    Im Zweifelsfall: Liegenlassen oder kurz recherchieren.`,
  "sf.outro.finished.text.round2": `Ein paar Regeln für sichereres Chatten:
    <ul>
      <li>Akzeptiere keine Kontaktanfragen von unbekannten Personen.</li>
      <li>Beantworte niemals Fragen zu Passwörten oder Codes.</li>
      <li>Klicke nur auf Links zu dir bekannten Zielen.</li>
      <li>Sei wachsam bei zusammenhangslosen Kommentaren von Bekannten oder Kolleg:innen ("Schau mal hier", "Bist du das?").</li>
    </ul>
    Im Zweifelsfall: Frage bei bekannten Absender:innen lieber nach oder ignoriere die Nachricht.`,
  "sf.outro.finished.text.round3": `Betrügerische Webseiten können möglicherweise versuchen, dir unerwünschte oder gefährliche Software unterzujubeln oder dir vertrauliche Daten zu entlocken.
    <ul>
      <li>Deshalb achte auf die Adresszeile im Browser ("https" und eine korrekt geschriebene, vertrauenswürdige Adresse).</li>
      <li>Deshalb gebe keine vertraulichen Informationen (Passwörter, Bankdaten) in unbekannte Webseiten ein.</li>
      <li>Deshalb lade keine Dateien von unbekannten Webseiten herunter. Eine Webseite kann nicht beurteilen, ob du veraltete Software oder einen Virus hast.</li>
    </ul>`,
  "sf.outro.finished.text.round4": `Am schwierigsten wird es, wenn Angriffe auf dich persönlich zugeschnitten sind. Dabei ist es unerheblich, ob du "nur ein:e kleine:r Angestellte:r" bist. Du bist möglicherweise der Startpunkt für den nächsten Angriff. Daher:
    <ul>
      <li>Beantworte niemals Unbekannten Fragen zu Internas deiner Organisation. Gib keine Kontakte weiter, die nicht ohnehin öffentlich sind (Rezeption, Support).</li>
      <li>Sei vorsichtig bei zusammenhangslosen Aufforderungen zu sensiblen Aktionen (wie z.B. ein Passwort ändern oder Dateien downloaden), auch wenn sie von Bekannten oder Kolleg:innen sind. Rufe lieber an und frage nach.</li>
    </ul>`,
};

const SFTour = {
  "sf.tour.start.dialog.title": "Einführung",
  "sf.tour.start.dialog.text":
    "Helfe York und markiere potentiell gefährliche Inhalte. Behalte einen kühlen Kopf.",
  "sf.tour.screen2": "Ist diese Nachricht sicher oder unsicher?",
  "sf.tour.screen3": "Die Nachricht ist sicher?<br/> Drücke auf „Sicher“",
  "sf.tour.screen4": "Die Nachricht ist unsicher?<br/> Drücke auf „Unsicher“",
  "sf.tour.screen5":
    "Klicke auf „Nachfragen“, um den Absendenden der Nachricht zu kontaktieren.<br/><br/>Das kostet dich 10 Sekunden deiner Spielzeit.",
  "sf.tour.screen6":
    "Klicke auf „Recherchieren“, um eine Suchmaschine zu nutzen.<br/><br/>Das kostet dich 5 Sekunden deiner Spielzeit.",
  "sf.tour.end.dialog.title": "Sehr gut",
  "sf.tour.end.dialog.text": "Jetzt geht es los!",
};

const sf = {
  "sf.help.dialog.title": "Nachgefragt...",
  "sf.help.dialog.safe": "Der Absender ist sicher!",
  "sf.help.dialog.unsafe": "Der Absender ist unsicher!",
  "sf.start-end-page.start-text":
    "Erpresserische Software oder Phishing Mails – Kriminelle sind oft ziemlich einfallsreich, wenn es darum geht, in die IT-Systeme von Organisationen einzudringen. Schaffst du es, in der vorgegebenen Zeit Gut von Böse zu unterscheiden?",
  "sf.start-end-page.end-text":
    "Nicht komplett durchgekommen? Das macht nichts. Lass dir lieber Zeit und triff die richtigen Entscheidungen als überstürzt den falschen Link zu klicken.",
  "sf.help.search.nohelp": "Ich habe nichts gefunden",
  "sf.help.search.safe": "Dies ist kein Spam",
  "sf.help.search.unsafe": "Dies ist Spam",
  "sf.help.ask.chat.safe":
    "Dein Kontakt bestätigt, dass er dir dies geschickt hat.",
  "sf.help.ask.chat.unsafe":
    "Dein Kontakt sagt, dass er dir diese Nachricht nicht gesendet hat.",
  "sf.help.ask.chat.nohelp":
    "Dieser Kontakt ist nicht in deinem Adressbuch hinterlegt.",
  "sf.help.ask.website":
    "Tut mir leid, ich weiß nicht, wen ich kontaktieren soll.",
  "sf.help.ask.email.nohelp": "Du bekamst keine Antwort.",
  "sf.help.ask.email.safe":
    "Dein Kontakt hat den Versand dieser E-Mail an dich bestätigt.",
};

const quizTime = {
  "quizTime.intro.decision1": "Ja, na klar!",
  "quizTime.intro.decision2": "Nein, dafür bin ich zu müde.",
  "quizTime.avatar.description": "Ich habe da mal eine Frage...",
  "quizTime.title.titleDescription": "(Mehrfachauswahl möglich)",
  "quizTime.title.noQuestion": "Das war´s für's Erste",
  "quizTime.avatar.noQuestion-before-questions":
    "Hier kannst Du dein Wissen testen. An einem roten Kreis auf dem Quiztime-Icon erkennst du, dass es neue Fragen gibt.",
  "quizTime.no-question-info.title":
    "Wir informieren Dich sofern es neue Quiz-Fragen gibt.",
  "quizTime.button.skip": "Überspringen",
  "quizTime.button.finish": "Fertig",
  "quizTime.avatar.answer": "Und hier die Lösung...",
  "quizTime.button.continue": "Weiter",
  "quizTime.button.moreInfo": "Mehr Infos",
  "quizTime.content.box.title": "Mehr Infos zu dem Thema?",
  "quizTime.content.box.open.button": "Anzeigen",
  "quizTime.content.button.back": "Zurück",
  "quizTime.dialog.msg":
    "Hallo {{settings.playerName}}! Hier sind noch ein paar Quiz Time-Fragen zum Thema der aktuellen Episode für dich. Viel Spaß!",
  "quizTime.dialog.button": "Fragen anzeigen",
};

const tinaTweets = {
  "tinaTweets.error": "Tina-Tweets konnten nicht geladen werden",
};

const badges = {
  /** S00EP1 */
  "badges.zero-to-hero": "Zero to hero",
  "badges.description.zero-to-hero":
    "Prima! Du hast alle erforderlichen Daten angegeben und erfolgreich dein Profil erstellt.",
  "badges.safety-first": "Safety first",
  "badges.description.safety-first":
    "Herzlichen Glückwunsch, du hast es geschafft den Cyberangriff abzuwehren mit einem starken, komplexen Passwort!",
  /** S01EP1 */
  "badges.Recherche-King": "Recherche-King",
  "badges.Crypto-Meister": "Crypto-Meister",
  "badges.Organisationstalent": "Organisationstalent",
  "badges.Sharing is caring": "Sharing is caring",

  "badges.description.Recherche-King":
    "Deine Online-Recherche-Kenntnisse sind überdurchschnittlich gut ausgeprägt!",
  "badges.description.Crypto-Meister":
    "Du hast ein überdurchschnittlich ausgeprägtes Auge für gute Apps und Sicherheit.",
  "badges.description.Organisationstalent":
    "Du kannst Termine problemlos koordinieren!",
  "badges.description.Sharing is caring":
    "Du weißt genau, wie man wichtige Links und Informationen mit anderen teilt!",

  /** S01EP2 */
  "badges.Cloud Specialist": "Cloud Specialist",
  "badges.Secret Service": "Secret Service",
  "badges.The Traveler": "The Traveler",

  "badges.description.Cloud Specialist":
    "Du hast ein überdurchschnittliches Auge für Cloud-Anbieter und Sicherheit.",
  "badges.description.Secret Service":
    "Du weisst genau, wie Du wichtige Dateien auf sichere Weise freigeben kannst.",
  "badges.description.The Traveler":
    "Du kannst ganz einfach Tickets online buchen und reisen, wohin Du willst!",

  /** S01EP3 */
  "badges.Good Sign(s)": "Good Sign(s)",
  "badges.Web Watcher": "Web Watcher",
  "badges.Wizard of Code": "Wizard of Code",

  "badges.description.Good Sign(s)":
    "Du weisst genau, wie Du Dokumente digital signierst.",
  "badges.description.Web Watcher":
    "Du weisst, wie Du Alarme einrichtest, damit Du immer auf dem neuesten Stand bist.",
  "badges.description.Wizard of Code":
    "Du hast eine Vorstellung davon, wie Algorithmen funktionieren.",

  /** S01EP4 */
  "badges.Web shield": "Web shield",
  "badges.Work Simplifier": "Simplifier",

  "badges.description.Web shield":
    "Du weißt, wie Du schädliche Websites meldest.",
  "badges.description.Work Simplifier":
    "Du kannst einfache Aufgaben bei der Arbeit automatisieren!",

  /** S01EP5 */
  "badges.Spam Fighter": "Spam Fighter",
  "badges.description.Spam Fighter":
    "Du hast ein überdurchschnittliches Auge für Spam-E-Mails und gefälschte Websites!",

  /** S01EP6 */
  "badges.Knowledge on board": "Wissen an Bord",
  "badges.Automation professional": "Automatisierungs-Profi",

  "badges.description.Knowledge on board":
    "Du weisst so einiges über die digitale Welt!",
  "badges.description.Automation professional":
    "Du kannst schwierige Prozesse einfach automatisieren.",

  /** S01EP7 */
  "badges.Email automation pro": "E-Mail-Automatisierung-Profi",
  "badges.description.Email automation pro":
    "Du kannst dein Mailbox ganz einfach mit Mail-Automatisierungsregeln aufräumen.",

  /** S01EP8 */
  "badges.Digital Zen-Master": "Digital Zen Master",
  "badges.description.Digital Zen-Master":
    "Dein persönliches Wohlbefinden bleibt auch im digitalen Alltag stets im Gleichgewicht. Sehr gut!",

  /** S02EP1 */
  "badges.Little Helper": "Little Helper",
  "badges.description.Little Helper":
    "Glückwunsch! Du weißt genau wie ein Chatbot zur Unterstützung im (Arbeits-)Alltag wird.",

  /** S02E02 */
  "badges.hurdler": "Hürdenläufer",
  "badges.description.hurdler":
    "Egal welcher Kanal und welche Sprache -auch bei spontanen Kommunikationsherausforderungen zwischen mehreren Kolleg:innen behälst du einen kühlen Kopf!",

  "badges.team_builder": "Team Builder",
  "badges.description.team_builder":
    "Teamspirit ist dein Ding - du bist das Bindeglied zwischen Teammitgliedern und erfolgreichen digitalen Teamtreffen!",

  /** S02E03 */
  "badges.Kanban Master": "Kanban Meister",
  "badges.description.Kanban Master":
    "Du beherrschst die hohe Kunst des Kanban und machst dir seine Fähigkeiten mühelos zu eigen! Bravo!",

  /** S02E04 */
  "badges.team_player": "Teamplayer",
  "badges.description.team_player":
    "Du hast mehr als eine Person eingeladen um mit dir zusammen zu arbeiten - dein überaus großes Engagement wird hiermit belohnt!",

  /** global */
  "badges.replay1": "One More Time",
  "badges.description.replay1":
    "Neue Runde, neue Chancen - du gibst nicht auf und spielst eine abgeschlossene Episode noch einmal!",

  "badges.profile-fill": "Der innere Monk",
  "badges.description.profile-fill":
    "100% deines Profils sind ausgefüllt - herzlichen Glückwunsch, du hast es geschafft deinen inneren Monk zufrieden zu stellen!",

  /** S91E01 */
  "badges.s91-Crypto-Meister": "Crypto-Meister",
  "badges.s91-Organisationstalent": "Organisationstalent",

  "badges.description.s91-Crypto-Meister":
    "Du hast ein überdurchschnittlich ausgeprägtes Auge für gute Apps und Sicherheit.",
  "badges.description.s91-Organisationstalent":
    "Du kannst Termine problemlos koordinieren!",

  "badges.s91-Spam Fighter": "Spam Fighter",
  "badges.description.s91-Spam Fighter":
    "Du hast ein überdurchschnittliches Auge für Spam-E-Mails und gefälschte Websites!",

  "badges.s91-Digital Zen-Master": "Digital Zen Master",
  "badges.description.s91-Digital Zen-Master":
    "Dein persönliches Wohlbefinden bleibt auch im digitalen Alltag stets im Gleichgewicht. Sehr gut!",

  "badges.s91-Cloud Specialist": "Cloud Specialist",
  "badges.description.s91-Cloud Specialist":
    "Du hast ein überdurchschnittliches Auge für Cloud-Anbieter und Sicherheit.",

  /** S93E01 */
  "badges.perfect-coffee-gameplay": "Perfekter Koffein-Level",
  "badges.description.perfect-coffee-gameplay":
    "Alle Antworten waren in der Situation perfekt!",

  /** S93E02 */
  "badges.financeguru": "Finanzguru",
  "badges.description.financeguru": "Alle Finanzierungsoptionen sind gezogen!",

  /** S93E04 */
  "badges.service-hero": "Manage it all",
  "badges.description.service-hero":
    "Du hast eine Managed Services Opportunity aufgedeckt!",
  "badges.solution-hero": "Perfektes Solutioneering",
  "badges.description.solution-hero": "Du hast den Kunden perfekt verstanden!",

  /** S94E01 */
  "badges.guter-start": "Guter Start",
  "badges.description.guter-start":
    "Du bist dein ersten Schritt gegangen. Weiter so!",

  /** S94E02 */
  "badges.geballtes-wissen": "Geballtes Wissen",
  "badges.description.geballtes-wissen":
    "Du hast alle Fragen perfekt beantwortet!",

  "badges.leistungsrechtprofi": "Leistungsrecht-Profi",
  "badges.description.leistungsrechtprofi":
    "Du hast alle Quiztime-Fragen richtig!",
};

const appFooter = {
  "appfooter.questinfo.title": "Deine Aufgabe(n)",
};

const quizTaxi = {
  "quiz-taxi.answer.title": "Die Antwort ist...",
  "quiz-taxi.answer.correct": "RICHTIG!",
  "quiz-taxi.answer.incorrect": "FALSCH",
  "quiz-taxi.answer.incorrect.more-info": "Mehr dazu?",
  "quiz-taxi.answer.next": "Nächste Frage",
  "quiz-taxi.joker.call-home.title": "Kontakte",
  "quiz-taxi.finish": "Quiz beenden",
};

const mailAutomation = {
  "mail-automation.rotate-phone.title": "Dreh Dein Telefon",
  "mail-automation.rotate-phone.text":
    "Um die Anwendung nutzen zu können, drehe bitte deinen Bildschirm ins Querformat.",

  "mail-automation.and": "und...",
  "mail-automation.save-rule": "Regel speichern",

  "mail-automation.folders.inbox": "Inbox",
  "mail-automation.folders.do": "Erledigen",
  "mail-automation.folders.delegate": "Delegieren",
  "mail-automation.folders.later": "Später",
  "mail-automation.folders.veryImportant": "Besonders wichtig",
  "mail-automation.folders.bigFiles": "Große Dateien",

  "mail-automation.condition": "Bedingung hinzufügen",
  "mail-automation.select-condition": "Bedingung auswählen",
  "mail-automation.add-another-condition": "Eine weitere Bedingung hinzufügen",
  "mail-automation.keywords": "Schlüsselwörter",
  "mail-automation.action": "Aktion hinzufügen",
  "mail-automation.select-action": "Aktion auswählen",
  "mail-automation.add-another-action": "Weitere Aktion hinzufügen",
  "mail-automation.select-folder": "Ordner auswählen",

  "mail-automation.condition.people": "Personen",
  "mail-automation.condition.my-name-is": "Mein Name ist",
  "mail-automation.condition.subject": "Betreff",

  "mail-automation.attachments": "Anhänge",

  "mail-automation.condition.people.from": "Von",
  "mail-automation.condition.people.except-from": "Außer von",
  "mail-automation.condition.people.to": "An",
  "mail-automation.condition.people.except-to": "Außer an",

  "mail-automation.condition.my-name-is.in-to-line":
    'Ich stehe in der Zeile "An"',
  "mail-automation.condition.my-name-is.in-cc-line":
    "Ich stehe in der Cc-Zeile",
  "mail-automation.condition.my-name-is.in-cc-or-to-line":
    'Ich stehe in der Zeile "An" oder "Cc"',

  "mail-automation.condition.subject.subject-includes": "Betreff enthält",
  "mail-automation.condition.subject.subject-or-body-includes":
    "Betreff oder Nachrichtentext enthält",

  "mail-automation.condition.keywords.body-includes": "Nachrichtentext enthält",
  "mail-automation.condition.keywords.address-includes":
    "Absenderadresse enthält",
  "mail-automation.condition.keywords.header-includes":
    "Nachrichtenkopf enthält",

  "mail-automation.condition.attachments.size-atleast": "Mindestgröße",

  "mail-automation.action.organize": "Organisieren",
  "mail-automation.action.mark-message": "Nachricht markieren",
  "mail-automation.action.route": "Weiterleiten",

  "mail-automation.action.organize.move-to": "Verschieben in",
  "mail-automation.action.organize.copy-to": "Kopieren in",
  "mail-automation.action.organize.delete": "Löschen",

  "mail-automation.action.mark-message.mark-as-read": "Als gelesen markieren",
  "mail-automation.action.mark-message.mark-with-importance":
    "Mit Wichtigkeit markieren",

  "mail-automation.action.route.forward-to": "Weiterleiten an",
  "mail-automation.action.route.redirect-to": "Umleiten an",
  "mail-automation.action.route.send-automatic-reply": "Automatisch antworten",

  "mail-automation.action.add-value": "Wert hinzufügen",
  "mail-automation.action.select-folder": "Ordner auswählen",

  "mail-automation.instructions.wait": "Warte auf Yorks Anweisungen...",
};

const kanbanApp = {
  "kanban.app.column.name.ideas": "Ideen",
  "kanban.app.column.name.todo": "Zu Erledigen",
  "kanban.app.column.name.done": "Fertig",
  "kanban.app.board.members": "Vorstandsmitglieder",
  "kanban.app.board.labels": "Labels",
  "kanban.app.board.save": "Speichern",
  "kanban.app.board.assign.members": "Mitglieder Zuweisen",
  "kanban.app.board.assign.labels": "Labels Bearbeiten",
  "kanban.app.board.assign.position": "Karte Verschieben",
  "kanban.app.board.card.title.placeholder":
    "Gib einen Titel für diese Karte ein...",
  "kanban.app.board.card.dropdown.label": "Karte in Spalte verschieben:",
  "kanban.app.board.card.add": "Eine Karte hinzufügen",
  "kanban.app.board.card.add.confirm": "Karte hinzufügen",
  "kanban.app.board.label.veryHigh": "Sehr Hoch",
  "kanban.app.board.label.high": "Hoch",
  "kanban.app.board.label.medium": "Mittel",
  "kanban.app.board.label.low": "Niedrig",
  "kanban.app.board.label.veryLow": "Sehr Niedrig",
  "kanban.app.instruction": "Warte auf die Anweisungen deiner Kolleg:innen",
};

const signals = {
  "signals.1.what":
    "Management hat sich angemeldet, aber noch keine Episode gespielt.",
  "signals.1.why":
    "Teamleiter und Führungskräfte können ihre Teams unterstützen.",
  "signals.1.how":
    "Ermutige Manager und Teamleiter dazu, einige Episoden zu absolvieren.",
  "signals.2.what": "Es gibt Teams mit zu geringer Teilnehmerzahl.",
  "signals.2.why":
    "Eine Mindestteilnehmerzahl ist erforderlich, um Teamergebnisse zu analysieren.",
  "signals.2.how":
    "Binde die Teamleiter ein und fasse kleine Teams in talent::digital zusammen.",
  "signals.3.what": "Es gibt Funktionen mit zu geringer Teilnehmerzahl.",
  "signals.3.why":
    "Eine Mindestteilnehmerzahl ist erforderlich, um Ergebnisse auf Funktionsebene zu analysieren.",
  "signals.3.how":
    "Motiviere die entsprechenden Teilnehmer und fasse ggf. kleine Funktionen zusammen.",
  "signals.4.what": "Mehr als 16% der Teilnehmer haben kein Team angegeben.",
  "signals.4.why":
    "Eine Mindestteilnehmerzahl ist erforderlich, um Teamergebnisse zu analysieren.",
  "signals.4.how":
    "Motiviere die Teilnehmer und erkläre ggf. die Datenschutzmassnahmen.",
  "signals.5.what":
    "Mehr als 16% der Teilnehmer haben keine Funktion angegeben.",
  "signals.5.why":
    "Eine Mindestteilnehmerzahl ist erforderlich, um die Ergebnisse der Funktionen zu analysieren.",
  "signals.5.how":
    "Motiviere die Teilnehmer und erkläre ggf. die Datenschutzmassnahmen.",
  "signals.6.what": "Erhöhe das Verständnis für sichere Passworte.",
  "signals.6.why":
    "Sichere Passworte senken das Risiko von Störungen und Datenverlusten durch Einbrüche in die IT.",
  "signals.6.how":
    "talent::digital spielt passende Materialien aus und verfolgt die Situation. Du musst nur aktiv werden, wenn sich der Wert mittelfristig nicht ändert.",
  "signals.7.what": "Verbessere die Abwehrkräfte gegen Spam und Phishing.",
  "signals.7.why":
    "Senke das Risiko von finanziellen Verlusten und Datenverlusten durch Phishing.",
  "signals.7.how":
    "talent::digital spielt passende Materialien aus und verfolgt die Situation. Du musst nur aktiv werden, wenn sich der Wert mittelfristig nicht ändert.",
  "signals.8.what": "Es gibt wenige, aktive Teilnehmer mit Führungsfunktion.",
  "signals.8.why":
    "Das Führungskräfte unterstützen Mitarbeiter dabei, Weiterbildung in den Alltag einzubauen.",
  "signals.8.how": "Beziehe die Führungskräfte mit ein.",
};

const other = {
  "button.sales": "Quick-Start",
};

const documentEditor = {
  "document-editor.title": "Editor",
  "document-editor.share": "Freigeben",
  "document-editor.share-dialog.title": "Link abrufen",
  "document-editor.share-dialog.copy-link": "Link kopieren",
  "document-editor.share-dialog.link-copied": "Link kopiert",
  "document-editor.share-dialog.button-text": "Fertig",

  "document-editor.todoList": "Aufgabenliste",
  "document-editor.check": "Dokument überprüfen",
  "document-editor.submit": "Dokument einreichen",
  "document-editor.no-submit":
    "Bitte warte darauf, dass der Spieler, der dir die Einladung zu diesem Dokument geschickt hat, es einreicht.",
  "document-editor.try-again": "Erneut versuchen",
  "document-editor.submit-with-mistakes": "Mit Fehlern einreichen",
  "document-editor.correct":
    "Großartig! Alle Informationen scheinen korrekt zu sein. Ich werde jetzt das Patent für Sie sichern!",
  "document-editor.mistakes":
    "Oh, es scheint, dass einige der Informationen in dem Dokument falsch sind. Sie können das Dokument immer noch aktualisieren und ich werde es noch einmal überprüfen...",

  "document-editor.message0":
    "Bitte ersetzen Sie alle ?s im Dokument durch die richtigen Informationen.",

  "document-editor.message1":
    "Das ist schon mal ein guter Anfang. Ersetzen Sie diese ?s weiter.",

  "document-editor.message2": "Gut, Sie haben fast die Hälfte geschafft.",

  "document-editor.message3": "Gut, nur noch ein paar mehr.",

  "document-editor.message4": "Fast geschafft, bleiben Sie dran!",

  "document-editor.message5":
    "Sehr gut! Jetzt können Sie das Dokument einreichen und ich werde überprüfen, ob die Informationen korrekt sind.",

  "document-editor.connected": "Verbindung besteht",
  "document-editor.disconnected": "Verbindung unterbrochen",

  "document-editor.reload-button": "Dokument neu laden",
  "document-editor.reload.text":
    "Dadurch werden alle an dem Dokument vorgenommenen Änderungen entfernt.",
  "document-editor.reload.agree": "Zustimmen",
  "document-editor.reload.disagree": "Nicht zustimmen",
};

const jciApps = {
  "jciApps.noResults": "Keine Ergebnisse",
  "jciApps.customers": "Kunden",
  "jciApps.solutions": "Infomaterial",
};

const coffeeGameApp = {
  "coffeeGameApp.noScore": "Noch keine Koffeinpunkte",
};

const createConversationDialog = {
  "create.conversation.dialog.your.score":
    "Du hast {score} Koffeinpunkte erreicht.",
  "create.conversation.dialog.no.score":
    "Super, du hast den Dialog beendet. Gut gemacht!",
};
const trainingRecommendations = {
  "training.recommendations.noTraining":
    "Wir haben noch keine Trainingsempfehlung für dich. Schau dir weitere Episoden an, um hier Vorschläge zu sehen.",
};

const botChat = {
  "bot-chat.write-a-message": "Schreiben Sie eine Nachricht...",
  "bot-chat.no-messages": "Keine Meldungen",
};

const errorHandling = {
  missingPlayersDecisionErrorCatch: "[Entscheidung des Spielers]",
};

const messages = {
  ...animation,
  ...appStore,
  ...calendar,
  ...chat,
  ...decisions,
  ...gameControl,
  ...header,
  ...globalMessages,
  ...mailbox,
  ...news,
  ...report,
  ...root,
  ...search,
  ...settings,
  ...socialNetwork,
  ...tour,
  ...onboardingBuyer,
  ...videoCall,
  ...cloudapparticles,
  ...cloudapp,
  ...travelBot,
  ...alerts,
  ...storageApp,
  ...programmingGame,
  ...reboot,
  ...digidoc,
  ...goblinNews,
  ...levelEnd,
  ...notification,
  ...itttapp,
  ...surfpatrol,
  ...dashboard,
  ...buttons,
  ...sfOutro,
  ...SFTour,
  ...sf,
  ...quizTime,
  ...tinaTweets,
  ...badges,
  ...appFooter,
  ...quizTaxi,
  ...mailAutomation,
  ...digitalZen,
  ...boarding,
  ...certificates,
  ...seasonEnd,
  ...other,
  ...chatBot,
  ...meetingsTool,
  ...meetingsToolBrowser,
  ...kanbanApp,
  ...gezwitscherApp,
  ...signals,
  ...documentEditor,
  ...rewinder,
  ...competences,
  ...jciApps,
  ...coffeeGameApp,
  ...createConversationDialog,
  ...trainingRecommendations,
  ...supportedLanguages,
  ...botChat,
  ...errorHandling,
};

export default messages;

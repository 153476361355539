import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 28l13.1 4.6 20.7 20.7c1.6 1.6 1.6 4.1 0 5.7L59 61.8c-1.6 1.6-4.1 1.6-5.7 0L32.6 41.1 28 28zM30.7 29.4l-1.4 1.5"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5 41.9l4.4-4 16.7 16.5-4.3 4.3M9 9c0 1.7-1.8 3-4 3s-4-1.3-4-3 1.8-3 4-3M9 20c0 1.7-1.8 3-4 3s-4-1.3-4-3 1.8-3 4-3M9 31c0 1.7-1.8 3-4 3s-4-1.3-4-3 1.8-3 4-3M9 42c0 1.7-1.8 3-4 3s-4-1.3-4-3 1.8-3 4-3M9 53c0 1.7-1.8 3-4 3s-4-1.3-4-3 1.8-3 4-3"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7V1h45v29M5 18v-6M5 29v-6M5 40v-6M5 51v-6M42 61H5v-5"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 26c-1-7-8-11-15-11-7.1 0-12 5-12 10 0 3.4 2 8 7 8 3 0 6-2 6-5"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;

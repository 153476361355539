import { ContentConfirmActionType } from ".";
import { Message as MessageHubMessage } from "../../message-hub/interfaces";

export const CONTENT_CONFIRM_SET_IMAGE = "contentConfirm.set.image";
export const CONTENT_CONFIRM_BUTTON_CLICKED = "contentConfirm.button.clicked";
export const CONTENT_CONFIRM_SET_ACTION = "contentConfirm.set.action";
export const CONTENT_CONFIRM_SET_ARTICLE = "contentConfirm.set.article";

export type ContentConfirmSetImage = MessageHubMessage<
  typeof CONTENT_CONFIRM_SET_IMAGE,
  {
    src: string;
  }
>;

export type ContentConfirmButtonClicked = MessageHubMessage<
  typeof CONTENT_CONFIRM_BUTTON_CLICKED
>;

export type ContentConfirmSetAction = MessageHubMessage<
  typeof CONTENT_CONFIRM_SET_ACTION,
  {
    type: ContentConfirmActionType;
    label: string;
  }
>;

export type ContentConfirmSetArticle = MessageHubMessage<
  typeof CONTENT_CONFIRM_SET_ARTICLE,
  {
    articleHtmlContent: string;
  }
>;

export type ContentConfirmMessage =
  | ContentConfirmSetImage
  | ContentConfirmButtonClicked
  | ContentConfirmSetAction
  | ContentConfirmSetArticle;

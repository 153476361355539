import { Button, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const StyledHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-start",
}));

export const StyledHeaderDivider = styled("div")(() => ({
  borderBottom: "2px solid rgba(255, 255, 255, 0.6)",
  marginRight: 20,
}));

export const StyledInfoSection = styled("div")(() => ({
  margin: "30px 20px 30px 0",
  display: "flex",
  flexWrap: "wrap",

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      margin: "5px 0 10px",
    },
}));

export const StyledInfoSectionTextContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      margin: "0 20px 0 0",
    },
}));

export const StyledInfoSectionTitle = styled("p")(({ theme }) => ({
  fontSize: "large",
  fontWeight: 700,
  color: "white",
  margin: 0,
  marginBottom: theme.spacing(1),

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      fontSize: "medium",
      lineHeight: 2,
    },
}));

export const StyledEpisodesList = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      overflow: "auto",
    },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgb(255, 255, 255)",
  },
}));

export const StyledInfoButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(({ isSelected }) => ({
  border: "1px solid white",
  borderRadius: 8,
  color: isSelected ? "rgb(25, 66, 74)" : "white",
  backgroundColor: isSelected ? "white" : "transparent",
  fontWeight: 700,

  "&:hover": {
    backgroundColor: isSelected ? "white" : "transparent",
  },

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      "@media (orientation: portrait)": {
        fontSize: 10,
        padding: "5px 5px",
      },

      "@media (orientation: landscape)": {
        fontSize: 10,
        padding: "5px 15px",
      },
    },

  "&:nth-of-type(2)": {
    margin: "0 5px",
  },
}));

export const StyledInfoButtonsRow = styled("div")(() => ({
  margin: "20px 20px 20px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      margin: "8px 20px 8px 0",
    },
}));

export const StyledFurtherInfo = styled("div")(() => ({
  display: "flex",
  margin: "20px 20px 20px 0",

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      margin: "0 20px 0 0",
    },
}));

export const StyledFurtherInfoText = styled("div")(() => ({
  color: "white",
  fontSize: "medium",
  margin: "20px 0",

  "& b": {
    fontSize: "large",
  },

  "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
    {
      "& b": {
        fontSize: "medium",
        lineHeight: 2,
      },
      margin: "5px 0",
    },
}));

export const StyledDialogPopUpHeader = styled("div")(() => ({
  top: 0,
  width: "100%",
  fontSize: "1.6rem",
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
}));

export const StyledDialogPopUpHeaderTitle = styled("p")(() => ({
  justifySelf: "center",
  alignSelf: "center",
}));

export const StyledActionButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "buttonType",
})<{
  buttonType: "cancel" | "confirm";
}>(({ buttonType }) => ({
  border: "2px solid #fff",
  color: "#fff",
  padding: "16px 16px",
  borderRadius: "20px",
  fontFamily: "Roboto",
  letterSpacing: ".5px",
  fontSize: "14px",
  transition: ".5s",
  width: "300px",
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background:
    buttonType === "cancel"
      ? "rgb(158, 163, 172, 0.85)"
      : "rgb(232, 62, 98, 0.9)",
  "&:hover": {
    background:
      buttonType === "cancel" ? "rgb(158, 163, 172, 1)" : "rgb(232, 62, 98, 1)",
  },
}));

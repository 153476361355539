import { Message } from "../../message-hub/interfaces";

export const ALERTS_ADD_ALERT = "alerts.add.alert";
export const INIT_CORRECT_ALERTS = "alerts.init.correct";

export type AlertsAddAlert = Message<
  typeof ALERTS_ADD_ALERT,
  { alerts: string }
>;

export type InitCorrectAlerts = Message<
  typeof INIT_CORRECT_ALERTS,
  { correct: string[] }
>;

export type AlertsAppMessages = AlertsAddAlert | InitCorrectAlerts;

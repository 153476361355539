import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "0",
  completed: false,
  index: 0,
  title: {
    en: "Your very first mission (tutorial)",
    de: "Deine erste Mission (Tutorial)",
  },
  info: {
    en: `The world is threatened - as it often is - by a gang of criminals. They are targeting sensitive company data. There have already been some victims - can you help your company in the fight against cybercrime?`,
    de: `Die Welt wird - wie so oft- von einer Bande Krimineller bedroht. Diese haben es auf sensible Firmendaten abgesehen. Es gabe bereits einige Opfer - kannst du deiner Firma helfen im Kampf gegen die Cyberkriminalität?`,
  },
  topics: [
    {
      competenceAreaId: 4,
      description: {
        de: "Wie du dich und Daten, mit denen du arbeitest, besser schützt.",
        en: "How to better protect yourself and the data you work with.",
      },
    },
    {
      competenceAreaId: 1,
      description: {
        de: "Wie du echte von falschen Nachrichten unterscheiden kannst.",

        en: "How to distinguish real news from fake news.",
      },
    },
  ],
  seasonEndMessage: { de: "", en: "" },
  certificates: [],
  episodes: [
    {
      id: "s00e01",
      title: {
        en: "Welcome to the team!",
        de: "Willkommen im Team!",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Moderatorin Anna und dein Kollege Tim begrüßen dich recht herzlich in deiner digitalen Büroumgebung! Kaum bist du hier angekommmen, wartet auch gleich der erste Auftrag auf dich ...  ",
        en: "Host Anna and your colleague Tim welcome you to your digital office! As soon as you arrive here, the first assignment is already waiting for you ... ",
      },
      imageUrl: "/episodes/s00-e01/background.svg",
      url: "/episodes/s00-e01/",
      load: async () => import(/* webpackChunkName: "s00e01" */ `../s00-e01`),
    },
  ].map((e, index) => ({
    ...e,
    index,
    seasonId: "0",
    skipFeedback: true,
  })),
};

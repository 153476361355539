import React, { useEffect, useState } from "react";
import { getApplicationConfigSingleValue, postEvents } from "./client";
import { getItem, setItem } from "./storage";

export type MicrolearningContent = {
  test: string;
  search?: string;
  embeddable: boolean;
  url: string;
  title: string;
  tool?: { type: string; name: string };
};

export type TakeAwayContent = {
  links?: MicrolearningContent[];
  markdown?: string;
};

type MicrolearningActivity = {
  linksOpened: string[];
  linksVoted: { url: string; value: -1 | 1 }[];
};

export type MicrolearningHook = {
  microlearningActivity: MicrolearningActivity;
  tools: Record<string, string[]>;
  onMicrolearningLinkClicked: (item: MicrolearningContent) => void;
  onMicrolearningLinkVoted: (item: MicrolearningContent, value: -1 | 1) => void;
};

export const useMicrolearning = (): MicrolearningHook => {
  const STORAGE_KEY = "microlearningActivity";

  const [microlearningActivity, setMicrolearningActivity] =
    useState<MicrolearningActivity>(
      getItem(STORAGE_KEY) || { linksOpened: [], linksVoted: [] }
    );

  const [tools, setTools] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (microlearningActivity) {
      setItem(STORAGE_KEY, microlearningActivity);
    }
  }, [microlearningActivity]);

  useEffect(() => {
    getApplicationConfigSingleValue("tools").then((response) => {
      if (response && response !== "1") {
        setTools(JSON.parse(response));
      }
    });
  }, []);

  const onMicrolearningLinkClicked = ({
    url,
    search,
  }: MicrolearningContent): void => {
    postEvents([
      {
        eventTypeId: "microlearning.opened",
        url,
        search,
      },
    ]);
    if (microlearningActivity.linksOpened.includes(url)) return;

    const linksOpened = [...microlearningActivity.linksOpened, url];
    const updatedMicrolearningActivity = {
      ...microlearningActivity,
      linksOpened,
    };
    setMicrolearningActivity(updatedMicrolearningActivity);
  };

  const onMicrolearningLinkVoted = (
    { url, search }: MicrolearningContent,
    value: -1 | 1
  ): void => {
    if (microlearningActivity.linksVoted.some((vote) => vote.url === url))
      return;

    postEvents([
      {
        eventTypeId: "microlearning.voted",
        value,
        url,
        search,
      },
    ]);
    const linksVoted = [...microlearningActivity.linksVoted, { url, value }];
    const updatedMicrolearningActivity = {
      ...microlearningActivity,
      linksVoted,
    };
    setMicrolearningActivity(updatedMicrolearningActivity);
  };

  return {
    microlearningActivity,
    tools,
    onMicrolearningLinkClicked,
    onMicrolearningLinkVoted,
  };
};

export const MicrolearningContext = React.createContext<MicrolearningHook>({
  microlearningActivity: { linksOpened: [], linksVoted: [] },
  tools: {},
  onMicrolearningLinkClicked: (item) => {
    item;
  },
  onMicrolearningLinkVoted: (item, value) => {
    item;
    value;
  },
});

import { CompetenceLevelText } from "core/enums";
import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "93",
  completed: false,
  index: 7,
  title: "OpenBlue Solutioneer",
  info: {
    de: `Corona-Pandemie, drastisch steigende Energiekosten, ESR-Meldepflicht ... was bedeutet das für meine Kunden und meinen Umsatz?`,
    en: `Corona pandemic, drastically increasing cost of energy, mandatory ESR reporting ... what does it mean for my customers and my sales?`,
  },
  topics: [
    {
      competenceAreaId: 2,
      description: {
        de: "Lösungsorientierter Vertrieb",
        en: "Solution-oriented sales",
      },
    },
    {
      competenceAreaId: 5,
      description: {
        de: "Grundlagen von OpenBlue Lösungen",
        en: "Foundations of OpenBlue solutions",
      },
    },
  ],
  certificates: [
    {
      id: "93",
      name: "OpenBlue Solutioneer",
      subcompetences: [78, 105],
      level: CompetenceLevelText.Foundation,
      color: "#08B79C",
      groupName: "jciopenbluesolutions",
    },
  ],
  seasonEndMessage: "",
  episodes: [
    {
      id: "s93e01",
      title: "SAAT & GUT Co. KGaA, Frau Hennings",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        'Du wirst überraschend zu einem Termin mit dem Thema "Wartungsvertrag Brandmeldetechnik" eingeladen, dabei ist der Vetrag längst unter Dach und Fach. Was ist das los?',
      imageUrl: "/episodes/s93-e01/preview.svg",
      url: "/episodes/s93-e01/",
      load: async () => import(/* webpackChunkName: "s93-e01" */ `../s93-e01`),
    },
    {
      id: "s93e02",
      title: "SAAT & GUT Co. KGaA, Dr. Alexander Varus",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Dir ist es gelungen, die Situation zu drehen und das Interesse des Kunden zu wecken. Kannst du SAAT & GUT mit digitalen Mitteln aus der Klemme helfen?",
      imageUrl: "/episodes/s93-e02/preview.svg",
      url: "/episodes/s93-e02/",
      load: async () => import(/* webpackChunkName: "s93-e02" */ `../s93-e02`),
    },
    {
      id: "s93e03",
      title: "Intermezzo: Quiz Duell",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        'Auf dem Weg zu einem Kunden im "Ländle" gerätst du in ein besonderes Taxi…',
      imageUrl: "/episodes/s93-e03/preview.svg",
      url: "/episodes/s93-e03/",
      load: async () => import(/* webpackChunkName: "s93-e03" */ `../s93-e03`),
    },
    {
      id: "s93e04",
      title: "IT Drum AG, Herr York",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "An heissen Sommertagen müssen Mitarbeiter und Server leiden, und Edward York hat den CIO auf der Matte stehen. Wie kannst du eine frische Brise in das Unternehmen bringen?",
      imageUrl: "/episodes/s93-e04/preview.svg",
      url: "/episodes/s93-e04/",
      load: async () => import(/* webpackChunkName: "s93-e04" */ `../s93-e04`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "93" })),
};

import loadable from "@loadable/component";
import { FC, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { BackgrondProps, Background } from "../interfaces";
import { Office } from "./office/office";

type PropsType = {
  background: Background | string | undefined;
};
const backgrounds = {
  [Background.Office]: loadable(() =>
    import("./office/office").then((m) => m.Office)
  ),
  [Background.TrainStation]: loadable(() =>
    import("./train-station").then((m) => m.TrainStation)
  ),
  [Background.Train]: loadable(() =>
    import("./train/train").then((m) => m.Train)
  ),
  [Background.YorksOffice]: loadable(() =>
    import("./yorks-office").then((m) => m.YorksOffice)
  ),
  [Background.BergenStation]: loadable(() =>
    import("./bergen-station").then((m) => m.BergenStation)
  ),
  [Background.VincentsLab]: loadable(() =>
    import("./vincents-lab").then((m) => m.VincentsLab)
  ),
  [Background.FancyOffice]: loadable(() =>
    import("./fancy-office/fancy-office").then((m) => m.FancyOffice)
  ),
  [Background.HospitalConsulting]: loadable(() =>
    import("./hospital/consulting").then((m) => m.HospitalConsulting)
  ),
  [Background.HospitalTheater]: loadable(() =>
    import("./hospital/theater").then((m) => m.HospitalTheater)
  ),
  [Background.HospitalResting]: loadable(() =>
    import("./hospital/resting").then((m) => m.HospitalResting)
  ),
};

export const BackgroundSwitcher = ({ background }: PropsType) => {
  const [idle, setIdle] = useState(false);
  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
  });
  const props: BackgrondProps = {
    svgProps: {
      preserveAspectRatio: "xMidYMax slice",
      style: { width: "100%", height: "100%" },
    },
    idle,
  };
  let content: JSX.Element;
  if (typeof background === "string") {
    content = <BackgroundStatic img={background} />;
  } else {
    const Component: FC<BackgrondProps> = background
      ? backgrounds[background]
      : Office;
    content = <Component {...props} />;
  }

  return (
    <div style={{ position: "fixed", height: "100vh", width: "100%" }}>
      {content}
    </div>
  );
};

export const BackgroundStatic = (props: { img: string }) => {
  const style = {
    backgroundImage: `url(${props.img})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100%",
  };
  return <div style={style} />;
};

import { getItem, setItem } from "core/storage";
import { useEffect, useReducer, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { Episode } from "../../game-engine/interfaces";
import MessageHub from "../../message-hub";
import { postEvents } from "../client";
import { GAME_ENGAGEMENT_POINTS_INCREMENT } from "../messages";
import {
  msgKey,
  MULTIPLE,
  SUB_TITLE_FINAL,
  TITLE_APPROVED,
  TITLE_FEELING,
  TITLE_FINAL,
  TITLE_REJECTED,
  TITLE_SATISFACTION,
  TOPICS,
} from "./data";
import Satisfaction from "./satisfaction";
import { selectArea, SelectFeeling, SelectTopics } from "./select";
import styles from "./styles.module.scss";
import Thanks from "./thanks";

const initialState = {
  title: TITLE_SATISFACTION,
  subtitle: "",
  satisfaction: undefined as undefined | 0 | 1 | 2 | 3 | 4,
  feelings: [] as string[],
  like: [] as string[],
  improve: [] as string[],
  Panel: Satisfaction as any,
  currentPanelFromQueue: undefined as undefined | { area: string; panel: any },
  panelQueue: undefined as undefined | Array<{ area: string; panel: any }>,
};

const reducer = (state: typeof initialState, answer) => {
  let done = false;
  if (state.satisfaction === undefined) {
    state.satisfaction = answer as 0 | 1 | 2 | 3 | 4;
    state.title = state.satisfaction < 3 ? TITLE_REJECTED : TITLE_APPROVED;
    state.subtitle = MULTIPLE;
    state.Panel = SelectTopics;
  } else if (state.Panel === SelectTopics) {
    if (state.satisfaction < 3) {
      const answerList = answer as string[];
      if (answerList.length > 0) {
        state.improve = answerList;
        state.panelQueue = answerList.map(selectArea);
        const q = (state.currentPanelFromQueue = state.panelQueue.pop());
        if (q) {
          state.Panel = q.panel;
        }
        state.title = TOPICS;
      } else {
        done = true;
      }
    } else {
      state.like = answer;
      state.title = TITLE_FEELING;
      state.Panel = SelectFeeling;
    }
  } else if (state.currentPanelFromQueue !== undefined) {
    const area = state.currentPanelFromQueue.area;
    state.improve.push(...answer.map((t) => `${area} > ${t}`));
    if (state.panelQueue && state.panelQueue.length > 0) {
      const q = (state.currentPanelFromQueue = state.panelQueue.pop());
      if (q) {
        state.Panel = q.panel;
      }
    } else {
      done = true;
    }
  } else {
    done = true;
  }

  if (done) {
    if (state.Panel === SelectFeeling) state.feelings = answer;
    state.title = TITLE_FINAL;
    state.subtitle = SUB_TITLE_FINAL;
    state.Panel = Thanks;
  }

  return { ...state };
};

export default function LevelSurvey(props: { episode: Episode; hash: string }) {
  const intl = useIntl();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const event = useRef<any>({});
  const titleValues = state.currentPanelFromQueue
    ? {
        area: intl.formatMessage({
          id: msgKey(state.currentPanelFromQueue.area),
        }),
      }
    : {};

  if (event.current !== undefined) {
    event.current = {
      episode: props.episode.id,
      hash: props.hash,
      eventTypeId: "level.survey",
      data: {
        satisfaction: state.satisfaction,
        feelings: state.feelings,
        like: state.like,
        improve: state.improve,
      },
    };
  }

  const submitForm = () => {
    if (event.current && event.current.data.satisfaction !== undefined) {
      const survey = getItem("levelSurvey") || [];
      setItem("levelSurvey", [...survey, props.hash]);
      postEvents([{ ...event.current }]);
      event.current = undefined;
    }
  };
  useEffect(() => {
    if (state.Panel === Thanks) {
      MessageHub.send({
        type: GAME_ENGAGEMENT_POINTS_INCREMENT,
        payload: {
          points: 5,
        },
      });
      submitForm();
    }
  }, [state.Panel]);

  useEffect(() => submitForm, []);

  return (
    <div className={styles.container}>
      <header className={styles.title}>
        <h1>
          <FormattedMessage id={state.title} values={titleValues} />
        </h1>
        <small>
          {state.subtitle && <FormattedMessage id={state.subtitle} />}
        </small>
      </header>
      {state.Panel && <state.Panel onAnswer={dispatch} />}
    </div>
  );
}

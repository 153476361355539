import { makeStyles } from "@mui/styles";
import AppLayout from "../app-layout/app-layout";
import { useDeviceDetect } from "../hooks";
import { Application } from "../interfaces";
import AppIcon from "./app-icon";

const styles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

interface AppSwitcherProps {
  applications: Pick<
    Application,
    "id" | "icon" | "name" | "notification" | "path" | "color"
  >[];
}

const AppSwitcher = ({ applications, ...props }: AppSwitcherProps) => {
  const classes = styles();
  const device = useDeviceDetect();

  return (
    <AppLayout
      contentColor="transparent"
      desktopMode="none"
      content={
        <div
          data-app-launcher
          data-e2e-app-switcher
          className={classes.root}
          style={{
            margin: device.browser ? "50px 25px" : "0",
            width: device.browser ? "calc(100% - 50px)" : "100%",
          }}
        >
          {applications.map((application, idx) => (
            <div
              key={idx}
              style={{
                marginLeft: device.browser ? "25px" : "0",
              }}
            >
              <AppIcon application={application} />
            </div>
          ))}
        </div>
      }
    />
  );
};

export default AppSwitcher;

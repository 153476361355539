import * as clickReveal from "apps/click-reveal/messages";
import * as contentConfirm from "apps/content-confirm/messages";
import * as imageGapFilling from "apps/image-gap-filling/messages";
import * as alert from "../apps/alerts/messages";
import * as appStore from "../apps/app-store/messages";
import * as breakingNews from "../apps/breaking-news/messages";
import * as calendar from "../apps/calendar/messages";
import * as chatbot from "../apps/chat-bot/messages";
import * as chat from "../apps/chat/messages";
import * as cloudApp from "../apps/cloud-app/messages";
import * as digidoc from "../apps/digidoc/messages";
import * as digitalZen from "../apps/digital-zen/messages";
import * as documentEditor from "../apps/document-editor/messages";
import * as gezwitscher from "../apps/gezwitscher/messages";
import * as goblinNews from "../apps/goblin-news/messages";
import * as itttapp from "../apps/ittt-app/messages";
import * as kanbanApp from "../apps/kanban-app/messages";
import * as mailAutomationTool from "../apps/mail-automation-tool/messages";
import * as email from "../apps/mailbox/messages";
import * as meetingsToolBrowser from "../apps/meetings-tool-browser/messages";
import * as meetingsTool from "../apps/meetings-tool/messages";
import * as playerSetup from "../apps/player-setup/messages";
import * as programmingGame from "../apps/programming-game/messages";
import * as quizTaxi from "../apps/quiz-taxi/messages";
import * as quizTime from "../apps/quiz-time/messages";
import * as search from "../apps/search/messages";
import * as socialnetwork from "../apps/socialnetwork/messages";
import * as spamFighter from "../apps/spam-fighter/messages";
import * as speedCheck from "../apps/speed-check/messages";
import * as storageApp from "../apps/storage-app/messages";
import * as surfPatrol from "../apps/surf-patrol/messages";
import * as travelBot from "../apps/travel-bot/messages";
import * as videoCallOpenAi from "../apps/video-call-openai/messages";
import * as videoCall from "../apps/video-call/messages";
import * as coffeeGame from "../core/coffee-game/messages";
import * as news from "../core/news/messages";
import * as decision from "./decision/messages";
import * as directMessages from "./direct-communication/messages";
import * as guidedCommunication from "./guided-communication/messages";
import * as common from "./messages";
import * as root from "./messages";

const messages = {
  ...chat,
  ...news,
  ...email,
  ...search,
  ...common,
  ...directMessages,
  ...appStore,
  ...calendar,
  ...decision,
  ...root,
  ...socialnetwork,
  ...videoCall,
  ...cloudApp,
  ...travelBot,
  ...programmingGame,
  ...storageApp,
  ...digidoc,
  ...goblinNews,
  ...alert,
  ...itttapp,
  ...surfPatrol,
  ...spamFighter,
  ...speedCheck,
  ...quizTime,
  ...quizTaxi,
  ...breakingNews,
  ...mailAutomationTool,
  ...digitalZen,
  ...chatbot,
  ...meetingsTool,
  ...kanbanApp,
  ...meetingsToolBrowser,
  ...playerSetup,
  ...documentEditor,
  ...gezwitscher,
  ...coffeeGame,
  ...contentConfirm,
  ...imageGapFilling,
  ...clickReveal,
  ...guidedCommunication,
  ...videoCallOpenAi,
};

export default messages;

const messages = {
  "zen.master": "You are the master of Zen!",
  "zen.reach": "Reach your 7 digital chakras",
  "zen.skip": "Skip",
  "zen.next": "Next",
  "zen.moreinfo": "More info on the topic?",
  "zen.start": "Start",
  "zen.done": "Done",
  "zen.back": "Back",
  "zen.continue": "Continue",
  "zen.multiple": "Multiple options are possible",
  "zen.moreinfotitle": "More info",
  "zen.question": "Knowledge question",
  "zen.assess": "Self-assessment",
};

export default messages;

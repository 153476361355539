import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#DEFFFF",
    display: "flex",
    flexDirection: "column",
    width: 300,
    minWidth: 220,
    overflowY: "auto",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        width: "70vw",
        minWidth: "70vw",
        transform: "translateX(-71vw)",
      },
  },
  opened: {
    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        animation: "$open 300ms linear forwards",
      },
  },
  closed: {
    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        animation: "$close 300ms linear forwards",
      },
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 20%",

    borderBottom: "1px solid rgba(168, 171, 183, 0.6)",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        margin: "0 10px",
      },
  },
  avatar: {
    width: "50%",
    minWidth: 60,
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6vh",
    userSelect: "none",

    "& img": {
      height: "80%",
      maxWidth: "100%",
    },

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        marginTop: "4vh",
        width: "30%",
      },
  },
  playerName: {
    fontSize: 18,
    fontFamily: `'Lato', sans-serif`,
    userSelect: "none",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        marginBottom: "2vh",
      },
  },
  playerProfileLink: {
    fontSize: 18,
    color: "#116E6B",
    fontFamily: `'Lato', sans-serif`,
    fontWeight: 700,
    userSelect: "none",
    marginTop: 0,
    marginBottom: "1em",
    textDecoration: "none",

    "&:hover": {
      fontWeight: 900,
    },
  },
  playerProfileLinkActive: {
    fontWeight: 900,
    textDecoration: "underline",
  },
  menu: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  menuTitle: {
    fontFamily: `'Lato', sans-serif`,
    fontSize: 16,
    fontWeight: 900,
    letterSpacing: 3,
    marginLeft: "20%",
    marginTop: 35,
    userSelect: "none",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        marginTop: "3vh",
      },
  },
  menuItem: {
    display: "flex",
    textDecoration: "none",
    "& .indicator": {
      width: "20%",
      opacity: 0,
    },
    "&.active .indicator": {
      opacity: 1,
    },
  },
  indicator: {
    width: 10,
    backgroundColor: "#116E6B",
    height: "100%",
  },
  menuItemText: {
    color: "#272727",
    fontFamily: `'Lato', sans-serif`,
    fontWeight: 18,
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    fontSize: "0.875rem",

    "& img:first-of-type": {
      marginRight: 15,
      width: 20,
    },
    "& .lock": {
      marginLeft: 8,
    },
  },
  supportButton: {
    alignSelf: "center",
    width: "60%",
    backgroundColor: "#F08224",
    color: "white",

    "&:hover": {
      backgroundColor: "#F08224",
    },

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        margin: 0,
        borderRadius: 0,
        height: 50,
        flex: "1 1 auto",
      },
  },
  logOutButton: {
    alignSelf: "center",
    width: "60%",
    backgroundColor: "rgba(39, 177, 153, 0.15)",
    margin: "15px 0 30px",
    color: "#27B199",

    "&:hover": {
      backgroundColor: "rgba(39, 177, 153, 0.15)",
    },

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        margin: 0,
        borderRadius: 0,
        height: 50,
        flex: "1 1 auto",
      },
  },
  inviteColleagueWrapper: {
    display: "flex",
    textDecoration: "none",

    "&.active > div": {
      opacity: 1,
    },
  },
  inviteColleague: {
    margin: "0 20% 0 calc(20% - 10px)",
    flex: "1 1 auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(168, 171, 183, 0.6)",

    "& img": {
      marginRight: 10,
    },
    "& p": {
      fontSize: 16,
      margin: "15px 0",
      userSelect: "none",
      color: "#272727",
      fontFamily: "Lato",
      wordBreak: "break-word",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0 10px 0 0",
    },
  },
  inviteColleagueIndicator: {
    flex: "0 0 10px",
    backgroundColor: "#116e6b",
    height: 50,
    margin: "auto 0",
    opacity: 0,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        flexDirection: "row",
        alignItems: "flex-start",
      },
  },

  "@keyframes open": {
    from: {
      transform: "translateX(-71vw)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  "@keyframes close": {
    from: {
      transform: "translateX(0)",
    },
    to: {
      transform: "translateX(-71vw)",
    },
  },
}));

export default styles;

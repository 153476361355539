import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import MessageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";

const styles = makeStyles((theme: Theme) => ({
  openModal: {
    position: "fixed",
    zIndex: 2000,
    bottom: 0,
    width: "100%",
    padding: theme.spacing(2),
    background: "rgba(255, 255, 255, 1)",
    overflowY: "scroll",
  },
  openButton: {
    position: "fixed",
    zIndex: 2000,
    bottom: theme.spacing(10),
    left: theme.spacing(3),
    width: 24,
    height: 24,
    borderRadius: "100%",
    padding: 0,
    background: "#FEC330",
    border: "none",
  },
  options: {
    position: "fixed",
    zIndex: 2001,
    display: "flex",
    gap: theme.spacing(1),
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

const unsubscribe = new Subject<void>();
const visibleSubject = new Subject<void>();

export const MessageHubDebugTool = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [messages, setMessages] = useState<Message<string, any>[]>([]);
  const [searchTypeInput, setSearchTypeInput] = useState("");
  const [searchEverythingInput, setSearchEverythingInput] = useState("");
  const classes = styles();

  useEffect(() => {
    MessageHub.subject.pipe(takeUntil(unsubscribe)).subscribe((incomingMsg) => {
      setMessages((msg) => [incomingMsg, ...msg]);
    });
    visibleSubject.pipe(takeUntil(unsubscribe)).subscribe(() => {
      setVisible(true);
    });

    return () => {
      unsubscribe.next();
      unsubscribe.complete();
    };
  }, []);

  if (!visible) {
    return null;
  }

  if (expanded) {
    return (
      <div
        className={classes.openModal}
        style={{ height: fullscreen ? "100vh" : "50vh" }}
      >
        <div className={classes.options}>
          <input
            placeholder="Filter by type"
            value={searchTypeInput}
            onChange={(e) => {
              setSearchTypeInput(e.target.value);
            }}
          />
          <input
            placeholder="Filter by everything"
            value={searchEverythingInput}
            onChange={(e) => {
              setSearchEverythingInput(e.target.value);
            }}
          />
          <button onClick={() => setFullscreen((v) => !v)}>
            Toggle Fullscreen
          </button>
          <button onClick={() => setMessages([])}>Clear</button>
          <button onClick={() => setExpanded(false)}>Close</button>
        </div>
        {messages
          .filter((msg) => msg.type.includes(searchTypeInput))
          .filter((msg) => JSON.stringify(msg).includes(searchEverythingInput))
          .map((message, index) => (
            <div
              key={index}
              style={{ background: index % 2 ? "#eee" : "#fff" }}
            >
              <pre>{JSON.stringify(message, null, 2)}</pre>
            </div>
          ))}
      </div>
    );
  }

  return (
    <button className={classes.openButton} onClick={() => setExpanded(true)} />
  );
};

function msgDebug() {
  visibleSubject.next();
}

(window as any).msgDebug = msgDebug;

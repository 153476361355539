import { getIntl, getLanguage } from "i18n";
import LogRocket from "logrocket";
import { Observable } from "rxjs";
import { ofTypes } from "../core/applications";
import { DecisionOption } from "../core/decision/interfaces/decision-option";
import { DecisionPayload } from "../core/decision/interfaces/decision-payload";
import { decisionChoose, DECISION_CHOOSE } from "../core/decision/messages";
import { Message } from "../message-hub/interfaces";

type DecisionsMultipleType = Record<
  DecisionPayload["id"],
  Record<DecisionOption["id"], DecisionOption>
>;

const language = getLanguage();
const intl = getIntl(language);

export class DecisionStore {
  decisions = {} as Record<DecisionPayload["id"], DecisionOption>;
  decisionsMultiple = {} as DecisionsMultipleType;

  constructor(messages: Observable<Message>) {
    messages
      .pipe(ofTypes(DECISION_CHOOSE))
      .subscribe((msg) => this.onMessage(msg as decisionChoose));
  }

  private onMessage = (msg: decisionChoose) => {
    this.decisions[msg.payload.decision] = msg.payload.option;
    const multiple = (this.decisionsMultiple[msg.payload.decision] =
      this.decisionsMultiple[msg.payload.decision] || {});
    multiple[msg.payload.option.id] = msg.payload.option;
  };

  replaceDecision = (id: string, property?: string): string => {
    const decision = this.decisions[id];
    if (property) {
      return decision.data[property];
    }

    const maybeDecisionText = decision?.title || decision?.text;

    if (maybeDecisionText) {
      return maybeDecisionText;
    }

    LogRocket.captureMessage(getDecisionReasonError(id));

    return (intl.messages.missingPlayersDecisionErrorCatch as string) ?? "";
  };

  replaceMultipleDecision = (
    decisionId: string,
    optionId?: string,
    property?: string
  ) => {
    let decision: DecisionOption;

    if (optionId) {
      decision = this.decisionsMultiple[decisionId][optionId];
    } else {
      decision = Object.values(this.decisionsMultiple[decisionId])[0];
    }

    if (property) {
      return decision.data[property];
    }
    return decision.title || decision.text;
  };
}

const getDecisionReasonError = (decisionId: string) => {
  return `
  For unkown reason the decision with id: "${decisionId}" has not
  been found when trying to replace it with the player's decision.
  As per TI-2521 we have decided to replace missing decisions
  with a placeholder text to avoid breaking the game.
  This solution is not ideal as it does not fix the core issue
  that as to why the decision was missing in the first place but
  at least it is safe and allows for the player to continue playing.
  This error has been initially found when replying an old savegame on
  v2demo so it did not have any production impact.
  `;
};

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: "#ff0000",
    color: "#fff",
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
  },
  iconVariant: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface Props {
  open: boolean;
  message: string;
  onClose: () => void;
}

const InformSnackbar = (props: Props) => {
  const { open, message, onClose } = props;
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={105000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes.error}
        aria-describedby="inform-snackbar"
        message={
          <span id="inform-snackbar" className={classes.message}>
            <ErrorIcon className={classes.iconVariant} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default InformSnackbar;

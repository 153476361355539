import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { Notification } from "./interfaces";
import styles from "./notification.module.scss";

type Props = {
  notification: Notification;
  onDone: () => void;
};
const NotificationComponent: React.FC<Props> = ({
  notification,
  onDone,
}: Props) => {
  const history = useHistory();
  const icons = Array.isArray(notification.icon)
    ? notification.icon
    : notification.icon
    ? [notification.icon]
    : [];

  const actors = Array.isArray(notification.actor)
    ? notification.actor
    : notification.actor
    ? [notification.actor]
    : [];

  const chat = notification.appId !== "videoCall";

  const onConfirm = () => {
    if (notification.appId === "chat") {
      const roomId = notification.chatRoomId || actors[0];
      history.push(`/chat/${roomId}`);
    }
    onDone();
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2
          className={`${styles.title} animate__animated animate__slideInDown`}
        >
          {notification.topText}
        </h2>
        <div
          className={`${styles.dialog} animate__animated animate__slideInUp`}
          style={{ backgroundColor: notification.backgroundColor || "#ddd" }}
        >
          {notification.topIcon && (
            <div
              className={styles.notification}
              style={
                notification.topBackgroundColor
                  ? { backgroundColor: notification.topBackgroundColor }
                  : {}
              }
            >
              <img src={notification.topIcon} alt="Icon" />
            </div>
          )}
          <h1
            className={styles.dialogTitle}
            style={{ paddingTop: notification.topIcon ? 20 : 0 }}
          >
            {notification.title}
            {actors.length === 1 && chat && (
              <>
                <FormattedMessage id="notification.from" />
                {actors.map((actor: string, idx: number) => (
                  <span key={idx} style={{ textTransform: "capitalize" }}>
                    {actor}{" "}
                  </span>
                ))}
              </>
            )}
          </h1>
          {icons.length > 0 && (
            <div className={`${icons.length > 1 ? styles.icons : styles.icon}`}>
              {icons.map((i, ix) => (
                <img key={ix} src={i} alt={notification.title} />
              ))}
            </div>
          )}
          <p
            style={{ color: notification.textColor || "#666" }}
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: notification.description || "",
            }}
          />
          <button
            data-e2e-notification-button
            id="notificationButton"
            onClick={onConfirm}
            className={`${styles.button} animate__animated animate__slideInUp`}
            style={{ backgroundColor: notification.buttonColor || "#03BEA5" }}
          >
            {notification.buttonLabel || <FormattedMessage id="global.ok" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationComponent;

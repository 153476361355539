import { CompetenceLevelText } from "core/enums";
import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "95",
  completed: false,
  index: 1,
  title: "Der Kooperationsplan im Bürgergeld",
  info: {
    de: `Ab dem 1.7.2023 wird der zweite Teil des Bürgergeld-Gesetzes verpflichtend. Mit diesem wird die Eingliederungsvereinbarung durch den Kooperationsplan abgelöst. Ergänzen Sie Ihr praktisches Wissen zum Kooperationsplan und lernen Sie Details zu den rechtlichen Regelungen durch Quizze, Szenarien, Artikel und Fachvorträge vom renommierten Bürgergeld-Experten Alexander Lahne.`,
    en: `From 1 July 2023, the second part of the Citizen's Income Act will become mandatory. With this, the integration agreement will be replaced by the cooperation plan. Supplement your practical knowledge of the cooperation plan and learn details about the legal regulations through quizzes, scenarios, articles and expert lectures by the renowned citizen's income expert Alexander Lahne.`,
  },
  topics: [
    {
      competenceAreaId: 1,
      description: {
        de: "Grundlagen und Inhalte des Kooperationsplans",
        en: "Principles and content of the cooperation plan",
      },
    },
    {
      competenceAreaId: 5,
      description: {
        de: "Die Potenzialanalyse",
        en: "Potential analysis",
      },
    },
    {
      competenceAreaId: 2,
      description: {
        de: "Der Kooperationsplan in der Praxis",
        en: "The cooperation plan in practice",
      },
    },
  ],
  certificates: [
    {
      id: "95",
      name: "Der Kooperationsplan im Bürgergeld",
      subcompetences: [
        110101, 110102, 110201, 110202, 110203, 110301, 110302, 110303, 110401,
        110402, 110403, 110404, 110501, 110502, 110503,
      ],
      level: CompetenceLevelText.Foundation,
      color: "#08B79C",
      groupName: "kooperationsplan",
    },
  ],
  seasonEndMessage: "",
  episodes: [
    {
      id: "s95e01",
      testsId: "season95episode1",
      title: "Vorgänger des Kooperationsplans: Die Eingliederungsvereinbarung",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Die Eingliederungsvereinbarung ist der Vorläufer des Kooperationsplans. Um die Veränderungen und Unterschiede gut zu verstehen, beginnen wir mit einer Auffrischung zu diesem Thema. Hinweis: Wir empfehlen neuen Mitarbeitenden, diese Episode zu überspringen.",
      imageUrl: "/episodes/s95-e01/preview.svg",
      url: "/episodes/s95-e01/",
      load: async () => import(/* webpackChunkName: "s95-e01" */ `../s95-e01`),
    },
    {
      id: "s95e02",
      testsId: "season95episode2",
      title: "Die gleiche Hausnummer",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode geht es um den § 15 SGB II, der die Rechtsgrundlage des Kooperationsplans darstellt.",
      imageUrl: "/episodes/s95-e02/preview.svg",
      url: "/episodes/s95-e02/",
      load: async () => import(/* webpackChunkName: "s95-e02" */ `../s95-e02`),
    },
    {
      id: "s95e03",
      testsId: "season95episode3",
      title: "Hat Amy Potenzial?",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Diese Episode schaut weiterhin auf den § 15 SGB II, wobei das Augenmerk auf die Potenzialanalyse gerichtet ist.",
      imageUrl: "/episodes/s95-e03/preview.svg",
      url: "/episodes/s95-e03/",
      load: async () => import(/* webpackChunkName: "s95-e03" */ `../s95-e03`),
    },
    {
      id: "s95e04",
      testsId: "season95episode4",
      title: "Abschluss-Unsicherheiten",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zum Abschluss eines Kooperationsplans an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e04/preview.svg",
      url: "/episodes/s95-e04/",
      load: async () => import(/* webpackChunkName: "s95-e04" */ `../s95-e04`),
    },
    {
      id: "s95e05",
      testsId: "season95episode5",
      title: "Was steht im Kooperationsplan?",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zu den Inhalten eines Kooperationsplans an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e05/preview.svg",
      url: "/episodes/s95-e05/",
      load: async () => import(/* webpackChunkName: "s95-e05" */ `../s95-e05`),
    },
    {
      id: "s95e06",
      testsId: "season95episode6",
      title: "Der Bote der Verbote",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zu Inhalten an, die im Kooperationsplan nichts zu suchen haben, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e06/preview.svg",
      url: "/episodes/s95-e06/",
      load: async () => import(/* webpackChunkName: "s95-e06" */ `../s95-e06`),
    },
    {
      id: "s95e07",
      testsId: "season95episode7",
      title: "Verflixte Form",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zur Form des Kooperationsplans an, wobei wir nicht nur im Gebiet des §15 SGB II bleiben, sondern auch über den Tellerrand hinaus schauen.",
      imageUrl: "/episodes/s95-e07/preview.svg",
      url: "/episodes/s95-e07/",
      load: async () => import(/* webpackChunkName: "s95-e07" */ `../s95-e07`),
    },
    {
      id: "s95e08",
      testsId: "season95episode8",
      title: "Fortschreibung folgt…",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zu Inhalten bzw. konkret zur Aktualisierung und Fortschreibung dieser an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e08/preview.svg",
      url: "/episodes/s95-e08/",
      load: async () => import(/* webpackChunkName: "s95-e08" */ `../s95-e08`),
    },
    {
      id: "s95e09",
      testsId: "season95episode9",
      title: "„Erste Einladung“",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zur ersten Gesprächseinladung zur Erstellung der Potenzialanalyse und des Kooperationsplans an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e09/preview.svg",
      url: "/episodes/s95-e09/",
      load: async () => import(/* webpackChunkName: "s95-e09" */ `../s95-e09`),
    },
    {
      id: "s95e10",
      testsId: "season95episode10",
      title: "Aufforderung ohne Mühe",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In dieser Episode schauen wir uns einige Details zu den Eigenbemühungen und der Neuerung der Aufforderung mit Rechtsfolgenbelehrung an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e10/preview.svg",
      url: "/episodes/s95-e10/",
      load: async () => import(/* webpackChunkName: "s95-e10" */ `../s95-e10`),
    },
    {
      id: "s95e11",
      testsId: "season95episode11",
      title: "Best of - Wesentliche Unterschiede",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In der finalen Episode schauen wir uns noch einmal die wesentlichen Unterschiede zwischen der Eingliederungsvereinbarung und dem Kooperationsplan an, wobei wir weiterhin im Gebiet des §15 SGB II bleiben.",
      imageUrl: "/episodes/s95-e11/preview.svg",
      url: "/episodes/s95-e11/",
      load: async () => import(/* webpackChunkName: "s95-e11" */ `../s95-e11`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "95" })),
};

import { UserInfo } from "../core/interfaces";
import { GAME_CURRENT_QUEST, USER_STUCK } from "../core/messages";
import { getItem } from "../core/storage";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";

const LOGROCKET_ID = "hdghyj/profile-2";

export async function initLogRocket(
  userInfo: UserInfo
): Promise<typeof LogRocket | undefined> {
  if (getItem("notrack")) return;
  const { default: LogRocket } = await import("logrocket");
  const { playerName } = getItem("SETTINGS");
  LogRocket.init(LOGROCKET_ID);
  LogRocket.identify(userInfo.sub, {
    name: playerName || "anonymous",
  });
  const tracker = (m: Message) => {
    if (m.type === GAME_CURRENT_QUEST || m.type === USER_STUCK) {
      LogRocket.track(`${m.episode}:${m.type}:${m.payload?.title}`);
    }
  };
  MessageHub.track(tracker);
  return LogRocket;
}

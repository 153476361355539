import { CompetenceLevelText } from "../../core/enums";
import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "1",
  completed: false,
  index: 1,
  title: {
    en: "When ideas learn to fly",
    de: "Wenn Ideen fliegen lernen",
  },
  info: {
    en: `As a celebrated genius inventor, you turn your back on the intelligence services and the military to put your knowledge at the service of mankind in the future.
    Edward York, philanthropist and entrepreneur, is enthusiastic about your new direction and tests your skills. An old enemy also appears on the scene ... Will you be able to lead the enterprise to success?`,
    de: `Als gefeiertes Erfinder-Genie kehrst du Geheimdiensten und Militär den Rücken, um dein Wissen zukünftig in den Dienst der Menschheit zu stellen.
    Edward York, Philanthrop und Unternehmer, ist von deiner Neuausrichtung begeistert und testet deine Fähigkeiten. Ein alter Feind erscheint zudem auf der Bildfläche … Gelingt es dir die Unternehmung zum Erfolg zu führen?`,
  },
  topics: [
    {
      competenceAreaId: 5,
      description: {
        de: "So erleichtern Cloud-Tools deine Arbeit.",
        en: "How cloud tools make your work easier.",
      },
    },
    {
      competenceAreaId: 4,
      description: {
        de: "Wie du dich und Daten, mit denen du arbeitest, besser schützt.",
        en: "How to better protect yourself and the data you work with.",
      },
    },
  ],
  seasonEndMessage: {
    de: "Das Ende war ja ganz schön explosiv. Zum Glück ist niemand zu Schaden gekommen! Wie es mit dem SunCopter weitergeht, wird die Zukunft zeigen. Obwohl du die Geschichte abgeschlossen hast, kannst du die Episoden immer wieder spielen, um fehlende Kompetenzdiamanten und Abzeichen zu sammeln und somit dein digitales Wissen weiter vertiefen. Viel Spaß!",
    en: "The end was quite explosive. Fortunately, no one was hurt! Only time will tell what happens next with the SunCopter. Even though you've completed the story, you can play the episodes over and over again to collect missing competence diamonds and badges, further deepening your digital knowledge. Have fun!",
  },

  certificates: [
    // TI-2846 - Commented out the certificates that are not used in the season..
    // {
    //   id: "1",
    //   name: "Security Basics",
    //   subcompetences: [94, 95, 97, 98, 99, 100],
    //   level: CompetenceLevelText.Foundation,
    //   color: "#08B79C",
    //   groupName: "basics_of_security",
    // },
    // {
    //   id: "2",
    //   name: "Automation Basics",
    //   subcompetences: [105, 107],
    //   level: CompetenceLevelText.Foundation,
    //   color: "#EF3D61",
    //   groupName: "basics_of_automation",
    // },
    {
      id: "3",
      name: "Wenn Ideen fliegen lernen",
      subcompetences: [
        50, 72, 75, 95, 97, 98, 107, 71, 64, 77, 105, 89, 81, 67, 68, 94, 111,
        99, 100,
      ],
      level: CompetenceLevelText.Foundation,
      color: "#EF3D61",
      groupName: "roadMAPseason1",
    },
  ],
  episodes: [
    {
      id: "s01e01",
      title: {
        de: "Neustart",
        en: "Restart",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Zukünftig willst du deine Fähigkeiten ausschließlich in den Dienst des Guten stellen. Es kommt dir daher gelegen, dass der bekannte Unternehmer Edward York mit einem Auftrag auf dich zukommt.",
        en: "In the future, you want to put your skills exclusively in the service of good. It therefore comes in handy that the well-known entrepreneur Edward York approaches you with an order.",
      },
      imageUrl: "/episodes/s01-e01/background-office-full-view.svg",
      url: "/episodes/s01-e01/",
      load: async () => import(/* webpackChunkName: "s01e01" */ `../s01-e01`),
    },
    {
      id: "s01e02",
      title: {
        de: "Die Reise beginnt",
        en: "The journey begins",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Der Angriff auf dein System hat dir gezeigt, dass du verwundbar bist. Goblin Inc. will deine Idee boykottieren. Dennoch bist du bestärkt darin, den eingeschlagenen Weg weiter fortzuführen.",
        en: "The attack on your system has shown you that you are vulnerable. Goblin Inc. wants to boycott your idea. Nevertheless, you are encouraged to continue on the path you have chosen.",
      },
      imageUrl: "/episodes/s01-e02/images/background-train-station.svg",
      url: "/episodes/s01-e02/",
      load: async () => import(/* webpackChunkName: "s01e02" */ `../s01-e02`),
    },
    {
      id: "s01e03",
      title: {
        de: "Alleine im Zug",
        en: "Alone on the train",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Du bist auf dem Weg zu Vincent Vector, um die Entwicklung des SunCopters voran zu treiben. Eigentlich sollte es eine entspannte Reise werden, doch stattdessen hast du eine Menge zu erledigen.",
        en: "You are on your way to Vincent Vector to advance the development of the SunCopter. It was supposed to be a relaxing trip, but instead you have a lot to do.",
      },
      imageUrl: "/episodes/s01-e03/images/train_background.svg",
      url: "/episodes/s01-e03/",
      load: async () => import(/* webpackChunkName: "s01e03" */ `../s01-e03`),
    },
    {
      id: "s01e04",
      title: {
        de: "Goblin Inc. zeigt sich",
        en: "Goblin Inc. shows itself",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Du kannst es nicht glauben: Ein Maulwurf in den eigenen Reihen? Während du damit beschäftigt bist, einige Brandherde zu löschen, droht bereits die nächste Katastrophe.",
        en: "You can't believe it: a mole in your own ranks? While you're busy putting out a few fires, the next disaster is already looming.",
      },
      imageUrl: "/episodes/s01-e03/images/train_background.svg",
      url: "/episodes/s01-e04/",
      load: async () => import(/* webpackChunkName: "s01e04" */ `../s01-e04`),
    },
    {
      id: "s01e05",
      title: {
        de: "Fehler mit Folgen",
        en: "Mistake with consequences",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Edward Yorks Vorfreude auf einen Produktionsbeginn des SunCopters trübt sein Urteilsvermögen. Mit einem Trick gelangt Goblin Inc. an vertrauliche Informationen.",
        en: "Edward York's anticipation of a SunCopter production launch clouds his judgment. Goblin Inc. uses a trick to obtain confidential information.",
      },
      imageUrl: "/episodes/s01-e05/images/office_york.svg",
      url: "/episodes/s01-e05/",
      load: async () => import(/* webpackChunkName: "s01e05" */ `../s01-e05`),
    },
    {
      id: "s01e06",
      title: {
        de: "Ja oder nein?",
        en: "Yes or no?",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Ein weiterer Hackingangriff zwingt Edward York in eine unangenehme Situation: Wird er der Geldforderung nachkommen, um wieder vollen Zugriff auf seine Dokumente zu erhalten?",
        en: "Another hacking attack forces Edward York into an awkward situation: will he comply with the money demand to regain full access to his documents?",
      },
      imageUrl: "/episodes/s01-e06/images/trainstation.svg",
      url: "/episodes/s01-e06/",
      load: async () => import(/* webpackChunkName: "s01e06" */ `../s01-e06`),
    },
    {
      id: "s01e07",
      title: {
        de: "Die Arbeit beginnt",
        en: "The work begins",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Nach anfänglichen Schwierigkeiten beginnt nun endlich die Arbeit an dem SunCopter. Doch plötzlich erscheint Goblin Inc. wieder auf der Bildfläche ...",
        en: "After initial difficulties, work on the SunCopter finally begins. But suddenly Goblin Inc. appears on the scene again ...",
      },
      imageUrl: "/episodes/s01-e07/images/secret_labor.svg",
      url: "/episodes/s01-e07/",
      load: async () => import(/* webpackChunkName: "s01e07" */ `../s01-e07`),
    },
    {
      id: "s01e08",
      title: {
        de: "Ausgeflogen",
        en: "Flown out",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Das Geschehen am weltweiten Wolfram-Markt trübt die Stimmung. Doch York hat einen Einfall. Der Massenstart des SunCopters rückt in greifbare Nähe.",
        en: "What's happening on the global tungsten market is clouding the mood. But York has an idea. The mass launch of the SunCopter is within reach.",
      },
      imageUrl: "/episodes/s01-e07/images/secret_labor.svg",
      url: "/episodes/s01-e08/",
      load: async () => import(/* webpackChunkName: "s01e08" */ `../s01-e08`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "1" })),
};

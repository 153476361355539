import { Observable } from "rxjs";
import { find } from "rxjs/operators";
import { removeItem, setItem } from "../core/storage";
import { Message } from "../message-hub/interfaces";
import { MESSAGES_STORAGE_KEY } from "../message-hub/message-hub";
import { SCRIPT_HASH, STEP_DONE } from "./messages";

export const lastStepKey = (episodeId: string) => `lastStep.${episodeId}`;
export const lastNamedStepKey = (episodeId: string) =>
  `lastStepNamed.${episodeId}`;
export const scriptHash = (episodeId: string) => `scriptHash.${episodeId}`;

export const saveMessages = (msgs: Message[]) => {
  setItem(MESSAGES_STORAGE_KEY, msgs);
};

export const saveLastStep = (messages: Observable<Message>) => {
  messages
    .pipe(find((msg) => msg.type == SCRIPT_HASH))
    .subscribe(
      (msg) => msg && setItem(scriptHash(msg.episode as string), msg.payload)
    );
  messages.subscribe((msg: Message) => {
    if (msg.type !== STEP_DONE) return;
    if (!/^node\.main\.\d+$/.test(msg.payload))
      setItem(lastNamedStepKey(msg.episode as string), msg.payload);
    setItem(lastStepKey(msg.episode as string), msg.payload);
  });
};

export const removeLastStep = (id) => {
  removeItem(lastStepKey(id));
  removeItem(lastNamedStepKey(id));
};

import { registerMessages } from "../../i18n";
import story from "./assets/a";
import tasks from "./assets/b";
import subjects from "./assets/c";
import design from "./assets/d";
import feelinga from "./assets/feeling-a";
import feelingb from "./assets/feeling-b";
import feelingc from "./assets/feeling-c";
import feelingd from "./assets/feeling-d";
export const scale = [
  { title: "Überhaupt nicht gut" },
  { title: "Nicht gut" },
  { title: "Geht so" },
  { title: "Gut" },
  { title: "Sehr gut" },
];

const scaleEn = ["Not good at all", "Not good", "Ok", "Good", "Very Good"];

export const areas = [
  {
    title: "Story",
    icon: story,
    topics: [
      "Kürzer",
      "Länger",
      "Handlung",
      "Charaktere",
      "Dramaturgie",
      "Weiteres",
    ],
  },
  {
    title: "Aufgaben",
    icon: tasks,
    topics: ["Mehr", "Weniger", "Schwerer", "Leichter", "Weiteres"],
  },
  {
    title: "Themen",
    icon: subjects,
    topics: ["Tiefer", "Breiter", "Spezifischer", "Weiteres"],
  },
  {
    title: "Design",
    icon: design,
    topics: ["Verspielter", "Reduzierter", "Funktionaler", "Weiteres"],
  },
];

const areasEn = [
  {
    title: "Story",
    topics: [
      "Shorter",
      "Longer",
      "Plot",
      "Characters",
      "Dramaturgy",
      "Additional",
    ],
  },
  {
    title: "Tasks",
    topics: ["Tasks", "Fewer", "Heavier", "Lighter", "Other"],
  },
  {
    title: "Subjects",
    topics: ["Deeper", "Wider", "More Specific", "Other"],
  },
  {
    title: "Design",
    topics: ["More playful", "Reduced", "More functional", "Other"],
  },
];

export const feelings = [
  { title: "Inspiriert", icon: feelinga },
  { title: "Unterhalten", icon: feelingb },
  { title: "Informiert", icon: feelingc },
  { title: "Motiviert", icon: feelingd },
];

const feelingsEn = ["Inspired", "Entertained", "Informed", "Motivated"];

const prefix = "levelsurvey";
export const msgKey = (part: string) => `${prefix}.${part}`;
export const TITLE_SATISFACTION = `${prefix}.t.Satisfaction`;
export const TITLE_APPROVED = `${prefix}.t.Approved`;
export const TITLE_REJECTED = `${prefix}.t.Rejected`;
export const TITLE_FEELING = `${prefix}.t.feeling`;
export const MULTIPLE = `${prefix}.multiplechoices`;
export const CONTINUE = `${prefix}.continue`;
export const TITLE_FINAL = `${prefix}.t.final`;
export const SUB_TITLE_FINAL = `${prefix}.st.final`;
export const TOPICS = `${prefix}.topics`;

const messagesDe = {
  [TITLE_SATISFACTION]: "Wie hat Dir die Episode gefallen?",
  [TITLE_APPROVED]: "Was hat Dir gefallen?",
  [TITLE_REJECTED]: "Was würdest du verbessern?",
  [TITLE_FEELING]: "Wie fühlst Du dich jetzt?",
  [TITLE_FINAL]: "Vielen Dank",
  [SUB_TITLE_FINAL]: "Wir belohnen Dich für deine Mühe.",
  [MULTIPLE]: "Mehrfachauswahl möglich",
  [CONTINUE]: "Weiter",
  [TOPICS]: 'Was genau bei "{area}"?',
  [`${prefix}.points`]: "Aktivitätspunkte",
} as Record<string, string>;
const messagesEn = {
  [TITLE_SATISFACTION]: "How did you like the episode?",
  [TITLE_APPROVED]: "What did you like?",
  [TITLE_REJECTED]: "What would you improve?",
  [TITLE_FEELING]: "How do you feel now?",
  [TITLE_FINAL]: "Many thanks",
  [SUB_TITLE_FINAL]: "We reward you for your effort.",
  [MULTIPLE]: "Multiple coices possible",
  [CONTINUE]: "Submit",
  [TOPICS]: 'What exactly about "{area}"?',
  [`${prefix}.points`]: "Activity points",
} as Record<string, string>;

for (const [ix, s] of scale.entries() as any) {
  const key = `${prefix}.${s.title}`;
  messagesDe[key] = s.title;
  messagesEn[key] = scaleEn[ix];
}

for (const [ix, s] of feelings.entries() as any) {
  const key = `${prefix}.${s.title}`;
  messagesDe[key] = s.title;
  messagesEn[key] = feelingsEn[ix];
}

for (const [ix, a] of areas.entries() as any) {
  const key = `${prefix}.${a.title}`;
  messagesDe[key] = a.title;
  messagesEn[key] = areasEn[ix].title;
  for (const [iy, t] of a.topics.entries() as any) {
    const key = `${prefix}.${t}`;
    messagesDe[key] = t;
    messagesEn[key] = areasEn[ix].topics[iy];
  }
}

registerMessages(messagesDe, "de");
registerMessages(messagesEn, "en");

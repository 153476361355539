import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import OpenAI from "openai";
import { openAiConnection } from "../services/open-ai-connection";
import { useGetOpenAiMessages } from "./use-get-open-ai-messages";

type PostOpenAiMessage = {
  content: string;
  threadId: string;
};

export const usePostOpenAiMessage = (
  options?: UseMutationOptions<unknown, unknown, PostOpenAiMessage>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ threadId, content }: PostOpenAiMessage) => {
      return await openAiConnection
        .getConnection()
        .beta.threads.messages.create(threadId, {
          role: "user",
          content,
        });
    },

    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: [useGetOpenAiMessages.queryKey, data.threadId],
      });

      const prevValues =
        queryClient.getQueryData<OpenAI.Beta.Threads.Messages.MessagesPage>([
          useGetOpenAiMessages.queryKey,
          data.threadId,
        ]);

      if (prevValues) {
        const newValues: OpenAI.Beta.Threads.Messages.MessagesPage = {
          ...prevValues,
          data: [
            {
              id: `temporary-${Math.random()}`,
              role: "user",
              content: [
                {
                  type: "text",
                  text: {
                    value: data.content,
                  },
                },
              ],
            },
            ...prevValues.data,
          ],
        } as OpenAI.Beta.Threads.Messages.MessagesPage;

        queryClient.setQueryData<OpenAI.Beta.Threads.Messages.MessagesPage>(
          [useGetOpenAiMessages.queryKey, data.threadId],
          newValues
        );
      }

      return { prevValues };
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [useGetOpenAiMessages.queryKey],
      });
    },
    ...options,
  });
};

usePostOpenAiMessage.queryKey = "usePostOpenAiMessage";

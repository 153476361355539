import { Pause, Replay } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { AppFooter as App } from "..";
import { useActors, useAppState } from "../../applications";
import { DirectMessages } from "../../direct-communication";
import { useAppState as useDirectCommunicationAppState } from "../../direct-communication/hooks";
import { FeedbackButton } from "../../feedback-button/feedback-button";
import { AppHeader } from "../../header";
import { State as HeaderState } from "../../header/state";
import { useDeviceDetect, useTourState } from "../../hooks";
import QuestRewinder from "../../quest-rewinder/quest-rewinder";
import { ReactComponent as gameHome } from "../assets/game_home_button.svg";
import { excludedLocations } from "../excluded-locations";
import { usePulseRewindIcon } from "../hooks";
import { State as FooterState } from "../state";
import { StylesProps, useStyles } from "./footer.styles";
import { RewinderIcon } from "./rewinderIcon";

type AppFooterProps = {
  onExit: () => void;
  onRestart: () => void;
  onRewind: (rewindId: string) => void;
};
const AppFooter: React.FC<AppFooterProps> = (props: AppFooterProps) => {
  const {
    mode,
    open,
    autostart,
    actor: communicationActor,
  } = useDirectCommunicationAppState();
  const classes = useStyles({
    mode,
  } as StylesProps);
  const device = useDeviceDetect();
  const history = useHistory();
  const actors = useActors();
  const location = useLocation();
  const [homeButtonVisible, setHomeButtonVisible] = useState<boolean>(true);
  const [exitDialog, setExitDialog] = useState(false);
  const [rewinderOpen, setRewinderOpen] = useState(false);
  const tourState = useTourState();
  const { completedTests } = useAppState<HeaderState>(AppHeader.instance);
  const { rewinderClicked } = useAppState<FooterState>(App.instance);
  const pulseRewind = usePulseRewindIcon();

  useEffect(() => {
    let showHomeButton = true;

    excludedLocations.forEach((loc) => {
      if (typeof loc === "string") {
        if (location.pathname === loc) {
          showHomeButton = false;
        }
      } else {
        if (loc.test(location.pathname)) {
          showHomeButton = false;
        }
      }
    });

    setHomeButtonVisible(showHomeButton);
  }, [location]);

  useEffect(() => {
    if (completedTests.length && !rewinderClicked) {
      App.instance.startPulseRewindIcon();
    }
  }, [completedTests]);

  const [notificationRepeatDelay, setNotificationRepeatDelay] = useState(5000);

  const openDirectCommunication = () => {
    if (mode !== "idle" && !open && !tourState?.run) {
      setNotificationRepeatDelay(5000);
      DirectMessages.instance.open();
    }
  };

  const onRewinderOpen = () => {
    setRewinderOpen(true);
    DirectMessages.instance.close();
    App.instance.stopPulseRewindIcon();
  };

  const onNotificationAnimationEnd = ({ animationName }) => {
    if (!notificationRef.current) return;
    notificationRef.current.classList.remove("animate__bounce");
    if (notificationRepeatDelay > 1) {
      setNotificationRepeatDelay((d) => d * 0.9);
    }
    setTimeout(() => {
      notificationRef.current?.classList.add("animate__bounce");
    }, notificationRepeatDelay);
  };

  useEffect(() => {
    if (autostart) {
      openDirectCommunication();
    }
  }, [autostart]);

  const notificationRef = useRef<HTMLDivElement | null>(null);

  const getNotificationButton = () => {
    const actor = actors[communicationActor || ""];

    const url = tourState?.run
      ? "/actors/tina.svg"
      : actor
      ? actor.avatarUrl
      : "";

    const getClasses = () => {
      let cls = `${classes.notification}`;

      if (tourState?.run) {
        return `${cls} ${classes.notificationTour}`;
      }

      if (open) {
        return cls;
      }

      if (mode === "active" || mode === "end") {
        cls = `${cls} ${classes.notificationActive} animate__animated animate__bounce`;
      }

      if (mode === "finish") {
        cls = `${cls} ${classes.notificationCompleted}`;
      }

      return cls;
    };

    return (
      <div
        ref={notificationRef}
        className={getClasses()}
        onClick={openDirectCommunication}
        id="game-notice"
        data-e2e-direct-communication-notification={mode}
        onAnimationEnd={onNotificationAnimationEnd}
      >
        <img className={classes.notificationAvatar} src={url} />
      </div>
    );
  };

  const getStyles = () => {
    if (tourState?.run) {
      return {
        margin: `0 68px 0 ${device.browser ? "auto" : "0"}`,
      };
    }

    if (mode === "idle") {
      return {
        margin: "0 10px 0 auto",
      };
    }

    return {
      margin: `0 68px 0 ${device.browser || !homeButtonVisible ? "auto" : "0"}`,
    };
  };

  const exitConfirmDialog = (
    <Dialog
      sx={{ zIndex: 1500 }}
      open={exitDialog}
      onClose={() => setExitDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="global.exit.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="global.exit.description" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setExitDialog(false)} autoFocus>
          <FormattedMessage id="global.exit.continue" />
        </Button>
        <Button onClick={props.onRestart}>
          <FormattedMessage id="global.exit.restart" />
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <QuestRewinder
        onRewind={props.onRewind}
        open={rewinderOpen}
        onClose={() => setRewinderOpen(false)}
      />
      {exitConfirmDialog}
      <div className={classes.root} data-e2e-app-footer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            id="game-episode-leave"
            aria-label="game-replay"
            className={classes.button}
            onClick={props.onExit}
            data-e2e-exit-game-button
            size="large"
          >
            <Pause />
          </IconButton>
          <IconButton
            aria-label="game-exit"
            className={classes.button}
            onClick={() => setExitDialog(true)}
            size="large"
          >
            <Replay />
          </IconButton>
          <FeedbackButton
            fallbackContactStyles={{
              position: "absolute",
              bottom: 35,
              left: 15,
            }}
            styles={{ marginLeft: 10 }}
            iconButton={device.mobile}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {device.mobile && (homeButtonVisible || tourState?.run) && (
            <IconButton
              id="game-home-button"
              data-e2e-home-button
              aria-label="game-home"
              className={`${classes.button}`}
              style={{ margin: "0 10px 0 0" }}
              onClick={() => (tourState?.run ? null : history.push("/"))}
              size="large"
            >
              <SvgIcon component={gameHome} />
            </IconButton>
          )}
          <IconButton
            id="game-rewind-quest"
            aria-label="game-rewind-quest"
            className={`${classes.button} ${
              rewinderOpen ? classes.rewindButtonOpen : classes.rewindButton
            } ${
              !rewinderClicked && pulseRewind
                ? `${classes.newQuest} animate__animated animate__heartBeat`
                : ""
            }`}
            style={getStyles()}
            onClick={() => !rewinderOpen && onRewinderOpen()}
            size="large"
          >
            <RewinderIcon />
          </IconButton>
          {(mode !== "idle" || tourState?.run) && getNotificationButton()}
        </div>
      </div>
    </>
  );
};

export default AppFooter;

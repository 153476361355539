import { flatten } from "lodash";
import { http } from "../../core/client";
import { keycloak } from "../../core/keycloak";
import { DepartmentGroup, SelectedItem, Tag, UserProfile } from "./interfaces";
import { tagTranslates } from "./metadata";

const loadUserInfo = async () => {
  try {
    return (await keycloak.loadUserInfo()) as any;
  } catch (e) {
    // do nothing
  }
};

export const getLanguage = () => {
  let locale = "en";

  const userInfo = loadUserInfo() as any;

  if (userInfo && userInfo.locale) {
    locale = userInfo.locale;
  } else {
    const lang = navigator.language.toLowerCase();
    locale = lang.match(/^de-?/gi) ? "de" : "en";
  }

  return locale;
};

const fetchData = async (url: string): Promise<any> => {
  try {
    const res = await http.get(url);
    return await res.json();
  } catch (e) {
    console.error(e);
    return { success: false };
  }
};

const getData = async (urls: Array<string>) => {
  const requestPromises = urls.map((URL: string) => fetchData(URL));
  return await Promise.all(requestPromises);
};

export const getProfileData = async (): Promise<UserProfile> =>
  fetchData("talent");

export const getProfileTagsTypes = async (): Promise<string[]> =>
  fetchData(`tags/types`);

export const getProfileTagsByType = async (tag): Promise<Tag[]> =>
  fetchData(`tags/by-type/${tag}`);

export const getAllProfileTags = async (): Promise<Tag[]> => {
  const profileTags = await getProfileTagsTypes();
  return getData(profileTags.map((tag) => `tags/by-type/${tag}`)).then(flatten);
};

export const getParentGroups = async (): Promise<DepartmentGroup[]> =>
  fetchData("talent-groups/by-parent");

export const getGroupsByParentIds = async (
  parentIds: number[]
): Promise<DepartmentGroup[]> =>
  getData(
    parentIds.map((parentId) => `talent-groups/by-parent/${parentId}`)
  ).then(flatten);

export const getGroups = async (parentId: number): Promise<DepartmentGroup[]> =>
  fetchData(`talent-groups/by-parent/${parentId}`);

export const putTags = async (body: SelectedItem[]) => {
  if (body.length > 0) {
    return http.put("talent/tags", { json: body });
  }
};

export const updateDepartment = async (departmentId: number) =>
  http.put("talent/update-group", { json: { value: departmentId } });

export const getTranslate = (key: string) => tagTranslates[key] || key;

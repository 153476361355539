import { PassengerInfo, Ticket } from "./interfaces";

export const TRAVEL_BOT_TICKET_SELECT = "travelbot.ticket.select";
export const TRAVEL_BOT_START = "travelbot.start";
export const TRAVEL_BOT_UPDATE_PASSENGER_INFO =
  "travelbot.update.passenger.info";
export const TRAVEL_BOT_TICKET_COPY = "travelbot.ticket.copy";

export interface TravelBotTicketSelect {
  type: typeof TRAVEL_BOT_TICKET_SELECT;
  payload: Ticket;
}

export interface TravelBotUpdatePassengerInfo {
  type: typeof TRAVEL_BOT_UPDATE_PASSENGER_INFO;
  payload: PassengerInfo;
}

export interface TravelBotTicketCopy {
  type: typeof TRAVEL_BOT_TICKET_COPY;
  payload: boolean;
}

export interface TravelBotStart {
  type: typeof TRAVEL_BOT_START;
  payload: true;
}

export type TravelBotMessages =
  | TravelBotTicketSelect
  | TravelBotUpdatePassengerInfo
  | TravelBotTicketCopy
  | TravelBotStart;

import { Badge, ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { APP_OPEN } from "core/messages";
import MessageHub from "message-hub";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Application } from "../interfaces";

const useStyles = makeStyles(() => ({
  iconWrapper: {
    position: "relative",
    textAlign: "center",
    width: 90,
    height: 100,
    borderRadius: 20,
    border: "3px solid #FFFFFF",
    background: ({ color }: any) => (color ? color : "gray"),
    backgroundRepeat: "no-repeat",
    backgroundSize: "60%",
    backgroundPosition: "center 32%",
    boxShadow: "0px 3px 6px #00000029",

    "& label": {
      color: "white",
      textTransform: "uppercase",
      fontWeight: "bold",
      textShadow:
        "-1px -1px 4px rgba(0,0,0,0.75), 1px 1px 4px rgba(0,0,0,0.75)",
      fontSize: "0.6rem",
      position: "absolute",
      right: 0,
      left: 0,
      bottom: 10,
    },
  },
  icon: {
    width: "100%",
    height: "70%",
    backgroundImage: ({ id, icon }: any) => `url(${icon || `/apps/${id}.svg`})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "60%",
    backgroundPosition: "center 50%",
  },
}));

interface AppIconProps {
  application: Pick<
    Application,
    "name" | "notification" | "path" | "color" | "id"
  > & {
    externalUrl?: string;
  };
}

const WrapLink = (props: PropsWithChildren<{ link: string; app: string }>) => {
  if (props.link.startsWith("http")) {
    return (
      <a href={props.link} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }
  return (
    <Link
      to={props.link}
      style={{ textDecoration: "none", color: "black" }}
      onClick={() => {
        MessageHub.send({
          type: APP_OPEN,
          payload: {
            appId: props.app,
          },
        });
      }}
    >
      {props.children}
    </Link>
  );
};

const AppIcon: React.FC<AppIconProps> = (props: AppIconProps) => {
  const { application } = props;
  const { path, name, notification, externalUrl, id } = application;
  const classes = useStyles(application);

  return (
    <ButtonBase
      focusRipple
      data-e2e-app-switcher-button={name.replace(/\s/g, "").toLowerCase()}
      style={{
        padding: "1em",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <WrapLink link={externalUrl || path} app={id}>
        <Badge
          id={"app" + name.replace(/\s/g, "") + "Button"}
          badgeContent={notification}
          color="secondary"
        >
          <div className={classes.iconWrapper}>
            <div className={classes.icon}></div>
            <label>{name}</label>
          </div>
        </Badge>
      </WrapLink>
    </ButtonBase>
  );
};

export default AppIcon;

import { CardMedia, Grid, LinearProgress, Typography } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { ReactChild, ReactChildren } from "react";
import LockedBanner from "./locked-banner";

const useStyles = makeStyles((theme) => ({
  mediaContent: {
    height: "100%",
    background: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const CardLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: "0px 0px 5px 5px",
      marginBottom: theme.spacing(2),
    },
    bar: {
      borderRadius: "0px 0px 5px 5px",
      backgroundColor: "#FEC330",
    },
  })
)(LinearProgress);

const CardMediaWithTitle = ({
  image,
  title,
  children,
  height = 100,
}: {
  image: string;
  title: string;
  children?: ReactChild | ReactChildren;
  height?: number;
}) => {
  const classes = useStyles();
  return (
    <CardMedia image={image} style={{ height, position: "relative" }}>
      <Grid container className={classes.mediaContent} alignContent="flex-end">
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {children}
    </CardMedia>
  );
};

const ProgressBar = ({ progress }: { progress: number }) => (
  <CardLinearProgress variant="determinate" value={progress} />
);

CardMediaWithTitle.WithProgress = ProgressBar;
CardMediaWithTitle.WithBanner = LockedBanner;

export default CardMediaWithTitle;

import { useEffect, useState } from "react";
import { DirectMessages } from ".";
import GameEngine from "../../game-engine";
import { useAppState as useAppStateGlobal } from "../applications";
import { DirectCommunicationMessage } from "./interfaces/direct-communication-message";
import { State } from "./interfaces/state";

const replacePlaceholders = (messages: State["messages"]) =>
  messages.map((m) => ({
    ...m,
    message: GameEngine().replacePlaceholders(m.message) || m.message,
  }));

export const useAppState = () =>
  useAppStateGlobal<State>(DirectMessages.instance);

export function useMessages(): DirectCommunicationMessage[] {
  const state = useAppState();
  const [messages, setMessages] = useState<DirectCommunicationMessage[]>(
    replacePlaceholders(state.messages)
  );
  useEffect(() => {
    setMessages(replacePlaceholders(state.messages));
  }, [state.messages]);
  return messages;
}

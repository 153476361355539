const appModules = {
  chat: () => import("./chat"),
  search: () => import("./search"),
  mailbox: () => import("./mailbox"),
  appStore: () => import("./app-store"),
  videoCall: () => import("./video-call"),
  calendar: () => import("./calendar"),
  socialnetwork: () => import("./socialnetwork"),
  cloudapparticles: () => import("./cloud-app-articles"),
  cloudapp: () => import("./cloud-app"),
  travelbot: () => import("./travel-bot"),
  programmingGame: () => import("./programming-game"),
  alerts: () => import("./alerts"),
  storageapp: () => import("./storage-app"),
  digidoc: () => import("./digidoc"),
  goblinnews: () => import("./goblin-news"),
  itttapp: () => import("./ittt-app"),
  surfpatrol: () => import("./surf-patrol"),
  spamfighter: () => import("./spam-fighter"),
  speedcheck: () => import("./speed-check"),
  quiztime: () => import("./quiz-time"),
  quiztaxi: () => import("./quiz-taxi"),
  breakingnews: () => import("./breaking-news"),
  tinatweets: () => import("./tina-tweets"),
  mailAutomationTool: () => import("./mail-automation-tool"),
  digitalzen: () => import("./digital-zen"),
  chatbot: () => import("./chat-bot"),
  meetingsTool: () => import("./meetings-tool"),
  kanbanApp: () => import("./kanban-app"),
  meetingsToolBrowser: () => import("./meetings-tool-browser"),
  realtimeBoard: () => import("./realtime-board"),
  playerSetup: () => import("./player-setup"),
  gezwitscher: () => import("./gezwitscher"),
  documentEditor: () => import("./document-editor"),
  solutions: () => import("./solutions"),
  customers: () => import("./customers"),
  contentConfirm: () => import("./content-confirm"),
  imageGapFilling: () => import("./image-gap-filling"),
  clickReveal: () => import("./click-reveal"),
  videoCallOpenai: () => import("./video-call-openai"),
};
export default appModules;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { useCompetence } from "../../competences";
import DashboardTooltip from "./tooltip";

const useStyles = makeStyles({
  infoSectionIcon: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid white",
    userSelect: "none",
    "& svg": {
      width: "auto !important",
      height: "50%",
      color: "white",
    },
  },
});

const CompetenceIcon = ({
  competenceAreaId,
  index,
}: {
  competenceAreaId: number;
  index?: number;
}) => {
  const classes = useStyles();
  const c = useCompetence(competenceAreaId, index);

  if (!c) return null;

  const { color, icon, name } = c;

  if (!color) return null;

  return (
    <DashboardTooltip title={name}>
      <div
        className={classes.infoSectionIcon}
        style={{
          backgroundColor: color,
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    </DashboardTooltip>
  );
};

export default CompetenceIcon;

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={56}
      height={60}
      viewBox="0 0 56 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 58.667a4.667 4.667 0 100-9.333 4.667 4.667 0 000 9.333zM50.4 31.067C52.267 28.8 53.334 26 53.334 22.8c0-7.2-6-13.067-13.334-13.067a13.15 13.15 0 00-3.2.4c-1.733-5.2-6.666-8.8-12.533-8.8-7.067 0-12.8 5.467-13.2 12.267-5.6 1.334-9.733 6.267-9.733 12.133 0 5.2 3.2 9.6 7.6 11.467-.267.667-.4 1.467-.4 2.267 0 4 3.2 7.2 7.2 7.2 2.666 0 4.933-1.334 6.266-3.467 2 1.467 4.4 2.267 7.067 2.267 3.333 0 6.267-1.334 8.533-3.467M8.934 37.333L10.8 36M22 43.2l-.133-2.133M11.067 13.6l2.133 1.333M36.8 10.133l-1.466 2.134"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M46.667 30.667L49.334 36l5.333 2.667-5.333 2.667-2.667 5.333L44 41.334l-5.333-2.667L44 36l2.667-5.333zM37.334 16L40 21.333 45.334 24 40 26.667 37.334 32l-2.667-5.333L29.333 24l5.334-2.667L37.333 16z"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M29.334 32v2.667M29.334 37.334V40M28 36h-2.666M33.334 36h-2.667"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgComponent;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moon from "./assets/moon.svg";
import sun from "./assets/sun.svg";

const styles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.custom.zIndex.timePassing,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  day: {
    width: "100%",
    height: "100%",
    background: `linear-gradient(to left, #B8E0EB, #2C6EA1)`,
    backgroundSize: "800% 800%",
    display: "flex",
    justifyContent: "center",
  },
  night: {
    width: "100%",
    height: "100%",
    background: `linear-gradient(to left, #2C6EA1, #B8E0EB)`,
    backgroundSize: "1000% 1000%",
    display: "flex",
    justifyContent: "center",
  },
  sun: {
    marginTop: "4%",
    width: "40%",
    height: "25%",
    background: `url(${sun})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: landscape)": {
        marginTop: "10%",
      },
      "@media (orientation: portrait)": {
        marginTop: "12%",
      },
    },
  },
  moon: {
    marginTop: "4%",
    width: "40%",
    height: "25%",
    background: `url(${moon})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: landscape)": {
        marginTop: "10%",
      },
      "@media (orientation: portrait)": {
        marginTop: "12%",
      },
    },
  },
  content: {
    position: "absolute",
    bottom: 40,
    left: 0,
    right: 0,
    height: "calc(65% - 40px)",
    textAlign: "center",
    display: "block",
    [theme.breakpoints.down("md")]: {
      "@media (orientation: landscape)": {
        height: "calc(50% - 40px)",
      },
      "@media (orientation: portrait)": {
        height: "calc(60% - 40px)",
      },
    },
  },
  text: {
    fontSize: "xxx-large",
    color: "white",
    height: "20%",
  },
  star: {
    position: "absolute",

    "&.star-1": {
      top: "11%",
      left: "22%",
      transform: "scale(0.35)",
    },
    "&.star-2": {
      top: "34%",
      left: "18%",
      transform: "scale(1.5)",
    },
    "&.star-3": {
      top: "34%",
      left: "80%",
      transform: "scale(0.6)",
    },
    "&.star-4": {
      top: "9%",
      left: "76%",
      transform: "scale(0.35)",
    },
    "&.star-5": {
      top: "75%",
      left: "26%",
      transform: "scale(1.2)",
    },
    "&.star-6": {
      top: "78%",
      left: "60%",
      transform: "scale(1.8)",
    },
    "&.star-7": {
      top: "68%",
      left: "81%",
      transform: "scale(0.45)",
    },
  },
  earthContainer: {
    height: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  earth: {
    width: "60%",
    height: "65%",
    zIndex: 2,
    position: "relative",
  },
  cloud: {
    position: "absolute",

    "&.cloud-1": {
      top: "22%",
      left: "11%",
      zIndex: 3,
      transform: "scale(0.6)",
    },
    "&.cloud-2": {
      top: "2%",
      left: "59%",
      zIndex: 1,
      transform: "scale(0.6)",
    },
    "&.cloud-3": {
      top: "4%",
      left: "12%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 100,
      backgroundColor: "white",
      borderRadius: 20,
    },
    "&.cloud-4": {
      top: "16%",
      left: "4%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 100,
      backgroundColor: "white",
      borderRadius: 20,
    },
    "&.cloud-5": {
      top: "-3%",
      left: "56%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 34,
      backgroundColor: "white",
      borderRadius: 20,
    },
    "&.cloud-6": {
      top: "22%",
      left: "68%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 68,
      backgroundColor: "white",
      borderRadius: 20,
    },
    "&.cloud-7": {
      top: "25%",
      left: "74%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 34,
      backgroundColor: "white",
      borderRadius: 20,
    },
    "&.cloud-8": {
      top: "29%",
      left: "36%",
      zIndex: 1,
      transform: "scale(0.6)",
      height: 18,
      width: 96,
      backgroundColor: "white",
      borderRadius: 20,
    },
  },
}));

export default styles;

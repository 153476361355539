import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import gameEngine from "../../../game-engine";
import { DashboardContext, DashboardContextProps } from "../dashboard.context";
import styles from "./player-progress-bar.styles";
import DashboardTooltip from "./tooltip";

export type PlayerProgressBarRules = Array<{
  nameId: string;
  ruleFunct: (context: DashboardContextProps) => boolean;
  path?: string;
}>;

interface Point {
  nameId: string;
  passed: boolean;
  path?: string;
}

type PropsType = {
  rules: PlayerProgressBarRules;
};

export const PlayerProgressBar = (props: PropsType) => {
  const { rules } = props;
  const classes = styles();
  const context = useContext(DashboardContext);
  const [progress, setProgress] = useState(0);
  const [points, setPoints] = useState<Point[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (rules && rules.length) {
      const points: Point[] = [];
      let total = 0;
      let passed = 0;

      rules.forEach((rule) => {
        const result = rule.ruleFunct(context);
        total++;
        if (result) passed++;
        points.push({
          nameId: rule.nameId,
          passed: result,
          path: rule.path,
        });
      });

      setProgress(Math.round((100 * passed) / total));
      setPoints(points);
    }
  }, [context, rules]);

  useEffect(() => {
    if (progress === 100) {
      gameEngine().badgesEngine.addBadgeAndWaitForAck("profile-fill");
    }
  }, [progress, context]);

  const click = (point: Point) => {
    if (!point.passed && point.path?.length) {
      history.push(point.path);
    }
  };

  return (
    <DashboardTooltip
      placement="bottom"
      title={
        <List
          subheader={
            <ListSubheader className={classes.title}>
              <FormattedMessage id="dashboard.player-progress.list-title" />
            </ListSubheader>
          }
        >
          {points.map((p, i) => (
            <ListItem
              key={i}
              className={!p.passed ? classes.listItemActive : ""}
              onClick={() => click(p)}
            >
              <ListItemIcon className={classes.checkCircle}>
                {p.passed ? <CheckCircle /> : <RadioButtonUnchecked />}
              </ListItemIcon>
              <ListItemText
                className={
                  !p.passed ? classes.listItemActiveText : classes.listItem
                }
              >
                <FormattedMessage id={p.nameId} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      }
    >
      <div className={classes.root}>
        <div className={classes.progress}>
          <div className={classes.bar} style={{ width: `${progress}%` }}></div>
        </div>
        <p className={classes.text}>
          <FormattedMessage
            id="dashboard.menu.progress.bar.value"
            values={{ value: progress }}
          />
        </p>
      </div>
    </DashboardTooltip>
  );
};

import { GuidedCommunication } from "core/guided-communication";
import { Observable } from "rxjs";
import appModules from "../apps/app-modules";
import { AppLauncher } from "../core";
import { ofTypes } from "../core/applications";
import CoffeeGameApp from "../core/coffee-game";
import DecisionApp from "../core/decision";
import { DirectMessages } from "../core/direct-communication";
import { AppFooter } from "../core/footer";
import { AppHeader } from "../core/header";
import { APP_ADD, APP_ADD_SUCCESSFUL } from "../core/messages";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";

export const createApplications = (messages: Observable<Message>) => {
  const loadedApps = new Set();
  new AppLauncher(messages);
  new AppHeader(messages);
  new AppFooter(messages);
  new DirectMessages(messages);
  new DecisionApp(messages);
  new CoffeeGameApp(messages);
  new GuidedCommunication(messages);

  messages.pipe(ofTypes(APP_ADD)).subscribe({
    next: ({ payload: { name } }: Message) => {
      const loadAppModule = appModules[name];
      if (
        typeof loadAppModule === "function" &&
        !loadedApps.has(loadAppModule)
      ) {
        loadedApps.add(loadAppModule);
        loadAppModule().then(({ default: App }) => {
          new App(messages);

          MessageHub.send({
            type: APP_ADD_SUCCESSFUL,
            payload: { name },
            save: true,
          });
        });
      }
    },
  });
};

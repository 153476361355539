import loadable from "@loadable/component";
import { ThemeProvider } from "@mui/material/styles";
import { BotChat } from "core/bot-chat";
import { getCompetencesTree } from "core/client";
import { CompetenceAreaTree } from "core/interfaces";
import { uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Episode } from "../../game-engine/interfaces";
import {
  getAllProfileTags,
  getProfileData,
} from "../../onboarding/player/services";
import { FeedbackButton } from "../feedback-button/feedback-button";
import { useDeviceDetect } from "../hooks";
import { keycloak, userHasRole } from "../keycloak";
import { getItem } from "../storage";
import headerIcon from "./assets/header-icon.svg";
import DashboardMenu from "./components/menu";
import QuizTakeawaysPopup from "./components/quiz-takeaways-pop-up";
import { DashboardContext } from "./dashboard.context";
import styles from "./dashboard.styles";
import { pages } from "./pages";
import DashboardHome from "./pages/home/home";
import { COLLEAGUE_INVITE_LINK_ACTIVATED_STORAGE_KEY } from "./pages/invitation/invitation";
import theme from "./theme";

const Debugger = loadable(() => import("../debugger/debugger"));

type DashboardProps = {
  onPlay: (e: Episode) => void;
  lastEpisode?: Episode;
};

function getNavigationConfig(
  roles: ReturnType<typeof getRoles>
): Record<string, boolean> {
  if (roles.demo) {
    return {
      adminLink: false,
      companyLink: true,
    };
  }

  return {
    adminLink: roles.companyAdmin,
    companyLink: roles.companyReport,
  };
}

function getRoles() {
  return {
    demo: getItem("isDemoTenant"),
    companyReport: userHasRole("talent_company_report"),
    companyAdmin: userHasRole("talent_admin"),
  };
}

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const navConfig = useRef<Record<string, boolean>>();
  const roles = useRef<ReturnType<typeof getRoles>>();
  const [menuOpened, setMenuOpened] = useState<boolean | undefined>();
  const [headerInfo, setHeaderInfo] = useState({ title: "no-title", icon: "" });
  const location = useLocation();
  const device = useDeviceDetect();
  const classes = styles();
  const [filledTags, setFilledTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState(0);
  const [departmentsFilled, setDepartmentsFilled] = useState<boolean>(false);
  const [playerSettingsFilled, setPlayerSettingsFilled] =
    useState<boolean>(false);
  const [colleagueInviteLinkActivated, setColleagueInviteLinkActivated] =
    useState<boolean>(false);
  const [dashboardTourFinished, setDashboardTourFinished] =
    useState<boolean>(false);
  const [competenceAreaTree, setCompetenceAreaTree] = useState<
    CompetenceAreaTree[]
  >([]);

  if (roles.current === undefined) {
    roles.current = getRoles();
  }

  if (navConfig.current === undefined) {
    navConfig.current = getNavigationConfig(roles.current);
  }

  useEffect(() => {
    Promise.all([getProfileData(), getAllProfileTags()]).then(
      ([data, tags]) => {
        setAvailableTags(uniqBy(tags, (t) => t.type).length);
        setFilledTags(data.tagItems.map((x) => x.type));

        if (data.talentGroup) {
          setDepartmentsFilled(true);
        }
      }
    );

    getCompetencesTree().then(setCompetenceAreaTree);

    const pSettings = getItem("SETTINGS");

    if (pSettings.playerEmailAddress?.length) {
      setPlayerSettingsFilled(true);
    }

    if (getItem(COLLEAGUE_INVITE_LINK_ACTIVATED_STORAGE_KEY)) {
      setColleagueInviteLinkActivated(true);
    }
  }, []);

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [contentRef.current, location.pathname]);

  useEffect(() => {
    setMenuOpened(false);
    for (const p of Object.values(pages)) {
      let matches = false;
      if (p.path.length === 1) {
        matches = p.path === location.pathname;
      } else {
        matches = location.pathname.startsWith(p.path);
      }
      if (matches) {
        setHeaderInfo({
          title: p.titleId,
          icon: p.iconWhite || "",
        });
        return;
      }
    }
  }, [location.pathname]);

  let classList = "";
  if (device.mobile && typeof menuOpened === "boolean") {
    classList += menuOpened
      ? classes.pageContentOpened
      : classes.pageContentClosed;
  }
  classList = `${classes.pageContent} ${classList}`;

  return (
    <ThemeProvider theme={theme}>
      <DashboardContext.Provider
        value={{
          filledTags,
          setFilledTags: (data) => setFilledTags(data),
          availableTags,
          departmentsFilled,
          setDepartmentsFilled: (data) => setDepartmentsFilled(data),
          playerSettingsFilled,
          setPlayerSettingsFilled: (data) => setPlayerSettingsFilled(data),
          colleagueInviteLinkActivated,
          setColleagueInviteLinkActivated: (data) =>
            setColleagueInviteLinkActivated(data),
          dashboardTourFinished,
          setDashboardTourFinished: (data) => setDashboardTourFinished(data),
          competenceAreaTree,
          setCompetenceAreaTree: (data) => setCompetenceAreaTree(data),
        }}
      >
        <div className={classes.root} data-e2e-dashboard>
          <DashboardMenu
            adminLink={navConfig.current.adminLink}
            companyLink={navConfig.current.companyLink}
            menuOpened={menuOpened}
            pages={pages}
            logout={() => keycloak.logout()}
          />
          <div
            className={classList}
            onClick={() => menuOpened && setMenuOpened(false)}
          >
            {device.mobile && (
              <div className={classes.mobileContentHeader}>
                <img
                  src={headerIcon}
                  onClick={() => setMenuOpened(true)}
                  data-e2e-dashboard-open-menu-button
                />
                <div className={classes.mobileContentHeaderTitle}>
                  {headerInfo.icon && <img src={headerInfo.icon} />}
                  <FormattedMessage id={headerInfo.title} />
                </div>
              </div>
            )}
            <div className={classes.content}>
              <div
                ref={contentRef}
                style={{
                  pointerEvents: menuOpened ? "none" : "all",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <Switch>
                  <Route
                    exact
                    path={[pages.HOME.path, `${pages.HOME.path}/:seasonId`]}
                  >
                    <DashboardHome
                      onPlay={props.onPlay}
                      lastEpisode={props.lastEpisode}
                      contentRef={contentRef}
                    />
                  </Route>
                  <Route
                    path={pages.PROFILE.path}
                    component={pages.PROFILE.component}
                  />
                  <Route
                    path={pages.NEWS.path}
                    component={pages.NEWS.component}
                  />
                  <Route
                    exact
                    path={pages.REPORT.path}
                    component={pages.REPORT.component}
                  />
                  <Route
                    path={pages.COMPANY.path}
                    component={pages.COMPANY.component}
                  />
                  <Route
                    exact
                    path={pages.ADMIN.path}
                    component={pages.ADMIN.component}
                  />
                  <Route
                    exact
                    path={pages.INVITATION.path}
                    component={pages.INVITATION.component}
                  />
                  <Route path="/quiz/takeaways/:id">
                    <DashboardHome
                      onPlay={props.onPlay}
                      lastEpisode={props.lastEpisode}
                    />
                    <QuizTakeawaysPopup />
                  </Route>
                  <Route path="/debugger">
                    <div
                      style={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        position: "absolute",
                      }}
                    >
                      <Debugger />
                    </div>
                  </Route>
                  <Redirect to={pages.HOME.path} />
                </Switch>
              </div>
            </div>
            <FeedbackButton
              styles={{ position: "absolute", bottom: 0, right: 10 }}
              fallbackContactStyles={{
                position: "absolute",
                bottom: 15,
                right: 15,
              }}
            />
          </div>
        </div>
        <BotChat />
      </DashboardContext.Provider>
    </ThemeProvider>
  );
};

export default Dashboard;

import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GameEngine from "../../../game-engine";
import { loadQuizExplanations } from "../../../game-engine/episodes-loader";
import { useDeviceDetect, useHref } from "../../hooks";

type QuizTakeAway = {
  heading: string;
  content: string;
};

const styles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: "75%",
    minHeight: "80%",
    backgroundColor: "rgba(17, 110, 107, 0.93)",
    ["@supports (backdrop-filter: blur(30px))"]: {
      backgroundColor: "rgba(17, 110, 107, 0.6)",
      backdropFilter: "blur(10px)",
    },
    borderRadius: 20,
    color: "#fff",
    marginTop: 60,
    paddingBottom: 20,
    border: "0.5px solid white",
    "& h5": {
      color: "inherit",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& a": {
      color: "#f08224",
      "&:hover": {
        color: "#f08224",
      },
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      borderRadius: 0,
      border: 0,
      height: "100%",
      paddingBottom: 10,
    },
  },
  content: {
    padding: theme.spacing(1, 7, 3),
    overflowX: "hidden",
    marginRight: 3,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 3, 2),
    },
  },
  articleImg: {
    width: "100%",
    height: "100%",
  },
  btn: {
    minWidth: 48,
  },
  closeBtn: {
    color: "#fff",
  },
  articleDescription: {
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  articleBody: {
    fontSize: "1rem",
    fontWeight: 400,
    "& > p > a > img": {
      maxHeight: 80,
      userSelect: "none",
      display: "flex",
      margin: "0 auto 30px",
      width: "auto",
      maxWidth: "100%",
    },
    "& .flex-row": {
      display: "flex",
      flexDirection: "row",

      "& div": {
        "& img": {
          maxHeight: 100,
          userSelect: "none",
          display: "flex",
          margin: "0 auto 10px",
          width: "auto",
          maxWidth: "100%",
        },
      },
    },
    "& .flex-row > *": {
      margin: theme.spacing(1),
      userSelect: "none",
    },
    "& .flex-row > div": {
      width: "20%",
      textAlign: "center",
    },
    "& .flex-row > p": {
      width: "80%",
      userSelect: "none",
      fontSize: "0.9em",
    },
    "& .fake-row": {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      flexWrap: "wrap",
    },
    "& .fake": {
      width: "40%",
      marginBottom: theme.spacing(0.5),
      "@media screen and (min-width: 1024px)": {
        width: "30%",
      },
    },
  },
  paperActions: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  open: { height: "auto" },
  close: { height: 0 },
  toggleIcon: {
    marginRight: 8,
    fontSize: "1rem",
  },
  toggleIconOpen: {
    transform: "rotate(90deg)",
  },
}));

type QuizTakeawaysPopupItemProps = {
  heading: string;
  content: string;
};

const QuizTakeawaysPopupItem = ({
  heading,
  content,
}: QuizTakeawaysPopupItemProps) => {
  const classes = styles();
  const divRef = useHref("advice");
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Typography
        style={{ cursor: "pointer" }}
        variant="h5"
        color="primary"
        gutterBottom
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon
          className={`${classes.toggleIcon} ${
            open ? classes.toggleIconOpen : ""
          }`}
          icon={faPlay}
        />
        {heading}
      </Typography>
      <div style={{ overflow: "hidden" }}>
        {open ? (
          <Typography
            component="div"
            ref={divRef}
            className={`${classes.articleBody} animate__animated animate__fadeInDown`}
            dangerouslySetInnerHTML={{
              __html: marked(content),
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const QuizTakeawaysPopup = () => {
  const gameEngine = GameEngine();
  const classes = styles();
  const device = useDeviceDetect();
  const { id } = useParams<any>();
  const [open, setOpen] = useState<boolean>(true);
  const history = useHistory();
  const [quizTakeAways, setQuizTakeAways] = useState<QuizTakeAway[]>([]);

  useEffect(() => {
    gameEngine.episodes.getEpisodeById(id).then((e) => {
      loadQuizExplanations(e).then(setQuizTakeAways);
    });
  }, []);

  const handleClose = () => {
    history.push({
      pathname: "/",
      state: undefined,
    });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      fullScreen={device.mobile}
    >
      <DialogActions className={classes.paperActions}>
        <Button
          onClick={handleClose}
          color="primary"
          className={classes.btn}
          data-e2e-report-advice-popup-close
        >
          <CloseIcon fontSize="large" className={classes.closeBtn} />
        </Button>
      </DialogActions>
      <DialogContent classes={{ root: classes.content }}>
        {quizTakeAways.length > 0 && (
          <div data-e2e-report-advice-popup-article />
        )}
        {quizTakeAways.length > 0 &&
          quizTakeAways.map((t) => (
            <QuizTakeawaysPopupItem
              key={t.heading}
              heading={t.heading}
              content={t.content}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default QuizTakeawaysPopup;

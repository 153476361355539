const allowedChars = new RegExp(/[0-9A-Za-zÀ-ÖØ-öø-ÿ\._-]+/g);

export const generateCompanyURL = (companyName: string): string => {
  return `${companyName
    .trim()
    .split(/\s+/)
    .join("-")
    .toLowerCase()
    .match(allowedChars)
    ?.join("")}.com`;
};

export const generatePlayerEmailAddress = (
  playerName: string,
  companyURL: string
): string =>
  `${playerName
    .trim()
    .split(/\s+/)
    .join(".")
    .match(allowedChars)
    ?.join("")}@${companyURL}`;

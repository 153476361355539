import { SUPPORTED_LANGUAGES_TYPE } from "../../i18n";
import { TagTranslates } from "./interfaces";

export const defaultSettings = (language: SUPPORTED_LANGUAGES_TYPE) => ({
  id: "player",
  companyName: "Acme",
  companyURL: "acme.inc",
  companyLogo: "",
  playerName: language === "de" ? "Teammitglied" : "Team member",
  playerAvatar: "/avatars/avatar5.svg",
  leadingColor: "#d3e553",
  playerEmailAddress: "",
});

export const tagTranslates: TagTranslates = {
  Geschlecht: "Gender",
  Altersgruppe: "Age group",
  Bildungsabschluss: "Educational achievement",
  Organisationszugehörigkeit: "Length of employment",
  Funktion: "Function",
  Branche: "Industry",
  "Management-Verantwortung": "Management responsibility",
  Region: "Region",
  Beschäftigungsverhältnis: "Employment status",
  Standort: "Site",
  Abteilung: "Department",

  Männlich: "Male",
  Weiblich: "Female",
  Divers: "Diverse",

  "Bis 25": "Up to 25",
  "26-35": "26-35",
  "36-45": "36-45",
  "46-55": "46-55",
  "56-65": "56-65",
  "Über 65": "Over 65",

  "> 30 Std. pro Woche": "> 30h per week",
  "30 - 21 Std. pro Woche": "30-21h per week",
  "< 21 Std. pro Woche": "< 21h per week",

  "Kein Abschluss": "No degree",
  Hauptschule: "Secondary school",
  "Mittlere Reife": "Middle school",
  Abitur: "High School",
  Bachelor: "Bachelor",
  Master: "Master",
  Promotion: "Doctorate",
  "Bachelor, FH-Diplom, Staatsexamen, Fachwirt, Meister, ...":
    "Bachelor, FH diploma, state examination, business administrator, master, ...",
  "Master, Uni-Diplom, Magister, Staatsexamen, ...":
    "Masters, university diploma, magister, state examination, ...",

  "Unter 2 Jahre": "Under 2 years",
  "2-5 Jahre": "2-5 years",
  "6-9 Jahre": "6-9 years",
  "Über 10 Jahre": "Over 10 years",

  Linienmanagement: "Line management",
  Produktleitung: "Product line",
  Projektleitung: "Project management",
  "Forschung und Entwicklung": "Research and development",
  "Verwaltung und Organisation": "Administration and organization",
  Vertrieb: "Sales",

  Agrarwirtschaft: "Agriculture",
  Bau: "Construction",
  "Chemie und Rohstoffe": "Chemistry and raw materials",
  "Dienstleistungen und Handwerk": "Services and crafts",
  "E-Commerce": "E-Commerce",
  "Energie und Umwelt": "Energy and environment",
  "Finanzen Versicherungen und Immobilien":
    "Finances, insurance and real estate",
  Freizeit: "Leisure",
  Gesellschaft: "Society",
  Handel: "Trade",
  Internet: "Internet",
  "Konsum und FMCG": "Consumption and FMCG",
  "Medien und Marketing": "Media and marketing",
  "Metall und Elektronik": "Metal and electronics",
  "Pharma und Gesundheit": "Pharma and health",
  "Technik und Telekommunikation": "Technology and telecommunications",
  "Tourismus und Gastronomie": "Tourism and gastronomy",
  "Verkehr und Logistik": "Transport and logistics",
  "Verwaltung und Verteidigung": "Administration and defense",
  "Wirtschaft und Politik": "Economy and politics",

  Keine: "None",
  "Team-Leitung": "Team leader",
  "Mittleres Management": "Middle management",
  Vorstand: "Executive",

  "Baden-Württemberg": "Baden-Württemberg",
  Bayern: "Bavaria",
  Berlin: "Berlin",
  Brandenburg: "Brandenburg",
  Bremen: "Bremen",
  Hamburg: "Hamburg",
  Hessen: "Hessen",
  "Mecklenburg-Vorpommern": "Mecklenburg-Vorpommern",
  Niedersachsen: "Lower Saxony",
  "Nordrhein-Westfalen": "North Rhine-Westphalia",
  "Rheinland-Pfalz": "Rhineland-Palatinate",
  Saarland: "Saarland",
  Sachsen: "Saxony",
  "Sachsen-Anhalt": "Saxony-Anhalt",
  "Schleswig-Holstein": "Schleswig-Holstein",
  Thüringen: "Thuringia",

  "Über 50 Jahre": "Over 50 years",
  Vergnügen: "Leasure",
  Liechtenstein: "Liechtenstein",
  Nirgendwo: "Nowhere",

  Andere: "Other",
  Sonstiger: "Other",
  Sonstige: "Other",
};

import { ConnectedCalendar } from "./interfaces/connected-calendar";
import { MeetingEvent } from "./interfaces/meeting-event";

export const CALENDAR_SELECTED_MONTH_ADDED = "Calendar.selectedMonthAdded";
export const CALENDAR_SELECTED_MONTH_SUBTRACTED =
  "Calendar.selectedMonthSubtracted";
export const CALENDAR_ADD_ACTOR_CALENDAR = "Calendar.addActorCalendar";
export const CALENDAR_ADD_MEETING = "Calendar.addMeeting";

export interface SelectedMonthAdded {
  type: typeof CALENDAR_SELECTED_MONTH_ADDED;
  payload: {
    numberOfMonths: number;
  };
}

export interface SelectedMonthSubtracted {
  type: typeof CALENDAR_SELECTED_MONTH_SUBTRACTED;
  payload: {
    numberOfMonths: number;
  };
}

export interface AddActorCalendar {
  type: typeof CALENDAR_ADD_ACTOR_CALENDAR;
  payload: ConnectedCalendar;
}

export interface AddMeeting {
  type: typeof CALENDAR_ADD_MEETING;
  payload: MeetingEvent;
}

export type CalendarMessage =
  | SelectedMonthAdded
  | SelectedMonthSubtracted
  | AddActorCalendar
  | AddMeeting;

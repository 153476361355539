import { Message as MessageHubMessage } from "../../message-hub/interfaces";
import { Scenario } from "./interfaces/scenario";
import { ScenarioResults } from "./interfaces/scenario-results";

export const PROGRAMMING_GAME_ADD_SCENARIO = "programming.game.add.scenario";
export const PROGRAMMING_GAME_START = "programming.game.start";
export const PROGRAMMING_GAME_ATTEMPT_FAILED =
  "programming.game.attempt.failed";
export const PROGRAMMING_GAME_SCENARIO_FINISHED =
  "programming.game.scenario.finished";
export const PROGRAMMING_GAME_SHOW_HINTS = "programming.game.show.hints";
export const PROGRAMMING_GAME_RESET = "programming.game.reset";
export const PROGRAMMING_GAME_EXIT = "programming.game.exit";
export const PROGRAMMING_GAME_SHOW_END_SCREEN =
  "programming.game.show.end.screen";

export type ProgrammingGameAddScenario = MessageHubMessage<
  typeof PROGRAMMING_GAME_ADD_SCENARIO,
  Scenario
>;

export type ProgrammingGameStart = MessageHubMessage<
  typeof PROGRAMMING_GAME_START
>;

export type ProgrammingGameAttemptFailed = MessageHubMessage<
  typeof PROGRAMMING_GAME_ATTEMPT_FAILED
>;

export type ProgrammingGameScenarioFinished = MessageHubMessage<
  typeof PROGRAMMING_GAME_SCENARIO_FINISHED,
  {
    scenario: Scenario;
    results: ScenarioResults;
  }
>;

export type ProgrammingGameShowHints = MessageHubMessage<
  typeof PROGRAMMING_GAME_SHOW_HINTS
>;

export type ProgrammingGameReset = MessageHubMessage<
  typeof PROGRAMMING_GAME_RESET
>;

export type ProgrammingGameExit = MessageHubMessage<
  typeof PROGRAMMING_GAME_EXIT
>;

export type ProgrammingGameShowEndScreen = MessageHubMessage<
  typeof PROGRAMMING_GAME_SHOW_END_SCREEN
>;

export type ProgrammingGameMessage =
  | ProgrammingGameAddScenario
  | ProgrammingGameStart
  | ProgrammingGameAttemptFailed
  | ProgrammingGameScenarioFinished
  | ProgrammingGameShowHints
  | ProgrammingGameReset
  | ProgrammingGameExit
  | ProgrammingGameShowEndScreen;

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={62}
      height={65}
      viewBox="0 0 62 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 0v11.304M9.018 9.043l8.032 8.055M0 31.087h11.273M9.018 53.13l8.032-8.054M52.982 53.13l-8.032-8.054M62 31.087H50.727M52.982 9.043l-8.032 8.055M36.636 62.174h-1.409l-1.409 1.413h-5.636l-1.41-1.413h-1.408M22.545 53.696h16.91M23.955 57.935h14.09M21.277 7.63l2.114 5.229M7.609 21.337l5.213 2.12M7.609 40.837l5.213-2.12M54.39 40.837l-5.213-2.12M54.39 21.337l-5.213 2.12M40.722 7.63L38.61 12.86M33.818 31.087l.141-9.467-11.414 13.706h5.637v9.892l11.273-14.131h-5.637z"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M39.455 50.87v-2.968c0-2.685.704-4.804 2.254-6.358 1.55-1.555 4.791-4.664 4.791-10.457 0-8.62-6.904-15.543-15.5-15.543s-15.5 6.923-15.5 15.543c0 5.793 3.382 9.043 4.79 10.457 1.55 1.554 2.256 3.673 2.256 6.358v2.968"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;

import "animate.css";
import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/animate.css/animate.min.css";
import CONFIG from "./config";
import AppRoot from "./core/app-root";
import { postEvents } from "./core/client";
import {
  checkForInvitationLink,
  sendEventIfUserInvited,
} from "./core/dashboard/pages/invitation/invitation.service";
import type { UserInfo } from "./core/interfaces";
import { keycloak } from "./core/keycloak";
import { initStorage } from "./core/storage";
import "./css-variables.scss";
import "./fonts.css";
import { GameEngine } from "./game-engine/game-engine";
import { TestResult } from "./game-engine/interfaces";
import { register, waitForServiceWorker } from "./serviceWorker";
import "./style.css";
import { initUserSupport } from "./user-support";

function checkHref() {
  // to prevent page reload in testcafe
  if (!window.location.href.includes("#"))
    window.history.replaceState(
      {},
      document.title,
      window.location.href + "#/"
    );
}

function userInvitedByPlayerTest() {
  const passwordStorageKey = "PW_COMPLEXITY_SCORE";
  const { localStorage } = window;
  const newPasswordTextScore = localStorage.getItem(passwordStorageKey);

  if (newPasswordTextScore) {
    sendEventIfUserInvited();
  }
}

function passwordComplexityTests() {
  const passwordStorageKey = "PW_COMPLEXITY_SCORE";
  const { localStorage } = window;
  const newPasswordTextScore = localStorage.getItem(passwordStorageKey);
  if (newPasswordTextScore) {
    const score = parseInt(newPasswordTextScore, 10);
    if (isNaN(score)) {
      console.error("Invalid password complexity score");
      return;
    }

    const results: TestResult[] = [
      {
        id: "select non-trivial password at sign-up",
        value: score >= 3 ? 1 : 0,
      },
      {
        id: "select complex password at sign-up",
        value: score >= 4 ? 1 : 0,
      },
    ];

    postEvents(
      results.map((result) => ({ type: "test.complete", result }))
    ).then(() => {
      localStorage.removeItem(passwordStorageKey);
    });
  }
}

function otpTest() {
  const otpStorageKey = "OTP_CONFIGURED";
  const { localStorage } = window;
  const otpConfigured = !!localStorage.getItem(otpStorageKey);
  if (otpConfigured) {
    const event = {
      type: "test.complete",
      result: {
        id: "configure otp",
        value: 1,
      },
    };
    postEvents([event]).then(() => {
      localStorage.removeItem(otpStorageKey);
    });
  }
}

async function startGame(user: UserInfo) {
  checkHref();

  await initStorage(user);

  const rootEl = document.getElementById("root") as HTMLElement;
  const gameEngine = new GameEngine();

  await waitForServiceWorker();
  await gameEngine.init();

  ReactDOM.render(React.createElement(AppRoot), rootEl);

  initUserSupport(user);
  userInvitedByPlayerTest();
  passwordComplexityTests();
  otpTest();
}

async function start() {
  try {
    const authenticated = await keycloak.init({
      onLoad: "check-sso",
    });
    if (authenticated) {
      if (!("serviceWorker" in navigator)) {
        alert(
          "Your browser is missing Service Worker functionality. Some images and videos may not load correctly."
        );
      }
      const userInfo = (await keycloak.loadUserInfo()) as UserInfo;
      startGame(userInfo);
    } else {
      checkForInvitationLink();
      keycloak.login({ maxAge: 100000 });
    }
  } catch (e) {
    alert("Error authenticating");
    console.error(e);
  }
}

register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        const sw = event.target as ServiceWorker;
        if (sw.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

start();

if (!CONFIG.ANIMATIONS_ENABLED) {
  document.documentElement.style.setProperty("--animate-duration", "0");
}

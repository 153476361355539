import { Lock, LockOpen } from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Card, CircularProgress, Tab, Tabs, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GameEngine from "../../../../game-engine";
import { Badge } from "../../../../game-engine/badges-engine";
import { Episode, EpisodePublish } from "../../../../game-engine/interfaces";
import { getReportTestDetails } from "../../../client";
import badgesIcon from "../../../header/assets/badges.svg";
import diamondIcon from "../../../header/assets/diamond.svg";
import takeAwaysIcon from "../../../header/assets/take_aways.svg";
import { useDeviceDetect } from "../../../hooks";
import { CompetenceArea } from "../../../interfaces";
import inProgressIcon from "../../assets/in_progress_icon.svg";
import lockedIcon from "../../assets/locked_icon.svg";
import noImgImage from "../../assets/no_img.svg";
import replayIcon from "../../assets/replay_icon.svg";
import { Tests } from "../../components/episode-report";
import { Badges } from "../../components/episode-report-badges";
import TakeAways from "../../components/episode-report-takeaways";
import LockedBanner from "../../components/locked-banner";
import DashboardTooltip from "../../components/tooltip";
import { useStyles } from "./item.styles";

type PropsType = {
  seasonId: string;
  allEpisodes?: Episode[];
  episode: Episode;
  onPlay: () => void;
  onSelectTab: (tab: string) => void;
  currentTab?: { episodeId: Episode["id"]; tab: string };
  onSelectEpisode: () => void;
  isAdmin: boolean;
  assetsUrl?: string;
};

const BADGES = "badges";
const COMPETENCE = "competence";
const TAKE_AWAYS = "take_aways";
const CHECKPOINT_THRESHOLD = 0.8;

const DashboardHomeItem = (props: PropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { episode, allEpisodes, onSelectEpisode, isAdmin } = props;
  const [activeTab, setActiveTab] = useState<string>("");
  const [badges, setBadges] = useState<Badge[]>([]);
  const [scrollIntoView, setScrollIntoView] = useState<boolean>(false);
  const [testDetails, setTestDetails] = useState<CompetenceArea[]>([]);
  const [testDetailsLoaded, setTestDetailsLoaded] = useState(false);
  const [checkpointChecked, setCheckpointChecked] = useState(false);
  const [checkpointEpisodePassed, setCheckpointEpisodePassed] = useState(false);
  const [currentEpisodeCheckpointPassed, setCurrentEpisodeCheckpointPassed] =
    useState(false);
  const [unfoldDesc, setUnfoldDesc] = useState(false);
  const intl = useIntl();

  const params = new URLSearchParams(window.location.search);
  const debugWithUnlock = params.get("unlock") !== null;

  const episodePlayable =
    debugWithUnlock ||
    (!episode.locked && !episode.hidden && checkpointEpisodePassed);

  const showCheckpointNotice =
    Boolean(episode.checkpointEpisode) && !currentEpisodeCheckpointPassed;

  useEffect(() => {
    const previousEpisodes = allEpisodes?.filter(
      (e) => e.index < episode.index
    );

    if (previousEpisodes?.length === 0) {
      setCheckpointEpisodePassed(true);
      setCheckpointChecked(true);
      return;
    }

    const lastCheckpointEpisode = previousEpisodes
      ?.slice()
      .reverse()
      .find((episode) => episode.checkpointEpisode);

    if (!lastCheckpointEpisode) {
      setCheckpointEpisodePassed(true);
      setCheckpointChecked(true);
      return;
    }

    getReportTestDetails(
      lastCheckpointEpisode.seasonId,
      lastCheckpointEpisode.id
    ).then((reportTestDetails: CompetenceArea[]) => {
      if (testsMatchThreshold(reportTestDetails)) {
        setCheckpointEpisodePassed(true);
      }
      setCheckpointChecked(true);
    });
  }, []);

  useEffect(() => {
    if (episode.completed) {
      setBadges(GameEngine().badgesEngine.getBadgesInEpisode(episode.id));

      getReportTestDetails(episode.seasonId, episode.id).then(
        (reportTestDetails: CompetenceArea[]) => {
          if (testsMatchThreshold(reportTestDetails)) {
            setCurrentEpisodeCheckpointPassed(true);
          }
          setTestDetails(reportTestDetails);
          setTestDetailsLoaded(true);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!testDetailsLoaded) return;
    if (props.currentTab?.episodeId === episode.id) {
      const tab = getFirstAvailableActiveTab(
        props.currentTab?.tab,
        testDetails,
        badges
      );
      setActiveTab(tab);
      setScrollIntoView(true);
    }
  }, [
    props.currentTab?.tab,
    props.currentTab?.episodeId,
    testDetails.length,
    badges.length,
    testDetailsLoaded,
  ]);

  useEffect(() => {
    if (!ref.current || !scrollIntoView) return;
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [scrollIntoView]);

  useEffect(() => {
    if (activeTab) props.onSelectTab(activeTab);
  }, [activeTab]);

  const device = useDeviceDetect();

  const onConfirmPublish = () => {
    if (episode?.publish === "READY") {
      onSelectEpisode();
    }
  };

  const caret = (tab) => (activeTab === tab ? "▼" : "▶");
  const getCompetenceLabel = () => {
    let passedTests = 0;
    let totalTests = 0;
    if (testDetails.length) {
      for (const { tests } of testDetails) {
        for (const test of tests) {
          passedTests += test.result;
          totalTests++;
        }
      }
    }

    return device.mobile ? (
      `${passedTests}/${totalTests}`
    ) : (
      <FormattedMessage
        id="dashboard.page.home.competencies"
        values={{ passedTests, totalTests, caret: caret(COMPETENCE) }}
      />
    );
  };

  const getBadgesLabel = () => {
    const earnedBadges = badges.filter((b) => b.obtained).length;
    const totalBadges = badges.length;
    return device.mobile ? (
      `${earnedBadges}/${totalBadges}`
    ) : (
      <FormattedMessage
        id="dashboard.page.home.badges"
        values={{
          earnedBadges,
          totalBadges,
          caret: caret(BADGES),
        }}
      />
    );
  };

  const getTakeAwaysLabel = () =>
    device.mobile ? (
      " "
    ) : (
      <FormattedMessage
        id="dashboard.page.home.take-aways"
        values={{ caret: caret(TAKE_AWAYS) }}
      />
    );

  const play = () => {
    if (episodePlayable) {
      props.onPlay();
    }
  };

  const getIcon = (e: Episode): string =>
    episode.locked
      ? lockedIcon
      : episode.completed
      ? replayIcon
      : inProgressIcon;

  const getImg = (episode: Episode, classes: any) => {
    const assetsUrl = props.assetsUrl;

    if (assetsUrl?.length) {
      return (
        <img
          className={classes.image}
          src={`${assetsUrl}/${episode.imageUrl}`}
        />
      );
    } else if (episode.imageUrl) {
      return <img className={classes.image} src={episode.imageUrl} />;
    } else {
      return <img className={classes.image} src={noImgImage} />;
    }
  };

  const handleChange = (_, newTab: string) => {
    setActiveTab((t) => (t === newTab ? "" : newTab));
  };

  const getTextForEpisodeDescription = (episode: Episode) =>
    (episode.completed && !episode.hidden
      ? intl.messages["dashboard.page.home.replay-episode"]
      : episode.description) || "";

  const getLockedBanner = () => {
    if (episode.episodePublishingCompatibility) {
      if (!isAdmin && episode.publish !== "PUBLISHED") {
        return <LockedBanner />;
      }
    }

    if (episode.maturity !== "PUBLIC") {
      return <LockedBanner />;
    }

    return <></>;
  };

  return (
    <Box mb={4}>
      <Card
        data-dashboard-tour="episode-item"
        style={
          episode.maturity === "PUBLIC" ? { opacity: 1 } : { opacity: 0.5 }
        }
        ref={ref}
      >
        {getLockedBanner()}
        <div className={classes.firstRow}>
          <div
            className={`${classes.imageContainer} ${
              episodePlayable ? classes.playableImageContainer : ""
            }`}
            onClick={play}
          >
            {!checkpointChecked && (
              <CircularProgress
                color="primary"
                className={classes.checkpointLoader}
              />
            )}
            {getImg(episode, classes)}
            {episodePlayable && (
              <div className={classes.playIconWrapper}>
                <div />
              </div>
            )}
            {checkpointChecked && !checkpointEpisodePassed && !debugWithUnlock && (
              <div className={classes.blockIconWrapper}>
                <Tooltip
                  title={
                    <FormattedMessage
                      id="dashboard.page.home.checkpoint-episode-not-met-tooltip"
                      values={{ percentage: CHECKPOINT_THRESHOLD * 100 }}
                    />
                  }
                >
                  <BlockIcon style={{ fontSize: 60 }} />
                </Tooltip>
              </div>
            )}
          </div>
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              {!episode.hidden && (
                <img
                  style={{ cursor: episodePlayable ? "pointer" : "default" }}
                  src={getIcon(episode)}
                  onClick={play}
                  data-e2e-dashboard-start-episode-button
                />
              )}
              <p
                className="title"
                style={{
                  cursor: episodePlayable ? "pointer" : "default",
                }}
                onClick={play}
              >
                {episode.index}. {episode.title}
              </p>
              {isAdmin && episode.episodePublishingCompatibility && (
                <ButtonPublishEpisode
                  state={episode.publish}
                  onClick={onConfirmPublish}
                />
              )}
            </div>
            <p onClick={() => device.mobile && setUnfoldDesc((prev) => !prev)}>
              {device.mobile && !unfoldDesc
                ? `${getTextForEpisodeDescription(episode)?.slice(0, 45)}...`
                : getTextForEpisodeDescription(episode)}
            </p>
          </div>
        </div>
        {episode.completed && (
          // TODO: #TI-1710 Implement swiper for tabs on mobile
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab || false}
              className={classes.tabs}
              classes={{
                root: activeTab ? classes.expandedPanel : classes.foldedPanel,
              }}
              onChange={handleChange}
              variant="fullWidth"
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab
                data-e2e-report-competencies-tab={episode.id}
                data-dashboard-tour="report-competencies-tab"
                label={getCompetenceLabel()}
                icon={<img src={diamondIcon} />}
                value={COMPETENCE}
                disabled={testDetails.length === 0}
              />
              <Tab
                data-e2e-report-badges-tab={episode.id}
                data-dashboard-tour="report-badges-tab"
                label={getBadgesLabel()}
                icon={<img src={badgesIcon} />}
                value={BADGES}
                disabled={badges.length === 0}
              />
              <Tab
                data-e2e-report-take-aways-tab={episode.id}
                data-dashboard-tour="report-take-aways-tab"
                label={getTakeAwaysLabel()}
                icon={<img src={takeAwaysIcon} />}
                value={TAKE_AWAYS}
              />
            </Tabs>
            <TabPanel value={COMPETENCE} style={{ minHeight: "500px" }}>
              <Tests
                testDetails={testDetails}
                showCheckpointNotice={showCheckpointNotice}
                checkpointThreshold={CHECKPOINT_THRESHOLD}
              />
            </TabPanel>
            <TabPanel value={BADGES}>
              <Badges badges={badges} />
            </TabPanel>
            <TabPanel value={TAKE_AWAYS}>
              <TakeAways episode={episode} testDetails={testDetails} />
            </TabPanel>
          </TabContext>
        )}
      </Card>
    </Box>
  );
};

const ButtonPublishEpisode = (props: {
  state?: EpisodePublish;
  onClick: () => void;
}) => {
  const state = props.state;
  const classes = useStyles();
  const animClasses =
    state === "READY" ? "animate__animated animate__heartBeat" : "";
  let background = "#BD1367";
  if (state === "PUBLISHED") {
    background = "#27b199";
  } else if (state === "READY") {
    background = "#FCC231";
  }
  return (
    <DashboardTooltip title={<ButtonPublishInfo />} enterTouchDelay={0}>
      <button
        className={`${classes.publishButton} ${animClasses}`}
        onClick={props.onClick}
        style={{ background }}
      >
        {state === "PUBLISHED" ? <LockOpen /> : <Lock />}
      </button>
    </DashboardTooltip>
  );
};

const ButtonPublishInfo = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.publishButtonTooltipRow}>
        <div
          className={classes.publishButtonTooltipIcon}
          style={{
            background: "#BD1367",
          }}
        >
          <Lock />
        </div>
        <p>
          <FormattedMessage id="dashboard.page.home.publish.tooltip.locked" />
        </p>
      </div>
      <div className={classes.publishButtonTooltipRow}>
        <div
          className={classes.publishButtonTooltipIcon}
          style={{
            background: "#FCC231",
          }}
        >
          <Lock />
        </div>
        <p>
          <FormattedMessage id="dashboard.page.home.publish.tooltip.ready" />
        </p>
      </div>
      <div className={classes.publishButtonTooltipRow}>
        <div
          className={classes.publishButtonTooltipIcon}
          style={{
            background: "#27b199",
          }}
        >
          <LockOpen />
        </div>
        <p>
          <FormattedMessage id="dashboard.page.home.publish.tooltip.published" />
        </p>
      </div>
    </>
  );
};

const testsMatchThreshold = (testDetails: CompetenceArea[]): boolean => {
  const testResults = testDetails.flatMap((testDetails) =>
    testDetails.tests.map((test) => test.result)
  );

  if (testResults.length === 0) return true;

  const testsPassed = testResults.filter(Boolean).length;
  const percentagePassed = testsPassed / testResults.length;

  return percentagePassed >= CHECKPOINT_THRESHOLD;
};

const getFirstAvailableActiveTab = (
  tab: string,
  testDetails: CompetenceArea[],
  badges: Badge[]
) => {
  if (tab === COMPETENCE && testDetails.length !== 0) return COMPETENCE;
  if (tab === BADGES && badges.length !== 0) return BADGES;
  return TAKE_AWAYS;
};

export default DashboardHomeItem;

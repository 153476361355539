import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    backgroundColor: "rgba(25, 66, 74, 0.95)",
    display: "flex",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > p": {
      margin: "5px 0",
    },
    "& > p:first-of-type": {
      fontSize: "2rem",
      marginBottom: ".5rem",
    },
    "& > p:last-of-type": {
      margin: "10px 0",
    },
    "@media (max-width: 600px)": {
      "& > p": {
        margin: "15px 0",
      },
      "& > p:first-of-type": {
        marginBottom: "0",
      },
    },
  },
  button: {
    border: "2px solid #fff",
    color: "#fff",
    padding: "16px 16px",
    borderRadius: "20px",
    fontFamily: "Roboto",
    letterSpacing: "1px",
    fontSize: "14px",
    transition: ".5s",
    width: "300px",
    background: "rgb(232, 62, 98, 0.9)",
    "&:hover": {
      background: "rgb(232, 62, 98, 1)",
    },
  },
  pageContent: {
    width: "calc(100vw - 300px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        width: "100vw",
        transform: "translateX(-70vw)",
      },
  },
  mobileContentHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    width: "100vw",

    "& img": {
      marginLeft: 20,
    },
  },
  mobileContentHeaderTitle: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    fontSize: 18,
    fontFamily: `'Lato', sans-serif`,
    fontWeight: 700,
    userSelect: "none",
    color: "#FFFFFF",

    "& img": {
      color: "white",
      marginRight: 20,
    },
  },
  pageContentOpened: {
    animation: "$open 300ms linear forwards",
  },
  pageContentClosed: {
    animation: "$close 300ms linear forwards",
  },
  content: {
    height: "100%",
    overflow: "hidden",
    paddingTop: theme.spacing(3),

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        paddingTop: 0,
        height: "calc(100% - 60px)",
      },
  },

  "@keyframes open": {
    from: {
      transform: "translateX(-70vw)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
  "@keyframes close": {
    from: {
      transform: "translateX(0)",
    },
    to: {
      transform: "translateX(-70vw)",
    },
  },
}));

export default styles;

import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";
import { useSettings } from "../../hooks";
import inviteColleagueIcon from "../assets/invite_colleague.svg";
import rules from "../player-progress.rules";
import styles from "./menu.styles";
import { PlayerProgressBar } from "./player-progress-bar";

type PropsType = {
  menuOpened?: boolean;
  pages: any;
  logout: () => void;
  companyLink: boolean;
  adminLink: boolean;
};

const DashboardMenu = (props: PropsType) => {
  const classes = styles();
  const { settings } = useSettings();
  const { menuOpened, pages, logout } = props;
  const history = useHistory();

  let openStateCls = "";

  const goToInvitations = () => {
    history.push("/invitation");
  };

  if (typeof menuOpened === "boolean") {
    openStateCls = menuOpened ? classes.opened : classes.closed;
  }

  const playerMenu = (
    <>
      <MenuItem
        title={pages.HOME.titleId}
        icon={pages.HOME.icon}
        href={pages.HOME.path}
      />
      <MenuItem
        title={pages.NEWS.titleId}
        icon={pages.NEWS.icon}
        href={pages.NEWS.path}
      />
      <MenuItem
        title={pages.REPORT.titleId}
        icon={pages.REPORT.icon}
        href={pages.REPORT.path}
      />
    </>
  );

  const companyMenu = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <MenuItem
        title={pages.ADMIN.titleId}
        icon={pages.ADMIN.icon}
        href={pages.ADMIN.path}
        hidden={!props.adminLink}
      />
      <MenuItem
        title={pages.COMPANY.titleId}
        icon={pages.COMPANY.icon}
        href={pages.COMPANY.path}
        hidden={!props.companyLink}
      />
    </div>
  );

  return (
    <div className={`${classes.root} ${openStateCls}`}>
      <div className={classes.profile}>
        <div
          className={classes.avatar}
          style={{ backgroundColor: settings?.leadingColor }}
        >
          <img src={settings?.playerAvatar} />
        </div>
        <p className={classes.playerName}>{settings?.playerName}</p>
        <NavLink
          to={pages.PROFILE.path}
          activeClassName={classes.playerProfileLinkActive}
          className={classes.playerProfileLink}
          data-e2e-dashboard-open-page-button
          data-e2e-dashboard-open-profile-page-button
        >
          <FormattedMessage id={pages.PROFILE.titleId} />
        </NavLink>
        <PlayerProgressBar rules={rules} />
      </div>

      <NavLink
        to="/invitation"
        className={classes.inviteColleagueWrapper}
        data-e2e-dashboard-invite-colleague-button
      >
        <div className={classes.inviteColleagueIndicator}></div>
        <div className={classes.inviteColleague} onClick={goToInvitations}>
          <img src={inviteColleagueIcon} alt="invite-icon" />
          <p>
            <FormattedMessage id="dashboard.main.menu.invite-colleague-button" />
          </p>
        </div>
      </NavLink>

      <div className={classes.menu}>
        <p className={classes.menuTitle}>MENU</p>
        {playerMenu}
      </div>

      {companyMenu}

      <div className={classes.buttonsBox}>
        <Button
          variant="contained"
          className={classes.logOutButton}
          onClick={logout}
          data-e2e-dashboard-logout-button
        >
          <FormattedMessage id="dashboard.main.menu.logout-button" />
        </Button>
      </div>
    </div>
  );
};

export default DashboardMenu;

type MenuItemProps = {
  title: string;
  icon: string;
  href: string;
  hidden?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {
  const classes = styles();
  const { title, icon, hidden = false } = props;
  const { href = "/" } = props;

  if (hidden) return null;

  const exact = href === "/";
  const useAHref = /^https?:/.test(href);

  const inner = (
    <>
      <div className="indicator">
        <div className={classes.indicator} />
      </div>
      <p className={classes.menuItemText}>
        <img src={icon} alt="icon" />
        <FormattedMessage id={title} />
      </p>
    </>
  );

  if (useAHref) {
    return (
      <a
        className={classes.menuItem}
        href={href}
        target="_blank"
        data-e2e-dashboard-open-page-button
        rel="noreferrer"
      >
        {inner}
      </a>
    );
  }

  return (
    <NavLink
      exact={exact}
      to={href}
      className={classes.menuItem}
      data-e2e-dashboard-open-page-button
    >
      {inner}
    </NavLink>
  );
};

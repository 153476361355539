import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
  },
  pulsating: {
    animation: "$pulsation 3s ease-in-out infinite",
  },
  "@keyframes pulsation": {
    "0%": {
      transform: "scale(1)",
    },
    "25%": {
      transform: "scale(1.35)",
    },
    "50%": {
      transform: "scale(1)",
    },
    "75%": {
      transform: "scale(1.35)",
    },
  },
}));

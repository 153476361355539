import { Observable } from "rxjs";
import { Message } from "../../message-hub/interfaces";
import { ClearPayload, SearchResult, SearchResultPayload } from "./interfaces";

export const SEARCH_ENTER = "search.enter";
export const SEARCH_TERMS = "search.terms";
export const SEARCH_RESULTS = "search.results";
export const SEARCH_RESULTS_TRIGGER = "search.results.trigger";
export const SEARCH_WEB = "search.web";
export const SEARCH_RESULT_CLICK = "search.result.click";
export const SEARCH_CLEAR_RESULTS = "search.result.clear";

export type searchTermMessage = Message<
  typeof SEARCH_TERMS,
  {
    query: string[];
  }
>;

export type searchResultMessage = Message<
  typeof SEARCH_RESULTS,
  SearchResultPayload
>;

export type searchResulsTriggertMessage = Message<
  typeof SEARCH_RESULTS_TRIGGER,
  {
    id: string;
    search: (query: string[]) => Observable<SearchResult>;
  }
>;

export type searchResultClickMessage = Message<
  typeof SEARCH_RESULT_CLICK,
  {
    url: string;
    id: string | undefined;
  }
>;

export type clearResultMessage = Message<
  typeof SEARCH_CLEAR_RESULTS,
  ClearPayload
>;

export type searchWebMessage = Message<
  typeof SEARCH_WEB,
  {
    query: string[];
  }
>;

export type searchMessage =
  | searchTermMessage
  | searchResultMessage
  | searchResulsTriggertMessage
  | searchResultClickMessage
  | clearResultMessage
  | searchWebMessage;

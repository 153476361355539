import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import messageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { useObservabale } from "../header/hooks";
import { GAME_QUESTS_UPDATE, PULSE_REWIND_ICON } from "../messages";
import { Quest, RewindRef } from "./../../game-engine/rewinder";

const $questsList = messageHub.subject.pipe((observable: Observable<Message>) =>
  observable.pipe(
    filter((m) => m.type === GAME_QUESTS_UPDATE),
    map((m) => m.payload)
  )
);

export const useQuestsList: () => {
  quests: Quest[];
  refs: Record<string, RewindRef>;
} = useObservabale($questsList, {});

const $pulseRewindIcon = messageHub.subject.pipe(
  filter((m) => m.type === PULSE_REWIND_ICON),
  map((m) => m.payload)
);

export const usePulseRewindIcon = useObservabale($pulseRewindIcon, false);

import { postEvents } from "../../../client";

const USER_INVITED_BY_STORAGE_KEY = "USER_INVITED_BY";
const EVENT_NAME = "user.invited.by.player";

export const checkForInvitationLink = () => {
  if (!window.location) return;

  const invitation = new URL(window.location.href).searchParams.get(
    "invitation"
  );

  if (invitation?.length)
    localStorage.setItem(USER_INVITED_BY_STORAGE_KEY, invitation);
};

export const sendEventIfUserInvited = () => {
  const hash = localStorage.getItem(USER_INVITED_BY_STORAGE_KEY);

  if (hash?.length) {
    postEvents([
      {
        type: EVENT_NAME,
        payload: {
          hash,
        },
      },
    ])
      .then(() => {
        localStorage.removeItem(USER_INVITED_BY_STORAGE_KEY);
      })
      .catch((err) => console.log(err));
  }
};

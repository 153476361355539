import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "98",
  completed: false,
  index: 1,
  title: "OpenBlue Solutioneer",
  info: {
    de: `Corona-Pandemie, drastisch steigende Energiekosten, ESR-Meldepflicht ... was bedeutet das für meine Kunden und meinen Umsatz?`,
    en: `Corona pandemic, drastically increasing cost of energy, mandatory ESR reporting ... what does it mean for my customers and my sales?`,
  },
  topics: [
    {
      competenceAreaId: 2,
      description: {
        de: "Lösungsorientierter Vertrieb",
        en: "Solution-oriented sales",
      },
    },
  ],
  certificates: [],
  seasonEndMessage: "",
  episodes: [
    {
      id: "s98e01",
      title: "SAAT & GUT Co. KGaA, Frau Hennings",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        'Du wirst überraschend zu einem Termin mit dem Thema "Wartungsvertrag Brandmeldetechnik" eingeladen, dabei ist der Vetrag längst unter Dach und Fach. Was ist das los?',
      imageUrl: "/episodes/s98-e01/preview.svg",
      url: "/episodes/s98-e01/",
      load: async () => import(/* webpackChunkName: "s98-e01" */ `../s98-e01`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "98" })),
};

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import DecisionApp from "..";
import { ANCHOR_ID, DECISION_ANCHOR__ScrollToView } from "../constants";
import { DecisionOption } from "../interfaces/decision-option";
import { DecisionPayload } from "../interfaces/decision-payload";

const getElement = (type: string): HTMLDivElement =>
  document.getElementById(`${ANCHOR_ID}_${type}`) as HTMLDivElement;

type PropsType = {
  decision?: DecisionPayload;
  choices: Record<string, boolean>;
};

export const Bottom = ({ decision, choices }: PropsType) => {
  const [pendingOption, setPendingOption] = useState<DecisionOption>();
  const [anchor, setAnchor] = useState<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>(null);
  const decisionRef = useRef<DecisionPayload | undefined>();
  decisionRef.current = decision;

  /**
   * Fallback for a case when decision was added before DirectCommunication or Chat was opened.
   * We are using MutationObserver to listen to changes in DOM then check if decision_anchor element was added.
   */
  useEffect(() => {
    const callback = () => {
      if (decisionRef.current) {
        const el = getElement(decisionRef.current.type);
        if (el) setAnchor(el);
      }
    };

    callback();

    const observer = new MutationObserver(callback);

    observer.observe(document.getElementById("root") || document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }

      const el = getElement(decisionRef.current?.type ?? "");

      if (el) {
        el.style.marginBottom = "0";
        el.dataset.height = "0";
      }
    };
  }, []);

  useEffect(() => {
    if (!anchor) return;

    const data = anchor.dataset["styles"];
    let anchorStyles;

    try {
      if (data?.length) {
        anchorStyles = JSON.parse(data);
      }
    } catch (err) {
      console.error(err);
    }

    if (!anchorStyles) return;

    const event = new CustomEvent(DECISION_ANCHOR__ScrollToView);
    const timeout = setTimeout(() => document.dispatchEvent(event), 300);

    anchor.style.marginBottom = `${
      (ref?.current?.clientHeight || 0) - (anchorStyles.bottom || 0)
    }px`;

    anchor.dataset.height = `${
      (ref?.current?.clientHeight || 0) - (anchorStyles.bottom || 0)
    }`;

    return () => clearTimeout(timeout);
  }, [anchor, ref?.current]);

  const onOptionClick = (option: DecisionOption) => {
    if (!decisionRef.current) return;

    if (
      decisionRef.current.confirm &&
      decisionRef.current.options.findIndex((x) => x.id === option.id) ===
        decisionRef.current.options.length - 1
    ) {
      setPendingOption(option);
    } else {
      DecisionApp.instance.choose(decisionRef.current.id, option);
    }
  };

  const onConfirm = () => {
    if (!decisionRef.current || !pendingOption) return;

    if (anchor) {
      anchor.style.height = "0";
    }

    DecisionApp.instance.choose(decisionRef.current.id, pendingOption);
    setPendingOption(undefined);
  };

  const onCancel = () => {
    setPendingOption(undefined);
  };

  const options = (
    <StyledOptionsBox
      data-e2e-decision-panel
      data-e2e-multiple={decision?.multiple}
      className="animate__animated animate__fadeIn"
      wrap={
        decisionRef.current?.options && decisionRef.current.options.length > 2
          ? "wrap"
          : "no-wrap"
      }
    >
      {decisionRef.current?.options.map((option, idx) => (
        <StyledButton
          data-e2e-decision-panel-button={idx + 1}
          id={(option.id as string) || idx.toString()}
          key={option.id || idx}
          variant="text"
          onClick={() => onOptionClick(option)}
          disabled={choices[option.id] !== undefined}
        >
          {option.text}
        </StyledButton>
      ))}
    </StyledOptionsBox>
  );

  const confirm = (
    <StyledConfirmBox
      data-e2e-confirm-decision-panel
      className="animate__animated animate__fadeIn"
    >
      <StyledHeaderText>
        <FormattedMessage id="decisions.confirmHeader" />
      </StyledHeaderText>
      <StyledOptionsBox wrap="no-wrap">
        <StyledButton
          data-e2e-confirm-option
          onClick={onConfirm}
          variant="text"
        >
          <FormattedMessage id="decisions.confirmOption" />
        </StyledButton>
        <StyledButton data-e2e-reject-option onClick={onCancel} variant="text">
          <FormattedMessage id="decisions.rejectOption" />
        </StyledButton>
      </StyledOptionsBox>
    </StyledConfirmBox>
  );

  if (!anchor) return null;

  return createPortal(
    <StyledContainer className="animate__animated animate__fadeIn" ref={ref}>
      {pendingOption && confirm}
      {!pendingOption && options}
    </StyledContainer>,
    anchor
  );
};

const StyledContainer = styled("div")({
  backgroundColor: "rgba(242, 242, 242, 0.9)",
  backdropFilter: "blur(30px)",
  position: "absolute",
  width: "100%",
  bottom: 0,
  marginTop: 10,
  paddingBottom: 60,
  zIndex: 3,
});

const StyledOptionsBox = styled("div")<{ wrap?: "wrap" | "no-wrap" }>(
  ({ wrap }) => ({
    width: "100%",
    padding: 16,
    display: "flex",
    flexWrap: wrap,
    gap: 10,
    justifyContent: "center",
    alignItems: "stretch",
  })
);

const StyledConfirmBox = styled("div")({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledButton = styled(Button)({
  fontSize: 14,
  flex: "1 1 auto",
  minWidth: "calc(50% - 5px)",
  textTransform: "none",
  backgroundColor: "#00b2e2",
  color: "white",
  borderRadius: 8,
  padding: 12,
  transition: "background-color 300ms linear, transform 150ms linear",

  "&:hover": {
    backgroundColor: "#02bdf0",
    transform: "scale(1.03)",
  },

  "&:disabled": {
    backgroundColor: "#67d6f5",
  },
});

const StyledHeaderText = styled("p")({
  fontSize: "1.1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: 0,
});

import { Message } from "../../message-hub/interfaces";
import { Question } from "../quiz-time/interfaces/question";
import { HelpType, PlayerAnswer, QuestionWithContent } from "./interfaces";

export const QUIZ_TAXI_INIT = "qtaxi.add.init";
export const QUIZ_TAXI_INTRO_DONE = "qtaxi.intro.done";
export const QUIZ_TAXI_SKIP = "qtaxi.skip";
export const QUIZ_TAXI_ADD_QUESTION = "qtaxi.question.add";
export const QUIZ_TAXI_ANSWER = "qtaxi.answer";
export const QUIZ_TAXI_HELP = "qtaxi.help";
export const QUIZ_TAXI_TAKE_NEXT = "qtaxi.take.next";
export const QUIZ_TAXI_FINISH = "qtaxi.finish";
export const QUIZ_TAXI_JOKER_CALL_HOME_ACTOR_SELECTED =
  "qtaxi.joker.call.home.actor.selected";
export const QUIZ_TAXI_JOKER_CLOSE = "qtaxi.joker.close";

export const messagesTypes = {
  QUIZ_TAXI_INIT,
  QUIZ_TAXI_INTRO_DONE,
  QUIZ_TAXI_SKIP,
  QUIZ_TAXI_ADD_QUESTION,
  QUIZ_TAXI_ANSWER,
  QUIZ_TAXI_HELP,
  QUIZ_TAXI_TAKE_NEXT,
  QUIZ_TAXI_FINISH,
  QUIZ_TAXI_JOKER_CALL_HOME_ACTOR_SELECTED,
  QUIZ_TAXI_JOKER_CLOSE,
} as const;

export type quizTaxiInit = Message<
  typeof QUIZ_TAXI_INIT,
  { markdownUrl: string; language: string; taxiDriversName: string }
>;
export type quizTaxiIntroDone = Message<typeof QUIZ_TAXI_INTRO_DONE, undefined>;
export type quizTaxiSkip = Message<typeof QUIZ_TAXI_SKIP, undefined>;
export type quizTaxiQuestion = Message<
  typeof QUIZ_TAXI_ADD_QUESTION,
  QuestionWithContent
>;
export type quizTaxiAnswer = Message<typeof QUIZ_TAXI_ANSWER, PlayerAnswer>;
export type quizTaxiHelp = Message<
  typeof QUIZ_TAXI_HELP,
  { type: HelpType; question: Question["id"] }
>;
export type quizTaxiTakeNext = Message<typeof QUIZ_TAXI_TAKE_NEXT, undefined>;
export type quizTaxiFinish = Message<typeof QUIZ_TAXI_FINISH, undefined>;
export type quizTaxiJokerCallHomeActorSelected = Message<
  typeof QUIZ_TAXI_JOKER_CALL_HOME_ACTOR_SELECTED,
  string
>;

export type quizTaxiJokerClose = Message<typeof QUIZ_TAXI_JOKER_CLOSE, string>;

export type quizTaxiMessages =
  | quizTaxiInit
  | quizTaxiIntroDone
  | quizTaxiSkip
  | quizTaxiQuestion
  | quizTaxiAnswer
  | quizTaxiHelp
  | quizTaxiTakeNext
  | quizTaxiFinish
  | quizTaxiJokerCallHomeActorSelected
  | quizTaxiJokerClose;

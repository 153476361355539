export const competences: Record<string, string> = {
  "Informations- und Datenkompetenz": "Information and Data Literacy",
  "Kommunikation und Kollaboration": "Communication and Collaboration",
  "Erstellen von digitalen Inhalten": "Digital Content Creation",
  "Schutz und Sicherheit": "Protection and security",
  "Lösung von Problemen": "Problem solving",

  "Durchsuchen, Suchen und Filtern von digitalen Inhalten":
    "Browsing, searching and filtering digital content",
  "Auswerten, Analysieren und Vergleichen von digitalen Inhalten":
    "Evaluating, analysing and comparing digital content",
  "Verwalten, Organisieren, Speichern und Abrufen von digitalen Inhalten":
    "Managing, organising, storing and retrieving digital content",
  "Interagieren durch digitale Technologien":
    "Interacting through digital technologies",
  "Teilen durch digitale Technologien": "Sharing through digital technologies",
  "Teilnahme an der Gesellschaft durch digitale Dienste":
    "Participating in society through digital services",
  "Zusammenarbeit durch digitale Dienste":
    "Collaborating through digital services",
  Netiquette: "Netiquette",
  "Verwalten und Schützen der digitalen Identität":
    "Managing and protecting digital identity",
  "Kreieren und Bearbeiten von digitalen Inhalten":
    "Creating and editing digital content",
  "Ändern und Verbessern von digitalen Inhalten":
    "Modifying and enhancing digital content",
  "Urheberrecht und Lizenzen": "Copyright and Licensing",
  "Planen und Programmieren von digitalen Technologien":
    "Designing and programming digital technologies",
  "Schützen von Geräten": "Protecting devices",
  "Schützen von persönlichen Daten": "Protecting personal data",
  Gesundheitsschutz: "Health protection",
  Umweltschutz: "Environmental protection",
  "Technische Probleme lösen": "Solving technical problems",
  "Identifizierung von Bedarfen und technischen Lösungen":
    "Identifying needs and technical solutions",
  "Kreative Nutzung digitaler Möglichkeiten":
    "Creative use of digital opportunities",
  "Kompetenzlücken identifizieren": "Identifying competence gaps",

  Linienmanagement: "Line management",
  Produktleitung: "Product Management",
  Projektleitung: "Project management",
  "Forschung und Entwicklung": "Research and development",
  "Verwaltung und Organisation": "Management and organization",
  Vertrieb: "Distribution",
  Sonstige: "Others",

  "Personalabteilung/HR": "Human Resources/HR",
  Kundendienst: "Customer service",
  Produktentwicklung: "Product development",
};

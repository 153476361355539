import { Message } from "../../message-hub/interfaces";
import {
  ActionRule,
  Challenge,
  ChatBotNode,
  TriggerRule,
} from "./interfaces/chatbot";

export const CHATBOT_AUTOMATION_TOOL_START = "chatbot.automation.tool.start";

export const CHATBOT_AUTOMATION_TOOL_END = "chatbot.automation.tool.end";

export const CHATBOT_AUTOMATION_TOOL_CHANGE_STAGE =
  "chatbot.automation.tool.change.stage";

export const CHATBOT_AUTOMATION_TOOL_SET_CHALLENGE =
  "chatbot.automation.tool.challenge.set";

export const CHATBOT_AUTOMATION_TOOL_SET_SUBQUEST_CHALLENGE =
  "chatbot.automation.tool.sub.challenge.set";

export const CHATBOT_AUTOMATION_TOOL_CLEAR_SUBQUEST_CHALLENGE =
  "chatbot.automation.tool.sub.challenge.clear";

export const CHATBOT_AUTOMATION_TOOL_TEST_RULE =
  "chatbot.automation.tool.test.rule";

export const CHATBOT_AUTOMATION_TOOL_SKIP_RULE =
  "chatbot.automation.tool.skip.rule";

export const CHATBOT_AUTOMATION_TOOL_END_QUEST =
  "chatbot.automation.tool.end.quest";

export type chatBotAutomationToolStart = Message<
  typeof CHATBOT_AUTOMATION_TOOL_START
>;

export type chatBotAutomationToolEnd = Message<
  typeof CHATBOT_AUTOMATION_TOOL_END
>;

export type chatBotAutomationToolChangeStage = Message<
  typeof CHATBOT_AUTOMATION_TOOL_CHANGE_STAGE,
  "start" | "game" | "end"
>;

export type chatBotAutomationToolSetChallenge = Message<
  typeof CHATBOT_AUTOMATION_TOOL_SET_CHALLENGE,
  { diagram: ChatBotNode[]; challenge: Challenge }
>;

export type chatBotAutomationToolSetSubQuestChallenge = Message<
  typeof CHATBOT_AUTOMATION_TOOL_SET_SUBQUEST_CHALLENGE,
  {
    rules: [TriggerRule, ActionRule];
    defaultMessage: boolean;
    questId: string;
    diagram?: ChatBotNode[];
  }
>;

export type chatBotAutomationToolSubQuestChallengeClear = Message<
  typeof CHATBOT_AUTOMATION_TOOL_CLEAR_SUBQUEST_CHALLENGE,
  { diagram: ChatBotNode[] }
>;

export type chatBotAutomationToolTestRule = Message<
  typeof CHATBOT_AUTOMATION_TOOL_TEST_RULE,
  { questId: string; subQuestId: string; correct: boolean }
>;

export type chatBotAutomationToolSkipRule = Message<
  typeof CHATBOT_AUTOMATION_TOOL_SKIP_RULE,
  { questId: string; subQuestId: string; correct: boolean }
>;

export type chatBotAutomationToolEndQuest = Message<
  typeof CHATBOT_AUTOMATION_TOOL_END_QUEST,
  {
    id: string;
    failed: boolean;
  }
>;

export const messageTypes = {
  CHATBOT_AUTOMATION_TOOL_START,
  CHATBOT_AUTOMATION_TOOL_END,
  CHATBOT_AUTOMATION_TOOL_CHANGE_STAGE,
  CHATBOT_AUTOMATION_TOOL_SET_CHALLENGE,
  CHATBOT_AUTOMATION_TOOL_SET_SUBQUEST_CHALLENGE,
  CHATBOT_AUTOMATION_TOOL_CLEAR_SUBQUEST_CHALLENGE,
  CHATBOT_AUTOMATION_TOOL_TEST_RULE,
  CHATBOT_AUTOMATION_TOOL_SKIP_RULE,
  CHATBOT_AUTOMATION_TOOL_END_QUEST,
} as const;

export type ChatBotAutomationToolMessages =
  | chatBotAutomationToolStart
  | chatBotAutomationToolEnd
  | chatBotAutomationToolChangeStage
  | chatBotAutomationToolSetChallenge
  | chatBotAutomationToolSetSubQuestChallenge
  | chatBotAutomationToolSubQuestChallengeClear
  | chatBotAutomationToolTestRule
  | chatBotAutomationToolSkipRule
  | chatBotAutomationToolEndQuest;

import { CompetenceLevelText } from "core/enums";
import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "94",
  completed: false,
  index: 8,
  title: "Bürgergeld in der Praxis",
  info: {
    de: `Ab Januar 2023 wird das Bürgergeld eingeführt. Was bedeutet das für meine Beratungsarbeit und für die Leistungsvergabe?`,
    en: `From January 2023, Bürgergeld will be introduced. What does this mean for my counselling work?`,
  },
  topics: [
    {
      competenceAreaId: 1,
      description: {
        de: "Beratung auf Augenhöhe",
        en: "Counselling at eye level",
      },
    },
    {
      competenceAreaId: 2,
      description: {
        de: "Qualifizierung statt Vermittlungsvorrang",
        en: "Qualification instead of placement priority",
      },
    },
    {
      competenceAreaId: 5,
      description: {
        de: "Details der Regelung",
        en: "Bürgergeld procedures",
      },
    },
  ],
  certificates: [
    {
      id: "94",
      name: "Das Bürgergeld in der Praxis",
      subcompetences: [
        1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1109, 1110, 1200,
        1201, 1202, 1300, 1301, 1302,
      ],
      level: CompetenceLevelText.Foundation,
      color: "#08B79C",
      groupName: "buergergeld",
    },
  ],
  seasonEndMessage: "",
  episodes: [
    {
      id: "s94e01",
      testsId: "season94episode1",
      title: "Unter die Lupe genommen",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Dein Kollege Vincent fragt dir zu den neuen Regelungen Löcher in den Bauch. Stillst du seinen Wissensdurst?",
      imageUrl: "/episodes/s94-e01/preview.svg",
      url: "/episodes/s94-e01/",
      load: async () => import(/* webpackChunkName: "s94-e01" */ `../s94-e01`),
    },
    {
      id: "s94e02",
      testsId: "season94episode2",
      title: "Familie Larsson",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Du begleitest Familie Larsson bereits seit ein paar Wochen. Frau und Herr Larsson haben sich einen Beratungstermin bei dir gebucht, da sie wissen wollen wie sich die Änderungen durch das Bürgergeld auf ihre eigene Situations auswirken  wird.  Wie kannst du ihnen helfen?",
      imageUrl: "/episodes/s94-e02/preview.svg",
      url: "/episodes/s94-e02/",
      load: async () => import(/* webpackChunkName: "s94-e02" */ `../s94-e02`),
    },
    {
      id: "s94e03",
      testsId: "season94episode3",
      title: "Ein gutes Verhältnis?",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Bei Familie Larsson geht einiges schief. Auf die Mitarbeiter im Leitungsbereich und Integrationsbereich kommen schwierige Gespräche zu.  Wirst Du es schaffen, eine Lösung zu finden und auf Augenhöhe zu beraten?",
      imageUrl: "/episodes/s94-e03/preview.svg",
      url: "/episodes/s94-e03/",
      load: async () => import(/* webpackChunkName: "s94-e03" */ `../s94-e03`),
    },
    {
      id: "s94e04",
      testsId: "season94episode4",
      title: "So war das nicht geplant!",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Herr Larsson kommt nach dem 01.07.2023 ins Jobcenter zu einem Beratungsgespräch mit Dir. Er hat seinen Mini-Job verloren und möchte nun besprechen, welche neuen Chancen ihm das Bürgergeld liefert. Wirst Du die passende Lösung finden?",
      imageUrl: "/episodes/s94-e04/preview.svg",
      url: "/episodes/s94-e04/",
      load: async () => import(/* webpackChunkName: "s94-e04" */ `../s94-e04`),
    },
    {
      id: "s94e05",
      testsId: "season94episode5",
      title: "Und wieder ein verpasster Termin",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Es ist eine gewisse Zeit vergangen. Herr Larsson kommt wieder zu Dir. Leider hat er einen Termin verpasst und ein Anhörungsverfahren läuft bereits. Findest Du einen passenden Umgang mit ihm?",
      imageUrl: "/episodes/s94-e05/preview.svg",
      url: "/episodes/s94-e05/",
      load: async () => import(/* webpackChunkName: "s94-e05" */ `../s94-e05`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "94" })),
};

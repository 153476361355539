import type { SavegameStorage } from "@talentdigital/kit";
import { Observable } from "rxjs";
import { setGameEngine } from ".";
import { updateApp } from "../core/applications";
import { setSettings } from "../core/hooks";
import { getUser } from "../core/keycloak";
import { EPISODE_COMPLETE, EPISODE_PLAY } from "../core/messages";
import {
  NOTIFICATION_REMOVE,
  NOTIFICATION_TRANSFORM,
} from "../core/notifications/messages";
import { getItem, SAVE_KEY, SEASONS_KEY, setItem } from "../core/storage";
import { getBadges } from "../episodes";
import { getIntl, getLanguage } from "../i18n";
import { default as messageHub, default as MessageHub } from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { MESSAGES_STORAGE_KEY } from "../message-hub/message-hub";
import { ActorStore } from "./actor-store";
import { createApplications } from "./app-store";
import { BadgesEngine, BADGES_STORAGE_KEY } from "./badges-engine";
import { DecisionStore } from "./decision-store";
import { startDecisionUploader } from "./decision-uploader";
import {
  EngagementPointsEngine,
  ENGAGEMENT_STORAGE_KEY,
} from "./engagement-points-engine";
import { EndedEpisodesExternal, Episodes } from "./episodes-loader";
import { Episode } from "./interfaces";
import {
  lastNamedStepKey,
  lastStepKey,
  removeLastStep,
  saveLastStep,
  saveMessages,
  scriptHash,
} from "./persistence";
import { GameRewinder } from "./rewinder";
import { SaveReference, ScriptRunner } from "./script-runner";
import { TestsEngine } from "./tests-engine";
import { VarStore } from "./var-store";

export class GameEngine {
  episodes = new Episodes();
  badgesEngine = new BadgesEngine(getItem(BADGES_STORAGE_KEY), (a) =>
    setItem(BADGES_STORAGE_KEY, a)
  );
  tourTipDone = false;
  tourDone = false;
  testEngine?: TestsEngine;
  engagementEngine!: EngagementPointsEngine;
  private rewinder = new GameRewinder();
  episode?: Episode;
  constructor() {
    setGameEngine(this);
    MessageHub.onSaveMessages(saveMessages);
    this.engagementEngine = new EngagementPointsEngine(
      () => getItem(ENGAGEMENT_STORAGE_KEY),
      (data) => setItem(ENGAGEMENT_STORAGE_KEY, data)
    );
    setSettings();
  }

  save() {
    this.episodes.saveMetaInfo(this.tourDone, this.tourTipDone);
  }

  finishTour() {
    this.tourDone = true;
    this.save();
  }

  finishTourTip() {
    this.tourTipDone = true;
    this.save();
  }

  async init(): Promise<void> {
    const saved = getItem(SAVE_KEY);

    const savegameStorage = getItem(SEASONS_KEY) as SavegameStorage;

    const endedEpisodesExternal: EndedEpisodesExternal = savegameStorage
      ? Object.fromEntries(
          Object.entries(savegameStorage).map(([sid, season]) => [
            sid,
            Object.fromEntries(
              Object.entries(season).map(([eid, { playcount }]) => [
                eid,
                playcount,
              ])
            ),
          ])
        )
      : {};

    this.tourDone = saved.tourDone;
    this.tourTipDone = saved.tourTipDone;
    await this.episodes.init(
      saved.endedEpisodes,
      endedEpisodesExternal,
      saved.episodesPayload,
      getItem("forcedEpisode")
    );
    this.badgesEngine.setBadges(
      await getBadges(...(await this.episodes.getSeasons()).map((s) => s.id))
    );
    MessageHub.init(getItem(MESSAGES_STORAGE_KEY));
  }

  testEngineHasFailedResultUploads(): boolean {
    return (
      this.testEngine !== undefined && this.testEngine.hasFailedResultUploads()
    );
  }

  replacePlaceholders = (text: string, wrapper = (a, ...b) => a): string => {
    throw new Error("Episode has not started");
  };

  beforeStartEpisode = async (episode: Episode) => {
    const playCount = episode.playcount;
    const isStarting = !getItem(lastStepKey(episode.id));
    if (episode.seasonId !== "0" && playCount >= 1 && isStarting) {
      await this.badgesEngine.addBadgeAndWaitForAck("replay1");
    }
  };

  beforeStartExternalEpisode = async (episode: Episode) => {
    const playCount = episode.playcount;
    if (playCount) {
      await this.badgesEngine.addBadgeAndWaitForAck("replay1");
    }
  };

  restartEpisode() {
    return this.runEpisode(this.episode as Episode, true);
  }

  runEpisode(episode: Episode, replay = false): Promise<Observable<Message>> {
    this.exitEpisode();
    this.episode = episode;
    if (getItem("isDemoTenant")) {
      updateApp.call(
        {
          id: "landingPage",
          in: {
            home: true,
          },
          color: "#F0900A",
          icon: "/logos/td-white.png",
          name: getIntl().formatMessage({ id: "button.sales" }),
        },
        {
          externalUrl: "https://talentdigital.eu/demo-success/",
        }
      );
    }
    const { badgesEngine } = this;
    const onMessage = (m) => {
      MessageHub.sendEpisode(m);
      badgesEngine.onMessage(m);
    };

    const {
      testEngine,
      episodeMessagesPromise,
      replacePlaceholders,
      episode$,
    } = runEpisode(episode, replay, episode.playcount);
    this.rewinder.init(episode$);
    this.replacePlaceholders = replacePlaceholders;
    this.testEngine = testEngine;
    this.engagementEngine.setEpisode(episode$);
    return episodeMessagesPromise.then((episode) => {
      episode?.subscribe(onMessage);
      return episode$;
    });
  }

  rewindEpisode(rewindId: string) {
    const [stepId, discardedStepIds] = this.rewinder.getRef(rewindId);
    const e = this.episode as Episode;
    setItem(lastStepKey(e.id), stepId);
    messageHub.removeSavedMessages(
      (m) => typeof m.stepId === "string" && discardedStepIds.has(m.stepId)
    );
    return this.runEpisode(e);
  }

  rewindEpisodeToStep(id: string) {
    const [stepId, discardedStepIds] = this.rewinder.getRewindInfo(id);
    const e = this.episode as Episode;
    setItem(lastStepKey(e.id), stepId);
    messageHub.removeSavedMessages(
      (m) => typeof m.stepId === "string" && discardedStepIds.has(m.stepId)
    );
    return this.runEpisode(e);
  }

  exitEpisode = () => {
    if (this.episode) {
      MessageHub.send({
        type: NOTIFICATION_TRANSFORM,
        payload: undefined,
      });
      MessageHub.send({
        type: NOTIFICATION_REMOVE,
        payload: undefined,
      });
      MessageHub.completeEpisode();
      this.episode = undefined;
    }
  };

  completeEpisode = async () => {
    if (!this.episode) throw new Error("Episode is not running");
    await this.episodes.complete(this.episode.id);
    MessageHub.send({
      type: EPISODE_COMPLETE,
      payload: { id: this.episode.id },
    });
    removeLastStep(this.episode.id);
    this.save();
  };
}

function runEpisode(episode: Episode, replay: boolean, playCount: number) {
  const { id } = episode;
  const saveReference: SaveReference = {
    precise: getItem(lastStepKey(id)),
    named: getItem(lastNamedStepKey(id)),
    hash: getItem(scriptHash(id)),
  };
  if (
    episode.completed &&
    (!saveReference.precise || !saveReference.named || !saveReference.hash)
  ) {
    const msg = getItem(MESSAGES_STORAGE_KEY).filter((m) => m.episode !== id);
    setItem(MESSAGES_STORAGE_KEY, msg);
    replay = true;
  }

  if (replay) {
    saveReference.precise = "";
    saveReference.named = "";
  }

  const episode$ = MessageHub.startEpisode(id, replay);

  MessageHub.send({
    type: EPISODE_PLAY,
    payload: { id, playCount },
  });

  const testEngine = new TestsEngine(episode$, episode.seasonId, episode.id);
  const vars = new VarStore(episode$);
  const decisions = new DecisionStore(episode$);
  const actors = new ActorStore(episode$);
  const isReplay = playCount > 0;
  const episodeMessagesPromise = episode
    .load()
    .then(({ default: episodeScript }) => {
      const runner = new ScriptRunner(
        episode$,
        episodeScript,
        getIntl(),
        testEngine,
        vars,
        decisions,
        saveReference,
        getLanguage(),
        isReplay,
        episode.payload
      );
      (window as any).runner = runner;
      return runner.runScript();
    })
    .catch((err) => {
      if (err.name === "ChunkLoadError") {
        if (!window.location.search.includes("reload")) {
          window.location.search += window.location.search.includes("?")
            ? "&reload"
            : "?reload";
        } else {
          console.error("Error while loading episode", err.name, err.message);
          alert("The episode could not be loaded.");
        }
      }
    });
  const replacePlaceholders = createReplacePlaceholders(
    actors,
    decisions,
    vars
  );
  createApplications(episode$);
  setSettings();
  if (episode.seasonId) saveLastStep(episode$);
  if (episode.seasonId)
    startDecisionUploader(episode$, episode.seasonId, episode.id);

  return {
    episode$,
    replacePlaceholders,
    episodeMessagesPromise,
    testEngine,
  };
}

function createReplacePlaceholders(
  actors: ActorStore,
  decisions: DecisionStore,
  vars: VarStore
) {
  const regex = /{{([^}]+)}}/g;
  const regexEntities = /%7B%7B([^%]+)%7D%7D/g;
  const replaceSetting = (id) => {
    const settings = getItem("SETTINGS") || {};
    return settings[id];
  };
  const replaceUser = (prop: string) => {
    const u = getUser();
    return u?.[prop] || prop;
  };

  const methods = {
    actor: actors.replaceActor,
    mention: actors.replaceActor,
    settings: replaceSetting,
    setting: replaceSetting,
    decision: decisions.replaceDecision,
    multidecision: decisions.replaceMultipleDecision,
    var: vars.replaceVar,
    user: replaceUser,
  };
  return (text: string, wrapper = (a, ...b) => a) => {
    if (typeof text !== "string") {
      return text;
    }

    const replacer = (placeholder, text) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // eslint-disable-next-line prefer-const
      const [cmd, ...args] = text.split(/[:\.]/).map((s) => s.trim());
      const command = cmd.toLowerCase();
      if (methods[command]) {
        return wrapper(methods[command](...args), command, args);
      }

      return placeholder;
    };
    return text.replace(regex, replacer).replace(regexEntities, replacer);
  };
}

import { Observable } from "rxjs";
import { scan, shareReplay, startWith } from "rxjs/operators";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import {
  Actor,
  RunAnimationPayload,
  State,
  StopAnimationPayload,
} from "./interfaces";
import {
  ACTOR_ADD,
  ACTOR_CHANGE,
  ACTOR_REMOVE,
  ANIMATION_RUN,
  ANIMATION_STOP,
  APP_CHANGE,
  APP_LOCATION,
  APP_REMOVE,
  EPISODE_END,
  GAME_POINTS_SCALE,
  HIDE_APPS,
  NAVIGATION_URL,
  NOTIFICATION_REMOVE,
  PASS_TIME,
  PASS_TIME_FINISH,
  SHOW_APPS,
  TOUR_FINISH,
  TOUR_RUN,
  TOUR_SCREEN_CHANGE,
  VIDEO_FINISH,
  VIDEO_PLAY,
} from "./messages";
import { Notification } from "./notifications/interfaces";
import { NOTIFICATION_TRANSFORM } from "./notifications/messages";

export class AppLauncher {
  static instance: AppLauncher;

  initialState: State = {
    apps: {},
    actors: {},
    notifications: [],
    animation: {
      run: false,
      animation: undefined,
      duration: 0,
      zIndex: 0,
    },
    timePassing: {
      state: false,
    },
    tour: {
      run: false,
      screen: 1,
    },
    video: {
      state: false,
      src: "",
    },
    showApps: true,
    levelEnd: false,
  };

  state: Observable<State>;

  private get currentState(): State {
    let state!: State;
    this.state.subscribe((s) => (state = s)).unsubscribe();
    return state;
  }

  constructor(messages: Observable<Message> = MessageHub.subject) {
    AppLauncher.instance = this;
    MessageHub.send({
      type: NOTIFICATION_TRANSFORM,
      payload: this.addNotificationInfo,
    });
    this.state = messages.pipe(
      scan(this.reducer, this.initialState),
      startWith(this.initialState),
      shareReplay(1)
    );
  }

  private reducer = (state: State, msg: Message) => {
    switch (msg.type) {
      case APP_CHANGE:
        return this.onAppChange(state, msg.payload);
      case APP_REMOVE:
        return this.onAppRemove(state, msg.payload);
      case ACTOR_ADD:
        return this.onActorChange(state, msg.payload);
      case ACTOR_CHANGE:
        return this.onActorChange(state, msg.payload);
      case ACTOR_REMOVE:
        return this.onActorRemove(state, msg.payload);
      case NAVIGATION_URL:
        if (msg.payload.url) setTimeout(this.resetGotoUrl, 1000);
        return { ...state, gotoUrl: msg.payload.url };
      case EPISODE_END:
        return { ...state, levelEnd: true };
      case APP_LOCATION:
        return {
          ...state,
          background: msg.payload.background,
        };
      case GAME_POINTS_SCALE:
        return { ...state, level: msg.payload.level };
      case ANIMATION_RUN:
        return {
          ...state,
          animation: {
            run: msg.payload.run,
            animation: msg.payload.animation,
            duration: msg.payload.duration,
            zIndex: msg.payload.zIndex,
          },
        };
      case ANIMATION_STOP:
        return { ...state, animation: { run: msg.payload.run } };
      case PASS_TIME:
        return {
          ...state,
          timePassing: {
            state: true,
            duration: msg.payload.duration || null,
            keepRoute: msg.payload.keepRoute,
          },
        };
      case PASS_TIME_FINISH:
        return {
          ...state,
          timePassing: {
            state: false,
            duration: null,
          },
        };
      case VIDEO_PLAY:
        return {
          ...state,
          video: {
            state: true,
            src: msg.payload.src,
          },
        };
      case VIDEO_FINISH:
        return {
          ...state,
          video: {
            state: false,
            src: "",
          },
        };
      case TOUR_RUN:
        return {
          ...state,
          tour: {
            ...state.tour,
            run: msg.payload,
          },
        };
      case TOUR_FINISH:
        return {
          ...state,
          tour: {
            ...state.tour,
            ...msg.payload,
          },
        };
      case TOUR_SCREEN_CHANGE:
        return {
          ...state,
          tour: {
            ...state.tour,
            screen: msg.payload,
          },
        };
      case HIDE_APPS: {
        return {
          ...state,
          showApps: false,
        };
      }
      case SHOW_APPS: {
        return {
          ...state,
          showApps: true,
        };
      }
      default:
        return state;
    }
  };

  private resetGotoUrl() {
    MessageHub.send({
      type: NAVIGATION_URL,
      payload: {},
    });
  }

  private onAppChange(state, app) {
    let { apps } = state;
    apps = { ...apps, [app.id]: { ...(apps[app.id] || {}), ...app } };
    return { ...state, apps };
  }

  private onAppRemove(state, app) {
    const apps = { ...state.apps };
    delete apps[app.id];
    return { ...state, apps };
  }

  private onActorChange(state, actor) {
    let { actors } = state;
    const current = actors[actor.id] || {
      avatarUrl: `/actors/${actor.id}.svg`,
      sprites: {
        face: `/actors/${actor.id}.svg`,
        angry: `/actors/${actor.id}_angry.svg`,
        excited: `/actors/${actor.id}_excited.svg`,
        happy: `/actors/${actor.id}_happy.svg`,
        neutral: `/actors/${actor.id}_neutral.svg`,
        reflective: `/actors/${actor.id}_reflective.svg`,
        shocked: `/actors/${actor.id}_shocked.svg`,
        tired: `/actors/${actor.id}_tired.svg`,
        shy: `/actors/${actor.id}_shy.svg`,
        sad: `/actors/${actor.id}_sad.svg`,
      },
      backgroundUrl: `/actors/backgrounds/${actor.background}.svg`,
    };
    actors = { ...actors, [actor.id]: { ...current, ...actor } };
    return { ...state, actors };
  }

  private onActorRemove(state, actor) {
    const actors = { ...state.actors };
    delete actors[actor.id];
    return { ...state, actors };
  }

  private addNotificationInfo = (notification: Notification): Notification => {
    if (notification.appId) {
      const { apps } = this.currentState;
      const app = apps[notification.appId];
      if (app) {
        notification.topIcon = app.icon;
        notification.topBackgroundColor = app.color;
        notification.backgroundColor = app.notificationStyles?.backgroundColor;
        notification.buttonColor = app.notificationStyles?.buttonColor;
        notification.textColor = app.notificationStyles?.textColor;
      }
    }
    if (notification.actor && !notification.icon) {
      const { actors } = this.currentState;
      const actorIds = Array.isArray(notification.actor)
        ? notification.actor
        : [notification.actor];
      const icons: string[] = [];
      for (const id of actorIds) {
        const actor: Actor = actors[id];
        if (actor && actor.avatarUrl) icons.push(actor.avatarUrl);
      }
      notification.icon = icons;
    }
    return notification;
  };

  removeNotification = (payload) => {
    MessageHub.send({
      type: NOTIFICATION_REMOVE,
      payload,
    });
  };

  onRunAnimation = (payload: RunAnimationPayload) => {
    MessageHub.send({
      type: ANIMATION_RUN,
      payload,
    });
  };

  onStopAnimation = (payload: StopAnimationPayload) => {
    MessageHub.send({
      type: ANIMATION_STOP,
      payload,
    });
  };
}

import { Step } from "../../components/dashboard-tour";

export const DASHBOARD_TOUR_COMPLETE_STORAGE_KEY = "DASHBOARD_TOUR_COMPLETE";

const tourData = [
  {
    dataAttr: "episode-item",
    textId: "dashboard.tour.1",
    position: "top-left",
    mobilePosition: "top-left",
    scrollToTop: true,
  },
  {
    dataAttr: "report-competencies-tab",
    textId: "dashboard.tour.2",
    position: "top-right",
    mobilePosition: "bottom-left",
    timeout: 300,
  },
  {
    dataAttr: "report-badges-tab",
    textId: "dashboard.tour.3",
    position: "top-left",
    mobilePosition: "bottom-center",
    timeout: 300,
  },
  {
    dataAttr: "report-take-aways-tab",
    textId: "dashboard.tour.4",
    position: "top-right",
    mobilePosition: "bottom-right",
    timeout: 300,
  },
  {
    dataAttr: "episodes-list-back-button",
    textId: "dashboard.tour.5",
    position: "bottom-right",
    mobilePosition: "bottom-left",
    scrollToTop: true,
  },
  {
    dataAttr: "feedback-button",
    textId: "dashboard.tour.6",
    position: "top-right",
    mobilePosition: "top-right",
  },
] as Step[];

export default tourData;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type StylesProps = {
  color: string | undefined;
  height?: string | number;
  border: string | undefined;
};

const styles = makeStyles((theme: Theme) => ({
  root: {
    background: ({ color }: StylesProps) => color || "white",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  rounded: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,

    [theme.breakpoints.down("md")]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  splashScreenContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    zIndex: theme.custom.zIndex.splash,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
  },
  splashScreenSearchBar: {
    position: "absolute",
    zIndex: 1,
    width: "60vw",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  splashModal: {
    marginLeft: "20vw",
    marginTop: ({ height }: StylesProps) => (height ? "20vh" : "10vh"),
    width: "60vw",
    height: ({ height }: StylesProps) => height || "80vh",
  },
  splashPanel: {
    right: 0,
    bottom: 0,
    marginRight: "10vw",
    width: "400px",
    height: "55vh",
    position: "absolute",
  },
  splashMobile: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  modalView: {
    position: "absolute",
    marginLeft: "20vw",
    marginTop: ({ height }: StylesProps) => (height ? "20vh" : "10vh"),
    width: "60vw",
    height: ({ height }: StylesProps) => height || "80vh",
    top: 0,
    left: 0,
    border: "none",
  },
  panelView: {
    position: "absolute",
    right: 0,
    bottom: 0,
    marginRight: "10vw",
    width: "400px",
    height: "65vh",
  },
  dialogView: {
    position: "absolute",
    margin: "20vh 4vw 0",
    width: "92vw",
    height: "75vh",
    top: 0,
    left: 0,
    borderRadius: "20px !important",
    border: "none",
  },
  content: {
    overflow: "auto",
    flex: 1,
    backgroundColor: (props: any) => props.color || "",
    borderLeft: (props: any) => props.border || "none",
    borderRight: (props: any) => props.border || "none",
    borderBottom: (props: any) => props.border || "none",
  },
  footer: {
    top: "auto",
    left: 0,
    right: 0,
    bottom: 0,
    height: 60,
    backgroundColor: (props: any) => props.color || "white",
  },
  homeButton: {
    color: "white",
    transform: "scale(1.4)",
    padding: 6,
    "& img": {
      width: 30,
      height: 30,
    },
  },
  contentBottomBox: {
    width: "100%",
    height: 1,
    marginBottom: 60,
  },
  browserToolbar: {
    backgroundColor: "transparent",
    maxHeight: 40,
    minHeight: 40,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
  },
  browserToolbarExitButton: {
    paddingRight: 12,
    color: "black",
  },
  browserToolbarExitButtonAbsolute: {
    color: "white",
    width: 48,
    height: 48,
  },
  browserToolbarExitButtonAbsoluteWrapper: {
    position: "absolute",
    display: "flex",
    top: 0,
    right: 6,
    zIndex: 2,
    height: 64,
    alignItems: "center",
  },
  browserAppBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  sfBrowserAppBar: {
    boxShadow: "none",
    minHeight: 60,
  },
  panelViewAnimation: {
    animation: "$showPanel 600ms linear forwards",
  },

  "@keyframes showPanel": {
    from: {
      transform: "translateY(100%)",
    },
    to: {
      transform: "translateY(0)",
    },
  },
}));

export default styles;

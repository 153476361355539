import { Message } from "../../message-hub/interfaces";
import savedTypes from "./saved-message-types";

export const updateForSeasons = (state: any) => {
  const version = state.version || 1;
  if (version > 1) return state;
  const info = state.GameEngineMetaInfo || {};

  if (info.endedEpisodes && info.endedEpisodes.length) {
    info.endedEpisodes = info.endedEpisodes.map((e) =>
      /^\d$/.test(e) ? `s01e0${e}` : e
    );
  }

  if (info.tour === false && info.startPage === false) {
    info.tourDone = true;
  }

  const messages: Message[] = [];
  (state.messages || []).forEach((m) => {
    if (!savedTypes.has(m.type)) return;
    if (m.episode && /^\d$/.test(m.episode)) m.episode = `s01e0${m.episode}`;
    if (info.endedEpisodes.includes(m.episode)) messages.push(m);
  });
  state.messages = messages;

  const lastStepExp = /^lastStep\.(\d)$/;
  Object.keys(state).forEach((k) => {
    if (lastStepExp.test(k)) {
      const [, episodeNr] = k.match(lastStepExp) as [string, string];
      const key = `lastStep.s01e0${episodeNr}`;
      if (info.endedEpisodes.includes(`s01e0${episodeNr}`)) {
        state[key] = state[k];
      }
      delete state[k];
    }
  });

  state.version = 2;
  return state;
};

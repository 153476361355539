import { LocalFile } from "./interfaces/local-file.interface";
import { Attachment, MessageMail } from "./interfaces/mail.interface";

export const MAIL_SEND = "mail.send";
export const MAIL_SELECT = "mail.select";
export const MAIL_ATTACHMENT_OPEN = "mail.attachment.open";
export const MAIL_FILE_ADD = "mail.file.add";
export const MAIL_FILE_REMOVE = "mail.file.remove";
export const MAIL_OPENED = "mail.opened";

export interface SendEmail {
  type: typeof MAIL_SEND;
  payload: MessageMail;
}

export interface SelectMail {
  type: typeof MAIL_SELECT;
  payload: {
    id: string;
  };
}

export interface OpenAttachment {
  type: typeof MAIL_ATTACHMENT_OPEN;
  payload: { mail: MessageMail; attachment: Attachment };
}

export interface AddLocalFile {
  type: typeof MAIL_FILE_ADD;
  payload: LocalFile;
}

export interface RemoveLocalFile {
  type: typeof MAIL_FILE_REMOVE;
  payload: string;
}

export interface MailOpened {
  type: typeof MAIL_OPENED;
  payload: { mailId: MessageMail["id"]; attachmentId?: string };
}

export type MailboxMessage =
  | SendEmail
  | SelectMail
  | OpenAttachment
  | AddLocalFile
  | RemoveLocalFile
  | MailOpened;

import { Box, Typography } from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import { useAppState } from "core/applications";
import { createRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import CoffeeGameApp, { State } from "..";
import CoffeeImage from "../assets/coffee.svg";

const SYNCH_WITH_ANIMATION = 1100;

export const CoffeeGame = () => {
  const audioRef = createRef<HTMLAudioElement>();
  const { score, visible, showLocation } = useAppState<State>(
    CoffeeGameApp.instance
  );
  const match = useRouteMatch(showLocation);

  useEffect(() => {
    if (score === 0) return;

    const timeout = setTimeout(() => {
      if (audioRef.current) {
        audioRef.current.volume = 0.6;
        audioRef.current.play();
      }
    }, SYNCH_WITH_ANIMATION);

    return () => clearTimeout(timeout);
  }, [score]);

  if (!match || !visible) return null;

  return createPortal(
    <StyledContentBox>
      <audio ref={audioRef} src="/episodes/s93-e01/sounds/coffee_pour.mp3" />
      <StyledCoffeeContainer>
        {score === 0 ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontSize={20} sx={{ opacity: 0.5, marginRight: 1 }}>
              <FormattedMessage id="coffeeGameApp.noScore" />
            </Typography>
            <StyledCoffee
              src={CoffeeImage}
              alt="Coffee"
              sx={{ opacity: 0.3, filter: "grayscale(1)" }}
            />
          </Box>
        ) : (
          Array(score)
            .fill("")
            .map((_, index) => (
              <Box
                key={index}
                className="animate__animated animate__rotateInDownRight"
              >
                <StyledCoffeeSteam />
                <StyledCoffeePour />
                <StyledCoffee src={CoffeeImage} alt="Coffee" />
              </Box>
            ))
        )}
      </StyledCoffeeContainer>
    </StyledContentBox>,
    document.body
  );
};

const StyledCoffeeContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: "flex",
  gap: theme.spacing(1),
  width: "40%",
  flexDirection: "row-reverse",
  flexWrap: "wrap",
  alignItems: "flex-end",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    maxHeight: "320px",
  },

  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(7),
  },
}));

const StyledCoffee = styled("img")(() => ({
  width: "55px",
}));

const StyledCoffeeSteam = styled("div")(() => ({
  width: "25px",
  height: "25px",
  background: "#fff",
  position: "absolute",
  left: "15px",
  top: 0,
  filter: "blur(6px)",
  opacity: 0,
  zIndex: 2,
  animationName: steamVanish,
  animationDuration: "3s",
  animationDelay: "1.4s",
  animationFillMode: "forwards",
  animationTimingFunction: "ease-out",
}));

const steamVanish = keyframes`
  0% {
    top: 0;
    left: 15px;
    opacity: 0;
    width: 25px;
    height: 25px;
  }
  15% {
    opacity: 1;
  }
  40% {
    opacity: 0.7
  }
  100% {
    top: -35px;
    left: 3px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
`;

const StyledCoffeePour = styled("div")(() => ({
  top: "-16px",
  width: "4px",
  height: 0,
  background: "#9b6a47",
  marginLeft: "25px",
  position: "absolute",
  opacity: 0.8,
  zIndex: 1,
  borderRadius: "2px",
  animationName: pourCoffee,
  animationDuration: "1s",
  animationDelay: "1s",
  animationFillMode: "forwards",
  animationTimingFunction: "cubic-bezier(0.35, 0, 0.25, 1)",
}));

const pourCoffee = keyframes`
  0% {
    height: 0;
  }
  50% {
    height: 38px;
    top: -16px;
  }
  100% {
    height: 0;
    top: 19px;
  }
`;

const StyledContentBox = styled("div")(({ theme }) => ({
  width: "60vw",
  margin: "10vh 20vw 0",
  position: "absolute",

  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    margin: 0,
  },
}));

import { Grid, Skeleton } from "@mui/material";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DepartmentGroup, Tag, Tags, UserProfile } from "../interfaces";
import {
  getAllProfileTags,
  getGroups,
  getParentGroups,
  getProfileData,
} from "../services";
import TagSelect from "./select";
import InformSnackbar from "./snackbar";

type PropsType = {
  language: string;
  onSelectTags: (type: string, id: number) => void;
  onSelectDepartments: (id?: number) => void;
  onLoadEnded?: () => void;
};

const PlayerProfile: React.FC<PropsType> = (props: PropsType) => {
  const { onSelectTags, language, onSelectDepartments, onLoadEnded } = props;
  const [tags, setTags] = useState<Tags>({});
  const [tagValues, setTagValue] = useState<Record<Tag["type"], Tag["id"]>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [displayScreen, setDisplayScreen] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const intl = useIntl();
  const [parentGroups, setParentGroups] = useState<DepartmentGroup[]>([]);
  const [parentGroupId, setParentGroupId] = useState<number | undefined>();
  const [groups, setGroups] = useState<DepartmentGroup[]>([]);
  const [groupId, setGroupId] = useState<number | undefined>();

  const updateTag = (type, id) => {
    setTagValue((v) => ({
      ...v,
      [type]: id,
    }));
    onSelectTags(type, id);
  };

  const onChangeParentGroup = (id: number) => {
    setParentGroupId(id);
    onChangeGroup();
  };

  const onChangeGroup = (id?: number) => {
    setGroupId(id);
    onSelectDepartments(id);
  };

  const onLoadData = ([profile, allTags, groups]: [
    UserProfile,
    Tag[],
    DepartmentGroup[]
  ]) => {
    if (profile) {
      const tagValues = {};
      for (const t of profile.tagItems) {
        tagValues[t.type] = t.id;
      }
      setTagValue(tagValues);
      onChangeGroup(profile.talentGroup?.id ?? 0);
      setParentGroupId(profile.talentGroup?.talentGroupParentId ?? 0);
    }

    const tags = {};
    for (const t of allTags) {
      tags[t.type] = tags[t.type] || [];
      tags[t.type].push(t);
    }

    setTags(tags);
    setParentGroups(sortBy(groups, (g) => g.name));

    if (groups?.length === 1) {
      setParentGroupId(groups[0]?.id);
    }
  };

  useEffect(() => {
    let isMounted = true;
    Promise.all([getProfileData(), getAllProfileTags(), getParentGroups()])
      .then((r) => {
        if (isMounted) onLoadData(r);
      })
      .catch((e) => setIsSnackBarOpen(true))
      .finally(() => {
        if (isMounted) setIsLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (typeof parentGroupId === "number") {
      getGroups(parentGroupId).then((groups) => {
        if (isMounted) setGroups(sortBy(groups, (g) => g.name));
      });
    }
    return () => {
      isMounted = false;
    };
  }, [parentGroupId]);

  useEffect(() => {
    if (!isLoading && groups.length > 0) {
      setDisplayScreen(true);
      if (onLoadEnded) onLoadEnded();
    }
  }, [isLoading, groups.length]);

  return (
    <>
      {displayScreen ? (
        <Grid container spacing={3}>
          {parentGroups.length > 1 && (
            <TagSelect
              label="Abteilung"
              data={parentGroups}
              value={parentGroupId}
              selectCallback={onChangeParentGroup}
              language={language}
              private={true}
            />
          )}
          {typeof parentGroupId === "number" && (
            <TagSelect
              label="Team"
              data={groups}
              value={groupId}
              selectCallback={onChangeGroup}
              language={language}
              private={true}
            />
          )}
          {Object.entries(tags).map(([type, value]: [string, Tag[]]) => (
            <TagSelect
              key={type}
              label={type}
              data={value}
              value={tagValues[type]}
              selectCallback={(id) => updateTag(type, id)}
              language={language}
              private={true}
            />
          ))}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {Array.from({ length: 9 }).map((_, i) => (
            <Grid key={i} item xs={12} md={6}>
              <Skeleton variant="rectangular" width={"100%"} height={56} />
            </Grid>
          ))}
        </Grid>
      )}
      <InformSnackbar
        open={isSnackBarOpen}
        message={intl.messages["errorMessage"] as string}
        onClose={() => setIsSnackBarOpen(false)}
      />
    </>
  );
};

export default PlayerProfile;

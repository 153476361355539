import { IEventTemplate } from "./interfaces/event-template.interface";
import { IScheduledEvent } from "./interfaces/scheduled-event.interface";
import { ITeam } from "./interfaces/team.interface";

export const MEETINGS_TOOL_ADD_EVENT_TEMPLATE =
  "meetings.tool.add.event.template";
export const MEETINGS_TOOL_EDIT_EVENT_TEMPLATE =
  "meetings.tool.edit.event.template";
export const MEETINGS_TOOL_REMOVE_EVENT_TEMPLATE =
  "meetings.tool.remove.event.template";
export const MEETINGS_TOOL_ADD_SCHEDULED_EVENT =
  "meetings.tool.add.scheduled.event";
export const MEETINGS_TOOL_CANCEL_SCHEDULED_EVENT =
  "meetings.tool.cancel.scheduled.event";
export const MEETINGS_TOOL_ADD_TEAM = "meetings.tool.add.team";
export const MEETINGS_TOOL_REMOVE_TEAM = "meetings.tool.remove.team";
export const MEETINGS_TOOL_SYNCHRONIZE_APP = "meetings.tool.synchronize.app";
export const MEETINGS_TOOL_SET_TASK = "meetings.tool.set.task";

export interface AddEventTemplate {
  type: typeof MEETINGS_TOOL_ADD_EVENT_TEMPLATE;
  payload: IEventTemplate;
}

export interface EditEventTemplate {
  type: typeof MEETINGS_TOOL_EDIT_EVENT_TEMPLATE;
  payload: IEventTemplate;
}

export interface RemoveEventTemplate {
  type: typeof MEETINGS_TOOL_REMOVE_EVENT_TEMPLATE;
  payload: {
    id: string;
  };
}

export interface AddScheduledEvent {
  type: typeof MEETINGS_TOOL_ADD_SCHEDULED_EVENT;
  payload: IScheduledEvent;
}

export interface CancelScheduledEvent {
  type: typeof MEETINGS_TOOL_CANCEL_SCHEDULED_EVENT;
  payload: {
    id: string;
  };
}

export interface AddTeam {
  type: typeof MEETINGS_TOOL_ADD_TEAM;
  payload: ITeam;
}

export interface RemoveTeam {
  type: typeof MEETINGS_TOOL_REMOVE_TEAM;
  payload: {
    id: string;
  };
}

export interface SynchronizeApp {
  type: typeof MEETINGS_TOOL_SYNCHRONIZE_APP;
  payload: {
    app: string;
  };
}

export interface SetTask {
  type: typeof MEETINGS_TOOL_SET_TASK;
  payload: {
    task: number;
  };
}

export type MeetingsToolMessages =
  | AddEventTemplate
  | EditEventTemplate
  | RemoveEventTemplate
  | AddScheduledEvent
  | CancelScheduledEvent
  | AddTeam
  | RemoveTeam
  | SynchronizeApp
  | SetTask;

export const messageTypes = {
  MEETINGS_TOOL_ADD_EVENT_TEMPLATE,
  MEETINGS_TOOL_EDIT_EVENT_TEMPLATE,
  MEETINGS_TOOL_REMOVE_EVENT_TEMPLATE,
  MEETINGS_TOOL_ADD_SCHEDULED_EVENT,
  MEETINGS_TOOL_CANCEL_SCHEDULED_EVENT,
  MEETINGS_TOOL_ADD_TEAM,
  MEETINGS_TOOL_REMOVE_TEAM,
  MEETINGS_TOOL_SYNCHRONIZE_APP,
  MEETINGS_TOOL_SET_TASK,
} as const;

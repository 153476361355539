import { noop } from "lodash";
import type { IntlFormatters } from "react-intl";
import {
  concat,
  connectable,
  defer,
  EMPTY,
  isObservable,
  merge,
  NEVER,
  Observable,
  of,
  OperatorFunction,
  Subject,
} from "rxjs";
import {
  concatMap,
  endWith,
  filter,
  find,
  map,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { h32 } from "xxhashjs";
import appModules from "../apps/app-modules";
import { Room } from "../apps/chat/interfaces";
import { ChatMessageLink } from "../apps/chat/interfaces/chat-message-link";
import { CHAT_MESSAGE_SEND, CHAT_ROOM_ADD } from "../apps/chat/messages";
import { MAIL_SEND } from "../apps/mailbox/messages";
import { VIDEO_CALL_ACTOR_SAY } from "../apps/video-call/messages";
import messages from "../core/all-messages";
import { DecisionOption } from "../core/decision/interfaces/decision-option";
import { DecisionPayload } from "../core/decision/interfaces/decision-payload";
import { DECISION_ADD, DECISION_CHOOSE } from "../core/decision/messages";
import { DIRECT_COMMUNICATION_SEND } from "../core/direct-communication/messages";
import { Actor } from "../core/interfaces";
import {
  ACTOR_ADD,
  APP_ADD,
  EPISODE_END,
  GAME_BADGE_ADD,
  GAME_CURRENT_QUEST,
  GAME_LEVEL_PROGRESS,
  GAME_POINTS_ADD,
  GAME_STEP_ADD,
  MAIN_WAIT,
  NOTIFICATION_ADD,
  PASS_TIME,
  TEST_COMPLETE,
  TEST_STOP,
  VIDEO_PLAY,
} from "../core/messages";
import { HASH_SALT } from "../core/utils";
import type { EventualMessage, Message } from "../message-hub/interfaces";
import type { DecisionStore } from "./decision-store";
import {
  EpisodeScript,
  ExecArguments,
  StepNode,
  TestOutcome,
} from "./interfaces";
import {
  SCRIPT_COMPLETE,
  SCRIPT_HASH,
  SCRIPT_QUESTS,
  SCRIPT_REPLAY_DONE,
  SCRIPT_STEPS,
  STEP_DONE,
  STEP_MARKER,
} from "./messages";
import {
  handleMessageReplay,
  handleSpecialMessages,
} from "./messages-to-observable";
import { TestsEngine } from "./tests-engine";
import type { VarStore } from "./var-store";

export interface Quest {
  title: string;
  description: string;
  lastStep: StepNode;
}

export type SaveReference = {
  precise: string;
  named: string;
  hash: string;
};

export class ScriptRunner {
  private ctxQuest: any = { title: "" };
  private ctxActor = "";
  private ctxApp = "";
  private quests: any[] = [];
  private leadingMessages: Message[] = [];
  private steps: StepNode[] = [];
  private tests: Record<string, any> = {};
  stepCondition?: (data: ExecArguments) => boolean;
  lastDecisionId = "";
  private triggerBlockMessages: Subject<Observable<Message>> = new Subject();
  private running!: Promise<any>;
  private testResults: Record<string, TestOutcome> = {};

  messagesTypes = messages;
  private msgsRealtime!: Observable<Message>;
  private handleSpecialMessages: () => OperatorFunction<Message, Message>;
  private mode: "replay" | "running" = "replay";

  constructor(
    private msgsAll: Observable<Message>,
    episode: EpisodeScript,
    public intl: Pick<IntlFormatters, "formatMessage">,
    private testsEngine: Pick<TestsEngine, "startTest" | "testResults">,
    private vars: Pick<VarStore, "state"> = { state: {} },
    private decisions: Pick<DecisionStore, "decisions"> = { decisions: {} },
    private saveReference: SaveReference = { precise: "", named: "", hash: "" },
    public language: string = "de",
    public replay: boolean = false,
    public episodePayload?: any
  ) {
    const realtime = connectable(msgsAll);
    this.msgsRealtime = realtime;
    this.running = episode(this)
      .then(this.setRunHead)
      .catch((e) => {
        console.error("Script error", e);
      });
    const handleMessagesRunning = concatMap(
      handleSpecialMessages(this.msgsRealtime)
    );
    this.handleSpecialMessages = () =>
      this.mode === "running" ? handleMessagesRunning : handleMessageReplay;
    realtime.connect();
  }

  actors(...actors: Partial<Actor>[]) {
    actors.forEach((payload) =>
      this.addLeadingMessage({
        type: ACTOR_ADD,
        payload,
      })
    );
  }

  applications(...applications: (keyof typeof appModules)[]) {
    applications.forEach((name) =>
      this.addLeadingMessage({
        type: APP_ADD,
        payload: { name },
      })
    );
  }

  private setRunHead = () => {
    let stepIds = "";
    for (const step of this.steps) {
      if ("id" in step) stepIds += step.id;
    }
    const newHash = h32(stepIds, HASH_SALT).toString(16);
    this.leadingMessages.push(
      {
        type: SCRIPT_HASH,
        payload: newHash,
      },
      { type: SCRIPT_STEPS, payload: this.steps.map((s) => s.id) },
      {
        type: SCRIPT_QUESTS,
        payload: this.quests.map(({ title, description }) => ({
          id: title,
          description,
        })),
      }
    );
    const { hash, precise, named } = this.saveReference;
    const savePoint = newHash === hash ? precise : named;
    if (savePoint) {
      const replayTailIndex = this.steps.findIndex((s) => s.id === savePoint);
      this.steps[replayTailIndex + 1].isRunHead = true;
    } else {
      this.steps[0].isRunHead = true;
    }
  };

  async getStepNodes() {
    await this.running;
    return this.steps;
  }

  async runScript(): Promise<Observable<Message>> {
    await this.running;
    this.addStep = this.addStepWhileRunning;
    this.marker = noop;
    this.quest = () => this;
    this.when = this.whenever;

    const stepNodes = of(...this.steps).pipe(
      concatMap((step) =>
        defer(() =>
          this.stepToObservable(step).pipe(this.addProgressControl(step))
        )
      ),
      endWith({ type: SCRIPT_COMPLETE, payload: undefined }),
      tap((m) => {
        if (m?.type === SCRIPT_COMPLETE) this.triggerBlockMessages.complete();
      })
    );
    const userExits$ = this.msgsAll.pipe(find(() => false));

    return (
      concat(
        of(...this.leadingMessages),
        merge(this.triggerBlockMessages.pipe(concatMap((o) => o)), stepNodes),
        of(
          {
            type: GAME_LEVEL_PROGRESS,
            payload: {
              total: this.steps.length,
              current: this.steps.length,
              progress: 1,
            },
          },
          { type: EPISODE_END }
        )
      ) as Observable<Message>
    ).pipe(takeUntil(userExits$));
  }

  addStep(step: StepNode["value"] | Message, customId?: string) {
    const id = customId || `node.main.${this.steps.length}`;
    const node = {
      index: this.steps.length,
      id,
      value: typeof step === "function" ? step : () => step,
      quest: this.ctxQuest.title,
      condition: this.stepCondition,
    };
    this.steps.push(node);

    this.ctxQuest.lastStep = node;
  }

  private addStepWhileRunning(
    value: StepNode["value"] | Message,
    ignoredCustomId?: string
  ) {
    const fn = typeof value === "function" ? value : () => value;
    this.triggerBlockMessages.next(defer(() => this.valueToObservable(fn)));
  }

  private blockArguments = () => ({
    msgsAll: this.msgsAll,
    msgsRealtime: this.mode === "replay" ? this.msgsAll : this.msgsRealtime,
    decisions: this.decisions.decisions,
    vars: this.vars.state,
    testResults: this.testsEngine.testResults,
  });

  private valueToObservable(value: StepNode["value"]): Observable<Message> {
    let output: EventualMessage = value(this.blockArguments());
    if (!isObservable(output)) {
      output = Array.isArray(output) ? of(...output) : of(output);
    }
    return output.pipe(this.handleSpecialMessages());
  }

  private flipMode(step: StepNode) {
    if (this.mode === "replay") {
      if (step.isRunHead) {
        this.mode = "running";
      }
    }
  }

  private stepToObservable(step: StepNode): Observable<Message> {
    this.flipMode(step);
    if (step.condition && !step.condition(this.blockArguments())) return EMPTY;
    return this.valueToObservable(step.value);
  }

  private addProgressControl(step: StepNode) {
    const totalSteps = this.steps.length;
    const id = step.id;
    const addStepId = (m: Message): Message => {
      m.stepId = id;
      return m;
    };
    const stepDone = [
      {
        type: STEP_DONE,
        payload: step.id,
      },
    ];
    return ($message: Observable<Message>) => {
      $message = $message.pipe(map(addStepId));
      if (this.mode === "running") {
        const start: Message[] = [
          {
            type: GAME_LEVEL_PROGRESS,
            payload: {
              total: totalSteps,
              current: step.index,
              progress: step.index / totalSteps,
            },
          },
        ];
        if (step.isRunHead) {
          start.unshift({ type: SCRIPT_REPLAY_DONE, payload: undefined });
        }
        return concat(start, $message, stepDone);
      }
      return concat($message, stepDone);
    };
  }

  marker(id: string) {
    this.addStep({ type: STEP_MARKER, payload: undefined }, id);
  }

  addLeadingMessage(message: Message) {
    this.leadingMessages.push(message);
  }

  quest = (title: string, description?: string) => {
    const questAlreadyExists = this.quests.find((q) => q.title === title);
    if (
      !title ||
      questAlreadyExists ||
      (this.ctxQuest && this.ctxQuest.title === title)
    ) {
      return this;
    }

    this.ctxQuest = {
      title,
      description,
      lastStep: null,
    };

    this.addLeadingMessage({
      payload: {
        ix: this.quests.length,
        title,
        completed: false,
      },
      type: GAME_STEP_ADD,
    });

    this.addStep({
      type: GAME_CURRENT_QUEST,
      payload: this.ctxQuest,
    });

    this.quests.push(this.ctxQuest);

    return this;
  };

  test = (id: string, testFn) => {
    this.testsEngine?.startTest(id, testFn);
  };

  testRequire = (id: string) => {
    this.whenever(
      (msg) => msg.type === TEST_COMPLETE && msg.payload.test === id
    );
    return this;
  };

  testStop = (id: string) => {
    this.addStep({
      type: TEST_STOP,
      payload: { test: id },
    });
    return this;
  };

  actor = (actor) => {
    this.ctxActor = actor;
    return this;
  };

  app = (app) => {
    this.ctxApp = app;
    return this;
  };

  do = (type: string, payload?: any, id?: string) => {
    this.addStep(
      {
        type,
        payload: { actor: this.ctxActor, ...(payload || {}) },
      },
      id
    );

    return this;
  };

  exec = (funct: StepNode["value"], customId?: string) => {
    this.addStep(funct, customId);
    return this;
  };

  points = (payload) => {
    this.addStep({
      type: GAME_POINTS_ADD,
      payload,
    });
  };

  say = (...sentences: (string | any)[]) => {
    const types = {
      chat: CHAT_MESSAGE_SEND,
      mailbox: MAIL_SEND,
      direct: DIRECT_COMMUNICATION_SEND,
      videoCall: VIDEO_CALL_ACTOR_SAY,
    };
    const app = this.ctxApp;
    const actor = this.ctxActor;
    const type = types[app];
    if (!app) throw new Error("No context app defined");
    if (!actor) throw new Error("No context actor defined");
    if (!type) throw new Error(`No message type defined for the app ${app}`);
    sentences.forEach((message) => {
      this.addStep({
        type,
        payload: { actor, from: actor, message },
      });
    });
    return this;
  };

  decision = (
    id: DecisionPayload["id"],
    options: DecisionOption[],
    config?: Partial<DecisionPayload>
  ) => {
    this.lastDecisionId = id;
    this.marker(id);
    this.addStep({
      type: DECISION_ADD,
      payload: {
        id,
        options,
        ...config,
      } as DecisionPayload,
    });
    this.marker(`end.${id}`);
    return this;
  };

  onDecision = (
    key: ((id: string) => boolean) | string | number,
    block: () => void
  ) => {
    const match =
      typeof key === "function" ? key : (id) => String(id) === String(key);
    const decisionId = this.lastDecisionId;
    const condition = (m: Message) =>
      m.type === DECISION_CHOOSE &&
      m.payload.decision === decisionId &&
      match(m.payload.option.id);
    if (!decisionId) {
      throw new Error("Prior decision not defined in script");
    }
    this.onMessage(condition, block);
  };

  onMessage = (
    condition: ((m: Message) => boolean) | string,
    block: (a: ExecArguments & { msg?: Message }) => void
  ) => {
    const conditionFn =
      typeof condition === "function"
        ? condition
        : (m: Message) => m.type === condition;

    this.addStep(({ msgsAll }) => {
      msgsAll.pipe(filter(conditionFn), take(1)).subscribe((msg) => {
        block({ ...this.blockArguments(), msg });
      });
      return EMPTY;
    });
  };

  when = (
    type: string | ((a: any, i?: number) => boolean),
    customId?: string
  ) => {
    const findFn =
      typeof type === "function" ? type : (m: Message) => m.type === type;
    return this.createWhen(findFn, this.msgsRealtime, customId);
  };

  whenever = (type: string | ((m: Message) => boolean), customId?: string) => {
    const findFn =
      typeof type === "function" ? type : (m: Message) => m.type === type;
    return this.createWhen(findFn, this.msgsAll, customId);
  };

  private createWhen = (
    findFunction: (m: Message) => boolean,
    observable,
    customId?: string
  ) => {
    /**
     * If the step has been passed once, we assume that the task has been completed even if the message was not persisted
     * When in replay mode
     */
    this.addStep(
      () =>
        defer(() => {
          if (this.mode === "replay") return EMPTY;
          return NEVER.pipe(takeUntil(observable.pipe(filter(findFunction))));
        }),
      customId
    );
    return this;
  };

  wait = (seconds: number) => {
    this.addStep({ type: MAIN_WAIT, save: false, payload: { seconds } });
    return this;
  };

  passTime = (duration?: number, keepRoute = false) => {
    this.addStep({
      type: PASS_TIME,
      payload: { duration: duration || 5, keepRoute },
      save: false,
    });
    return this;
  };

  playVideo = (src: string) => {
    this.marker(`video.${src}`);
    this.addStep({
      type: VIDEO_PLAY,
      payload: { src },
      save: false,
    });
    return this;
  };

  chat = (
    roomId: string,
    roomName: string | null,
    roomActors: string[],
    messages: { actor: string; message: string; links?: ChatMessageLink[] }[],
    notification?: boolean
  ) => {
    const intl = this.intl;
    if (messages.length) {
      const actor = this.ctxActor;
      // TODO: it would be nice not to have script exceptions here
      const icon = !roomActors.includes(roomId)
        ? `/group_chats/${roomId}.svg`
        : undefined;
      this.addStep({
        type: CHAT_ROOM_ADD,
        payload: {
          id: roomId,
          messages: [],
          roomActors,
          roomName,
          unread: 0,
          decisions: 0,
          icon,
        } as Room,
      });

      messages.forEach((message, index) => {
        this.addStep({
          type: CHAT_MESSAGE_SEND,
          payload: {
            roomId,
            actor: message.actor || actor,
            message: message.message,
            links: message.links || [],
            next:
              index < messages.length
                ? messages[index + 1]
                  ? messages[index + 1].actor
                  : null
                : null,
          },
        });
      });

      const payload = roomName
        ? {
            appId: "chat",
            actors: roomActors,
            title: roomName,
            icon,
            description: intl.formatMessage(
              { id: "chat.groupAdded" },
              { roomName }
            ),
            buttonLabel: intl.formatMessage({ id: "notification.read" }),
          }
        : {
            appId: "chat",
            actors: roomActors,
            title: intl.formatMessage({ id: "notification.newMessage" }),
            buttonLabel: intl.formatMessage({ id: "notification.read" }),
          };

      if (notification) {
        this.addStep({
          type: NOTIFICATION_ADD,
          save: false,
          payload,
        });
      }
    }

    return this;
  };

  badge = (badge) => {
    this.addStep({
      type: GAME_BADGE_ADD,
      payload: badge,
    });
  };

  episodeStats = () => {
    console.log("Quests");
    console.table(
      this.quests.map((q) => ({
        title: q.title,
        lastStep: q.lastStep.id,
      }))
    );
    // console.log("StepNodeList");
    // console.table(this.steps);
    let named = 0;
    const total = this.steps.length;
    for (const s of this.steps) {
      if (s.id.startsWith("node.main")) continue;
      named++;
    }
    console.log(
      `${named} named nodes in a total of ${total}. ${Math.round(
        (named / total) * 100
      )}% named nodes`
    );
  };

  inReplay = () => {
    return this.mode === "replay";
  };
}

import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { loadTakeAways } from "../../../game-engine/episodes-loader";
import { Episode } from "../../../game-engine/interfaces";
import { getLanguage } from "../../../i18n";
import { useHref } from "../../hooks";
import { CompetenceArea, Test } from "../../interfaces";
import {
  MicrolearningContent,
  MicrolearningContext,
  TakeAwayContent,
} from "../../microlearning";
import { useStyles } from "../pages/home/item.styles";

const MAX_NUMBER_OF_LINKS = 7;

const MicrolearningItem = ({ item }: { item: MicrolearningContent }) => {
  const {
    microlearningActivity,
    onMicrolearningLinkClicked,
    onMicrolearningLinkVoted,
  } = useContext(MicrolearningContext);

  return (
    <li>
      {microlearningActivity?.linksOpened?.includes(item.url) &&
        !microlearningActivity.linksVoted.some(
          (vote) => vote.url === item.url
        ) && (
          <span style={{ cursor: "pointer" }}>
            <span onClick={() => onMicrolearningLinkVoted(item, 1)}>👍</span>
            <span onClick={() => onMicrolearningLinkVoted(item, -1)}>👎</span>
          </span>
        )}
      {microlearningActivity.linksVoted.some(
        (vote) => vote.url === item.url && vote.value > 0
      ) && "🌟"}
      <a
        href={item.url}
        target="_blank"
        rel="noreferrer"
        onClick={() => onMicrolearningLinkClicked(item)}
        dangerouslySetInnerHTML={{
          __html: item.title,
        }}
      />
    </li>
  );
};

const TestItem = ({
  test,
  takeAways,
  failed,
}: {
  test: Test;
  takeAways: TakeAwayContent;
  failed: boolean;
}) => {
  const { microlearningActivity, tools } = useContext(MicrolearningContext);

  return (
    <li>
      {JSON.parse(test.description)[getLanguage()]}
      <ul>
        {takeAways.links &&
          takeAways.links
            .filter(
              (l) =>
                l.test === test.id &&
                !microlearningActivity.linksVoted.some(
                  (vote) => vote.url === l.url && vote.value === -1
                )
            )
            .sort((a, b) => {
              if (!a.tool && !b.tool) return 0;

              if (a.tool && !b.tool)
                return (tools[a.tool.type] || []).includes(a.tool.name)
                  ? -1
                  : 0;

              if (!a.tool && b.tool)
                return (tools[b.tool.type] || []).includes(b.tool.name) ? 1 : 0;

              if (a.tool && (tools[a.tool.type] || []).includes(a.tool.name))
                return b.tool &&
                  (tools[b.tool.type] || []).includes(b.tool.name)
                  ? 0
                  : -1;

              if (b.tool && (tools[b.tool.type] || []).includes(b.tool.name))
                return a.tool &&
                  (tools[a.tool.type] || []).includes(a.tool.name)
                  ? 0
                  : 1;

              return 0;
            })
            .slice(0, MAX_NUMBER_OF_LINKS)
            .map((item) => <MicrolearningItem key={item.url} item={item} />)}
      </ul>
    </li>
  );
};

const TakeAways = ({
  episode,
  testDetails,
}: {
  episode: Episode;
  testDetails: CompetenceArea[];
}) => {
  const classes = useStyles();
  const divRef = useHref("advice");
  const [takeAways, setTakeAways] = useState<TakeAwayContent>({});
  const [failedTestItems, setFailedTestItems] = useState<Test[]>([]);
  const [passedTestItems, setPassedTestItems] = useState<Test[]>([]);

  const [showPassedTestItems, setShowPassedTestItems] = useState(false);

  useEffect(() => {
    if (episode && testDetails) {
      loadTakeAways(episode).then(setTakeAways);
    }

    if (testDetails.length) {
      const tests = testDetails.flatMap(
        (competenceArea) => competenceArea.tests
      );
      setFailedTestItems(tests.filter((test) => test.result === 0));
      setPassedTestItems(tests.filter((test) => test.result === 1));
    }
  }, [testDetails, episode]);

  return (
    <>
      {takeAways.markdown?.length && (
        <div
          data-e2e-report-take-aways
          ref={divRef}
          className={`${classes.takeAwaysText} animate__animated animate__fadeIn`}
          dangerouslySetInnerHTML={{
            __html: `<div class="takeaways-wrapper animate__animated animate__bounceInDown">${takeAways.markdown}</div>`,
          }}
        />
      )}

      {takeAways.links && (
        <div
          className={`${classes.microlearningList} animate__animated animate__fadeIn`}
        >
          {!!failedTestItems.length &&
            !!failedTestItems.some((f) =>
              takeAways.links?.some((l) => !!l && l.test === f.id)
            ) && (
              <div>
                <FormattedMessage id="dashboard.page.home.take-aways.intro-failed" />
                <ul>
                  {failedTestItems
                    .filter((test) =>
                      takeAways.links?.some((l) => !!l && l.test === test.id)
                    )
                    .map((test) => (
                      <TestItem
                        key={test.id}
                        test={test}
                        takeAways={takeAways}
                        failed={true}
                      />
                    ))}
                </ul>
              </div>
            )}
          {!!passedTestItems.length &&
            !!passedTestItems.some((f) =>
              takeAways.links?.some((l) => !!l && l.test === f.id)
            ) && (
              <>
                <Button
                  style={{ color: "#fff" }}
                  onClick={() => setShowPassedTestItems(!showPassedTestItems)}
                >
                  <FormattedMessage
                    id={
                      showPassedTestItems
                        ? "dashboard.page.home.take-aways.hide-passed"
                        : "dashboard.page.home.take-aways.show-passed"
                    }
                  />
                </Button>
                {showPassedTestItems && (
                  <div>
                    <FormattedMessage id="dashboard.page.home.take-aways.intro-passed" />
                    <ul>
                      {passedTestItems
                        .filter((test) =>
                          takeAways.links?.some(
                            (l) => !!l && l.test === test.id
                          )
                        )
                        .map((test) => (
                          <TestItem
                            key={test.id}
                            test={test}
                            takeAways={takeAways}
                            failed={false}
                          />
                        ))}
                    </ul>
                  </div>
                )}
              </>
            )}
        </div>
      )}
    </>
  );
};

export default TakeAways;

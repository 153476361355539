const messages = {
  "meetings-tool.create": "Erstellen",
  "meetings-tool.synchronize-apps": "Anwendungen synchronisieren",
  "meetings-tool.synchronize-apps.connect": "Apps verbinden",
  "meetings-tool.synchronize-apps.title":
    "Anwendung auswählen, die mit Meet:ly synchronisiert werden soll",
  "meetings-tool.edit": "Bearbeiten",
  "meetings-tool.remove": "Entfernen",
  "meetings-tool.save": "Speichern",
  "meetings-tool.dialog.remove-template":
    "Möchten Sie diese Veranstaltungsvorlage entfernen?",
  "meetings-tool.type": "Typ: ",
  "meetings-tool.location": "Standort: ",
  "meetings-tool.cancel": "Löschen",
  "meetings-tool.dialog.cancel-event":
    "Möchten Sie diese Veranstaltung absagen?",
  "meetings-tool.create-team": "Team erstellen",
  "meetings-tool.dialog.remove-team": "Möchten Sie dieses Team entfernen?",
  "meetings-tool.teams.edit.title": "Neues Team erstellen",
  "meetings-tool.teams.edit.new-team-name":
    "Legen Sie Ihren neuen Teamnamen fest *",
  "meetings-tool.teams.edit.team-members": "Wählen Sie Ihre Teammitglieder",

  "meetings-tool.templates.edit.type-of-meeting":
    "Art der Besprechung auswählen *",
  "meetings-tool.templates.edit.basic-information":
    "Grundlegende Informationen",
  "meetings-tool.templates.edit.select-team": "Team auswählen",
  "meetings-tool.templates.edit.select-team-sub":
    "Veranstalten Sie ein Event mit einer anderen Person und lassen Sie die Eingeladenen eine Zeit wählen, zu der Sie alle verfügbar sind. Wenn Sie kein Mitglied eines Teams sind, müssen Sie ein neues Team erstellen, bevor Sie ein Ereignis erstellen können.",
  "meetings-tool.templates.edit.event-name": "Name der Veranstaltung *",
  "meetings-tool.templates.edit.location": "Standort *",
  "meetings-tool.templates.edit.description": "Beschreibung",
  "meetings-tool.templates.edit.event-color": "Ereignisfarbe",
  "meetings-tool.templates.edit.your-availability": "Ihre Verfügbarkeit",
  "meetings-tool.templates.edit.duration": "Laufzeit *",
  "meetings-tool.templates.edit.schedule": "Zeitplan *",
  "meetings-tool.templates.edit.additional-information":
    "Zusätzliche Informationen",
  "meetings-tool.templates.edit.invitees-can-schedule": "Termin möglich...",
  "meetings-tool.templates.edit.day-into-future": "Tage in die Zukunft",
  "meetings-tool.templates.edit.indefinitely":
    "auf unbestimmte Zeit in die Zukunft",
  "meetings-tool.templates.edit.time-before-after":
    "Zeit vor oder nach der Veranstaltung hinzufügen",
  "meetings-tool.templates.edit.before": "Vor der Veranstaltung",
  "meetings-tool.templates.edit.after": "Nach der Veranstaltung",
  "meetings-tool.templates.edit.maximum-participants":
    "Maximale Teilnehmerzahl (0 für unbegrenzt)",
  "meetings-tool.templates.edit.reminders": "Erinnerungen",
  "meetings-tool.templates.edit.reminders-subtitle":
    "Erinnern Sie alle Teilnehmer...",
  "meetings-tool.templates.edit.reminders.10min": "10 Minuten vor dem Ereignis",
  "meetings-tool.templates.edit.reminders.1h": "1 Stunde vor der Veranstaltung",
  "meetings-tool.templates.edit.reminders.12h":
    "12 Stunden vor der Veranstaltung",
  "meetings-tool.templates.edit.reminders.24h":
    "24 Stunden vor der Veranstaltung",

  "meetings-tool.minutes": "Minuten",

  "meetings-tool.sync-dialog.mailbox": "Mailbox",
  "meetings-tool.sync-dialog.calendar": "Kalender",
  "meetings-tool.sync-dialog.messenger": "Messenger",
  "meetings-tool.sync-dialog.video-call": "Videokonferenz",
  "meetings-tool.sync-dialog.phone-call": "Telefonat",

  "meetings-tool.event-types.one-on-one": "Einzeltreffen",
  "meetings-tool.event-types.group": "Gruppentreffen",
  "meetings-tool.event-types.collective": "Kollektives Treffen (Team)",

  "meetings-tool.days.monday": "Montag",
  "meetings-tool.days.tuesday": "Dienstag",
  "meetings-tool.days.wednesday": "Mittwoch",
  "meetings-tool.days.thursday": "Donnerstag",
  "meetings-tool.days.friday": "Freitag",

  "meetings-tool.menu.event-templates": "Terminvorlagen",
  "meetings-tool.menu.scheduled-events": "Gebuchte Termine",
  "meetings-tool.menu.your-teams": "Eigene Teams",

  "meetings-tool.templates.edit.type-tooltip.group":
    "Laden Sie mehrere Personen zu einem Event mit Ihnen ein, an dem alle gleichzeitig teilnehmen.",
  "meetings-tool.templates.edit.type-tooltip.collective":
    "Veranstalten Sie und Ihr Team ein Ereignis mit einer anderen Person und lassen Sie die andere Person einen Zeitraum wählen, zu dem alle aus dem Team verfügbar sind.",

  "meetings-tool.yes": "Ja",
  "meetings-tool.no": "Nein",
};

export default messages;

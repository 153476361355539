import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import OpenAI from "openai";
import { openAiConnection } from "../services/open-ai-connection";

export const usePostOpenAiRun = (
  options?: UseMutationOptions<OpenAI.Beta.Threads.Runs.Run, unknown, unknown>
) => {
  return useMutation({
    mutationFn: async ({
      threadId,
      assistantId,
    }: {
      threadId: string;
      assistantId: string;
    }) => {
      return await openAiConnection
        .getConnection()
        .beta.threads.runs.create(threadId, {
          // eslint-disable-next-line camelcase
          assistant_id: assistantId,
        });
    },
    meta: {
      devErrorMsg: "usePostOpenAiRun",
    },
    ...options,
  });
};

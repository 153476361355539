import type { UserInfo } from "../core/interfaces";
import { getItem } from "../core/storage";
import { initFreshchat } from "./fresh-chat";
import { initHubspot } from "./hub-spot";
import { initLogRocket } from "./log-rocket";

export async function initUserSupport(user: UserInfo) {
  if (getItem("isDemoTenant")) initHubspot(user);

  const [setLogRocketSession, LogRocket] = await Promise.all([
    initFreshchat(user),
    initLogRocket(user),
  ]);

  if (LogRocket) {
    LogRocket.getSessionURL(setLogRocketSession);
  }
}

const messages = {
  "zen.master": "Du bist Meister des Zens!",
  "zen.reach": "Erreiche deine 7 Digital-Chakras",
  "zen.skip": "Überspringen",
  "zen.next": "Weiter",
  "zen.moreinfo": "Mehr Infos zu dem Thema?",
  "zen.start": "Start",
  "zen.done": "Fertig",
  "zen.back": "Zurück",
  "zen.continue": "Weiter",
  "zen.multiple": "Mehrfach Auswahl ist möglich",
  "zen.moreinfotitle": "Weitere Informationen",
  "zen.question": "Wissensfrage",
  "zen.assess": "Selbsteinschätzung",
};

export default messages;

const messages = {
  "meetings-tool.create": "Create",
  "meetings-tool.synchronize-apps": "Synchronize apps",
  "meetings-tool.synchronize-apps.connect": "Connect",
  "meetings-tool.synchronize-apps.title":
    "Select application to synchronize with Meet:ly",
  "meetings-tool.edit": "Edit",
  "meetings-tool.remove": "Remove",
  "meetings-tool.save": "Save",
  "meetings-tool.dialog.remove-template":
    "Do you want to remove this event template?",
  "meetings-tool.type": "Type: ",
  "meetings-tool.location": "Location: ",
  "meetings-tool.cancel": "Cancel",
  "meetings-tool.dialog.cancel-event": "Do you want to cancel this event?",
  "meetings-tool.create-team": "Create team",
  "meetings-tool.dialog.remove-team": "Do you want to remove this team?",
  "meetings-tool.teams.edit.title": "Create new team",
  "meetings-tool.teams.edit.new-team-name": "Set your new team name *",
  "meetings-tool.teams.edit.team-members": "Select your team members",

  "meetings-tool.templates.edit.type-of-meeting": "Select type of meeting *",
  "meetings-tool.templates.edit.basic-information": "Basic information",
  "meetings-tool.templates.edit.select-team": "Select team",
  "meetings-tool.templates.edit.select-team-sub":
    "Host an event with another person and let invitees pick a time when you’re all available. If you are not a member of any team then you need to create a new one before you'll create an event.",
  "meetings-tool.templates.edit.event-name": "Event name *",
  "meetings-tool.templates.edit.location": "Location *",
  "meetings-tool.templates.edit.description": "Description",
  "meetings-tool.templates.edit.event-color": "Event color",
  "meetings-tool.templates.edit.your-availability": "Your availability",
  "meetings-tool.templates.edit.duration": "Duration *",
  "meetings-tool.templates.edit.schedule": "Schedule *",
  "meetings-tool.templates.edit.additional-information":
    "Additional informations",
  "meetings-tool.templates.edit.invitees-can-schedule":
    "Invitees can schedule...",
  "meetings-tool.templates.edit.day-into-future": "days into the future",
  "meetings-tool.templates.edit.indefinitely": "indefinitely into future",
  "meetings-tool.templates.edit.time-before-after":
    "Add time before or after event",
  "meetings-tool.templates.edit.before": "Before event",
  "meetings-tool.templates.edit.after": "After event",
  "meetings-tool.templates.edit.maximum-participants":
    "Maximum participants (0 for unlimited)",
  "meetings-tool.templates.edit.reminders": "Reminders",
  "meetings-tool.templates.edit.reminders-subtitle":
    "Remind all participants...",
  "meetings-tool.templates.edit.reminders.10min": "10 minutes before event",
  "meetings-tool.templates.edit.reminders.1h": "1 hour before event",
  "meetings-tool.templates.edit.reminders.12h": "12 hour before event",
  "meetings-tool.templates.edit.reminders.24h": "24 hour before event",

  "meetings-tool.minutes": "mins",

  "meetings-tool.sync-dialog.mailbox": "Mailbox",
  "meetings-tool.sync-dialog.calendar": "Calendar",
  "meetings-tool.sync-dialog.messenger": "Messenger",
  "meetings-tool.sync-dialog.video-call": "Video call",
  "meetings-tool.sync-dialog.phone-call": "Phone call",

  "meetings-tool.event-types.one-on-one": "One on one",
  "meetings-tool.event-types.group": "Group",
  "meetings-tool.event-types.collective": "Collective event (for team)",

  "meetings-tool.days.monday": "Monday",
  "meetings-tool.days.tuesday": "Tuesday",
  "meetings-tool.days.wednesday": "Wednesday",
  "meetings-tool.days.thursday": "Thursday",
  "meetings-tool.days.friday": "Friday",

  "meetings-tool.menu.event-templates": "Event templates",
  "meetings-tool.menu.scheduled-events": "Scheduled events",
  "meetings-tool.menu.your-teams": "Your teams",

  "meetings-tool.templates.edit.type-tooltip.group":
    "Invite several people to an event with you where they all participate at the same time.",
  "meetings-tool.templates.edit.type-tooltip.collective":
    "Host an event with someone else and have the other person choose a time when everyone on the team is available.",

  "meetings-tool.yes": "Yes",
  "meetings-tool.no": "No",
};

export default messages;

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cuid from "cuid";
import React, { useRef } from "react";
import { Tag } from "../interfaces";
import { getTranslate } from "../services";

const styles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    maxWidth: "100%",
  },
}));

type PropsType = {
  label: string;
  data: Pick<Tag, "id" | "name">[];
  selectCallback: (value: number) => void;
  value?: number;
  language: string;
  private?: boolean;
};

const TagSelect: React.FC<PropsType> = (props: PropsType) => {
  const classes = styles();
  const { data, selectCallback, value = 0, language, label } = props;
  const labelId = useRef(cuid());

  const getItemName = (itemName: string) => {
    if (language === "en") {
      return getTranslate(itemName);
    } else {
      return itemName;
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id={`select-id-${labelId}`}>
          {language === "en" ? getTranslate(label) : label}
        </InputLabel>
        <Select<number | string>
          labelId={`select-id-${labelId}`}
          label={language === "en" ? getTranslate(label) : label}
          value={value === 0 ? "" : `${value}`}
          onChange={(e) => selectCallback(e.target.value as unknown as number)}
          data-private={!!props.private}
          defaultValue={""}
        >
          {data.map((item) => {
            return (
              <MenuItem
                key={`${item.name}${item.id}`}
                value={item.id}
                data-private={!!props.private}
              >
                {getItemName(item.name)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};
export default TagSelect;

import { styled, useTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styled-engine";
import DOMPurify from "dompurify";
import { useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import DecisionApp from "..";
import { DecisionOption } from "../interfaces/decision-option";
import { DecisionPayload } from "../interfaces/decision-payload";

type PropsType = {
  decision?: DecisionPayload;
};

export const Fullscreen = ({ decision }: PropsType) => {
  const [classes, setClasses] = useState("fadeIn");
  const theme = useTheme();

  const onSelect = (option: DecisionOption) => {
    if (!decision) return;

    setClasses("fadeOut");

    setTimeout(() => {
      DecisionApp.instance.choose(decision.id, option);
    }, theme.custom.ANIMATIONS_SPEED_CONFIG.animate__fast);
  };

  return createPortal(
    <StyledContainerBox
      className={`animate__animated animate__${classes} animate__fast`}
      data-e2e-decision-panel
    >
      <StyledTitle>
        {decision?.title ?? <FormattedMessage id="decisions.title" />}
      </StyledTitle>
      {decision?.options.map((option, idx) => (
        <StyledOptionBox
          data-e2e-decision-panel-button={idx + 1}
          id={option.id as string}
          key={option.id}
          onClick={() => onSelect(option)}
        >
          {option.title && (
            <StyledOptionTitle>{option.title}</StyledOptionTitle>
          )}
          <StyledOptionText
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(option.text),
            }}
          />
        </StyledOptionBox>
      ))}
    </StyledContainerBox>,
    document.body
  );
};

const StyledContainerBox = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: theme.custom.zIndex.decision,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(1, 13, 19, 0.7)",
  backdropFilter: "blur(10px)",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 40,

  [theme.breakpoints.down("sm")]: {
    gap: 25,
  },
}));

const StyledTitle = styled("p")(({ theme }) => ({
  color: "white",
  fontSize: 28,
  marginTop: 55,
  marginBottom: 0,

  [theme.breakpoints.down("sm")]: {
    marginBottom: 5,
  },
}));

const StyledOptionBox = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  border: "1px solid white",
  borderRadius: 8,
  width: "50%",
  cursor: "pointer",
  transition: "transform 150ms linear",
  textAlign: "center",

  "&:hover": {
    backgroundColor: "#EEF0F2",
    transform: "scale(1.03)",
  },

  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const TextBase: CSSProperties = {
  color: "black",
  fontFamily: "Roboto",
  margin: 16,
};

const StyledOptionTitle = styled("p")({
  ...TextBase,
  fontSize: 24,
});

const StyledOptionText = styled("p")({
  ...TextBase,
  fontSize: 16,
});

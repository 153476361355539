import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={70}
      viewBox="0 0 60 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.292 40.834l-19.024 8.75v1.458l8.78 5.833 19.025-8.75v-1.458l-8.78-5.834z"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M10.244 52.5v7.292l19.024 8.75 19.024-8.75V52.5"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M43.61 30.916l12 5.542v1.459l-7.317 2.916L43.17 38.5M15.805 30.625L3.073 36.458v1.459l7.317 2.916 5.561-2.479"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.268 51.041v17.5M10.244 40.834l19.024 8.75v1.458l-8.78 5.833-19.025-8.75v-1.458l8.78-5.834z"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M36.585 39.375c0-18.083 5.854-24.938 10.244-27.708 4.39-2.771 11.561-1.459 11.561 2.916s-4.683 5.25-7.463 3.209M23.415 40.834c0-16.48-5.708-21.73-10.244-23.917-4.39-2.188-10.537-.438-11.561 3.937-.878 3.646 3.658 6.417 5.854 2.625"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.415 13.125l-2.927 2.917M30.732 5.833L27.805 8.75M27.805 13.125l2.927 2.917M20.488 5.833l2.927 2.917M29.268 21.875v2.917M29.268 27.709v2.916M27.805 26.25h-2.927M33.658 26.25h-2.927M7.317 0v2.917M7.317 5.833V8.75M5.854 4.375H2.927M11.707 4.375H8.78"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M38.049 10.209c2.634-4.375 6.146-7.292 10.244-8.75"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { State } from "../../direct-communication/interfaces/state";

export interface StylesProps {
  mode: State["mode"];
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    zIndex: theme.custom.zIndex.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backdropFilter: "blur(10px)",
  },
  button: {
    backgroundColor: "rgba(112, 112, 112, 0.6)",
    margin: "0 0 0 10px",
    color: "white",
    transition: "background-color 600ms linear",

    "&:hover": {
      backgroundColor: "rgba(112, 112, 112, 0.6)",
      transition: "background-color 600ms linear",
    },
  },
  buttonCenter: {
    margin: "0 auto 0 10px",
  },
  rewindButton: {
    "& .arrow": {
      transition: "fill 600ms linear",
      fill: "#474747",
    },
    "&:hover": {
      background: "#00B2E2",
      "& .arrow": {
        transition: "fill 600ms linear",
        fill: "#00B2E2",
      },
    },
  },
  rewindButtonOpen: {
    background: "#00B2E2",
    "& .arrow": {
      fill: "#00B2E2",
    },
    "&:hover": {
      background: "#00B2E2",
    },
  },
  newQuest: {
    background: "#00B2E2",
    "& .arrow": {
      fill: "#00B2E2",
    },
  },
  notification: {
    width: 48,
    height: 48,
    borderRadius: 50,
    marginRight: 10,
    backgroundColor: "#333",
    position: "absolute",
    bottom: 6,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 600ms linear",
  },
  notificationAvatar: {
    width: "80%",
    userSelect: "none",
  },
  notificationActive: {
    transition: "background-color 600ms linear",
    backgroundColor: "#27B199",
    cursor: "pointer",
  },
  notificationCompleted: {
    transition: "background-color 600ms linear",
    backgroundColor: "rgba(39, 177, 153, 0.5)",
    cursor: "pointer",
  },
  notificationDisabled: {
    transition: "background-color 600ms linear",
    backgroundColor: "#333",
    cursor: "default",
  },
  notificationTour: {
    backgroundColor: "#27B199",
    cursor: "default",
  },
  questButtonActive: {
    transition: "background-color 600ms linear",
    backgroundColor: "#27B199 !important",
  },
}));

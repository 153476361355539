import { PlayerProgressBarRules } from "./components/player-progress-bar";

const rules: PlayerProgressBarRules = [
  {
    nameId: "dashboard.player-progress.player-settings",
    ruleFunct: (context) => context.playerSettingsFilled,
    path: "/profile/playerprofile",
  },
  {
    nameId: "dashboard.player-progress.demographic-data",
    ruleFunct: (context) =>
      context.filledTags.length >= context.availableTags &&
      context.departmentsFilled,
    path: "/profile/data",
  },
  {
    nameId: "dashboard.player-progress.invite-teammate",
    ruleFunct: (context) => context.colleagueInviteLinkActivated,
    path: "/invitation",
  },
];

export default rules;

import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Season } from "../../../game-engine/interfaces";
import { getName } from "../pages/admin/missions/missions.service";
import CardMediaWithTitle from "./cardMediaWithTitle";
import CompetenceIcon from "./competence-icon";

const StyledSeason = styled(Grid)(({ theme }) => ({
  cursor: "pointer",
  transition: "transform 0.2s",
  transform: "scale(1)",
  "&:hover": {
    transform: "scale(1.025)",
  },
  "& > div": {
    height: "100%",
  },
}));

const StyledTopics = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const shortenText = (text) => `${text.slice(0, 140)}...`;

const SeasonCard = ({
  season,
  onClick,
}: {
  season: Season;
  onClick: () => void;
}) => {
  const seasonImage = useMemo(
    () =>
      season.assetsURL
        ? `${season.assetsURL}/season.svg`
        : `/seasons/${season.id}.svg`,
    [season, season.assetsURL]
  );

  const { total, completed, progress } = useMemo(() => {
    const total = season.episodes.filter((e) => {
      if (e.hidden) {
        return !e.locked || e.completed;
      }
      return true;
    }).length;

    const completed = season.episodes.filter((e) => e.completed).length;

    return {
      total,
      completed,
      progress: season.episodes.find((e) => e.maturity === "PUBLIC")
        ? (completed * 100) / total
        : -1,
    };
  }, [season, season.episodes]);

  return (
    <StyledSeason
      item
      sm={12}
      md={6}
      lg={4}
      onClick={onClick}
      data-e2e-dashboard-season={season.id}
    >
      <Card key={season.index}>
        <CardMediaWithTitle
          title={season.title}
          image={seasonImage}
          height={200}
        >
          {progress >= 0 ? (
            <CardMediaWithTitle.WithProgress progress={progress} />
          ) : (
            <CardMediaWithTitle.WithBanner withOverlay />
          )}
        </CardMediaWithTitle>
        <CardContent>
          <Typography align="center" variant="subtitle1">
            <FormattedMessage
              id="dashboard.page.completed.episodes"
              values={{
                completed,
                total,
              }}
            />
          </Typography>
          <Divider />
          <p>{shortenText(season.info)}</p>
          {season.topics?.length ? (
            <>
              <Divider />
              <br />
              <Typography variant="subtitle1">
                <FormattedMessage id="dashboard.page.home.title" />
              </Typography>
              <StyledTopics>
                <List>
                  {season.topics.map(({ competenceAreaId, description }, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <CompetenceIcon
                          competenceAreaId={competenceAreaId}
                          index={i}
                        />
                      </ListItemIcon>
                      <ListItemText primary={getName(description)} />
                    </ListItem>
                  ))}
                </List>
              </StyledTopics>
            </>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </StyledSeason>
  );
};

const Seasons = ({
  seasons,
  onSelectSeason,
}: {
  seasons: Season[];
  onSelectSeason: (seasonId: Season["id"]) => void;
}) => {
  return (
    <Grid container direction="row" spacing={2} alignContent="stretch">
      {seasons.map((season) => (
        <SeasonCard
          key={season.id}
          season={season}
          onClick={() => onSelectSeason(season.id)}
        />
      ))}
    </Grid>
  );
};

export default Seasons;

import { Route, Switch } from "react-router-dom";
import AppSwitcher from "./app-switcher/app-switcher";
import Browser from "./browser/browser";
import { AppsHome, useDeviceDetect } from "./hooks";
import { Application } from "./interfaces";

export const Apps = (props: {
  router: Application[];
  browser: Application[];
  home: AppsHome[];
  hidden?: boolean;
}) => {
  const device = useDeviceDetect();
  const appSwitcher = props.hidden ? (
    false
  ) : (
    <AppSwitcher applications={props.home} />
  );
  const appSwitcherMobile = device.mobile ? (
    <Route path="/" exact>
      {appSwitcher}
    </Route>
  ) : (
    false
  );
  const appSwitcherDesktop = device.mobile ? false : appSwitcher;

  return (
    <>
      {appSwitcherDesktop}
      <Switch>
        {appSwitcherMobile}
        {props.router.map((a) => (
          <Route
            key={a.id}
            path={a.path}
            render={() =>
              a.component && <a.component {...(a.props || {})} app={a} />
            }
          />
        ))}
        <Route path={["/browser/:domain", "/browser"]}>
          <Browser apps={props.browser} />
        </Route>
      </Switch>
    </>
  );
};

import type { UserInfo } from "../core/interfaces";
import { EPISODE_COMPLETE, EPISODE_PLAY } from "../core/messages";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";

const HUBSPOT_ID = 7361468;

export function initHubspot(user: UserInfo) {
  let identified = false;
  const track = (id: string, action: string) => {
    const _hsq = (window as any)._hsq || [];
    if (!identified) {
      _hsq.push(["identify", { email: user.email }]);
      identified = true;
    }
    _hsq.push(["setPath", `/episode/${id}/${action}`]);
    _hsq.push(["trackPageView"]);
  };
  const script = document.createElement("script");
  script.id = "hs-script-loader";
  script.type = "text/javascript";
  script.src = `//js.hs-scripts.com/${HUBSPOT_ID}.js`;
  document.body.appendChild(script);
  MessageHub.track((m: Message) => {
    if (m.type === EPISODE_PLAY && m.payload.playCount === 0) {
      track(m.payload.id, "play");
    } else if (m.type === EPISODE_COMPLETE) {
      track(m.payload.id, "complete");
    }
  });
}

import { Observable } from "rxjs";
import { scan, shareReplay, startWith } from "rxjs/operators";
import { Message } from "../../message-hub/interfaces";
import { ofTypes } from "../applications";
import {
  GuidedCommunicationMessages,
  GUIDED_COMMUNICATION_CLOSE,
  GUIDED_COMMUNICATION_OPEN,
  GUIDED_COMMUNICATION_SEND,
} from "./messages";

export interface State {
  open: boolean;
  message: string;
  showNext: boolean;
  actorImage: string;
}

export class GuidedCommunication {
  static instance: GuidedCommunication;

  initialState: State = {
    open: false,
    message: "",
    showNext: false,
    actorImage: "",
  };

  state: Observable<State>;

  constructor(messages: Observable<Message>) {
    GuidedCommunication.instance = this;

    this.state = messages.pipe(
      ofTypes<GuidedCommunicationMessages>(
        GUIDED_COMMUNICATION_OPEN,
        GUIDED_COMMUNICATION_CLOSE,
        GUIDED_COMMUNICATION_SEND
      ),
      scan(this.reducer, this.initialState),
      startWith(this.initialState),
      shareReplay(1)
    );

    this.state.subscribe();
  }

  private reducer = (state: State, msg: GuidedCommunicationMessages): State => {
    switch (msg.type) {
      case GUIDED_COMMUNICATION_OPEN:
        return this.onToggle(state, true);
      case GUIDED_COMMUNICATION_CLOSE:
        return this.onToggle(state, false);
      case GUIDED_COMMUNICATION_SEND:
        return this.onSendMessage(
          state,
          msg.payload.message,
          msg.payload.actorImage,
          msg.payload.showNext
        );
      default:
        return state;
    }
  };

  private onToggle = (state: State, open: boolean): State => ({
    ...state,
    open,
  });

  private onSendMessage = (
    state: State,
    message: string,
    actorImage: string,
    showNext = false
  ): State => ({
    ...state,
    message,
    showNext,
    actorImage,
  });
}

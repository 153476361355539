import { flatten } from "lodash";
import type {
  Season,
  SeasonDefinition,
  SeasonIntl,
} from "../game-engine/interfaces";
import { season as season00 } from "./s00";
import { season as season01 } from "./s01";
import { season as season02 } from "./s02";
import { season as season03 } from "./s03";
import { season as season91 } from "./s91";
import { season as season92 } from "./s92";
import { season as season93 } from "./s93";
import { season as season94 } from "./s94";
import { season as season95 } from "./s95";
import { season as season96 } from "./s96";
import { season as season97 } from "./s97";
import { season as season98 } from "./s98";

const seasons = [
  season00,
  season01,
  season97,
  season95,
  season02,
  season03,
  season91,
  season92,
  season93,
  season94,
  season96,
  season98,
];

const badgeMap = {
  [season00.id]: () => import("./s00/badges"),
  [season01.id]: () => import("./s01/badges"),
  [season02.id]: () => import("./s02/badges"),
  [season91.id]: () => import("./s91/badges"),
  [season93.id]: () => import("./s93/badges"),
  [season94.id]: () => import("./s94/badges"),
  [season95.id]: () => import("./s95/badges"),
  [season96.id]: () => import("./s96/badges"),
  [season97.id]: () => import("./s97/badges"),
};

export const createToLang = (lang = "de") => {
  const defaultLang = "de";

  const toLang = (property: SeasonIntl | SeasonIntl[]) => {
    if (Array.isArray(property)) return property.map(toLang);
    if (typeof property === "string") return property;
    if (property[lang]) return property[lang];
    if (property[defaultLang]) return property[defaultLang];
    return "";
  };

  return toLang;
};

export const definitionToSeason = (
  season: SeasonDefinition | Season,
  lang = "de"
) => {
  const toLang = createToLang(lang);

  return {
    ...season,
    title: toLang(season.title),
    info: toLang(season.info),
    topics: season.topics?.map(({ competenceAreaId, description }) => ({
      competenceAreaId,
      description: toLang(description),
    })),
    seasonEndMessage: toLang(season.seasonEndMessage),
    episodes: season.episodes.map((e) => ({
      ...e,
      title: toLang(e.title),
      description: toLang(e.description),
      episodePublishingCompatibility: true,
    })),
  };
};

export const getSeasons = async (lang = "de"): Promise<Season[]> => {
  return seasons.map((s) => definitionToSeason(s, lang));
};

export const getBadges = async (...seasonId: Season["id"][]) => {
  return Promise.all(
    seasonId.map((id) => {
      const fn = badgeMap[id];
      if (fn) return fn().then(({ badges }) => badges);
      return Promise.resolve([]);
    })
  ).then(flatten);
};

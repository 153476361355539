export enum CompetenceLevel {
  Foundation = 1,
  Intermediate = 2,
  Advanced = 3,
  HighlySpecialised = 4,
}

export enum CompetenceLevelText {
  Foundation = "FOUNDATION",
  Intermediate = "INTERMEDIATE",
  Advanced = "ADVANCED",
  HighlySpecialised = "HIGHLYSPECIALIZED",
}

export enum whoScope {
  All = "ALL",
  Department = "DEPARTMENT",
  Team = "TEAM",
  Function = "FUNCTION",
}

export enum whatScope {
  All = "ALL",
  CompetenceArea = "COMPETENCE_AREA",
  Competence = "COMPETENCE",
  SubCompetence = "SUB_COMPETENCE",
}

export enum MissionProgressTrend {
  Done = "done",
  OnTrack = "onTrack",
  Stalled = "stalled",
  Declining = "declining",
  None = "none",
}

export enum MissionProgressTrendImportance {
  none = 1,
  done,
  onTrack,
  stalled,
  declining,
}

export enum SignalsStrength {
  NOT_ACTIVE = "NOT_ACTIVE",
  NO_DATA = "NO_DATA",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERYHIGH = "VERYHIGH",
}

export enum FeedbackWeights {
  PERFECT = "PERFECT",
  MIXED = "MIXED",
  BAD = "BAD",
  NO_DATA = "NO_DATA",
}

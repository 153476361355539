import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bgClouds from "./assets/clouds.svg";

const styles = makeStyles((theme: Theme) => {
  const levelText = {
    color: theme.palette.common.white,
    fontSize: "1.875rem",
    letterSpacing: 0,
  };

  return {
    root: {
      backgroundColor: "#7FC6E1",
      backgroundImage: `url(${bgClouds})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundSize: "contain",
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: theme.custom.zIndex.levelEnd,
      userSelect: "none",
      overflow: "auto",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    level: {
      ...levelText,
      textTransform: "uppercase",
    },
    levelText: levelText,
    button: {
      backgroundColor: "#08B79C",
      color: theme.palette.common.white,
      borderRadius: 10,
      height: 52,
      textTransform: "none",
      fontSize: "0.9375rem",
      margin: "8px 0px",
    },
  };
});

export default styles;

import { createTheme } from "@mui/material/styles";
import CONFIG from "../config";

const theme = createTheme({
  custom: {
    color: {
      red: {
        500: "#E65100",
      },
      yellow: {
        300: "#ffd970",
        500: "#FFB74D",
      },
      green: {
        500: "#27B199",
      },
      turquoise: {
        300: "#427c7a",
        500: "#254c53",
        700: "#21444b",
      },
    },
    zIndex: {
      videoCallInCall: {
        message: 1,
      },
      tour: 1292,
      animation: 1292,
      overlay: 1299,
      report: 1299,
      modalNotification: 1300,
      levelEnd: 1304,
      splash: 1307,
      decision: 1310,
      quizApp: 1312,
      toolbar: 1313,
      gameControl: 1313,
      video: 1400,
      timePassing: 1401,

      directCommunication: {
        main: 1309,
        notification: 1310,
        notificationAvatar: 1311,
      },
      documentEditor: {
        speechArrow: 1200,
      },
    },
    ANIMATIONS_ENABLED: CONFIG.ANIMATIONS_ENABLED,
    /**
     * In milliseconds
     */
    ANIMATIONS_SPEED_CONFIG: {
      animate__slow: 2000,
      animate__slower: 3000,
      animate__fast: 800,
      animate__faster: 500,
      animate__normal: 1000,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    secondary: {
      main: "#f50057",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#fff",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          maxWidth: 360,
          ".MuiAlert-root": {
            flexDirection: "column",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        style: {
          zIndex: 1313,
        },
      },
    },
  },
});

export default theme;

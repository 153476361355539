import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAppState } from "core/applications";
import { useEffect, useState } from "react";
import { GuidedCommunication, State } from "..";
import MessageHub from "../../../message-hub";
import { GUIDED_COMMUNICATION_NEXT } from "../messages";

const GuidedCommunicationPanel = () => {
  const { open, message, showNext, actorImage } = useAppState<State>(
    GuidedCommunication.instance
  );
  const [hidden, setHidden] = useState(false);

  const handleNext = () => {
    MessageHub.send({
      type: GUIDED_COMMUNICATION_NEXT,
      payload: null,
    });
  };

  useEffect(() => {
    setHidden(false);
  }, [message]);

  if (!open) {
    return null;
  }

  return (
    <>
      <StyledHideButton
        variant="contained"
        endIcon={hidden ? null : <ChevronRightIcon />}
        startIcon={hidden ? <ChevronLeftIcon /> : null}
        onClick={() => setHidden(!hidden)}
      >
        {hidden ? "Show" : "Hide"}
      </StyledHideButton>
      <StyledMainContainer hidden={hidden}>
        <Box sx={{ height: "55%", position: "relative" }}>
          <StyledSpeachBubble>
            <StyledSpeachBubbleText>
              <span>{message}</span>
              {showNext && (
                <StyledNextButton onClick={handleNext}>Next</StyledNextButton>
              )}
            </StyledSpeachBubbleText>
          </StyledSpeachBubble>
        </Box>
        <Box sx={{ height: "45%", position: "relative" }}>
          <StyledImage src={actorImage} alt="Person" />
        </Box>
      </StyledMainContainer>
    </>
  );
};

const StyledNextButton = styled("button")(() => ({
  background: "none",
  border: "none",
  textDecoration: "underline",
  color: "#0A8066",
  display: "block",
  marginLeft: "auto",
}));

const StyledHideButton = styled(Button)(() => ({
  position: "fixed",
  right: "25px",
  bottom: "75px",
  zIndex: 2,
  background: "rgba(78, 101, 107, 0.98)",

  "&:hover": {
    background: "rgba(98, 121, 127, 0.98)",
  },
}));

const StyledMainContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "hidden",
})<{
  hidden: boolean;
}>(({ hidden }) => ({
  width: "25%",
  height: "100%",
  minWidth: "230px",
  maxWidth: "400px",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  right: hidden ? "-500px" : 0,
  opacity: hidden ? 0 : 1,
  bottom: 0,
  zIndex: 1,
  transition: "0.75s",
}));

const StyledSpeachBubbleText = styled("div")(() => ({
  overflow: "auto",
  maxHeight: "40vh",
  fontSize: "16px",

  p: {
    margin: 0,
  },
}));

const StyledSpeachBubble = styled("div")(() => ({
  width: "calc(100% - 16px)",
  right: "16px",
  position: "absolute",
  bottom: 0,
  background: "#fff",
  padding: "8px",
  borderRadius: "8px",
  border: "3px solid #6ea19e",

  "&:after": {
    border: "1em solid transparent",
    borderTopColor: "#fff",
    content: "''",
    marginLeft: "-1em",
    position: "absolute",
    top: "100%",
    left: "calc(50% + 8px)",
    width: "0",
    height: "0",
  },

  "&:before": {
    border: "1.3em solid transparent",
    borderTopColor: "#6ea19e",
    content: "''",
    marginLeft: "-1.2em",
    position: "absolute",
    top: "100%",
    left: "calc(50% + 7px)",
    width: "0",
    height: "0",
  },
}));

const StyledImage = styled("img")(() => ({
  marginTop: "32px",
  position: "relative",
  width: "100%",
}));

export default GuidedCommunicationPanel;

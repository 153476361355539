import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={64}
      viewBox="0 0 60 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.759 10l-6.207 14v35c0 2.2 1.862 4 4.138 4h4.138c2.275 0 4.137-1.8 4.137-4V24L52.76 10zM51.724 13h2.07"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.966 25h-6.207v30h6.207"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.035 18h4.138"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.035 54V1h16.551v35"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.035 30h6.207M1.035 42h4.138"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M9.31 11c1.143 0 2.07-.895 2.07-2s-.927-2-2.07-2c-1.142 0-2.068.895-2.068 2s.926 2 2.069 2z"
        fill="#00B2E3"
      />
      <path
        d="M39.931 42l-9.93-11L1.034 63h39.31"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 44.1L20.172 55h19.655L30 44.1z"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;

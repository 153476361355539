import { Message as MessageHubMessage } from "../../message-hub/interfaces";
import { Signed } from "./interfaces/signed";

export const CLOUD_APP_SELECT_PROVIDER = "cloud.app.select.provider";
export const CLOUD_APP_SIGN_UP = "cloud.app.sign.up";
export const CLOUD_APP_VERIFIED = "cloud.app.verified";
export const CLOUD_APP_2FACTOR_MOBILENUMBER_SET =
  "cloud.app.2factor.mobilenumber.set";
export const CLOUD_APP_2FACTOR_DEPOSIT_CODE = "cloud.app.2factor.deposit.code";
export const CLOUD_APP_2FACTOR_ACTIVATE_CODE =
  "cloud.app.2factor.activate.code";
export const CLOUD_APP_EMAIL_VERIFIED = "cloud.app.email.verified";
export const CLOUD_APP_INSTALL_APPS = "cloud.app.install.apps";
export const CLOUD_APP_READ_TERMS = "cloud.read.terms";
export const CLOUD_APP_READ_PRIVACY = "cloud.read.privacy";
export const CLOUD_SIGNUP_PERFECT = "cloud.signup.perfect";

export type CloudAppSelectProvider = MessageHubMessage<
  typeof CLOUD_APP_SELECT_PROVIDER,
  { provider: string }
>;

export type CloudAppPerfect = MessageHubMessage<
  typeof CLOUD_SIGNUP_PERFECT,
  undefined
>;

export type CloudAppSignUp = MessageHubMessage<
  typeof CLOUD_APP_SIGN_UP,
  Signed
>;

export type CloudAppVerified = MessageHubMessage<
  typeof CLOUD_APP_VERIFIED,
  boolean
>;

export type CloudApp2FactorMobileNumberSet = MessageHubMessage<
  typeof CLOUD_APP_2FACTOR_MOBILENUMBER_SET,
  {
    mobilenumber: string;
  }
>;

export type CloudApp2FactorDepositCode = MessageHubMessage<
  typeof CLOUD_APP_2FACTOR_DEPOSIT_CODE
>;

export type CloudApp2FactorActivateCode = MessageHubMessage<
  typeof CLOUD_APP_2FACTOR_ACTIVATE_CODE,
  { code: string }
>;

export type CloudAppEmailVerified = MessageHubMessage<
  typeof CLOUD_APP_EMAIL_VERIFIED
>;

export type CloudAppInstallApps = MessageHubMessage<
  typeof CLOUD_APP_INSTALL_APPS
>;

export interface CloudAppReadTerms {
  type: typeof CLOUD_APP_READ_TERMS;
  payload: boolean;
}

export interface CloudAppReadPrivacy {
  type: typeof CLOUD_APP_READ_PRIVACY;
  payload: boolean;
}

export type CloudAppMessage =
  | CloudAppSelectProvider
  | CloudAppSignUp
  | CloudAppVerified
  | CloudApp2FactorMobileNumberSet
  | CloudApp2FactorDepositCode
  | CloudApp2FactorActivateCode
  | CloudAppEmailVerified
  | CloudAppInstallApps
  | CloudAppReadTerms
  | CloudAppPerfect;

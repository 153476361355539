import loadable from "@loadable/component";
import menuHomeWhite from "./assets/menu-home-white.svg";
import menuHome from "./assets/menu-home.svg";
import menuNewsWhite from "./assets/menu-news-white.svg";
import menuNews from "./assets/menu-news.svg";
import menuReportWhite from "./assets/menu-report-white.svg";
import menuReport from "./assets/menu-report.svg";

const buildId = (k) => `dashboard.main.menu.${k}`;

export const pages = {
  HOME: {
    path: "/seasons",
    titleId: buildId("home"),
    iconWhite: menuHomeWhite,
    icon: menuHome,
    component: null,
  },
  PROFILE: {
    path: "/profile",
    titleId: "dashboard.main.profile-link",
    iconWhite: null,
    icon: null,
    component: loadable(() => import("./pages/profile/profile")),
  },
  NEWS: {
    path: "/news",
    titleId: buildId("news"),
    iconWhite: menuNewsWhite,
    icon: menuNews,
    component: loadable(() => import("./pages/news/news")),
  },
  REPORT: {
    path: "/report",
    titleId: buildId("report"),
    iconWhite: menuReportWhite,
    icon: menuReport,
    component: loadable(() => import("./pages/report/report")),
  },
  COMPANY: {
    path: "/managament-cockpit",
    titleId: buildId("company-report"),
    iconWhite: menuReportWhite,
    icon: menuReport,
    component: loadable(
      () => import("./pages/management-cockpit/management-cockpit")
    ),
  },
  ADMIN: {
    path: "/admin",
    titleId: buildId("admin"),
    iconWhite: menuReportWhite,
    icon: menuReport,
    component: loadable(() => import("./pages/admin/admin")),
  },
  INVITATION: {
    path: "/invitation",
    titleId: buildId("invitation"),
    iconWhite: null,
    icon: null,
    component: loadable(() => import("./pages/invitation/invitation")),
  },
} as const;

import { Box, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { generatePlayerEmailAddress } from "../../../core/services/accountSettings";
import { Settings } from "../interfaces";
import ImageSelection from "./image-selection";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "315px",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const avatars = Array.from({ length: 12 }).map(
  (_, ix) => `/avatars/avatar${ix + 1}.svg`
);

type PropsType = {
  settings: Settings;
  onSettingsChanged: (data: Settings) => any;
};

const PlayerAccount: React.FC<PropsType> = (props: PropsType) => {
  const { settings, onSettingsChanged } = props;
  const intl = useIntl();
  const [settingsInternal, setSettingsInternal] = useState<Settings>(settings);

  useEffect(() => {
    onSettingsChanged(settingsInternal);
  }, [settingsInternal]);

  return (
    <Grid container data-e2e-dashboard-my-player-profile-panel>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Box mb={8}>
              <StyledTextField
                label={intl.messages["playerName"]}
                id="playerName"
                variant="outlined"
                name="name"
                value={settingsInternal.playerName}
                onChange={(event) =>
                  setSettingsInternal((settings) => ({
                    ...settings,
                    playerName: event.target.value,
                    playerEmailAddress: generatePlayerEmailAddress(
                      event.target.value,
                      settingsInternal.companyURL
                    ),
                  }))
                }
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="label"
              display="block"
              mb={2}
            >
              {intl.messages["playerAvatar"]}
            </Typography>
          </Grid>
          <Grid item>
            <ImageSelection
              id="avatar"
              images={avatars}
              value={settingsInternal.playerAvatar}
              onSelect={(src, color) =>
                setSettingsInternal((settings) => ({
                  ...settings,
                  playerAvatar: src,
                  leadingColor: color || "",
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerAccount;

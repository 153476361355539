import React from "react";
import Avatar from "./components/avatar";
import ProgressBar from "./components/progress-bar";
import Scores from "./components/scores";
import { useStyles } from "./header.styles";

const AppHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Avatar />
      <ProgressBar />
      <Scores />
    </div>
  );
};

export default AppHeader;

import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useDeviceDetect, useSettings } from "../../hooks";
import anna from "../assets/anna.svg";
import classes from "./banner.module.scss";

export type BannerType = "home-demo" | "company-demo" | undefined;

export type DashboardBannerProps = {
  bannerType?: BannerType;
  position?: "fixed-top" | "fixed-bottom";
};

type BannerProps = {
  playerName?: string;
  intl: IntlShape;
};

const HomeDemoBanner = ({ playerName, intl }: BannerProps) => {
  const device = useDeviceDetect();
  return (
    <>
      <div className={classes.text}>
        <p>
          <FormattedMessage
            id={"dashboard.banner.demo.title"}
            values={{ player: playerName }}
          />
        </p>
        {device.mobile && (
          <p
            style={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{
              __html: intl.messages[
                "dashboard.banner.demo.description.mobile"
              ] as string,
            }}
          />
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: intl.messages[
              "dashboard.banner.demo.description"
            ] as string,
          }}
        />
      </div>
      <button
        className={`${classes.actionBtn} animate__animated animate__pulse animate__infinite animate__slow`}
      >
        <a
          href="https://talentdigital.eu/demo-success/"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="dashboard.banner.demo.btn" />
        </a>
      </button>
    </>
  );
};

const CompanyDemoBanner = ({ intl }: BannerProps) => {
  return (
    <div className={classes.companyBanner}>
      <div className={classes.content}>
        <img src={anna} alt={"anna"} />
        <div
          dangerouslySetInnerHTML={{
            __html: intl.messages["dashboard.banner.demo.company"] as string,
          }}
        />
      </div>
      <button className="animate__animated animate__pulse animate__infinite animate__slow">
        <a
          href="https://meetings.hubspot.com/gina-wietzig-wassenberg/30-min-demo-austausch-sales-team"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="dashboard.banner.demo.company.btn" />
        </a>
      </button>
    </div>
  );
};

const DashboardBanner = ({ bannerType, position }: DashboardBannerProps) => {
  const intl = useIntl();
  const { settings } = useSettings();
  const [fillerHeight, setFillerHeight] = useState(0);
  const banner = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (position) {
      const handleResize = () => {
        const height = banner?.current?.getBoundingClientRect().height || 0;
        setFillerHeight(height);
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  });

  const renderContent = () => {
    switch (bannerType) {
      case "home-demo":
        return <HomeDemoBanner playerName={settings.playerName} intl={intl} />;
      case "company-demo":
        return <CompanyDemoBanner intl={intl} />;
      default:
        return <></>;
    }
  };

  return bannerType ? (
    <>
      {position && <div style={{ height: `${fillerHeight}px` }} />}
      <div
        ref={banner}
        className={`${
          position ? classes.bannerFixed : classes.banner
        } animate__animated ${
          position === "fixed-bottom"
            ? "animate__slideInTop"
            : "animate__slideInDown"
        }`}
        style={position === "fixed-top" ? { top: 0 } : { bottom: 0 }}
      >
        {renderContent()}
      </div>
    </>
  ) : (
    <></>
  );
};

export default DashboardBanner;

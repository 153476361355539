import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Confetti from "react-confetti";
import styles from "./pop-up.styles";

type PopUpProps = {
  handleClose: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  actionsBody: React.ReactNode;
  withConfetti: boolean;
};

const PopUp = ({
  handleClose,
  children,
  actionsBody,
  withConfetti,
}: PopUpProps) => {
  const classes = styles();
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const onResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  });

  return (
    <>
      <div className={classes.conffettiContainer}>
        {withConfetti &&
          [0, 1].map((e) => (
            <Confetti
              key={e}
              width={windowSize.width}
              height={windowSize.height}
              confettiSource={{
                x: e === 1 ? 0 : windowSize.width,
                y: e === 1 ? windowSize.height : windowSize.height,
                w: 10,
                h: 10,
              }}
              numberOfPieces={750}
              recycle={false}
              initialVelocityY={35}
              initialVelocityX={25}
              tweenDuration={200}
            />
          ))}
      </div>

      <Dialog
        className={classes.dialog}
        fullScreen={fullScreen}
        open={true}
        onClose={() => handleClose(false)}
        scroll="paper"
        classes={{
          paper: classes.dialogRoot,
        }}
      >
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {actionsBody}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopUp;

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.1 49.5l-3.9 3.9c-1.6 1.6-1.6 4.1 0 5.7L5 61.8c1.6 1.6 4.1 1.6 5.7 0l8.6-8.6M48.5 24l8.8-8.9L63 1 48.9 6.7 45 10.5"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2 48.8l-6 6 4.2 4.3M56.6 15.8l-4.2-4.2-8 8M60.2 2.4l1.4 1.4M34 47l5-17V16M24 47l-5-17v-7"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56 33l-17-3H19.1L11 32"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2 16H18.8c-.5 0-1 .2-1.4.6L2.3 31.7c-.8.8-.8 2-.1 2.8l25.2 27.9c.8.9 2.3.9 3.1 0l25.2-27.9c.7-.8.7-2-.1-2.8l-15-15.1c-.4-.4-.9-.6-1.4-.6zM24.4 4.7l-1.8 3.7M12.1 4l1.4 3.9M6.7 14L3 12.2"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;

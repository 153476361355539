import OpenAI from "openai";

let openAiConnect: OpenAI;

const THIRTY_SECONDS = 30000;

export const openAiConnection = {
  getConnection: () => {
    if (!openAiConnect) throw new Error("OpenAI connection not initialized");

    return openAiConnect;
  },
  initalizeConnection: (apiKey: string) => {
    openAiConnect = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true, // TODO TI-2594
      timeout: THIRTY_SECONDS,
    });
  },
};

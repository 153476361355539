import { Tooltip, TooltipProps, Typography } from "@mui/material";

const DashboardTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={0}
      leaveTouchDelay={2000}
      {...props}
      title={<Typography variant="body2">{props.title}</Typography>}
    />
  );
};

export default DashboardTooltip;

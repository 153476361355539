export const competences: Record<string, string> = {
  All: "Alle",
  "Web search": "Websuche",
  "Online media search": "Online-Mediensuche",
  "Enterprise and desktop search": "Unternehmens- und Desktop-Suche",
  "Online data sources and data search": "Online-Datenquellen und Datensuche",
  "Alternative online content sources and search engines":
    "Alternative Online-Inhaltsquellen und Suchmaschinen",
  "Industry-specific online search": "Branchenspezifische Online-Suche",
  "Reliability and completeness of online information":
    "Zuverlässigkeit und Vollständigkeit von Online-Informationen",
  "Reliability and completeness of online data":
    "Zuverlässigkeit und Vollständigkeit der Online-Daten",
  "Analysis of tabular data": "Analyse der Tabellendaten",
  "Analysis of legacy and semi-structured data, big data and data mining":
    "Analyse von Altdaten und halbstrukturierten Daten, Big Data und Data Mining",
  "Real-time analytics": "Echtzeit-Analysen",
  "Data visualization": "Datenvisualisierung",
  "Customer analytics": "Kundenanalytik",
  "Industry-specific analytics": "Branchenspezifische Analysen",
  "Managing files": "Dateien verwalten",
  "Data modeling": "Datenmodellierung",
  "Digital content publishing": "Veröffentlichung digitaler Inhalte",
  "Non-realtime interaction": "Nicht-Echtzeit-Interaktion",
  "Real-time interaction": "Interaktion in Echtzeit",
  "Automating interaction": "Automatisierte Interaktion",
  "Modern interaction technologies": "Moderne Interaktionstechnologien",
  "File and document sharing": "Gemeinsame Nutzung von Dateien und Dokumenten",
  "Public sharing": "Öffentliche Freigabe",
  Accessibility: "Zugänglichkeit",
  "Usability and user-centered design":
    "Benutzerfreundlichkeit und nutzerzentriertes Design",
  "Team collaboration": "Teamzusammenarbeit",
  "Co-creation tools": "Werkzeuge für die Mitgestaltung",
  "Customer experience management": "Management von Kundenerfahrungen",
  "Customer collaboration": "Zusammenarbeit mit Kunden",
  Netiquette: "Netiquette",
  "Identity management": "Identitätsmanagement",
  "Reputation management": "Reputationsmanagement",
  "Basic content technology": "Grundlegende Content-Technologie",
  Web: "Web",
  Documents: "Dokumente",
  Presentations: "Präsentationen",
  "Graphics and design": "Grafik und Design",
  "Document and content management systems":
    "Dokument- und Inhaltsverwaltungssysteme",
  Licensing: "Lizenzierung",
  "Computer science fundamentals": "Grundlagen der Informatik",
  "Web development": "Web-Entwicklung",
  "Mobile development": "Mobile Entwicklung",
  "Low-code development": "Low-Code-Entwicklung",
  "Backend development": "Backend-Entwicklung",
  "Device security": "Gerätesicherheit",
  "IT security": "IT-Sicherheit",
  "Security processes, standards and certifications":
    "Sicherheitsprozesse, Standards und Zertifizierungen",
  "Personal IT security": "Persönliche IT-Sicherheit",
  "Data protection": "Datenschutz",
  "Health and well-being": "Gesundheit und Wohlbefinden",
  "Protecting the environment": "Schutz der Umwelt",
  "IT basics": "IT-Grundlagen",
  "Communication technologies": "Kommunikationstechniken",
  "Data center technologies": "Rechenzentrumstechnologien",
  Digitization: "Digitalisierung",
  "Operational efficiency": "Betriebliche Effizienz",
  "Digital controlling": "Digitale Steuerung",
  "Software-as-a-service": "Software-as-a-Service",
  "Digital business": "Digitales Geschäft",
  "Data science and artificial intelligence":
    "Data Science und künstliche Intelligenz",
  Blockchain: "Blockchain",
  "Internet of Things": "Internet der Dinge",
  "Learning management systems and eLearning":
    "Lernmanagementsysteme und eLearning",
  "Innovation management": "Innovationsmanagement",
};

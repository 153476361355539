import { StepLabel, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Quest } from "../../game-engine/rewinder";
import QuestStepIcon from "./quest-step-icon";

const styles = makeStyles({
  root: {
    height: "60px",
  },
  label: {
    color: "#fff",
    margin: 0,
    width: "95%",
  },
  disabled: {
    opacity: 0.5,
  },
  active: {
    color: "#00B2E2",
    fontWeight: "bold",
  },
  rewind: {
    color: "#FFB400",
    fontWeight: "bold",
  },
  completed: {
    color: "#fff",
  },
});

type QuestStepLabelProps = {
  quest: Quest;
  index: number;
  currentQuest: number;
  isSkipped: boolean;
  onRewind: (id) => void;
  isMobile: boolean;
};

const QuestStepLabel = (props: QuestStepLabelProps) => {
  const { quest, index, currentQuest, onRewind, isMobile, isSkipped } = props;
  const classes = styles();
  const [hover, setHover] = useState(false);
  const active = index === currentQuest;
  const completed = index < currentQuest;

  useEffect(() => {
    if (isMobile) {
      setHover(true);
    } else {
      setHover(false);
    }
  }, [isMobile]);

  const getLabelClass = () => {
    if (active && hover) return classes.rewind;
    if (active) return classes.active;
    if (!completed) return classes.disabled;
    if (completed && isSkipped) return classes.disabled;
    if (completed && hover) return classes.rewind;
    if (completed) return classes.completed;
  };

  const getCaption = () => {
    if (active)
      return (
        <Typography
          className={`${hover ? classes.rewind : classes.active}`}
          variant="caption"
        >
          {!hover ? (
            <FormattedMessage id="rewinder.quest.active" />
          ) : (
            <FormattedMessage id="rewinder.quest.active.restart" />
          )}
        </Typography>
      );
    if (completed && isSkipped) {
      return (
        <Typography
          className={`${classes.disabled} ${classes.completed}`}
          variant="caption"
        >
          <FormattedMessage id="rewinder.quest.skipped" />
        </Typography>
      );
    }
    if (index < currentQuest)
      return (
        <Typography
          className={`${hover ? classes.rewind : classes.completed}`}
          variant="caption"
        >
          <FormattedMessage id="rewinder.quest.completed" />
        </Typography>
      );
    return null;
  };

  return (
    <StepLabel
      className={classes.root}
      style={
        (completed || active) && !isSkipped
          ? {
              cursor: "pointer",
            }
          : {}
      }
      StepIconProps={{
        style: hover ? { background: "#FFB400" } : {},
        completed,
        active,
        error: completed && isSkipped,
      }}
      StepIconComponent={QuestStepIcon}
      optional={getCaption()}
      onMouseEnter={() => (completed || active) && setHover(true)}
      onMouseLeave={() => (completed || active) && setHover(false)}
      onClick={() => onRewind(quest.id)}
    >
      <Typography
        className={`${classes.label} ${getLabelClass()}`}
        variant={"body1"}
      >
        {quest.description}
      </Typography>
    </StepLabel>
  );
};

export default QuestStepLabel;

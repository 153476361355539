import {
  ClickAwayListener,
  Step,
  StepConnector,
  Stepper,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import MessageHub from "../../message-hub";
import { useQuestsList } from "../footer/hooks";
import { useDeviceDetect } from "../hooks";
import { REWIND_ANIMATION_START } from "../messages";
import QuestStepLabel from "./quest-step-label";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    width: "30vw",
    inset: "0 0 0 auto",
    zIndex: 1311,
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    color: "#fff",
    backdropFilter: "blur(10px)",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    paddingTop: `calc(${theme.spacing(1)} + 40px)`,
    paddingBottom: `calc(${theme.spacing(1)} + 60px)`,
    paddingLeft: theme.spacing(2),
    transition: "height 0.4s ease-out",
    [theme.breakpoints.down("lg")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
  },
  stepper: {
    background: "transparent",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const Connector = withStyles({
  root: {
    marginLeft: 18,
    marginTop: 5,
  },
  line: {
    width: 2,
    backgroundColor: "#ccc",
  },
})(StepConnector);

type QuestRewinderProps = {
  onRewind: (rewindId: string) => void;
  open: boolean;
  onClose: () => void;
};

const QuestRewinder = ({ onRewind, open, onClose }: QuestRewinderProps) => {
  const classes = useStyles();
  const { quests, refs } = useQuestsList();
  const [activeQuest, setActiveQuest] = useState(-1);
  const device = useDeviceDetect();

  useEffect(() => {
    if (refs && Object.values(refs).length) {
      const currentRefs = Object.values(refs);
      if (typeof currentRefs === "object") {
        const lastQuestRef = currentRefs[currentRefs.length - 1];

        const questIndex = quests.findIndex(
          (quest) => quest.id === lastQuestRef?.id
        );
        setActiveQuest(questIndex);
      }
    }
  });

  const onRewindToStep = (id) => {
    if (refs[id]) {
      MessageHub.send({
        type: REWIND_ANIMATION_START,
        payload: undefined,
      });
      onRewind(refs[id].stepId);
      onClose();
    }
  };

  if (!open) return null;

  return (
    <ClickAwayListener onClickAway={() => setTimeout(() => onClose())}>
      <div
        className={`${classes.root} animate__animated animate__slideInRight animate__faster`}
      >
        <Typography style={{ marginBottom: 8 }} variant="h5" align="center">
          <FormattedMessage id="rewinder.title" />
        </Typography>
        <Stepper
          className={classes.stepper}
          activeStep={activeQuest}
          orientation="vertical"
          connector={<Connector />}
        >
          {quests &&
            quests
              .filter(({ description }) => description)
              .map((quest, ix) => (
                <Step key={quest.id}>
                  <QuestStepLabel
                    quest={quest}
                    index={ix}
                    currentQuest={activeQuest}
                    isSkipped={refs[quest.id] ? false : true}
                    onRewind={onRewindToStep}
                    isMobile={device.mobile}
                  />
                </Step>
              ))}
        </Stepper>
      </div>
    </ClickAwayListener>
  );
};

export default QuestRewinder;

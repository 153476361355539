import loadable from "@loadable/component";
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import "@mui/styles";
import React, { useEffect, useState } from "react";
import { RawIntlProvider } from "react-intl";
import { HashRouter, useHistory } from "react-router-dom";
import GameEngine from "../game-engine";
import { Episode } from "../game-engine/interfaces";
import { getIntl } from "../i18n";
import RewindAnimation from "./animations/rewind/rewind";
import Dashboard from "./dashboard/dashboard";
import { Game } from "./game";
import {
  useGetEpisodeFromUrl,
  useLanguage,
  useNotification,
  useOddResolutionDetect,
  useRewindAnimation,
  useShowBuyer,
} from "./hooks";
import { MessageHubDebugTool } from "./message-hub-debug-tool";
import ModalNotification from "./notifications/modal";
import theme from "./theme";
import { Warning } from "./warning/warning";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const Tour = loadable(() => import("../onboarding/tour/tour"));
const OnboardingBuyer = loadable(() => import("../onboarding/buyer/component"));

export const AppRoot = () => {
  return (
    <HashRouter>
      <AppRootInner />
    </HashRouter>
  );
};

const AppRootInner: React.FC = () => {
  const history = useHistory();
  const gameEngine = GameEngine();
  const language = useLanguage();

  const rewindAnimation = useRewindAnimation();
  const [notification, removeNotification] = useNotification();
  const { isBuyer, buyerTourDone, onDone: onBuyerTourDone } = useShowBuyer();
  const { episode: forcedEpisodeFromUrl } = useGetEpisodeFromUrl();

  const [tour, setTour] = useState(false);
  const [episode, setEpisode] = useState<Episode>();
  const [clickedEpisode, setClickedEpisode] = useState<Episode>();
  const [lastEpisode, setLastEpisode] = useState<Episode>();
  const isOddResolution = useOddResolutionDetect();
  const [confirmWarning, setConfirmWarning] = useState(false);

  const onTourDone = async () => {
    gameEngine.finishTour();

    setTour(false);

    if (clickedEpisode) {
      setClickedEpisode(undefined);
      setEpisode(clickedEpisode);
    }
  };

  const onExitGame = () => {
    gameEngine.episodes.pause(episode?.id);
    setLastEpisode(episode);
    if (gameEngine.episodes.getForcedEpisode()?.redirectUrl) {
      window.location.href =
        gameEngine.episodes.getForcedEpisode()?.redirectUrl || "/";
    } else {
      history.push(`/seasons/${episode?.seasonId}`);
      setEpisode(undefined);
    }
  };

  const onPlay = async (episode?: Episode) => {
    if (!episode) return;

    if (episode.format?.length) {
      await gameEngine.beforeStartExternalEpisode(episode);
      const redirectUrl = window.location.href.includes(episode.seasonId)
        ? window.location.href
        : `${window.location.href}/${episode.seasonId}`;

      const redirectUrlEncoded = encodeURIComponent(redirectUrl);
      const seasonIdEncoded = encodeURIComponent(episode.seasonId);
      const episodeIdEncoded = encodeURIComponent(episode.id);

      window.location.href = `app/${episode.format}?sid=${seasonIdEncoded}&eid=${episodeIdEncoded}&redirectUrl=${redirectUrlEncoded}`;
    } else {
      setClickedEpisode(episode);

      if (!gameEngine.tourDone) {
        return setTour(true);
      }

      await gameEngine.beforeStartEpisode(episode);

      setEpisode(episode);
    }
  };

  const onBoardingBuyerDone = async () => {
    onBuyerTourDone();
    setEpisode(undefined);
    history.push("/managament-cockpit");
  };

  useEffect(() => {
    if (forcedEpisodeFromUrl) setEpisode(forcedEpisodeFromUrl);
  }, [forcedEpisodeFromUrl]);

  useEffect(() => {
    // Only redirect on the root path
    if (buyerTourDone && isBuyer && history.location.pathname === "/") {
      history.push("/company-report");
    }
  }, [isBuyer, buyerTourDone]);

  const content = () => {
    if (!buyerTourDone) return <OnboardingBuyer onDone={onBoardingBuyerDone} />;
    if (tour) return <Tour onDone={onTourDone} />;
    if (episode) return <Game episode={episode} onExit={onExitGame} />;

    return <Dashboard lastEpisode={lastEpisode} onPlay={onPlay} />;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RawIntlProvider value={getIntl(language)}>
          <MessageHubDebugTool />

          <CssBaseline />
          {rewindAnimation && <RewindAnimation />}
          {notification && (
            <ModalNotification
              notification={notification}
              onDone={removeNotification}
            />
          )}
          {content()}
          {!confirmWarning && isOddResolution && (
            <Warning
              onConfirm={() => setConfirmWarning(true)}
              message="global.warning.message"
              confirmText="global.warning.confirm"
            />
          )}
        </RawIntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppRoot;

import { Message } from "../../message-hub/interfaces";
import { Question } from "./interfaces";

export const ZEN_START = "zen.start";
export const ZEN_ANSWER = "zen.answer";
export const ZEN_DONE = "zen.done";

export type zenStart = Message<typeof ZEN_START, undefined>;
export type zenAnswer = Message<
  typeof ZEN_ANSWER,
  { question: Question["id"]; selected: number[] | null; failed: boolean }
>;
export type zenDone = Message<typeof ZEN_DONE, undefined>;

export const messagesTypes = {
  ZEN_START,
  ZEN_ANSWER,
  ZEN_DONE,
} as const;

export type zenMessages = zenStart | zenAnswer | zenDone;

import { h32 } from "xxhashjs";

export const HASH_SALT = 0xabcd;

export const createEpisodeUrl = (episodeUrl: string): string => {
  const searchParams = new URLSearchParams({
    run: createEpisodeHash(episodeUrl),
  });

  return `${window.location.origin}?${searchParams}`;
};

export const createEpisodeHash = (episodeId: string): string => {
  return h32(episodeId, HASH_SALT).toString(16);
};

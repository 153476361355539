import { Message } from "../../message-hub/interfaces";
import { Security } from "./interfaces";

export const SF_TOUR_START = "SPAMFIGHTER.TOUR.START";
export const SF_TOUR_END = "SPAMFIGHTER.TOUR.END";
export const SF_ROUND_START = "SPAMFIGHTER.ROUND.START";
export const SF_COUNTDOWN = "SPAMFIGHTER.COUNTDOWN.START";
export const SF_ROUND_PLAY = "SPAMFIGHTER.ROUND.PLAY";
export const SF_ROUND_SKIP = "SPAMFIGHTER.ROUND.SKIP";
export const SF_ROUND_END = "SPAMFIGHTER.ROUND.END";
export const SF_ROUND_TIMEOUT = "SPAMFIGHTER.ROUND.TIMEOUT";
export const SF_CHALLENGE_ANSWER = "SPAMFIGHTER.CHALLENGE.ANSWER";
export const SF_CHALLENGE_CORRECT = "SPAMFIGHTER.CHALLENGE.CORRECT";
export const SF_CHALLENGE_FAIL = "SPAMFIGHTER.CHALLENGE.FAIL";
export const SF_CHALLENGE_HELP = "SPAMFIGHTER.CHALLENGE.HELP";
export const SF_COMPLETE = "SPAMFIGHTER.COMPLETE";
export const SF_HELP_CLOSE = "SPAMFIGHTER.HELP.CLOSE";
export const SF_EXIT = "SPAMFIGHTER.EXIT";

export type tourStart = Message<typeof SF_TOUR_START, null>;

export type tourEnd = Message<typeof SF_TOUR_END, null>;

export type roundStart = Message<
  typeof SF_ROUND_START,
  {
    round: string;
  }
>;

export type roundPlay = Message<typeof SF_ROUND_PLAY, null>;

export type countDown = Message<typeof SF_COUNTDOWN, null>;

export type roundSkip = Message<
  typeof SF_ROUND_SKIP,
  {
    round: string;
  }
>;

export type roundEnd = Message<
  typeof SF_ROUND_END,
  {
    round: string;
  }
>;

export type roundTimeout = Message<
  typeof SF_ROUND_TIMEOUT,
  {
    round: string;
  }
>;

export type challengeAnswer = Message<
  typeof SF_CHALLENGE_ANSWER,
  {
    round: string;
    challenge: string;
    result: Security;
    success: boolean;
  }
>;

export type challengeCorrect = Message<
  typeof SF_CHALLENGE_CORRECT,
  {
    round: string;
    challenge: string;
  }
>;

export type challengeFail = Message<
  typeof SF_CHALLENGE_FAIL,
  {
    round: string;
    challenge: string;
  }
>;

export type challengeHelp = Message<
  typeof SF_CHALLENGE_HELP,
  {
    round: string;
    challenge: string;
    type: "ask" | "search";
  }
>;

export type helpClose = Message<typeof SF_HELP_CLOSE, null>;

export type complete = Message<typeof SF_COMPLETE, null>;

export type exit = Message<typeof SF_EXIT, null>;

export type sfMessages =
  | tourStart
  | tourEnd
  | roundStart
  | countDown
  | roundPlay
  | roundEnd
  | roundSkip
  | roundTimeout
  | challengeAnswer
  | challengeCorrect
  | challengeFail
  | challengeHelp
  | complete
  | helpClose
  | exit;

import { ClickAwayListener, Theme, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CONFIG from "../../../config";
import { useAppState } from "../../applications";
import { DiamondIcon } from "../../diamond-icon/diamond-icon";
import { CompetenceLevel } from "../../enums";
import badge from "../assets/badge.svg";
import coins from "../assets/coins.svg";
import { useBadges, useEngagementPoints, useEpisodeTests } from "../hooks";
import { AppHeader } from "../index";
import { State } from "../state";
import { useStyles } from "./scores.styles";

const ScoreTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    minWidth: 500,
    backgroundColor: "#116E6B",
    maxHeight: "80vh",
    overflowY: "auto",
    color: "#fff",
    fontSize: 12,
    borderRadius: 16,
    border: "3px solid #fff",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90vw",
      minWidth: 0,
      maxHeight: "80vh",
      overflowY: "auto",
      "& h1": {
        fontSize: 14,
      },
    },
  },
}))(Tooltip);

const Scores = () => {
  const classes = useStyles();
  const engagementPoints = useEngagementPoints();
  const { obtained, total } = useBadges();
  const [competenceScore, setCompetenceScore] = useState({
    passed: 0,
    total: 0,
  });

  const [scoreTooltipOpen, setScoreTooltipOpen] = useState(false);

  const { completedTests } = useAppState<State>(AppHeader.instance);

  const testDetails = useEpisodeTests();

  useEffect(() => {
    const totalCompleted = completedTests.length;
    if (!isEmpty(testDetails)) {
      const total = Object.keys(testDetails).length;
      const passed = completedTests.filter(
        (result) => result?.value === 1
      ).length;

      setCompetenceScore({ passed, total });
      if (CONFIG.ANIMATIONS_ENABLED && totalCompleted > 0) {
        setTimeout(() => {
          setScoreTooltipOpen(true);
        }, 200);

        setTimeout(() => {
          setScoreTooltipOpen(false);
        }, 3000);
      }
    }
  }, [completedTests, testDetails]);

  const getScoreTooltipContent = () => (
    <div className={classes.testsList}>
      <h1>
        <FormattedMessage id="header.score.heading" />
      </h1>
      {completedTests.length === 0 ? (
        <FormattedMessage id="header.score.no-tests" />
      ) : (
        completedTests.map((result, i) => {
          if (!result) return;
          const { id, value } = result;

          if (!testDetails[id]) return;

          return (
            <div
              key={id}
              style={{
                opacity: value === 1 ? 1 : 0.5,
                fontWeight: i === 0 ? "bold" : "normal",
                textDecoration: value === 0 ? "line-through" : "none",
              }}
            >
              <DiamondIcon
                level={testDetails[id].level}
                className={classes.icon}
              />
              {testDetails[id].description}
            </div>
          );
        })
      )}
    </div>
  );

  return (
    <div className={classes.scores}>
      <ClickAwayListener onClickAway={() => setScoreTooltipOpen(false)}>
        <div>
          <ScoreTooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={scoreTooltipOpen}
            title={getScoreTooltipContent()}
          >
            <div
              id="game-competence-diamonds"
              className={`${classes.score} ${classes.clickable}`}
              onClick={() => setScoreTooltipOpen(!scoreTooltipOpen)}
            >
              <DiamondIcon
                level={CompetenceLevel.Foundation}
                tooltip={false}
                className={classes.icon}
              />
              <p>
                {competenceScore.passed}
                <span className={classes.total}>
                  {" "}
                  / {competenceScore.total}
                </span>
              </p>
            </div>
          </ScoreTooltip>
        </div>
      </ClickAwayListener>
      <div className={classes.score}>
        <img src={badge} alt="Badges" className={classes.icon} />
        <p>
          {obtained || 0} <span className={classes.total}>/ {total || 0}</span>
        </p>
      </div>
      <div id="game-competence-score" className={classes.score}>
        <img src={coins} alt="Score" className={classes.icon} />
        <p>{engagementPoints}</p>
      </div>
    </div>
  );
};

export default Scores;

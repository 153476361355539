import { styled, useTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styled-engine";
import { useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import DecisionApp from "..";
import { DecisionOption } from "../interfaces/decision-option";
import { DecisionPayload } from "../interfaces/decision-payload";

type PropsType = {
  decision?: DecisionPayload;
};

export const Overflow = ({ decision }: PropsType) => {
  const [classes, setClasses] = useState("fadeIn");
  const theme = useTheme();

  const onSelect = (option: DecisionOption) => {
    if (!decision) return;

    setClasses("fadeOut");

    setTimeout(() => {
      DecisionApp.instance.choose(decision.id, option);
    }, theme.custom.ANIMATIONS_SPEED_CONFIG.animate__faster);
  };

  return createPortal(
    <StyledContainerBox>
      <StyledContentBox
        className={`animate__animated animate__${classes} animate__faster`}
        data-e2e-decision-panel
      >
        <StyledTitle>
          <p>{decision?.title ?? <FormattedMessage id="decisions.title" />}</p>
        </StyledTitle>
        {decision?.options.map((option, idx) => (
          <StyledOptionBox
            data-e2e-decision-panel-button={idx + 1}
            id={option.id as string}
            key={option.id}
            onClick={() => onSelect(option)}
            lastMarginBottom={getLastMarginBottom(decision)}
          >
            {option.title && (
              <StyledOptionTitle>{option.title}</StyledOptionTitle>
            )}
            <StyledOptionText>{option.text}</StyledOptionText>
          </StyledOptionBox>
        ))}
      </StyledContentBox>
    </StyledContainerBox>,
    document.body
  );
};

const StyledContainerBox = styled("div")(({ theme }) => ({
  zIndex: theme.custom.zIndex.decision,
  width: "100%",
  height: "100%",
  // Be cautious when trying to add position: "absolute" or "relative here" might break in
  // unexpected episodes
}));

const StyledContentBox = styled("div")(({ theme }) => ({
  width: "60vw",
  height: "80vh",
  margin: "10vh 20vw",
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  backgroundColor: "rgba(1, 13, 19, 0.7)",
  backdropFilter: "blur(10px)",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,

  overflow: "auto",

  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    height: "100vh",
    margin: 0,
    borderRadius: 0,
  },
}));

const StyledTitle = styled("p")(({ theme }) => ({
  color: "white",
  fontSize: 14,
  margin: 14,
  width: "min(80%, 600px)",

  "& p": {
    margin: 16,
  },

  [theme.breakpoints.down("sm")]: {
    marginTop: 60,
    marginLeft: 15,
    marginRight: 15,
  },
}));

const StyledOptionBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "lastMarginBottom",
})<{
  lastMarginBottom: number;
}>(({ lastMarginBottom, theme }) => ({
  backgroundColor: "#00B2E3",
  border: "1px solid white",
  borderRadius: 8,
  width: "min(80%, 600px)",
  cursor: "pointer",
  transition: "background-color 300ms linear, transform 150ms linear",

  "&:hover": {
    backgroundColor: "#31cdf7",
    transform: "scale(1.03)",
  },

  "&:last-of-type": {
    marginBottom: lastMarginBottom,
  },

  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const TextBase: CSSProperties = {
  color: "#ECF0F4",
  fontFamily: "Roboto",
  margin: 16,
};

const StyledOptionTitle = styled("p")({
  ...TextBase,
  fontSize: 24,
  textAlign: "center",
});

const StyledOptionText = styled("p")({
  ...TextBase,
  fontSize: 16,
});

/*
  This function is not an ideal solution to having too long decision titles but it
  does allow to see all options and it does not break the layout in other episodes as
  other solutions did.
*/
function getLastMarginBottom(decision: DecisionPayload | undefined) {
  const optionsLength = decision?.options.reduce((sum, item) => {
    return sum + item.text.length;
  }, 0);
  const titleLengthCalculated = (decision?.title?.length ?? 1) / 6;
  const optionsLengthCalculated = (optionsLength ?? 1) / 6;
  const responsiveCorrection = Math.max(1250 - window.innerWidth, 0) / 4;

  return titleLengthCalculated + optionsLengthCalculated + responsiveCorrection;
}

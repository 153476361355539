import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={64}
      height={63}
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0)"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
      >
        <path d="M13.333 32.17c3.067 0 5.334-2.547 5.334-5.764V24.53c0-3.217-2.267-5.763-5.334-5.763C10.267 18.766 8 21.312 8 24.53v1.876c0 3.217 2.267 5.764 5.333 5.764z" />
        <path
          d="M22.667 38.872c-1.734-.938-3.867-2.547-6.667-2.68H9.334c-4.8 0-8 2.01-8 8.042v5.362c0 5.361 4.266 6.702 8 6.702h5.333"
          strokeLinejoin="round"
        />
        <path
          d="M8 42.894v5.361c0 .804.533 1.34 1.333 1.34h8M6.667 56.298V63M21.334 58.978V63M30.4 53.617h-5.066L28 48.255h4.533c.934 0 1.6 1.073 1.2 1.877l-.933 1.877c-.533 1.072-1.466 1.608-2.4 1.608zM13.334 41.553l5.333 6.702"
          strokeLinejoin="round"
        />
        <path
          d="M32 48.255l2.667-5.361H21.333l-8 16.085h13.334l2.666-5.362M37.334 32.17v-1.34c0-1.207-1.067-2.28-1.867-3.217-1.2-1.34-2.133-2.95-2.133-4.826 0-3.753 2.933-6.702 6.666-6.702 3.733 0 6.667 2.949 6.667 6.702 0 2.01-.8 3.62-2.134 4.826-.8.938-1.866 2.01-1.866 3.217v1.34h-5.334zM40 12.064V5.362M31.467 14.208l-3.734-3.753M29.334 22.787h-6.667M31.467 31.366l-3.734 3.753M48.534 31.366l3.733 3.753M50.667 22.787h6.667M48.534 14.208l3.733-3.753M37.334 32.17v2.68l1.333 1.341h2.666l1.334-1.34V32.17"
          strokeLinejoin="round"
        />
        <path
          d="M16 14.745V5.362c0-2.28 1.733-4.022 4-4.022h38.667c2.266 0 4 1.743 4 4.022v33.51c0 2.279-1.734 4.022-4 4.022h-4l-8.4 9.383c-.8.938-2.267.402-2.267-.939v-8.444h-5.333"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h64v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;

import { filter, Observable, skipWhile } from "rxjs";
import { postEvents } from "../core/client";
import { DECISION_CHOOSE } from "../core/decision/messages";
import { Message } from "../message-hub/interfaces";
import { SCRIPT_REPLAY_DONE } from "./messages";

export const startDecisionUploader = (
  messages: Observable<Message>,
  seasonId: string,
  episodeId: string
): void => {
  messages
    .pipe(
      skipWhile((msg) => msg.type !== SCRIPT_REPLAY_DONE),
      filter((msg) => msg.type === DECISION_CHOOSE && msg.payload.decision)
    )
    .subscribe((msg) => {
      postEvents(
        [
          {
            eventTypeId: msg.payload.decision,
            ...msg,
          },
        ],
        seasonId,
        episodeId
      );
    });
};

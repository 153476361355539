import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import browserIcon from "../core/browser/browser-icon.svg";
import GameEngine from "../game-engine";
import { Episode } from "../game-engine/interfaces";
import { getLanguage } from "../i18n";
import MessageHub from "../message-hub";
import { Settings } from "../onboarding/player/interfaces";
import { defaultSettings } from "../onboarding/player/metadata";
import { ofTypes, useAppState } from "./applications";
import { AppLauncher } from "./index";
import { Actor, Application, State, Tour } from "./interfaces";
import { userHasRole } from "./keycloak";
import {
  ACTOR_CHANGE,
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
  REWIND_ANIMATION_START,
  REWIND_ANIMATION_STOP,
} from "./messages";
import { NOTIFICATION_TRANSFORM } from "./notifications/messages";
import { getItem, setItem } from "./storage";

export interface Device {
  mobile: boolean;
  browser: boolean;
}
export interface Orientation {
  landscape: boolean;
  portrait: boolean;
}

export const useQuery = (): URLSearchParams => {
  const location = useLocation();
  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams());

  useEffect(() => {
    setQuery(new URLSearchParams(location.search));
  }, [location, location.search]);

  return query;
};

export function useDeviceDetect(): Device {
  const query =
    "(max-width: 600px), (max-height: 600px) and (orientation: landscape)";
  const isMobile = window.matchMedia(query);

  const [device, setDevice] = useState<Device>({
    mobile: isMobile.matches,
    browser: !isMobile.matches,
  });

  useEffect(() => {
    const mediaQueryList = matchMedia(query);

    const onChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setDevice({
          mobile: true,
          browser: false,
        });
      } else {
        setDevice({
          mobile: false,
          browser: true,
        });
      }
    };

    /**
     * Because Safari still didn't fix a bug since 2019 we need to use this deprecated methods
     * https://github.com/mdn/sprints/issues/858
     */
    mediaQueryList.addListener(onChange);

    return () => mediaQueryList.removeListener(onChange);
  }, []);

  return device;
}

export const useOrientationDetect = (): Orientation => {
  const query = "(orientation: landscape)";
  const orientation = window.matchMedia(query);

  const [device, setDevice] = useState<Orientation>({
    landscape: orientation.matches,
    portrait: !orientation.matches,
  });

  useEffect(() => {
    const mediaQueryList = matchMedia(query);

    const onChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setDevice({
          landscape: true,
          portrait: false,
        });
      } else {
        setDevice({
          landscape: false,
          portrait: true,
        });
      }
    };

    /**
     * Because Safari still didn't fix a bug since 2019 we need to use this deprecated methods
     * https://github.com/mdn/sprints/issues/858
     */
    mediaQueryList.addListener(onChange);

    return () => mediaQueryList.removeListener(onChange);
  }, []);

  return device;
};

export const useOddResolutionDetect = () => {
  const query = "(max-width: 374px),(max-height: 659px)";
  const orientation = window.matchMedia(query);
  const [isOddResolution, setIsOddResolution] = useState(orientation.matches);

  useEffect(() => {
    const mediaQueryList = matchMedia(query);

    const onChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setIsOddResolution(true);
      } else {
        setIsOddResolution(false);
      }
    };

    /**
     * Because Safari still didn't fix a bug since 2019 we need to use this deprecated methods
     * https://github.com/mdn/sprints/issues/858
     */
    mediaQueryList.addListener(onChange);

    return () => mediaQueryList.removeListener(onChange);
  }, []);

  return isOddResolution;
};

let layout = {
  appLeft: 0,
  appRight: 0,
};
let layoutSetters: ((layout) => void)[] = [];

export function updateAppLayout(layoutChanges: Partial<typeof layout>) {
  layout = { ...layout, ...layoutChanges };
  layoutSetters.forEach((s) => s(layout));
}

export function useAppLayout() {
  const [stateLayout, setLayout] = useState(layout);
  useEffect(() => {
    layoutSetters.push(setLayout);
    return () => {
      layoutSetters = layoutSetters.filter((s) => s !== setLayout);
    };
  }, [setLayout]);
  return stateLayout;
}

export function openLink(history, link: string, state?: string) {
  let isExternal = false;
  let pathname = "";
  try {
    const url = new URL(link);
    isExternal = url.origin !== window.location.origin;
    pathname = url.pathname;
  } catch (e) {
    pathname = link;
  }
  if (isExternal) {
    window.open(link);
  } else {
    history.push({ pathname, state });
  }
}

export function useHref(
  state?: string,
  onClickCallback?: (element: any) => any
) {
  const history = useHistory();

  return useCallback(
    (node) => {
      const onClick = (e: any) => {
        const { target } = e;
        if (target.nodeName === "A") {
          e.preventDefault();
          openLink(history, target.href, state);
          if (onClickCallback) {
            onClickCallback(target);
          }
        }
      };
      if (node) {
        node.addEventListener("click", onClick);
      }
    },
    [history]
  );
}

export const useThrottledResizeObserver = () => {
  const [size, setSize] = useState<any>({});
  const { ref } = useResizeObserver({ onResize: setSize });
  return { ref, ...size };
};

export const useVideoLayerAndHref = (
  state?: string,
  onClickCallback?: (element: any) => any
) => {
  const history = useHistory();

  return useCallback(
    (node) => {
      const onClick = (e: any) => {
        const { target } = e;
        if (target.nodeName === "A") {
          e.preventDefault();
          openLink(history, target.href, state);
          if (onClickCallback) {
            onClickCallback(target);
          }
        }
      };
      if (node) {
        node.addEventListener("click", onClick);

        const videos = node.querySelectorAll("video");

        videos.forEach((video) => {
          const videoClone = video.cloneNode(true);

          const div = document.createElement("div");
          div.style.position = "relative";

          const buttonContainer = document.createElement("div");
          buttonContainer.style.position = "absolute";
          buttonContainer.style.height = "100%";
          buttonContainer.style.width = "100%";
          buttonContainer.style.zIndex = "999";
          buttonContainer.style.display = "flex";
          buttonContainer.style.alignItems = "center";
          buttonContainer.style.justifyContent = "center";
          buttonContainer.style.cursor = "pointer";

          const img = document.createElement("img");
          img.src = "/icons/play_circle_outline_black_24dp.svg";
          img.style.width = "72px";
          img.style.height = "72px";

          buttonContainer.appendChild(img);

          const containerClickCallback = function () {
            buttonContainer.removeEventListener(
              "click",
              containerClickCallback
            );
            div.removeChild(buttonContainer);
            videoClone.play();
          };

          buttonContainer.addEventListener("click", containerClickCallback);

          div.appendChild(buttonContainer);
          div.appendChild(videoClone);
          console.log("vid", video);
          video.replaceWith(div);
        });
      }
    },
    [history]
  );
};

export type AppsHome = Pick<
  Application,
  "id" | "icon" | "name" | "path" | "color" | "notification"
>;

export const useLanguage = getLanguage;

const wrapActorsInProxy = (actors = {}): Record<Actor["id"], Actor> =>
  new Proxy(actors, {
    get: function (obj, id: string) {
      if (id in obj) {
        return obj[id];
      }
      return {
        id,
        name: `${id} 🚧`,
        shortname: id,
        email: `${id}@gmail.com`,
        avatarUrl: "/episodes/s01-e05/images/avatar-random.svg",
      };
    },
  });

const toOutState = (state: State, instance) => {
  let includeBrowser = false;
  const browser: Application[] = [];
  const home: AppsHome[] = [];
  const router: Application[] = [];
  Object.values(state.apps).forEach((a) => {
    if (!a) return;
    if (a.in.home) home.push(a);
    if (a.in.router) router.push(a);
    if (a.browser) browser.push(a);
    if (!includeBrowser && a.browser) {
      includeBrowser = true;
      home.push({
        id: "browser",
        name: "Browser",
        color: "#F9B075",
        icon: browserIcon,
        path: "/browser",
        notification: 0,
      });
    }
  });
  return {
    ...state,
    actors: wrapActorsInProxy(state.actors),
    appsBrowser: browser,
    appsHome: home,
    appsRouter: router,
    language: useLanguage(),
    notification: state.notifications[0],
    removeNotification: instance.removeNotification,
    onRunAnimation: instance.onRunAnimation,
    onStopAnimation: instance.onStopAnimation,
  };
};

export function useRootState() {
  const { instance } = AppLauncher;
  const state = useAppState<State>(instance);
  const [outState, setOutState] = useState(toOutState(state, instance));
  useEffect(() => {
    setOutState(toOutState(state, instance));
  }, [state]);
  return outState;
}

export const setSettings = (
  settings: Settings = getItem("SETTINGS") || defaultSettings(getLanguage())
) => {
  setItem("SETTINGS", settings);
  const avatarPart = settings.playerAvatar.substr(
    0,
    settings.playerAvatar.length - 4
  );
  const background = "train";
  MessageHub.send({
    type: ACTOR_CHANGE,
    save: false,
    payload: {
      id: "player",
      name: settings.playerName,
      shortname: settings.playerName,
      avatarUrl: settings.playerAvatar,
      leadingColor: settings.leadingColor,
      email: settings.playerEmailAddress,
      sprites: {
        faceHappy: `${avatarPart}_face_happy.svg`,
        faceNeutral: `${avatarPart}_face_neutral.svg`,
        faceShocked: `${avatarPart}_face_shocked.svg`,
        faceSmile: `${avatarPart}_face_smile.svg`,
        faceWorried: `${avatarPart}_face_worried.svg`,
        face: `${avatarPart}.svg`,
        angry: `${avatarPart}_angry.svg`,
        excited: `${avatarPart}_excited.svg`,
        happy: `${avatarPart}_happy.svg`,
        reflective: `${avatarPart}_reflective.svg`,
        shocked: `${avatarPart}_shocked.svg`,
        tired: `${avatarPart}_tired.svg`,
        shy: `${avatarPart}_shy.svg`,
        sad: `${avatarPart}_sad.svg`,
      },
      background,
      backgroundUrl: `/actors/backgrounds/${background}.svg`,
    } as Actor,
  });
};

export const useSettings = () => {
  return {
    settings: (getItem("SETTINGS") ||
      defaultSettings(getLanguage())) as Settings,
    setSettings,
  };
};

export function useTourState(): Tour {
  const { instance } = AppLauncher;
  const state = useAppState<State>(instance);
  const [tour, setTour] = useState<Tour>(state.tour);

  useEffect(() => {
    setTour(state.tour);
  }, [state]);

  return tour;
}

const removeNotification = () => {
  MessageHub.send({
    type: NOTIFICATION_REMOVE,
    payload: undefined,
  });
};

export const useNotification = () => {
  const [notification, setNotification] = useState([] as any[]);
  const decoratorRef = useRef((n) => n);
  useEffect(() => {
    const sub = MessageHub.subject
      .pipe(
        ofTypes(NOTIFICATION_ADD, NOTIFICATION_REMOVE, NOTIFICATION_TRANSFORM)
      )
      .subscribe((msg) => {
        switch (msg.type) {
          case NOTIFICATION_ADD:
            setNotification((n) => [...n, decoratorRef.current(msg.payload)]);
            break;
          case NOTIFICATION_REMOVE:
            setNotification((n) => n.filter((_, ix) => ix > 0));
            break;
          case NOTIFICATION_TRANSFORM:
            decoratorRef.current = msg.payload || ((n) => n);
            break;
          default:
            break;
        }
      });
    return () => sub.unsubscribe();
  }, []);
  return [notification[0], removeNotification];
};

export const useRewindAnimation = () => {
  const [rewindAnimation, setRewindAnimation] = useState(false);
  useEffect(() => {
    const sub = MessageHub.subject
      .pipe(ofTypes(REWIND_ANIMATION_START, REWIND_ANIMATION_STOP))
      .subscribe((msg) => {
        switch (msg.type) {
          case REWIND_ANIMATION_START:
            setRewindAnimation(true);
            break;
          case REWIND_ANIMATION_STOP:
            setRewindAnimation(false);
            break;
          default:
            break;
        }
      });
    return () => sub.unsubscribe();
  }, []);

  return rewindAnimation;
};

export const useShowBuyer = () => {
  const params = new URLSearchParams(window.location.search);
  const episodeId = params.get("eid");
  const seasonId = params.get("sid");
  const redirectUrl = params.get("redirectUrl");

  const BUYER_ROLE = "talent_company_report";
  const storageKey = "onboardBuyerDone";
  const [isBuyer] = useState(() => userHasRole(BUYER_ROLE));
  const cockpitUser =
    Boolean(seasonId) && Boolean(episodeId) && Boolean(redirectUrl);
  const [buyerTourDone, setBuyerTourDone] = useState(
    () => !isBuyer || getItem(storageKey) || cockpitUser
  );
  const onDone = () => {
    setBuyerTourDone(true);
    setItem(storageKey, true);
  };

  return {
    isBuyer,
    buyerTourDone,
    onDone,
  };
};

export const useGetEpisodeFromUrl = () => {
  const gameEngine = GameEngine();
  const query = useQuery();
  const metaInfo = getItem("GameEngineMetaInfo");
  const [episode, setEpisode] = useState<Episode>();
  const forcedEpisode = gameEngine.episodes.getForcedEpisode();
  const run = query.get("run");
  const [episodeHash, encodedPayload] = run ? run.split(".") : [];

  const getEpisodeFromHash = useCallback(
    async (episodeHash) => {
      if (!episodeHash) return;

      const episode = gameEngine.episodes.getEpisodeByHash(episodeHash);

      if (!episode) {
        console.error("Cannot find specified episode");
        return;
      }

      if (encodedPayload) {
        const episodesPayload = metaInfo.episodesPayload || {};
        const decodedPayload = window.atob(encodedPayload);
        episode.payload = JSON.parse(decodedPayload);
        episodesPayload[episode.id] = JSON.parse(decodedPayload);
        setItem("GameEngineMetaInfo", { ...metaInfo, episodesPayload });
      }

      episode.directRun = true;

      setEpisode(episode);
    },
    [episodeHash, encodedPayload]
  );

  const getEpisodeFromUrl = useCallback(async () => {
    if (
      !forcedEpisode?.sid ||
      !forcedEpisode?.eid ||
      !forcedEpisode?.redirectUrl
    )
      return;

    const episode = await gameEngine.episodes.getEpisodeById(forcedEpisode.eid);

    if (!episode) {
      console.error("Cannot find specified episode");
      return;
    }

    if (episode.hasOwnProperty("load")) {
      setEpisode(episode);
    }
  }, [forcedEpisode]);

  useEffect(() => {
    getEpisodeFromUrl();
  }, [forcedEpisode]);

  useEffect(() => {
    getEpisodeFromHash(episodeHash);
  }, [episodeHash]);

  return {
    episode,
  };
};

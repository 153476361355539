import { Actor } from "../../core/interfaces";
import { Room } from "../chat/interfaces";
import { ChatMessage } from "./interfaces/chat-message";
import {
  ProfileDetail,
  SocialNetworkLoginWithCode,
  SocialNetworkMessage,
  SocialNetworkProfile,
  SocialNetworkSearchTags,
  SocialNetworkSearchTerm,
} from "./interfaces/social-network.interface";

export const SOCIAL_NETWORK_LOGIN_WITH_CODE = "socialNetwork.codeActivation";
export const SOCIAL_NETWORK_LOGIN_FAILED = "socialNetwork.loginFailed";
export const SOCIAL_NETWORK_UPDATE_PROFILE = "socialNetwork.updateProfile";
export const SOCIAL_NETWORK_SHARE_PROFILE = "socialNetwork.shareProfile";
export const SOCIAL_NETWORK_ADD_PROFILE = "socialNetwork.addProfile";
export const SOCIAL_NETWORK_SEND_MESSAGE = "socialNetwork.sendMessage";
export const SOCIAL_NETWORK_SEARCH_TAGS = "socialNetwork.searchTags";
export const SOCIAL_NETWORK_SEARCH_TERMS = "socialNetwork.searchTerms";
export const SOCIAL_NETWORK_CHAT_MESSAGE_SEND =
  "socialNetwork.chat.message.send";
export const SOCIAL_NETWORK_CHAT_MESSAGE_TAKE_NEXT =
  "socialNetwork.chat.message.take.next";
export const SOCIAL_NETWORK_LOGGED_IN = "socialNetwork.loggedIn";

export interface LoginWithCode {
  type: typeof SOCIAL_NETWORK_LOGIN_WITH_CODE;
  payload: SocialNetworkLoginWithCode;
}

export interface UpdateProfile {
  type: typeof SOCIAL_NETWORK_UPDATE_PROFILE;
  payload: SocialNetworkProfile;
}

export interface ShareProfile {
  type: typeof SOCIAL_NETWORK_SHARE_PROFILE;
  payload: {
    profile: Actor["id"];
    to: Array<Actor["id"]>;
  };
}
export interface AddProfile {
  type: typeof SOCIAL_NETWORK_ADD_PROFILE;
  payload: ProfileDetail;
}

export interface SendMessage {
  type: typeof SOCIAL_NETWORK_SEND_MESSAGE;
  payload: SocialNetworkMessage;
}

export interface SocialSearchTagsMessage {
  type: typeof SOCIAL_NETWORK_SEARCH_TAGS;
  payload: SocialNetworkSearchTags;
}

export interface SocialSearchTermMessage {
  type: typeof SOCIAL_NETWORK_SEARCH_TERMS;
  payload: SocialNetworkSearchTerm;
}

export interface SocialNetworkChatMessageSend {
  type: typeof SOCIAL_NETWORK_CHAT_MESSAGE_SEND;
  payload: ChatMessage;
  timestamp: number;
}

export interface chatMessageTakeNext {
  type: typeof SOCIAL_NETWORK_CHAT_MESSAGE_TAKE_NEXT;
  payload: Pick<Room, "id">;
}

export interface SocialNetworkLoggedIn {
  type: typeof SOCIAL_NETWORK_LOGGED_IN;
  payload: boolean;
}

export type SocialNetworkMessages =
  | LoginWithCode
  | UpdateProfile
  | ShareProfile
  | AddProfile
  | SendMessage
  | SocialSearchTagsMessage
  | SocialSearchTermMessage
  | SocialNetworkChatMessageSend
  | chatMessageTakeNext
  | SocialNetworkLoggedIn;

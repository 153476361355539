import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { areas, CONTINUE, feelings, msgKey } from "./data";
import styles from "./styles.module.scss";

export function SelectTopics(props: { onAnswer: (a: string[]) => void }) {
  return <Select onAnswer={props.onAnswer} items={areas} />;
}

export function SelectFeeling(props: { onAnswer: (a: string[]) => void }) {
  return <Select onAnswer={props.onAnswer} items={feelings} />;
}

export function selectArea(area: string) {
  const t = areas.find((a) => a.title === area) as { topics: string[] };
  const items = t.topics.map((title) => ({ title }));
  const panel = (props: { onAnswer: (a: string[]) => void }) => (
    <Select onAnswer={props.onAnswer} items={items} />
  );
  return {
    area,
    panel,
  };
}

export function Select(props: {
  items: { title: string; icon?: (props: any) => JSX.Element }[];
  onAnswer: (a: string[]) => void;
}) {
  const [answer, setAnswer] = useState<Record<string, boolean>>({});
  const toggleAnswer = (str: string) => {
    setAnswer((s: Record<string, boolean>) => ({
      ...s,
      [str]: !s[str],
    }));
  };
  const submit = () => {
    props.onAnswer(
      Object.entries(answer)
        .filter(([, selected]) => selected)
        .map(([val]) => val)
    );
  };
  return (
    <div className={styles.column} style={{ margin: "auto 0" }}>
      <div className={styles.buttonContainer} style={{ margin: "auto 0" }}>
        {props.items.map((s, ix) => (
          <button
            key={ix}
            className={`${styles.button} ${s.icon ? styles.square : ""} ${
              answer[s.title] ? styles.selected : ""
            }`}
            onClick={() => toggleAnswer(s.title)}
          >
            {s.icon && <s.icon />}
            <span>
              <FormattedMessage id={msgKey(s.title)} />
            </span>
          </button>
        ))}
      </div>
      <div
        className={styles.row}
        style={{
          justifyContent: "center",
          marginTop: "auto",
          paddingTop: "1rem",
        }}
      >
        <button className={styles.submitButton} onClick={submit}>
          <FormattedMessage id={CONTINUE} />
        </button>
      </div>
    </div>
  );
}

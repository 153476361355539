import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  scores: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    display: "flex",
    color: "white",
    fontSize: 12,
    alignItems: "center",
  },
  score: {
    margin: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
    "& p": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.3)",
    },
  },
  icon: {
    height: 30,
    [theme.breakpoints.down("sm")]: {
      height: 20,
    },
  },
  total: {
    display: "inline",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  testsList: {
    "& div": {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      "& img": {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

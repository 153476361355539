import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Episode, Season } from "game-engine/interfaces";
import { FunctionComponent } from "react";
import { CompetenceLevel, whatScope, whoScope } from "./enums";
import { Notification } from "./notifications/interfaces";

export interface AccredibleLink {
  certificate: string;
  url: string;
  recipientName: string;
}

export interface Actor {
  id: string;
  name: string;
  shortname: string;
  email?: string;
  avatarUrl: string;
  background?: string;
  backgroundUrl?: string;
  phoneNumber?: string;
  leadingColor?: string;
  sprites?: {
    faceHappy?: string;
    faceNeutral?: string;
    faceShocked?: string;
    faceSmile?: string;
    faceWorried?: string;
    face?: string;
    angry?: string;
    excited?: string;
    happy?: string;
    neutral?: string;
    reflective?: string;
    shocked?: string;
    tired?: string;
    shy?: string;
    sad?: string;
  };
}

export interface Application {
  id: string;
  name: string;
  icon: string;
  color?: string;
  path: string;
  component?: FunctionComponent<any>;
  props?: Record<string, unknown>;
  notification?: number;
  browser?: {
    default?: boolean;
    domain: string;
    virtualPath?: string;
    keywords?: string[];
  };
  in: {
    home?: boolean;
    router?: boolean;
  };
  splash?: FunctionComponent;
  fastLinkIcon?: string;
  notificationStyles?: {
    backgroundColor?: string;
    buttonColor?: string;
    textColor?: string;
  };
}

export interface ApplicationConstructable {
  new (): Application;
}

export interface AppSwitcherProps {
  applications: Application[];
  style: React.CSSProperties;
}

export enum Background {
  Office,
  TrainStation,
  Train,
  YorksOffice,
  BergenStation,
  VincentsLab,
  FancyOffice,
  HospitalConsulting,
  HospitalTheater,
  HospitalResting,
}

export type BackgrondProps = {
  svgProps: {
    preserveAspectRatio: string;
    style: {
      width: string;
      height: string;
    };
  };
  idle: boolean;
};

export interface CompanyGroup {
  type: "tag" | "department";
  id: number;
  name: string;
  competenceAreaList: CompanyReportData[];
  children?: CompanyGroup[];
  translate?: boolean;
}

export interface CompanyCompletedEpisodesStatisticsReport<T = number> {
  completedEpisodes: CompletedEpisodeStatistics<T>[];
}

export interface CompanyNumberOfUsersStatisticsReport {
  currentPeriodActiveUsers: number;
  previousPeriodActiveUsers: number;
  allRegisteredUsers: number;
  currentPeriodRegisteredUsers: number;
  previousPeriodRegisteredUsers: number;
}

export interface CompanyReportData {
  availableTests: number;
  competenceAreaId: number;
  competenceAreaName: string;
  medianPassed: number;
  maxPassed: number;
  maxTried: number;
  bestMedianPassed: number;
  initialMedianPassed: number;
  medianTried: number;
  minPassed: number;
  minTried: number;
  testUserCount: number;
  position?: string; // computed on client
  percent?: number; // computed on client
}

export interface Competence {
  id: number;
  title: string;
  articleId: string;
  nameId: string;
  icon: IconProp;
  color: string;
}

export interface CompetenceArea {
  competenceAreaId: number;
  tests: Test[];
}

export interface CompetenceAreaTree {
  id: number;
  name: string;
  testsAvailable: boolean;
  competences: CompetenceTree[];
}

export interface CompetenceTree {
  id: number;
  name: string;
  competenceAreaId: number;
  subCompetences: SubCompetenceTree[];
  testsAvailable: boolean;
}

export type CompetenceType =
  | "information"
  | "communication"
  | "content"
  | "safety"
  | "problem";

export interface CompletedEpisodeStatistics<T = number> {
  /*
    Episode as T is a legacy support system. The new BE returns a string but the UI
    expects a number.
  */
  episode: T;
  numberOfUsers: number;
}

export type EngagementPayload = {
  date: string;
  engagementPoints: number;
};
export interface FooterProps {
  backgroundColor: string;
}

export interface LevelEndProps {
  background?: string;
  level?: number;
  text?: string;
  btnBackground?: string;
  btnText?: string;
  path?: string;
}

export interface LevelEndPayload {
  levelEnd: boolean;
}

export type MissionIndividualProgress = MissionProgress & MissionProgressOnly;

export interface MissionOrganizationProgress extends MissionProgress {
  initialResults: number[] | null;
  bestResults: number[] | null;
}

interface MissionProgress {
  targetId: string;
  whoScope: whoScope;
  who: string;
  whatScope: whatScope;
  what: string;
  level: CompetenceLevel;
}

export interface MissionProgressOnly {
  myProgress: number;
  organizationProgress: number;
}

export type MissionTarget = {
  id?: string;
  department?: number;
  team?: number;
  function?: number;
  competenceArea?: number;
  competence?: number;
  subCompetence?: number;
  level: number;
};

export interface PlayerTeamOrganisationComparison {
  available: number;
  playerTried: number;
  playerPassed: number;
  teamTried: number;
  teamPassed: number;
  organisationTried: number;
  organisationPassed: number;
}

export interface PlayRecommendation {
  season: Season["id"];
  episode: Episode["id"];
  pendingTests: number;
}

export interface ReportData {
  availableTests: number;
  competenceAreaId: number;
  competenceAreaName: string;
  totalPassed: number;
  totalTried: number;
}

export interface RunAnimationPayload {
  run: boolean;
  animation: JSX.Element | JSX.Element[];
  duration: number;
  zIndex: number;
}

export type SaveGame = Record<string, any>;

export interface Score {
  competencies: { [competence in CompetenceType]: number };
  level: number;
  points: number;
  minPoints: number;
  maxPoints: number;
  valuation: number;
}

export interface ShowReportPayload {
  show: boolean;
  advices: Array<{
    text: string;
    links: Array<{ name: string; href: string }>;
  }>;
}

export interface State {
  apps: Record<string, Application>;
  actors: Record<string, any>;
  notifications: Notification[];
  gotoUrl?: string;
  background?: Background;
  animation: {
    run: boolean;
    animation: JSX.Element | JSX.Element[] | undefined;
    duration: number;
    zIndex: number;
  };
  timePassing: {
    state: boolean;
    duration?: number;
    keepRoute?: boolean;
  };
  video: {
    state: boolean;
    src: string;
  };
  tour: Tour;
  showApps: boolean;
  levelEnd: boolean;
}

export interface Step {
  id: string;
  title: string;
  completed: boolean;
}

export interface StopAnimationPayload {
  run: boolean;
}

export interface StyleProps {
  backgroundColor: string;
}

export interface Subcompetence {
  subCompetenceId: number;
  level: CompetenceLevel;
  passedTests: number;
  availableTests: number;
}

export interface SubCompetenceTree {
  id: number;
  name: string;
  competenceId: number;
  testsAvailable: boolean;
}

export interface Test {
  description: string;
  episode: number;
  id: string;
  level: CompetenceLevel;
  result: 0 | 1;
  subCompetenceId: number;
}

export interface Tour {
  run: boolean;
  screen: number;
}

export interface TrainingRecommendation {
  cost: number;
  courseName: string;
  description: string;
  durationHours: number;
  language: string;
  providerName: string;
  type: "FACE_TO_FACE" | "ONLINE";
  url: string;
}

export interface UrlBoxProps {
  url: string;
  urlList: string[];
  onChange: (url: string) => void;
  top: number;
}

export interface UserInfo {
  sub: string;
  email?: string;
  name?: string;
  preferred_username?: string;
  locale?: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      color: {
        red: {
          500: string;
        };
        yellow: {
          300: string;
          500: string;
        };
        green: {
          500: string;
        };
        turquoise: {
          300: string;
          500: string;
          700: string;
        };
      };
      zIndex: {
        documentEditor: {
          speechArrow: number;
        };
        videoCallInCall: {
          message: number;
        };
        directCommunication: {
          main: number;
          notification: number;
          notificationAvatar: number;
        };
        gameControl: number;
        decision: number;
        modalNotification: number;
        levelEnd: number;
        animation: number;
        report: number;
        overlay: number;
        splash: number;
        tour: number;
        toolbar: number;
        quizApp: number;
        video: number;
        timePassing: number;
      };
      ANIMATIONS_ENABLED: boolean;
      /**
       * In milliseconds
       */
      ANIMATIONS_SPEED_CONFIG: {
        animate__slow: number;
        animate__slower: number;
        animate__fast: number;
        animate__faster: number;
        animate__normal: number;
      };
    };
  }

  interface ThemeOptions {
    custom?: {
      color?: {
        red: {
          500: string;
        };
        yellow: {
          300: string;
          500: string;
        };
        green: {
          500: string;
        };
        turquoise: {
          300: string;
          500: string;
          700: string;
        };
      };
      zIndex?: {
        documentEditor: {
          speechArrow: number;
        };
        directCommunication?: {
          main?: number;
          notification?: number;
          notificationAvatar?: number;
        };
        videoCallInCall: {
          message: number;
        };
        gameControl?: number;
        decision?: number;
        modalNotification?: number;
        levelEnd?: number;
        animation?: number;
        report?: number;
        overlay?: number;
        splash?: number;
        tour: number;
        toolbar: number;
        quizApp: number;
        video: number;
        timePassing?: number;
      };
      ANIMATIONS_ENABLED?: boolean;
      /**
       * In milliseconds
       */
      ANIMATIONS_SPEED_CONFIG: {
        animate__slow: number;
        animate__slower: number;
        animate__fast: number;
        animate__faster: number;
        animate__normal: number;
      };
    };
  }
}

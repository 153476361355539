import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import OpenAI from "openai";
import { openAiConnection } from "../services/open-ai-connection";

export const useGetOpenAiMessages = <
  T = OpenAI.Beta.Threads.Messages.MessagesPage
>(
  threadId = "",
  options?: UseQueryOptions<
    OpenAI.Beta.Threads.Messages.MessagesPage,
    unknown,
    T
  >
) => {
  return useQuery({
    queryKey: [useGetOpenAiMessages.queryKey, threadId],
    queryFn: async () => {
      return await openAiConnection
        .getConnection()
        .beta.threads.messages.list(threadId);
    },
    meta: {
      devErrorMsg: useGetOpenAiMessages.queryKey,
    },
    ...options,
  });
};

useGetOpenAiMessages.queryKey = "useGetOpenAiMessages";

import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "3",
  completed: false,
  index: 3,
  title: {
    de: "Staffel Titel",
    en: "Season Title",
  },
  info: {
    de: "Staffel Info",
    en: "Season Info",
  },
  topics: [],
  seasonEndMessage: {
    en: "",
    de: "",
  },
  certificates: [],
  episodes: [
    {
      id: "s03e01",
      title: { de: "Episode Titel", en: "Episode Title" },
      maturity: "ALPHA" as EpisodeMaturity,
      description: {
        de: "Beschreibung der Episode",
        en: "Episode description",
      },
      imageUrl: "/episodes/s02e01/preview.svg",
      url: "/episodes/s03-e01/",
      load: async () => import(/* webpackChunkName: "s03e01" */ "../s03-e01"),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "3" })),
};

import digitalZen from "../apps/digital-zen/i18n/message-en";
import meetingsToolBrowser from "../apps/meetings-tool-browser/i18n/message-en";
import meetingsTool from "../apps/meetings-tool/i18n/message-en";
import { competences } from "./competences-en";

const supportedLanguages = {
  "language.en": "English",
  "language.de": "German",
};

const globalMessages = {
  all: "All",
  "global.continue": "Continue",
  "global.ok": "Ok",
  "global.congratulation": "Congratulation!",
  "global.hour": "O'clock",
  "global.contact": "Contact",
  "global.contact.tip":
    "Need help? We'll gladly give you a tip. Want to tell us what you like and what you don't like? We appreciate all feedback -- because this product is made for you.",
  "global.warning.message":
    "talent::digital works best with a browser window of at least 375 x 660 pixels. Please increase your browser window or use a device that allows a larger browser window",
  "global.warning.confirm": "Confirm",
  "global.contact.blocked":
    "Looks like your IT organization blocks the support chat. You can read more about it in this <a href='https://talentdigital.freshdesk.com/support/solutions/articles/47001208238-wie-kann-ich-chat-support-aktivieren-' target='_blank'>article</a>",
  "global.contact.email": `You can still reach us:
    <ul>
      <li><a href='https://talentdigital.freshdesk.com/support/home' target='_blank'>through our support website</a>,</li>
      <li><a href='mailto:support@talentdigital.eu' target='_blank'>via email</a> or</li>
      <li>via phone at <a href='tel:+49 211 94252837'>+49 211 94252837</a>.</li>
    </ul>`,
  "global.exit.title": "Confirm restart?",
  "global.exit.description": "Do you want to restart the current episode?",
  "global.exit.restart": "Restart",
  "global.exit.continue": "Continue  episode",
  "global.video.playbackSpeed": "Playback speed",
};

const gameControl = {
  "gameControl.tabs.stats": "Stats",
  "gameControl.tabs.teams": "Teams",
  "gameControl.tabs.profil": "Profil",
  "gameControl.tabs.settings": "Settings",
  "gameControl.stats.engagementPoints": "Engagement Points",
  "gameControl.stats.competenceScore": "Competence Score",
  "gameControl.stats.progress": "Progress",
  "gameControl.stats.progress.toNextLevel":
    "{percents}% left for Level {level}",
  "gameControl.stats.tasks": "Current Task(s)",
  "gameControl.profile.badges":
    "Earn different badges by setting off on a journey of discovery and trying new things during the game! Can you unlock all badges?",
  "gameControl.stats.exitGame": "Exit game",
  "gameControl.stats.pauseGame": "Pause game",
  "gameControl.stats.playGame": "Continue game",
  "gameControl.settings.my-data": "My data",
  "gameControl.settings.my-player-profile": "My player profile",
  "gameControl.settings.terms-and-conditions": "Terms and Conditions",
  "gameControl.levelend.uploadFailed":
    "Game could not be saved. This happens if your Internet connection has a problem or if you kept the app idle for too long. Your game will be saved if you reload the page and log in again.",
  "gameControl.modal.player-profile.account.title":
    "Set up your name and avatar",
  "gameControl.modal.player-profile.data.title": "Edit your profile",
  "gameControl.modal.player-profile.data.subtitle":
    "This data is used for anonymized reports. ",
  "gameControl.modal.player-profile.data.subtitle.link": "More information.",
};

const header = {
  "header.score.heading": "Completed tasks in this episode",
  "header.score.no-tests": "No tasks completed yet.",
  "header.test-level.1": "Starter",
  "header.test-level.FOUNDATION": "Starter",
  "header.test-level.2": "Advanced",
  "header.test-level.INTERMEDIATE": "Advanced",
  "header.test-level.3": "Professional",
  "header.test-level.ADVANCED": "Professional",
};

const settings = {
  saveButton: "Save and continue",
  companyName: "What is your company name?",
  companyLogo: "Select your company logo",
  playerName: "Your player name",
  playerAvatar: "What should your avatar look like?",
  playerPassword: "Choose your password",
  playerEmailAddress: "Here is your player email",
  settingsDone: "Employee profile created. You are ready to go.",
  profileTitle: "Employee profile",
};

const boarding = {
  "boarding.start": "Start Episode",
  "boarding.end": "Finish Episode",
};

const rewinder = {
  "rewinder.title": "Where do you want to rewind to?",
  "rewinder.quest.active": "Current task",
  "rewinder.quest.active.restart": "Restart current task",
  "rewinder.quest.completed": "Rewind to this task",
  "rewinder.quest.skipped": "Task skipped",
};

const certificates = {
  "certificates.certificate": "Certificate",
  "certificates.no-certiticates-in-season":
    "There are no certificates available for this season yet. Please select another season.",
  "certificates.attention": "Complete the certificate by playing episodes ",
  "certificates.sorting":
    "The episodes are sorted by relative performance. From the first episode mentioned you need proportionally the most points to get the certificate, from the last episode proportionally the least.",

  // TI-2846 - Commented out the certificates that are not used in the season
  // // Season 1
  // "certificates.name.Security Basics": "Foundations of Safety",
  // "certificates.description.Security Basics":
  //   "<p>The participant has successfully participated in a certification on the basics of safety and security according to DigComp 2.1. The following areas were covered:</p><ul><li>Protection of devices and digital content.</li><li>Use of passwords and multi-factor authentication.</li><li>Basics of encryption and data protection.</li><li>Identification of basic patterns in dangerous websites, spam and phishing.</li><li>Avoidance of health hazards and stress factors in digital workplaces.</li><li>Energy consumption by information technology.</li></ul>",
  // "certificates.accredible.description.Security Basics": `The participant has successfully participated in a certification on the basics of safety and security according to DigComp 2.1. The following areas were covered:

  // * Protection of devices and digital content.
  // * Use of passwords and multi-factor authentication.
  // * Basics of encryption and data protection.</li><li>Identification of basic patterns in dangerous websites, spam and phishing.
  // * Avoidance of health hazards and stress factors in digital workplaces.
  // * Energy consumption by information technology.`,
  "certificates.certificate.conjuction": " of ",
  // "certificates.name.Automation Basics": "Foundations of Automation",
  // "certificates.description.Automation Basics":
  //   "<p>The participant has successfully participated in a certification on the basics of digital problem solving according to DigComp 2.1. The following topics in Area 5.2 “Identifying needs and technological responses” were covered:</p> <ul><li>Selecting and customization of standard applications for given requirements.</li><li>Relevance of terms of services for IT services.</li><li>Understanding processes that can be automated, as well as describing and automating elementary processes.</li></ul>",
  // "certificates.accredible.description.Automation Basics": `The participant has successfully participated in a certification on the basics of digital problem solving according to DigComp 2.1. The following topics in Area 5.2 “Identifying needs and technological responses” were covered:
  // * Selecting and customization of standard applications for given requirements.
  // * Relevance of terms of services for IT services.
  // * Understanding processes that can be automated, as well as describing and automating elementary processes.`,

  // Season Roadmap
  "certificates.name.Wenn Ideen fliegen lernen": "Wenn Ideen fliegen lernen",
  "certificates.description.Wenn Ideen fliegen lernen":
    "<p>T participant has successfully participated in a certification on the basics of safety and security according to DigComp 2.1. The following areas were covered:</p><ul><li>Protection of devices and digital content.</li><li>Use of passwords and multi-factor authentication.</li><li>Basics of encryption and data protection.</li><li>Identification of basic patterns in dangerous websites, spam and phishing.</li><li>Avoidance of health hazards and stress factors in digital workplaces.</li><li>Energy consumption by information technology.</li></ul>",
  "certificates.accredible.description.Wenn Ideen fliegen lernen": `The participant has successfully participated in a certification on the basics of safety and security according to DigComp 2.1. The following areas were covered:

  * Protection of devices and digital content.
  * Use of passwords and multi-factor authentication.
  * Basics of encryption and data protection.</li><li>Identification of basic patterns in dangerous websites, spam and phishing.
  * Avoidance of health hazards and stress factors in digital workplaces.
  * Energy consumption by information technology.`,

  // Season 2
  "certificates.name.Digital Collaboration":
    "Foundations of Digital Collaboration",
  "certificates.description.Digital Collaboration": `<p>The participant has successfully participated in a certification on the basics of digital collaboration according to DigComp 2.1.
    The following topics in Area 2.1, interacting through digital technologies, and Area 2.4, collaborating through digital channels, were covered:</p>
    <ul>
     <li>Use of digital tools and technologies for collaborative processes and collaborative teamwork.</li>
     <li>Chatbot basics.</li>
     <li>Modern project management.</li>
     <li>Digital real-time collaboration.</li>
    </ul>`,
  "certificates.accredible.description.Digital Collaboration": `The participant has successfully participated in a certification on the basics of digital collaboration according to DigComp 2.1. The following topics in Area 2.1, interacting through digital technologies, and Area 2.4, collaborating through digital channels, were covered:
  * Use of digital tools and technologies for collaborative processes and collaborative teamwork.
  * Chatbot basics.
  * Modern project management.
  * Digital real-time collaboration.`,

  // Season 93
  "certificates.name.OpenBlue Solutioneer": "OpenBlue Solutioneer",
  "certificates.description.OpenBlue Solutioneer": `<p>The participant has attended a course on selling OpenBlue-based solutions. The following aspects were covered:</p>
    <ul>
      <li>Solution-oriented sales.</li>
      <li>Customer solutions with OpenBlue.</li>
      <li>Basics of digital building management.</li>
    </ul>`,
  "certificates.accredible.description.OpenBlue Solutioneer": `The participant has attended a course on selling OpenBlue-based solutions. The following aspects were covered:
  * Solution-oriented sales.
  * Customer solutions with OpenBlue.
  * Basics of digital building management.
  `,

  // Season 94
  "certificates.name.Das Bürgergeld in der Praxis":
    "Das Bürgergeld in der Praxis",

  "certificates.description.Das Bürgergeld in der Praxis": `<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Bürgergelds teilgenommen. Dabei wurden die folgenden Themen behandelt:</p>
  <ul>
     <li>Bürgergeld-Detailregelungen (gesetzliche Neuerungen - insbesondere zu Regelsatzerhöhung, Karenzzeit, Vermögenswerten, Einkommensfreibeträgen, usw.)</li>
     <li>Beratungsansätze (Beratung auf Augenhöhe, Potenzialanalyse, Kooperationsplan, usw.)</li>
     <li>Qualifizierungsmechanismen (Qualifizierungsgebot, zielgerichtete Unterstützung, Weiterbildungsgeld, usw.)</li>
  </ul>`,

  "certificates.accredible.description.Das Bürgergeld in der Praxis": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Bürgergelds teilgenommen. Dabei wurden die folgenden Themen behandelt:
   * Bürgergeld-Detailregelungen (gesetzliche Neuerungen – insbesondere zu Regelsatzerhöhung, Karenzzeit, Vermögenswerten, Einkommensfreibeträgen, usw.)
   * Beratungsansätze (Beratung auf Augenhöhe, Potenzialanalyse, Kooperationsplan, usw.)
   * Qualifizierungsmechanismen (Qualifizierungsgebot, zielgerichtete Unterstützung, Weiterbildungsgeld, usw.)`,

  // Season 95
  "certificates.name.Der Kooperationsplan im Bürgergeld":
    "Der Kooperationsplan im Bürgergeld",
  "certificates.description.Der Kooperationsplan im Bürgergeld": `<p>Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Kooperationsplans teilgenommen. Dabei wurden die folgenden Themen behandelt:</p>
  <ul>
     <li>Grundlagen des Kooperationsplans (Rahmen, Rechtsgrundlage, usw.)</li>
     <li>Inhalte des Kooperationsplans (Abschluss, Form, Inhalte, usw.)</li>
     <li>Potenzialanalyse (Rechtsgrundlagen, Rahmen, Inhalte, usw.)</li>
     <li>Kooperationsplan in der Praxis (Einladungsprozedere, Erstellung, Überprüfung, usw.)</li>
     <li>Schlichtungsverfahren (Rechtsgrundlagen, Rahmen, Inhalte, usw.)</li>
     <li>Leistungsminderungen (Neuerungen, Rechtsgrundlagen, Ablauf, usw.)</li>
  </ul>`,

  "certificates.accredible.description.Der Kooperationsplan im Bürgergeld": `Der Teilnehmer bzw. die Teilnehmerin hat erfolgreich an einer Zertifizierung zu den Regelungen bezüglich des Kooperationsplans teilgenommen. Dabei wurden die folgenden Themen behandelt:
  *Grundlagen des Kooperationsplans (Rahmen, Rechtsgrundlage, usw.)
  *Inhalte des Kooperationsplans (Abschluss, Form, Inhalte, usw.)
  *Potenzialanalyse (Rechtsgrundlagen, Rahmen, Inhalte, usw.)
  *Kooperationsplan in der Praxis (Einladungsprozedere, Erstellung, Überprüfung, usw.)
  *Schlichtungsverfahren (Rechtsgrundlagen, Rahmen, Inhalte, usw.)
  *Leistungsminderungen (Neuerungen, Rechtsgrundlagen, Ablauf, usw.)`,

  // Regular translations
  "certificates.download": "Download certificate",
  "certificates.threshold":
    "You need at least {threshold}% to receive a certificate.",
};

const mailbox = {
  "mailbox.datetime.today": "Today",
  "mailbox.attachments.storage.openButton": "Save in the Cloud",
  "mailbox.attachments.storage.saveToFile": "Save to file",
  "mailbox.attachments.storage.project": "Project",
  "mailbox.attachments.storage.saveConfirmationMessage":
    "Document successfully saved in the cloud.",
};

const search = {
  "search.searchLabel": "Search the web",
  "search.searchTitle": "Finder",
  "search.noResults": "No entries found",
};

const appStore = {
  "appStore.title": "APP Store",
  "appStore.search.title": "Search",
  "appStore.list.downloadButton": "Download",
  "appStore.list.runButton": "Open",
  "appStore.list.sponsored": "Sponsored",
  "appStore.list.noAppsFoundMessage":
    "Unfortunately we don't have an offer (yet). Please start a new search.",
  "appStore.info.dialog.text":
    "No other applications of the same category can be downloaded.",
  "appStore.info.dialog.error": "Error installing app",
};

const calendar = {
  "calendar.meetingAppointment.title": "Name of the appointment",
  "calendar.meetingAppointment.allDay": "All-day",
  "calendar.meetingAppointment.cancelButton": "Abort",
  "calendar.meetingAppointment.saveButton": "Save appointment",
  "calendar.meetingAppointment.videoCallText":
    "Video conferencing and dial-in details have been added.",
  "calendar.meetingAppointment.start": "Start",
  "calendar.meetingAppointment.end": "End",
  "calendar.selectActorsDialog.title": "Entry list",
  "calendar.selectActorsDialog.save": "Save guests",
  "calendar.meetingAppointment.error.startTime":
    "You cannot create an appointment that starts before 09:00.",
  "calendar.meetingAppointment.error.endTime":
    "You cannot create an appointment that ends after 17:00.",
  "calendar.meetingAppointment.error.endBeforeStart":
    "The end of the meeting cannot be before the start.",
};

const chatBot = {
  "chatbot.btn.save.trigger": "Save Trigger",
  "chatbot.btn.save.action": "Save Rule",
  "chatbot.btn.cancel": "Cancel",
  "chatbot.label.keyword": "Keyword",
  "chatbot.label.keywords": "Keywords List",
  "chatbot.label.select.subject": "Subject",
  "chatbot.label.when": "When",
  "chatbot.label.then": "Then",

  "chatbot.dropdown.select.label.selectTrigger": "Select Trigger",
  "chatbot.dropdown.select.label.selectDepartment": "Select Department",
  "chatbot.dropdown.select.label.selectEmployee": "Select Employee",
  "chatbot.dropdown.select.label.addAttributes":
    "Add attributes to your message",
  "chatbot.dropdown.select.label.saveAndAssign":
    "Save visitor message and assign to:",

  "chatbot.select.item.start": "Customer visits website",
  "chatbot.select.item.with_keywords":
    "Customer message includes one of the keywords",
  "chatbot.select.item.without_keywords":
    "Customer message doesn't include any keywords",
  "chatbot.select.item.correct_1": "Frequently asked questions and answers",
  "chatbot.select.item.incorrect_1":
    "Bubblr - so simple even your kids can do it",
  "chatbot.select.item.correct_2":
    "Whether bathtub or shower - your Bubblr even works at home.",
  "chatbot.select.item.incorrect_2":
    "Everything you wanted to know about Bubblr but were afraid to ask",
  "chatbot.select.item.correct_3":
    "Sustainable, environmentally conscious and healthy, life runs smoothly with Bubblr!",
  "chatbot.select.item.incorrect_3":
    "The Number One Reason You Should (Do) Bubblr",
  "chatbot.select.item.sales": "Sales Department",
  "chatbot.select.item.hr": "HR Department",
  "chatbot.select.item.development": "Product Development Department",
  "chatbot.select.item.it": "IT Department",
  "chatbot.select.item.warranty": "Warranty Service Department",
  "chatbot.select.item.costumer": "Customer Service Department",

  "chatbot.creator.message.label": "Write your message",
  "chatbot.creator.add.attributes.label": "Add attributes to your message",
  "chatbot.creator.member.defaultMessage": "Forward visitor message directly",
  "chatbot.creator.ticket.defaultMessage": "New Ticket created by chatbot",
  "chatbot.creator.connect.defaultMessage": "Connect Visitor to LiveChat with",

  "chatbot.header.sidebar.rule": "Create Rule",
  "chatbot.header.sidebar.action": "Select Action",

  "chatbot.action.menu.message": "Send response",
  "chatbot.action.menu.ticket": "Create ticket",
  "chatbot.action.menu.member": "Send visitor message to team member",
  "chatbot.action.menu.connect": "Transfer to",

  "chatbot.defaultMessage.chatbotRule2SubquestRule1":
    "There is a really good questions and answers area on our site",

  "chatbot.node.title.start": "Start",
  "chatbot.node.subtitle.start": "Create a new rule for your chatbot",
  "chatbot.node.title.welcome": "Welcome message",
  "chatbot.node.subtitle.welcome": "Start the chat with a message.",
  "chatbot.node.greeting.message":
    "Hello! I'm your personal assistant and I help you with all questions about Bubblr. What can I do for you today?",

  "chatbot.flow.item.trigger.with.keywords": "When message includes keywords.",
  "chatbot.flow.item.trigger.without.keywords":
    "When message doesn't include keywords.",
  "chatbot.flow.item.action.message":
    "Then send automatic response with article",
  "chatbot.flow.item.action.ticket": "Then create a ticket",
  "chatbot.flow.item.action.member": "Then forward message to team member",
  "chatbot.flow.item.action.connect": "Then connect on live chat",
  "chatbot.end.message": "Thank you for using ChatBot!",
  "chatbot.close.button": "End",
};

const socialNetwork = {
  "socialNetwork.btn.codeActivation": "Activate code",
  "socialNetwork.btn.login": "Login",
  "socialnetwork.chat.title": "News",
  "socialNetwork.codeActivation.btn": "Activate code",
  "socialNetwork.codeActivation.text": "Please enter your code:",
  "socialNetwork.codeActivation.wrongCode": "Code is invalid.",
  "socialNetwork.commentInput.placeholder": "Write a message",
  "socialNetwork.login.welcomeText":
    "Welcome to science: LAB. Log in or activate your invitation code.",
  "socialNetwork.profile.btn": "Create profile",
  "socialNetwork.profile.input.company": "Company",
  "socialNetwork.profile.input.fullName": "Name",
  "socialNetwork.profile.radio.label": "What are you Looking for?",
  "socialNetwork.profile.radio.value1": "I am looking for a Job",
  "socialNetwork.profile.radio.value2":
    "I am looking for reinforcements for my team",
  "socialNetwork.profile.radio.value3": "I am just looking around",
  "socialNetwork.profile.subText": "Submit your profile",
  "socialNetwork.profile.welcomeText": "Welcome",
  "socialNetwork.subtitle":
    "Secret network: Access Only for scientists and inventors",
  "socialNetwork.title": "science:LAB",
  "socialNetwork.userIdentification.btn": "Log in",
  "socialNetwork.userIdentification.errorText":
    "Access data could not be found. Please check your data.",
  "socialNetwork.userIdentification.password": "Password",
  "socialNetwork.userIdentification.text":
    "Please enter your login information.",
  "socialNetwork.userIdentification.userName": "User name",
  "socialNetwork.userIdentification.wrongCredentials":
    "Username or password is invalid.",
};

const chat = {
  "chat.title": "Messenger",
  "chat.message.player": "Player",
  "chat.groupAdded": 'You were added to the group "{roomName}".',
};

const animation = {
  "animation.hacker.message":
    "I know what you're up to! And I will not let your project be successful! - Prepare for your downfall! Hahahahah!",
  "animation.dataRecovered.message":
    "The transaction was successful.<br/>The data is now released.",
  "animation.dataRecovered.message1": "Restore data",
  "animation.dataRecovered.message2": "Restoration...",
  "animation.dataRecovered.message3": "Data restored!",
  "animation.dataDeleted.message": "Data will be deleted",
  "animation.dataDeleted.message1": "Deleting data",
  "animation.dataDeleted.message2": "Data deleted! FOREVER!",
  "animation.dataDeletedHacker.message": "Your data will be destroyed forever!",
};

const decisions = {
  "decisions.title": "I choose:",
  "decisions.confirmHeader": "Are you sure?",
  "decisions.confirmOption": "Yes",
  "decisions.rejectOption": "No",
};

const root = {
  "root.timePassing": "Time goes by",
};

const report = {
  "report.title": "Your game report",
  "report.points": "Activity coins",
  "report.competenceScore": "Competence Score",
  "report.badges": "Badges",
  "report.competences": "Competence diamonds collected",
  "report.departments": "Departments",
  "report.missions": "Missions",
  "report.adminCockpit": "Management Cockpit",
  "report.competence1": "Information and data literacy",
  "report.competence1.tooltip": `
  <h3>Information and data literacy</h3>
  <p><i>Is the ability to deal confidently and productively with digital information and data.</i></p>
  <p>Do you use search operators in an Internet search to improve the quality of results?<br/>
  Are the information sources displayed subsequently further reviewed and compared to verify relevance, credibility, and accuracy?<br/>
  How is the found content stored, managed, analyzed and organized?</p>
  `,
  "report.competence2": "Communication and collaboration",
  "report.competence2.tooltip": `
  <h3>Communication and collaboration</h3>
  <p><i>Is the ability to use digital technologies to facilitate the sharing of data and information.</i></p>
  <p>For example, are email, (mobile) messengers and social networks being used meaningfully for collaboration?<br />
  Is respectful and appreciative communication also observed in the digital environment?<br />
  Do you carefully shape your personal identity and reputation?<br />
  And does one take into account that cultural and intergenerational diversity also applies online?</p>
  `,
  "report.competence3": "Digital content creation",
  "report.competence3.tooltip": `
  <h3>Creation of digital content</h3>
  <p><i>Is the competence to create and distribute digital content independently.</i></p>
  <p>For example, is it easy to create and edit video, audio, and image files?<br />
  Are advanced features of word processing and spreadsheet programs used (e.g., mail merge, pivot tables, etc.)?<br />
  Can digital applications (e.g. websites or apps) be created independently because the knowledge of (at least) one programming language is available?<br />
  In this context, knowledge about copyright, licenses and copyright also plays an essential role.</p>
  `,
  "report.competence4": "Safety",
  "report.competence4.tooltip": `
  <h3>Safety and security</h3>
  <p><i>Is the competence to protect (personal) data and information as well as hardware.</i></p>
  <p>For example, are a firewall and anti-virus programs used and kept up to date with updates?<br />
  Are important files shared in encrypted form and backed up?<br />
  Are applications protected by changing and challenging passwords?<br />
  Can a phishing email be distinguished from trustworthy information, and is there a plan for what to do if a hacking attack has occurred?</p>
  `,
  "report.competence5": "Problem solving",
  "report.competence5.tooltip": `
  <h3>Digital problem solving</h3>
  <p><i>Is the competence to consciously use digital tools to solve specific problem situations.</i></p>
  <p>Are you able to select digital applications that meet the needs of the organization?<br />
  Can the effectiveness of digital programs or services be assessed to solve existing problems?<br />
  This also includes the continuous monitoring of current technological developments.</p>
  `,
  "report.takeAways": "Take aways",
  "report.textToAvoidHacker":
    "Hacker attacks also pose a threat in the real world.<br/>Here are a few pointers on how to avoid them:",
  "report.helpfulInfo":
    "Finding information online quickly is helpful and saves time. Here you can find out how you can improve your search:",
  "report.qualifyButton": "Suitable further training offers for you",
  "report.goDashboardButton": "Go to dashboard",
  "report.continue": "Continue",
  "report.noBadges": "No badges.",
  "report.season": "Season ",
  "report.solved": "solved",
};

const seasonEnd = {
  "seasonend.congratulations": `Great, you've finished the season "{season_title}" of talent::digital. {season_end_message}`,
  "seasonend.next": "Next",
};

const videoCall = {
  "videoCall.endCall.text": "The conference call has ended.",
  "videocall.home.insertCode": "Enter conference code",
  "videocall.home.select": "Select a conference from the list",
  "videocall.code.title": "Please enter the conference code",
  "videocall.code.button": "Join conference",
  "videocall.select.title": "Scheduled conferences",
  "videocall.notification.join": "Join",
  "videocall.notification.incoming": "Incoming video call",
  "videocall.conferenceRoom.disabled": " - Conference have not yet started",
  "videocall.activeCall.sendTranslationRequest": "Send Translation Request",
  "videocall.activeCall.disableTranslation": "Disable Translation",
  "videocall.activeCall.startRecording": "Start Recording",
  "videocall.activeCall.stopRecording": "Stop Recording",
  "videoCall.endCall.recording":
    "A video of your call has been saved under VideoCall - salesCallWithJoao.mp4.",
  "videoCall.endCall.transcript":
    "A transcript of your call has been saved under VideoCall - salesCallWithJoao.txt.",
  "videoCall.title": "{conversationPartnerName}, {companyName}: {topic}",
  "videoCall.quest.title": "Video call",
  "videoCall.quest.description":
    "Video call with {conversationPartnerName} about {topic}",
};

const news = {
  "news.search.placeholder": "Search for articles",
  "news.search.subtitle": 'Your search results for "{term}"',
  "news.search.noResults": 'No search results for "{term}"',
  "news.search.moreArticles": "More articles that could fit",

  "news.header": "News",
  "news.title": "Top news",
  "news.toolbar.input.placeholder": "Search messages...",
  "news.search.noFavorites": "No bookmarked articles",
  "news.favorite.subtitle": "My bookmarked articles",
  "news.article.nps.title":
    "Would you recommend this article to a colleague to read?",
  "news.article.nps.recommend": "Under no circumstance",
  "news.article.nps.noRecommend": "Yes sure!",
  "news.assetManager.title": "Manage Assets",
  "news.assetManager.delete": "Delete",
  "news.assetManager.askDelete": "Are you sure you want to delete {fileName} ?",
  "news.assetManager.confirmDelete": "Yes, Delete",
  "news.assetManager.upload": "Upload a file",
  "news.assetManager.close": "Close",
  "news.assetManager.select": "Select",
  "news.assetManager.select-image": "Select image",
  "news.assetManager.select-video": "Select video (optional)",
  "news.assetManager.fileAlreadyExists":
    "A file with the same name already exists!",
  "news.assetManager.cancel": "Cancel",
  "news.assetManager.select-file": "Select file",
  "news.assetManager.save": "Save",
  "news.assetManager.keep_original_image": "Keep original size",

  "news.menu.tooltip.my-favourites": "My favourites",
  "news.menu.tooltip.manage-files": "Manage files",
  "news.menu.tooltip.add-new": "Add new",

  "news.editor.abstract.title": "Summary (in bold)",
  "news.article.remove.title": "Delete article permanently?",
  "news.article.remove.content":
    "If you delete this article, you won't be able to recover it. Do you want to delete it?",
  "news.article.remove.cancel-button": "Cancel",
  "news.article.remove.article": "Article",
  "news.editor.unsaved-changes": "You have unsaved changes",
  "news.editor.saved-changes": "All changes have been saved",
  "news.editor.author": "Author",
  "news.editor.enter-title": "Title",
  "news.editor.add-tag": "Add tag",

  "news.list.item.tooltip.edit": "The article can be edited",
  "news.list.item.tooltip.remove": "The article can be removed",
  "news.list.noArticle": "No articles found",

  "news.article.untitled": "Untitled",
  "news.article.no-abstract": "No abstract",
};

const tour = {
  "settings.welcome": "Game controls",
  "settings.overview": "Do you want a tour of the game controls?",
  "settings.start": "Yes",
  "settings.skip": "Skip",

  "tour.screen1.title": "Episode Progress",
  "tour.screen1.description": "Here you can see how far you have come.",
  "tour.screen2.title": "Competence diamonds",
  "tour.screen2.description":
    "Solve tasks to get more diamonds. Click here to see details of completed tasks.",
  "tour.screen3.title": "Activity coins",
  "tour.screen3.description":
    "The more often and more intensively you use talent :: digital, the more activity points you collect.",
  "tour.screen4.title": "Personal messages",
  "tour.screen4.description":
    "Click on the head for personal messages from a game character.",
  "tour.screen5.title": "Rewinder",
  "tour.screen5.description":
    "Here you can find out what to do next, and you can rewind to a prior point of the episode.",
  "tour.screen6.title": "Home button",
  "tour.screen6.description": "Here you can go back to home page",
  "tour.screen7.title": "Pause game",
  "tour.screen7.description": "Here you can pause the game.",
  "tour.screen8.title": "Your character's contact details.",
  "tour.screen8.description":
    "Here you can quickly and easily access your character's contact details. Just click on your avatar to open the contact details, then click on the information to copy it into the clipboard.",
  "tour.btn": "Next",
  "tour.finalBtn": "Finish",
};

const onboardingBuyer = {
  "onboardingBuyer.dashboard.seasons.season1": "01. When ideas learn to fly.",

  "onboardingBuyer.scenarios.button.start": "Start",
  "onboardingBuyer.scenarios.tooltip.locked": "Not available in the trial",

  "onboardingBuyer.scenarios.scenario1.title": "Data Security",
  "onboardingBuyer.scenarios.scenario1.description":
    "Test and increase privacy and security awareness in your organisation.",

  "onboardingBuyer.scenarios.scenario2.title": "Digital Communication",
  "onboardingBuyer.scenarios.scenario2.description":
    "Chat bots and other digital tools improve your workflows and processes. Develop your organisation further.",

  "onboardingBuyer.scenarios.scenario3.title": "Digital Fitness",
  "onboardingBuyer.scenarios.scenario3.description":
    "Test and increase your organisation's digital confidence.",

  "onboardingBuyer.scenarios.scenario4.title": "Data-Driven Organisation",
  "onboardingBuyer.scenarios.scenario4.description":
    "Data is the gold of today. Find out how your organisation can work better with it.",

  "onboardingBuyer.scenarios.scenario5.title": "Digital Sales",
  "onboardingBuyer.scenarios.scenario5.description":
    "How well equipped is your sales team to meet the demands of a digital sales force? Find out and boost their skills.",

  "onboardingBuyer.scenarios.scenario6.title": "Own Topics",
  "onboardingBuyer.scenarios.scenario6.description":
    "Your issue doesn't appear here? Talk to us, together we can realise your project",

  "onboardingBuyer.scenarios.scenario1.signals.signal1.what":
    "Improve defenses against spam and phishing.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.why":
    "Reduce the risk of financial losses and data breaches.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.how":
    "talent::digital offers suitable content (season 1 episode 5). If applicable, motivate the team to complete the basic IT security certification.",
  "onboardingBuyer.scenarios.scenario1.signals.signal1.scope.name":
    "Accounting",

  "onboardingBuyer.scenarios.scenario1.signals.signal2.what":
    "Increase understanding of strong passwords and multi-factor authentication.",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.why":
    "Reduce the risk of disruption and data loss due to intrusions",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.how":
    "talent::digital offers suitable content (tutorial). If applicable, motivate the team to complete the basic IT security certification.",
  "onboardingBuyer.scenarios.scenario1.signals.signal2.scope.name":
    "Accounting",

  "onboardingBuyer.scenarios.scenario1.signals.signal3.what":
    "Involve your management team.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.why":
    "The management team is a necessary support for employees in the digital transformation.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.how":
    "Inform the management team and explicitly invite them to try out talent::digital.",
  "onboardingBuyer.scenarios.scenario1.signals.signal3.scope.name":
    "Line management",

  "onboardingBuyer.scenarios.scenario2.signals.signal1.what":
    "Test the use of digital contact offerings and contact automation.",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.why":
    "Enable higher availability in, for example, customer service and sales.",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.how":
    "Basic Certification Digital Collaboration, Repeat Season 2 Episode 1 & 2",
  "onboardingBuyer.scenarios.scenario2.signals.signal1.scope.name":
    "Customer service",

  "onboardingBuyer.scenarios.scenario2.signals.signal2.what":
    "Increase understanding of agile working practices.",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.why":
    "Lower your project and development risks through incremental, adaptive working.",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.how":
    "Repeat Basic Certification Digital Collaboration, Season 2 Episode 3",
  "onboardingBuyer.scenarios.scenario2.signals.signal2.scope.name":
    "Consulting",

  "onboardingBuyer.scenarios.scenario2.signals.signal3.what":
    "Involve Your Magement Team.",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.why":
    "The management team supports employees in the digital transformation.",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.how":
    "Inform the management team and explicitly invite them to try out talent::digital",
  "onboardingBuyer.scenarios.scenario2.signals.signal3.scope.name":
    "Line management",

  "onboardingBuyer.departments.centralFunction": "Central Functions",
  "onboardingBuyer.departments.accounting": "Accounting",
  "onboardingBuyer.departments.humanResources": "HumanResources Department",
  "onboardingBuyer.departments.fieldService": "Field Service",
  "onboardingBuyer.departments.customerService": "Customer Service",
  "onboardingBuyer.departments.consulting": "Consulting",

  "onboardingBuyer.competenceAreas.informationAndDataLiteracy":
    "Information and Data Literacy",
  "onboardingBuyer.competenceAreas.communicationAndCollaboration":
    "Communication and Collaboration",
  "onboardingBuyer.competenceAreas.digitalContentCreation":
    "Digital Content Creation",
  "onboardingBuyer.competenceAreas.protectionAndSecurity":
    "Protection and Security",
  "onboardingBuyer.competenceAreas.problemSolving": "Problem Solving",
};

const travelBot = {
  "travelBot.title1": "It is time to<br />travel",
  "travelBot.title2": "Give your<br />cell phone number",
  "travelBot.title3": "Super!",
  "travelBot.description":
    "Tobi will get in touch with you right away with a message. He will help you book your trip.",
  "travelBot.startBtn": "Start now",
  "travelBot.continueBtn": "Continue",

  "travelBot.tickets.btnText": "Outward journey",
  "travelBot.tickets.yourJourney": "Your journey",
  "travelBot.tickets.direction": "Düsseldorf - Leirvik",

  "travelBot.form.salutation": "Salutation",
  "travelBot.form.title": "Title",
  "travelBot.form.name": "Name",
  "travelBot.form.email": "Email",
  "travelBot.form.Weiblich": "Ms",
  "travelBot.form.Männlich": "Mr",
  "travelBot.form.Divers": "Mx",

  "travelBot.booking.yourData": "Your data",
  "travelBot.booking.wallet": "Wallet",
  "travelBot.booking.bookBtn": "Book with costs",

  "travelBot.creditCard.use": "Credit card from the wallet use?",
  "travelBot.creditCard.name": "SunCopter credit card",

  "travelBot.booked.copy": "Copy to the wallet",
  "travelBot.booked.yourBooking": "Your booking",
  "travelBot.booked.eTicket": "E-Ticket",
  "travelBot.booked.type": "Easy train ride",
  "travelBot.booked.passengers": "Passengers",
  "travelBot.booked.adult": "adult",
  "travelBot.booked.date": "Date",
  "travelBot.booked.trainNo": "Train no.",
  "travelBot.booked.time": "Time",
  "travelBot.booked.class": "Class",
  "travelBot.booked.seat": "Seat",
  "travelBot.tomorrow": "Tomorrow",
};

const cloudapparticles = {
  "cloudapparticles.title": "Review: The Best Cloud Providers",
  "cloudapparticles.description":
    "From simple file storage to complete enterprise operating system -- always available, from everywhere. We introduce you to the best providers.",
  "cloudapparticles.pros": "Pros",
  "cloudapparticles.cons": "Cons",
  "cloudapparticles.button": "Select provider",
};

const cloudapp = {
  "cloudapp.title": "Solutions for smart companies of the future",
  "cloudapp.advantages": "Advantages",
  "cloudapp.formTitle": "Please enter the following information",
  "cloudapp.form.organization": "Organization",
  "cloudapp.form.name": "Name",
  "cloudapp.form.email": "E-mail address",
  "cloudapp.form.terms.conditions": "Terms and conditions",
  "cloudapp.form.terms.conditions.separator": " and ",
  "cloudapp.form.data.protection": "Data protection",
  "cloudapp.form.terms.conditions.end": " read",
  "cloudapp.form.2factorauth": "Set up 2-factor authentication",
  "cloudapp.form.marketingEmail": "Receive marketing email",
  "cloudapp.signedpage.title": "Thanks a lot!",
  "cloudapp.signedpage.text1": "You will receive a confirmation email shortly.",
  "cloudapp.signedpage.text2":
    "To activate your account, simply click on the activation link in the email.",
  "cloudapp.verification.title": "Account has been verified!",
  "cloudapp.verification.description":
    "Please select the number of people now.",
  "cloudapp.verification.button": "Next",
  "cloudapp.verification.package.small.title": "1 to 4",
  "cloudapp.verification.package.small.description": "For Free",
  "cloudapp.verification.package.medium.title": "5 - 10",
  "cloudapp.verification.package.medium.description": "219 &euro; / month",
  "cloudapp.verification.package.large.title": "11 - 20",
  "cloudapp.verification.package.large.description": "439 &euro; / month",
  "cloudapp.2factor.title": "2 Factor authentication",
  "cloudapp.2factor.description1": "Please give us your mobile number.",
  "cloudapp.2factor.description2":
    "We will send you a personal code to your mobile number. Please press the button and enter it here.",
  "cloudapp.2factor.mobilenumber": "Mobile number",
  "cloudapp.2factor.code": "Code",
  "cloudapp.2factor.button1": "Next",
  "cloudapp.2factor.button2": "Send code",
  "cloudapp.appsinstall.title": "Thanks a lot!",
  "cloudapp.appsinstall.description":
    "You can now download the {{provider}} apps to your device and share them with other users. Have lots of fun with it.",
  "cloudapp.appsinstall.button": "Download app",
  "cloudapp.appsWasInstalled.button":
    "The download was successful.<br />You can close the browser now.",
  "cloudapp.appsinstall.app.contacts": "Contacts",
  "cloudapp.appsinstall.app.wallet": "Wallet",
  "cloudapp.appsinstall.app.storage": "Storage",
  "cloudapp.back": "Back",
  "cloudapp.signup.email.error": "Incorrect E-Mail format",
};

const alerts = {
  "alerts.finder": "Finder",
  "alerts.alerts": "Alerts",
  "alerts.follow": "Follow interesting new content on the web",
  "alerts.placeholder": "Create alert for ...",
  "alerts.info.message": "This will create an email alert for ",
  "alerts.btn": "Create alert",
  "alerts.list.title": "My alerts ",
};

const storageApp = {
  "storageApp.name": "Files",
  "storageApp.main.folder.name": "Files",
  "storageApp.main.folder.noDocuments": "No documents.",
  "storageApp.folders.list.project": "Project",
  "storageApp.modal.share": "Share",
  "storageApp.modal.view": "View File",
  "storageApp.add.people": "Add people",
  "storageApp.contacts.modal.title": "Send project to:",
  "storageApp.add": "Add",
  "storageApp.message.field.placeholder": "Add a message",
  "storageApp.confirmation.message": "Your message was sent",
};

const gezwitscherApp = {
  "gezwitscherApp.profile.desc":
    "We write innovation and thinker spirit big! We know and create the trends of tomorrow!",
  "gezwitscherApp.profile.followers": "134 Follow me",
  "gezwitscherApp.profile.observe": "3632 Follower",
  "gezwitscherApp.tweet.content":
    "„Under water is bubbling! Only 24 hours left until the pre-sale launch of our BabblR underwater speaker! Of course with patented whale song technology!“",
};

const programmingGame = {
  "programmingGame.dialogs.success.title": "You completed the mission!",
  "programmingGame.dialogs.warning.title": "You did not complete your mission.",
  "programmingGame.dialogs.warning.text": "Try it again.",
  "programmingGame.dialogs.error.title": "Your instructions are incorrect.",
  "programmingGame.dialogs.information.title":
    "Do you want to skip this mission?",
  "programmingGame.dialogs.hint.title": "Would you like a tip?",
  "programmingGame.dialogs.button.text": "Yes",
  "programmingGame.dialog.deniedButton.text": "No",
  "programmingGame.menu.title": `Guide Randalf, the magician, safely through enchanted lands to his destination. Do you know the appropriate sayings from the Java script to help him?`,
  "programmingGame.menu.description": "You passed all levels!",
  "programmingGame.descriptionPage.tasks": "Instructions",
  "programmingGame.descriptionPage.instructions": "Your mission",
  "programmingGame.descriptionPage.hints": "Tips",
  "programmingGame.descriptionPage.button.next": "Further",
  "programmingGame.descriptionPage.button.conclude": "Conclude",
  "programmingGame.endScreen.title": "Congratulations!",
  "programmingGame.endScreen.description1":
    "You have completed all the missions and guided Randalf safely through the enchanted lands.",
  "programmingGame.endScreen.description2": "You can close the app now.",
  "programmingGame.endScreen.skipped.title": "Uhm, ok...",
  "programmingGame.endScreen.skipped.description1":
    "You skipped all the missions and left poor Randalf to fend for himself.",
  "programmingGame.tab.0": "Mission",
  "programmingGame.tab.1": "Manual",
  "programmingGame.tab.2": "Game board",
  "programmingGame.tab.3": "Tips",
};

const reboot = {
  reboot: "Systems are restarted.",
};

const digidoc = {
  "digidoc.description": "Just send and sign",
  "digidoc.login.button": "Sign in with a DSUITE account",
  "digidoc.login.afterregister.description1": "Thank you for registering.",
  "digidoc.login.afterregister.description2":
    "Start now with your first document.",
  "digidoc.start.button": "Start now",
  "digidoc.step": "Step",
  "digidoc.upload.document": "Upload document",
  "digidoc.upload.document.invalid.image": "Signature file is not valid",
  "digidoc.upload.document.select.again": "Please upload the signature again",
  "digidoc.button.next": "Next",
  "digidoc.button.share": "Share document",
  "digidoc.selectFiles.title": "Files",
  "digidoc.sign.document": "Sign document",
  "digidoc.sign.document.input.title": "Full name",
  "digidoc.sign.document.name.preview": "Preview",
  "digidoc.check.document": "Check document",
  "digidoc.add.people": "Add people",
  "digidoc.add.people.skip": "Skip",
  "digidoc.add.people.new-signatory-email":
    "Email address of the new signatory",
  "digidoc.add.people.new-signatory-name": "Name of the new signatory",
  "digidoc.add.people.add.new": "+ Add more people?",
  "digidoc.share.title": "Share",
  "digidoc.share.document": "Document",
  "digidoc.share.signatory": "Signatory",
  "digidoc.document.sent.title": "Document sent",
  "digidoc.document.sent.text1": "Your document has been sent.",
  "digidoc.document.sent.text2":
    "We will inform you by E-Mail if someone has signed the document.",
};

const goblinNews = {
  "goblin.news.news": "Goblin INC. developed<br />new helicopter",
  "goblin.news.video": "video",
  "goblin.news.register.text":
    "SunCopter: An Invention of Goblin Inc. - Coming Soon!<br />Register now and receive more information",
  "goblin.news.register.name": "First name",
  "goblin.news.register.surname": "Surname",
  "goblin.news.register.income": "Income",
  "goblin.news.register.email": "E-mail address",
  "goblin.news.register.start": "Start now",
};

const levelEnd = {
  "level.end.completed": "Level {level} completed",
  "level.end.to.results": "To the results",
};

const notification = {
  "notification.newMessage": "New message",
  "notification.newGroup": "New group",
  "notification.read": "Read",
  "notification.ok": "OK",
  "notification.continue": "Continue",
  "notification.congratulation": "Congratulation!",
  "notification.projectChat": "SunCopter Group",
  "notification.teamChat": "Team Chat",
  "notification.bubblr": "BubblR Group",
  "notification.groupAdded": "SunCopter Group",
  "notification.newGroupMessage": "You receive new message",
  "notification.from": " from ",
  "notification.event.title": "Event starting soon",
  "notification.event.description":
    'Your event, video call with {conversationPartnerName} ({companyName}) re: "{topic}" is starting soon',
};

const itttapp = {
  "ittt.title1": "IF",
  "ittt.title2": "THIS",
  "ittt.title3": "THAN",
  "ittt.title4": "THAT",
  "ittt.subtitle": "Process automation made easy.",
  "ittt.start": "START",
  "ittt.choose.trigger": "Choose a trigger",
  "ittt.choose.action": "Choose an action",
  "ittt.continue": "CONTINUE",
  "ittt.trigger.sender.label": "Select the sender",
  "ittt.trigger.option.label": "Select option",
  "ittt.trigger.checkbox.label.signal": "Signal strength (RSSI)",
  "ittt.trigger.checkbox.label.voltage": "Voltage (V)",
  "ittt.trigger.checkbox.label.energy": "Energy (kWh)",
  "ittt.action.action.label": "What should be done?",
  "ittt.action.data.label": "Which dates?",
  "ittt.trigger.subject.label": "What should appear in the subject?",
  "ittt.trigger.subject.label.energysense": "Select data points:",
  "ittt.action.reason.label": "Choose the reason",
  "ittt.action.done.label": "What should be done?",
  "ittt.action.placeholder.surfpatrol":
    "<p>The following placeholders are available:</p><ul><li><i>The sender of the email</i>: {{emailSender}}</li><li><i>The title of the email</i>: {{emailSubject}}</li><li><i>The content of the email</i>: {{emailText}}</li></ul>",
  "ittt.action.placeholder.dashdb":
    "<p>The following placeholders are available:</p><ul><li><i>Measurement time</i>: {{time}}</li><li><i>Measuring device</i>: {{device}}</li><li><i>Measured value:</i>: {{value}}</li></ul>",
  "ittt.action.placeholder.none":
    "<p>The following placeholders are available:</p><ul><li>None</li></ul>",
  "ittt.title.sender": "Sender:",
  "ittt.title.subject": "Subject:",
  "ittt.title.reason": "Reason:",
  "ittt.title.reason.dashdb": "Do:",
  "ittt.title.whatToDo": "What:",
  "ittt.title.whatToDo.dashdb": "Data:",
  "ittt.title.data-points": "Data points:",
  "ittt.button.finished": "FINISHED?",
  "ittt.ready.page.title": "READY?",
  "ittt.button.further": "Close app",
  "ittt.ready.page.subtitle": "The automatic process was saved.",
  "ittt.form.options.alert": "Alert",
  "ittt.form.options.digidoc": "DIGIdoc",
  "ittt.form.options.tina": "Dr. Tina Digel",
  "ittt.form.options.dsuite": "DSuite",
  "ittt.form.options.restart": "Restart",
  "ittt.form.options.battery": "Battery warning",
  "ittt.form.options.signal": "Signal strength warning",
  "ittt.form.options.newdata": "New data",
  "ittt.form.options.new": "Insert new data",
  "ittt.form.options.update": "Update data",
  "ittt.form.options.delete": "Delete data",
  "ittt.form.options.vulnerability": "Report a vulnerability",
  "ittt.form.options.virus": "Report virus",
  "ittt.form.options.website": "Report website",
  "ittt.form.dashdb.label.source": "Source",
  "ittt.form.dashdb.label.timestamp": "Timestamp",
  "ittt.form.dashdb.label.value": "Value",
  "ittt.wrongApp.title": "Connect {{name}}",
  "ittt.wrongApp.subtitle": "Please connect your account",
  "ittt.wrongApp.input.login": "Login name",
  "ittt.wrongApp.input.password": "Password",
  "ittt.wrongApp.alert.message": "Can't find user",
};

const surfpatrol = {
  "surfpatrol.description":
    "Thank you for helping us protect against phishing websites. If, in your opinion, you have come across a page to access personal data of a user, please report this page using the form below.",
  "surfpatrol.input.label": "What's the url?",
  "surfpatrol.button": "Send",
  "surfpatrol.text.thanks": "THANK YOU!",
  "surfpatrol.text.thanks.description":
    "The page you reported is checked. Thank you for helping us fight phishing! Your Surf-Patrol Team.",
  "surfpatrol.report.another": "+ Report another page?",
  "surfpatrol.contact":
    "Would you like to contact us directly? \nphishing@surf-patrol.com",
};

const dashboard = {
  "dashboard.main.profile-link": "My Profile",
  "dashboard.main.menu.home": "Play",
  "dashboard.main.menu.news": "News",
  "dashboard.main.menu.competition": "Competition",
  "dashboard.main.menu.report": "Report",
  "dashboard.main.menu.explore": "Explore",
  "dashboard.main.menu.support-button": "Support",
  "dashboard.main.menu.company-report": "Management Cockpit",
  "dashboard.main.menu.admin": "Our Organisation",
  "dashboard.main.menu.marketplace": "Qualification Marketplace",
  "dashboard.main.menu.invitation": "Invite colleague",
  "dashboard.main.menu.logout-button": "Log out",
  "dashboard.main.menu.invite-colleague-button": "Invite colleagues",

  "dashboard.page.home.title": "Focus topics",
  "dashboard.page.home.competences": "Competence Areas:",
  "dashboard.page.home.button.further-information": "Further information",
  "dashboard.page.home.button.thematic-focus": "Thematic focus",
  "dashboard.page.home.seasons-dropdown": "More seasons",
  "dashboard.page.home.seasons-dropdown.topics": "Focus:",
  "dashboard.page.home.replay-episode":
    'Something unclear still? Check "Take-aways", then try again! You cannot lose points that you have already achieved.',
  "seasonend.congratulations":
    "Not happy with the results? Have a look at the take-aways and just try again! You cannot lose points that you have already achieved.",
  "dashboard.page.home.competencies":
    "Competencies {passedTests}/{totalTests} {caret}",
  "dashboard.page.home.badges": "Badges {earnedBadges}/{totalBadges} {caret}",
  "dashboard.page.home.take-aways": "Takeaways {caret}",
  "dashboard.page.home.take-aways.intro-failed":
    "You have not yet collected the following competence diamonds. Here are some links that might help you collect these diamonds when you replay the episode.",
  "dashboard.page.home.take-aways.intro-passed":
    "Here are some links related to the competence diamons you've already collected.",
  "dashboard.page.home.take-aways.show-passed":
    "Show collected competence diamonds",
  "dashboard.page.home.take-aways.hide-passed":
    "Hide collected competence diamonds",
  "dashboard.page.home.publish.tooltip.locked":
    "The episode is not ready for publishing to users.",
  "dashboard.page.home.publish.tooltip.ready":
    "The episode can be published to users.",
  "dashboard.page.home.publish.tooltip.published":
    "The episode is published and all users can play it.",
  "dashboard.page.home.publish.btn.cancel": "Not now",
  "dashboard.page.home.publish.btn.confirm": "Publish",
  "dashboard.page.home.publish.message":
    "Are you sure you want to publish this episode?",
  "dashboard.page.home.checkpoint-episode-not-met-tooltip":
    "You need to have a score of at least {percentage}% in the last playable episode in order to proceed",
  "dashboard.page.home.episode-report.checkpoint-not-met":
    "At least {percentage}% of the tasks in this episode need to be correctly completed to proceed to the next episode. Please revisit the episode.",

  "dashboard.page.home.missions.title": "Missions",
  "dashboard.page.home.missions.subheader":
    "Mission targets are set for you by a system administrator. Missions are complete when the bar reaches {threshold}%",
  "dashboard.page.home.missions.you": "Your part",
  "dashboard.page.home.missions.everyone": "Everyone",
  "dashboard.page.home.missions.show-more": "Show More",
  "dashboard.page.home.missions.show-less": "Show Less",

  "dashboard.page.play.continue.btn": "Play on",
  "dashboard.page.completed.episodes": "Completed {completed}/{total}",
  "dashboard.page.episode.play": "Play Episode {episode} ",
  "dashboard.page.coming.soon": "Coming Soon",

  "dashboard.page.profile.title": "MY PROFILE",
  "dashboard.page.profile.data.title": "My data",
  "dashboard.page.profile.profile.title": "My player profile",
  "dashboard.page.profile.termsandconditions.title": "Terms & Conditions",
  "dashboard.page.profile.account.title": "Account settings",
  "dashboard.page.profile.button.save": "Save",
  "dashboard.page.profile.termsAndConditions": "Terms of Service",
  "dashboard.page.profile.privacyPolicy": "Privacy Policy",

  "dashboard.page.report.title": "Report",
  "dashboard.page.report.tab.competence": "Competence",
  "dashboard.page.report.tab.progress": "Progress",
  "dashboard.page.report.tab.engagement": "Engagement",
  "dashboard.page.report.title.competencyPoints": "Competency diamonds",
  "dashboard.page.report.title.certificates": "Certificates",
  "dashboard.page.report.fallback": "There is not enough data available",
  "dashboard.page.report.dialog.confirm": "Confirm",
  "dashboard.page.report.dialog.cancel": "Cancel",
  "dashboard.page.report.dialog.description.title": "Congratulations!",
  "dashboard.page.report.dialog.description.text":
    "You have reached your first certificate. Please tell us now your first name and surname to whom the certificate should be displayed.",
  "dashboard.page.report.dialog.attention.title": "Attention:",
  "dashboard.page.report.dialog.attention.text":
    "The name cannot be changed later. Therefore, please make sure that it is correct before submitting.",
  "dashboard.page.report.dialog.placeholder.firstName": "First Name",
  "dashboard.page.report.dialog.placeholder.lastName": "Last Name",
  "dashboard.page.report.dialog.validationError.firstName":
    "Please enter your first name",
  "dashboard.page.report.dialog.validationError.lastName":
    "Please enter your last name",
  "dashboard.page.report.title.results": "My Result",
  "dashboard.page.report.results.team": "Best in the Team",
  "dashboard.page.report.results.organisation": "Best in the Organization",
  "dashboard.page.report.results.passed": "{score} Completed Tasks",
  "dashboard.page.report.results.tried": "{score} Done Tasks",
  "dashboard.page.report.results.tooltip.title":
    "You haven't configured your team.",
  "dashboard.page.report.results.tooltip.noData":
    "There's not enough data available",
  "dashboard.page.report.results.tooltip":
    "Please configure your department and team {link}",
  "dashboard.page.report.results.tooltip.link": "here",

  "dashboard.page.company-report.competence": "Competence diamonds",
  "dashboard.page.company-report.Organisationseinheiten":
    "Organizational units",
  "dashboard.page.company-report.Organisationszugehörigkeit":
    "Organizational affiliation",
  "dashboard.page.company-report.Management-Verantwortung":
    "Management responsibility",
  "dashboard.page.company-report.Beschäftigungsverhältnis": "Employment type",
  "dashboard.page.company-report.Bildungsabschluss":
    "Educational qualification",
  "dashboard.page.company-report.Geschlecht": "Gender",
  "dashboard.page.company-report.Funktion": "Function",
  "dashboard.page.company-report.Altersgruppe": "Age group",
  "dashboard.page.company-report.Standort": "Site",
  "dashboard.page.company-report.departments.btn.points": "Points",
  "dashboard.page.company-report.departments.btn.placement": "Placement",
  "dashboard.page.company-report.departments.tooltip.total": "     All tasks",
  "dashboard.page.company-report.departments.tooltip.passed": "Tasks passed",
  "dashboard.page.company-report.departments.tooltip.tried":
    "       Played tasks",
  "dashboard.page.company-report.departments.tooltip.progress.top":
    "% Value from played tasks",
  "dashboard.page.company-report.departments.tooltip.progress.bottom":
    "and tasks passed",

  "dashboard.page.company-report.missions": "Missions",
  "dashboard.page.company-report.missions.department-team-function":
    "Department, Team or Function",
  "dashboard.page.company-report.missions.competence": "Competence",
  "dashboard.page.company-report.missions.difficultyLevel": "Difficulty level",

  "dashboard.page.company-report.missions.competenceLevels.1": "Foundation",
  "dashboard.page.company-report.missions.competenceLevels.2": "Intermediate",
  "dashboard.page.company-report.missions.competenceLevels.3": "Advanced",

  "dashboard.page.company-report.missions.description": `Define your missions here. This allows you to determine the level of difficulty at which a department, a team or a specific function should master the game (mission objective). The available levels are "Foundation", "Intermediate" and "Advanced". talent::digital then provides current status information, shows the progress compared to the baseline assessment and presents the trend in a 14-day comparison.`,
  "dashboard.page.company-report.missions.createButton": "Create",
  "dashboard.page.company-report.missions.emptyStateMessage":
    "Create your first new mission",
  "dashboard.page.company-report.missions.noData": "Not enough data collected.",
  "dashboard.page.company-report.missions.noMissionAdded":
    "No mission has been added",
  "dashboard.page.company-report.missions.progress":
    "Baseline assessment {initial}%, improvement {progress}%",

  "dashboard.page.company-report.missions.trends.done": "Done",
  "dashboard.page.company-report.missions.trends.onTrack": "On Track",
  "dashboard.page.company-report.missions.trends.stalled": "Stalled",
  "dashboard.page.company-report.missions.trends.declining": "Declining",
  "dashboard.page.company-report.missions.trends.none": "Not enough data",

  "dashboard.page.company-report.missions.whatScope.ALL": "All",
  "dashboard.page.company-report.missions.whatScope.COMPETENCE_AREA":
    "Competence Area",
  "dashboard.page.company-report.missions.whatScope.COMPETENCE": "Competence",
  "dashboard.page.company-report.missions.whatScope.SUB_COMPETENCE":
    "Sub Competence",
  "dashboard.page.company-report.signals.VERYHIGH": "Very high",
  "dashboard.page.company-report.signals.HIGH": "High",
  "dashboard.page.company-report.signals.MEDIUM": "Medium",
  "dashboard.page.company-report.signals.LOW": "Low",
  "dashboard.page.company-report.signals.NO_DATA": "Not enough data",
  "dashboard.page.company-report.signals.NOT_ACTIVE": "Very Low",
  "dashboard.page.company-report.feedback.PERFECT": "No action needed",
  "dashboard.page.company-report.feedback.MIXED": "Action recommended",
  "dashboard.page.company-report.feedback.BAD": "Action needed",
  "dashboard.page.company-report.feedback.NO_DATA": "Not enough data",

  "dashboard.page.company-report.missions.link-to-crate": "Add new mission ",
  "dashboard.page.company-report.missions.link": "here",

  "dashboard.page.company-report.chart.select-season": "Select Season",

  "dashboard.page.company-report.statistics.active-users": "Active players",
  "dashboard.page.company-report.statistics.participating-users":
    "Registered players",
  "dashboard.page.company-report.statistics.active-user": "Episodes",
  "dashboard.page.company-report.statistics.no-data": "No Data",

  "dashboard.page.company-report.tooltip.competence":
    "Median of tasks played and passed. The data always keeps changing and adapts to the progress of the game.",
  "dashboard.page.company-report.tooltip.organization-header":
    "Category switch",
  "dashboard.page.company-report.tooltip.organization-paragraph1":
    "This section shows the test results further categorized by player's attributes, such as organizational unit or function.",
  "dashboard.page.company-report.tooltip.organization-paragraph2":
    "Click on the category in the title bar to switch to different categories.",
  "dashboard.page.company-report.tooltip.organization-paragraph3":
    "We recommend waiting until enough tasks have been completed before interpreting the figures. The more tasks are completed, the more reliable the picture becomes.",
  "dashboard.page.company-report.tooltip.points-header": "Competence table",
  "dashboard.page.company-report.tooltip.points-paragraph1":
    "The left-hand number shows the median number of tasks passed, the right-hand number the median number of tasks performed. If no numbers are displayed, the required minimum number of players is not yet present in the feature.",
  "dashboard.page.company-report.tooltip.points-paragraph2":
    "Depending on the progress in the game, the numbers will always keep moving. The values can rise or fall.",
  "dashboard.page.company-report.tooltip.participants-header":
    "Registered players",
  "dashboard.page.company-report.tooltip.participants-paragraph1":
    "Here is the total number of registered players.",
  "dashboard.page.company-report.tooltip.participants-paragraph2":
    "The number in brackets shows the new registrations of the last two weeks.",
  "dashboard.page.company-report.tooltip.active-header": "Active players",
  "dashboard.page.company-report.tooltip.active-paragraph1":
    "This is the number of players who have actively played the game in the last two weeks.",
  "dashboard.page.engagement.title": "Activity coins",
  "dashboard.page.engagement.tooltip":
    "The number in brackets shows the change in the last two weeks.",
  "dashboard.page.company-report.departments.subheader":
    "Grayed out areas indicate that there is not enough data yet. This can happen if the participants are not advanced enough in the game, or if the feature contains too few participants.",
  "dashboard.page.company-report.no-data.tooltip":
    "Not enough tasks have been played to get a test result.",

  "dashboard.page.company-report.signals": "Signals",
  "dashboard.page.company-report.signals.collecting": "Collecting Data...",
  "dashboard.page.company-report.signals.why": "Why?",
  "dashboard.page.company-report.signals.how": "How?",
  "dashboard.page.company-report.activity": "Activity",
  "dashboard.page.company-report.activity.failToLoad": "Failed to load data",
  "dashboard.page.company-report.signals.departments": "Relevant Departments",
  "dashboard.page.company-report.signals.functions": "Relevant Job Functions",

  "dashboard.page.company-report.feedback": "Employee feedback",
  "dashboard.page.company-report.feedback.noFeedbackAdded":
    "No feedback data available",

  "dashboard.page.admin.title": "Our Organisation",
  "dashboard.page.admin.tools": "Our Tools",
  "dashboard.page.admin.tools.description":
    "Please select the tools used by our organization. Learning materials for these tools, if available, will be preferred. You can select any number of tools per category. Miss something? Let us know through the contact button.",

  "dashboard.page.admin.save": "Save",
  "dashboard.page.admin.reset": "Reset",

  "dashboard.page.admin.tools.chat": "Chat",
  "dashboard.page.admin.tools.calendar-services": "Appointment Management",
  "dashboard.page.admin.tools.project-collaboration": "Project Management",
  "dashboard.page.admin.tools.crm": "CRM",
  "dashboard.page.admin.tools.video-conference": "Video Conference",
  "dashboard.page.admin.tools.chatbot": "Chatbot",
  "dashboard.page.admin.tools.document-creation": "Document Creation",

  "dashboard.page.invitation.title": "Invite colleagues to talent::digital",
  "dashboard.page.invitation.mail-subject":
    "Become more digital with talent::digital!",
  "dashboard.page.invitation.mail-body":
    "Use this link to register to talent::digital and to develop your digital skills:%0D{link}%0D",
  "dashboard.page.invitation.description":
    "Use an email or link to easily invite your colleagues to talent::digital.",

  "dashboard.page.invitation.email.title": "Invite via e-mail",
  "dashboard.page.invitation.email.description":
    "Send your invitation via your email program. It is best to add something personal so that colleagues do not mistake the invitation for spam or phishing.",
  "dashboard.page.invitation.email.action": "Open email",

  "dashboard.page.invitation.link.title": "Invite via link",
  "dashboard.page.invitation.link.description":
    "Copy a link to your clipboard. From the clipboard, you can paste the link into, for example, chats, e-mails or intranet messages.",
  "dashboard.page.invitation.link.action": "Copy link",

  "dashboard.page.invitation.copied": "Copied to clipboard",

  "dashboard.company.chart.tooltip":
    "{players} players have not finished an episode yet.",
  "dashboard.company.chart.tooltip1":
    "{players} players have played episode {episode}",

  "dashboard.hubspot.title": "Own topics? - No problem!",
  "dashboard.hubspot.text":
    "Would you like to train your own topics? With pleasure! talent :: digital can be individually adapted to your needs. Talk to us!",

  "dashboard.news.edit": "Edit",
  "dashboard.news.done": "Done",
  "dashboard.news.publish": "Publish",
  "dashboard.news.unpublish": "Unpublish",
  "dashboard.news.select-image": "Select image or video",
  "dashboard.news.draft": "Draft",
  "dashboard.news.published": "Published",
  "dashboard.news.remove": "Remove article",

  "dashboard.banner.demo.title": "Hello {player}!",
  "dashboard.banner.demo.description":
    "Talent::digital has even more to offer.<br /> Do you want to further analyze and develop the digital competencies of your organization? Contact us.",
  "dashboard.banner.demo.description.mobile":
    "Are you interested in the full version of talent::digital? Feel free to contact us.",
  "dashboard.banner.demo.btn": "Get in touch",
  "dashboard.banner.demo.company":
    "<p>Learn more?</p><p>We would be pleased to discuss possible applications and functions of talent::digital with you.</p>",
  "dashboard.banner.demo.company.btn": "Contact us",

  "dashboard.demo.popup.s01e05":
    '<p>Congratulations!</p> <p>You have already mastered 5 episodes. </p> <p>You will now have access to the "Qualification Marketplace". Here you will find tailor-made training offers. </p> <p>Take a look at it directly. </p>',
  "dashboard.demo.popup.link": "More information about talent::digital",
  "dashboard.report.popup.headerContent": "Congratulations!",
  "dashboard.report.popup.content": "You have achieved threshold of:",
  "dashboard.report.popup.contentEnd": "You can download Your certificate!",
  "dashboard.report.popup.btn.content": "Get certificate",
  "dashboard.report.popup.btn.contentReject": "I'll do that later!",

  "dashboard.menu.progress.bar.value": "Profile {value}% complete",

  "dashboard.player-progress.list-title": "Set up your account",
  "dashboard.player-progress.demographic-data": "Fill your demographic data",
  "dashboard.player-progress.player-settings": "Set your name",
  "dashboard.player-progress.invite-teammate": "Invite your teammates",
  "dashboard.tour.1": "At the end of each episode you will get an overview.",
  "dashboard.tour.1.header": "OVERVIEW:",
  "dashboard.tour.2": "Here you can see which tasks you have solved.",
  "dashboard.tour.2.header": "COMPETENCE:",
  "dashboard.tour.3":
    "Here you can see which badges you have received. Badges are awarded for special achievements.",
  "dashboard.tour.3.header": "BADGE:",
  "dashboard.tour.4":
    'Here you can see your personal materials for "further knowledge".',
  "dashboard.tour.4.header": "FURTHER KNOWLEDGE:",
  "dashboard.tour.5": "Here you come to the overview page with all seasons.",
  "dashboard.tour.5.header": "BACK:",
  "dashboard.tour.6":
    "And now have fun! The talent::digital team is always at your side via the contact button.",
  "dashboard.tour.6.header": "CONTACT:",
  "dashboard.tour.support-text": "Next",
  "dashboard.tour.support-text-finish": "Finish",
  "dashboard.tour.episode.hint": "Click here to start your first episode",

  "dashboard.company.tour.1":
    "How far has your organisation progressed towards its goals?",
  "dashboard.company.tour.1.header": "Missions",

  "dashboard.company.tour.2":
    "What insights from player data can help your organization improve?",
  "dashboard.company.tour.2.header": "Signals",

  "dashboard.company.tour.3":
    "What is your team's attitude toward your goals, and how do they work?",
  "dashboard.company.tour.3.header": "Feedback",

  "dashboard.company.tour.4": "How active is your organisation in game?",
  "dashboard.company.tour.4.header": "Activity",

  "dashboard.recommendations.description": "Description",
  "dashboard.recommendations.provider": "Provider",
  "dashboard.recommendations.trainingType": "Training type",
  "dashboard.recommendations.cost": "Cost",
  "dashboard.recommendations.trainingType.faceToFace": "Face to face",
  "dashboard.recommendations.trainingType.online": "Online",
  "dashboard.recommendations.free": "Free",
  "dashboard.recommendations.link": "Visit",
};

const buttons = {
  "sf.button.play": "Play!",
  "sf.button.ok": "Ok",
  "sf.button.continue": "Continue",
  "sf.button.safe": "Safe",
  "sf.button.unsafe": "Unsafe",
  "sf.button.ask": "Ask",
  "sf.button.search": "Search",
  "sf.button.level": "Level ",
  "sf.button.finished": "Finished",
  "sf.button.skip": "Skip round?",
  "sf.button.next.round": "Start next round",
};

const sfOutro = {
  "sf.outro.level": "Level ",
  "sf.outro.round": "Round ",
  "sf.outro.finished": " FINISHED",
  "sf.outro.levelup": "You recognized ",
  "sf.outro.levelup.round1": " emails correctly.",
  "sf.outro.levelup.round2": " chats correctly.",
  "sf.outro.levelup.round3": " websites correctly.",
  "sf.outro.levelup.round4": " emails correctly.",
  "sf.outro.finished.title.round1": "Spam emails",
  "sf.outro.finished.title.round2": "Unsafe chats",
  "sf.outro.finished.title.round3": "Unsafe websites",
  "sf.outro.finished.title.round4": "Spear phishing",
  "sf.outro.finished.text.round1": `Indications for spam emails are:
    <ul>
      <li>Obscure sender addresses.</li>
      <li>Links to dubious websites or attachments.</li>
      <li>Inconsistencies between sender, title and content.</li>
      <li>Urgent requests for action and sensitive topics.</li>
    </ul>
    When in doubt: Ignore it or do a quick search.`,
  "sf.outro.finished.text.round2": `A few rules for safer chatting:
    <ul>
      <li>Do not accept contact requests from unknown people.</li>
      <li>Never answer questions about passwords or codes.</li>
      <li>Only click on links to destinations you know.</li>
      <li>Be wary of incoherent comments from friends or colleagues ("Is that you?").</li>
    </ul>
    If in doubt, ask a known sender or ignore the message.`,
  "sf.outro.finished.text.round3": `Fraudulent websites try to slip you unwanted or dangerous software or elicit confidential data.
    <ul>
      <li>Pay attention to the address bar in your browser ("https" and a correctly spelled, trustworthy address).</li>
      <li>Do not enter confidential information (passwords, bank details) into unknown websites.</li>
      <li>Do not download files from unknown websites. A website can't judge if you have outdated software or a virus.</li>
    </ul>`,
  "sf.outro.finished.text.round4": `It is most difficult when attacks are tailored to you personally. It doesn't matter if you are "just a little employee". You are the starting point for the next attack. Therefore:
    <ul>
      <li>Never answer questions about your organization's internals to strangers. Do not give out contacts that are not public anyway (reception, support).</li>
      <li>Be careful with incoherent requests for sensitive actions (password change, download), even if they are from acquaintances or colleagues. Rather call and ask.</li>
    </ul>`,
};

const SFTour = {
  "sf.tour.start.dialog.title": "Introduction",
  "sf.tour.start.dialog.text":
    "Help York and flag potentially dangerous content. Keep a cool head.",
  "sf.tour.screen2": "Is this message safe or unsafe?",
  "sf.tour.screen3": "Is the message safe?<br/>Press 'Safe'",
  "sf.tour.screen4": "Is the message unsafe?<br/>Press 'Unsafe'",
  "sf.tour.screen5":
    "Click on 'Ask' to contact the person who sent the message.<br/><br/>That will cost you 10 seconds of your playing time.",
  "sf.tour.screen6":
    "Click on 'Search' to use a search engine.<br/><br/>That costs you 5 seconds of your playing time.",
  "sf.tour.end.dialog.title": "Very good",
  "sf.tour.end.dialog.text": "Here we go!",
};

const sf = {
  "sf.help.dialog.title": "Request...",
  "sf.help.dialog.safe": "The sender is safe!!",
  "sf.help.dialog.unsafe": "The sender is unsafe!",
  "sf.start-end-page.start-text":
    "Extortionate software or phishing mails - criminals are often quite resourceful when it comes to breaking into the IT systems of organizations. Can you distinguish good from bad in the allotted time?",
  "sf.start-end-page.end-text":
    "Didn't get all the way through? That doesn't matter. Take your time and make the right decisions rather than rushing to a wrong click.",
  "sf.help.search.nohelp": "I didn't find anything",
  "sf.help.search.safe": "This is not spam",
  "sf.help.search.unsafe": "This is spam",
  "sf.help.ask.chat.safe": "Your contact confirms they sent your this.",
  "sf.help.ask.chat.unsafe":
    "Your contact says they didn't send you this message.",
  "sf.help.ask.chat.nohelp": "This address does not appear in your contacts.",
  "sf.help.ask.website": "Sorry, I don't know who to contact.",
  "sf.help.ask.email.nohelp": "You did not get an answer.",
  "sf.help.ask.email.safe":
    "Your contact confirms that they sent you this email.",
};

const quizTime = {
  "quizTime.intro.decision1": "Yes of course!",
  "quizTime.intro.decision2": "No, I'm too tired for that.",
  "quizTime.avatar.description": "I've got a question...",
  "quizTime.title.titleDescription": "(Multiple choices possible)",
  "quizTime.title.noQuestion": "That's it for now",
  "quizTime.avatar.noQuestion-before-questions":
    "Test your knowledge here. A red circle on the Quiztime icon shows that new questions are available.",
  "quizTime.no-question-info.title":
    "We will inform you if there are new quiz questions.",
  "quizTime.button.skip": "Skip",
  "quizTime.button.finish": "Finish",
  "quizTime.avatar.answer": "And here's the solution...",
  "quizTime.button.continue": "Continue",
  "quizTime.button.moreInfo": "More Info",
  "quizTime.content.box.title": "More information on the topic?",
  "quizTime.content.box.open.button": "Show",
  "quizTime.content.button.back": "Back",
  "quizTime.dialog.msg":
    "Hello {{settings.playerName}}! I have some more Quiz Time questions for you, regarding the current topic of this episode. Good luck and have fun!",
  "quizTime.dialog.button": "Show questions",
};

const tinaTweets = {
  "tinaTweets.error": "Could not load Tina tweets",
};

const badges = {
  /** S00EP1 */
  "badges.zero-to-hero": "Zero to hero",
  "badges.description.zero-to-hero":
    "Great! You have entered all the required data and successfully created your profile.",
  "badges.safety-first": "Safety first",
  "badges.description.safety-first":
    "Congratulations, you managed to fend off the cyber attack with a very strong, complex password!",
  /** S01EP1 */
  "badges.Recherche-King": "Research King",
  "badges.Crypto-Meister": "Crypto master",
  "badges.Organisationstalent": "Organizational talent",
  "badges.Sharing is caring": "Sharing is caring",

  "badges.description.Recherche-King":
    "Your online research skills are above average!",
  "badges.description.Crypto-Meister":
    "You have an above-average eye for good apps and security.",
  "badges.description.Organisationstalent":
    "You can easily coordinate appointments!",
  "badges.description.Sharing is caring":
    "You know exactly how to share important links and information with others!",

  /** S01EP2 */
  "badges.Cloud Specialist": "Cloud Specialist",
  "badges.Secret Service": "Secret Service",
  "badges.The Traveler": "The Traveler",

  "badges.description.Cloud Specialist":
    "You have an above-average eye for cloud providers and security.",
  "badges.description.Secret Service":
    "They know exactly how to securely share important files.",
  "badges.description.The Traveler":
    "You can easily book tickets online and travel wherever you want!",

  /** S01EP3 */
  "badges.Good Sign(s)": "Good Sign(s)",
  "badges.Web Watcher": "Web Watcher",
  "badges.Wizard of Code": "Wizard of Code",

  "badges.description.Good Sign(s)":
    "You know exactly how to sign documents with your digital signature.",
  "badges.description.Web Watcher":
    "You know how to set up notifications to stay up to date",
  "badges.description.Wizard of Code":
    "You have an idea how programming works!",

  /** S01EP4 */
  "badges.Web shield": "Web shield",
  "badges.Work Simplifier": "Simplifier",

  "badges.description.Web shield": "You know how to report malicious sites",
  "badges.description.Work Simplifier":
    "You can easily automate simple tasks at work!",

  /** S01EP5 */
  "badges.Spam Fighter": "Spam Fighter",
  "badges.description.Spam Fighter":
    "You have an above-average eye for spam emails and fake sites!",

  /** S01EP6 */
  "badges.Knowledge on board": "Knowledge on board",
  "badges.Automation professional": "Automation professional",

  "badges.description.Knowledge on board":
    "You have vast knowledge about the digital world!",
  "badges.description.Automation professional":
    "You can easily automate difficult processes",

  /** S01EP7 */
  "badges.Email automation pro": "Email automation professional",
  "badges.description.Email automation pro":
    "You can easily clean your mailbox using mail automation rules.",

  /** S01EP8 */
  "badges.Digital Zen-Master": "Digital Zen Master",
  "badges.description.Digital Zen-Master":
    "Your personal well-being always remains in balance even in everyday digital life. Very good!",

  /** S02EP1 */
  "badges.Little Helper": "Little Helper",
  "badges.description.Little Helper":
    "Congratulation! You know exactly how a chatbot becomes a support in everyday (work) life",

  /** S02E02 */
  "badges.hurdler": "Hurdler",
  "badges.description.hurdler":
    "No matter which channel and which language - even in spontaneous communication challenges between several colleagues you keep a cool head!",

  "badges.team_builder": "Team Builder",
  "badges.description.team_builder":
    "Team spirit is your thing - you're the link between team members and successful digital team meetings!",

  /** S02E03 */
  "badges.Kanban Master": "Kanban Master",
  "badges.description.Kanban Master":
    "You have mastered the fine art of Kanban and effortlessly embrace its capabilities! Bravo!",

  /** S02E04 */
  "badges.team_player": "Team Player",
  "badges.description.team_player":
    "You have invited more than one person to collaborate with you - your great commitment is hereby rewarded!",

  /** global */
  "badges.replay1": "One More Time",
  "badges.description.replay1":
    "New round, new chances - you don't give up and play a completed episode once again!",

  "badges.profile-fill": "The Inner Monk",
  "badges.description.profile-fill":
    "100% of your profile is filled in - congratulations, you've managed to satisfy your inner Monk!",

  /** S91E01 */
  "badges.s91-Crypto-Meister": "Crypto master",
  "badges.s91-Organisationstalent": "Organizational talent",

  "badges.description.s91-Crypto-Meister":
    "You have an above-average eye for good apps and security.",
  "badges.description.s91-Organisationstalent":
    "You can easily coordinate appointments!",

  "badges.s91-Spam Fighter": "Spam Fighter",
  "badges.description.s91-Spam Fighter":
    "You have an above-average eye for spam emails and fake sites!",

  "badges.s91-Digital Zen-Master": "Digital Zen Master",
  "badges.description.s91-Digital Zen-Master":
    "Your personal well-being always remains in balance even in everyday digital life. Very good!",

  "badges.s91-Cloud Specialist": "Cloud Specialist",
  "badges.description.s91-Cloud Specialist":
    "You have an above-average eye for cloud providers and security.",

  /** S93E01 */
  "badges.perfect-coffee-gameplay": "Perfect caffeine level",
  "badges.description.perfect-coffee-gameplay":
    "All answers perfectly matched the situation!",

  /** S93E02 */
  "badges.financeguru": "Finance guru",
  "badges.description.financeguru": "All financing established!",

  /** S93E04 */
  "badges.service-hero": "Manage it all",
  "badges.description.service-hero":
    "You uncovered a managed services opportunity!",
  "badges.solution-hero": "Perfect solutioneering",
  "badges.description.solution-hero": "You understood the customer perfectly!",
};

const appFooter = {
  "appfooter.questinfo.title": "Current Task(s)",
};

const quizTaxi = {
  "quiz-taxi.answer.title": "The answer is...",
  "quiz-taxi.answer.correct": "CORRECT!",
  "quiz-taxi.answer.incorrect": "NOT CORRECT",
  "quiz-taxi.answer.incorrect.more-info": "More on this?",
  "quiz-taxi.answer.next": "Next question",
  "quiz-taxi.joker.call-home.title": "Contacts",
  "quiz-taxi.finish": "Finish quiz",
};

const mailAutomation = {
  "mail-automation.rotate-phone.title": "Rotate your phone",
  "mail-automation.rotate-phone.text":
    "To fully use the application, please rotate the phone horizontally.",

  "mail-automation.and": "and...",
  "mail-automation.save-rule": "Save rule",

  "mail-automation.folders.inbox": "Inbox",
  "mail-automation.folders.do": "Do",
  "mail-automation.folders.delegate": "Delegate",
  "mail-automation.folders.later": "Later",
  "mail-automation.folders.veryImportant": "Very important",
  "mail-automation.folders.bigFiles": "Big Files",

  "mail-automation.condition": "Add a condition",
  "mail-automation.select-condition": "Select condition",
  "mail-automation.add-another-condition": "Add another condition",
  "mail-automation.keywords": "Keywords",
  "mail-automation.action": "Add an action",
  "mail-automation.select-action": "Select action",
  "mail-automation.add-another-action": "Add another action",
  "mail-automation.select-folder": "Select a folder",

  "mail-automation.condition.people": "People",
  "mail-automation.condition.my-name-is": "My name is",
  "mail-automation.condition.subject": "Subject",
  "mail-automation.attachments": "Attachments",

  "mail-automation.condition.people.from": "From",
  "mail-automation.condition.people.except-from": "Except from",
  "mail-automation.condition.people.to": "To",
  "mail-automation.condition.people.except-to": "Except to",

  "mail-automation.condition.my-name-is.in-to-line": "I'm on the To line",
  "mail-automation.condition.my-name-is.in-cc-line": "I'm on the Cc line",
  "mail-automation.condition.my-name-is.in-cc-or-to-line":
    "I'm on the To or Cc line",

  "mail-automation.condition.subject.subject-includes": "Subject includes",
  "mail-automation.condition.subject.subject-or-body-includes":
    "Subject or body includes",

  "mail-automation.condition.keywords.body-includes": "Message body includes",
  "mail-automation.condition.keywords.address-includes":
    "Sender address includes",
  "mail-automation.condition.keywords.header-includes":
    "Message header includes",

  "mail-automation.condition.attachments.size-atleast": "Size at least",

  "mail-automation.action.organize": "Organize",
  "mail-automation.action.mark-message": "Mark message",
  "mail-automation.action.route": "Route",

  "mail-automation.action.organize.move-to": "Move to",
  "mail-automation.action.organize.copy-to": "Copy to",
  "mail-automation.action.organize.delete": "Delete",

  "mail-automation.action.mark-message.mark-as-read": "Mark as read",
  "mail-automation.action.mark-message.mark-with-importance":
    "Mark with importance",

  "mail-automation.action.route.forward-to": "Forward to",
  "mail-automation.action.route.redirect-to": "Redirect to",
  "mail-automation.action.route.send-automatic-reply": "Send automatic reply",

  "mail-automation.action.add-value": "Add a value",
  "mail-automation.action.select-folder": "Select folder",

  "mail-automation.instructions.wait":
    "Please wait for instructions from York...",
};

const kanbanApp = {
  "kanban.app.column.name.ideas": "Ideas",
  "kanban.app.column.name.todo": "To Do",
  "kanban.app.column.name.done": "Done",
  "kanban.app.board.members": "Board Members",
  "kanban.app.board.labels": "Labels",
  "kanban.app.board.save": "Save",
  "kanban.app.board.assign.members": "Assign Members",
  "kanban.app.board.assign.labels": "Edit Labels",
  "kanban.app.board.assign.position": "Move Card",
  "kanban.app.board.card.title.placeholder": "Enter a title for this card...",
  "kanban.app.board.card.dropdown.label": "Move Card To Column:",
  "kanban.app.board.card.add": "Add a card",
  "kanban.app.board.card.add.confirm": "Add card",
  "kanban.app.board.label.veryHigh": "Very High",
  "kanban.app.board.label.high": "High",
  "kanban.app.board.label.medium": "Medium",
  "kanban.app.board.label.low": "Low",
  "kanban.app.board.label.veryLow": "Very Low",
  "kanban.app.instruction": "Wait for the instructions of your colleagues",
};

const signals = {
  "signals.1.what":
    "Management have signed up, but have not played an episode.",
  "signals.1.why": "Team leaders and managers can support their teams.",
  "signals.1.how":
    "Encourage managers and team leaders to complete some episodes.",
  "signals.2.what": "There are teams that lack participants.",
  "signals.2.why":
    "To be able to analyse results on team level, a minimum number of participants per team is required.",
  "signals.2.how":
    "Involve the team leads and join small teams together in talent::digital.",
  "signals.3.what": "There are functions that lack participants.",
  "signals.3.why":
    "To be able to analyse results on function level, a minimum number of participants per function is required.",
  "signals.3.how":
    "Encourage the participants and join small functions together in talent::digital.",
  "signals.4.what":
    "More than 16% of the participants have not set their team.",
  "signals.4.why":
    "To be able to analyse results on team level, a minimum number of participants per team is required.",
  "signals.4.how":
    "Encourage the participants and explain the data protection measures.",
  "signals.5.what":
    "More than 16% of the participants have not set their function.",
  "signals.5.why":
    "To be able to analyse results on function level, a minimum number of participants per function is required.",
  "signals.5.how":
    "Encourage the participants and explain the data protection measures.",
  "signals.6.what": "Increase the understanding of secure passwords.",
  "signals.6.why":
    "Secure authentication reduces the risk of disruptions and loss of data from breaking into IT systems.",
  "signals.6.how":
    "talent::digital shows suitable material to participants. You only need to act if the situation does not improve.",
  "signals.7.what": "Improve your defences against spam and phishing.",
  "signals.7.why":
    "Reduce the risk of financial losses and data breaches due to phishing.",
  "signals.7.how":
    "talent::digital shows suitable material to participants. You only need to act if the situation does not improve.",
  "signals.8.what":
    "There are few active participants in management functions.",
  "signals.8.why":
    "Managers support their team members in integrating continuous education into their routine.",
  "signals.8.how": "Include your management functions.",
};

const other = {
  "button.sales": "Quick-Start",
};

const documentEditor = {
  "document-editor.title": "Editor",
  "document-editor.share": "Share",
  "document-editor.share-dialog.title": "Get a link",
  "document-editor.share-dialog.copy-link": "Copy Link",
  "document-editor.share-dialog.link-copied": "Link Copied",
  "document-editor.share-dialog.button-text": "Done",

  "document-editor.todoList": "To-do list",
  "document-editor.check": "Check document",
  "document-editor.submit": "Submit document",
  "document-editor.no-submit":
    "Please wait for the player who sent you the invitation to this document to submit it.",
  "document-editor.try-again": "Try Again",
  "document-editor.submit-with-mistakes": "Submit with Mistakes",
  "document-editor.correct":
    "Great! All the information seems correct. I'll go ahead and secure your patent for you now!",
  "document-editor.mistakes":
    "Oh, it seems some of the information in the document is incorrect. You can still update the document and I'll check it again...",

  "document-editor.message0":
    "Please replace all the ?s in the document with the correct information.",

  "document-editor.message1": "That's a good start. Keep replacing those ?s.",

  "document-editor.message2": "Good, you're almost half way done.",

  "document-editor.message3": "Nice, almost there, only a few more to go.",

  "document-editor.message4": "Almost there, keep going!.",

  "document-editor.message5":
    "Great! Now you can submit the document and I'll check if the information is correct.",

  "document-editor.connected": "Connected",
  "document-editor.disconnected": "Disconnected",

  "document-editor.reload-button": "Reload document",
  "document-editor.reload.text":
    "This will remove all changes made to the document.",
  "document-editor.reload.agree": "Agree",
  "document-editor.reload.disagree": "Disagree",
};

const jciApps = {
  "jciApps.noResults": "No results",
  "jciApps.customers": "Customers",
  "jciApps.solutions": "Info material",
};

const coffeeGameApp = {
  "coffeeGameApp.noScore": "No caffeine score yet",
};

const createConversationDialog = {
  "create.conversation.dialog.your.score": "Your caffeine score is {score}.",
  "create.conversation.dialog.no.score":
    "Great, you've reached the end of the dialog. Well done!",
};

const trainingRecommendations = {
  "training.recommendations.noTraining":
    "Currently, we don't have training recommendations for you. Play more episodes to see suggestions here.",
};

const botChat = {
  "bot-chat.write-a-message": "Write a message...",
  "bot-chat.no-messages": "No messages",
};

const errorHandling = {
  missingPlayersDecisionErrorCatch: "[Player's decision]",
};

const messages = {
  ...animation,
  ...appStore,
  ...calendar,
  ...chat,
  ...decisions,
  ...gameControl,
  ...header,
  ...globalMessages,
  ...mailbox,
  ...news,
  ...report,
  ...root,
  ...search,
  ...settings,
  ...socialNetwork,
  ...tour,
  ...onboardingBuyer,
  ...videoCall,
  ...cloudapparticles,
  ...cloudapp,
  ...travelBot,
  ...alerts,
  ...storageApp,
  ...programmingGame,
  ...reboot,
  ...digidoc,
  ...goblinNews,
  ...levelEnd,
  ...notification,
  ...itttapp,
  ...surfpatrol,
  ...dashboard,
  ...buttons,
  ...sfOutro,
  ...SFTour,
  ...sf,
  ...quizTime,
  ...tinaTweets,
  ...badges,
  ...appFooter,
  ...quizTaxi,
  ...mailAutomation,
  ...digitalZen,
  ...boarding,
  ...certificates,
  ...seasonEnd,
  ...other,
  ...chatBot,
  ...meetingsTool,
  ...meetingsToolBrowser,
  ...kanbanApp,
  ...gezwitscherApp,
  ...signals,
  ...documentEditor,
  ...rewinder,
  ...competences,
  ...jciApps,
  ...coffeeGameApp,
  ...createConversationDialog,
  ...trainingRecommendations,
  ...supportedLanguages,
  ...botChat,
  ...errorHandling,
};

export default messages;

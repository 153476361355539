import { Message } from "../../message-hub/interfaces";
import { Challenge } from "./interfaces/challenge";
import { MailFolders } from "./interfaces/mail-folders";
import { Rules } from "./interfaces/rules";

export const MAIL_AUTOMATION_TOOL_START = "mail.automation.tool.start";
export const MAIL_AUTOMATION_TOOL_SET_CHALLENGE = "matool.challenge.set";
export const MAIL_AUTOMATION_TOOL_SAVE_RULE = "mail.automation.tool.save.rule";
export const MAIL_AUTOMATION_TOOL_SET_FOLDERS = "matool.folders.set";

export type mailAutomationToolStart = Message<
  typeof MAIL_AUTOMATION_TOOL_START,
  undefined
>;

export type mailAutomationToolSetChallenge = Message<
  typeof MAIL_AUTOMATION_TOOL_SET_CHALLENGE,
  Challenge
>;

export type mailAutomationToolSaveRule = Message<
  typeof MAIL_AUTOMATION_TOOL_SAVE_RULE,
  Rules
>;

export type mailAutomationToolSetFolders = Message<
  typeof MAIL_AUTOMATION_TOOL_SET_FOLDERS,
  MailFolders
>;

export const messageTypes = {
  MAIL_AUTOMATION_TOOL_START,
  MAIL_AUTOMATION_TOOL_SET_CHALLENGE,
  MAIL_AUTOMATION_TOOL_SAVE_RULE,
  MAIL_AUTOMATION_TOOL_SET_FOLDERS,
} as const;

export type MailAutomationToolMessages =
  | mailAutomationToolStart
  | mailAutomationToolSaveRule
  | mailAutomationToolSetFolders
  | mailAutomationToolSetChallenge;

export const SURF_PATROL_PAGE_REPORT = "surf.patrol.page.report";

export interface SurfPatrolPageReport {
  type: typeof SURF_PATROL_PAGE_REPORT;
  payload: {
    url: string;
  };
}

export type SurfPatrolMessages = SurfPatrolPageReport;

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DirectMessages } from "..";
import { useActors, useAppState } from "../../applications";
import DecisionApp from "../../decision";
import { DecisionAnchor } from "../../decision/components/decision-anchor";
import { DecisionType } from "../../decision/interfaces/decision-type";
import { State as DecisionState } from "../../decision/interfaces/state";
import { useMessages } from "../hooks";
import { DirectCommunicationMessage } from "../interfaces/direct-communication-message";
import { State } from "../interfaces/state";
import s from "./direct-communication.module.scss";

const scrollToBottom = (open, el) => {
  if (open && el) {
    el.scrollTop = el.scrollHeight;
  }
};

let observer: MutationObserver;

const DirectCommunicationPanel: React.FC<{ permanent?: boolean }> = (props) => {
  const { lastMessage, mode, endText, open, autostart, actor } =
    useAppState<State>(DirectMessages.instance);
  const { locked: decisionLocked } = useAppState<DecisionState>(
    DecisionApp.instance
  );
  const messages = useMessages();
  const messagesRef = useRef<HTMLDivElement>(null);
  const action: "end" | "next" =
    mode !== "active" || (!lastMessage?.next && !lastMessage?.nextDialog)
      ? "end"
      : "next";
  const actorProps = lastMessage || { actor: actor || "", mood: "happy" };
  const showEndButton = action === "end" && !props.permanent;
  const visible = (props.permanent || open) && !!actorProps.actor;
  const anchorRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(`var(--actor-height)`);

  useEffect(() => {
    autostart && DirectMessages.instance.open();
  }, [autostart]);

  useEffect(
    () => scrollToBottom(open, messagesRef.current),
    [messages, open, height]
  );

  useEffect(() => {
    observer = new MutationObserver(() => {
      setHeight(
        parseInt(anchorRef.current?.dataset.height || "0") > 0
          ? `calc(var(--actor-height) - ${anchorRef.current?.dataset.height}px)`
          : `var(--actor-height)`
      );
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (anchorRef.current) {
      if (open) {
        observer.observe(anchorRef.current, {
          attributes: true,
        });
      } else {
        observer.disconnect();
      }
    } else {
      setHeight("var(--actor-height)");
    }
  }, [open]);

  if (!visible) return null;

  return (
    <div
      className={`${s.root} ${
        props.permanent ? s.reduced : ""
      } animate__animated animate__slideInRight animate__faster`}
      data-e2e-direct-communication-panel
    >
      <div className={s.messages} ref={messagesRef}>
        {messages.map((m: DirectCommunicationMessage, idx: number) => (
          <MessageBubble
            key={idx}
            message={m.message}
            actor={m.actor}
            links={m.links || []}
          />
        ))}
      </div>
      <ActorImag {...actorProps} />
      <div
        className={s.action}
        style={{
          height,
        }}
      >
        {!decisionLocked && (
          <>
            {action === "next" && (
              <button
                data-e2e-next-msg
                className={`${s.next} animate__animated animate__pulse animate__infinite`}
                onClick={
                  lastMessage?.nextDialog
                    ? DirectMessages.instance.takeNextDialog
                    : DirectMessages.instance.takeNext
                }
              >
                <ArrowDownwardIcon />
              </button>
            )}

            {showEndButton && !lastMessage?.noEnd && (
              <Button
                id="endButton"
                className={`${s.button} ${props.permanent ? s.reduced : ""}`}
                onClick={DirectMessages.instance.finish}
              >
                {endText || <FormattedMessage id="global.ok" />}
              </Button>
            )}
            <div style={{ height: 60 }} />
          </>
        )}
      </div>
      <DecisionAnchor
        type={DecisionType.DIRECT_COMMUNICATION}
        bottom={60}
        ref={anchorRef}
      />
    </div>
  );
};

const ActorImag = (props: { actor: string; mood?: string }) => {
  const { actor, mood } = props;
  const actors = useActors();
  const [actorImage, setActorImage] = useState("");
  const [playerActorImage, setPlayerActorImage] = useState("");
  const isPlayer = actor === "player";
  const playerOpacity = isPlayer && playerActorImage ? 1 : 0;
  const actorOpacity = isPlayer && actorImage ? 0 : 1;

  useEffect(() => {
    const sprites = actors[actor]?.sprites || {};
    const actorImage = sprites[mood || "happy"] || "";
    const setFn = isPlayer ? setPlayerActorImage : setActorImage;
    setFn(actorImage);
  }, [actor, actors, mood]);

  return (
    <>
      {!!playerActorImage && (
        <img
          src={playerActorImage}
          alt="avatar"
          className={`${s.actor} ${s.player}`}
          style={{ opacity: playerOpacity }}
          onError={() => setPlayerActorImage("")}
        />
      )}
      {!!actorImage && (
        <img
          src={actorImage}
          alt="avatar"
          className={s.actor}
          style={{ opacity: actorOpacity }}
          onError={() => setActorImage("")}
        />
      )}
    </>
  );
};

type MessageProps = {
  actor: string;
  message: string;
  links: { title: string; url: string }[];
};

const MessageBubble = ({ actor, message, links }: MessageProps) => (
  <p
    aria-label="message"
    className={`animate__animated animate__fadeIn ${
      actor === "player" ? s.player : ""
    }`}
  >
    {message}
    {links.map((l) => (
      <a key={l.title} href={l.url} target="_blank" rel="noopener noreferrer">
        {l.title}
      </a>
    ))}
  </p>
);

export default DirectCommunicationPanel;

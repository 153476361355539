import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import CONFIG from "../../../config";
import MessageHub from "../../../message-hub";
import { useDeviceDetect } from "../../hooks";
import { PASS_TIME_FINISH } from "../../messages";
import earth from "./assets/earth.svg";
import path1 from "./assets/path-1.svg";
import path2 from "./assets/path-2.svg";
import star from "./assets/star.svg";
import "./time-passing.css";
import styles from "./time-passing.styles";

type PropsType = {
  duration?: number;
  keepRoute?: boolean;
};

const TimePassing: React.FC<PropsType> = (props: PropsType) => {
  const { duration = 1500, keepRoute = false } = props;
  const [phaseDuration, setPhaseDuration] = useState<number>();
  const maxTicks = 3;
  const classes = styles();
  const [phase, setPhase] = useState<number>(1);
  const [counter, setCounter] = useState<number>(0);
  const device = useDeviceDetect();
  const history = useHistory();

  useEffect(() => {
    if (!keepRoute) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (!CONFIG.ANIMATIONS_ENABLED) {
      setPhaseDuration(0);
      return;
    }

    if (duration < 3) {
      setPhaseDuration(1000);
    } else {
      setPhaseDuration((duration / 3) * 1000);
    }
  }, [duration, CONFIG.ANIMATIONS_ENABLED]);

  useEffect(() => {
    if (maxTicks > 0) {
      setTimeout(() => {
        setCounter((counter) => counter + 1);
        setPhase((phase) => (phase + 1) % 2);
      }, phaseDuration);
    }
  }, [phase, maxTicks]);

  const animation = {
    animation: CONFIG.ANIMATIONS_ENABLED
      ? `imageAnimation ${phaseDuration}ms cubic-bezier(.23,.57,.69,.39) forwards`
      : "",
  };

  const dayToNightAnimation = {
    animation: CONFIG.ANIMATIONS_ENABLED
      ? `dayToNight ${phaseDuration}ms cubic-bezier(.23,.57,.69,.39) forwards`
      : "",
  };

  useEffect(() => {
    if (counter >= maxTicks) {
      MessageHub.send({
        type: PASS_TIME_FINISH,
        payload: null,
      });
    }
  }, [counter, maxTicks]);

  return (
    <>
      {counter < maxTicks && (
        <div className={classes.root}>
          {phase === 0 && (
            <>
              <div className={classes.night} style={dayToNightAnimation}>
                <div
                  className={classes.sun}
                  style={{ width: device.mobile ? "80%" : "40%" }}
                ></div>
              </div>
            </>
          )}
          {phase === 1 && (
            <>
              <div className={classes.day} style={dayToNightAnimation}>
                <div
                  className={classes.moon}
                  style={{ width: device.mobile ? "80%" : "40%" }}
                ></div>
              </div>
              <img
                src={star}
                className={`${classes.star} star-1`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-2`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-3`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-4`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-5`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-6`}
                style={animation}
              />
              <img
                src={star}
                className={`${classes.star} star-7`}
                style={animation}
              />
            </>
          )}
          <div className={classes.content}>
            <div
              className={classes.earthContainer}
              style={{ width: device.mobile ? "100%" : "60%" }}
            >
              <img src={earth} className={classes.earth} />
              {phase === 0 && (
                <>
                  <img
                    src={path1}
                    className={`${classes.cloud} cloud-1`}
                    style={animation}
                  />
                  <img
                    src={path2}
                    className={`${classes.cloud} cloud-2`}
                    style={animation}
                  />
                  <div className={`${classes.cloud} cloud-3`} style={animation}>
                    &nbsp;
                  </div>
                  <div className={`${classes.cloud} cloud-4`} style={animation}>
                    &nbsp;
                  </div>
                  <div className={`${classes.cloud} cloud-5`} style={animation}>
                    &nbsp;
                  </div>
                  <div className={`${classes.cloud} cloud-6`} style={animation}>
                    &nbsp;
                  </div>
                  <div className={`${classes.cloud} cloud-7`} style={animation}>
                    &nbsp;
                  </div>
                  <div className={`${classes.cloud} cloud-8`} style={animation}>
                    &nbsp;
                  </div>
                </>
              )}
            </div>
            <span className={classes.text}>
              <FormattedMessage id="root.timePassing" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TimePassing;

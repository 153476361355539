import { SAVE_KEY } from "../../core/storage";

export function updateForReplays(state) {
  if (state.version < 3) {
    state.version = 3;
    const endedEpisodes = {};
    if (state[SAVE_KEY].endedEpisodes) {
      for (const e of state[SAVE_KEY].endedEpisodes) {
        endedEpisodes[e] = 1;
      }
    }
    state[SAVE_KEY].endedEpisodes = endedEpisodes;
  }
  return state;
}

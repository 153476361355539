import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getLanguage } from "../../../i18n";
import { CompetenceArea } from "../../interfaces";
import { CompetenceAreaTests } from "./episode-report-tests";

type PropsType = {
  testDetails: CompetenceArea[];
  showCheckpointNotice: boolean;
  checkpointThreshold: number;
};

export const Tests = ({
  testDetails,
  showCheckpointNotice,
  checkpointThreshold,
}: PropsType) => {
  const language = getLanguage();

  return (
    <div>
      {showCheckpointNotice && (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          <h2>🚧</h2>
          <h3>
            <FormattedMessage
              id="dashboard.page.home.episode-report.checkpoint-not-met"
              values={{ percentage: checkpointThreshold * 100 }}
            />
          </h3>
        </Box>
      )}

      {testDetails.map((competenceArea, i) => (
        <CompetenceAreaTests
          key={i}
          competenceArea={competenceArea}
          language={language}
        />
      ))}
    </div>
  );
};

import { DropResult } from "react-beautiful-dnd";
import { Actor } from "../../core/interfaces";
import { Message } from "../../message-hub/interfaces";
import { CardType, ColumnType, ManualDropResults, Tag } from "./interfaces";

export const KANBAN_APP_START = "kanban.start";

export const KANBAN_APP_SET_BOARD_DATA = "kanban.set.board.data";

export const KANBAN_APP_DRAG_END = "kanban.drag.end";

export const KANBAN_APP_MOVE_CARD = "kanban.move.card";

export const KANBAN_APP_INVITE_SEND = "kanban.invite.send";

export const KANBAN_APP_ADD_ASSIGNES_TO_CARD = "kanban.add.assignes.to.card";

export const KANBAN_APP_UPDATE_CARD_TAGS = "kanban.update.card.tags";

export const KANBAN_APP_ADD_CARD = "kanban.add.card";

export const KANBAN_APP_START_QUEST = "kanban.add.start.quest";

export type kanbanAppStart = Message<typeof KANBAN_APP_START>;

export type kanbanAppSetBoardData = Message<
  typeof KANBAN_APP_SET_BOARD_DATA,
  { columns: Record<string, ColumnType>; members?: Actor["id"][] }
>;

export type kanbanAppDragEnd = Message<
  typeof KANBAN_APP_DRAG_END,
  { result: DropResult }
>;

export type kanbanMoveCard = Message<
  typeof KANBAN_APP_MOVE_CARD,
  { result: ManualDropResults }
>;

export type kanbanAppInviteSend = Message<
  typeof KANBAN_APP_INVITE_SEND,
  { members: Actor["id"][] }
>;

export type kanbanAppAddAssignesToCard = Message<
  typeof KANBAN_APP_ADD_ASSIGNES_TO_CARD,
  { columnId: string; cardId: CardType["id"]; assignes: Actor["id"][] }
>;

export type kanbanAppUpdateCardTags = Message<
  typeof KANBAN_APP_UPDATE_CARD_TAGS,
  { columnId: string; cardId: CardType["id"]; tags: Tag[] }
>;

export type kanbanAppAddCard = Message<
  typeof KANBAN_APP_ADD_CARD,
  { columnId: string; title: string; cardId: CardType["id"] }
>;

export type kanbanAppStartQuest = Message<typeof KANBAN_APP_START_QUEST>;

export const messageTypes = {
  KANBAN_APP_START,
  KANBAN_APP_SET_BOARD_DATA,
  KANBAN_APP_DRAG_END,
  KANBAN_APP_MOVE_CARD,
  KANBAN_APP_INVITE_SEND,
  KANBAN_APP_ADD_ASSIGNES_TO_CARD,
  KANBAN_APP_UPDATE_CARD_TAGS,
  KANBAN_APP_ADD_CARD,
  KANBAN_APP_START_QUEST,
} as const;

export type KanbanAppMessages =
  | kanbanAppStart
  | kanbanAppSetBoardData
  | kanbanAppDragEnd
  | kanbanMoveCard
  | kanbanAppInviteSend
  | kanbanAppAddAssignesToCard
  | kanbanAppUpdateCardTags
  | kanbanAppAddCard
  | kanbanAppStartQuest;

import { ReplaySubject, Subject } from "rxjs";
import { SCRIPT_REPLAY_DONE, STEP_DONE } from "../game-engine/messages";
import { Message } from "./interfaces";

export const MESSAGES_STORAGE_KEY = "messages";

type tracker = (m: Message) => void;
export class MessageHub {
  private episodeSubject?: Subject<Message>;
  private readonly mainSubject = new Subject<Message>();
  private trackers: tracker[] = [];
  private lastStepId = "";
  private savedMessages: Message[] = [];
  private episodeSavedMessagesByStepId: Record<string, Message[]> = {};
  private saveMessages?: (msg: Message[]) => void;
  subject = this.mainSubject.asObservable();
  episodeId?: string;

  private addMessageInfoAndSave = (originalMsg: Message): Message => {
    let msg = originalMsg;
    if (msg.timestamp === undefined) {
      msg = { ...msg, timestamp: Date.now() };
    }
    if (!msg.episode && this.episodeId) {
      msg.episode = this.episodeId;
      if (this.episodeId.startsWith("_")) msg.save = false;
    }
    if (!msg.stepId) {
      msg.stepId = this.lastStepId;
    }
    if (msg.save) {
      this.savedMessages.push(msg);
      this.onUpdateSavedMessages();
    }
    return msg;
  };

  private onUpdateSavedMessages() {
    if (this.saveMessages) {
      this.saveMessages(this.savedMessages);
    }
  }

  removeSavedMessages(filterFn: (m: Message) => boolean) {
    this.savedMessages = this.savedMessages.filter((m) => !filterFn(m));
    this.onUpdateSavedMessages();
  }
  onSaveMessages(fn: (msg: Message[]) => void) {
    this.saveMessages = fn;
  }

  track(tracker: tracker) {
    this.trackers.push(tracker);
  }

  init(messages: Message[]) {
    this.savedMessages = messages;
    messages.forEach((m) => {
      if (!m.episode) this.mainSubject.next(m);
    });
  }

  send = (originalMsg: Message) => {
    const m = this.addMessageInfoAndSave(originalMsg);
    const subject = this.episodeSubject || this.mainSubject;
    subject.next(m);
    if (m.stepId) this.lastStepId = m.stepId;
    const savedMessages = this.episodeSavedMessagesByStepId;
    if (m.type === STEP_DONE && savedMessages[m.payload]) {
      savedMessages[m.payload].forEach((m) => this.episodeSubject?.next(m));
      delete savedMessages[m.payload];
    }
    if (m.type === SCRIPT_REPLAY_DONE) {
      Object.keys(savedMessages).forEach((k) => {
        console.error(`Missed step id ${k}`);
        savedMessages[k].forEach((m) => this.episodeSubject?.next(m));
        delete savedMessages[m.payload];
      });
    }
  };
  sendEpisode = this.send;

  startEpisode(id: string, replay: boolean) {
    this.completeEpisode();
    if (replay) {
      this.removeSavedMessages((m) => m.episode === id);
    }
    this.episodeId = id;
    const e = (this.episodeSubject = new ReplaySubject());
    const map = (this.episodeSavedMessagesByStepId = {});
    this.savedMessages.forEach((m) => {
      if (m.episode === id || !m.episode) {
        if (m.stepId) {
          const list = (map[m.stepId] = map[m.stepId] ?? []);
          list.push(m);
        } else {
          e.next(m);
        }
      }
    });
    e.subscribe({
      next: (m) => {
        this.trackers.forEach((tracker) => tracker(m));
        this.mainSubject.next(m);
      },
    });
    return e.asObservable();
  }

  completeEpisode() {
    if (this.episodeSubject) this.episodeSubject.complete();
    this.lastStepId = "";
    delete this.episodeId;
    delete this.episodeSubject;
  }
}

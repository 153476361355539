import type { UserInfo } from "../core/interfaces";
import { getLanguage } from "../i18n";

const FRESHCHAT_TOKEN = "667748c6-9d02-4f96-9282-ccb37f19660b";

const getKeycloakUrl = (sub: string) => {
  const domain = window.location.host;
  const tenant = domain.split(".")[0];
  return `https://${domain}/auth/admin/master/console/#/realms/talentdigital-${tenant}/users/${sub}`;
};

export async function initFreshchat(userInfo: UserInfo): Promise<any> {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://wchat.freshchat.com/js/widget.js";
  const p = new Promise((res) => {
    script.onload = () => {
      const config = {
        token: FRESHCHAT_TOKEN,
        host: "https://wchat.freshchat.com",
        externalId: userInfo.sub,
        firstName: userInfo.name,
        email: userInfo.email,
        locale: getLanguage(),
        tags: ["talentdigital"],
      };
      const fw = (window as any).fcWidget;
      let logRocketSession = "";
      const setSession = () => {
        if (logRocketSession) {
          fw.user.setProperties({
            logrocket: logRocketSession,
            keycloak: getKeycloakUrl(userInfo.sub),
          });
          fw.track("logrocket", {
            url: logRocketSession,
          });
          fw.off("widget:opened", setSession);
        }
      };
      fw.on("widget:opened", setSession);
      fw.init(config);
      fw.setFaqTags({
        tags: ["talentdigital", "loggedin"],
        filterType: "article",
      });
      res((session) => (logRocketSession = session));
    };
  });
  document.body.appendChild(script);
  return p;
}

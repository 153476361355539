import { CompetenceLevelText } from "../../core/enums";
import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "2",
  completed: false,
  index: 9,
  title: {
    de: "Zusammenarbeit mit Hindernissen",
    en: "Cooperation with barriers",
  },
  info: {
    de: `Erfolg, Glamour, Wachstum… Das war einmal. Du wirst von einer Organisation beauftragt, die ihre besten Tage hinter sich hat. Doch Veränderung fällt schwer. Gelingt es dir, deine neuen Kolleg:innen für dich zu gewinnen, ein digitales Mindset zu etablieren und den Glanz vergangener Zeiten wieder herzustellen?`,
    en: `Success, glamour, growth… That was once. You are hired by an organization that has its best days behind it. But change is hard. Can you win over your new colleagues, establish a digital mindset and restore the glamour of the past?`,
  },
  topics: [
    {
      competenceAreaId: 2,
      description: {
        de: "Wie Chatbots und andere Dienste die digitale Kommunikation erleichtern.",
        en: "How chatbots and other services facilitate digital communication.",
      },
    },
    {
      competenceAreaId: 2,
      description: {
        de: "Wie du in Echtzeit digital zusammenarbeiten kannst.",
        en: "How you can collaborate digitally in real time.",
      },
    },
  ],
  seasonEndMessage: {
    de: "Chatbots, Kanban und Realtime Kollaboration … In dieser Staffel ist einiges passiert. Und der Abschluss der Geschichte ist nun wirklich eine Überraschung. Nach der letzten Episode dachte ich, alles sei in trockenen Tüchern ☔ Naja, dann gab es doch noch ein paar Wendungen... Wie gewöhnlich kannst du die gesamte Staffel oder einzelne Episoden so oft spielen, wie du willst. Sammle weitere Abzeichen, Punkte und Diamanten oder vertiefe dein Wissen, indem du den News-Bereich erkundest.",
    en: "Chatbots, Kanban and real-time collaboration ... A lot has happened this season. And the conclusion of the story is really a surprise, after the last episode I thought everything was in the bag ☔ Well, always good, such twists ...As usual, you can play the whole season or single episodes as many times as you want. Collect more badges, points and diamonds or deepen your knowledge by exploring the news section.",
  },
  certificates: [
    {
      id: "3",
      name: "Digital Collaboration",
      subcompetences: [68, 69, 75, 76],
      level: CompetenceLevelText.Foundation,
      color: "#12B2E2",
      groupName: "basics_of_collaboration",
    },
  ],
  episodes: [
    {
      id: "s02e01",
      title: {
        de: "Erste Schritte, schwere Schritte.",
        en: "First steps, hard steps.",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Eigentlich willst du dir bloß einen Überblick verschaffen... Bis dir klar wird, dass selbst Kleinigkeiten größte Herausforderungen darstellen. Gelingt es dir, das Chaos zu entwirren?",
        en: "Actually, you just want to get an overview. Until you realize that even the smallest things present the biggest challenges. Will you manage to untangle the chaos?",
      },
      imageUrl: "/episodes/s02-e01/preview.svg",
      url: "/episodes/s02-e01/",
      load: async () => import(/* webpackChunkName: "s02e01" */ `../s02-e01`),
    },
    {
      id: "s02e02",
      title: {
        de: "Fala português?",
        en: "Fala português?",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Ein Kollege ist krank, ein anderer spricht nur Portugiesisch. Bevor alle nur noch Bahnhof verstehen, müssen dringend einige Abläufe geklärt werden. Leider ist davon nicht jeder begeistert...",
        en: "One colleague is sick, another only speaks Portuguese. Before everyone only understands the train station, some processes urgently need to be clarified. Unfortunately, not everyone is enthusiastic about this ...",
      },
      imageUrl: "/episodes/s02-e02/preview.svg",
      url: "/episodes/s02-e02/",
      load: async () => import(/* webpackChunkName: "s02e02" */ `../s02-e02`),
    },
    {
      id: "s02e03",
      title: {
        de: "Gegen- oder miteinander?",
        en: "Against or along with each other?",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Es ist mittlerweile deutlich geworden, dass Zusammenarbeit im Team mehr Erfolg hat als Solo-Aktionen im Angriffmodus. Herr Silva möchte eine einwandfreie Produktvermarktung und es zeigt sich jetzt, ob alle am gleichen Strang ziehen.",
        en: "It has become clear that teamwork is more successful than solo actions. Mr. Silva wants flawless product marketing, and it is now becoming clear whether everyone is pulling in the same direction.",
      },
      imageUrl: "/episodes/s02-e03/preview.svg",
      url: "/episodes/s02-e03/",
      load: async () => import(/* webpackChunkName: "s02e03" */ `../s02-e03`),
    },
    {
      id: "s02e04",
      title: {
        de: "Zusammen oder Arbeit?",
        en: "Team or work?",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Die Lage spitzt sich zu: PearX versucht, dich auszustechen. Jetzt kommt es darauf an: Gelingt es dir, dein Team zusammenzuhalten und den Bubblr gemeinsam zum Erfolg zu führen?",
        en: "The situation is coming to a head: PearX is trying to outdo you. Can you keep your team together and lead the BubblR team to success?",
      },
      imageUrl: "/episodes/s02-e04/preview.svg",
      url: "/episodes/s02-e04/",
      load: async () => import(/* webpackChunkName: "s02e04" */ `../s02-e04`),
    },
    {
      id: "s02e05",
      title: {
        de: "Echte Zusammenarbeit zahlt sich aus!",
        en: "Real collaboration with colleagues pays off!",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Diese Episode ist exklusiv für die Kollaboration mit einem echten Teammitglied deiner Organisation gedacht. Du kannst die Episode betreten, wenn du von einer anderen Person eingeladen wirst.",
        en: "You can play this episode only when receiving an invite from other player",
      },
      hidden: true,
      imageUrl: "/episodes/s02-e04/preview.svg",
      url: "/episodes/s02-e05/",
      load: async () => import(/* webpackChunkName: "s02e04" */ `../s02-e05`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "2" })),
};

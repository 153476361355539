import { ALERTS_ADD_ALERT } from "../../apps/alerts/messages";
import { APPSTORE_APP_INSTALL } from "../../apps/app-store/messages";
import { CALENDAR_ADD_MEETING } from "../../apps/calendar/messages";
import {
  CLOUD_APP_2FACTOR_ACTIVATE_CODE,
  CLOUD_APP_2FACTOR_DEPOSIT_CODE,
  CLOUD_APP_2FACTOR_MOBILENUMBER_SET,
  CLOUD_APP_EMAIL_VERIFIED,
  CLOUD_APP_INSTALL_APPS,
  CLOUD_APP_READ_PRIVACY,
  CLOUD_APP_READ_TERMS,
  CLOUD_APP_SELECT_PROVIDER,
  CLOUD_APP_SIGN_UP,
  CLOUD_APP_VERIFIED,
} from "../../apps/cloud-app/messages";
import { DIGIDOC_USER_SIGN_IN } from "../../apps/digidoc/messages";
import { ZEN_ANSWER, ZEN_DONE } from "../../apps/digital-zen/messages";
import { ITTT_PROCESS_ADD } from "../../apps/ittt-app/messages";
import { MAIL_AUTOMATION_TOOL_SAVE_RULE } from "../../apps/mail-automation-tool/messages";
import { MAIL_ATTACHMENT_OPEN, MAIL_SELECT } from "../../apps/mailbox/messages";
import {
  PROGRAMMING_GAME_ATTEMPT_FAILED,
  PROGRAMMING_GAME_EXIT,
  PROGRAMMING_GAME_RESET,
  PROGRAMMING_GAME_SCENARIO_FINISHED,
  PROGRAMMING_GAME_SHOW_HINTS,
  PROGRAMMING_GAME_START,
} from "../../apps/programming-game/messages";
import {
  QUIZ_TAXI_ANSWER,
  QUIZ_TAXI_FINISH,
  QUIZ_TAXI_HELP,
  QUIZ_TAXI_JOKER_CALL_HOME_ACTOR_SELECTED,
  QUIZ_TAXI_JOKER_CLOSE,
} from "../../apps/quiz-taxi/messages";
import {
  QUIZ_TIME_ANSWER_QUESTION,
  QUIZ_TIME_CONTINUE,
  QUIZ_TIME_SKIP_QUESTION,
} from "../../apps/quiz-time/messages";
import {
  SOCIAL_NETWORK_LOGGED_IN,
  SOCIAL_NETWORK_LOGIN_FAILED,
  SOCIAL_NETWORK_LOGIN_WITH_CODE,
  SOCIAL_NETWORK_SHARE_PROFILE,
  SOCIAL_NETWORK_UPDATE_PROFILE,
} from "../../apps/socialnetwork/messages";
import {
  SF_CHALLENGE_ANSWER,
  SF_CHALLENGE_HELP,
  SF_COMPLETE,
  SF_EXIT,
  SF_ROUND_SKIP,
  SF_ROUND_START,
  SF_ROUND_TIMEOUT,
} from "../../apps/spam-fighter/messages";
import {
  STORAGE_APP_SHARE_PROJECT,
  STORAGE_APP_STORE_FILE,
} from "../../apps/storage-app/messages";
import {
  TRAVEL_BOT_START,
  TRAVEL_BOT_TICKET_COPY,
  TRAVEL_BOT_TICKET_SELECT,
  TRAVEL_BOT_UPDATE_PASSENGER_INFO,
} from "../../apps/travel-bot/messages";
import { DECISION_CHOOSE } from "../../core/decision/messages";

const types = new Set([
  ALERTS_ADD_ALERT,
  APPSTORE_APP_INSTALL,
  CALENDAR_ADD_MEETING,
  CLOUD_APP_READ_TERMS,
  CLOUD_APP_READ_PRIVACY,
  CLOUD_APP_INSTALL_APPS,
  CLOUD_APP_SIGN_UP,
  CLOUD_APP_2FACTOR_MOBILENUMBER_SET,
  CLOUD_APP_2FACTOR_DEPOSIT_CODE,
  CLOUD_APP_2FACTOR_ACTIVATE_CODE,
  CLOUD_APP_EMAIL_VERIFIED,
  CLOUD_APP_VERIFIED,
  CLOUD_APP_SELECT_PROVIDER,
  DIGIDOC_USER_SIGN_IN,
  ZEN_ANSWER,
  ZEN_DONE,
  ITTT_PROCESS_ADD,
  MAIL_AUTOMATION_TOOL_SAVE_RULE,
  STORAGE_APP_STORE_FILE,
  MAIL_ATTACHMENT_OPEN,
  MAIL_SELECT,
  PROGRAMMING_GAME_EXIT,
  PROGRAMMING_GAME_START,
  PROGRAMMING_GAME_SHOW_HINTS,
  PROGRAMMING_GAME_RESET,
  PROGRAMMING_GAME_SCENARIO_FINISHED,
  PROGRAMMING_GAME_ATTEMPT_FAILED,
  QUIZ_TAXI_ANSWER,
  QUIZ_TAXI_FINISH,
  QUIZ_TAXI_HELP,
  QUIZ_TAXI_JOKER_CALL_HOME_ACTOR_SELECTED,
  QUIZ_TAXI_JOKER_CLOSE,
  QUIZ_TIME_SKIP_QUESTION,
  QUIZ_TIME_ANSWER_QUESTION,
  QUIZ_TIME_CONTINUE,
  SOCIAL_NETWORK_SHARE_PROFILE,
  SOCIAL_NETWORK_LOGIN_FAILED,
  SOCIAL_NETWORK_LOGIN_WITH_CODE,
  SOCIAL_NETWORK_UPDATE_PROFILE,
  SOCIAL_NETWORK_LOGGED_IN,
  SF_CHALLENGE_ANSWER,
  SF_CHALLENGE_HELP,
  SF_ROUND_SKIP,
  SF_ROUND_START,
  SF_COMPLETE,
  SF_ROUND_TIMEOUT,
  SF_EXIT,
  STORAGE_APP_SHARE_PROJECT,
  TRAVEL_BOT_START,
  TRAVEL_BOT_TICKET_COPY,
  TRAVEL_BOT_UPDATE_PASSENGER_INFO,
  TRAVEL_BOT_TICKET_SELECT,
  DECISION_CHOOSE,
]);

export default types;

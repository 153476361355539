import Keycloak, { KeycloakTokenParsed } from "keycloak-js";

const win = window as any;

export const keycloak = Keycloak({
  realm: win.REACT_APP_AUTH_REALM,
  url: win.REACT_APP_AUTH_URL,
  clientId: win.REACT_APP_AUTH_CLIENT_ID,
});

if ("serviceWorker" in navigator)
  navigator.serviceWorker.addEventListener("message", updateServiceWorker);

export async function updateServiceWorker() {
  if ("serviceWorker" in navigator && keycloak.token) {
    await navigator.serviceWorker.ready;
    navigator.serviceWorker.controller?.postMessage({ token: keycloak.token });
  }
}

export type KeycloakRole =
  | "talent_admin"
  | "talent_article_author"
  | "talent_company_report";

const getParsedToken = (): KeycloakTokenParsed | undefined => {
  if (!keycloak.tokenParsed) {
    console.error("Parsed token not available, reloading");
    window.location.reload();
    return;
  }
  return keycloak.tokenParsed;
};

export const userHasRole = (role: KeycloakRole): boolean =>
  getParsedToken()?.realm_access?.roles.includes(role) || false;

export const getUser = () => getParsedToken();

export const getUserFullname = (): string | undefined => getParsedToken()?.name;

export const getUserEmail = (): string | undefined => getParsedToken()?.email;

keycloak.onAuthSuccess = async () => {
  if (keycloak.token) {
    await updateServiceWorker();
  }
};

keycloak.onTokenExpired = async () => {
  await keycloak.updateToken(5);
  if (keycloak.token) {
    await updateServiceWorker();
  }
};

import { Avatar, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  imageContainer: {
    width: "100%",
    gap: 25,

    [theme.breakpoints.down("lg")]: {
      gap: 0,
    },
  },
  imageSelection: {
    display: "flex",
    justifyContent: "center",
    "& div": {
      opacity: 0.4,
      "&.selected": {
        opacity: 1,
      },
      "&:hover": {
        opacity: 1,
      },
    },
  },
  avatar: {
    width: 88,
    height: "auto",
    maxWidth: "95%",
    marginBottom: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

type PropsType = {
  id: string;
  images: string[];
  value?: string;
  onSelect: (src: string, color?: string) => void;
  className?: string;
};

const colors = [
  "#83d6b6",
  "#9095d1",
  "#e8c35b",
  "#c6854f",
  "#d3e553",
  "#87d1e8",
  "#83d6b6",
  "#9095d1",
  "#e8c35b",
  "#c6854f",
  "#d3e553",
  "#87d1e8",
];

const ImageSelection: React.FC<PropsType> = (props: PropsType) => {
  const { id, images, value, onSelect } = props;
  const classes = styles();
  const selected = (val) => val === value;
  const selClass = (val) => (selected(val) ? "selected" : "");

  return (
    <Grid container className={classes.imageContainer}>
      {images.map((src, ix) => (
        <Grid item key={ix} xs={2} className={classes.imageSelection}>
          {src.includes("avatar") ? (
            <Avatar
              id={`${id}${ix}`}
              role="option"
              onClick={() => onSelect(src, colors[ix])}
              aria-selected={selected(src)}
              className={`${classes.avatar} ${selClass(src)}`}
              alt={`${id}${ix}`}
              src={src}
              style={{
                backgroundColor: colors[ix],
              }}
            />
          ) : (
            <Avatar
              id={`${id}${ix}`}
              role="option"
              onClick={() => onSelect(src)}
              aria-selected={selected(src)}
              className={`${classes.avatar} ${selClass(src)}`}
              src={src}
              alt={`${id}${ix}`}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageSelection;

import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "91",
  completed: false,
  index: 5,
  title: "Von Hackern und Helden",
  info: `Entdecke die Welt der digitalen Sicherheit und Effizienz. Lerne, wie innovative Apps und Technologien deinen Arbeitsalltag erleichtern und mach dich bereit für eine Reise durch die spannende Welt des digitalen Schutzes!`,
  topics: [
    {
      competenceAreaId: 4,
      description: {
        de: "Wie digitale Tools und Technologien dabei helfen, deinen Arbeitsalltag zu erleichtern.",
        en: "How digital tools and technologies help to make your day-to-day work easier.",
      },
    },
    {
      competenceAreaId: 4,
      description: {
        de: "Wie Zusammenarbeit im Team sicherer und besser gemacht werden kann.",
        en: "How teamwork can be made safer and better.",
      },
    },
    {
      competenceAreaId: 4,
      description: {
        de: "Wie du dich und Daten, mit denen du arbeitest, besser schützt.",
        en: "How to better protect yourself and the data you work with.",
      },
    },
  ],
  certificates: [],
  seasonEndMessage: {
    de: "Von Spam bis Digital Detox. In dieser Staffel hast du einiges über den digitalen Schutz und die Sicherheit gelernt. Nun hast du dir aber wirklich eine Pause verdient. Wie gewöhnlich kannst du die gesamte Staffel oder einzelne Episoden so oft spielen, wie du willst. Sammle weitere Abzeichen, Punkte und Diamanten oder vertiefe dein Wissen, indem du den News-Bereich erkundest.",
    en: "From spam to digital detox. In this season, you've learned a lot about digital protection and security. But now you've really earned a break. As usual, you can play the entire season or individual episodes as many times as you like. Collect more badges, points and diamonds or deepen your knowledge by exploring the news section.",
  },
  episodes: [
    {
      id: "s91e01",
      title: "Zwischen Digitalität und Detox",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "In deinem Team geht es drunter und drüber: Hacking Angriffe, Viren und eine etwas zu große Liebe für Pausen. Gelingt es dir, dich in dem Flohhaufen zurechtzufinden?",
      imageUrl: "/episodes/s02-e01/preview.svg",
      url: "/episodes/s91-e01/",
      load: async () => import(/* webpackChunkName: "s91-e01" */ `../s91-e01`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "91" })),
};

import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={67}
      height={64}
      viewBox="0 0 67 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.969 1v2M61.66 9.7l1.571-1.4M53.6 4.8l.732-1.9M34.337 4.8l-.733-1.9M35.594 50s-.838-4.1-2.513-6.2C29.312 39 25.125 33.1 25.125 27c0-10 8.375-18 18.844-18 10.468 0 18.843 8 18.843 18l3.141 9H60.72v6c0 2.2-1.885 4-4.188 4h-1.78c-1.465 0-2.721.9-3.035 2.3-.314 1.1-.42 2.3-.42 3.5V54H35.595"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.828 44c0-6 0-12-4.187-16h14.656c-4.188 4-4.188 10-4.188 16"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.594 58c0 2.8 2.303 5 5.234 5h5.234c2.932 0 5.235-2.2 5.235-5M29.313 9h-3.141c-1.152 0-2.094.9-2.094 2v.8c0 .8-.523 1.5-1.256 1.8l-5.34 2.1c-.732.3-1.674.1-2.302-.4l-.628-.6a2.09 2.09 0 00-2.932 0L7.12 19c-.838.8-.838 2 0 2.8l1.151 1.1c.314.3.42.7.21 1.1l-2.513 5.8c-.314.7-1.047 1.2-1.884 1.2H3.14c-1.152 0-2.094.9-2.094 2v6c0 1.1.942 2 2.094 2h.837c.838 0 1.57.5 1.884 1.2l2.199 5.1c.419.8.314 1.7-.314 2.2l-.628.6c-.838.8-.838 2 0 2.8l4.397 4.2c.837.8 2.093.8 2.93 0l.629-.6c.628-.6 1.466-.7 2.303-.4l5.34 2.1c.732.3 1.255 1 1.255 1.8v1c0 1.1.943 2 2.094 2h4.188"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M18.32 28c-1.675 2.3-2.617 5-2.617 8 0 7.7 6.595 14 14.656 14h5.235"
        stroke="#00B2E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;

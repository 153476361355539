import { FunctionComponent, SVGProps } from "react";
import { Message as MessageHubMessage } from "../../message-hub/interfaces";

export const CLICK_REVEAL_SET_IMAGE = "clickReveal.set.image";
export const CLICK_REVEAL_CLOSE = "clickReveal.close";

export type clickRevealSetImage = MessageHubMessage<
  typeof CLICK_REVEAL_SET_IMAGE,
  {
    SvgElement: FunctionComponent<SVGProps<SVGSVGElement>>;
  }
>;

export type clickRevealMessage = clickRevealSetImage;

import { formatISO, isSameDay, parseISO } from "date-fns";
import { Observable, skipWhile } from "rxjs";
import { ZEN_ANSWER } from "../apps/digital-zen/messages";
import { QUIZ_TAXI_ANSWER } from "../apps/quiz-taxi/messages";
import { SF_CHALLENGE_ANSWER } from "../apps/spam-fighter/messages";
import { ofTypes } from "../core/applications";
import {
  GAME_ENGAGEMENT_POINTS_ADD,
  GAME_ENGAGEMENT_POINTS_INCREMENT,
  GAME_LEVEL_PROGRESS,
} from "../core/messages";
import {
  NewsMessage,
  NEWS_ARTICLE_LINK_OPENED,
  NEWS_ARTICLE_OPENED,
  NEWS_ARTICLE_RATED,
} from "../core/news/messages";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { SCRIPT_REPLAY_DONE } from "./messages";

interface EngagementsPointsStorage {
  points: number;
  date?: string;
  openedArticles: string[];
  ratedArticles: string[];
  linksOpened: Record<string, string[]>;
}

export const ENGAGEMENT_STORAGE_KEY = "ENGAGEMENT_POINTS";
export class EngagementPointsEngine {
  private data: EngagementsPointsStorage = {
    points: 0,
    openedArticles: [],
    ratedArticles: [],
    linksOpened: {},
  };

  /**
   * Prevent from getting one point for each start of episode for at least same session
   */
  private lastEpisodeProgressInSession: Record<string, number> = {};

  constructor(
    private get: () => EngagementsPointsStorage,
    private save: (a: EngagementsPointsStorage) => void
  ) {
    this.data = this.getAll();
    this.getPointsForLogin();

    MessageHub.subject
      .pipe(
        ofTypes<NewsMessage>(
          GAME_ENGAGEMENT_POINTS_INCREMENT,
          NEWS_ARTICLE_LINK_OPENED,
          NEWS_ARTICLE_OPENED,
          NEWS_ARTICLE_RATED,
          SCRIPT_REPLAY_DONE,
          GAME_LEVEL_PROGRESS
        )
      )
      .subscribe(this.handleMessages);

    this.notifyChanges();
  }

  setEpisode(episode$: Observable<Message>) {
    episode$
      .pipe(
        skipWhile((m) => m.type !== SCRIPT_REPLAY_DONE),
        ofTypes(QUIZ_TAXI_ANSWER, SF_CHALLENGE_ANSWER, ZEN_ANSWER)
      )
      .subscribe(() => this.addPoints(1));
  }

  private addPoints = (value: number) => {
    this.data.points = this.data.points + value;

    this.save(this.data);
    this.notifyChanges();
  };

  private notifyChanges() {
    MessageHub.send({
      type: GAME_ENGAGEMENT_POINTS_ADD,
      payload: {
        points: this.data.points,
      },
    });
  }

  getAll = (): EngagementsPointsStorage => {
    const storage = this.get() as any;

    return storage
      ? {
          points: storage.points || 0,
          openedArticles: storage.openedArticles || [],
          ratedArticles: storage.ratedArticles || [],
          date: storage.date || null,
          linksOpened: storage.linksOpened || {},
        }
      : { points: 0, openedArticles: [], ratedArticles: [], linksOpened: {} };
  };

  private getPointsForLogin = () => {
    const lastDate = this.data.date;
    const currentDate = new Date();

    if (!lastDate || !isSameDay(parseISO(lastDate), currentDate)) {
      this.data.date = formatISO(currentDate);
      this.addPoints(20);
    }
  };

  private handleMessages = (msg: Message) => {
    switch (msg.type) {
      case GAME_ENGAGEMENT_POINTS_INCREMENT:
        this.addPoints(msg.payload.points);
        break;
      case NEWS_ARTICLE_OPENED: {
        const { id } = msg.payload;

        if (!this.data.openedArticles.includes(id)) {
          this.data.openedArticles.push(id);
          this.addPoints(20);
        }

        break;
      }
      case NEWS_ARTICLE_RATED: {
        const { id } = msg.payload;

        if (!this.data.ratedArticles.includes(id)) {
          this.data.ratedArticles.push(id);
          this.addPoints(5);
        }

        break;
      }
      case NEWS_ARTICLE_LINK_OPENED:
        const { articleId, link } = msg.payload;

        if (!this.data.linksOpened[articleId]) {
          this.data.linksOpened[articleId] = [link];
          this.addPoints(5);
        } else if (!this.data.linksOpened[articleId].includes(link)) {
          this.data.linksOpened[articleId] = [
            ...this.data.linksOpened[articleId],
            link,
          ];
          this.addPoints(5);
        }
        break;
      case GAME_LEVEL_PROGRESS:
        if (
          this.lastEpisodeProgressInSession[msg.episode as string] !==
          msg.payload.progress
        ) {
          this.addPoints(1);
        }
        this.lastEpisodeProgressInSession[msg.episode as string] =
          msg.payload.progress;
        break;
      case SCRIPT_REPLAY_DONE:
        setTimeout(() => this.notifyChanges());
        break;
      default:
        break;
    }
  };
}

import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "92",
  completed: false,
  index: 6,
  title: "Test title",
  info: `Lorem ipsum test`,
  topics: [
    {
      competenceAreaId: 4,
      description: {
        de: "DE Test competence 1",
        en: "EN Test competence 1",
      },
    },
  ],
  certificates: [],
  seasonEndMessage: "",
  episodes: [
    /*
      TODO: Currently starts as episode 1 but should be 0. For more information see:
      https://github.com/talent-digital/talentdigital/pull/1560#discussion_r911050384
    */
    {
      id: "s92e00",
      title: "Test title (Speed check app)",
      maturity: "PUBLIC" as EpisodeMaturity,
      description: "Test description",
      imageUrl: "",
      url: "/episodes/s92-e00/",
      load: async () => import(/* webpackChunkName: "s92-e00" */ `../s92-e00`),
    },
    {
      id: "s92e01",
      title: "Test title lorem",
      maturity: "PUBLIC" as EpisodeMaturity,
      description: "Test description",
      imageUrl: "",
      url: "/episodes/s92-e01/",
      load: async () => import(/* webpackChunkName: "s92-e01" */ `../s92-e01`),
    },
    {
      id: "s92e02",
      title: "Test title lorem",
      maturity: "PUBLIC" as EpisodeMaturity,
      description: "Test description",
      imageUrl: "",
      url: "/episodes/s92-e02/",
      load: async () => import(/* webpackChunkName: "s92-e02" */ `../s92-e02`),
    },
    {
      id: "s92e03",
      title: "Test title lorem",
      maturity: "PUBLIC" as EpisodeMaturity,
      description: "Test description",
      imageUrl: "",
      url: "/episodes/s92-e03/",
      load: async () => import(/* webpackChunkName: "s92-e03" */ `../s92-e03`),
    },
    {
      id: "s92e04",
      title: "Test title lorem",
      maturity: "PUBLIC" as EpisodeMaturity,
      description: "Test description",
      imageUrl: "",
      url: "/episodes/s92-e04/",
      load: async () => import(/* webpackChunkName: "s92-e04" */ `../s92-e04`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "92" })),
};

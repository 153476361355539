import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CONFIG from "../../config";
import { useRootState } from "../hooks";
import { AnimationProps } from "./interfaces";

export interface StylesProps {
  zIndex: number;
}

const styles = makeStyles((theme: Theme) => ({
  animationContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: (props: StylesProps) =>
      props.zIndex ? props.zIndex : theme.custom.zIndex.animation,
  },
}));

const Animation: React.FC<AnimationProps> = (props: AnimationProps) => {
  const classes = styles({ zIndex: props.zIndex });
  const { animation } = props;
  let { duration } = props;
  const { onStopAnimation } = useRootState();

  if (!CONFIG.ANIMATIONS_ENABLED) {
    duration = 1000;
  }

  setTimeout(() => {
    onStopAnimation({ run: false });
  }, duration);

  return (
    <>
      {animation && (
        <Grid container className={classes.animationContainer}>
          {animation}
        </Grid>
      )}
    </>
  );
};

export default Animation;

import { Message } from "../../message-hub/interfaces";
import { DirectCommunicationEnd } from "./interfaces/direct-communication-end";
import { DirectCommunicationMessage } from "./interfaces/direct-communication-message";

export const DIRECT_COMMUNICATION_START = "DirectCOmmunication.start";
export const DIRECT_COMMUNICATION_SEND = "DirectCommunication.send";
export const DIRECT_COMMUNICATION_END = "DirectCommunication.end";
export const DIRECT_COMMUNICATION_OPEN = "DirectCommunication.open";
export const DIRECT_COMMUNICATION_CLOSE = "DirectCommunication.close";
export const DIRECT_COMMUNICATION_FINISHED = "DirectCommunication.finished";
export const DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT =
  "DirectCommunication.message.take.next";
export const DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG =
  "DirectCommunication.dialog.take.next";
export const DIRECT_COMMUNICATION_CLEAR = "DirectCommunication.clear";

export type Start = Message<
  typeof DIRECT_COMMUNICATION_START,
  {
    actor?: string;
  }
>;
export type SendMessage = Message<
  typeof DIRECT_COMMUNICATION_SEND,
  DirectCommunicationMessage
>;
export type End = Message<
  typeof DIRECT_COMMUNICATION_END,
  DirectCommunicationEnd
>;
export type Open = Message<typeof DIRECT_COMMUNICATION_OPEN, boolean>;
export type Close = Message<typeof DIRECT_COMMUNICATION_CLOSE, boolean>;
export type Finished = Message<typeof DIRECT_COMMUNICATION_FINISHED>;
export type TakeNext = Message<
  typeof DIRECT_COMMUNICATION_MESSAGE_TAKE_NEXT,
  null
>;
export type TakeNextDialog = Message<
  typeof DIRECT_COMMUNICATION_TAKE_NEXT_DIALOG
>;

export type Clear = Message<typeof DIRECT_COMMUNICATION_CLEAR>;

export type DirectMessageHubMessages =
  | Start
  | SendMessage
  | End
  | Open
  | Close
  | Finished
  | TakeNext
  | TakeNextDialog
  | Clear;

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import arrow from "../../assets/arrow.svg";
import diamond from "../../assets/diamond.svg";
import playCircle from "../../assets/play_circle.svg";
import playCircleFilled from "../../assets/play_circle_filled.svg";

export const useStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    height: "120px !important",
    minHeight: "120px !important",
    maxHeight: "120px !important",
    minWidth: "180px !important",
    maxWidth: "180px !important",
    marginRight: theme.spacing(2),
    position: "relative",

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        minHeight: "90px !important",
        maxHeight: "90px !important",
        minWidth: "90px !important",
        maxWidth: "90px !important",
        overflow: "hidden",
      },

    "@media (min-width: 601px) and (max-width: 920px)": {
      "&": {
        maxWidth: "100% !important",
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  playableImageContainer: {
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: "10px",

    "& img": {
      transition: " 0.5s all ease-in-out",

      "&:hover": {
        transform: "scale(1.3)",
      },
    },

    "&:hover": {
      "& div div": {
        backgroundImage: `url(${playCircleFilled})`,
      },
    },
  },
  playIconWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",

    "& div": {
      height: 50,
      width: 50,
      backgroundImage: `url(${playCircle})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },

    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        "& div": {
          height: 55,
          width: 55,
        },
      },
  },
  firstRow: {
    display: "flex",
    alignItems: "stretch",
    padding: theme.spacing(2),

    "@media (min-width: 601px) and (max-width: 920px)": {
      "&": {
        flexDirection: "column",
      },
    },
  },
  takeAwaysText: {
    display: "flex",
    overflow: "hidden",
    justifyContent: "flex-start",
    "& .takeaways-wrapper": {
      width: "100%",
      "& div": {
        marginBottom: "16px",
      },
    },
    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        display: "flex",
        flexDirection: "column",
        "& div": {
          marginBottom: 30,
          maxWidth: "100%",
        },
      },
    color: "white",
    fontWeight: "bold",
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    "& span": {
      marginLeft: 10,
    },
    "& a": {
      color: "#00B2E3",
      letterSpacing: 0,
      textDecoration: "none",
      marginLeft: 10,
      verticalAlign: "text-top",
    },
    "& li::before": {
      content: `url(${arrow})`,
      width: 16,
      height: 16,
      display: "inline",
      verticalAlign: "middle",
    },
    "& p": {
      display: "inline",
      margin: 0,
    },
    "& li": {
      verticalAlign: "middle",
      marginTop: 15,
      paddingLeft: 30,
      textIndent: -28,
    },
  },
  microlearningList: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "flex-start",
    "& .takeaways-wrapper": {
      width: "100%",
      "& div": {
        marginBottom: "16px",
      },
    },
    "@media (max-width: 600px), (max-height: 600px) and (orientation: landscape)":
      {
        display: "flex",
        "& div": {
          marginBottom: 30,
          maxWidth: "100%",
        },
      },
    color: "white",
    fontWeight: "bold",
    "& > div": {
      marginTop: 30,
      marginBottom: 30,
      "& > ul": {
        listStyle: "none",
        padding: 0,
        margin: 0,
        "& > li::before": {
          marginRight: 16,
          content: `url(${diamond})`,
          width: 16,
          height: 16,
          display: "inline",
          verticalAlign: "middle",
        },
        "& ul": {
          listStyle: "none",
          padding: 0,
          margin: 0,
        },
      },
    },
    "& span": {
      marginLeft: 10,
    },
    "& a": {
      color: "#00B2E3",
      letterSpacing: 0,
      textDecoration: "none",
      marginLeft: 10,
      verticalAlign: "text-top",
    },
    "& p": {
      display: "inline",
      margin: 0,
    },
    "& li": {
      verticalAlign: "middle",
      marginTop: 15,
      paddingLeft: 30,
      textIndent: -28,
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    color: "white",
    fontSize: "medium",
    position: "relative",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    width: "calc(100% - 10px)",

    "& img": {
      width: "20px",
      height: "20px",
      marginLeft: 10,
    },

    "& p": {
      margin: "0 0 0 10px",
    },

    "& .title": {
      fontWeight: 700,
      fontSize: "large",
      marginRight: 30,
    },
  },
  foldedPanel: {
    borderRadiusBottom: 0,
  },
  expandedPanel: {
    boxShadow: "0 5px 5px rgba(0,0,0,0.3)",
    borderRadius: 0,
    background: "#21444b", // Watch out for Mui Card opacity background
  },
  publishButton: {
    position: "absolute",
    right: "-5px",
    top: "-5px",
    color: "#fff",
    borderRadius: "50px",
    border: "2px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    "& .MuiSvgIcon-root": {
      height: "20px",
      width: "20px",
    },
  },
  publishButtonTooltipRow: {
    display: "flex",
    alignItems: "center",
  },
  publishButtonTooltipIcon: {
    color: "#fff",
    borderRadius: "50px",
    border: "2px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    marginRight: "8px",
  },
  tabs: {
    padding: theme.spacing(1, 2, 2),
  },
  checkpointLoader: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    zIndex: 1,
  },
  blockIconWrapper: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
}));

import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { VAR, varMessage } from "../core/messages";
import { Message } from "../message-hub/interfaces";

export class VarStore {
  state = {} as Record<string, any>;
  constructor(messages: Observable<Message>) {
    messages
      .pipe(filter((msg: any) => msg.type === VAR))
      .subscribe(this.onMessage);
  }

  private onMessage = (msg: varMessage) => {
    this.state[msg.payload.id] = msg.payload.value;
  };

  replaceVar = (id: string, property?: string) => {
    return property ? this.state[id][property] : this.state[id];
  };
}

import { Observable } from "rxjs";
import { scan, shareReplay } from "rxjs/operators";
import MessageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { ofTypes } from "../applications";
import {
  appLauncherMessages,
  GAME_CHANGE_MODE,
  GAME_CURRENT_QUEST,
  PULSE_REWIND_ICON,
} from "../messages";
import { State } from "./state";

export class AppFooter {
  static instance: AppFooter;

  initialState: State = {
    gameMode: "play",
    rewinderClicked: false,
  };

  constructor(messages: Observable<Message>) {
    AppFooter.instance = this;
    this.state = messages.pipe(
      ofTypes<appLauncherMessages>(
        GAME_CHANGE_MODE,
        GAME_CURRENT_QUEST,
        PULSE_REWIND_ICON
      ),
      scan(this.reducer, this.initialState),
      shareReplay(1)
    );
    this.state.subscribe();
  }

  state: Observable<State>;

  private reducer = (state: State, msg: appLauncherMessages) => {
    switch (msg.type) {
      case GAME_CHANGE_MODE:
        return { ...state, gameMode: msg.payload.mode };
      case PULSE_REWIND_ICON:
        return {
          ...state,
          rewinderClicked: !msg.payload ? true : state.rewinderClicked,
        };
      default:
        return state;
    }
  };

  startPulseRewindIcon = () => {
    MessageHub.send({
      type: PULSE_REWIND_ICON,
      payload: true,
    });
  };

  stopPulseRewindIcon() {
    MessageHub.send({
      type: PULSE_REWIND_ICON,
      payload: false,
    });
  }
}

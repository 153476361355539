import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type StylesProps = {
  color: string | undefined;
};

const styles = makeStyles((theme: Theme) => ({
  searchBar: {
    background: ({ color }: StylesProps) => color || `rgba(100,100,100,0.2)`,
    height: 64,
    backdropFilter: "blur(30px)",
    display: "flex",
  },
  urlBox: {
    height: 36,
    width: "calc(100% - 65px)",
    backgroundColor: "#626263",
    boxShadow: "0px -1px 0px #FFFFFF24",
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
    justifyContent: "flex-start",
    position: "relative",
    "& > :first-child": {
      position: "absolute",
      left: "1rem",
      top: "11px",
      height: "14px",
    },
    "& svg": {
      fill: theme.palette.common.white,
    },
    "& input": {
      backgroundColor: "transparent",
      color: "white",
      marginLeft: "1rem",
      marginTop: "1px",
      border: 0,
      flex: 1,
      fontSize: "1rem",
      padding: "0 0 0 2rem !important",
    },
    "& [class*=-underline]::after, [class*=-underline]::before": {
      borderBottom: "0 !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  urlName: {
    border: 0,
    backgroundColor: "#626263",
    color: theme.palette.common.white,
    marginLeft: 5,
    cursor: "pointer",

    "&:focus": {
      outline: "none",
    },
  },
  mobile: {
    position: "absolute",
    width: "100%",
    top: 40,
    paddingTop: 20,
    paddingBottom: 20,
    zIndex: 1,
  },
}));

export default styles;

import { Message } from "../../message-hub/interfaces";

export const BREAKING_NEWS_ADD_CONTENT = "breaking-news.content.add";
export const BREAKING_NEWS_USER_ACTION = "breaking-news.user.action";
export const BREAKING_NEWS_PLAY_VIDEO = "breaking-news.play.video";

export type BreakingNewsAddContent = Message<
  typeof BREAKING_NEWS_ADD_CONTENT,
  {
    headline: string;
    content: string;
    tags: string;
    video?: boolean;
  }
>;

export type BreakingNewsUserAction = Message<typeof BREAKING_NEWS_USER_ACTION>;

export type BreakingNewsPlayVideo = Message<typeof BREAKING_NEWS_PLAY_VIDEO>;

export type BreakingNewsAppMessages =
  | BreakingNewsAddContent
  | BreakingNewsUserAction
  | BreakingNewsPlayVideo;

import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "96",
  completed: false,
  index: 1,
  title: "ProtEmbo® Cerebral Protection System V3",
  info: {
    de: `Discover the ProtEmbo® Cerebral Protection System V3 for TAVR procedures, designed to deflect embolic material from cerebrovascular circulation.`,
    en: `Discover the ProtEmbo® Cerebral Protection System V3 for TAVR procedures, designed to deflect embolic material from cerebrovascular circulation.`,
  },
  topics: [
    {
      competenceAreaId: 20,
      description: {
        de: "ProtEmbo System Function",
        en: "ProtEmbo System Function",
      },
    },
    {
      competenceAreaId: 20,
      description: {
        de: "Preparation, implantation and removal procedure",
        en: "Preparation, implantation and removal procedure",
      },
    },
  ],
  certificates: [
    /*
    {
      id: "94",
      name: "Grundlagen des Bürgergelds",
      subcompetences: [78, 105],
      level: CompetenceLevelText.Foundation,
      color: "#08B79C",
      groupName: "buergergeld",
    },
  */
  ],
  seasonEndMessage: "Congratulations!",
  episodes: [
    {
      id: "s96e01",
      testsId: "season96episode1",
      title: "System Function & Procedure Overview",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Victor, Interventional Cardiologist and inventor of ProtEmbo®, introduces the ProtEmbo® Cerebral Protection System, along with its features and procedure overview. This episode will take you 8 minutes to complete.",
      imageUrl: "/episodes/s96-e01/preview.svg",
      url: "/episodes/s96-e01/",
      load: async () => import(/* webpackChunkName: "s96-e01" */ `../s96-e01`),
      skipEndgameFeedback: true,
    },
    {
      id: "s96e02",
      testsId: "season96episode2",
      title: "Quiz System Function & Procedure Overview",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Test your knowledge of the Protembo® system function and the overall procedure. This episode will take you 5 minutes to complete.",
      imageUrl: "/episodes/s96-e02/preview.png",
      url: "/episodes/s96-e02/",
      load: async () => import(/* webpackChunkName: "s96-e02" */ `../s96-e02`),
      skipEndgameFeedback: true,
      checkpointEpisode: true,
    },
    {
      id: "s96e03",
      testsId: "season96episode3",
      title: "Prepare ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Victor, Cardiologist and inventor of ProtEmbo®, and Vicky, Cardiac Surgeon, prepare the ProtEmbo® System V3. This episode will take you 9 minutes to complete.",
      imageUrl: "/episodes/s96-e03/preview.svg",
      url: "/episodes/s96-e03/",
      load: async () => import(/* webpackChunkName: "s96-e03" */ `../s96-e03`),
      skipEndgameFeedback: true,
    },
    {
      id: "s96e04",
      testsId: "season96episode4",
      title: "Quiz Prepare ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Test your knowledge of the preparing the ProtEmbo® System. This episode will take you 5 minutes to complete.",
      imageUrl: "/episodes/s96-e04/preview.png",
      url: "/episodes/s96-e04/",
      load: async () => import(/* webpackChunkName: "s96-e04" */ `../s96-e04`),
      skipEndgameFeedback: true,
      checkpointEpisode: true,
    },
    {
      id: "s96e05",
      testsId: "season96episode5",
      title: "Implant ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Victor, Cardiologist and inventor of ProtEmbo®, and Vicky, Cardiac Surgeon, implant the ProtEmbo® System V3. This episode will take you 13 minutes to complete.",
      imageUrl: "/episodes/s96-e05/preview.png",
      url: "/episodes/s96-e05/",
      load: async () => import(/* webpackChunkName: "s96-e05" */ `../s96-e05`),
      skipEndgameFeedback: true,
    },
    {
      id: "s96e06",
      testsId: "season96episode6",
      title: "Quiz Implant ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Test your knowledge of implanting ProtEmbo® System. This episode will take you 5 minutes to complete.",
      imageUrl: "/episodes/s96-e06/preview.png",
      url: "/episodes/s96-e06/",
      load: async () => import(/* webpackChunkName: "s96-e06" */ `../s96-e06`),
      skipEndgameFeedback: true,
      checkpointEpisode: true,
    },
    {
      id: "s96e07",
      testsId: "season96episode7",
      title: "Remove ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Victor and Vicky remove the ProtEmbo® System V3. This episode will take you 5 minutes to complete.",
      imageUrl: "/episodes/s96-e07/preview.png",
      url: "/episodes/s96-e07/",
      load: async () => import(/* webpackChunkName: "s96-e07" */ `../s96-e07`),
      skipEndgameFeedback: true,
    },
    {
      id: "s96e08",
      testsId: "season96episode8",
      title: "Quiz Remove ProtEmbo® System",
      maturity: "PUBLIC" as EpisodeMaturity,
      description:
        "Test your knowledge of removing ProtEmbo® System. This episode will take you 5 minutes to complete.",
      imageUrl: "/episodes/s96-e08/preview.png",
      url: "/episodes/s96-e08/",
      load: async () => import(/* webpackChunkName: "s96-e08" */ `../s96-e08`),
      skipEndgameFeedback: true,
    },
  ].map((e, index) => ({ ...e, index, seasonId: "96" })),
};

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { keycloak } from "core/keycloak";
import PlayerProfile from "onboarding/player/components/player-profile";
import { putTags, updateDepartment } from "onboarding/player/services";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import MessageHub from "../../../../message-hub";
import PlayerAccount from "../../../../onboarding/player/components/player-account";
import { Settings, Tag } from "../../../../onboarding/player/interfaces";
import { useLanguage, useSettings } from "../../../hooks";
import { ACTOR_CHANGE } from "../../../messages";
import {
  generateCompanyURL,
  generatePlayerEmailAddress,
} from "../../../services/accountSettings";
import { setItem } from "../../../storage";
import { DashboardContext } from "../../dashboard.context";

const StyledPlayerProfileDialog = styled(Dialog)<DialogProps>(() => ({
  "& .MuiPaper-root": {
    background: "#21444B",
  },
}));

const StyledPirvacyPolicyLoader = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  left: 0,
  background: "rgba(0, 0, 0, 0.3)",
  zIndex: 2,
}));

type PlayerProfileModalProps = {
  open: boolean;
  close: () => void;
  onSave: () => void;
};

const PlayerProfileModal = ({
  open,
  close,
  onSave,
}: PlayerProfileModalProps) => {
  const { settings, setSettings } = useSettings();
  const [settingsInternal, setSettingsInternal] = useState<Settings>(settings);
  const {
    setPlayerSettingsFilled,
    filledTags,
    setFilledTags,
    setDepartmentsFilled,
  } = useContext(DashboardContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [step, setStep] = useState(0);
  const language = useLanguage();
  const [selectedTags, setSelectedTags] = useState<
    Record<Tag["type"], Tag["id"]>
  >({});
  const [selectedDepartment, setSelectedDepartment] = useState<number>();
  const [loadingEnded, setLoadingEnded] = useState(false);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");

  useEffect(() => {
    const fn = async () => {
      const FALLBACK_URL = "/privacy-policy/general.html";
      const url = `/privacy-policy/${keycloak.realm}.html`;
      const urlExsits = await checkIfRealmSpecificUrlExists(url);

      if (urlExsits) {
        setPrivacyPolicyLink(url);
      } else {
        setPrivacyPolicyLink(FALLBACK_URL);
      }
    };
    fn();
  }, []);

  const saveData = async (): Promise<void> => {
    setFilledTags(
      Array.from(new Set([...filledTags, ...Object.keys(selectedTags)]))
    );

    putTags(Object.values(selectedTags).map((value) => ({ value })));
    setSelectedTags({});

    if (selectedDepartment) {
      updateDepartment(selectedDepartment);
      setDepartmentsFilled(true);
    }

    setStep(1);
  };

  const savePlayerProfile = () => {
    const newPlayer = {
      id: "player",
      companyName: settingsInternal.companyName,
      companyLogo: settingsInternal.companyLogo,
      playerName: settingsInternal.playerName,
      playerAvatar: settingsInternal.playerAvatar,
      leadingColor: settingsInternal.leadingColor,
      companyURL:
        settingsInternal.companyURL ||
        generateCompanyURL(settingsInternal.companyName),
      playerEmailAddress:
        settingsInternal.playerEmailAddress ||
        generatePlayerEmailAddress(
          settingsInternal.playerName,
          generateCompanyURL(settingsInternal.companyName)
        ),
    };

    setItem("SETTINGS", newPlayer);
    setSettings(newPlayer);

    MessageHub.send({
      type: ACTOR_CHANGE,
      payload: newPlayer,
    });
    setPlayerSettingsFilled(true);
    close();

    onSave();
  };

  const onSelectTags = (type, id) => {
    setSelectedTags((tags) => ({ ...tags, [type]: id }));
  };

  const onClose = () => {
    close();
    setStep(0);
    setLoadingEnded(false);
  };

  return (
    <StyledPlayerProfileDialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={open}
      onClose={() => onClose()}
      scroll="paper"
    >
      <DialogTitle>
        <FormattedMessage
          id={
            step === 0
              ? "gameControl.modal.player-profile.data.title"
              : "gameControl.modal.player-profile.account.title"
          }
        />
        {step === 0 && !privacyPolicyLink && (
          <StyledPirvacyPolicyLoader className="animate__animated animate__fadeIn">
            <CircularProgress color="primary" />
          </StyledPirvacyPolicyLoader>
        )}
        {step === 0 && privacyPolicyLink && (
          <Typography sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
            <FormattedMessage id="gameControl.modal.player-profile.data.subtitle" />
            <Link
              href={privacyPolicyLink}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              <FormattedMessage id="gameControl.modal.player-profile.data.subtitle.link" />
            </Link>
          </Typography>
        )}
      </DialogTitle>
      <DialogContent style={{ paddingTop: "8px" }}>
        {step === 0 && (
          <PlayerProfile
            language={language}
            onSelectTags={onSelectTags}
            onSelectDepartments={setSelectedDepartment}
            onLoadEnded={() => setLoadingEnded(true)}
          />
        )}
        {step === 1 && (
          <PlayerAccount
            settings={settings}
            onSettingsChanged={(settings: Settings) =>
              setSettingsInternal(settings)
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {loadingEnded && (
          <>
            {step === 0 && (
              <Button onClick={() => setStep(1)}>
                <FormattedMessage id="settings.skip" />
              </Button>
            )}
            <Button
              onClick={step === 0 ? saveData : savePlayerProfile}
              data-e2e-dashboard-panel-save-button
              variant="contained"
              color="primary"
              size="large"
            >
              <FormattedMessage id="dashboard.page.profile.button.save" />
            </Button>
          </>
        )}
      </DialogActions>
    </StyledPlayerProfileDialog>
  );
};

const checkIfRealmSpecificUrlExists = async (url: string) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch {
    return false;
  }
};

export default PlayerProfileModal;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: 250,
  },
  dialog: {
    background: "transparent",
    "& .MuiPaper-root": {
      background: "linear-gradient(180deg, #03BEA5 0%, #00B2E3 100%)",
      border: "2px solid #fff",
      borderRadius: "15px",
      fontSize: "16px",
      overflowY: "unset",
      width: "50vw",
      [theme.breakpoints.down("md")]: {
        border: "none",
        borderRadius: 0,
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      },
    },
  },
  dialogTitle: {
    position: "relative",
    marginBottom: "32px",
    "& img": {
      position: "absolute",
      top: "-150%",
      left: "45%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  dialogContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start !important",
    color: "#fff",
    padding: "10px 32px 0",
    fontSize: "1.15rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "16px 16px 0",
      overflowX: "hidden",
      justifyContent: "center !important",
      margin: "1rem 0",
    },
  },
  dialogActions: {
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    width: "80%",
    margin: "20px auto 0",
    "& a": {
      color: "#fff",
      margin: "16px 0",
      fontSize: "20px",
      borderBottom: "1px solid rgba(250, 250, 250, 0.8)",
      cursor: "pointer",
      textDecoration: "none",
      textAlign: "center",
    },
  },
  conffettiContainer: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    background: "rgba(0, 0, 0, 0.3)",
    top: "0",
    left: "0",
  },
}));
export default styles;

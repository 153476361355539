import pick from "lodash/pick";
import { useEffect, useState } from "react";
import { asapScheduler, Observable } from "rxjs";
import { filter, skip, throttleTime } from "rxjs/operators";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { useRootState } from "./hooks";
import { APP_CHANGE } from "./messages";

export function updateApp(this, extra = {} as any) {
  MessageHub.sendEpisode({
    type: APP_CHANGE,
    payload: {
      ...pick(this, [
        "id",
        "name",
        "path",
        "in",
        "icon",
        "color",
        "component",
        "browser",
        "splash",
        "fastLinkIcon",
        "notificationStyles",
      ]),
      ...extra,
    },
  });
}

export function useActors() {
  const state = useRootState();

  return state.actors;
}

const appStateObservables = new WeakMap();

export function useAppState<T>(app): T {
  let appObservable = appStateObservables.get(app);
  if (!appObservable) {
    appObservable = app.state.pipe(
      throttleTime(50, asapScheduler, { leading: true, trailing: true })
    );
    appStateObservables.set(app, appObservable);
  }
  const [state, setState] = useState(() => {
    let firstState = app.initialState;
    appObservable
      .subscribe((s) => {
        firstState = s;
      })
      .unsubscribe();
    return firstState;
  });

  useEffect(() => {
    const subscription = appObservable.pipe(skip(1)).subscribe(setState);
    return subscription.unsubscribe.bind(subscription);
  }, []);

  return state;
}

export const ofTypes = <T = Message>(...types) => {
  return (observable: Observable<any>): Observable<T> => {
    return observable.pipe(filter(({ type }) => types.includes(type)));
  };
};

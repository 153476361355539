import { Message } from "../../message-hub/interfaces";
import { QUIZ_TIME_ANSWER_QUESTION } from "./../../apps/quiz-time/messages";

export const updateForQuizTimeApp = (state) => {
  if (state.version < 4) {
    state.version = 4;
    const messages: Message[] = state.messages.map((m) => {
      if (m.type === QUIZ_TIME_ANSWER_QUESTION) {
        const payload = { id: m.payload.id, answers: m.payload.cachedAnswers };
        return { ...m, payload: payload };
      }
      return m;
    });

    state.messages = messages;
  }
  return state;
};

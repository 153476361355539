import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,

          "@media screen and (max-width: 700px)": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          textAlign: "left",
          letterSpacing: 0,
          fontSize: "1.875rem",
          textTransform: "uppercase",
          fontWeight: 400,
        },
        subtitle2: {
          fontSize: "1.25rem",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: 0,
        },
      },
    },
  },
});

export default theme;

import { App } from "./interfaces/app";

export const APPSTORE_ADD = "AppStore.add";
export const APPSTORE_SEARCH = "AppStore.searchApps";
export const APPSTORE_APP_INSTALL = "AppStore.appInstalled";
export const APPSTORE_USER_AUTHENTICATED = "AppStore.authenticateUser";

export interface AddApp {
  type: typeof APPSTORE_ADD;
  payload: App;
}

export interface SearchApps {
  type: typeof APPSTORE_SEARCH;
  payload: {
    keyword: string;
  };
}

export interface UserAuthenticated {
  type: typeof APPSTORE_USER_AUTHENTICATED;
  payload: {
    authenticated: boolean;
  };
}

export interface AppInstalled {
  type: typeof APPSTORE_APP_INSTALL;
  payload: App;
}

export type AppStoreMessage =
  | AddApp
  | SearchApps
  | UserAuthenticated
  | AppInstalled;

import { Message as MessageHubMessage } from "../../message-hub/interfaces";
import { ShareDocument } from "./interfaces/share-document";

export const DIGIDOC_USER_SIGN_IN = "digidoc.user.sign.in";
export const DIGIDOC_SHARE_DOCUMENT = "digidoc.share.document";
export const DIGIDOC_TERMS_READ = "digidoc.terms.read";
export const DIGIDOC_PRIVACY_READ = "digidoc.privacy.read";

export type DigidocUserSignIn = MessageHubMessage<typeof DIGIDOC_USER_SIGN_IN>;

export type DigidocShareDocument = MessageHubMessage<
  typeof DIGIDOC_SHARE_DOCUMENT,
  ShareDocument
>;

export type DigidocTermsRead = MessageHubMessage<
  typeof DIGIDOC_TERMS_READ,
  null
>;

export type DigidocPrivacyRead = MessageHubMessage<
  typeof DIGIDOC_PRIVACY_READ,
  null
>;

export type DigidocMessage =
  | DigidocUserSignIn
  | DigidocShareDocument
  | DigidocTermsRead
  | DigidocPrivacyRead;

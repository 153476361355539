import { awardBadge } from "core/client";
import { filter } from "rxjs/operators";
import {
  GAME_BADGE_ADD,
  GAME_BADGE_UPDATE,
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
} from "../core/messages";
import GameEngine from "../game-engine";
import { getIntl } from "../i18n";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import { SCRIPT_REPLAY_DONE } from "./messages";

const globalBadges: Badge[] = [
  {
    id: "replay1",
    name: "One More Time",
    img: "/badges/replaying.svg",
    episodeId: "global",
  },
  {
    id: "profile-fill",
    name: "The Inner Monk",
    img: "/badges/monk.svg",
    episodeId: "global",
  },
];

export interface Badge {
  id: string;
  name: string;
  img: string;
  episodeId?: string;
  obtained?: boolean;
}

export const BADGES_STORAGE_KEY = "BADGES_ENGINE_STORAGE";

export class BadgesEngine {
  private badges: Badge[] = [...globalBadges];
  private intl = getIntl();
  constructor(private storage: string[], private save: (a: string[]) => void) {
    if (!this.storage) this.storage = [];
  }

  onMessage = (msg: Message) => {
    if (msg.type === GAME_BADGE_ADD) {
      this.addBadge(msg.payload.id, !msg.payload.noNotification);
    } else if (msg.type === SCRIPT_REPLAY_DONE) {
      setTimeout(() => this.notifyChanges());
    }
  };

  addBadgeAndWaitForAck = async (badgeId: Badge["id"]) => {
    const added = this.addBadge(badgeId, true);
    if (added) {
      const now = Date.now();
      return new Promise((res) => {
        const s = MessageHub.subject
          .pipe(filter((m) => (m.timestamp || 0) > now))
          .subscribe((m) => {
            if (m.type === NOTIFICATION_REMOVE) {
              s.unsubscribe();
              res(true);
            }
          });
      });
    }
    return true;
  };

  setBadges(badges: Badge[]) {
    this.badges = [...globalBadges, ...badges];
  }

  private addBadge = (badgeId: Badge["id"], notify = false) => {
    const seasonId = GameEngine().episode?.seasonId;
    awardBadge(badgeId, seasonId);
    const badgeStorage = this.storage;
    if (!badgeStorage.includes(badgeId)) {
      const badge = this.getBadge(badgeId);
      badgeStorage.push(badgeId);
      this.notifyChanges();
      if (notify) {
        MessageHub.send({
          type: NOTIFICATION_ADD,
          payload: this.badgeToNotification(badge),
        });
      }
      this.save(this.storage);
      return true;
    }
    return false;
  };

  private notifyChanges = () => {
    MessageHub.send({
      type: GAME_BADGE_UPDATE,
      payload: {
        obtained: this.storage.length,
        total: this.getAllBadges().length,
      },
    });
  };

  private getBadge = (badgeId: Badge["id"]): Badge => {
    const badge = this.badges.find((badge) => badge.id === badgeId);
    if (!badge) throw new Error(`${badgeId} does not exist`);
    return badge;
  };

  private badgeToNotification = (badge: Badge) => {
    const { intl } = this;
    return {
      topText: intl.formatMessage({
        id: "notification.congratulation",
      }),
      title: intl.formatMessage({
        id: `badges.${badge.id}`,
      }),
      description: intl.formatMessage({
        id: `badges.description.${badge.id}`,
      }),
      buttonLabel: intl.formatMessage({
        id: `notification.continue`,
      }),
      icon: badge.img,
    };
  };

  getAllBadges = (badgesList: Badge[] = this.badges): Badge[] => {
    return badgesList.map((b) => {
      if (this.storage.includes(b.id)) {
        return { ...b, obtained: true };
      }
      return b;
    });
  };

  getBadgesInCurrentEpisode = (): Badge[] => {
    const episodeBadges = this.badges.filter(
      (b) => b.episodeId === MessageHub.episodeId
    );
    return this.getAllBadges(episodeBadges);
  };

  getBadgesInEpisode = (episodeId): Badge[] => {
    const episodeBadges = this.badges.filter((b) => b.episodeId === episodeId);
    return this.getAllBadges(episodeBadges);
  };
}

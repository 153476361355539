import { Observable, scan, shareReplay, startWith } from "rxjs";
import { ofTypes } from "../../core/applications";
import { Message } from "../../message-hub/interfaces";
import {
  coffeeGameMessage,
  COFFEE_GAME_DISPLAY,
  COFFEE_GAME_SET_SCORE,
  COFFEE_GAME_SET_SHOW_LOCATION,
} from "./messages";

export interface State {
  score: number;
  showLocation: string;
  visible: boolean;
}

export default class CoffeeGameApp {
  static instance: CoffeeGameApp;
  private initialState: State = {
    score: 0,
    showLocation: "",
    visible: false,
  };
  state: Observable<State>;
  constructor(messages: Observable<Message>) {
    CoffeeGameApp.instance = this;
    this.state = messages.pipe(
      ofTypes<coffeeGameMessage>(
        COFFEE_GAME_SET_SCORE,
        COFFEE_GAME_DISPLAY,
        COFFEE_GAME_SET_SHOW_LOCATION
      ),
      scan(this.reducer, this.initialState),
      startWith(this.initialState),
      shareReplay(1)
    );
    this.state.subscribe();
  }

  get currentState(): State {
    let state;
    this.state.subscribe((s) => (state = s));
    return state;
  }

  private reducer = (state: State, msg: coffeeGameMessage) => {
    switch (msg.type) {
      case COFFEE_GAME_SET_SHOW_LOCATION:
        return this.onSetShowLocation(state, msg.payload.showLocation);
      case COFFEE_GAME_DISPLAY:
        return this.onCofffeGameShow(state, msg.payload.visible);
      case COFFEE_GAME_SET_SCORE:
        return this.onCofffeSetScore(state, msg.payload.score);
      default:
        return state;
    }
  };

  private onSetShowLocation(state: State, showLocation: string) {
    return {
      ...state,
      showLocation,
    };
  }

  private onCofffeGameShow(state: State, visible: boolean) {
    return {
      ...state,
      visible,
    };
  }

  private onCofffeSetScore(state: State, score: number) {
    return {
      ...state,
      score,
    };
  }
}

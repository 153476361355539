import { Observable } from "rxjs";
import { scan, shareReplay } from "rxjs/operators";
import { Quest } from "../../game-engine/script-runner";
import MessageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { ofTypes } from "../applications";
import {
  appLauncherMessages,
  episodeEndMessage,
  GAME_CURRENT_QUEST,
  GAME_LEVEL_PROGRESS,
  PULSE_PLAYER_ICON,
  testCompleteMessage,
  TEST_COMPLETE,
  USER_STUCK,
} from "../messages";
import { State } from "./state";

export class AppHeader {
  static instance: AppHeader;

  initialState: State = {
    progress: 0,
    completedTests: [],
  };

  constructor(messages: Observable<Message>) {
    AppHeader.instance = this;
    this.state = messages.pipe(
      ofTypes<appLauncherMessages>(
        GAME_CURRENT_QUEST,
        GAME_LEVEL_PROGRESS,
        TEST_COMPLETE
      ),
      scan(this.reducer, this.initialState),
      shareReplay(1)
    );
    this.state.subscribe();
  }

  state: Observable<State>;

  private reducer = (
    state: State,
    msg: appLauncherMessages | episodeEndMessage | testCompleteMessage
  ) => {
    switch (msg.type) {
      case GAME_CURRENT_QUEST:
        return { ...state, currentQuest: msg.payload };
      case GAME_LEVEL_PROGRESS:
        return {
          ...state,
          progress: msg.payload.progress * 100,
        };
      case TEST_COMPLETE:
        if (!msg.result) return state;
        return {
          ...state,
          completedTests: [msg.result, ...state.completedTests],
        };
      default:
        return state;
    }
  };

  stopPulsePlayerIcon() {
    MessageHub.send({
      type: PULSE_PLAYER_ICON,
      payload: false,
    });
  }

  sendUserStuck(quest: Quest | undefined) {
    MessageHub.send({
      type: USER_STUCK,
      payload: quest,
    });
  }
}

import { Message as MessageHubMessage } from "../../message-hub/interfaces";

export const IMAGE_GAP_FILLING_SET_PLACEHOLDER_DEFINITIONS =
  "imageGapFilling.set.placeholder.definitions";

export const IMAGE_GAP_FILLING_CLOSE = "imageGapFilling.answers.correct";

export type imageGapFillingSetPlaceholderDefinitions = MessageHubMessage<
  typeof IMAGE_GAP_FILLING_SET_PLACEHOLDER_DEFINITIONS,
  {
    labelList: string[];
    SvgElementHidden: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    SvgElementRevealed: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }
>;

export type imageGapFillingAnswersCorrect = MessageHubMessage<
  typeof IMAGE_GAP_FILLING_CLOSE
>;

export type imageGapFillingMessage =
  | imageGapFillingSetPlaceholderDefinitions
  | imageGapFillingAnswersCorrect;

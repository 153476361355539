import { faLightbulb, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import * as colors from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "./dashboard/dashboard.context";
import { getName } from "./dashboard/pages/admin/missions/missions.service";

export interface Competence {
  title: string;
  name: string;
  icon: IconDefinition;
  color: string;
}

const colorsArray = Object.keys(colors)
  .map((key) => [colors[key]])
  .map((x) => x[0][700])
  .filter((x) => x !== undefined);

export const useCompetence = (
  id: number,
  index = 0
): Competence | undefined => {
  const { competenceAreaTree } = useContext(DashboardContext);
  const [competence, setCompetence] = useState<Competence>();

  useEffect(() => {
    const comp = competenceAreaTree.find((x) => x.id === id);
    if (comp) {
      setCompetence({
        title: comp.name,
        name: getName(comp.name),
        icon: faLightbulb,
        color: colorsArray[index],
      });
    }
  }, [id, competenceAreaTree]);

  return competence;
};

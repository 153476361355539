import { styled } from "@mui/material";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { getTargets } from "core/dashboard/pages/admin/missions/missions.service";
import { MissionTarget } from "core/interfaces";
import { userHasRole } from "core/keycloak";
import { getItem } from "core/storage";
import { useEffect, useMemo, useState } from "react";
import annaImage from "./assets/anna.svg";
import victorImage from "./assets/victor.png";
import { BotChatInitialized } from "./bot-chat-initialized";
import { openAiConnection } from "./services/open-ai-connection";

const digitizationCompetenceAreas = [1, 2, 3, 4, 5];
const digitizationCompetences = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23,
  24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  43, 44, 45, 46, 47,
];
const digitizationSubCompetences = [
  100, 105, 107, 111, 50, 56, 64, 67, 68, 69, 70, 71, 72, 75, 76, 77, 81, 89,
  94, 95, 97, 98, 99,
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const protembisCompetenceAreas = [20];
const protembisCompetences = [2001, 2002, 2004, 2005, 2006, 2007];
const protembisSubCompetences = [
  200102, 200103, 200104, 200201, 200202, 200203, 200204, 200205, 200101,
  200401, 200402, 200403, 200404, 200405, 200501, 200602, 200603, 200604,
  200701, 200702,
];

const digitizationSeasonIds = ["s01", "s02", "s91", "s97"];
const protembisSeasonIds = ["s96"];

export const BotChat = () => {
  const [initialized, setInitialized] = useState(false);
  const [allTargets, setAllTargets] = useState<MissionTarget[]>([]);
  const isAdmin = userHasRole("talent_admin");

  const storageMessage = getItem("messages");
  const endedSeasons = storageMessage
    ?.map((item) => item?.episode?.slice(0, 3))
    .filter(Boolean);

  useEffect(() => {
    if (isAdmin) {
      getTargets().then((data) => {
        setAllTargets(data);
      });
    }
  }, [isAdmin]);

  const showDigitizationBot = useMemo(() => {
    const episodeCompleted = endedSeasons?.some((item) =>
      digitizationSeasonIds.includes(item ?? "")
    );

    const inTarget =
      allTargets?.some((item) =>
        digitizationCompetenceAreas.includes(item.competenceArea ?? -1)
      ) ||
      allTargets?.some((item) =>
        digitizationCompetences.includes(item.competence ?? -1)
      ) ||
      allTargets?.some((item) =>
        digitizationSubCompetences.includes(item.subCompetence ?? -1)
      );

    return inTarget && episodeCompleted;
  }, [allTargets, endedSeasons.length]);

  const showProtembisBot = useMemo(() => {
    const episodeCompleted = endedSeasons?.some((item) =>
      protembisSeasonIds.includes(item ?? "")
    );
    const inTarget =
      allTargets?.some((item) =>
        protembisCompetenceAreas.includes(item.competenceArea ?? -1)
      ) ||
      allTargets?.some((item) =>
        protembisCompetences.includes(item.competence ?? -1)
      ) ||
      allTargets?.some((item) =>
        protembisSubCompetences.includes(item.subCompetence ?? -1)
      );
    return inTarget && episodeCompleted;
  }, [allTargets, endedSeasons.length]);

  useEffect(() => {
    const queryParams = window.location.hash.split("?")[1];
    const openaiParam = queryParams
      ?.split("&")
      .find((param) => param.includes("openai="));
    const apiKey = openaiParam?.split("=")[1];
    const storageKey = sessionStorage.getItem("openai");

    if (apiKey) {
      sessionStorage.setItem("openai", apiKey);
      openAiConnection.initalizeConnection(apiKey);
      setInitialized(true);
    } else if (storageKey) {
      openAiConnection.initalizeConnection(storageKey);
      setInitialized(true);
    }
  }, []);

  if (!initialized) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <StyledContainer>
        {showDigitizationBot && (
          <BotChatInitialized
            avatarImg={annaImage}
            assistantId="asst_VtVbBDKTBLk0RhJXxkKtK22m"
          />
        )}
        {showProtembisBot && (
          <BotChatInitialized
            avatarImg={victorImage}
            assistantId="asst_hQMqZjXxlPVl58h5IfLfICCU"
          />
        )}
      </StyledContainer>
    </QueryClientProvider>
  );
};

const StyledContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  right: "140px",
  bottom: theme.spacing(2),
  zIndex: 11,
  display: "flex",
  gap: theme.spacing(2),
}));

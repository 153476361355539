import { Attachment } from "../mailbox/interfaces/mail.interface";
import { SharedProject } from "./interfaces";
export const STORAGE_APP_SHARE_PROJECT = "storage.app.share.project";
export const STORAGE_APP_STORE_FILE = "storage.app.store.file";

export interface StorageAppStoreFile {
  type: typeof STORAGE_APP_STORE_FILE;
  payload: Attachment;
}

export interface StorageAppShareProject {
  type: typeof STORAGE_APP_SHARE_PROJECT;
  payload: SharedProject;
}

export type StorageAppMessages = StorageAppShareProject | StorageAppStoreFile;

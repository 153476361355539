import debounce from "lodash/debounce";
import { updateBadges } from "../game-engine/migrations/badges";
import { updateForQuizTimeApp } from "../game-engine/migrations/quiz-answers";
import { updateForReplays } from "../game-engine/migrations/replays";
import { updateForSeasons } from "../game-engine/migrations/seasons";
import { defaults } from "../onboarding/player/defaults";
import { getState, saveState } from "./client";
import { UserInfo } from "./interfaces";

export interface ForcedEpisodeConfig {
  sid: string;
  eid: string;
  redirectUrl?: string;
}

let state: Record<string, any> = {};
let userInfo: UserInfo;

export const SAVE_KEY = "GameEngineMetaInfo";
export const SEASONS_KEY = "SEASONS";

const params = new URLSearchParams(window.location.search);
const seasonId = params.get("sid");
const episodeId = params.get("eid");
const redirectUrl = params.get("redirectUrl");
const useDefaults = params.get("defaults") !== null;
const staticProps = {
  defaults: useDefaults,
  forcedEpisode: {
    sid: seasonId,
    eid: episodeId,
    redirectUrl,
  } as ForcedEpisodeConfig,
  tenant: (window as any).REACT_APP_AUTH_REALM,
  isDevTenant: (window as any).REACT_APP_AUTH_REALM === "talentdigital-devtd2",
  isDemoTenant:
    params.get("demo") !== null ||
    (window as any).REACT_APP_AUTH_REALM === "talentdigital-v2demo",
  notrack:
    params.get("forcetrack") === null &&
    (window.location.hostname === "localhost" ||
      params.get("notrack") !== null ||
      ["talentdigital-devtd2", "talentdigital-internaldemo"].includes(
        (window as any).REACT_APP_AUTH_REALM
      )),
};

function isRestart() {
  return params.get("restart") !== null;
}

export async function initStorage(
  loggedUserInfo: UserInfo = { sub: "noauth" }
) {
  userInfo = loggedUserInfo;
  const { localStorage } = window;
  if (isRestart()) {
    state = { timestamp: Date.now() };
  } else {
    const savedLocal = localStorage.getItem(userInfo.sub);
    /*
      It looks like savedLocal is only present when you load a custom savegame for development /
      debugging purposes
    */
    if (savedLocal) {
      const localState = JSON.parse(savedLocal);
      let remoteState;
      try {
        remoteState = await getState();
      } catch (e) {
        state = localState;
        return;
      }
      state =
        remoteState.timestamp < localState.timestamp ? localState : remoteState;
    } else {
      state = await getState();
    }
  }

  if (!state.GameEngineMetaInfo) {
    state.GameEngineMetaInfo = {};
  }

  state = updateBadges(state);
  state = updateForSeasons(state);
  state = updateForReplays(state);
  state = updateForQuizTimeApp(state);

  if (useDefaults) {
    state.GameEngineMetaInfo.tourDone = true;
    state.SETTINGS = defaults;
    state.onboardBuyerDone = true;
    state.DASHBOARD_TOUR_COMPLETE = true;
  }
}

let persisting = false;
function persistLocally() {
  window.localStorage.setItem(userInfo.sub, JSON.stringify(state));
}

async function persist() {
  const timestamp = Date.now();
  state = { timestamp, ...state };
  if (userInfo.sub === "noauth") {
    persistLocally();
  } else {
    if (persisting) {
      persistDebounced();
      return;
    }
    try {
      persisting = true;
      await saveState(state);
      window.localStorage.removeItem(userInfo.sub);
    } catch (e) {
      persistLocally();
    } finally {
      persisting = false;
    }
  }
}

const persistDebounced = debounce(persist, 1000, {
  trailing: true,
  leading: false,
});

function saveGame(name: string) {
  window.localStorage.setItem(
    `savedGame.${name}`,
    JSON.stringify({ timestamp: Date.now(), ...state })
  );
}

async function loadSavedGame(name: string) {
  const savedGame = window.localStorage.getItem(`savedGame.${name}`);

  if (savedGame) {
    /**
     * Read the saved state from the local storage, replace the global state with it and call persist to save the new state in the database before reloading app
     */
    window.localStorage.setItem(userInfo.sub, savedGame);
    state = JSON.parse(savedGame);
    await persist();
    const search = window.location.search.substring(1);
    const params = new URLSearchParams(search);
    params.delete("restart");
    window.location.href = `${window.location.origin}?${params.toString()}`;
  } else {
    console.error(`No saved game with name ${name}`);
  }
}

function resetGame(targetEpisode: string) {
  (async function (targetEpisode) {
    const currentState = await getState();
    delete currentState[`lastStep.${targetEpisode}`];

    await saveState({
      ...currentState,
      timestamp: Date.now(),
      messages:
        currentState.messages?.filter((m) => m.episode !== targetEpisode) || [],
      GameEngineMetaInfo: {
        ...currentState.GameEngineMetaInfo,
        endedEpisodes:
          currentState.GameEngineMetaInfo?.endedEpisodes?.filter(
            (e) => e !== targetEpisode
          ) || [],
      },
    });

    window.location.reload();
  })(targetEpisode);

  return `Resetting to Episode ${targetEpisode}`;
}

function logState() {
  console.log(state);
}

export function setItem(key, value) {
  state[key] = value;
  persistDebounced();
}

export function getItem(key) {
  if (staticProps[key] !== undefined) return staticProps[key];
  if (state[key] !== undefined) return state[key];
  return userInfo?.[key];
}

export function setItemWithExpiry(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  window.localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function removeItem(key) {
  delete state[key];
  persistDebounced();
}

/**
 * To be able to change language of page without changing language of browser
 */
export function setLanguage(lang: "en" | "de") {
  if (["en", "de"].includes(lang)) {
    setItem("locale", lang);
  } else {
    console.error('Wrong language. Choose from: ["en", "de"]');
  }
}

(window as any).saveGame = saveGame;
(window as any).loadSavedGame = loadSavedGame;
(window as any).resetGame = resetGame;
(window as any).logState = logState;
(window as any).setLanguage = setLanguage;

import { Notification } from "./interfaces";

export const NOTIFICATION_ADD = "root.notification.add";
export const NOTIFICATION_REMOVE = "root.notification.remove";
export const NOTIFICATION_TRANSFORM = "root.notification.transform";

export interface addNotificationMessage {
  type: typeof NOTIFICATION_ADD;
  payload: Notification;
}

export interface removeNotificationMessage {
  type: typeof NOTIFICATION_REMOVE;
  payload: Notification;
}

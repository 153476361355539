import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {
  Backdrop,
  Box,
  IconButton,
  keyframes,
  styled,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  useGetOpenAiMessages,
  useGetOpenAiRun,
  usePostOpenAiMessage,
  usePostOpenAiRun,
  usePostOpenAiThread,
} from "./hooks";
import { TextBubble } from "./text-bubble";

const TWO_SECONDS = 2000;

export const BotChatInitialized = ({
  avatarImg,
  assistantId,
}: {
  avatarImg: string;
  assistantId: string;
}) => {
  const [chatOpen, setChatOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [lastKnownMessageId, setLastKnownMessageId] = useState<string>("");
  const [showWaitingBubble, setShowWaitingBubble] = useState(false);
  const queryClient = useQueryClient();
  const intl = useIntl();

  const {
    mutate: postOpenAiThread,
    data: thread,
    reset: resetPostOpenAiThread,
  } = usePostOpenAiThread();

  const {
    mutate: openAiRun,
    data: postRunData,
    reset: resetPostOpenAiRun,
    isLoading: postOpenAiRunIsPending,
  } = usePostOpenAiRun();

  const {
    mutate: postOpenAiMessage,
    isLoading: postOpenAiMessageIsPending,
    reset: resetPostOpenAiMessage,
  } = usePostOpenAiMessage();

  const { data: runData, isFetching: runDataIsFetching } = useGetOpenAiRun(
    thread?.id,
    postRunData?.id,
    {
      enabled: Boolean(thread?.id) && Boolean(postRunData?.id),
      keepPreviousData: true,
      refetchInterval: (data) => {
        if (data?.status === "in_progress") {
          return TWO_SECONDS;
        }
        return false;
      },
    }
  );

  const {
    data: openAiMessages = [],
    refetch: openAiMessagesRefetch,
    isRefetching: openAiMessagesIsRefetching,
  } = useGetOpenAiMessages(thread?.id, {
    enabled: Boolean(thread?.id),
    select: (data) => data.data.flatMap((item) => item),
  });

  useEffect(() => {
    if (chatOpen) {
      postOpenAiThread(null);
    } else {
      setInputValue("");
      resetPostOpenAiThread();
      resetPostOpenAiRun();
      resetPostOpenAiMessage();
      queryClient.resetQueries({ queryKey: [useGetOpenAiRun.queryKey] });
      queryClient.resetQueries({ queryKey: [useGetOpenAiMessages.queryKey] });
      setLastKnownMessageId("");
      setShowWaitingBubble(false);
    }
  }, [
    chatOpen,
    postOpenAiThread,
    resetPostOpenAiThread,
    resetPostOpenAiRun,
    resetPostOpenAiMessage,
    queryClient,
  ]);

  const waiting =
    runData?.status === "in_progress" ||
    runDataIsFetching ||
    openAiMessagesIsRefetching ||
    postOpenAiMessageIsPending ||
    postOpenAiRunIsPending;

  useEffect(() => {
    if (thread?.id && runData?.status === "completed") {
      openAiMessagesRefetch();
    }
  }, [thread?.id, runData?.id, runData?.status, openAiMessagesRefetch]);

  useLayoutEffect(() => {
    const latestMessage = openAiMessages[0];
    if (latestMessage && latestMessage.id !== lastKnownMessageId) {
      setLastKnownMessageId(lastKnownMessageId);
      setShowWaitingBubble(waiting && latestMessage.role === "user");
    }
  }, [lastKnownMessageId, waiting, openAiMessages]);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (thread?.id && !waiting) {
      postOpenAiMessage({
        threadId: thread.id,
        content: inputValue,
      });
      setInputValue("");
      openAiRun({ threadId: thread.id, assistantId });
    }
  };

  return (
    <>
      {chatOpen && (
        <Backdrop open={true}>
          <StyledChat
            sx={{ opacity: chatOpen ? 1 : 0 }}
            onClick={(event) => event.stopPropagation()}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2 }}>
              <StyledCloseIcon
                aria-label="close"
                onClick={() => setChatOpen(false)}
              >
                <CloseIcon />
              </StyledCloseIcon>
            </Box>
            {(!openAiMessages || openAiMessages.length === 0) && (
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#fff",
                }}
              >
                {intl.formatMessage({ id: "bot-chat.no-messages" })}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                overflowY: "auto",
                flexDirection: "column-reverse",
                px: 2,
              }}
            >
              {showWaitingBubble && (
                <TextBubble isBot={true} waiting={true} botAvatar={avatarImg} />
              )}
              {openAiMessages
                .filter((message) => message.content.length !== 0)
                .map((message) => (
                  <div key={message.id}>
                    {message.content.map((content, index) => (
                      <TextBubble
                        key={index}
                        content={content}
                        isBot={message.role === "assistant"}
                        botAvatar={avatarImg}
                      />
                    ))}
                  </div>
                ))}
            </Box>
            <Box sx={{ width: "100%", mt: "auto", px: 2 }}>
              <StyledInputWrapper>
                <StyledInput
                  placeholder={
                    intl.formatMessage({ id: "bot-chat.write-a-message" }) ?? ""
                  }
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  type="text"
                  autoFocus
                  onKeyDown={handleEnter}
                />
                <IconButton
                  aria-label="send"
                  size="small"
                  disabled={waiting}
                  color="primary"
                  onClick={handleSendMessage}
                >
                  <SendIcon fontSize="inherit" />
                </IconButton>
              </StyledInputWrapper>
            </Box>
          </StyledChat>
        </Backdrop>
      )}

      <div className="animate__animated animate__fadeInRight">
        <StyledBotChatBubble onClick={() => setChatOpen(true)}>
          <img src={avatarImg} alt="Bot icon" width="100%" height="100%" />
        </StyledBotChatBubble>
      </div>
    </>
  );
};

const StyledChat = styled("div")(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(2),
  bottom: "116px",
  width: `calc(100% - ${theme.spacing(4)})`,
  maxWidth: "1000px",
  height: "80vh",
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: "linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
  backgroundSize: "400% 400%",
  transition: "200ms",
  animation: `${gradientAnimation} 60s ease infinite`,
  zIndex: 12,
  overflow: "hidden",
}));

const StyledCloseIcon = styled(IconButton)(({ theme }) => ({
  color: "#000",
  background: "#fff",
  borderRadius: "100%",
  transition: "200ms",

  "&:hover": {
    background: "#fff",
    transform: "scale(1.1)",
  },
}));

const StyledInputWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  background: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  border: "none",
  outline: "none",
  color: "#000",
  borderRadius: theme.shape.borderRadius,
  cursor: "text",
}));

const StyledBotChatBubble = styled("div")(({ theme }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "100%",
  background: "linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
  backgroundSize: "400% 400%",
  animation: `${gradientAnimation} 60s ease infinite`,
  cursor: "pointer",
  transition: "200ms",
  overflow: "hidden",
  border: "5px solid #fff",
  boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.5)",

  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

import React, { forwardRef } from "react";
import { ANCHOR_ID } from "../constants";
import { DecisionType } from "../interfaces/decision-type";

export type DecisionAnchorPropsType = {
  type: DecisionType;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

export const DecisionAnchor = forwardRef(
  (
    { type, top, bottom, left, right }: DecisionAnchorPropsType,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        data-e2e-decision-anchor-element
        ref={ref}
        id={`${ANCHOR_ID}_${type.valueOf()}`}
        data-styles={JSON.stringify({
          top,
          bottom,
          left,
          right,
        })}
        data-height=""
      ></div>
    );
  }
);

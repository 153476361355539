import { Grid } from "@mui/material";
import { getPlayRecommendation, getTrainingRecommendation } from "core/client";
import Recommended from "core/dashboard/components/recommended";
import { TrainingRecommendations } from "core/dashboard/components/training-recommendations";
import { PlayRecommendation, TrainingRecommendation } from "core/interfaces";
import { Episode, Season } from "game-engine/interfaces";
import { useEffect, useState } from "react";
import GameEngine from "../../../../game-engine";

type RecommendationsProps = {
  onPlayEpisode: (episode: Episode) => void;
  seasons: Season[];
};

export const Recommendations = ({
  onPlayEpisode,
  seasons,
}: RecommendationsProps) => {
  const [loadingTraining, setLoadingTraining] = useState(true);
  const [loadingRecommendedEpisodes, setLoadingRecommendedEpisodes] =
    useState(true);

  const [displayTraining, setDisplayTraining] = useState<boolean | undefined>();
  const [nextPlayableEpisode, setNextPlayableEpisode] = useState<Episode>();
  const [recommendedEpisodes, setRecommendedEpisodes] = useState<
    PlayRecommendation[]
  >([]);
  const gameEngine = GameEngine();

  useEffect(() => {
    const nextPlayable = gameEngine.episodes.getNextPlayable();
    setNextPlayableEpisode(nextPlayable);
  }, []);

  const [trainingList, setTrainingList] = useState<TrainingRecommendation[]>(
    []
  );

  useEffect(() => {
    getTrainingRecommendation()
      .then((list) => {
        if (typeof list === "boolean") {
          setDisplayTraining(false);
        } else {
          setDisplayTraining(true);
          setTrainingList(list);
        }
      })
      .finally(() => {
        setLoadingTraining(false);
      });
  }, []);

  useEffect(() => {
    getPlayRecommendation()
      .then(setRecommendedEpisodes)
      .finally(() => {
        setLoadingRecommendedEpisodes(false);
      });
  }, []);

  if (loadingTraining || loadingRecommendedEpisodes) null;

  const displayRecommendations =
    nextPlayableEpisode || recommendedEpisodes.length > 0;

  return (
    <>
      {displayRecommendations && (
        <Grid
          item
          xs={12}
          md={displayTraining ? 6 : 12}
          data-testid="recommended"
        >
          <Recommended
            nextPlayableEpisode={nextPlayableEpisode}
            onPlay={onPlayEpisode}
            recommendedEpisodes={recommendedEpisodes}
            seasons={seasons}
          />
        </Grid>
      )}

      {displayTraining && (
        <Grid
          item
          xs={12}
          md={displayRecommendations ? 6 : 12}
          data-testid="training-recommendations"
        >
          <TrainingRecommendations trainingList={trainingList} />
        </Grid>
      )}
    </>
  );
};

import { Actor } from "../../core/interfaces";
import { Message as MessageHubMessage } from "../../message-hub/interfaces";
import { HomeState, VideoCallMessage, VideoCallProps } from "./interfaces";
export const VIDEO_CALL_ADD = "videoCall.call.add";
export const VIDEO_CALL_REMOVE = "videoCall.call.remove";
export const VIDEO_CALL_READY = "videoCall.call.ready";
export const VIDEO_CALL_START = "videoCall.call.start";
export const VIDEO_CALL_END = "videoCall.call.end";
export const VIDEO_CALL_FINISH = "videoCall.call.confirmEnd";
export const VIDEO_CALL_ACTOR_ADD = "videoCall.actor.add";
export const VIDEO_CALL_ACTOR_REMOVE = "videoCall.actor.remove";
export const VIDEO_CALL_ACTOR_SAY = "videoCall.actor.say";
export const VIDEO_CALL_MESSAGE_ACK = "videoCall.message.ack";
export const VIDEO_CALL_RECORD = "videoCall.record";
export const VIDEO_CALL_TRANSLATE = "videoCall.translate";
export const VIDEO_CALL_JOIN_BY_CODE = "videoCall.join.by.code";
export const VIDEO_CALL_HOME_STATE = "videoCall.home.state";

export type videoCallAdd = MessageHubMessage<
  typeof VIDEO_CALL_ADD,
  VideoCallProps
>;

export type videoCallRemove = MessageHubMessage<
  typeof VIDEO_CALL_REMOVE,
  Pick<VideoCallProps, "id">
>;

export type videoCallReady = MessageHubMessage<
  typeof VIDEO_CALL_READY,
  Pick<VideoCallProps, "id">
>;

export type videoCallStart = MessageHubMessage<
  typeof VIDEO_CALL_START,
  Pick<VideoCallProps, "id">
>;
export type videoCallEnd = MessageHubMessage<
  typeof VIDEO_CALL_END,
  Pick<VideoCallProps, "id">
>;
export type videoCallConfirmEnd = MessageHubMessage<
  typeof VIDEO_CALL_FINISH,
  Pick<VideoCallProps, "id">
>;

export type videoCallActorAdd = MessageHubMessage<
  typeof VIDEO_CALL_ACTOR_ADD,
  { callId?: VideoCallProps["id"]; actor: Actor["id"] }
>;

export type videoCallActorRemove = MessageHubMessage<
  typeof VIDEO_CALL_ACTOR_REMOVE,
  {
    callId?: VideoCallProps["id"];
    actor: Actor["id"];
  }
>;

export type videoCallActorSay = MessageHubMessage<
  typeof VIDEO_CALL_ACTOR_SAY,
  VideoCallMessage
>;

export type videoCallMessageAck = MessageHubMessage<
  typeof VIDEO_CALL_MESSAGE_ACK,
  Pick<VideoCallProps, "id">
>;

export type videoCallRecord = MessageHubMessage<
  typeof VIDEO_CALL_RECORD,
  boolean
>;

export type videoCallTranslate = MessageHubMessage<
  typeof VIDEO_CALL_TRANSLATE,
  boolean
>;

export type videoCallJoinByCode = MessageHubMessage<
  typeof VIDEO_CALL_JOIN_BY_CODE,
  { enabled: boolean }
>;

export type videoCallHomeState = MessageHubMessage<
  typeof VIDEO_CALL_HOME_STATE,
  { homeState: HomeState }
>;

export type videoChatMessage =
  | videoCallAdd
  | videoCallRemove
  | videoCallReady
  | videoCallStart
  | videoCallEnd
  | videoCallConfirmEnd
  | videoCallActorAdd
  | videoCallActorRemove
  | videoCallActorSay
  | videoCallMessageAck
  | videoCallRecord
  | videoCallTranslate
  | videoCallJoinByCode
  | videoCallHomeState;

import { Message } from "../../message-hub/interfaces";

export const GUIDED_COMMUNICATION_CLOSE = "GuidedCommunication.close";
export const GUIDED_COMMUNICATION_NEXT = "GuidedCommunication.next";
export const GUIDED_COMMUNICATION_OPEN = "GuidedCommunication.open";
export const GUIDED_COMMUNICATION_SEND = "GuidedCommunication.send";

export type Open = Message<typeof GUIDED_COMMUNICATION_OPEN>;
export type Close = Message<typeof GUIDED_COMMUNICATION_CLOSE>;
export type Next = Message<typeof GUIDED_COMMUNICATION_NEXT>;
export type Send = Message<
  typeof GUIDED_COMMUNICATION_SEND,
  {
    message: string;
    showNext?: boolean;
    actorImage: string;
  }
>;

export type GuidedCommunicationMessages = Open | Close | Send | Next;

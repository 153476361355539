import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { AppHeader as App } from "..";
import { useTourState } from "../../hooks";
import { useCurrentQuest, useProgress } from "../hooks";
import { useStyles } from "./progress-bar.styles";

const USER_STUCK_TIMEOUT = 1000 * 60 * 5;

const ProgressBar = () => {
  const tourState = useTourState();
  const gameProgress = useProgress();
  const currentQuest = useCurrentQuest();
  const classes = useStyles();
  const [activeTime, setActiveTime] = useState(0);

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60,
  });
  const currentGameProgress = tourState.run ? 45 : gameProgress;

  useEffect(() => {
    setActiveTime(0);
    const activeTimeInterval = setInterval(
      () =>
        !isIdle() &&
        setActiveTime((state) =>
          state < USER_STUCK_TIMEOUT ? state + 1000 : state
        ),
      1000
    );

    return () => clearInterval(activeTimeInterval);
  }, [gameProgress]);

  useEffect(() => {
    if (activeTime >= USER_STUCK_TIMEOUT) {
      App.instance.sendUserStuck(currentQuest);
    }
  }, [activeTime]);

  return (
    <div
      className={classes.bar}
      id="game-progress-bar"
      style={{ position: "relative" }}
    >
      {(currentGameProgress > 0 || tourState?.run) && (
        <>
          <div
            className={classes.gauge}
            style={{
              position: "relative",
              width: `${currentGameProgress}%`,
              borderRight: currentGameProgress === 100 ? 0 : "2px solid white",
            }}
          >
            {currentGameProgress >= 12 && (
              <p>
                {isFinite(currentGameProgress)
                  ? Math.round(currentGameProgress)
                  : "0"}
                %
              </p>
            )}
          </div>
          {currentGameProgress < 12 && (
            <p
              style={{
                position: "absolute",
                left: 5,
                margin: 0,
                padding: 0,
                marginRight: 10,
                lineHeight: "1px",
                fontSize: "0.875rem",
                color: "white",
              }}
            >
              {isFinite(currentGameProgress)
                ? Math.round(currentGameProgress)
                : "0"}
              %
            </p>
          )}
        </>
      )}
      {currentGameProgress === 0 && <p className={classes.emptyText}>0%</p>}
    </div>
  );
};

export default ProgressBar;

import { useEffect, useRef, useState } from "react";
import { Observable } from "rxjs";
import { filter, map, share, startWith } from "rxjs/operators";
import { AppHeader } from ".";
import GameEngine from "../../game-engine";
import { getLanguage } from "../../i18n";
import messageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { useAppState } from "../applications";
import { getReportTestDetails } from "../client";
import { CompetenceLevel } from "../enums";
import {
  COMPETENCE_SCORE_UPDATE,
  GAME_BADGE_UPDATE,
  GAME_ENGAGEMENT_POINTS_ADD,
  PULSE_PLAYER_ICON,
} from "../messages";
import { State } from "./state";

export const useObservabale =
  <T>(obs: Observable<T>, initialValue?: any) =>
  () => {
    const o = useRef(null as any);
    if (o.current === null) {
      o.current = {
        observable: obs.pipe(share()),
        subscriptions: [],
        initialValue,
      };

      const s = o.current.observable.subscribe((v) => {
        o.current.initialValue = v;
      });
      o.current.subscriptions.push(s);
    }
    const [value, setValue] = useState(o.current.initialValue);
    useEffect(() => {
      o.current.subscriptions.push(obs.subscribe((m) => setValue(m)));
      return () => o.current.subscriptions.forEach((s) => s.unsubscribe());
    }, []);
    return value;
  };

const $competenceScore = messageHub.subject.pipe(
  (observable: Observable<Message>) =>
    observable.pipe(
      filter((m) => m.type === COMPETENCE_SCORE_UPDATE),
      map((m) => m.payload)
    )
);

export const useCompetenceScore = useObservabale($competenceScore, {
  totalPassed: 0,
  totalTried: 0,
});

const $badges = messageHub.subject.pipe((observable: Observable<Message>) =>
  observable.pipe(
    filter((m) => m.type === GAME_BADGE_UPDATE),
    map((m) => m.payload),
    startWith({
      obtained: 0,
      total: 0,
    })
  )
);
export const useBadges = useObservabale($badges, {
  obtained: 0,
  total: 0,
});

const $engagementPoints = messageHub.subject.pipe(
  (observable: Observable<Message>) =>
    observable.pipe(
      filter((m) => m.type === GAME_ENGAGEMENT_POINTS_ADD),
      map((m) => m.payload.points)
    )
);
export const useEngagementPoints = useObservabale($engagementPoints, 0);

export const useProgress = () => {
  const app = AppHeader.instance;
  const state = useAppState<State>(app);
  return state.progress;
};

export const useCurrentQuest = () => {
  const app = AppHeader.instance;
  const state = useAppState<State>(app);

  return state.currentQuest;
};

const $pulsePlayerIcon = messageHub.subject.pipe(
  filter((m) => m.type === PULSE_PLAYER_ICON),
  map((m) => m.payload)
);

export const usePulsePlayerIcon = useObservabale($pulsePlayerIcon, false);

type TestDetails = Record<
  string,
  { description: string; level: CompetenceLevel; result?: 0 | 1 }
>;

export const useEpisodeTests = () => {
  const [testDetails, setTestDetails] = useState<TestDetails>({});
  const episode = GameEngine().episode;

  useEffect(() => {
    if (!episode?.seasonId || episode?.index === undefined) return;
    getReportTestDetails(episode?.seasonId, episode?.id).then(
      (reportTestDetails) => {
        const language = getLanguage();
        const details: TestDetails = {};
        for (const { tests } of reportTestDetails) {
          for (const test of tests) {
            const description = JSON.parse(test.description)[language];
            details[test.id] = { description, level: test.level };
          }
        }
        setTestDetails(details);
      }
    );
  }, []);

  return testDetails;
};

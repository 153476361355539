import { Alert, Button, Snackbar } from "@mui/material";
import { FormattedMessage } from "react-intl";

type WarningProps = {
  onConfirm: () => void;
  message: string;
  confirmText: string;
};

export const Warning = ({ onConfirm, message, confirmText }: WarningProps) => {
  return (
    <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert severity="warning">
        <FormattedMessage id={message} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            data-e2e-notification-button
            color="secondary"
            size="small"
            onClick={onConfirm}
          >
            <FormattedMessage id={confirmText} />
          </Button>
        </div>
      </Alert>
    </Snackbar>
  );
};

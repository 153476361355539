import {
  Backdrop,
  Button,
  Card,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { PlayRecommendation } from "core/interfaces";
import { Episode, Season } from "game-engine/interfaces";
import { RefObject, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import GameEngine from "../../../game-engine";
import { useDeviceDetect } from "../../hooks";
import arrow from "../assets/tour_arrow.svg";

const StyledMediaContent = styled(Grid)(({ theme }) => ({
  height: "100%",
  background: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(() => ({
  background: amber["500"],
  color: "#fff",
  transition: "all .3s",
  "&:hover": {
    background: amber["300"],
    transform: "scale(1.1)",
  },
}));

const StyledTourMessage = styled("div")(() => ({
  position: "absolute",
  zIndex: 9999,
}));

const StyledTourDialog = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  position: "relative",
}));

const StyledTourDialogContent = styled(Grid)(({ theme }) => ({
  backgroundColor: "#223838",
  border: "2px solid white",
  maxWidth: "260px",
  borderRadius: "8px",
  width: "auto",
  height: "136px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  minHeight: "75px",
  padding: theme.spacing(2),
}));

const TourMessage = ({
  buttonRef,
  cardAnimationEnd,
  confirm,
  confirmAndPlay,
}: {
  buttonRef: RefObject<HTMLButtonElement>;
  cardAnimationEnd: boolean;
  confirm: () => void;
  confirmAndPlay: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [pos, setPos] = useState({ top: 0, left: 0 });
  const [arrowStyles, setArrowStyles] = useState<{
    top?: string;
    left?: string;
    transform?: string;
    right?: string;
  }>({
    transform: "rotate(135deg)",
    top: "85px",
    right: "-68px",
  });
  const [btnStyles, setBtnStyles] = useState<{
    display?: "none" | "block";
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const [showMessage, setShowMessage] = useState(false);
  const device = useDeviceDetect();

  useEffect(() => {
    if (!cardAnimationEnd) return;

    const getPosition = () => {
      setShowMessage(false);
      if (ref.current && buttonRef.current) {
        const dialogRect = ref.current?.getBoundingClientRect();
        const buttonClientRect = buttonRef.current?.getBoundingClientRect();
        setBtnStyles({
          top: buttonClientRect.top,
          left: buttonClientRect.left,
          display: "none",
        });
        if (device.mobile && buttonClientRect.left <= 376) {
          setPos({
            top:
              buttonClientRect.y -
              buttonClientRect.height -
              dialogRect.height +
              250,
            left: dialogRect.width - buttonClientRect.x - 50,
          });
          setArrowStyles({
            transform: "rotate(315deg)",
            top: "-44px",
            left: "-68px",
          });
        } else {
          setPos({
            top:
              buttonClientRect.y - buttonClientRect.height - dialogRect.height,
            left: buttonClientRect.x - dialogRect.width,
          });
          setArrowStyles({
            transform: "rotate(135deg)",
            top: "85px",
            right: "-68px",
          });
        }
      }
    };

    getPosition();

    window.addEventListener("resize", getPosition);

    return () => window.removeEventListener("resize", getPosition);
  }, [device.mobile, cardAnimationEnd]);

  useEffect(() => {
    if (!cardAnimationEnd) return;

    const timeout = setTimeout(() => {
      setBtnStyles((prevState) => ({ ...prevState, display: "block" }));
      setShowMessage(true);
    }, 300);

    return () => clearTimeout(timeout);
  }, [btnStyles.top, btnStyles.left, cardAnimationEnd]);

  useEffect(() => {
    document.body.classList.add("dashboard-episodes-tour-active");
  }, []);

  const onConfirm = (play: boolean) => {
    document.body.classList.remove("dashboard-episodes-tour-active");
    play ? confirmAndPlay() : confirm();
  };

  return (
    <>
      {buttonRef.current &&
        createPortal(
          <Backdrop open={showMessage} onClick={() => onConfirm(false)}>
            <StyledTourMessage
              ref={ref}
              style={{ top: pos.top, left: pos.left }}
            >
              <StyledTourDialog>
                <StyledTourDialogContent
                  className={
                    "animate__animated animate__pulse animate__infinite"
                  }
                  container
                >
                  <Grid item>
                    <Typography variant="body1" style={{ color: "white" }}>
                      <FormattedMessage id="dashboard.tour.episode.hint" />
                    </Typography>
                  </Grid>
                  <Grid container item justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onConfirm(false)}
                    >
                      OK
                    </Button>
                  </Grid>
                </StyledTourDialogContent>
                <img
                  src={arrow}
                  style={{
                    position: "absolute",
                    zIndex: "-1",
                    ...arrowStyles,
                  }}
                />
              </StyledTourDialog>
            </StyledTourMessage>
            <StyledButton
              style={{ position: "absolute", display: "none", ...btnStyles }}
              variant="contained"
              onClick={() => onConfirm(true)}
            >
              <FormattedMessage id="dashboard.page.play.continue.btn" />
            </StyledButton>
          </Backdrop>,
          document.body
        )}
    </>
  );
};

const Recommended = ({
  nextPlayableEpisode,
  onPlay,
  recommendedEpisodes,
  seasons,
}: {
  nextPlayableEpisode?: Episode;
  onPlay: (episode: Episode) => void;
  recommendedEpisodes: PlayRecommendation[];
  seasons: Season[];
}) => {
  const [episode, setEpisode] = useState<Episode | null>(null);

  const gameEngine = GameEngine();
  const [cardAnimationEnd, setCardAnimationEnd] = useState(false);
  const [seasonTitle, setSeasonTitle] = useState("");
  const [showTip, setShowTip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const ref = useRef<HTMLDivElement>(null);

  const [assetsUrl, setAssetsUrl] = useState("");

  useEffect(() => {
    if (!gameEngine.tourDone && !gameEngine.tourTipDone) {
      setShowTip(true);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (recommendedEpisodes.length > 0) {
      const recommended = recommendedEpisodes[0];
      const season = seasons.find((s) => s.id === recommended.season);
      const episode = season?.episodes.find(
        (e) => e.id === recommended.episode
      );

      if (!episode || !season) return;

      const assetUrl = season?.assetsURL ?? "";

      setAssetsUrl(assetUrl);
      setEpisode(episode);
    } else if (nextPlayableEpisode) {
      const assetUrl = nextPlayableEpisode?.assetsUrl ?? "";

      setAssetsUrl(assetUrl);
      setEpisode(nextPlayableEpisode);
    }
  }, [recommendedEpisodes]);

  useEffect(() => {
    if (episode) {
      setSeasonTitle(
        seasons.find((s) => s.id === episode.seasonId)?.title || ""
      );
    } else {
      setSeasonTitle("");
    }
  }, [episode]);

  const onConfirm = () => {
    setShowTip(false);
    gameEngine.finishTourTip();
  };

  const onConfirmAndPlay = () => {
    onConfirm();

    if (episode) {
      onPlay(episode);
    }
  };

  if (!episode) return null;

  if (!nextPlayableEpisode && recommendedEpisodes.length === 0) return null;

  const getImage = (imageUrl: string) =>
    assetsUrl ? `${assetsUrl}/${imageUrl}` : imageUrl;

  return (
    <Card
      ref={ref}
      sx={{ mb: 2 }}
      onAnimationEnd={() => setCardAnimationEnd(true)}
      className="animate__animated animate__fadeIn"
    >
      <CardMedia image={getImage(episode.imageUrl)} style={{ height: 250 }}>
        <StyledMediaContent
          container
          justifyContent="flex-end"
          direction="column"
        >
          <Typography style={{ fontWeight: "bold" }} variant="h5">
            {seasonTitle}
          </Typography>
          <div>
            <Typography variant="h6" sx={{ my: 1 }}>
              <FormattedMessage
                id="dashboard.page.episode.play"
                values={{
                  episode: episode.index,
                }}
              />
              {episode.title}
            </Typography>
            <StyledButton
              onClick={() => onPlay(episode)}
              variant="contained"
              ref={buttonRef}
            >
              <FormattedMessage id="dashboard.page.play.continue.btn" />
            </StyledButton>
          </div>
        </StyledMediaContent>
        {showTip && buttonRef.current && (
          <TourMessage
            cardAnimationEnd={cardAnimationEnd}
            buttonRef={buttonRef}
            confirm={onConfirm}
            confirmAndPlay={onConfirmAndPlay}
          />
        )}
      </CardMedia>
    </Card>
  );
};

export default Recommended;

import { lexer, parser } from "marked";
import { parse as parseYaml } from "yaml";
import { Answer } from "../../apps/quiz-time/interfaces/answer";
import { Question } from "../../apps/quiz-time/interfaces/question";
export class MarkdownEpisodeBuilder {
  tokens: any[] = [];
  private url: string;
  constructor(params: { url: string; language: string });
  constructor(url: string);
  constructor(params: string | { url: string; language: string }) {
    this.url =
      typeof params === "object"
        ? params.language !== "de"
          ? `${params.url}/script_${params.language}.md`
          : `${params.url}/script.md`
        : params;
  }
  private initOnce!: Promise<void>;
  async init() {
    if (!this.initOnce) {
      this.initOnce = (async () => {
        const res = await fetch(this.url);
        const md = await res.text();
        this.tokens = lexer(md);
      })();
    }
    return this.initOnce;
  }

  quest(index) {
    const titles = this.tokens
      .filter((t) => t.type === "heading" && t.depth === 1)
      .map((t) => t.text.trim());
    return titles[index];
  }

  list(id) {
    const [tokens] = this.findLabel(id);
    const list = tokens.find((t) => t.type === "list");
    if (!list) throw new Error(`List not found under label ${id}`);
    return list.items.map((i) => i.text.trim());
  }

  dialog(id) {
    const [tokens] = this.findLabel(id);
    const dialog: any[] = [];
    tokens.forEach((t) => {
      const { tokens: [player, ...text] = [] } = t;

      if (player && text.length > 0) {
        if (player.type === "strong") {
          const [actor, mood = "happy"] = player.text
            .toLowerCase()
            .trim()
            .split(".");
          dialog.push({
            actor,
            mood,
            next: true,
            message: text
              .filter(
                (t) =>
                  t.type === "text" ||
                  (t.type === "link" && /^mailto:/.test(t.href))
              )
              .map((t) => t.raw)
              .join(""),
            links: text
              .filter((t) => t.type === "link" && !/^mailto:/.test(t.href))
              .map((l) => {
                const image =
                  (l.tokens || []).find((t) => t.type === "image") || {};
                return {
                  title: image?.text || l.text || l.href,
                  url: l.href,
                  image: image?.href || undefined,
                };
              }),
          });
        }
      } else if (player && player.type === "link") {
        const link = player;
        const last = dialog[dialog.length - 1];
        if (last) {
          const links = (last.links = last.links || []);
          links.push({
            title: link.text,
            url: link.href,
          });
        }
      }
    });
    return dialog;
  }

  question(id) {
    const [tokens] = this.findLabel(id);
    const question: Question = {
      id,
      title: "question",
      answers: [],
      type: "checkbox",
    };
    tokens.forEach((t) => {
      if (t.type === "heading") {
        question.title = t.text.trim();
      }
      if (t.type === "paragraph") {
        question.subtitle = t.text.trim();
      }
      if (t.type === "list") {
        question.answers = t.items.map((i) => {
          const answer: Answer = { title: i.text.trim(), correct: i.checked };
          if (
            i.tokens[0] &&
            i.tokens[0].tokens[0] &&
            i.tokens[0].tokens[0].type === "image"
          ) {
            answer.img = i.tokens[0].tokens[0].href;
            answer.title = i.tokens[0].tokens[0].text;
          }
          return answer;
        });
      }
    });

    if (question.answers.length === 0) {
      throw new Error(`No answers defined in question ${id}`);
    } else {
      if (question.answers[0].img) {
        question.type = "grid";
      }
    }

    return question;
  }

  text(id) {
    const [tokens] = this.findLabel(id);
    return tokens[0].text.trim();
  }

  rawHtml(id) {
    const [tokens] = this.findLabel(id);
    return parser(tokens);
  }

  html(id) {
    const [tokens, data] = this.findLabel(id);
    const title = tokens.find((t) => t.type === "heading");
    return {
      title: title && title.text.trim(),
      htmlBody: tokensToHtml(tokens.filter((t) => t !== title)),
      data,
    };
  }

  mail(id, read = false) {
    const [tokens] = this.findLabel(id);
    const title = tokens.find((t) => t.type === "heading");
    const actor = this.strong(tokens);
    return {
      id,
      actor: actor && actor.tokens[0].text.trim().toLowerCase(),
      title: title && title.text.trim(),
      message: parser(tokens.filter((t) => t !== title && t !== actor)),
      read: read,
    };
  }

  notification(id) {
    const [tokens] = this.findLabel(id);
    const title = tokens.find((t) => t.type === "heading").text;
    const buttonLabel = this.strong(tokens).text.replace(/\*/g, "");
    const description = tokens.filter(
      (t) => t.type !== "heading" && t.type !== "strong"
    )[0].text;
    return {
      title,
      description,
      buttonLabel,
    };
  }

  strong(tokensOrId) {
    let tokens = tokensOrId;
    if (typeof tokensOrId === "string") {
      [tokens] = this.findLabel(tokensOrId);
    }
    return tokens.find(
      (t) =>
        t.type === "paragraph" &&
        t.tokens.length === 1 &&
        t.tokens[0].type === "strong"
    );
  }

  choice(id) {
    const [tokens] = this.findLabel(id);
    const title = tokens.find((t) => t.type === "paragraph");
    const list = tokens.find((t) => t.type === "list");
    const useTitle = list.items.every((t) => t.text.match(/:/));

    return {
      title: title?.text ?? null,
      options: list.items.map((t, index) => {
        let title = "";
        let text = t.text.trim();
        if (useTitle) {
          [title, text] = text.split(":");
        }
        return {
          id: index,
          text,
          title,
          correct: t.checked,
        };
      }),
    };
  }

  findQuest(id): [string, string] {
    const _id = id.toLowerCase().trim().replace(/\s+/, "_");
    const startIndex = this.tokens.findIndex(
      (t) =>
        t.type === "blockquote" &&
        t.text.toLowerCase().trim().replace(/\s+/, "_") === _id
    );
    if (startIndex > -1) {
      for (let i = startIndex - 1; i >= 0; i--) {
        const t = this.tokens[i];
        if (t.type === "heading" && t.depth === 1) {
          const [title = "", description = ""] = t.text.trim().split(":");
          return [title, description];
        }
      }
    }
    return ["", ""];
  }

  labels() {
    return this.tokens
      .filter((t) => t.type === "blockquote")
      .map((t) => t.text.trim());
  }

  findLabel(id) {
    const _id = id.toLowerCase().trim().replace(/\s+/, "_");
    const startIndex = this.tokens.findIndex(
      (t) =>
        t.type === "blockquote" &&
        t.text.toLowerCase().trim().replace(/\s+/, "_") === _id
    );
    if (startIndex > -1) {
      let sliceIndex = startIndex + 1;
      const nextToken = this.tokens[sliceIndex];
      let labelData;
      if (nextToken.type === "code" && nextToken.lang === "yaml") {
        labelData = parseYaml(nextToken.text);
        sliceIndex++;
      }
      let tokens = this.tokens.slice(sliceIndex);
      const endIndex = tokens.findIndex(
        (t) =>
          t.type === "blockquote" ||
          t.type === "hr" ||
          (t.type === "heading" && t.depth === 1)
      );
      if (endIndex > -1) {
        tokens = tokens.slice(0, endIndex);
      }
      return [tokens, labelData];
    }
    throw new Error(`Label ${id} not found in markdown file`);
  }
}

export const tokensToHtml = (tokens) => parser(tokens);

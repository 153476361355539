import { Button, Step } from "@mui/material";
import { RefObject, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage, RawIntlProvider } from "react-intl";
import { getIntl } from "../../../i18n";
import { useDeviceDetect, useLanguage } from "../../hooks";
import { setItem } from "../../storage";
import arrow from "./assets/arrow.svg";
import styles from "./dashboard-tour.module.scss";

const DATA_ATTRIBUTE_PREFIX = "data-dashboard-tour=";

type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

type MobilePosition = "bottom-center";

export type Step = {
  dataAttr: string;
  textId: string;
  position: Position;
  mobilePosition: Position | MobilePosition;
  action?: "click";
  timeout?: number;
  checkIfActive?: boolean;
  scrollToTop?: boolean;
  scrollToElement?: boolean;
};

type PropsType = {
  tourData: Step[];
  onFinish: () => any;
  storageKey: string;
  containerDataAttribute: string;
  contentRef?: RefObject<HTMLDivElement>;
};

export const DashboardTour = (props: PropsType) => {
  const { tourData, onFinish, storageKey, containerDataAttribute, contentRef } =
    props;
  const [step, setStep] = useState<Step>();
  const [stepIdx, setStepIdx] = useState(0);
  const language = useLanguage();

  const next = () => {
    if (stepIdx < tourData.length) {
      setStep(tourData[stepIdx]);
      setStepIdx((idx) => idx + 1);
    } else {
      setItem(storageKey, true);
      document.body.classList.remove("dashboard-tour-active");
      onFinish();
    }
  };

  useEffect(() => {
    document.body.classList.add("dashboard-tour-active");
    next();

    return () => {
      document.body.classList.remove("dashboard-tour-active");
    };
  }, []);

  const back = () => {
    if (stepIdx - 2 >= 0) {
      setStep(tourData[stepIdx - 2]);
      setStepIdx((idx) => idx - 1);
    }
  };

  return (
    <>
      {createPortal(
        <RawIntlProvider value={getIntl(language)}>
          <TourMessage
            contentRef={contentRef}
            step={step}
            last={stepIdx === tourData.length}
            first={stepIdx === 1}
            next={next}
            back={back}
            numeration={`${stepIdx}/${tourData.length}`}
            containerDataAttribute={containerDataAttribute}
          />
        </RawIntlProvider>,
        document.body
      )}
    </>
  );
};

type TourMessagePropsType = {
  last: boolean;
  first: boolean;
  step?: Step;
  next: () => void;
  back: () => void;
  numeration: string;
  containerDataAttribute: string;
  contentRef?: RefObject<HTMLDivElement>;
};

const TourMessage = (props: TourMessagePropsType) => {
  const {
    first,
    last,
    step,
    numeration,
    next,
    back,
    containerDataAttribute,
    contentRef,
  } = props;
  const [internalStep, setInternalStep] = useState<Step>();
  const [opacity, setOpacity] = useState(0);
  const device = useDeviceDetect();
  const ref = useRef<any>();
  const dialogRef = useRef<any>();
  const imageRef = useRef<any>();
  const [rootStyles, setRootStyles] = useState<{
    top?: string;
    left?: string;
  }>({ top: "0", left: "0" });
  const [arrowStyles, setArrowStyles] = useState<{
    top?: string;
    left?: string;
    transform?: string;
  }>({ top: "0", left: "0", transform: "" });
  const [scrollTimeStamp, setScrollTimeStamp] = useState(0);

  const getPosition = (rootEl: HTMLElement, dialogEl: HTMLElement) => {
    const rects = rootEl.getBoundingClientRect();
    const dialogRects = dialogEl.getBoundingClientRect();

    const position =
      device.mobile && internalStep?.mobilePosition
        ? internalStep.mobilePosition
        : internalStep?.position;

    const onTop = position === "top-left" || position === "top-right";
    const onLeft = position === "top-left" || position === "bottom-left";

    let top = onTop
      ? rects.top - dialogRects.height - 25
      : rects.top + rects.height + 25;

    const left = onLeft
      ? rects.left + 90
      : rects.left + rects.width - dialogRects.width - 70;

    if (device.mobile) {
      top = onTop
        ? rects.top - dialogRects.height - 45
        : rects.top + rects.height + 45;

      return {
        top: `${top}px`,
      };
    }

    return {
      top: `${top}px`,
      left: `${
        left + dialogRects.width + 75 > document.body.clientWidth
          ? document.body.clientWidth - dialogRects.width - 120
          : left
      }px`,
    };
  };

  const getArrowPosition = (dialogEl: HTMLElement) => {
    const rects = dialogEl.getBoundingClientRect();

    const position =
      device.mobile && internalStep?.mobilePosition
        ? internalStep.mobilePosition
        : internalStep?.position;

    let top = "";
    let left = "";
    let transform = "";

    if (device.mobile) {
      switch (position as Position & MobilePosition) {
        case "top-left":
          top = `-25px`;
          left = `0px`;
          transform = `rotate(170deg) scaleX(-1) scale(0.8)`;
          break;
        case "top-right":
          top = `-25px`;
          left = `${rects.width - 70}px`;
          transform = `rotate(160deg) scale(0.6)`;
          break;
        case "bottom-left":
          top = `-${rects.height + 70}px`;
          transform = `scale(0.6)`;
          break;
        case "bottom-right":
          top = `-${rects.height + 70}px`;
          left = `${rects.width - 140}px`;
          transform = `rotate(40deg) scaleX(-1) scale(0.6)`;
          break;
        case "bottom-center":
          top = `-${rects.height * 1.5}px`;
          left = `${rects.width / 2 - 50}px`;
          transform = `rotate(15deg) scaleX(-1) scale(0.6)`;
          break;
      }

      return {
        top,
        left,
        transform,
      };
    }

    switch (position) {
      case "top-left":
        top = `${-(rects.height / 2.8)}px`;
        left = "-60px";
        transform = `rotate(215deg) scaleX(-1)`;
        break;
      case "top-right":
        top = `${-(rects.height / 3.4)}px`;
        left = `${rects.width - 10}px`;
        transform = `rotate(140deg)`;
        break;
      case "bottom-left":
        top = `${-rects.height - 52}px`;
        left = `-66px`;
        transform = `rotate(-45deg)`;
        break;
      case "bottom-right":
        top = `-${rects.height * 1.3}px`;
        left = `${rects.width - 10}px`;
        transform = `rotate(40deg) scaleX(-1)`;
        break;
    }

    return {
      top,
      left,
      transform,
    };
  };

  const positionElements = () => {
    if (!internalStep || !dialogRef?.current) return;

    const container = document.body.querySelector(
      `[data-${containerDataAttribute}]`
    );

    if (internalStep.scrollToTop) {
      if (container) {
        container.scrollTop = 0;
      }
    }

    const el = document.body.querySelector(
      `[${DATA_ATTRIBUTE_PREFIX}${internalStep.dataAttr}]`
    ) as HTMLElement;

    if (internalStep.scrollToElement && el) {
      el.scrollIntoView();
    }

    if (internalStep.action && internalStep.action === "click") {
      if (internalStep.checkIfActive) {
        const color = window
          .getComputedStyle(el, null)
          .getPropertyValue("background-color");

        if (color.replaceAll(" ", "") === "rgb(255,255,255)") {
          el.click();
        }
      }
    }

    setTimeout(() => {
      const el = document.body.querySelector(
        `[${DATA_ATTRIBUTE_PREFIX}${internalStep.dataAttr}]`
      ) as HTMLElement;

      if (!el) {
        next();
        return;
      }

      setRootStyles(getPosition(el, dialogRef.current));
      setArrowStyles(getArrowPosition(dialogRef.current));

      setTimeout(
        () => {
          setOpacity(1);
        },
        internalStep.timeout ? 0 : 300
      );
    }, internalStep.timeout || 0);
  };

  ref.current = {
    positionElements,
  };

  useEffect(() => {
    setOpacity(0);

    const timeout = setTimeout(() => {
      setInternalStep(step);
    }, 300);

    return () => clearTimeout(timeout);
  }, [step]);

  useEffect(() => {
    if (!contentRef?.current) return;

    const onScroll = (event) => {
      setScrollTimeStamp(event.timeStamp);
    };

    contentRef.current.addEventListener("scroll", onScroll);

    return () => {
      contentRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (!internalStep || !dialogRef?.current) return;

    const timeout = setTimeout(() => {
      positionElements();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [internalStep, dialogRef, scrollTimeStamp]);

  useEffect(() => {
    const onResize = () => {
      ref.current.positionElements();
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <div className={styles.root} style={{ opacity, ...rootStyles }}>
      <div ref={dialogRef} className={styles.dialog}>
        <p className={styles.header}>
          {internalStep?.textId && (
            <FormattedMessage id={`${internalStep?.textId}.header`} />
          )}
        </p>
        <p className={styles.text}>
          {internalStep?.textId && (
            <FormattedMessage id={internalStep?.textId} />
          )}
        </p>
        <div className={styles.footer}>
          <p className={styles.numeration}>{numeration}</p>
          <div className={styles.buttonRow}>
            {first || (
              <p className={styles.backButton} onClick={back}>
                {"<<"}
              </p>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={next}
              data-e2e-dashboard-tour-next-button
            >
              <FormattedMessage
                id={
                  last
                    ? "dashboard.tour.support-text-finish"
                    : "dashboard.tour.support-text"
                }
              />
            </Button>
          </div>
        </div>
      </div>
      <img
        ref={imageRef}
        src={arrow}
        className={styles.arrow}
        style={{ ...arrowStyles }}
      />
    </div>
  );
};

import { Message } from "../../message-hub/interfaces";
import { Rule } from "./interfaces";

export const DOCUMENT_EDITOR_GENERATE_ID = "doc-editor.generate-id";
export const DOCUMENT_EDITOR_SET_INITIAL_CONTENT =
  "doc-editor.set-initial-content";
export const DOCUMENT_EDITOR_LOAD_FILE = "doc-editor.load-file";
export const DOCUMENT_EDITOR_SET_RULES = "doc-editor.set.rules";
export const DOCUMENT_EDITOR_SET_INVITE_URL = "doc-editor.set.invite.url";
export const DOCUMENT_EDITOR_OPENED = "doc-editor.opened";
export const DOCUMENT_EDITOR_CONTRIBUTOR_EDITED =
  "doc-editor.contributor.edited";
export const DOCUMENT_EDITOR_CONTRIBUTOR_JOINED =
  "doc-editor.contributor.joined";
export const DOCUMENT_EDITOR_PLAYER_EDITED = "doc-editor.player.edited";
export const DOCUMENT_EDITOR_SUBMIT = "doc-editor.submit";
export const DOCUMENT_EDITOR_SET_MAIN_PLAYER = "doc-editor.set-main-player";
export const DOCUMENT_EDITOR_SET_LANG = "doc-editor.set-lang";

export type documentEditorGenerateId = Message<
  typeof DOCUMENT_EDITOR_GENERATE_ID,
  {
    docId: string;
  }
>;

export type documentEditorSetInitialContent = Message<
  typeof DOCUMENT_EDITOR_SET_INITIAL_CONTENT,
  {
    content: string;
  }
>;

export type documentEditorLoadFile = Message<
  typeof DOCUMENT_EDITOR_LOAD_FILE,
  {
    path: string;
  }
>;

export type documentEditorSetRules = Message<
  typeof DOCUMENT_EDITOR_SET_RULES,
  {
    rules: Rule[];
  }
>;

export type documentEditorSetInviteUrl = Message<
  typeof DOCUMENT_EDITOR_SET_INVITE_URL,
  {
    url: string;
  }
>;

export type documentEditorOpened = Message<
  typeof DOCUMENT_EDITOR_OPENED,
  undefined
>;

export type documentEditorContributorEdited = Message<
  typeof DOCUMENT_EDITOR_CONTRIBUTOR_EDITED,
  undefined
>;

export type documentEditorPlayerEdited = Message<
  typeof DOCUMENT_EDITOR_PLAYER_EDITED,
  undefined
>;

export type documentEditorContributorJoined = Message<
  typeof DOCUMENT_EDITOR_CONTRIBUTOR_JOINED,
  undefined
>;

export type documentEditorSubmit = Message<
  typeof DOCUMENT_EDITOR_SUBMIT,
  Pick<Rule, "checked">[]
>;

export type documentEditorSetMainPlayer = Message<
  typeof DOCUMENT_EDITOR_SET_MAIN_PLAYER,
  boolean
>;

export type documentEditorSetLang = Message<
  typeof DOCUMENT_EDITOR_SET_LANG,
  { lang: "de" | "en" }
>;

export const messagesTypes = {
  DOCUMENT_EDITOR_CONTRIBUTOR_EDITED,
  DOCUMENT_EDITOR_CONTRIBUTOR_JOINED,
  DOCUMENT_EDITOR_LOAD_FILE,
  DOCUMENT_EDITOR_OPENED,
  DOCUMENT_EDITOR_PLAYER_EDITED,
  DOCUMENT_EDITOR_SET_INITIAL_CONTENT,
  DOCUMENT_EDITOR_SET_INVITE_URL,
  DOCUMENT_EDITOR_SET_MAIN_PLAYER,
  DOCUMENT_EDITOR_SET_RULES,
  DOCUMENT_EDITOR_SUBMIT,
  DOCUMENT_EDITOR_SET_LANG,
} as const;

export type documentEditorMessages =
  | documentEditorContributorEdited
  | documentEditorContributorJoined
  | documentEditorGenerateId
  | documentEditorLoadFile
  | documentEditorOpened
  | documentEditorPlayerEdited
  | documentEditorSetInitialContent
  | documentEditorSetInviteUrl
  | documentEditorSetMainPlayer
  | documentEditorSetRules
  | documentEditorSubmit
  | documentEditorSetLang;

import { useEffect, useState } from "react";
import GameEngine from "../../game-engine";
import { getReportData } from "../client";

export const useUserScore = () => {
  const allBadges = GameEngine().badgesEngine.getAllBadges();
  const [competencePoints, setCompetencePoints] = useState<{
    totalPassed: number;
    totalTried: number;
  }>();
  const [badges, setBadges] = useState<{
    obtained: number;
    total: number;
  }>();
  const engagementPoints = GameEngine().engagementEngine.getAll().points;

  useEffect(() => {
    getReportData().then((result) => {
      if (Array.isArray(result)) {
        const competenceScore = result.reduce(
          (acc, curr) => {
            acc.totalPassed += curr.totalPassed;
            acc.totalTried += curr.totalTried;
            return acc;
          },
          { totalPassed: 0, totalTried: 0 }
        );
        setCompetencePoints(competenceScore);
      } else {
        setCompetencePoints({ totalPassed: 0, totalTried: 0 });
      }
      setBadges({
        obtained: allBadges.filter((b) => b.obtained).length,
        total: allBadges.length,
      });
    });
  }, []);

  return { competencePoints, badges, engagementPoints };
};

import { Message as MessageHubMessage } from "../../message-hub/interfaces";

export const COFFEE_GAME_DISPLAY = "coffeeGame.display";
export const COFFEE_GAME_SET_SCORE = "coffeeGame.set.score";
export const COFFEE_GAME_SET_SHOW_LOCATION = "coffeeGame.set.show.location";

export type coffeeGameAnswer = MessageHubMessage<
  typeof COFFEE_GAME_SET_SCORE,
  { score: number }
>;

export type coffeeGameDisplay = MessageHubMessage<
  typeof COFFEE_GAME_DISPLAY,
  { visible: boolean }
>;

export type coffeeGameSetShowLocation = MessageHubMessage<
  typeof COFFEE_GAME_SET_SHOW_LOCATION,
  { showLocation: string }
>;

export type coffeeGameMessage =
  | coffeeGameAnswer
  | coffeeGameDisplay
  | coffeeGameSetShowLocation;

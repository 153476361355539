import {
  EpisodeMaturity,
  SeasonDefinition,
} from "../../game-engine/interfaces";

export const season: SeasonDefinition = {
  id: "97",
  completed: false,
  index: 1,
  title: {
    en: "When ideas learn to fly",
    de: "Wenn Ideen fliegen lernen",
  },
  info: {
    de: "Als renommierte:r Professor:in begleitest du deine Kollegin Tina bei dem Vorhaben, eine Start-Up Idee einer Gruppe Studierender erfolgreich umzusetzen. Dabei werden deine Fähigkeiten immer wieder auf die Probe gestellt… Gelingt es dir, die Unternehmung zum Erfolg zu führen?",
    en: "As a renowned professor, you accompany your colleague Tina in her project to successfully implement a start-up idea for a group of students. Your skills are put to the test time and again... Will you be able to lead the venture to success?",
  },
  topics: [
    {
      competenceAreaId: 1,
      description: {
        de: "Wie du mit Daten arbeitest und umgehst.",
        en: "How you can work with data.",
      },
    },
    {
      competenceAreaId: 5,
      description: {
        de: "Wie Cloud-Tools deine Arbeit erleichtern.",
        en: "How cloud tools simplify your work.",
      },
    },
    {
      competenceAreaId: 1,
      description: {
        de: "Wie du Suchmaschinen und -funktionen effizient verwendest.",
        en: "How you can efficiently use search engines and their functionality.",
      },
    },
  ],
  certificates: [],
  seasonEndMessage: {
    de: `Cloud-Tools, Suchmaschinen und Daten… In dieser Staffel ist einiges passiert. Und der Abschluss der Geschichte ist nun wirklich eine Überraschung… Wie gewöhnlich kannst du die gesamte Staffel oder einzelne Episoden so oft spielen, wie du willst. Sammle weitere Abzeichen, Punkte und Diamanten oder vertiefe dein Wissen, indem du den News-Bereich erkundest.`,
    en: `Cloud tools, search engines and data... A lot has happened in this season. And the conclusion of the story is a real surprise... As usual, you can play the entire season or individual episodes as many times as you like. Collect more badges, points and diamonds or deepen your knowledge by exploring the news section.`,
  },
  episodes: [
    {
      id: "s97e01",
      title: {
        de: "Hürden der Innovation",
        en: "Barriers to innovation",
      },
      maturity: "PUBLIC" as EpisodeMaturity,
      description: {
        de: "Du verpflichtest dich dazu, dich bei der Entwicklung einer neuen Innovation zu beteiligen. Dabei triffst du auf ungeplante Herausforderungen. Schaffst du es, diese zu lösen?",
        en: "You commit to participating in the development of a new innovation. In doing so, you will encounter unplanned challenges. Will you be able to solve them?",
      },
      imageUrl: "/episodes/s01-e07/images/secret_labor.svg",
      url: "/episodes/s97-e01/",
      load: async () => import(/* webpackChunkName: "s97-e01" */ `../s97-e01`),
    },
  ].map((e, index) => ({ ...e, index, seasonId: "97" })),
};

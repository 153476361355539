import { useLanguage } from "core/hooks";
import { MissionTarget } from "core/interfaces";
import {
  getGroupsByParentIds,
  getParentGroups,
  getProfileTagsByType,
} from "../../../../../onboarding/player/services";
import { getCompetencesTree, http } from "../../../../client";
import { CompetenceLevel, whatScope, whoScope } from "../../../../enums";
import { GroupOption } from "./group-select";

export type CompetenceOption = GroupOption & {
  type: whatScope | whoScope;
};

// TODO remove that when the backend is fixed see https://talentdigital.atlassian.net/browse/TI-2187
export const getName = (name: unknown) => {
  const isJsonString = (value: unknown) => {
    if (typeof value !== "string") return false;
    if (!isNaN(Number(value))) return false;

    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  };

  if (isJsonString(name) && typeof name === "string") {
    const json = JSON.parse(name);
    if (json[useLanguage()]) return json[useLanguage()];
    if (json["de"]) return json["de"];
    return json[Object.keys(json)[0]];
  }

  return String(name);
};

export async function getCompetenceData() {
  const [functions, departments, competences] = await Promise.all([
    getProfileTagsByType("Funktion"),
    getParentGroups(),
    getCompetencesTree(),
  ]);

  const groups = await getGroupsByParentIds(departments.map(({ id }) => id));

  const departmentOptions = departments.map(({ id, name }) => ({
    id,
    name,
    type: whoScope.Department,
    items: groups
      .filter(({ talentGroupParentId }) => id === talentGroupParentId)
      .map(({ id, name }) => ({
        id,
        name: getName(name),
        type: whoScope.Team,
      })),
  }));

  const functionsOptions = functions.map(({ id, name }) => ({
    id,
    name: getName(name),
    type: whoScope.Function,
  }));

  const competenceOptions = competences.map(
    ({ id, name, competences, testsAvailable }) => ({
      id,
      name: getName(name),
      disabled: testsAvailable !== true,

      type: whatScope.CompetenceArea,
      items: competences.map(
        ({ id, name, subCompetences, testsAvailable }) => ({
          id,
          name: getName(name),
          disabled: testsAvailable !== true,

          type: whatScope.Competence,
          items: subCompetences.map(({ id, name, testsAvailable }) => ({
            id,
            name: getName(name),
            type: whatScope.SubCompetence,
            disabled: testsAvailable !== true,
          })),
        })
      ),
    })
  );

  return {
    competenceHierarchy: [
      { id: 0, type: whatScope.All, name: "All", items: [] },
      ...competenceOptions,
    ] as CompetenceOption[],
    competenceScopes: [
      { id: 0, type: whoScope.All, name: "All", items: [] },
      ...departmentOptions,
      ...functionsOptions,
    ] as CompetenceOption[],
  };
}

export const getTargets = async (): Promise<MissionTarget[]> => {
  try {
    const res = await http.get("v1/profile2/missions/target");

    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const createTarget = async (
  scope: CompetenceOption,
  competence: CompetenceOption,
  level: CompetenceLevel
): Promise<MissionTarget[]> => {
  const getLevel = (level: CompetenceLevel) => {
    switch (level) {
      case CompetenceLevel.Foundation:
        return 1;
      case CompetenceLevel.Intermediate:
        return 2;
      case CompetenceLevel.Advanced:
        return 3;
      case CompetenceLevel.HighlySpecialised:
        return 4;
      default:
        return 1;
    }
  };

  const target: MissionTarget = {
    department: scope.type === "DEPARTMENT" ? scope.id : undefined,
    team: scope.type === "TEAM" ? scope.id : undefined,
    function: scope.type === "FUNCTION" ? scope.id : undefined,
    competenceArea:
      competence.type === "COMPETENCE_AREA" ? competence.id : undefined,
    competence: competence.type === "COMPETENCE" ? competence.id : undefined,
    subCompetence:
      competence.type === "SUB_COMPETENCE" ? competence.id : undefined,
    level: getLevel(level),
  };

  try {
    const res = await http.post("v1/profile2/missions/target", {
      json: target,
    });

    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const removeTarget = async (id: string): Promise<MissionTarget[]> => {
  try {
    const res = await http.delete(`v1/profile2/missions/target/${id}`);

    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
};

import { makeStyles } from "@mui/styles";
import { AnimationEvent } from "react";
import MessageHub from "../../../message-hub";
import { REWIND_ANIMATION_STOP } from "../../messages";

const styles = makeStyles(() => ({
  "@keyframes animateHour": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(-500deg)",
    },
  },
  "@keyframes animateMinute": {
    "0%": {
      transform: "rotate(45deg)",
    },
    "100%": {
      transform: "rotate(-720deg)",
    },
  },
  root: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    background: "#18424A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 900000000,
  },
  container: {
    position: "relative",
    width: 350,
    height: 350,
    borderRadius: "50%",
    background: "#7A9A81",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clock: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  dot: {
    position: "absolute",
    height: 35,
    width: 35,
    borderRadius: "50%",
    background: "#000",
    zIndex: 1,
  },
  hourContainer: {
    position: "relative",
    height: "inherit",
    width: "inherit",
    borderRadius: "50%",
    animation: "$animateHour 3s forwards",
  },
  hour: {
    position: "absolute",
    top: "-85px",
    left: 10,
  },
  minuteContainer: {
    position: "relative",
    height: "inherit",
    width: "inherit",
    borderRadius: "50%",
    animation: "$animateMinute 3s forwards",
  },
  minute: {
    position: "absolute",
    top: "-120px",
    left: 10,
  },
}));

const RewindAnimation = () => {
  const classes = styles();

  const onAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    MessageHub.send({
      type: REWIND_ANIMATION_STOP,
      payload: undefined,
    });
  };

  return (
    <div className={classes.root} onAnimationEnd={onAnimationEnd}>
      <div className={classes.container}>
        <div className={classes.clock}>
          <svg width="350" height="350" viewBox="0 0 350 350" fill="none">
            <circle
              cx="175"
              cy="175"
              r="171"
              fill="#7A9A81"
              stroke="white"
              strokeWidth="5"
              strokeDasharray="30 30"
            />
          </svg>
        </div>
        <div className={classes.dot}>
          <div className={classes.hourContainer}>
            <div className={classes.hour}>
              <svg width="15" height="78" viewBox="0 0 15 78" fill="none">
                <path
                  d="M5.8533 0.0488281H10.7935L14.087 77.4456H0.913086L5.8533 0.0488281Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.dot}>
          <div className={classes.minuteContainer}>
            <div className={classes.minute}>
              <svg width="15" height="113" viewBox="0 0 15 113" fill="none">
                <path
                  d="M0.449665 0.613178L5.38346 0.361366L14.3934 112.28L1.23658 112.952L0.449665 0.613178Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewindAnimation;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    position: "relative",
  },
  title: {
    fontSize: 18,
    color: "white",
    background: "transparent",
  },
  text: {
    fontWeight: "bold",
    textAlign: "center",
  },
  progress: {
    backgroundColor: "white",
    borderRadius: 6,
    height: 14,
    width: "100%",
  },
  bar: {
    borderRadius: 6,
    height: 14,
    backgroundColor: "#08b79c",
    transition: "width 1s ease-in-out",
  },
  checkCircle: {
    color: "#08b79c",
    minWidth: 40,
  },
  listItemActive: {
    cursor: "pointer",
  },
  listItemActiveText: {
    textDecoration: "underline",
    fontSize: 14,
  },
  listItem: {
    fontSize: 14,
    color: "rgba(255, 255, 255, 0.87)",
  },
}));

export default styles;

import { ArrowForwardIos, Check } from "@mui/icons-material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slider,
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getLanguage, SUPPORTED_LANGUAGES } from "../../i18n";
import MessageHub from "../../message-hub";
import { VIDEO_FINISH } from "../messages";
import styles from "./video.styles";

const VOLUME_SETTINGS_STORAGE_KEY = "VIDEO_PLAYER_VOLUME_GLOBAL_SETTINGS";

const POWER_VALUES = [0.5, 0.75, 1, 1.25, 1.5];

interface VideoProps {
  src: string;
  type?: string;
  onVideoEnd?: () => void;
}

const Video: React.FC<VideoProps> = ({
  src,
  type = "video/mp4",
  onVideoEnd,
}: VideoProps) => {
  const classes = styles();
  const videoRef = createRef<HTMLVideoElement>();
  const [state, setState] = useState<"play" | "pause">("play");
  const [volumeOff, setVolumeOff] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const lastMove = useRef<number>(0);
  const language = getLanguage();
  const [videoTimestamp, setVideoTimestamp] = useState(0);
  const [videoSpeed, setVideoSpeed] = useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  if (!lastMove.current) {
    lastMove.current = Date.now();
  }

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.playbackRate = videoSpeed;
  }, [videoSpeed]);

  const onEndVideo = () => {
    MessageHub.send({
      type: VIDEO_FINISH,
      payload: {
        src,
        state: false,
      },
    });
    if (onVideoEnd) onVideoEnd();
  };

  const onPlayPause = () => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    if (state === "play") {
      videoRef.current.pause();
      setState("pause");
    } else {
      videoRef.current.play();
      setState("play");
    }
  };

  const onChangeVolume = (value: number, type: string) => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    videoRef.current.volume = value;

    localStorage.setItem(VOLUME_SETTINGS_STORAGE_KEY, `${value}`);

    if (value === 0 && type !== "mute") {
      setVolume(value);
      setVolumeOff(true);
    } else if (value === 0 && type === "mute") {
      setVolumeOff(true);
    } else if (value > 0) {
      setVolume(value);
      setVolumeOff(false);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      const volume = localStorage.getItem(VOLUME_SETTINGS_STORAGE_KEY);

      if (volume) {
        onChangeVolume(parseFloat(volume), "changeVolume");
      }

      videoRef.current.play().then(
        () => setState("play"),
        () => setState("pause")
      );
    }
  }, [videoRef.current]);

  const onTimeUpdate = () => {
    if (!videoRef.current) return;

    const video = videoRef.current;
    const percentage = (video.currentTime / video.duration) * 100;

    setVideoTimestamp(percentage);
  };

  const updateVideoTime = (_: any, newValue: number | number[]) => {
    if (!videoRef.current) return;

    const video = videoRef.current;
    const newVideoTime = (video.duration * (newValue as number)) / 100;

    video.currentTime = newVideoTime;
  };

  const createSubtitleSrc = (lang: string) => {
    const extensions = ["mov", "avi", "wmv", "flv", "3gp", "mp4", "mpg"];

    return src.replace(new RegExp(`\.${extensions.join("|")}`), `${lang}.vtt`);
  };

  const onClickVideo = () => {
    onPlayPause();
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const changePower = (event, level: number) => {
    setVideoSpeed(level);
    handleClose(event);
  };

  return (
    <div className={classes.videoContainer}>
      <link rel="preload" href={src} as="video" type={type} />
      <video
        ref={videoRef}
        id="video"
        src={src}
        className={classes.video}
        autoPlay
        preload="metadata"
        onTimeUpdate={onTimeUpdate}
        onEnded={onEndVideo}
        onClick={onClickVideo}
        playsInline
      >
        {SUPPORTED_LANGUAGES.map((lang) => (
          <track
            key={lang}
            label={lang}
            kind="subtitles"
            srcLang={lang}
            src={createSubtitleSrc(lang)}
            default={language === lang}
          ></track>
        ))}
      </video>
      <div className={classes.controlsContainer}>
        <div className={classes.controls}>
          <IconButton onClick={onPlayPause} size="large">
            {state === "play" ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton data-e2e-skip-button onClick={onEndVideo} size="large">
            <SkipNextIcon />
          </IconButton>
          <div className={classes.sliderContainer}>
            <Slider
              defaultValue={0}
              value={videoTimestamp}
              aria-label="Default"
              valueLabelDisplay="auto"
              classes={{
                root: classes.sliderRoot,
                rail: classes.rail,
                track: classes.track,
                thumb: classes.thumb,
              }}
              onChange={updateVideoTime}
            />
          </div>
          <div className={classes.volumeManageContainer}>
            <IconButton
              onClick={() => {
                onChangeVolume(volumeOff ? volume : 0, "mute");
              }}
              size="large"
            >
              {volumeOff ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
            <input
              type="range"
              id="volume-bar"
              title="volume"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={(e) =>
                onChangeVolume(Number(e.target.value), "changeVolume")
              }
            />
            <Button
              ref={anchorRef}
              aria-haspopup="true"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              className={classes.speedButton}
            >
              <FormattedMessage id="global.video.playbackSpeed" />: x
              {videoSpeed}
              &nbsp;
              <ArrowForwardIos fontSize="small" />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        onKeyDown={handleListKeyDown}
                        classes={{
                          root: classes.speedList,
                        }}
                      >
                        {POWER_VALUES.map((x, i) => (
                          <MenuItem
                            onClick={(event) => changePower(event, x)}
                            key={i}
                          >
                            <Check
                              className={classes.speedListItemIcon}
                              style={{
                                visibility:
                                  videoSpeed === x ? "visible" : "hidden",
                              }}
                            />
                            {x}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;

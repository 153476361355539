import { EventAppointment } from "./interfaces/event-appointment";
import { EventTemplate } from "./interfaces/event-template";

export const MEETINGS_TOOL_BROWSER_ADD_EVENT_TEMPLATE =
  "meetings.tool.browser.add.event.template";
export const MEETINGS_TOOL_BROWSER_CREATE_EVENT =
  "meetings.tool.browser.create.event";

export interface AddEvent {
  type: typeof MEETINGS_TOOL_BROWSER_ADD_EVENT_TEMPLATE;
  payload: EventTemplate;
}

export interface CreateEvent {
  type: typeof MEETINGS_TOOL_BROWSER_CREATE_EVENT;
  payload: EventAppointment;
}

export type MeetingsToolBrowserMessages = AddEvent | CreateEvent;

export const messageTypes = {
  MEETINGS_TOOL_BROWSER_ADD_EVENT_TEMPLATE,
  MEETINGS_TOOL_BROWSER_CREATE_EVENT,
} as const;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    width: 350,
    height: 25,
    borderRadius: 20,
    backgroundColor: "#FFFFFF7A",
    border: "2px solid white",
    marginLeft: 20,
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 3vw",
    },
  },
  gauge: {
    borderRadius: 20,
    backgroundColor: "#EF3D61",
    height: "100%",
    minWidth: 30,
    transition: theme.custom.ANIMATIONS_ENABLED ? "all 1s ease-in-out" : "",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& p": {
      margin: 0,
      padding: 0,
      marginRight: 10,
      lineHeight: "1px",
      color: "white",
    },

    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: 9,
        marginRight: 5,
      },
    },
  },
  emptyText: {
    margin: 0,
    padding: 0,
    lineHeight: "1px",
    color: "white",
    marginLeft: 10,
  },
}));

export const drawCoin = (ctx) => {
  ctx.save();
  ctx.strokeStyle = "rgba(0,0,0,0)";
  ctx.miterLimit = 4;
  ctx.font = "15px / 21.4286px ''";
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.font = "   15px ";
  ctx.scale(0.7843137254901962, 0.7843137254901962);
  ctx.save();
  ctx.fillStyle = "#A66300";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(95.7049, 54.0137);
  ctx.bezierCurveTo(99.1511, 32.4637, 84.475, 12.2004, 62.9251, 8.75422);
  ctx.bezierCurveTo(41.3751, 5.30806, 21.1118, 19.9841, 17.6656, 41.5341);
  ctx.bezierCurveTo(14.2194, 63.084, 28.8955, 83.3474, 50.4454, 86.7935);
  ctx.bezierCurveTo(71.9954, 90.2397, 92.2588, 75.5637, 95.7049, 54.0137);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#804E04";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(93.1257, 32.4756);
  ctx.lineTo(81.625, 31.438);
  ctx.lineTo(83.0086, 44.1493);
  ctx.lineTo(95.9793, 44.8411);
  ctx.bezierCurveTo(95.8064, 40.431, 94.7687, 36.2804, 93.1257, 32.4756);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#804E04";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(74.6202, 65.3345);
  ctx.lineTo(72.5449, 71.6469);
  ctx.lineTo(84.651, 75.7111);
  ctx.bezierCurveTo(87.2451, 73.1169, 89.4934, 70.1769, 91.3093, 66.9774);
  ctx.lineTo(74.6202, 65.3345);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FFBC26";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(86.3995, 56.707);
  ctx.bezierCurveTo(91.3166, 35.4429, 78.0648, 14.2189, 56.8008, 9.3017);
  ctx.bezierCurveTo(35.5367, 4.38454, 14.3127, 17.6363, 9.3955, 38.9004);
  ctx.bezierCurveTo(4.47834, 60.1644, 17.7301, 81.3885, 38.9942, 86.3057);
  ctx.bezierCurveTo(60.2582, 91.2228, 81.4823, 77.971, 86.3995, 56.707);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FFC673";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(17.8086, 73.377);
  ctx.bezierCurveTo(20.8351, 76.9223, 24.4669, 79.9489, 28.5311, 82.1971);
  ctx.lineTo(80.933, 25.9905);
  ctx.bezierCurveTo(77.9064, 21.4075, 73.9287, 17.4298, 69.2593, 14.4897);
  ctx.lineTo(17.8086, 73.377);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FAFFF3";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(9.76758, 37.491);
  ctx.lineTo(26.9755, 40.9498);
  ctx.lineTo(37.525, 27.2008);
  ctx.lineTo(32.7691, 11.29);
  ctx.bezierCurveTo(21.5277, 15.9595, 12.967, 25.6443, 9.76758, 37.491);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FF9900";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(78.8588, 23.2231);
  ctx.lineTo(21.0957, 76.8356);
  ctx.bezierCurveTo(28.1864, 83.4075, 37.6118, 87.3852, 47.9884, 87.3852);
  ctx.bezierCurveTo(69.7793, 87.2987, 87.4195, 69.6585, 87.4195, 47.7811);
  ctx.bezierCurveTo(87.4195, 38.5286, 84.2201, 29.9679, 78.8588, 23.2231);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#BD7100";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(75.6895, 54.2682);
  ctx.bezierCurveTo(79.2591, 38.9181, 69.7091, 23.5807, 54.3591, 20.0111);
  ctx.bezierCurveTo(39.009, 16.4415, 23.6716, 25.9915, 20.102, 41.3415);
  ctx.bezierCurveTo(16.5324, 56.6916, 26.0824, 72.029, 41.4324, 75.5986);
  ctx.bezierCurveTo(56.7825, 79.1682, 72.1199, 69.6182, 75.6895, 54.2682);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FF9A14";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(54.818, 25.4704);
  ctx.bezierCurveTo(38.6478, 25.1245, 25.2446, 38.5276, 25.5905, 54.6979);
  ctx.bezierCurveTo(25.7635, 60.6644, 27.7523, 66.2851, 31.0383, 70.7816);
  ctx.bezierCurveTo(31.1247, 70.8681, 31.1247, 70.8681, 31.2112, 70.9545);
  ctx.bezierCurveTo(35.7942, 74.2405, 41.3284, 76.2293, 47.2949, 76.4023);
  ctx.bezierCurveTo(63.3787, 76.6617, 76.7818, 63.2586, 76.4359, 47.1748);
  ctx.bezierCurveTo(76.263, 41.2083, 74.2741, 35.5876, 70.9882, 31.0911);
  ctx.bezierCurveTo(70.9017, 31.0046, 70.9017, 31.0046, 70.8152, 30.9181);
  ctx.bezierCurveTo(66.3187, 27.6322, 60.7845, 25.6434, 54.818, 25.4704);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#D48014";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(76.4369, 46.6572);
  ctx.bezierCurveTo(76.4369, 46.4842, 76.4369, 46.3113, 76.4369, 46.2248);
  ctx.bezierCurveTo(76.4369, 45.9654, 76.4369, 45.7924, 76.4369, 45.533);
  ctx.bezierCurveTo(76.4369, 45.3601, 76.4369, 45.1871, 76.3504, 44.9277);
  ctx.bezierCurveTo(76.3504, 44.7548, 76.3504, 44.5818, 76.264, 44.3224);
  ctx.bezierCurveTo(76.264, 44.063, 76.1775, 43.8901, 76.1775, 43.6306);
  ctx.bezierCurveTo(76.1775, 43.4577, 76.1775, 43.2848, 76.091, 43.1983);
  ctx.bezierCurveTo(76.091, 42.9389, 76.0046, 42.7659, 76.0046, 42.5065);
  ctx.bezierCurveTo(76.0046, 42.3336, 75.9181, 42.2471, 75.9181, 42.0741);
  ctx.bezierCurveTo(75.8316, 41.8147, 75.8316, 41.5553, 75.7451, 41.3824);
  ctx.bezierCurveTo(75.7451, 41.2959, 75.6587, 41.123, 75.6587, 41.0365);
  ctx.bezierCurveTo(75.5722, 40.7771, 75.4857, 40.5177, 75.4857, 40.2582);
  ctx.bezierCurveTo(75.4857, 40.1718, 75.3993, 40.0853, 75.3993, 39.9124);
  ctx.bezierCurveTo(75.3128, 39.6529, 75.2263, 39.3935, 75.1398, 39.1341);
  ctx.bezierCurveTo(75.1398, 39.0476, 75.0534, 38.9612, 75.0534, 38.8747);
  ctx.bezierCurveTo(74.9669, 38.6153, 74.8804, 38.3559, 74.794, 38.0964);
  ctx.bezierCurveTo(74.794, 38.01, 74.7075, 37.9235, 74.7075, 37.837);
  ctx.bezierCurveTo(74.621, 37.5776, 74.5345, 37.3182, 74.3616, 37.0588);
  ctx.bezierCurveTo(74.3616, 36.9723, 74.2751, 36.8858, 74.2751, 36.8858);
  ctx.bezierCurveTo(74.1887, 36.6264, 74.0157, 36.367, 73.9292, 36.0211);
  ctx.bezierCurveTo(73.9292, 35.9347, 73.8428, 35.9347, 73.8428, 35.8482);
  ctx.bezierCurveTo(73.7563, 35.5888, 73.5833, 35.3294, 73.4104, 35.0699);
  ctx.bezierCurveTo(73.4104, 34.9835, 73.3239, 34.9835, 73.3239, 34.897);
  ctx.bezierCurveTo(73.151, 34.6376, 73.0645, 34.3782, 72.8916, 34.1187);
  ctx.bezierCurveTo(72.8916, 34.0323, 72.8051, 34.0323, 72.8051, 33.9458);
  ctx.bezierCurveTo(72.6322, 33.6864, 72.4592, 33.427, 72.3727, 33.1676);
  ctx.bezierCurveTo(72.3727, 33.0811, 72.2863, 33.0811, 72.2863, 32.9946);
  ctx.bezierCurveTo(72.1133, 32.7352, 71.9404, 32.4758, 71.7674, 32.2164);
  ctx.bezierCurveTo(71.7674, 32.1299, 71.681, 32.1299, 71.681, 32.0434);
  ctx.bezierCurveTo(71.508, 31.784, 71.3351, 31.5246, 71.1621, 31.3517);
  ctx.bezierCurveTo(71.1621, 31.2652, 71.0757, 31.2652, 71.0757, 31.1787);
  ctx.bezierCurveTo(61.3044, 39.8259, 40.5512, 58.8497, 29.7422, 68.8804);
  ctx.bezierCurveTo(30.1745, 69.5722, 30.6069, 70.2639, 31.1257, 70.9557);
  ctx.bezierCurveTo(31.4716, 71.2151, 31.8175, 71.3881, 32.1634, 71.6475);
  ctx.bezierCurveTo(32.2499, 71.6475, 32.2499, 71.734, 32.3363, 71.734);
  ctx.bezierCurveTo(32.5958, 71.9069, 32.8552, 72.0798, 33.2011, 72.2528);
  ctx.bezierCurveTo(33.2875, 72.3393, 33.374, 72.3393, 33.4605, 72.4257);
  ctx.bezierCurveTo(33.7199, 72.5987, 33.9793, 72.6851, 34.1522, 72.8581);
  ctx.bezierCurveTo(34.2387, 72.9446, 34.4117, 72.9446, 34.4981, 73.031);
  ctx.bezierCurveTo(34.7575, 73.1175, 34.9305, 73.2904, 35.1899, 73.3769);
  ctx.bezierCurveTo(35.2764, 73.4634, 35.4493, 73.4634, 35.5358, 73.5499);
  ctx.bezierCurveTo(35.7952, 73.6363, 36.0546, 73.8093, 36.2276, 73.8957);
  ctx.bezierCurveTo(36.314, 73.9822, 36.487, 73.9822, 36.5735, 74.0687);
  ctx.bezierCurveTo(36.8329, 74.1552, 37.0923, 74.3281, 37.3517, 74.4146);
  ctx.bezierCurveTo(37.4382, 74.4146, 37.5246, 74.501, 37.6976, 74.501);
  ctx.bezierCurveTo(38.4758, 74.7605, 39.2541, 75.0199, 40.0323, 75.2793);
  ctx.bezierCurveTo(40.1188, 75.2793, 40.2917, 75.3658, 40.3782, 75.3658);
  ctx.bezierCurveTo(40.6376, 75.4522, 40.897, 75.5387, 41.1565, 75.5387);
  ctx.bezierCurveTo(41.3294, 75.5387, 41.4159, 75.6252, 41.5888, 75.6252);
  ctx.bezierCurveTo(41.8482, 75.7117, 42.1076, 75.7117, 42.3671, 75.7981);
  ctx.bezierCurveTo(42.54, 75.7981, 42.7129, 75.8846, 42.8859, 75.8846);
  ctx.bezierCurveTo(43.1453, 75.9711, 43.4047, 75.9711, 43.6641, 75.9711);
  ctx.bezierCurveTo(43.8371, 75.9711, 44.01, 76.0575, 44.183, 76.0575);
  ctx.bezierCurveTo(44.4424, 76.0575, 44.7018, 76.144, 44.9612, 76.144);
  ctx.bezierCurveTo(45.1342, 76.144, 45.3071, 76.144, 45.48, 76.2305);
  ctx.bezierCurveTo(45.7395, 76.2305, 45.9989, 76.2305, 46.3448, 76.317);
  ctx.bezierCurveTo(46.5177, 76.317, 46.6042, 76.317, 46.7771, 76.317);
  ctx.bezierCurveTo(47.2095, 76.317, 47.6418, 76.317, 48.0742, 76.317);
  ctx.bezierCurveTo(63.812, 76.317, 76.6099, 63.5191, 76.6099, 47.7813);
  ctx.bezierCurveTo(76.4369, 47.4354, 76.4369, 47.003, 76.4369, 46.6572);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FFBC26";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(58.7107, 24.7798);
  ctx.lineTo(36.1415, 42.0741);
  ctx.lineTo(43.6646, 54.0937);
  ctx.lineTo(34.4121, 69.918);
  ctx.lineTo(59.143, 55.4772);
  ctx.lineTo(51.7065, 39.9123);
  ctx.lineTo(58.7107, 24.7798);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#A66300";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(60.8725, 27.9792);
  ctx.lineTo(58.7107, 24.7798);
  ctx.lineTo(51.7065, 39.9123);
  ctx.lineTo(59.143, 55.4772);
  ctx.lineTo(34.4121, 69.918);
  ctx.lineTo(38.7357, 70.9557);
  ctx.lineTo(62.6019, 56.8608);
  ctx.lineTo(55.9436, 39.2206);
  ctx.lineTo(60.8725, 27.9792);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FAFFF3";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(58.7107, 24.7798);
  ctx.lineTo(39.5139, 43.0253);
  ctx.lineTo(46.7776, 53.7478);
  ctx.lineTo(34.4121, 69.918);
  ctx.lineTo(43.6646, 54.0937);
  ctx.lineTo(36.1415, 42.0741);
  ctx.lineTo(58.7107, 24.7798);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#FFC673";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(52.7436, 42.1606);
  ctx.lineTo(43.6641, 64.4703);
  ctx.lineTo(59.1425, 55.4773);
  ctx.lineTo(52.7436, 42.1606);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
};

export const drawDiamond = (ctx) => {
  ctx.save();
  ctx.strokeStyle = "rgba(0,0,0,0)";
  ctx.miterLimit = 4;
  ctx.font = "15px / 21.4286px ''";
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.font = "   15px ";
  ctx.save();
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(65, 0);
  ctx.bezierCurveTo(65, 0, 65, 0, 65, 0);
  ctx.lineTo(65, 80);
  ctx.bezierCurveTo(65, 80, 65, 80, 65, 80);
  ctx.lineTo(0, 80);
  ctx.bezierCurveTo(0, 80, 0, 80, 0, 80);
  ctx.lineTo(0, 0);
  ctx.bezierCurveTo(0, 0, 0, 0, 0, 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle = "#D3D782";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(64.9937, 39.9825);
  ctx.lineTo(32.4937, 79.965);
  ctx.lineTo(-0.00633621, 39.9825);
  ctx.lineTo(32.4937, 0);
  ctx.lineTo(64.9937, 39.9825);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#74C264";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(64.9932, 39.9942);
  ctx.lineTo(0.00338936, 39.9942);
  ctx.lineTo(32.5034, 79.9767);
  ctx.lineTo(64.9932, 39.9942);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#4BB337";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(32.5, 0);
  ctx.lineTo(16.4442, 39.968);
  ctx.lineTo(0, 39.9825);
  ctx.lineTo(32.5, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#198080";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(32.5, 80);
  ctx.lineTo(16.4442, 39.9679);
  ctx.lineTo(0, 40.0175);
  ctx.lineTo(32.5, 80);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#4BB337";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(32.4932, 0);
  ctx.lineTo(48.549, 39.968);
  ctx.lineTo(64.9932, 39.9825);
  ctx.lineTo(32.4932, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#198080";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(32.4932, 80);
  ctx.lineTo(48.549, 39.9679);
  ctx.lineTo(64.9932, 40.0175);
  ctx.lineTo(32.4932, 80);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "white";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(32.5, 0);
  ctx.lineTo(20.6195, 37.42);
  ctx.lineTo(32.5, 80.0058);
  ctx.lineTo(16.4544, 39.9738);
  ctx.lineTo(32.5, 0);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "white";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(48.5456, 39.965);
  ctx.lineTo(16.4544, 39.965);
  ctx.lineTo(19.4541, 36.4667);
  ctx.lineTo(48.5456, 39.965);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
};

export const drawBadge = (ctx) => {
  ctx.save();
  ctx.strokeStyle = "rgba(0,0,0,0)";
  ctx.miterLimit = 4;
  ctx.font = "15px / 21.4286px ''";
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.font = "   15px ";
  ctx.save();
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(78, 0);
  ctx.bezierCurveTo(78, 0, 78, 0, 78, 0);
  ctx.lineTo(78, 83);
  ctx.bezierCurveTo(78, 83, 78, 83, 78, 83);
  ctx.lineTo(0, 83);
  ctx.bezierCurveTo(0, 83, 0, 83, 0, 83);
  ctx.lineTo(0, 0);
  ctx.bezierCurveTo(0, 0, 0, 0, 0, 0);
  ctx.closePath();
  ctx.clip();
  ctx.save();
  ctx.fillStyle = "#0553BD";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(77.8629, 54.876);
  ctx.bezierCurveTo(78.6095, 58.4597, 76.2204, 62.8647, 72.7859, 64.8059);
  ctx.bezierCurveTo(62.9307, 70.1815, 52.926, 75.2585, 43.2947, 80.9328);
  ctx.bezierCurveTo(40.0843, 82.874, 35.4553, 82.7993, 32.2448, 80.7835);
  ctx.bezierCurveTo(23.2855, 75.3332, 14.1021, 70.3309, 5.06812, 65.0299);
  ctx.bezierCurveTo(1.70836, 63.0141, -0.606142, 58.4597, 0.140471, 54.9506);
  ctx.bezierCurveTo(4.09752, 40.541, 9.5478, 27.6246, 9.09983, 12.6177);
  ctx.bezierCurveTo(8.5772, 9.03391, 10.0704, 6.19678, 13.2809, 6.19678);
  ctx.bezierCurveTo(30.453, 6.19678, 47.6251, 6.19678, 64.7225, 6.19678);
  ctx.bezierCurveTo(67.933, 6.19678, 69.4262, 9.10857, 68.9036, 12.6177);
  ctx.bezierCurveTo(68.5303, 27.6246, 73.9805, 40.541, 77.8629, 54.876);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#1585D7";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(76.2212, 48.6792);
  ctx.bezierCurveTo(77.2665, 52.2629, 75.5493, 56.668, 72.3388, 58.6092);
  ctx.bezierCurveTo(62.9315, 64.2834, 53.2255, 69.3604, 43.5942, 74.736);
  ctx.bezierCurveTo(40.2344, 76.6772, 35.0828, 76.6025, 31.6484, 74.5867);
  ctx.bezierCurveTo(22.8384, 69.3604, 13.9537, 64.3581, 5.44226, 58.8331);
  ctx.bezierCurveTo(2.45581, 56.7426, 0.813254, 52.1883, 1.85852, 48.6792);
  ctx.bezierCurveTo(6.48752, 34.0456, 11.0419, 21.8011, 7.53278, 6.42088);
  ctx.bezierCurveTo(6.18887, 2.91179, 7.15947, 0, 10.6686, 0);
  ctx.bezierCurveTo(29.5579, 0, 48.4472, 0, 67.3365, 0);
  ctx.bezierCurveTo(70.9203, 0, 71.8162, 2.91179, 70.4723, 6.42088);
  ctx.bezierCurveTo(67.0379, 21.8011, 71.5922, 34.0456, 76.2212, 48.6792);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#0A7CFF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(76.2209, 48.6791);
  ctx.bezierCurveTo(71.5172, 34.0455, 67.0375, 21.8757, 70.5466, 6.49549);
  ctx.bezierCurveTo(71.1439, 4.9276, 71.2932, 3.43437, 70.9946, 2.31445);
  ctx.bezierCurveTo(60.4673, 12.0204, 54.5691, 23.0703, 46.8043, 34.8668);
  ctx.bezierCurveTo(38.9649, 46.738, 29.1842, 56.8919, 19.8516, 67.4938);
  ctx.bezierCurveTo(24.6299, 70.3309, 27.0937, 71.6748, 31.7974, 74.512);
  ctx.bezierCurveTo(35.1571, 76.5278, 40.3088, 76.6025, 43.7432, 74.6613);
  ctx.bezierCurveTo(53.2998, 69.2857, 63.0805, 64.2087, 72.4878, 58.5344);
  ctx.bezierCurveTo(75.5489, 56.6679, 77.2661, 52.1882, 76.2209, 48.6791);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#2DD5FF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(69.9473, 44.9462);
  ctx.bezierCurveTo(70.7686, 48.0073, 69.4247, 51.815, 66.8862, 53.4576);
  ctx.bezierCurveTo(59.1961, 58.3852, 51.2073, 62.7902, 43.2185, 67.2699);
  ctx.bezierCurveTo(40.2321, 68.9125, 35.5284, 68.8378, 32.6166, 67.1206);
  ctx.bezierCurveTo(25.2998, 62.7902, 18.0577, 58.4599, 11.1142, 53.6069);
  ctx.bezierCurveTo(8.65034, 51.8897, 7.38109, 48.0073, 8.20237, 44.9462);
  ctx.bezierCurveTo(11.7114, 32.4777, 14.3246, 21.8012, 12.7567, 8.8101);
  ctx.bezierCurveTo(12.0101, 5.74898, 13.2793, 3.28516, 16.1165, 3.28516);
  ctx.bezierCurveTo(31.422, 3.28516, 46.7276, 3.28516, 62.0332, 3.28516);
  ctx.bezierCurveTo(64.945, 3.28516, 66.1396, 5.74898, 65.393, 8.8101);
  ctx.bezierCurveTo(63.7504, 21.7265, 66.4382, 32.4777, 69.9473, 44.9462);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#2DD5FF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(57.3326, 51.2925);
  ctx.bezierCurveTo(55.3168, 53.7563, 54.1969, 55.0255, 51.957, 57.564);
  ctx.bezierCurveTo(54.8688, 60.9985, 56.362, 62.7903, 59.1245, 66.2247);
  ctx.bezierCurveTo(63.8282, 63.6116, 66.1427, 62.3424, 70.5477, 59.7292);
  ctx.bezierCurveTo(65.2468, 56.3695, 62.4843, 54.6522, 57.3326, 51.2925);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#0F7ED5";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(67.0351, 44.1251);
  ctx.bezierCurveTo(67.707, 46.8876, 66.5124, 50.3966, 64.1979, 51.8899);
  ctx.bezierCurveTo(57.2544, 56.3695, 50.0123, 60.4759, 42.7701, 64.5076);
  ctx.bezierCurveTo(40.0823, 66.0009, 35.752, 65.9262, 33.0642, 64.3583);
  ctx.bezierCurveTo(26.494, 60.4759, 19.9238, 56.4442, 13.6522, 52.0392);
  ctx.bezierCurveTo(11.4124, 50.4713, 10.2178, 46.8876, 10.9644, 44.1251);
  ctx.bezierCurveTo(13.7269, 33.2992, 16.9373, 22.324, 15.3694, 11.0501);
  ctx.bezierCurveTo(14.9214, 8.28766, 16.2654, 5.97314, 18.8038, 5.97314);
  ctx.bezierCurveTo(32.2429, 5.97314, 45.7566, 5.97314, 59.1956, 5.97314);
  ctx.bezierCurveTo(61.7341, 5.97314, 63.078, 8.21299, 62.63, 11.0501);
  ctx.bezierCurveTo(61.0622, 22.3986, 64.2726, 33.2992, 67.0351, 44.1251);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#57B5FF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(64.2012, 51.8896);
  ctx.bezierCurveTo(66.5157, 50.3964, 67.7103, 46.8873, 67.0383, 44.1248);
  ctx.bezierCurveTo(64.2759, 33.2989, 61.0654, 22.3984, 62.6333, 11.1245);
  ctx.bezierCurveTo(49.8662, 11.1245, 37.1738, 11.1245, 24.4067, 11.1245);
  ctx.bezierCurveTo(22.0175, 11.1245, 20.3003, 13.3644, 20.375, 16.2015);
  ctx.bezierCurveTo(20.4497, 27.4007, 17.6872, 38.3759, 15.522, 49.2765);
  ctx.bezierCurveTo(15.2234, 50.695, 15.522, 52.4122, 16.194, 53.9055);
  ctx.bezierCurveTo(21.7189, 57.6385, 27.3185, 61.0729, 33.0674, 64.5074);
  ctx.bezierCurveTo(35.6806, 66.0753, 40.0856, 66.1499, 42.7734, 64.6567);
  ctx.bezierCurveTo(50.0902, 60.5503, 57.2577, 56.4439, 64.2012, 51.8896);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#20A7D6";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(33.1405, 64.4328);
  ctx.bezierCurveTo(35.7537, 66.0006, 40.1587, 66.0753, 42.8465, 64.5821);
  ctx.bezierCurveTo(50.0886, 60.5504, 57.3308, 56.444, 64.2743, 51.9643);
  ctx.bezierCurveTo(66.5888, 50.4711, 67.7834, 46.962, 67.1114, 44.1995);
  ctx.bezierCurveTo(64.349, 33.3736, 61.1385, 22.4731, 62.7064, 11.1992);
  ctx.bezierCurveTo(50.4619, 27.9234, 40.6813, 45.0208, 26.5703, 60.4757);
  ctx.bezierCurveTo(29.1088, 61.9689, 30.4527, 62.7902, 33.1405, 64.4328);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#70BAFF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(62.7056, 11.1245);
  ctx.bezierCurveTo(49.0426, 27.102, 37.6941, 43.3782, 22.0898, 57.7132);
  ctx.bezierCurveTo(23.8817, 58.7584, 24.703, 59.2811, 26.5695, 60.401);
  ctx.bezierCurveTo(40.6805, 44.9461, 50.3865, 27.8487, 62.7056, 11.1245);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#2DD5FF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(60.7638, 34.643);
  ctx.lineTo(40.5305, 53.831);
  ctx.lineTo(20.2227, 34.643);
  ctx.lineTo(40.5305, 15.4551);
  ctx.lineTo(60.7638, 34.643);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#17A8FF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(60.7638, 34.6436);
  ctx.lineTo(20.2227, 34.6436);
  ctx.lineTo(40.5305, 53.8315);
  ctx.lineTo(60.7638, 34.6436);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#1480D5";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(40.5305, 15.4551);
  ctx.lineTo(30.5259, 34.643);
  ctx.lineTo(20.2227, 34.643);
  ctx.lineTo(40.5305, 15.4551);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#0A6CFF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(40.5305, 53.8315);
  ctx.lineTo(30.5259, 34.6436);
  ctx.lineTo(20.2227, 34.6436);
  ctx.lineTo(40.5305, 53.8315);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#1480D5";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(40.5312, 15.4551);
  ctx.lineTo(50.5359, 34.643);
  ctx.lineTo(60.7645, 34.643);
  ctx.lineTo(40.5312, 15.4551);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "#0A6CFF";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(40.5312, 53.8315);
  ctx.lineTo(50.5359, 34.6436);
  ctx.lineTo(60.7645, 34.6436);
  ctx.lineTo(40.5312, 53.8315);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "white";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(40.532, 15.4551);
  ctx.lineTo(33.0658, 33.3738);
  ctx.lineTo(40.532, 53.831);
  ctx.lineTo(30.5273, 34.643);
  ctx.lineTo(40.532, 15.4551);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.fillStyle = "white";
  ctx.font = "   15px ";
  ctx.beginPath();
  ctx.moveTo(50.5366, 34.643);
  ctx.lineTo(30.5273, 34.643);
  ctx.lineTo(32.3939, 32.9258);
  ctx.lineTo(50.5366, 34.643);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
};

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  banner: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  overlay: {
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,.25)",
  },
}));

export const LockedBanner = ({ withOverlay }: { withOverlay?: boolean }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.banner} ${withOverlay ? classes.overlay : ""}`}>
      <svg
        width="95"
        height="95"
        viewBox="0 0 95 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0454742 52.6591L52.659 0.0454362L94.7499 0.0453793L0.0454704 94.7499L0.0454742 52.6591Z"
          fill="url(#paint0_linear_179_26966)"
        />
        <text
          x="0"
          y="57"
          fill="#fff"
          style={{ transform: "rotate(-45deg)" }}
          textAnchor="middle"
        >
          <FormattedMessage id="dashboard.page.coming.soon" />
        </text>
        <defs>
          <linearGradient
            id="paint0_linear_179_26966"
            x1="26.3522"
            y1="26.3522"
            x2="47.3977"
            y2="47.3977"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E83E62" />
            <stop offset="1" stopColor="#BC2444" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default LockedBanner;

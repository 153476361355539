import { Message, Room } from "./interfaces";

export const CHAT_ROOM_ADD = "chat.room.add";
export const CHAT_ROOM_READ = "chat.room.read";
export const CHAT_MESSAGE_SEND = "chat.message.send";
export const CHAT_MESSAGE_TAKE_NEXT = "chat.message.take.next";
export const CHAT_DECISION_PENDING = "chat.decision.pending";
export const CHAT_DECISION_DONE = "chat.decision.done";
export const CHAT_ROOM_OPENED = "chat.room.opened";

export interface roomAddMessage {
  type: typeof CHAT_ROOM_ADD;
  payload: Partial<Room> & { id: string };
}

export interface roomRead {
  type: typeof CHAT_ROOM_READ;
  payload: Pick<Room, "id">;
}

export interface messageSend {
  type: typeof CHAT_MESSAGE_SEND;
  payload: Message;
  timestamp: number;
}

export interface messageTakeNext {
  type: typeof CHAT_MESSAGE_TAKE_NEXT;
  payload: Pick<Room, "id">;
}

export interface decisionPending {
  type: typeof CHAT_DECISION_PENDING;
  payload: Room["id"];
}

export interface decisionDone {
  type: typeof CHAT_DECISION_DONE;
}

export interface roomOpened {
  type: typeof CHAT_ROOM_OPENED;
  payload: Room["id"];
}

export type chatAppMessages =
  | roomAddMessage
  | roomRead
  | messageSend
  | messageTakeNext
  | decisionPending
  | decisionDone
  | roomOpened;

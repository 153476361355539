import { Settings } from "onboarding/player/interfaces";
import { defaultSettings } from "onboarding/player/metadata";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EMPTY, Observable } from "rxjs";
import GameEngine from "../game-engine";
import { Episode } from "../game-engine/interfaces";
import { getLanguage } from "../i18n";
import MessageHub from "../message-hub";
import { Message } from "../message-hub/interfaces";
import Animation from "./animations/animation";
import TimePassingComponent from "./animations/time-passing/time-passing";
import { Apps } from "./apps";
import { BackgroundSwitcher } from "./backgrounds/background-switcher";
import { postEvents } from "./client";
import { CoffeeGame } from "./coffee-game/components/coffee-game";
import { DecisionCore } from "./decision/components/decision-core";
import DirectCommunicationPanel from "./direct-communication/components/direct-communication-panel";
import AppFooter from "./footer/components/footer";
import GuidedCommunicationPanel from "./guided-communication/components/guided-communication-panel";
import AppHeader from "./header/header";
import { useRootState } from "./hooks";
import LevelEnd from "./level-end/level-end";
import { EPISODE_END, GAME_PAUSE } from "./messages";
import SeasonEnd from "./season-end/season-end";
import {
  generateCompanyURL,
  generatePlayerEmailAddress,
} from "./services/accountSettings";
import { getItem, setItem } from "./storage";
import Video from "./video/video";

type GameProps = PropsWithChildren<{
  episode: Episode;
  boarding?: boolean;
  onExit: () => void;
  onReady?: () => void;
}>;

export const GameContext = createContext({
  observable: EMPTY as Observable<Message>,
});

export const Game = (props: GameProps) => {
  const gameEngine = GameEngine();
  const { seasonId, index: episodeIndex, id } = props.episode;
  const [episodeObservable, setEpisodeObservable] =
    useState<Observable<Message>>();
  const [seasonEnd, setSeasonEnd] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [seasonReport, setSeasonReport] = useState(false);

  const onClose = () => {
    MessageHub.send({ type: GAME_PAUSE, payload: null, save: false });

    if (episodeObservable) {
      gameEngine.exitEpisode();
      props.onExit();
    }
  };
  const onLevelEndClose = () => {
    if (seasonEnd) {
      setSeasonReport(true);
    } else {
      onClose();
    }
  };
  const onComplete = async () => {
    if (episodeObservable) {
      await gameEngine.completeEpisode();
      setCompleted(true);
      postEvents(
        [
          {
            eventTypeId: EPISODE_END,
            season: seasonId,
            episode: id,
          },
        ],
        seasonId,
        id
      ).catch((err) =>
        console.error("Error while posting episode end event", err)
      );

      if (props.boarding || seasonId === "0") onClose();
    }
  };

  const onRestart = () => {
    setEpisodeObservable(undefined);
    gameEngine.restartEpisode().then(setEpisodeObservable);
  };

  const onRewind = (rewindId: string) => {
    setEpisodeObservable(undefined);
    gameEngine.rewindEpisodeToStep(rewindId).then(setEpisodeObservable);
  };

  useEffect(() => {
    document.body.classList.add("in-game");
    return () => document.body.classList.remove("in-game");
  }, []);

  useEffect(() => {
    gameEngine
      .runEpisode(props.episode, props.boarding || props.episode.directRun)
      .then((episodeObservable) => {
        if (props.onReady) props.onReady();
        setEpisodeObservable(episodeObservable);
      });
    return () => gameEngine.exitEpisode();
  }, []);

  useEffect(() => {
    if (seasonId) {
      gameEngine.episodes.getSeasonById(seasonId).then((s) => {
        const hasEndMessage = !!s.seasonEndMessage;
        const isLastEpisode =
          s.episodes.filter((e) => !e.hidden).length === episodeIndex;
        const isAlreadyCompleted = s.completed;
        const isSeasonEnd =
          hasEndMessage && isLastEpisode && !isAlreadyCompleted;
        setSeasonEnd(isSeasonEnd);
      });
    }
  }, [seasonId, episodeIndex]);

  useEffect(() => {
    const defaultValues: Settings = defaultSettings(getLanguage());
    const settings: Settings = getItem("SETTINGS") || defaultValues;

    const playerName = settings.playerName || defaultValues.playerName;
    const companyName = settings.companyName || defaultValues.companyName;
    const generatedPlayerEmailAddress = generatePlayerEmailAddress(
      playerName,
      generateCompanyURL(companyName)
    );

    if (
      !settings.companyName ||
      !settings.companyURL ||
      !settings.companyLogo ||
      !settings.playerName ||
      !settings.playerAvatar ||
      !settings.leadingColor ||
      !settings.playerEmailAddress ||
      settings.playerEmailAddress !== generatedPlayerEmailAddress
    ) {
      const playerWithFilledDefaults = {
        ...settings,
        companyName,
        companyURL: settings.companyURL || defaultValues.companyURL,
        companyLogo: settings.companyLogo || defaultValues.companyLogo,
        playerName,
        playerAvatar: settings.playerAvatar || defaultValues.playerAvatar,
        leadingColor: settings.leadingColor || defaultValues.leadingColor,
        playerEmailAddress: generatedPlayerEmailAddress,
      };

      setItem("SETTINGS", playerWithFilledDefaults);
    }
  }, []);

  if (!props.boarding && seasonReport)
    return <SeasonEnd seasonId={seasonId} onClose={onClose} />;

  if (!props.boarding && completed && seasonId !== "0")
    return <LevelEnd onClose={onLevelEndClose} />;

  if (!episodeObservable) return null;

  return (
    <GameContext.Provider value={{ observable: episodeObservable }}>
      {props.children}
      <GamePlaying
        onRestart={onRestart}
        onRewind={onRewind}
        onExit={onClose}
        onComplete={onComplete}
        boarding={props.boarding}
      />
    </GameContext.Provider>
  );
};

const GamePlaying = (props: {
  onRestart: () => void;
  onRewind: (rewindId: string) => void;
  onExit: () => void;
  onComplete: () => void;
  boarding?: boolean;
}) => {
  const history = useHistory();
  const s = useRootState();

  useEffect(() => {
    history.push("/");
  }, []);

  useEffect(() => {
    if (s.levelEnd) props.onComplete();
  }, [s.levelEnd]);

  useEffect(() => {
    if (s.gotoUrl) history.push(s.gotoUrl);
  }, [s.gotoUrl]);

  return (
    <>
      <BackgroundSwitcher background={s.background} />
      {!props.boarding && <AppHeader />}
      {!props.boarding && (
        <AppFooter
          onExit={props.onExit}
          onRestart={props.onRestart}
          onRewind={props.onRewind}
        />
      )}
      {s.showApps && (
        <Apps
          home={s.appsHome}
          router={s.appsRouter}
          browser={s.appsBrowser}
          hidden={props.boarding}
        />
      )}
      <DirectCommunicationPanel permanent={props.boarding} />
      <GuidedCommunicationPanel />
      <DecisionCore />
      <CoffeeGame />
      {s.animation.run && <Animation {...s.animation} />}
      {s.timePassing.state && (
        <TimePassingComponent
          duration={s.timePassing.duration}
          keepRoute={s.timePassing.keepRoute}
        />
      )}
      {s.video.state && s.video.src && <Video src={s.video.src} />}
    </>
  );
};

import { Badge } from "../../../game-engine/badges-engine";
import { BadgeItem } from "./badge";
import styles from "./episode-report.module.scss";

type PropsType = {
  badges: Badge[];
};
export const Badges = ({ badges }: PropsType) => {
  return badges.length > 0 ? (
    <div
      style={{ overflow: "hidden" }}
      className={"animate__animated animate__fadeIn"}
    >
      <div
        className={`${styles.badgesBox} animate__animated animate__bounceInDown`}
        data-e2e-report-badges-list
      >
        {badges.map((badge, i) => (
          <BadgeItem key={i} badge={badge} />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

import { ClickAwayListener, Theme, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useState } from "react";
import { AppHeader as App } from "..";
import { useSettings } from "../../hooks";
import copyIcon from "../assets/copy_icon.svg";
import { usePulsePlayerIcon } from "../hooks";
import { useStyles } from "./avatar.styles";

const ContactDetailsTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    paddingTop: 16,
    textAlign: "center",
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    borderRadius: 16,
    border: "3px solid #ddd",
    "& div > img": {
      width: 64,
    },
    "& p > img": {
      height: 16,
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.35)",
      },
    },
  },
}))(Tooltip);

const Avatar = () => {
  const { settings } = useSettings();
  const pulsePlayerIcon = usePulsePlayerIcon();
  const classes = useStyles();

  const [contactTooltipOpen, setContactTooltipOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setContactTooltipOpen(false)}>
      <div>
        <ContactDetailsTooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={contactTooltipOpen}
          title={
            <div>
              <img
                src={settings.companyLogo || "/logos/logo1.svg"}
                alt="Company Logo"
              />
              <h3>{settings.companyName}</h3>
              <p>{settings.companyURL}</p>
              <hr />
              <img
                src={settings.playerAvatar}
                alt="Avatar"
                style={{ opacity: settings.playerAvatar ? 1 : 0 }}
              />
              <h3>{settings.playerName}</h3>
              <p>
                {`${settings.playerEmailAddress} `}
                <img
                  src={copyIcon}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(settings.playerEmailAddress);
                    setContactTooltipOpen(false);
                  }}
                  role="button"
                />
              </p>
              <p>
                {"+49 (0)211-28051986 "}
                <img
                  src={copyIcon}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText("+49 (0)211-28051986");
                    setContactTooltipOpen(false);
                  }}
                  role="button"
                />
              </p>
            </div>
          }
        >
          <img
            id="avatar-icon"
            className={`${classes.avatar} ${
              pulsePlayerIcon && classes.pulsating
            }`}
            src={settings.playerAvatar}
            alt={settings.playerName}
            style={{
              opacity: settings.playerAvatar ? 1 : 0,
              backgroundColor: settings.leadingColor,
              cursor: "pointer",
            }}
            onClick={() => {
              setContactTooltipOpen(true);
              App.instance.stopPulsePlayerIcon();
            }}
          />
        </ContactDetailsTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default Avatar;

import React from "react";
import { FormattedMessage } from "react-intl";
import img from "./assets/coin.svg";
import styles from "./styles.module.scss";

export default function thanks() {
  return (
    <div className={styles.column}>
      <div className={styles.row}>
        <img src={img} />
        <span style={{ fontSize: "7em", color: "#FF9900" }}>5</span>
      </div>
      <h1 style={{ color: "#666666", fontWeight: "normal" }}>
        <FormattedMessage id="levelsurvey.points" />
      </h1>
    </div>
  );
}

import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import OpenAI from "openai";
import { openAiConnection } from "../services/open-ai-connection";

export const usePostOpenAiThread = (
  options?: UseMutationOptions<OpenAI.Beta.Threads.Thread, unknown, unknown>
) => {
  return useMutation({
    mutationFn: async () => {
      return await openAiConnection.getConnection().beta.threads.create();
    },
    meta: {
      devErrorMsg: "usePostOpenAiThread",
    },
    ...options,
  });
};

import { CompetenceLevel } from "../../core/enums";
import { Message } from "../../message-hub/interfaces";
import { Question } from "./types/question";
import { QuizInformation } from "./types/quizInformation";

export const SPEED_CHECK_ADD_DESCRIPTION = "speed.check.add.description";
export const SPEED_CHECK_ADD_MESSAGE = "speed.check.add.message";
export const SPEED_CHECK_ANSWER_QUESTIONS = "speed.check.answer.questions";
export const SPEED_CHECK_EXIT = "speed.check.exit";

export type addDescription = Message<
  typeof SPEED_CHECK_ADD_DESCRIPTION,
  QuizInformation
>;

export type addMessage = Message<
  typeof SPEED_CHECK_ADD_MESSAGE,
  {
    actor: ""; // unused
    content: {
      title: undefined; // unused
      htmlBody: string;
      data: undefined; // unused
    };
    level: CompetenceLevel;
    question: Omit<Question, "feedback">;
  }
>;

export type answerQuestion = Message<
  typeof SPEED_CHECK_ANSWER_QUESTIONS,
  {
    level: CompetenceLevel;
    correct: boolean;
  }
>;

export type exit = Message<typeof SPEED_CHECK_EXIT, null>;

export type speedCheckMessages =
  | exit
  | addMessage
  | answerQuestion
  | addDescription;

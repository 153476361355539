import { ThemeProvider, Tooltip } from "@mui/material";
import { CSSProperties } from "react";
import { FormattedMessage } from "react-intl";
import theme from "../dashboard/theme";
import { CompetenceLevel, CompetenceLevelText } from "../enums";
import diamond1 from "./assets/diamond_1.svg";
import diamond2 from "./assets/diamond_2.svg";
import diamond3 from "./assets/diamond_3.svg";

type PropsType = {
  level: CompetenceLevel | CompetenceLevelText;
  tooltip?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const DiamondIcon = ({
  level = CompetenceLevel.Foundation,
  tooltip,
  className,
  style,
}: PropsType) => {
  const getIcon = () => {
    switch (level) {
      case CompetenceLevel.Foundation:
      case CompetenceLevelText.Foundation:
        return diamond1;
      case CompetenceLevel.Intermediate:
      case CompetenceLevelText.Intermediate:
        return diamond2;
      case CompetenceLevel.Advanced:
      case CompetenceLevelText.Advanced:
        return diamond3;
      default:
        return diamond1;
    }
  };

  if (tooltip === false) {
    return <img src={getIcon()} alt="diamond" className={className} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={<FormattedMessage id={`header.test-level.${level}`} />}
        aria-label="add"
        placement="top"
        arrow
      >
        <img
          src={getIcon()}
          alt="diamond"
          className={className}
          style={style}
        />
      </Tooltip>
    </ThemeProvider>
  );
};

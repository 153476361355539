import { Box, keyframes, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSettings } from "core/hooks";
import DOMPurify from "dompurify";
import { marked } from "marked";
import OpenAI from "openai";
import { useMemo } from "react";

export const TextBubble = ({
  botAvatar,
  content,
  isBot,
  waiting,
}: {
  botAvatar?: string;
  content?: OpenAI.Beta.Threads.Messages.MessageContent | string;
  isBot: boolean;
  waiting?: boolean;
}) => {
  const { settings } = useSettings();

  const isOpenAiContent = typeof content === "object";

  //  TODO: TI-2594 - This useMemo could use some more optimization
  const text = useMemo(() => {
    if (isOpenAiContent) {
      return extractText(content);
    }
    return content ?? "";
  }, [content, isOpenAiContent]);

  const getText = () => {
    if (waiting) {
      return (
        <Box
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <StyledDotFlashing />
        </Box>
      );
    }

    if (isOpenAiContent) {
      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return <span>{content}</span>;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
      }}
    >
      {isBot && (
        <StyledAvatar>
          <img src={botAvatar} alt="Bot avatar" width="100%" height="100%" />
        </StyledAvatar>
      )}
      <StyledTextBubble>{getText()}</StyledTextBubble>
      {!isBot && (
        <StyledAvatar>
          <img
            src={settings?.playerAvatar}
            style={{ backgroundColor: settings?.leadingColor || "grey" }}
            alt="Player avatar"
            width="100%"
            height="100%"
          />
        </StyledAvatar>
      )}
    </Box>
  );
};

const StyledTextBubble = styled(Box)(({ theme }) => ({
  background: "#fff",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  margin: theme.spacing(0, 2),
  overflow: "auto",
  display: "flex",
  alignItems: "center",

  "& p": {
    margin: "auto",
  },
}));

const StyledAvatar = styled("div")(() => ({
  width: "56px",
  height: "56px",
  minWidth: "56px",
  minHeight: "56px",
  borderRadius: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
}));

const dotFlashingKeyframes = keyframes`
  0% {
    background-color: ${grey["400"]};
  }
  50%, 100% {
    background-color: ${grey["700"]};
  }
`;

const StyledDotFlashing = styled("div")(({ theme }) => ({
  position: "relative",
  width: "10px",
  height: "10px",
  borderRadius: theme.shape.borderRadius,
  animation: `${dotFlashingKeyframes} 1s infinite linear alternate`,
  animationDelay: "0.5s",
  backgroundColor: grey["700"],

  "&::before, &::after": {
    content: "''",
    display: "inline-block",
    position: "absolute",
    width: "10px",
    height: "10px",
    top: 0,
    backgroundColor: grey["700"],
    animation: `${dotFlashingKeyframes} 1s infinite alternate`,
    borderRadius: "5px",
  },

  "&::before": {
    left: "-15px",
    animationDelay: "0s",
  },

  "&::after": {
    left: "15px",
    animationDelay: "1s",
  },
}));

const extractText = (content: OpenAI.Beta.Threads.Messages.MessageContent) => {
  if (content.type === "text") {
    /*
      Remove source annotations from the text, these are not needed for a prototype
      https://community.openai.com/t/remove-35-source-from-assistant-response/581210/3
      https://platform.openai.com/docs/assistants/how-it-works/message-annotations
    */
    const contentWithoutSources = content.text.value.replace(
      /【\d+†source】/g,
      ""
    );
    return sanitizeParse(contentWithoutSources);
  }
  return ""; // TODO: handle other types of content"
};

const sanitizeParse = (html: string): string => {
  const dirtyHtml = marked(html, { breaks: true }) as string;
  const sanitizeOptions = {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["target"],
  };

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  });

  return DOMPurify.sanitize(dirtyHtml, sanitizeOptions);
};

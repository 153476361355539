import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Grid,
  Snackbar,
} from "@mui/material";
import { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { h32 } from "xxhashjs";
import { getUserEmail } from "../../../keycloak";
import { getItem, setItem } from "../../../storage";
import { HASH_SALT } from "../../../utils";
import { DashboardBox } from "../../components/box";
import { DashboardContext } from "../../dashboard.context";
import mailIcon from "./assets/email.svg";
import linkIcon from "./assets/link_building.svg";

export const COLLEAGUE_INVITE_LINK_ACTIVATED_STORAGE_KEY =
  "USER_ACTIVATED_COLLEAGURE_INVITE_LINK";

const Invitation = () => {
  const mail = getUserEmail();
  const link = `${(window as any).REACT_APP_AUTH_URL.replace(
    "/auth",
    ""
  )}?invitation=${h32(mail, HASH_SALT).toString(16)}`;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { setColleagueInviteLinkActivated } = useContext(DashboardContext);

  const saveInStorage = () => {
    if (!getItem(COLLEAGUE_INVITE_LINK_ACTIVATED_STORAGE_KEY)) {
      setItem(COLLEAGUE_INVITE_LINK_ACTIVATED_STORAGE_KEY, true);
      setColleagueInviteLinkActivated(true);
    }
  };

  const copyToClipboard = () => {
    setOpen(true);
    navigator.clipboard.writeText(link);
    saveInStorage();
  };

  const openEmail = () => {
    const mail = `mailto:?cc=&subject=${intl.formatMessage({
      id: "dashboard.page.invitation.mail-subject",
    })}&body=${intl.formatMessage(
      {
        id: "dashboard.page.invitation.mail-body",
      },
      {
        link,
      }
    )}`;
    window.open(mail);
    saveInStorage();
  };

  const box = (type: "email" | "link", icon: string, click: () => any) => (
    <Card variant="outlined">
      <CardHeader
        title={
          <FormattedMessage id={`dashboard.page.invitation.${type}.title`} />
        }
        avatar={<img src={icon} alt="icon" />}
        subheader={
          <FormattedMessage
            id={`dashboard.page.invitation.${type}.description`}
          />
        }
      />
      <CardActions>
        <Button onClick={click} color="primary">
          <FormattedMessage id={`dashboard.page.invitation.${type}.action`} />
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <Container maxWidth="lg">
      <DashboardBox
        title="dashboard.page.invitation.title"
        subheader={
          <FormattedMessage
            id="dashboard.page.invitation.description"
            values={{
              br: <br />,
            }}
          />
        }
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            {box("email", mailIcon, openEmail)}
          </Grid>
          <Grid item xs={12} md={6}>
            {box("link", linkIcon, copyToClipboard)}
          </Grid>
        </Grid>
      </DashboardBox>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={<FormattedMessage id="dashboard.page.invitation.copied" />}
      />
    </Container>
  );
};

export default Invitation;

import React, { createContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BrowserLayout from "../browser-layout/browser-layout";
import { Application } from "../interfaces";

const app404 = {
  id: "404",
  name: "404",
  icon: "none",
  path: "/404",
  in: {},
  browser: {
    domain: "",
  },
};

const Site404 = () => {
  return (
    <BrowserLayout
      headerColor="#333"
      app={app404}
      content={<h1 style={{ textAlign: "center" }}>404 page not found</h1>}
    ></BrowserLayout>
  );
};

export const BrowserContext = createContext({
  domain: "",
  navigate: (url: string) => {
    //emtpy
  },
});

const Browser = (props: { apps: Application[] }) => {
  const history = useHistory();
  const { domain } = useParams<any>();
  const navigate = (u) => history.push(`/browser/${u}`);
  const [defaultApp] = useState(() => {
    return props.apps.find((a) => (a.browser ? a.browser.default : false));
  });
  const domainDisplay = history.location.pathname.split("browser/").pop() || "";
  const app = props.apps.find((a) => a.browser?.domain === domain);
  const Component = app?.component ?? Site404;

  useEffect(() => {
    if (!domain) navigate(defaultApp?.browser?.domain);
  }, [domain]);

  return (
    <BrowserContext.Provider value={{ domain: domainDisplay, navigate }}>
      <Component />
    </BrowserContext.Provider>
  );
};

export default Browser;

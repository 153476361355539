import ky from "ky";
import { merge } from "lodash";
import { Season } from "../game-engine/interfaces";
import { SignalResponse } from "./dashboard/pages/management-cockpit/widgets/signals-widget";
import {
  AccredibleLink,
  CompanyCompletedEpisodesStatisticsReport,
  CompanyNumberOfUsersStatisticsReport,
  CompetenceArea,
  CompetenceAreaTree,
  EngagementPayload,
  MissionIndividualProgress,
  MissionOrganizationProgress,
  PlayerTeamOrganisationComparison,
  PlayRecommendation,
  ReportData,
  SaveGame,
  TrainingRecommendation,
} from "./interfaces";
import { keycloak, updateServiceWorker } from "./keycloak";
import { generatePlayerEmailAddress } from "./services/accountSettings";

const applicationId = "talentApplicationProfileTwo";

interface TalentUserWeb {
  id?: string;
  name?: string;
  talentGroup?: any;
  tagItems?: any[];
  supportUser?: boolean;
  avatarName?: string;
  avatarImage?: string;
  avatarLeadingColor?: string;
}

export const http = ky.create({
  prefixUrl: "/api/",
  hooks: {
    beforeRequest: [
      async (request) => {
        const refreshed = await keycloak.updateToken(5);
        if (refreshed) {
          updateServiceWorker();
        }
        request.headers.set("Authorization", `Bearer ${keycloak.token}`);
      },
    ],
  },
  timeout: false,
});

export async function postEvents(
  events: Record<string, any>[],
  seasonId?: string,
  episodeId?: string
) {
  return http.post("v1/event", {
    json: { applicationId, events, seasonId, episodeId },
    retry: {
      limit: 3,
    },
  });
}

export async function saveState(state: SaveGame) {
  return http.post("v1/savegame", {
    json: {
      applicationId,
      state: JSON.stringify(state),
    },
  });
}

export async function getState(): Promise<SaveGame> {
  const seasons = await getSeasonsDataFromApi();
  const episodeFormatList: string[] = seasons
    .flatMap((season) => Object.values(season.episodes))
    .map((episode) => episode.format);
  const episodeFormatListNoDuplicates = Array.from(new Set(episodeFormatList));
  const requestFormatList = episodeFormatListNoDuplicates
    .filter((item) => item.includes("webflow") || item.includes("format"))
    .map((item) => {
      return item.includes("webflow/") ? item.split("webflow/")[1] : item;
    });
  requestFormatList.push(applicationId);

  try {
    const requests = requestFormatList.map((format) => {
      return http
        .get(`v1/savegame/${format}`)
        .then((response) => response.json());
    });

    const results = await Promise.all(requests);
    const profile: TalentUserWeb = await http
      .get("v1/talent")
      .then((response) => response.json());

    const stateParsedList = results.map((result) =>
      JSON.parse((result as any).state)
    );

    const state = stateParsedList.reduce((acc, item) => {
      return merge(acc, item);
    }, {});

    if (profile?.avatarName) {
      state.SETTINGS.playerName = profile.avatarName;
      state.SETTINGS.playerEmailAddress = generatePlayerEmailAddress(
        profile.avatarName,
        state.SETTINGS.companyURL
      );
    }
    if (profile?.avatarLeadingColor) {
      state.SETTINGS.leadingColor = profile.avatarLeadingColor;
    }
    if (profile?.avatarImage) {
      state.SETTINGS.playerAvatar = profile.avatarImage;
    }

    return state;
  } catch (e) {
    console.error("getState(): Error while parsing savegame state", e);
    return {};
  }
}

export async function getReportData(): Promise<ReportData[]> {
  try {
    const res = await http.get("v1/user-report");
    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getReportTestDetails(
  season: Season["id"],
  episode?: string
): Promise<CompetenceArea[]> {
  try {
    const params = [["season", season]];

    if (episode !== undefined) {
      params.push(["episode", episode]);
    }

    const query = new URLSearchParams(params);
    const res = await http.get(
      `v1/user-report/test-details?${query.toString()}`
    );
    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getApplicationConfigSingleValue(
  name: string
): Promise<string> {
  let json = { value: "" };
  try {
    const res = await http.get(`v1/config/single-value/${name}`);
    json = await res.json();
  } catch (err) {
    console.error(err);
  }

  return json.value;
}

export async function updateApplicationConfigSingleValue(
  name: string,
  value: string
): Promise<void> {
  try {
    await http.put(`v1/config/single-value`, {
      json: { name, value },
    });
  } catch (err) {
    console.error(err);
  }
}

export async function getEngagementPoints(days): Promise<EngagementPayload[]> {
  try {
    const query = new URLSearchParams({ days: days.toString() });
    const res = await http.get(`v1/user-report/statistics?${query.toString()}`);
    return await res.json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyStatistics(): Promise<CompanyNumberOfUsersStatisticsReport> {
  try {
    const res = await http.get("v1/company-report/number-of-users");
    return await res.json();
  } catch (e) {
    console.error(e);
    return {
      currentPeriodActiveUsers: 0,
      previousPeriodActiveUsers: 0,
      allRegisteredUsers: 0,
      currentPeriodRegisteredUsers: 0,
      previousPeriodRegisteredUsers: 0,
    };
  }
}

export async function getCompanyHistogram(
  season: string
): Promise<CompanyCompletedEpisodesStatisticsReport> {
  try {
    const res = await http.get(
      `v1/company-report/completed-episodes?season=${season}`
    );
    const data: CompanyCompletedEpisodesStatisticsReport<string> =
      await res.json();

    const getCompletedEpisodeNumber = (episode: string): number => {
      const legacyEpisodeNumber = Number(episode.split("e")[1]);

      if (episode === "NO_EPISODE_COMPLETED") return 0;
      if (!isNaN(Number(episode))) return Number(episode);
      if (!isNaN(legacyEpisodeNumber)) return legacyEpisodeNumber;

      return 0;
    };

    return {
      completedEpisodes: data.completedEpisodes.map((item) => {
        return {
          ...item,
          episode: getCompletedEpisodeNumber(item.episode),
        };
      }),
    };
  } catch (e) {
    console.log(e);
    return { completedEpisodes: [] };
  }
}

export async function getSubCompetences(season: Season["id"]) {
  try {
    const res = await http.get(
      `report/profile2/sub-competence?season=${season}`
    );
    return await res.json();
  } catch (e) {
    console.log(e);
    return { success: false };
  }
}

export async function getUserCertificatesList(): Promise<AccredibleLink[]> {
  try {
    return await http.get("certificate").json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function postUserCertificateData(certificate) {
  try {
    return await http
      .post("certificate", {
        json: certificate,
      })
      .json();
  } catch (e) {
    console.error(e);
  }
}

export async function putApplicationConfigMultiValue(name, values: string[]) {
  try {
    await http.put("profile/application/config/multi-value", {
      json: { name, values },
    });
    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false };
  }
}

export async function getApplicationConfigMultiValue(name) {
  try {
    return (
      await http.get(`profile/application/config/multi-value/${name}`)
    ).json();
  } catch (e) {
    console.error(e);
    return { name, values: [] };
  }
}

export async function getPlayerTeamOrganisationComparisonReport(): Promise<PlayerTeamOrganisationComparison> {
  try {
    return (await http.get("report/profile2/compare")).json();
  } catch (e) {
    console.error(e);
    return {
      available: 0,
      playerTried: 0,
      playerPassed: 0,
      teamTried: 0,
      teamPassed: 0,
      organisationTried: 0,
      organisationPassed: 0,
    };
  }
}

export async function getCompetencesTree(): Promise<CompetenceAreaTree[]> {
  try {
    return (await http.get("v1/competences")).json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDoc(
  docId: string,
  awareness: string
): Promise<Response> {
  return http.put(`v1/profile2/application/realtimeDocument/${docId}`, {
    referrer: "",
    body: awareness,
  });
}

export async function sendDoc(docId: string, body: string): Promise<Response> {
  return http.post(`v1/profile2/application/realtimeDocument/${docId}`, {
    body,
    headers: {
      "Content-Type": "application/octet-stream",
      Accept: "application/json",
    },
    retry: {
      limit: 3,
    },
  });
}

export async function getSignals(): Promise<SignalResponse[]> {
  try {
    return (await http.get("v1/profile2/signals")).json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMissionsProgress(): Promise<
  MissionOrganizationProgress[]
> {
  try {
    return (await http.get("v1/profile2/missions/progress")).json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getMissionsIndividualProgress(): Promise<
  MissionIndividualProgress[]
> {
  try {
    return (await http.get("v1/profile2/missions/individual/progress")).json();
  } catch (e: any) {
    console.error(e);

    return [];
  }
}

export async function getPlayRecommendation(): Promise<PlayRecommendation[]> {
  try {
    return (await http.get("v1/profile2/recommendation/play")).json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getTrainingRecommendation(): Promise<
  TrainingRecommendation[] | false
> {
  try {
    const response = await http.get("v1/profile2/recommendation/training");

    if (response.status === 204) return Promise.resolve(false);

    return response.json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getSeasonsDataFromApi(): Promise<any> {
  try {
    const response = await http.get("v1/season");

    if (response.status === 204) return Promise.resolve(false);

    return response.json();
  } catch (e) {
    console.error(e);
    return [];
  }
}

/**
 * Replaces old awardBadge function from the BadgesEngine class. The new cockpit uses a different endpoint to award badges.
 * @param seasonId = The fallback value is "legacy-badge-season". This value is identical in the new cockpit inside
 * the porting of old users script so be cautious when changing it. The default value will also apply to global badges
 * that do not have a season.
 */
export async function awardBadge(
  badgeId: string,
  seasonId = "legacy-badge-season"
) {
  try {
    return http.post("v1/talent-badges", {
      json: { badgeId, seasonId },
    });
  } catch (e) {
    console.error(e);
  }
}

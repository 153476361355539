import { createTheme } from "@mui/material";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints"; // TODO private method would be good to remove that

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00B2E3",
      contrastText: "#fff",
    },
    common: {
      black: "#242424",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          /*
            overflow: hidden does not work well with position: sticky which is required for MUI tabs
            that is why we use contain: "paint" as a replacement and unset the overflow
          */
          contain: "paint",
          overflow: "unset",
          borderRadius: 20,
          backgroundColor: "rgba(1,1,1,0.1)",
          backgroundImage: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgb(79, 116, 121, .95)",
          borderRadius: 8,
          maxWidth: 500,
          fontSize: 16,
        },
        arrow: {
          color: "rgb(79, 116, 121, .95)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#00B2E3",
          color: "white",

          "&:hover": {
            backgroundColor: "#0FC3F4",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
        colorPrimary: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: "1px solid white",
          borderRadius: 12,
          marginRight: 10,
          textTransform: "none",

          "&:hover": {
            backgroundColor: "white",
            color: "#254c53",
            // "& > span": {
            //   color: "#254c53",
            // },
          },
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "#254c53",
            // opacity: 0,
          },
        },
        wrapped: {
          opacity: 1,
          color: "white",
          fontWeight: "bold",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        },
        labelIcon: {
          minHeight: 56,
          margin: 0,

          "& img": {
            marginBottom: "0px !important",
          },

          [breakpoints.down("xs")]: {
            minHeight: 0,
            height: 36,
          },
        },
        textColorInherit: {
          opacity: 1,
          transition: "background-color 250ms linear, color 250ms linear",

          "&$selected": {
            backgroundColor: "white",

            "& > span": {
              color: "#254c53",
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: "sticky",
          top: 0,
          zIndex: 1,
        },
        indicator: {
          backgroundColor: "transparent",
        },
        flexContainer: {
          gap: 15,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;

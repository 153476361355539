import { appLauncherMessages, APP_OPEN } from "core/messages";
import { Observable } from "rxjs";
import { scan, shareReplay, startWith } from "rxjs/operators";
import MessageHub from "../../message-hub";
import { Message } from "../../message-hub/interfaces";
import { ofTypes } from "../applications";
import { DecisionChoice } from "./interfaces/decision-choice";
import { DecisionOption } from "./interfaces/decision-option";
import { DecisionPayload } from "./interfaces/decision-payload";
import { DecisionType } from "./interfaces/decision-type";
import { State } from "./interfaces/state";
import {
  decisionChoose,
  decisionEnd,
  decisionMessage,
  DECISION_ADD,
  DECISION_BLOCK,
  DECISION_CHOOSE,
  DECISION_END,
} from "./messages";

export default class DecisionApp {
  static instance: DecisionApp;
  private initialState: State = {
    locked: false,
    decisions: {},
    choices: {},
  };
  state: Observable<State>;
  constructor(messages: Observable<Message>) {
    DecisionApp.instance = this;
    this.state = messages.pipe(
      ofTypes<decisionMessage>(
        DECISION_ADD,
        DECISION_END,
        DECISION_CHOOSE,
        DECISION_BLOCK,
        APP_OPEN
      ),
      scan(this.reducer, this.initialState),
      startWith(this.initialState),
      shareReplay(1)
    );
    this.state.subscribe();
  }

  get currentState(): State {
    let state;
    this.state.subscribe((s) => (state = s));
    return state;
  }

  private reducer = (
    state: State,
    msg: decisionMessage | appLauncherMessages
  ): State => {
    switch (msg.type) {
      case DECISION_ADD:
        return this.onAddDecision(state, msg.payload);
      case DECISION_CHOOSE:
        return this.onChoose(state, msg.payload);
      case DECISION_BLOCK:
        return this.onBlock(state, msg.payload);
      case DECISION_END:
        if (typeof msg.payload === "string") {
          if (msg.payload !== state.currentDecision?.id) return state;
        }

        return {
          ...state,
          currentDecision: undefined,
          currentDecisionApp: undefined,
          stashedDecision: undefined,
        };
      case APP_OPEN:
        if (
          state.currentDecision &&
          msg.payload.appId !== state.currentDecisionApp
        ) {
          return {
            ...state,
            stashedDecision: {
              appId: msg.payload.appId,
              decision: state.currentDecision as DecisionPayload,
            },
            currentDecision: undefined,
          };
        } else if (
          !state.currentDecision &&
          state.stashedDecision &&
          state.currentDecisionApp === msg.payload.appId
        ) {
          return {
            ...state,
            currentDecision: state.stashedDecision.decision,
            stashedDecision: undefined,
          };
        }

        return state;
      default:
        return state;
    }
  };

  private onAddDecision(state: State, newDecision: DecisionPayload): State {
    const ordered = this.orderState({
      ...state,
      decisions: {
        ...state.decisions,
        [newDecision.id]: newDecision,
      },
      currentDecision: newDecision,
    });

    return { ...ordered, currentDecisionApp: newDecision.app };
  }

  private onChoose(state: State, choice: DecisionChoice) {
    return this.onOption(state, choice, true);
  }

  private onBlock(state: State, choice: DecisionChoice) {
    return this.onOption(state, choice, false);
  }

  private onOption(
    state: State,
    choice: DecisionChoice,
    selectedOrBlock: boolean
  ) {
    const { choices } = state;

    const existingChoices = {
      ...(choices[choice.decision] || {}),
      [choice.option.id]: selectedOrBlock,
    };

    return this.orderState({
      ...state,
      choices: {
        ...choices,
        [choice.decision]: existingChoices,
      },
    });
  }

  private orderState(state: State): State {
    // we are mutating the state because a new object has been created before
    const { currentDecision, choices } = state;
    if (currentDecision) {
      const { id } = currentDecision;
      if (!currentDecision.multiple) {
        if (choices[id]) {
          // messages-to-observable needs a decisionEnd or decisionChoose to continue the script
          if (state.currentDecision) {
            const decisionId = state.currentDecision.id;
            setTimeout(() => this.end(decisionId));
          }
          state.currentDecision = undefined;
        }
      } else {
        /**
         * To avoid complexity around blocking remaining options by hand when
         * choosing, and because all
         * the multiple options have the shape of:
         * - i'll do it
         * - need help
         * - you do it
         *
         * Let's assume that behaviour is always the same for multiple options
         */
        if (state.choices[id]) {
          const currentChoices = state.choices[id];
          const choiceIndexes = Object.keys(currentChoices).map((id) =>
            currentDecision.options.findIndex((o) => id === String(o.id))
          );
          const maxIndex = Math.max(...choiceIndexes);
          let decisionCompleted = true;
          for (let i = 0; i < currentDecision.options.length; i++) {
            const id = currentDecision.options[i].id;
            if (i < maxIndex && currentChoices[id] === undefined) {
              currentChoices[id] = false;
            }
            if (decisionCompleted && currentChoices[id] === undefined) {
              decisionCompleted = false;
            }
          }
          if (decisionCompleted) {
            state.currentDecision = undefined;
          }
        }
      }
    }

    state.locked = state.currentDecision
      ? state.currentDecision.type !== DecisionType.CHAT &&
        !state.choices[state.currentDecision.id]
      : false;

    return state;
  }

  private end(decisionId: DecisionPayload["id"]) {
    MessageHub.send({
      type: DECISION_END,
      payload: decisionId,
    } as decisionEnd);
  }

  choose = (decision: DecisionPayload["id"], option: DecisionOption) => {
    MessageHub.send({
      type: DECISION_CHOOSE,
      save: true,
      payload: {
        decision,
        option,
      },
    } as decisionChoose);
  };
}

import { useEffect } from "react";
import DecisionApp from "..";
import {
  CHAT_DECISION_DONE,
  CHAT_DECISION_PENDING,
} from "../../../apps/chat/messages";
import MessageHub from "../../../message-hub";
import { useAppState } from "../../applications";
import { DecisionType } from "../interfaces/decision-type";
import { State } from "../interfaces/state";
import { Bottom } from "./bottom";
import { Fullscreen } from "./fullscreen";
import { Overflow } from "./overflow";

export const DecisionCore = () => {
  const {
    currentDecision: decision,
    choices,
    locked,
  } = useAppState<State>(DecisionApp.instance);
  const decisionChoices =
    decision !== undefined ? choices[decision.id] || {} : {};

  useEffect(() => {
    if (decision?.type === DecisionType.CHAT) {
      MessageHub.send({
        type: CHAT_DECISION_PENDING,
        payload: decision?.replyIn,
      });
    } else {
      MessageHub.send({
        type: CHAT_DECISION_DONE,
        payload: undefined,
      });
    }
  }, [decision]);

  useEffect(() => {
    if (locked) {
      document.body.classList.add("block-interaction");
    } else {
      document.body.classList.remove("block-interaction");
    }
  }, [locked]);

  if (!decision) return <></>;

  switch (decision.type) {
    case DecisionType.CHAT:
    case DecisionType.DIRECT_COMMUNICATION:
      return <Bottom decision={decision} choices={decisionChoices} />;
    case DecisionType.APP_OVERFLOW:
      return <Overflow decision={decision} />;
    case DecisionType.FULLSCREEN:
      return <Fullscreen decision={decision} />;
    default:
      return <Overflow decision={decision} />;
  }
};

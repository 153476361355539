import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: Theme) => ({
  videoContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    padding: 0,
    zIndex: theme.custom.zIndex.tour - 1,
    display: "flex",
    flexDirection: "column",
    background:
      "linear-gradient(to bottom, #000 0%, #000 calc(100% - 110px), #254c53 calc(100% - 110px), #254c53 100%)",

    [`${theme.breakpoints.down("md")} and (orientation: portrait)`]: {
      background:
        "linear-gradient(to bottom, #000 0%, #000 calc(100% - 160px), #254c53 calc(100% - 160px), #254c53 100%)",
    },
  },
  video: {
    width: "auto",
    maxWidth: "calc(100% - 20px)",
    height: "calc(100% - 150px)",
    margin: "40px auto 0 auto",
    cursor: "pointer",

    "&::cue": {
      background: "rgba(0, 0, 0, 0.6)",
      lineHeight: "5px",
    },

    [`${theme.breakpoints.down("md")} and (orientation: portrait)`]: {
      height: "calc(100% - 200px)",
    },
  },
  controlsContainer: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10% 60px",

    [`${theme.breakpoints.down("md")} and (orientation: portrait)`]: {
      height: 100,
      margin: "0 10px 60px",
    },
  },
  controls: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",

    "& svg": {
      fill: "white",
      stroke: "rgba(0,0,0,0.3)",
      strokeWidth: "0.5px",
    },

    [`${theme.breakpoints.down("md")} and (orientation: portrait)`]: {
      flexWrap: "wrap",
    },
  },
  sliderContainer: {
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 200,
  },
  sliderRoot: {
    height: 14,
    margin: "0 10px",
  },
  rail: {
    height: 14,
    borderRadius: 8,
    backgroundColor: "white",
    opacity: 1,
  },
  track: {
    height: 14,
    borderRadius: 8,
    backgroundColor: "#616161",
    marginLeft: "-1px",
    borderColor: "transparent",
  },
  thumb: {
    display: "none",
  },
  volumeManageContainer: {
    display: "flex",
    alignItems: "center",
  },
  speedContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 15px",

    "& p": {
      color: "white",
      fontSize: 16,
    },
  },
  speedButton: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: 14,
    marginLeft: 15,
    textTransform: "none",
  },
  speedList: {
    width: 200,
    backgroundColor: "#254c53",
    color: "white",
  },
  speedListItemIcon: {
    marginRight: 15,
  },
}));

export default styles;

import { createRef, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { FormattedMessage } from "react-intl";
import CONFIG from "../../config";
import GameEngine from "../../game-engine";
import { Season } from "../../game-engine/interfaces";
import { useDeviceDetect, useSettings } from "../hooks";
import anna from "./assets/anna.svg";
import badge from "./assets/badge.svg";
import coin from "./assets/coin.svg";
import diamond from "./assets/diamond.svg";
import laurels from "./assets/laurels.svg";
import soundOff from "./assets/sound_off.svg";
import soundOn from "./assets/sound_on.svg";
import { drawBadge, drawCoin, drawDiamond } from "./helpers/helpers";
import { useUserScore } from "./hooks";
import classes from "./season-end.module.scss";

type SeasonEndProps = {
  seasonId: Season["id"];
  onClose: () => void;
};

const SeasonEnd = ({ seasonId, onClose }: SeasonEndProps) => {
  const { settings } = useSettings();
  const gameEngine = GameEngine();
  const { competencePoints, engagementPoints, badges } = useUserScore();
  const ref = createRef<HTMLAudioElement>();
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [music, setMusic] = useState<"play" | "pause">("play");
  const [volumeOff, setVolumeOff] = useState<boolean>(false);
  const { mobile } = useDeviceDetect();
  const [seasonContent, setSeasonContent] = useState<Season>();

  useEffect(() => {
    gameEngine.episodes.getSeasons().then((seasons) => {
      const season = seasons.find((s) => s.id === seasonId);

      if (season) setSeasonContent(season);
    });
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  });

  useEffect(() => {
    if (CONFIG.AUDIO_ENABLED && ref && ref.current) {
      ref.current.volume = 0.3;
    }
  }, []);

  const onMuteMusic = (ref) => {
    if (CONFIG.AUDIO_ENABLED) {
      music === "play"
        ? (ref.current.pause(), setMusic("pause"), setVolumeOff(true))
        : (ref.current.play(), setMusic("play"), setVolumeOff(false));
    }
  };

  return (
    <>
      <div className={classes.offboarding}>
        {competencePoints && badges && (
          <>
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              numberOfPieces={20}
              drawShape={drawCoin}
              initialVelocityY={-25}
            />
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              numberOfPieces={20}
              drawShape={drawDiamond}
              initialVelocityY={-25}
            />
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              numberOfPieces={20}
              drawShape={drawBadge}
              initialVelocityY={-25}
            />
          </>
        )}
        {competencePoints && badges && (
          <div
            className={`${classes.summary} animate__animated animate__slideInDown`}
          >
            <div className={classes.summaryHeader}>
              <img src={anna} alt="anna" />
              <p>
                <FormattedMessage
                  id="seasonend.congratulations"
                  values={{
                    season_title: seasonContent?.title,
                    season_end_message: seasonContent?.seasonEndMessage,
                  }}
                />
              </p>
            </div>
            <div className={classes.user}>
              <img className={classes.laurels} src={laurels} alt="laurels" />
              <img
                className={classes.avatar}
                src={settings.playerAvatar}
                alt="player avatar"
              />
            </div>
            <div className={classes.score}>
              <div className={classes.points}>
                <img
                  className="animate__animated animate__pulse animate__infinite animate__slow"
                  src={diamond}
                  alt="diamond"
                />
                <p>
                  <span>{competencePoints.totalPassed}</span>/
                  <span>{competencePoints.totalTried}</span>
                </p>
              </div>
              <div className={classes.points}>
                <img
                  className="animate__animated animate__pulse animate__infinite animate__slow"
                  src={badge}
                  alt="badge"
                />
                <p>
                  <span>{badges.obtained}</span>/<span>{badges.total}</span>
                </p>
              </div>
              <div className={classes.points}>
                <img
                  className="animate__animated animate__pulse animate__infinite animate__slow"
                  src={coin}
                  alt="coin"
                />
                <p>
                  <span>{engagementPoints}</span>
                </p>
              </div>
            </div>
            <button
              data-e2e-season-end-btn
              className={classes.nextBtn}
              onClick={onClose}
            >
              <FormattedMessage id="seasonend.next" />
            </button>
          </div>
        )}
        {CONFIG.AUDIO_ENABLED && (
          <audio ref={ref} src="/sounds/season_finale.mp3" autoPlay loop />
        )}
      </div>
      {!mobile && (
        <div className={classes.soundImg}>
          <img
            src={volumeOff ? soundOff : soundOn}
            onClick={() => onMuteMusic(ref)}
            alt="music"
          />
        </div>
      )}
    </>
  );
};

export default SeasonEnd;

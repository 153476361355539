import { createIntl, createIntlCache, IntlShape } from "react-intl";
import { getItem } from "../core/storage";
import de from "./message-de";
import en from "./message-en";

const cache = createIntlCache();
const messages = { en, de };
const intls: Record<string, IntlShape> = {};

const defaultLocale = /^de?/gi.test(navigator.language) ? "de" : "en";

export const SUPPORTED_LANGUAGES = ["de", "en"] as const;

export type SUPPORTED_LANGUAGES_TYPE = typeof SUPPORTED_LANGUAGES[number];

export const getLanguage = () => getItem("locale") || defaultLocale;

export const getIntl = (locale = getLanguage()) => {
  if (!intls[locale]) {
    intls[locale] = createIntl(
      {
        locale: locale,
        messages: messages[locale],
      },
      cache
    );
  }
  return intls[locale];
};

export const registerMessages = (moreMessages, language) => {
  Object.assign(messages[language], moreMessages);
};

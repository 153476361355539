export const NEWS_ARTICLE_OPENED = "news.article.opened";
export const NEWS_ARTICLE_RATED = "news.article.rated";
export const NEWS_ARTICLE_LINK_OPENED = "news.article.link.opened";

export interface articleOpened {
  type: typeof NEWS_ARTICLE_OPENED;
  payload: {
    id: string;
  };
}

export interface articleRated {
  type: typeof NEWS_ARTICLE_RATED;
  payload: {
    id: string;
  };
}

export interface articleLinkOpened {
  type: typeof NEWS_ARTICLE_LINK_OPENED;
  payload: {
    articleId: string;
    link: string;
  };
}

export type NewsMessage = articleOpened | articleRated | articleLinkOpened;

import { RewindRef } from "../game-engine/rewinder";
import { Quest } from "../game-engine/script-runner";
import { Message } from "../message-hub/interfaces";
import type { Actor, Application } from "./interfaces";
import {
  Background,
  CompetenceType,
  RunAnimationPayload,
  Score,
  Step,
  StopAnimationPayload,
} from "./interfaces";
import {
  addNotificationMessage,
  removeNotificationMessage,
} from "./notifications/messages";

export const APP_ADD = "app.add";
export const APP_ADD_SUCCESSFUL = "app.add.successful";
export const APP_CHANGE = "app.change";
export const APP_REMOVE = "app.remove";
export const APP_OPEN = "app.open";
export const APP_CLOSED = "app.closed";
export const ACTOR_ADD = "actor.add";
export const ACTOR_CHANGE = "actor.change";
export const ACTOR_REMOVE = "actor.remove";
export const TEST_STOP = "test.stop";
export const TEST_COMPLETE = "test.complete";
export const BADGE_ADD = "badge.add";
export const CONTENT = "game.content";
export const VAR = "game.content";
export const EPISODE_CHANGE = "episode.change";
export const EPISODE_PLAY = "episode.play";
export const EPISODE_END = "episode.end";
export const EPISODE_COMPLETE = "episode.complete";
export const SEASON_END = "season.end";
export const COMPETENCE_SCORE_UPDATE = "competence.score.update";
export const PULSE_PLAYER_ICON = "pulse.player.icon";
export const PULSE_REWIND_ICON = "pulse.rewind.icon";
export const APP_LOCATION = "root.location";
export const MAIN_WAIT = "root.wait";
export const NAVIGATION_URL = "root.navigate.url";
export {
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
} from "./notifications/messages";
export const HIDE_APPS = "root.apps.hide";
export const SHOW_APPS = "root.apps.show";
export const ANIMATION_RUN = "root.animation.run";
export const ANIMATION_STOP = "root.animation.stop";
export const PASS_TIME = "root.pass.time";
export const PASS_TIME_FINISH = "root.pass.time.finished";
export const VIDEO_PLAY = "root.video.play";
export const VIDEO_FINISH = "root.video.finish";
export const TOUR_RUN = "tour.run";
export const TOUR_FINISH = "tour.finish";
export const TOUR_SCREEN_CHANGE = "tour.screen.change";
export const GAME_CURRENT_QUEST = "game.current.quest";
export const GAME_POINTS_ADD = "game.pointsAdd";
export const GAME_POINTS_SCALE = "game.pointsScale";
export const GAME_BADGE_ADD = "game.badgeAdd";
export const GAME_BADGE_UPDATE = "game.badgeUpdate";
export const GAME_CHANGE_MODE = "game.changeMode";
export const GAME_ENGAGEMENT_POINTS_ADD = "game.engagementPoints.add";
export const GAME_ENGAGEMENT_POINTS_INCREMENT =
  "game.engagementPoints.increment";
export const GAME_EXIT = "game.exit";
export const GAME_PAUSE = "game.pause";
export const GAME_STEP_ADD = "game.stepAdd";
export const GAME_LEVEL_PROGRESS = "game.level.progress";
export const USER_STUCK = "user.stuck";
export const GAME_QUESTS_UPDATE = "game.quests.update";
export const REWIND_ANIMATION_START = "root.rewind.animation.start";
export const REWIND_ANIMATION_STOP = "root.rewind.animation.stop";

export type appAddMessage = Message<typeof APP_ADD, Application>;

export type appAddSuccessfulMessage = Message<
  typeof APP_ADD_SUCCESSFUL,
  { name: string }
>;

export type appChangeMessage = Message<typeof APP_CHANGE, Partial<Application>>;

export interface appClosedMessage {
  type: typeof APP_CLOSED;
  payload: {
    appId: string;
  };
}

export interface appOpenMessage {
  type: typeof APP_OPEN;
  payload: {
    appId: string;
  };
}

export type actorAddMessage = Message<typeof ACTOR_ADD, Actor>;

export type actorChangeMessage = Message<typeof ACTOR_CHANGE, Partial<Actor>>;

export type testStopMessage = Message<typeof TEST_STOP, { test: string }>;

export type testCompleteMessage = Message<
  typeof TEST_COMPLETE,
  { test: string; failed: boolean }
>;

export type competenceScoreUpdateMessage = Message<
  typeof COMPETENCE_SCORE_UPDATE,
  { totalPassed: number; totalTried: number }
>;

export type contentMessage = Message<
  typeof CONTENT,
  {
    id: string;
    value: string;
  }
>;

export type varMessage = Message<
  typeof VAR,
  {
    id: string;
    value: any;
  }
>;

export type episodeChangeMessage = Message<
  typeof EPISODE_CHANGE,
  {
    id: string;
  }
>;

export type episodePlayMessage = Message<
  typeof EPISODE_PLAY,
  {
    id: string;
    playCount: number;
  }
>;

export type episodeEndMessage = Message<
  typeof EPISODE_END,
  {
    id: string;
  }
>;

export type episodeCompleteMessage = Message<
  typeof EPISODE_COMPLETE,
  {
    id: string;
  }
>;

export type seasonEndMessage = Message<
  typeof SEASON_END,
  {
    seasonId: string;
  }
>;

export type appPulsePlayerIconMessage = Message<
  typeof PULSE_PLAYER_ICON,
  boolean
>;

export type appPulseRewindIconMessage = Message<
  typeof PULSE_REWIND_ICON,
  boolean
>;

export interface navigateUrlMessage {
  type: typeof NAVIGATION_URL;
  payload: { url: string }; // app id
}

export interface hideApps {
  type: typeof HIDE_APPS;
}

export interface showApps {
  type: typeof SHOW_APPS;
}

export interface appLocation {
  type: typeof APP_LOCATION;
  payload: { background: Background; name: string };
}

export interface waitMessage {
  type: typeof MAIN_WAIT;
  payload: { seconds: number };
}

export interface animationRun {
  type: typeof ANIMATION_RUN;
  payload: RunAnimationPayload;
}

export interface animationStop {
  type: typeof ANIMATION_STOP;
  payload: StopAnimationPayload;
}

export interface passTime {
  type: typeof PASS_TIME;
  payload: {
    /**
     * In seconds.
     * Integers only.
     * Minimum 3.
     */
    duration?: number;
  };
}

export interface passTimeFinish {
  type: typeof PASS_TIME_FINISH;
  payload: Record<string, never>;
}

export interface videoPlay {
  type: typeof VIDEO_PLAY;
  payload: {
    state: boolean;
    src: string;
  };
}

export interface videoStop {
  type: typeof VIDEO_FINISH;
  payload: {
    src: string;
    state: boolean;
  };
}

export interface tourRun {
  type: typeof TOUR_RUN;
  payload: boolean;
}

export interface tourFinish {
  type: typeof TOUR_FINISH;
  payload: {
    run: boolean;
    finish: boolean;
  };
}

export interface tourScreenChange {
  type: typeof TOUR_SCREEN_CHANGE;
  payload: number;
}

export interface GameCurrentQuest {
  type: typeof GAME_CURRENT_QUEST;
  payload: Quest;
}

export interface pointsAddMessage {
  type: typeof GAME_POINTS_ADD;
  payload: {
    competencies: { [competence in CompetenceType]: number };
    global: number;
  };
}

export type badgeAddMessage = Message<
  typeof GAME_BADGE_ADD,
  {
    id: string;
    noNotification?: boolean;
  }
>;

export type badgeUpdateMessage = Message<
  typeof GAME_BADGE_UPDATE,
  {
    obtained: number;
    total: number;
  }
>;

export interface changeModeMessage {
  type: typeof GAME_CHANGE_MODE;
  payload: { mode: "play" | "pause" };
}

export interface gameEngagementPointsAddMessage {
  type: typeof GAME_ENGAGEMENT_POINTS_ADD;
  payload: {
    points: number;
  };
}
export type gameEngagementPointsIncrementMessage = Message<
  typeof GAME_ENGAGEMENT_POINTS_ADD,
  { points: number }
>;

export interface pointsScaleMessage {
  type: typeof GAME_POINTS_SCALE;
  payload: Pick<Score, "minPoints" | "maxPoints" | "level">;
}

export interface gameExitMessage {
  type: typeof GAME_EXIT;
  payload: null;
}

export interface gamePauseMessage {
  type: typeof GAME_PAUSE;
  payload: null;
}

export interface stepAddMessage {
  type: typeof GAME_STEP_ADD;
  payload: Step;
}

export type gameLevelProgress = Message<
  typeof GAME_LEVEL_PROGRESS,
  { current: number; total: number; progress: number }
>;

export type userStuckEvent = Message<typeof USER_STUCK, Quest | undefined>;

export type gameQuestsUpdate = Message<
  typeof GAME_QUESTS_UPDATE,
  {
    quests: { id: string; description: string }[];
    refs: Record<string, RewindRef>;
  }
>;

export type rewindAnimationStart = Message<
  typeof REWIND_ANIMATION_START,
  undefined
>;

export type rewindAnimationStop = Message<
  typeof REWIND_ANIMATION_STOP,
  undefined
>;

export type appLauncherMessages =
  | addNotificationMessage
  | removeNotificationMessage
  | navigateUrlMessage
  | appLocation
  | showApps
  | hideApps
  | waitMessage
  | animationRun
  | animationStop
  | passTime
  | passTimeFinish
  | videoPlay
  | videoStop
  | tourRun
  | tourFinish
  | tourScreenChange
  | GameCurrentQuest
  | pointsAddMessage
  | badgeAddMessage
  | badgeUpdateMessage
  | changeModeMessage
  | gameEngagementPointsAddMessage
  | gameEngagementPointsIncrementMessage
  | pointsScaleMessage
  | gameExitMessage
  | stepAddMessage
  | gameLevelProgress
  | appAddMessage
  | appChangeMessage
  | appClosedMessage
  | actorAddMessage
  | actorChangeMessage
  | testStopMessage
  | testCompleteMessage
  | competenceScoreUpdateMessage
  | contentMessage
  | varMessage
  | episodeChangeMessage
  | episodeEndMessage
  | seasonEndMessage
  | appPulsePlayerIconMessage
  | appPulseRewindIconMessage
  | userStuckEvent
  | gameQuestsUpdate
  | appAddSuccessfulMessage
  | rewindAnimationStart
  | rewindAnimationStop
  | appOpenMessage;

import { Message } from "../../message-hub/interfaces";
import { DecisionChoice } from "./interfaces/decision-choice";
import { DecisionPayload } from "./interfaces/decision-payload";

export const DECISION_ADD = "decision.add";
export const DECISION_END = "decision.end";
export const DECISION_CHOOSE = "decision.choose";
export const DECISION_BLOCK = "decision.block";

export type decisionAdd = Message<typeof DECISION_ADD, DecisionPayload>;

export type decisionEnd = Message<typeof DECISION_END, DecisionPayload["id"]>;

export type decisionChoose = Message<typeof DECISION_CHOOSE, DecisionChoice>;

export type decisionBlock = Message<typeof DECISION_BLOCK, DecisionChoice>;

export type decisionMessage =
  | decisionAdd
  | decisionEnd
  | decisionChoose
  | decisionBlock;

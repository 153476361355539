const messages = {
  "meetings-tool-browser.type": "Typ",
  "meetings-tool-browser.duration": "Dauer",
  "meetings-tool-browser.form.title": "Details ausfüllen",
  "meetings-tool-browser.form.name": "Name *",
  "meetings-tool-browser.form.mail": "Deine E-Mail *",
  "meetings-tool-browser.form.additional_information":
    "Zusätzliche Informationen",
  "meetings-tool-browser.form.schedule_event": "Termin planen",
  "meetings-tool-browser.message.title": "Sie sind mit {name} verabredet",
  "meetings-tool-browser.message.text":
    "Wir haben Ihnen die Einzelheiten des Treffens per E-Mail zugeschickt.",
  "meetings-tool-browser.mail-error-message": "Falsches Mail-Adressformat",
};

export default messages;
